import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { formatCurrency } from 'utils/l10n';
import { SVGIcon } from 'common/components';

import QuantityControls from '../QuantityControls/QuantityControls';

import './basket-item.less';

const BasketItem = ({
  soldOutLabel,
  name,
  isAddon,
  price,
  onChangeQuantity,
  quantity,
  onRemove,
  total,
  max,
  min,
  blockRemove,
}) => {
  const classNames = useClassName('BasketItem');

  return (
    <div
      key={name}
      className={classNames(['ticket', Boolean(soldOutLabel) && 'sold-out'])}>
      <div className={classNames('ticket-info')}>
        <span>{name}</span>
        {isAddon && <span className={classNames('addon')}>ADD-ON</span>}
      </div>
      <div className={classNames('ticket-controls')}>
        <span>{formatCurrency(price)}</span>
        {soldOutLabel ? (
          <div>{soldOutLabel}</div>
        ) : (
          <QuantityControls
            onChange={(quantity) => onChangeQuantity(quantity)}
            quantity={quantity}
            className={classNames('small-controls')}
            max={max}
            min={min}
          />
        )}
      </div>
      <div className={classNames('ticket-totals')}>
        <span>{formatCurrency(total || price * quantity)}</span>
      </div>
      <div
        className={classNames('ticket-remove')}
        onClick={() => onRemove()}
        {...(blockRemove && { style: { visibility: 'hidden' } })}>
        <SVGIcon name="trash" />
      </div>
    </div>
  );
};

BasketItem.propTypes = {
  soldOutLabel: PropTypes.bool,
  name: PropTypes.string,
  isAddon: PropTypes.bool,
  price: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  quantity: PropTypes.number,
  onRemove: PropTypes.func,
  total: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  blockRemove: PropTypes.bool,
};

export default BasketItem;
