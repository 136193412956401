import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { Container } from 'semantic-ui-react';
import { getContentfulAssetUrlandAlt } from 'common/components';
import { LoaderImage } from 'public/components/Loader';
import { DealDetailsBreadcrumb } from 'public/components';
import { useClassName } from 'common/hooks';
import { urlForUpload } from 'utils/api';
import { VenueDetailsMap } from '../../components/VenueDetailsScreen/VenueDetailsMap';
import dealsCalendarIcon from 'common/assets/deals-calendar.svg';

import './deal-details.less';

const DealDetail = (props) => {
  const { slug } = props.match.params;
  const [deal, setDeal] = useState(null);
  const className = useClassName('DealDetails');
  const { pathname } = useLocation();

  useEffect(() => {
    fetchDealBySlug();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchDealBySlug = async () => {
    const data = await props.deals.getDealBySlug(slug);
    setDeal(data);
  };

  if (!deal) return <></>;

  return (
    <Container className={className('details-page-wrapper')}>
      <DealDetailsBreadcrumb
        type={{ name: deal.venueId.name, slug: deal.venueId.slug }}
      />
      <div className={className('details-wrapper')}>
        <div className={className('details-info')}>
          <div className={className('details-venue-name')}>
            {deal.venueId && deal.venueId.name}
          </div>
          <div className={className('details-deal-title')}>
            {deal && deal.title}
          </div>
          <div className={className('details-deal-dates')}>
            <div className={className('details-deal-dates-icon')}>
              <LoaderImage
                src={dealsCalendarIcon}
                alt={''}
                loadingHeight={20}
                hideLoader
                fluid
              />
            </div>
            {new Date(deal.startDate).toLocaleDateString('en', {
              month: 'short',
              day: 'numeric',
            })}{' '}
            -{' '}
            {new Date(deal.endDate).toLocaleDateString('en', {
              month: 'short',
              day: 'numeric',
            })}
          </div>
          <div className={className('details-deal-description')}>
            {deal && deal.description}
          </div>
        </div>
        <div className={className('details-img')}>
          {deal.dealImage && (
            <div
              style={{
                backgroundImage: `url("${urlForUpload(deal.dealImage)}")`,
              }}
            />
          )}
          {!deal.dealImage && deal.venueId?.content?.logo && (
            <div
              style={{
                backgroundImage: `url("${
                  getContentfulAssetUrlandAlt(deal.venueId.content.logo)
                    .assetUrl
                }")`,
              }}
            />
          )}
          {!deal.dealImage && !deal.venueId?.content?.logo && (
            <div className={className('empty-deals-img')}>
              {deal.venueId && deal.venueId.name}
            </div>
          )}
        </div>
      </div>
      <div className={className('details-location-wrapper')}>
        {deal.venueId?.jibestreamDestinationId && (
          <VenueDetailsMap
            jibestreamDestinationId={deal.venueId.jibestreamDestinationId}
          />
        )}
      </div>
      {deal.term && (
        <div className={className('term-condition-wrapper')}>
          <span>Terms and conditions</span>
          <p>{deal.term}</p>
        </div>
      )}
    </Container>
  );
};

DealDetail.propTypes = {
  props: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default inject('deals')(DealDetail);
