import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { pick, pickBy } from 'lodash';

import { Screen } from 'public/helpers';
import { NotFound } from 'public/components';
import { isValidEmail, isValidPhone, isValidZipCode } from 'utils/helpers';

import { BirthdayCheckoutContainer } from '../BirthdayCheckoutContainer';
import { NewCheckoutSummary } from '../../Components/Summary';
import {
  getAdmissionSubtotal,
  getAdmissionTax,
  getAdmissionTotal,
} from '../Admission/getBirthdayAdmissionTotals';
import { STEPS } from '../../const';
import {
  getEntertainmentSubtotal,
  getEntertainmentTax,
  getEntertainmentTotal,
} from '../Entertainment/getBirthdayEntertainmentTotals';
import {
  getFoodAndBeverageSubtotal,
  getFoodAndBeverageTax,
  getFoodAndBeverageTotal,
} from '../BirthdayFoodAndBeverage/getBirthdayFoodAndBeverageTotals';
import {
  getMerchandiseSubtotal,
  getMerchandiseTax,
  getMerchandiseTotal,
} from '../Merchandise/getBirthdayMerchandiseTotals';
import { NewCheckoutProgressBar } from '../../Components/ProgressBar';
import { BirthdayInfoForm } from './BirthdayInfoForm';

const defaultFields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone Number',
  zipCode: 'Zip Code',
  email: 'Email',
};

const getRequiredValidation = (fieldName) => {
  return {
    isInvalid: (fields) => !fields[fieldName],
    message: `${fieldName} is required`,
  };
};

const validations = {
  [defaultFields.firstName]: [getRequiredValidation(defaultFields.firstName)],
  [defaultFields.lastName]: [getRequiredValidation(defaultFields.lastName)],
  [defaultFields.email]: [
    getRequiredValidation(defaultFields.email),
    {
      isInvalid: (fields) => !isValidEmail(fields[defaultFields.email]),
      message: `Please provide a valid ${defaultFields.email}`,
    },
  ],
  [defaultFields.phone]: [
    getRequiredValidation(defaultFields.phone),
    {
      isInvalid: (fields) => !isValidPhone(fields[defaultFields.phone]),
      message: `Please provide a valid ${defaultFields.phone}`,
    },
  ],
  [defaultFields.zipCode]: [
    getRequiredValidation(defaultFields.zipCode),
    {
      isInvalid: (fields) => !isValidZipCode(fields[defaultFields.zipCode]),
      message: `Please provide a valid ${defaultFields.zipCode}`,
    },
  ],
};

@inject('birthdays', 'venues', 'me', 'appSession')
@observer
export default class BirthdayInfo extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      venue: null,
      isEnabled: null,
      fieldErrors: {},
      selectedField: null,
    };
  }

  async routeDidUpdate() {
    if (!this.props.appSession.isLoggedIn()) {
      this.setState({
        redirect: `/signup?return=${location.pathname}`,
      });
    } else {
      const { slug } = (this.props.match && this.props.match.params) || {};
      const venue = await this.props.venues.fetchItemBySlug(slug);

      try {
        const settings = await this.props.birthdays.getParties(slug);
        const admission = await this.props.birthdays.validateAdmission(
          slug,
          settings
        );
        const { contactDetails, birthdayInfo } = this.props.birthdays.checkout;

        if (admission.isValid) {
          const { user } = this.props.me;

          this.setState({
            venue,
            isEnabled: true,
            quantities: {},
            settings,
            ...(user && {
              formData: {
                [defaultFields.firstName]: user.firstName,
                [defaultFields.lastName]: user.lastName,
                [defaultFields.zipCode]: user.address?.zipCode,
                [defaultFields.email]: user.email,
                [defaultFields.phone]: user.phone,
                ...contactDetails,
                ...birthdayInfo,
              },
            }),
          });
        } else {
          this.setState({
            redirect: this.props.match.url.replace('info', 'tickets'),
          });
        }
      } catch (e) {
        this.setState({ isEnabled: false });
      }
    }
  }

  onSubmit = () => {
    if (!this.validate(null, {})) return;

    const {
      formData,
      settings: { infoFields },
    } = this.state;
    this.setState({
      loading: true,
    });

    const prodForms = this.getProductForms();
    const fieldNames = infoFields
      .map((infoField) => infoField.title)
      .concat(
        prodForms
          .map((form) => form.fields)
          .flat()
          .map((field) => field.name)
      );

    this.props.birthdays.setInfo({
      contactDetails: pick(formData, Object.values(defaultFields)),
      birthdayInfo: pickBy(formData, (_, key) => {
        return fieldNames.includes(key);
      }),
    });

    this.setState({
      redirect: this.props.match.url.replace('info', 'payment'),
    });
  };

  renderSummary() {
    const { admission, entertainment, foodAndBeverage, merchandise } =
      this.props.birthdays.checkout;
    const { selectedSession, numberOfGuests } = admission;

    const totals = {
      subtotal:
        getAdmissionSubtotal({ selectedSession, numberOfGuests }) +
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal(merchandise),
      tax:
        getAdmissionTax({ selectedSession, numberOfGuests }) +
        getEntertainmentTax(entertainment) +
        getFoodAndBeverageTax(foodAndBeverage) +
        getMerchandiseTax(merchandise),
      ticketSubtotal: getAdmissionSubtotal({ selectedSession, numberOfGuests }),
      addonSubtotal:
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal(merchandise),
      total:
        getAdmissionTotal({ selectedSession, numberOfGuests }) +
        getEntertainmentTotal(entertainment) +
        getFoodAndBeverageTotal(foodAndBeverage) +
        getMerchandiseTotal(merchandise),
    };

    const error = this.state.error && { errorMessage: this.state.error };

    return (
      <NewCheckoutSummary
        stepNames={{
          tickets: 'Admission',
          addons: 'Add-ons',
          checkout: 'Details & Confirmation',
        }}
        error={error}
        canSubmit
        onSubmit={this.onSubmit}
        step={STEPS.Checkout}
        loading={this.state.loading}
        totals={totals}
      />
    );
  }

  setSelectedField = (selectedField) => {
    this.setState({
      selectedField,
    });
  };

  setFieldErrors = (fieldErrors) => {
    this.setState({
      fieldErrors,
    });
  };

  validate = (fieldName, { value }) => {
    const prodForms = this.getProductForms();
    const {
      settings: { infoFields },
      formData,
      fieldErrors: oldFieldErrors,
    } = this.state;
    const fields = Object.fromEntries(
      Object.values(defaultFields)
        .concat(infoFields.map((infoField) => infoField.title))
        .concat(
          prodForms
            .map((form) => form.fields)
            .flat()
            .map((field) => field.name)
        )
        .map((name) => [name, value || formData[name]])
    );

    const newFieldErrors = Object.keys(fields)
      .filter((key) => !fieldName || fieldName === key)
      .reduce((fieldErrors, key) => {
        const fieldValidations = validations[key] || [
          getRequiredValidation(key),
        ];
        if (!fieldValidations?.length) return fieldErrors;

        const error = fieldValidations.find((a) => a.isInvalid(fields));
        return {
          ...fieldErrors,
          [key]: error ? error.message : null,
        };
      }, {});

    const errors = {
      ...oldFieldErrors,
      ...newFieldErrors,
    };
    this.setFieldErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  getProductForms = () => {
    const { foodAndBeverage } = this.props.birthdays.checkout;

    const parentProductsIds = Object.keys(foodAndBeverage.quantities)
      .map((prodId) =>
        foodAndBeverage.products.find((prod) =>
          prod.products.some(({ id }) => prodId === id)
        )
      )
      .map((prod) => prod.id);

    return this.state.settings.foodAndBeverage.products
      .filter((prod) => parentProductsIds.includes(prod.productId))
      .map((prod) => ({
        fields: prod.formFields.map((field) => ({
          ...field,
          name: `${prod.name} - ${field.title}`,
        })),
        name: prod.name,
        id: prod.productId,
      }))
      .filter((form) => form.fields.length);
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const { isEnabled } = this.state;
    if (isEnabled === null) return null;
    if (!isEnabled) return <NotFound />;

    const productForms = this.getProductForms();

    return (
      <BirthdayCheckoutContainer
        attrs={this.getAttrs()}
        content={
          <>
            <NewCheckoutProgressBar
              title="Details & Confirmation"
              total={3}
              current={3}
              onClick={() => {
                this.setState({
                  redirect: this.props.match.url.replace('info', 'review'),
                });
              }}
            />
            <BirthdayInfoForm
              infoFields={this.state.settings.infoFields}
              setInfoFields={this.setInfoFields}
              formData={this.state.formData}
              setFormData={(formData) => this.setState({ formData })}
              fieldErrors={this.state.fieldErrors}
              validate={this.validate}
              selectedField={this.state.selectedField}
              setSelectedField={this.setSelectedField}
              defaultFields={defaultFields}
              productForms={productForms}
            />
          </>
        }
        summary={this.renderSummary()}
      />
    );
  }
}
