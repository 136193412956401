import React from 'react';
import { Component } from 'common/helpers';

import {
  Video,
  AnimatedSprite,
  ResponsiveImage,
  ContentfulImage,
  DesktopOnly,
  MobileOnly,
} from 'common/components';

import './motion-graphic.less';

export default class DreamWorksMotionGraphic extends Component {
  getGraphic() {
    const { field, contentfulName, ratio } = this.props;
    if (contentfulName) {
      return (
        <ResponsiveImage
          className={this.getElementClass('image')}
          contentfulName={contentfulName}
          ratio={ratio}
          fluid
        />
      );
    } else if (field) {
      return (
        <ContentfulImage
          className={this.getElementClass('image')}
          field={field}
        />
      );
    }
  }

  render() {
    const { circle } = this.props;
    return (
      <div {...this.getAttrs()}>
        {this.getGraphic()}
        <div
          className={this.getElementClass('motion', circle ? 'circle' : null)}>
          <DesktopOnly>
            <Video
              contentfulName="DreamWorks Animation - Motion - Video"
              className={this.getElementClass('motion-video')}
              controls={false}
              playsInline
              autoPlay
              loop
            />
          </DesktopOnly>
          <MobileOnly>
            <AnimatedSprite
              fps={12}
              frames={46}
              className={this.getElementClass('motion-sprite')}
              contentfulName="DreamWorks Animation - Motion - Sprite"
            />
          </MobileOnly>
        </div>
      </div>
    );
  }
}
