import React from 'react';
import { flatten } from 'lodash';
import { Container, Button, Divider } from 'semantic-ui-react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { STRIPE_PUBLISHABLE_KEY } from 'utils/env/client';
import { captureError } from 'utils/sentry';
import { Component } from 'common/helpers';
import { Layout, ResponsiveImage } from 'common/components';
import { Totals, Spacer } from 'public/components';
import { PaymentForm } from './components';
import { formatDate, formatCurrency } from 'utils/l10n';
import { getCookie, setCookie, deleteCookie } from 'utils/helpers/cookie';
import {
  getItemPrice,
  getItemName,
  getItemBulkDiscount,
  trackCheckoutStarted,
  trackOrderCompleted,
  trackCheckoutStepViewed,
  trackCheckoutStepCompleted,
  validateInventory,
} from 'common/helpers';
import { OLD_CHECKOUT } from 'public/constants/gtm';
import CartStep from './helpers/CartStep';
import { keys, getProductsQuantities } from './helpers/discounts';

import './checkout.less';
import {
  getDiscountValues,
  isTwoForOneDiscount,
} from './helpers/twoForOneCodes';

@CartStep
@observer
@inject('appSession', 'externalbookings')
export default class CartCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displayReview: true,
      order: null,
      error: null,
      paymentIntent: null,
      discountValue: null,
      discountUsageId: null,
      redirect: this.getInitialRedirect(),
    };
  }

  componentDidMount() {
    trackCheckoutStarted(this.props.cartItems);
    trackCheckoutStepViewed(2);
    if (this.props.appSession.isLoggedIn()) {
      this.getDiscount();
    }
  }

  async getDiscount() {
    const discountValue = getCookie(keys.DISCOUNT_VALUE_KEY) || 0;
    const discountCode = getCookie(keys.DISCOUNT_CODE_KEY);
    const discountUsageFromCookie = getCookie(keys.DISCOUNT_USAGE_KEY);
    const { cartItems } = this.props;

    try {
      if (!discountValue && !discountCode && !discountUsageFromCookie) return;

      // if discount was applied previously
      const discountUsage = JSON.parse(discountUsageFromCookie);
      const { id: prevUsageId, valid, userLoggedIn } = discountUsage;

      // user already logged in when discount was applied
      if (userLoggedIn && valid) {
        this.setState({ discountValue, discountUsageId: prevUsageId });
        return;
      }

      // delete previous redemption and create a new one
      deleteCookie(keys.DISCOUNT_USAGE_KEY);
      localStorage.removeItem(keys.DISCOUNT_USAGE_KEY);

      if (!valid) {
        deleteCookie(keys.DISCOUNT_VALUE_KEY);
        return;
      }

      await this.props.discounts.delete(prevUsageId);

      const productQuantities = getProductsQuantities(cartItems);
      const newDiscountUsage = await this.props.discounts.redeem(
        discountCode,
        productQuantities
      );
      newDiscountUsage.userLoggedIn = true;

      // Save cookie in case user reloads checkout page
      // Remove productIds from discountUsage and store them in localStorage
      const { productIds = [], ...rest } = newDiscountUsage;
      const discountUsageProductIdsString = JSON.stringify(productIds);
      const discountUsageString = JSON.stringify(rest);

      // Store discount product ids in localStorage
      localStorage.setItem(
        keys.DISCOUNT_USAGE_KEY,
        discountUsageProductIdsString
      );
      // Store discount usage without productIds in cookie
      setCookie(keys.DISCOUNT_USAGE_KEY, discountUsageString, 15);

      if (!newDiscountUsage?.valid) {
        throw new Error(
          newDiscountUsage.error || newDiscountUsage.errorMessage
        );
      }

      //save new usage in cookie here
      const { id: discountUsageId } = newDiscountUsage;

      if (isTwoForOneDiscount(newDiscountUsage)) {
        const { discount } = getDiscountValues(
          this.props.cartItems,
          newDiscountUsage,
          newDiscountUsage.reportingCategoryName
        );
        this.setState({ discountValue: discount, discountUsageId });
      } else {
        this.setState({ discountValue, discountUsageId });
      }
    } catch (error) {
      this.onError(error);
    }
  }

  getInitialRedirect() {
    if (!this.props.appSession.isLoggedIn()) {
      return '/signup?return=/cart/checkout';
    }
  }

  setOrderReviewed = () => {
    this.setState({
      displayReview: false,
    });
    window.scrollTo(0, 0);
    trackCheckoutStepCompleted(2);
    trackCheckoutStepViewed(3);
  };

  getValidationQuantity(item) {
    const { startTime, quantities } = item;
    return Object.keys(item.quantities).reduce((reducer, productId) => {
      const quantity = quantities[productId];
      return {
        ...reducer,
        [productId]: startTime ? { startTime, quantity } : quantity,
      };
    }, {});
  }

  validateInventory = async () => {
    const { externalbookings } = this.props;
    for (const ci of this.props.cartItems) {
      const validation = validateInventory(
        ci.inventory,
        this.getValidationQuantity(ci),
        ci.venue,
        externalbookings.get(ci.venue.slug, ci.reservationDate)
      );
      if (!validation.canSubmit) {
        throw new Error(validation.errorMessage);
      }
    }
  };

  onPaymentMethodReceived = async (paymentMethodId, saveCard) => {
    this.setState({
      error: null,
      order: null,
      paymentIntent: null,
      loading: true,
    });
    try {
      await this.validateInventory();
      const response = await this.props.orders.create({
        items: flatten(
          this.props.cartItems.map((cartItem) => {
            const {
              reservationDate,
              startTime,
              promoCode,
              inventory,
              quantities,
              venueId,
              bundleSlug,
            } = cartItem;
            return cartItem.tickets.map((inventoryItem) => {
              const {
                ticketOptionId,
                bookingItemId,
                addon,
                passportType,
                passportValidDays,
              } = inventoryItem;
              const quantity = quantities[ticketOptionId];
              const discount = getItemBulkDiscount(
                inventoryItem,
                inventory,
                quantities
              );
              const discountRateId = discount && discount.rateExternalId;
              return {
                quantity,
                promoCode,
                ticketOptionId,
                discountRateId,
                reservationDate,
                startTime,
                bookingItemId,
                venueId,
                bundleSlug,
                addon,
                passportType,
                passportValidDays,
              };
            });
          })
        ),
        paymentToken: paymentMethodId,
        saveCard,
        discountUsageId: this.state.discountValue
          ? this.state.discountUsageId
          : null,
        discountValue: this.state.discountValue || 0,
      });
      if (response.order.status === 'complete') {
        this.completeCheckout(response.order);
      } else if (response.intent) {
        this.setState({
          order: response.order,
          paymentIntent: response.intent,
        });
      } else {
        const err = new Error('Order could not be completed');
        err.payload = response;
        throw err;
      }
    } catch (err) {
      this.onError(err);
    }
  };

  onPaymentIntentSuccess = async (paymentIntent, saveCard) => {
    try {
      const response = await this.props.orders.confirm({
        orderId: this.state.order.id,
        paymentIntentId: paymentIntent.id,
        saveCard,
      });
      if (response.order.status === 'complete') {
        this.completeCheckout(response.order);
      } else {
        const err = new Error('Order could not be confirmed');
        err.payload = response;
        throw err;
      }
    } catch (err) {
      this.onError(err);
    }
  };

  onError = (error) => {
    this.setState({
      error,
      loading: false,
      paymentIntent: null,
    });
    captureError(error);
  };

  completeCheckout(order = {}) {
    trackOrderCompleted(order, this.props.cartItems);
    trackCheckoutStepCompleted(3);

    deleteCookie(keys.DISCOUNT_VALUE_KEY);
    deleteCookie(keys.DISCOUNT_CODE_KEY);
    deleteCookie(keys.DISCOUNT_USAGE_KEY);
    localStorage.removeItem(keys.DISCOUNT_USAGE_KEY);

    this.setState({
      loading: false,
      redirect: '/cart/completed',
    });
    this.props.cart.clear();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div {...this.getAttrs()}>
        <Container>
          <Spacer size="s" desktop />
          <Spacer size="s" />
          <h1>Checkout</h1>
          <Spacer size="s" />
          <Layout horizontal stackable>
            <Layout.Group className={this.getElementClass('left')} grow>
              {this.renderLeftPanel()}
            </Layout.Group>
            <Layout.Group className={this.getElementClass('totals')}>
              <Spacer size="s" />
              <p className="large">Summary</p>
              <Spacer size="s" />
              <Totals
                count={this.props.count}
                subtotal={this.props.subtotal}
                tax={this.props.tax}
                discount={this.state.discountValue}
              />
              {this.props.promoMessages}
              <Spacer size="s" />
            </Layout.Group>
          </Layout>
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderLeftPanel() {
    const { displayReview } = this.state;

    if (window[OLD_CHECKOUT] && displayReview) {
      return this.renderReviewOrder();
    }

    return this.renderPaymentForm();
  }

  getTicketImage(venue) {
    return venue.ticketImage || venue.images[0];
  }

  renderReviewOrder() {
    const { cartItems } = this.props;
    return (
      <React.Fragment>
        <div className={this.getElementClass('details-header')}>
          Booking Details
        </div>
        <Divider className="light" />
        {cartItems.map((cartItem) => {
          const {
            venue,
            reservationDate,
            inventory,
            quantities,
            tickets,
            startTimeName,
          } = cartItem;
          return tickets.map((inventoryItem) => {
            const { ticketOptionId } = inventoryItem;
            const quantity = quantities[ticketOptionId];
            const name = getItemName(inventoryItem, inventory, quantities);
            const price = getItemPrice(inventoryItem, inventory, quantities);
            const key = [venue.id, reservationDate, ticketOptionId].join('-');
            return (
              <React.Fragment key={key}>
                <Layout horizontal stackable>
                  <Layout.Group>
                    <ResponsiveImage
                      className={this.getElementClass('image')}
                      src={inventoryItem.image || this.getTicketImage(venue)}
                      width={150}
                      height={150}
                    />
                  </Layout.Group>
                  <Layout.Group
                    className={this.getElementClass('ticket-details')}
                    grow>
                    <p className="large">{venue.name}</p>
                    <Spacer size="xs" />
                    <Layout horizontal spread stackable>
                      <Layout.Group
                        className={this.getElementClass('ticket-label')}>
                        Date:
                      </Layout.Group>
                      <Layout.Group
                        className={this.getElementClass('ticket-value')}>
                        {formatDate(reservationDate)}
                      </Layout.Group>
                    </Layout>
                    {startTimeName && (
                      <>
                        <Spacer size="xs" />
                        <Layout horizontal spread stackable>
                          <Layout.Group
                            className={this.getElementClass('ticket-label')}>
                            Session:
                          </Layout.Group>
                          <Layout.Group
                            className={this.getElementClass('ticket-value')}>
                            {startTimeName}
                          </Layout.Group>
                        </Layout>
                      </>
                    )}
                    <Spacer size="xs" />
                    <Layout horizontal spread stackable>
                      <Layout.Group
                        className={this.getElementClass('ticket-label')}>
                        {quantity} x {name}
                      </Layout.Group>
                      <Layout.Group
                        className={this.getElementClass('ticket-value')}>
                        {formatCurrency(price * quantity)}
                      </Layout.Group>
                    </Layout>
                  </Layout.Group>
                </Layout>
                <Divider className="light" />
              </React.Fragment>
            );
          });
        })}
        <Button onClick={this.setOrderReviewed} fluid primary>
          Go To Payment
        </Button>
      </React.Fragment>
    );
  }

  renderPaymentForm() {
    const { error, loading, discountValue, paymentIntent } = this.state;

    return (
      <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
        <Elements>
          <PaymentForm
            error={error}
            loading={loading}
            showPayment={this.props.subtotal > discountValue}
            paymentIntent={paymentIntent}
            onPaymentMethodReceived={this.onPaymentMethodReceived}
            onPaymentIntentSuccess={this.onPaymentIntentSuccess}
            onCardActionError={this.onError}
            onPaymentMethodError={this.onError}
          />
        </Elements>
      </StripeProvider>
    );
  }
}
