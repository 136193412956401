import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Loader, Container, Form, Button } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { Spacer, Hero } from 'public/components';
import { NotFound } from '../NotFound';
import { isValidEmail } from 'utils/helpers/validations';
import { FORMS_DREAM_DEBUT } from 'utils/env/client';
import { Layout, ContentfulRichText, SVGIcon as Icon } from 'common/components';
import { identify, track } from 'utils/analytics';
import { DEFAULT_GROUP_ID, SMS_GROUP_ID } from '../../../common/const';
const FORM_URL = `https://docs.google.com/forms/u/0/d/e/${FORMS_DREAM_DEBUT}/formResponse`;

import { Link } from 'react-router-dom';
import './dream-debut.less';
import { DesktopOnly, MobileOnly } from '../../../common/components';
const FIELDS = [
  {
    name: 'entry.119288407',
    label: 'First Name',
    type: 'text',
    required: true,
  },
  {
    name: 'entry.584097175',
    label: 'Last Name',
    type: 'text',
    required: true,
  },
  {
    name: 'entry.288326482',
    label: 'Email',
    type: 'email',
    validate: isValidEmail,
    required: true,
  },
  {
    name: 'entry.394851946',
    label: 'Phone',
    type: 'tel',
    required: true,
  },
  {
    name: 'entry.928319177',
    label: 'City',
    type: 'text',
  },
  {
    name: 'entry.605321084',
    label: 'State/Province',
    type: 'text',
  },
  {
    name: 'entry.2125887778',
    label: 'Group Name',
    type: 'text',
    required: true,
  },
  {
    name: 'entry.1552232235',
    label: 'Performance Type',
    type: 'text',
  },
  {
    name: 'entry.438007430',
    label: 'Number of people',
    type: 'number',
    required: true,
    min: 1,
  },
  {
    name: 'entry.1517942247',
    label: 'About you',
    type: 'textarea',
    placeholder: 'Please tell us more about your group and past performances',
  },
  {
    name: 'entry.1437635803',
    label: 'Dates',
    type: 'textarea',
    placeholder: 'List up to five preferred date and time availability',
  },
  {
    name: 'entry.2055030626',
    label: 'Link to video of past performances',
    type: 'text',
    required: true,
  },
];

@inject('pages', 'subscriptions')
export default class DreamDebut extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      error: null,
      touched: false,
      current: 'pending',
      emailAccepted: true,
      smsAccepted: false,
    };
  }

  async routeDidUpdate() {
    try {
      const slug = 'dream-debut';
      this.setState({
        page: await this.props.pages.fetchItemBySlug(slug),
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
        });
      } else {
        throw error;
      }
    }
  }

  onChange = (evt, data) => {
    const { name, value } = data;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = async () => {
    if (this.validate()) {
      this.setState({
        current: 'loading',
      });
      await this.send();
      this.sendInfoBraze();
      this.setState({
        current: 'complete',
      });
    } else {
      this.setState({
        touched: true,
      });
    }
  };

  sendInfoBraze = async () => {
    const email = this.state['entry.288326482'];
    const firstName = this.state['entry.119288407'];
    const lastName = this.state['entry.584097175'];
    const phone = this.state['entry.394851946'];
    const user = { name: `${firstName} ${lastName}`, email, phone };
    const customAttributes = { dreamdebut: 'yes' };

    identify({
      ...user,
      ...customAttributes,
    });
    track(`subscription dream debut form`);

    if (this.state.emailAccepted) {
      await this.props.subscriptions.create({
        ...user,
        group: DEFAULT_GROUP_ID,
      });
    }

    if (this.state.smsAccepted) {
      await this.props.subscriptions.create({
        ...user,
        group: SMS_GROUP_ID,
      });
    }
  };

  validate() {
    return FIELDS.every((field) => {
      const { name, required, validate } = field;
      const value = this.state[name] || '';
      return this.isValid(value, required, validate);
    });
  }

  isValid(value, required, validate) {
    if (required && !value.trim()) {
      return false;
    }
    if (validate && !validate(value)) {
      return false;
    }
    return true;
  }

  send() {
    return fetch(FORM_URL, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      mode: 'no-cors',
      method: 'POST',
      body: FIELDS.reduce((params, field) => {
        const { name } = field;
        const value = this.state[name] || '';
        params.append(name, value);
        return params;
      }, new URLSearchParams()).toString(),
    });
  }

  renderBody() {
    const { page, error } = this.state;

    if (error) {
      return <NotFound {...this.props} />;
    } else if (!page) {
      return <Loader inline="centered" active />;
    }
    return (
      <div {...this.getAttrs()}>
        {this.renderHero()}
        <Spacer size="m" desktop />
        <Container className="white">
          <Layout vertical center>
            <Spacer size="m" />
            <Container className="small">
              <h1>{page.name}</h1>
              <Spacer size="s" />
              <ContentfulRichText field={page.object.fields.body} />
              <Spacer size="s" />
              {this.renderForm()}
              <Spacer size="m" />
            </Container>
          </Layout>
        </Container>
      </div>
    );
  }

  renderForm() {
    if (this.state.current === 'pending') {
      return this.renderFormPending();
    } else if (this.state.current === 'loading') {
      return this.renderFormLoading();
    } else {
      return this.renderFormThankYou();
    }
  }

  renderFormLoading() {
    return (
      <React.Fragment>
        <Spacer size="l" />
        <Loader size="large" active inline="centered" />
        <Spacer size="l" />
      </React.Fragment>
    );
  }

  renderFormThankYou() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <h1>Thank you!</h1>
        <h3>Someone will be in contact with you shortly about your inquiry.</h3>
        <Spacer size="m" />
      </React.Fragment>
    );
  }

  renderFormPending() {
    const { loading } = this.state;
    return (
      <Form onSubmit={this.onSubmit} loading={loading} noValidate>
        {FIELDS.map(this.renderField, this)}
        <Form.Checkbox
          className="checkbox"
          name="emailAccepted"
          label={
            <>
              {
                'Sign me up for exclusive American Dream sweepstakes, promotions, emails, events, and updates. By checking this option you agree to our '
              }
              <Link className={this.getElementClass('terms-link')} to="/terms">
                Terms and Conditions
              </Link>
              {' and '}
              <Link
                className={this.getElementClass('privacy-link')}
                to="/privacy">
                Privacy Policy
              </Link>
              .
            </>
          }
          checked={this.state.emailAccepted}
          onChange={(e, { checked }) =>
            this.setState({ emailAccepted: checked })
          }
        />
        <Form.Checkbox
          className="checkbox"
          name="smsAccepted"
          label={
            <>
              {
                'Sign me up for SMS messages with news, updates and special promotions at the number provided above. Msg&Data rates may apply. Msg frequency varies. Reply HELP for help, STOP to cancel. By checking this option you agree to our '
              }
              <Link className={this.getElementClass('terms-link')} to="/terms">
                Terms and Conditions
              </Link>
              {' and '}
              <Link
                className={this.getElementClass('privacy-link')}
                to="/privacy">
                Privacy Policy
              </Link>
              .
            </>
          }
          checked={this.state.smsAccepted}
          onChange={(e, { checked }) => this.setState({ smsAccepted: checked })}
        />
        <Spacer size="xs" />
        <Button primary type="submit">
          Submit
        </Button>
      </Form>
    );
  }

  renderField(field) {
    const { name, label, type, required, validate, min, placeholder } = field;
    const { touched } = this.state;
    const value = this.state[name] || '';
    const error = touched && !this.isValid(value, required, validate);
    const props = {
      name,
      label,
      value,
      error,
      min,
      placeholder,
      onChange: this.onChange,
    };

    let input;
    if (
      type === 'text' ||
      type === 'email' ||
      type === 'tel' ||
      type === 'number'
    ) {
      input = <Form.Input {...props} type={type} />;
    } else if (type === 'textarea') {
      input = <Form.TextArea {...props} className="field" />;
    } else if (field.options) {
      input = (
        <Form.Dropdown
          {...props}
          fluid
          icon={<Icon name="angle-down" size="mini" />}
          placeholder="Select"
          options={field.options}
        />
      );
    }
    return (
      <React.Fragment key={name}>
        {input}
        <Spacer size="xs" />
      </React.Fragment>
    );
  }

  getHeroImages(hero) {
    if (!hero) return;
    return hero['en-US']
      .map((f) => {
        const { url, details } = f.fields.file['en-US'];
        let ratio;
        if (details.image) {
          ratio = details.image.width / details.image.height;
        } else {
          ratio = 1.3333;
        }
        return {
          url,
          ratio,
        };
      })
      .filter((url) => url);
  }

  renderHero() {
    const { page } = this.state;

    const heroDesktopImages = this.getHeroImages(page.object.fields.hero);
    const heroMobileImages = this.getHeroImages(page.object.fields.heroMobile);

    const [mainDesktop, fallbackDesktop] = heroDesktopImages;
    const [mainMobile, fallbackMobile] = heroMobileImages;
    return (
      <>
        {heroDesktopImages.length && (
          <DesktopOnly>
            <Hero media={mainDesktop} fallback={fallbackDesktop} />;
          </DesktopOnly>
        )}

        {heroMobileImages.length && (
          <MobileOnly>
            <Hero media={mainMobile} fallback={fallbackMobile} />;
          </MobileOnly>
        )}
      </>
    );
  }
}

DreamDebut.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

DreamDebut.defaultProps = {
  contentfulSlugItem: 'dream-debut',
};
