import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { ProductPropType } from 'public/schema';
import { useClassName } from 'common/hooks';
import CloseIcon from 'common/assets/ic-close.svg';
import './add-on-detail-modal.less';

const AddOnDetailModal = ({ product, open, handleClose }) => {
  const className = useClassName('AddOnDetailModal');

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={className('container')}>
        <div className={className('header')}>
          <span className={className('product-name')}>{product.name}</span>
          <div
            className={className('close-button-container')}
            onClick={handleClose}>
            <img
              className={className('close-button')}
              src={CloseIcon}
              alt="logo"
            />
          </div>
        </div>
        <div className={className('content')}>
          <div
            className={className('product-description')}
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        </div>
      </div>
    </Modal>
  );
};

AddOnDetailModal.propTypes = {
  product: PropTypes.shape(ProductPropType).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddOnDetailModal;
