import React from 'react';
import { Message } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { Spacer, ConsentDialog } from 'public/components';
import { SVGIcon as Icon } from 'common/components';
import { ProfileGroup } from '../components';

import './preferences.less';

export default class PrivacyPreferences extends Screen {
  state = {
    message: null,
  };

  onSave = () => {
    this.setState({
      message: {
        type: 'success',
        text: 'Preferences Saved',
      },
    });
  };

  renderBody() {
    return (
      <ProfileGroup {...this.props}>
        <h3>Privacy Preferences</h3>
        <Spacer size="s" />
        <section>
          {this.renderMessage()}
          <div className={this.getElementClass('title')}>
            <Icon
              name="chat"
              size="tiny"
              className={this.getElementClass('title-icon')}
            />
            Data Collection through Cookies: Set your Preferences
          </div>
          <Spacer size="s" />
          <ConsentDialog onSave={this.onSave} />
          <Spacer size="m" />
        </section>
      </ProfileGroup>
    );
  }

  renderMessage() {
    const { message } = this.state;
    if (message) {
      const { type, text } = message;
      return (
        <React.Fragment>
          <Message
            content={text}
            positive={type === 'success'}
            negative={type === 'error'}
          />
          <Spacer size="s" />
        </React.Fragment>
      );
    }
  }
}
