import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { SVGIcon } from 'common/components';
import { useClassName } from 'common/hooks';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { Calendar } from 'public/components/Calendar';

import './birthday-date-picker.less';

const BirthdayDatePicker = ({
  date,
  setDate,
  inputProps,
  showLabel,
  allowNearDates,
  error,
  openForm,
  onClose,
  onOpen,
  daysInAdvance,
}) => {
  const classNames = useClassName('BirthdayDatePicker');
  const [isOpen, setOpen] = useState(false);

  const openFormHandler = () => {
    setOpen(false);
    openForm();
  };

  return (
    <>
      <ControlledInputContainer
        label={showLabel ? <label htmlFor="date">Date</label> : null}
        addonLeft={<SVGIcon name="date" className={classNames('addon-left')} />}
        input={
          <input
            readOnly
            value={
              date
                ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
                : ''
            }
            placeholder="Select Date"
            {...inputProps}
          />
        }
        addonRight={
          <SVGIcon name="angle-down" className={classNames('addon-right')} />
        }
        hasFocus={isOpen}
        error={error}
        onClick={() => setOpen(true)}
        trigger={
          <Calendar
            initialDate={date}
            open={isOpen}
            updateSelectedDate={(date) => {
              setOpen(false);
              const isoDate = DateTime.fromJSDate(date).toISODate();
              setDate(isoDate);
              onClose(isoDate);
            }}
            closeModal={() => {
              setOpen(false);
              onClose();
            }}
            openModal={() => {
              setOpen(true);
              onOpen();
            }}
            footer={
              !allowNearDates && (
                <div className={classNames('footer')}>
                  <SVGIcon name="info" size="small" />
                  <div>
                    <p>
                      To complete checkout, we recommend booking your birthday
                      party at least {daysInAdvance} days in advance.
                    </p>
                    <p className={classNames('link')} onClick={openFormHandler}>
                      Do you wish to book earlier?
                    </p>
                  </div>
                </div>
              )
            }
          />
        }
      />
    </>
  );
};

BirthdayDatePicker.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func,
  inputProps: PropTypes.object,
  showLabel: PropTypes.bool,
  allowNearDates: PropTypes.bool,
  error: PropTypes.string,
  openForm: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

BirthdayDatePicker.defaultProps = {
  onClose: () => false,
  onOpen: () => false,
};

export default BirthdayDatePicker;
