import React from 'react';
import { Container, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Layout, ResponsiveImage, Content } from 'common/components';
import { Screen, withVenue } from 'public/helpers';
import {
  Headline,
  Hero,
  Spacer,
  AttractionsNav,
  AttractionScreen,
} from 'public/components';

import './home.less';

@withVenue('nickelodeon-universe')
export default class NickUniverseHome extends Screen {
  renderBody() {
    const { venue } = this.props;
    if (venue?.content?.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }

    return (
      <div {...this.getAttrs()}>
        <Hero contentfulImage="NickU - Graphic - Home Hero" cover />
        <AttractionsNav venue={this.props.venue} />
        <div className={this.getElementClass('venueBackground')}>
          <Container className={this.getElementClass('venueContainer')}>
            <ResponsiveImage
              className={this.getElementClass('slime')}
              contentfulName="NickU - Graphic - Slime"
              ratio={4.34}
              fluid
            />
            <Spacer size="s" />
            <div className={this.getElementClass('pageHeadline')}>
              <span>Our</span>
              &nbsp;
              <span className={this.getElementClass('pageHeadline-subtitle')}>
                Rides
              </span>
            </div>
            <Spacer size="s" />
            <Layout wrap horizontal center stackable>
              <Layout.Group>
                <Link
                  to="/venue/nickelodeon-universe/thrill"
                  className={this.getElementClass('card')}>
                  <ResponsiveImage
                    className={this.getElementClass('card-image')}
                    contentfulName="NickU - Card - Thrill"
                    ratio={0.65}
                    fluid
                  />
                  <div className={this.getElementClass('card-text')}>
                    Thrill Rides
                  </div>
                </Link>
              </Layout.Group>
              <Layout.Group>
                <Link
                  to="/venue/nickelodeon-universe/family"
                  className={this.getElementClass('card')}>
                  <ResponsiveImage
                    className={this.getElementClass('card-image')}
                    contentfulName="NickU - Card - Family"
                    ratio={0.65}
                    fluid
                  />
                  <div className={this.getElementClass('card-text')}>
                    Family
                    <br />
                    Friendly Rides
                  </div>
                </Link>
              </Layout.Group>
              <Layout.Group>
                <Link
                  to="/venue/nickelodeon-universe/kids"
                  className={this.getElementClass('card')}>
                  <ResponsiveImage
                    className={this.getElementClass('card-image')}
                    contentfulName="NickU - Card - Kids"
                    ratio={0.65}
                    fluid
                  />
                  <div className={this.getElementClass('card-text')}>
                    Just for
                    <br />
                    Tots Rides
                  </div>
                </Link>
              </Layout.Group>
            </Layout>
            <Spacer size="l" />
            <div className={this.getElementClass('promo-top')}>
              <div className={this.getElementClass('promo-top-text')}>
                <div className={this.getElementClass('promo-top-pageHeadline')}>
                  <Headline
                    style={{ textAlign: 'left' }}
                    title="Ditch the"
                    subtitle="FOMO"
                    flipped
                  />
                </div>
                <div className={this.getElementClass('promo-top-subtitle')}>
                  MEET YOUR NICKELODEON BFF'S SPONGEBOB SQUAREPANTS, TEENAGE
                  MUTANT NINJA TURTLES, DORA THE EXPLORER, AND BLUE'S CLUES IN
                  PERSON AT AMERICAN DREAM
                </div>
                <Spacer size="s" />
              </div>
              <ResponsiveImage
                className={this.getElementClass('promo-top-graphic')}
                contentfulName="NickU - Graphic - OMG"
                square
              />
            </div>
          </Container>
          <Link to="/venue/nickelodeon-universe/tickets">
            <ResponsiveImage
              contentfulName="NickU - Graphic - Promo"
              ratio={0.98}
              fluid
            />
          </Link>
          <Container className={this.getElementClass('venueContainer')}>
            <Layout wrap horizontal center stackable>
              <Layout.Group>
                <Modal
                  trigger={
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-link')}>
                        <ResponsiveImage
                          className={this.getElementClass('card-image')}
                          contentfulName="NickU - Card - Group Experiences"
                          ratio={0.65}
                          fluid
                        />
                        <div className={this.getElementClass('card-text')}>
                          <div className={this.getElementClass('card-bold')}>
                            Group
                          </div>
                          Experiences
                        </div>
                      </div>
                    </div>
                  }>
                  <Modal.Content>
                    <div className={this.getElementClass('modalContent')}>
                      <div
                        className={this.getElementClass('modalContent-title')}>
                        It’s party time!
                      </div>
                      <Content>
                        <p>
                          Ask us about our semi-private party areas available
                          now, making the park your own and all other
                          opportunities for groups of 10 or more.
                        </p>
                        <p>
                          Please complete{' '}
                          <Link to="/group-sales">this form.</Link>
                        </p>
                      </Content>
                    </div>
                  </Modal.Content>
                </Modal>
              </Layout.Group>
              <Layout.Group>
                <Link
                  to="/venue/nickelodeon-universe/faq"
                  className={this.getElementClass('card')}>
                  <ResponsiveImage
                    className={this.getElementClass('card-image')}
                    contentfulName="NickU - Card - Guest Services"
                    ratio={0.65}
                    fluid
                  />
                  <div className={this.getElementClass('card-text')}>
                    <div className={this.getElementClass('card-bold')}>
                      Guest
                    </div>
                    services
                  </div>
                </Link>
              </Layout.Group>
              <Layout.Group>
                <Link to="/signup" className={this.getElementClass('card')}>
                  <ResponsiveImage
                    className={this.getElementClass('card-image')}
                    contentfulName="NickU - Card - Create an Account"
                    ratio={0.65}
                    fluid
                  />
                  <div className={this.getElementClass('card-text')}>
                    <div className={this.getElementClass('card-bold')}>
                      Stay In
                    </div>
                    The Know
                  </div>
                </Link>
              </Layout.Group>
            </Layout>
            <Spacer size="m" />
          </Container>
        </div>
      </div>
    );
  }
}
