import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Navigation from 'swiper/modules/navigation.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

import {
  useClassName,
  useVisible,
  usePreload,
  preloadMedia,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import { useNext, usePrev } from '../../Components/useNavigation';
import { usePagination } from '../../Components/usePagination';
import HomepageImage from '../../Components/Image';
import HomepageButton from '../../Components/Button';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import './testimonials.less';

export default function TestimonialsSection({ section }) {
  const classNames = useClassName('TestimonialsSection');
  const [navigationPrevRef, prev] = usePrev({
    className: classNames('navigation'),
  });
  const [navigationNextRef, next] = useNext({
    className: classNames('navigation'),
  });
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  const ref = useRef(null);
  const isVisible = useVisible(ref);
  usePreload((isDesktop) => {
    preloadMedia(section.fields.testimonials, isDesktop);
  }, ref);

  useEffect(() => {
    if (isVisible) {
      trackHomepageSection(() => ({
        id: section.fields.sectionId,
      }));
    }
  }, [isVisible]);

  return (
    <div
      style={{
        background: `linear-gradient(180deg, #FFF 0%, #000 100%)`,
      }}
      ref={ref}
      id={section.fields.sectionId}>
      <div
        className={classNames('container')}
        style={{
          '--primary-color': section.fields.primaryColor,
          '--secondary-color': section.fields.secondaryColor,
          ...(!isVisible && { minHeight: '1000px' }),
        }}>
        {isVisible && (
          <>
            <h1>{section.fields.title}</h1>
            <Swiper
              modules={[Navigation, Pagination]}
              height={542}
              slidesPerView={1}
              loop={false}
              className={classNames('wrapper')}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              onSlideChange={({ activeIndex }) =>
                trackHomepageSlide(() => ({
                  activeIndex,
                  name: section.fields.testimonials[activeIndex].fields.name,
                  section: section.fields.sectionId,
                }))
              }
              {...pagination}>
              {section.fields.testimonials.map(({ fields, sys }) => (
                <SwiperSlide
                  key={sys.id}
                  className={classNames('slide-container')}>
                  <HomepageButton
                    cta={fields.callToAction}
                    className={classNames('slide')}>
                    <div>
                      <h3>{fields.title}</h3>
                      <div>{fields.description}</div>
                      <div>
                        <ContentfulRichText value={fields.caption} />
                        <HomepageImage field={fields.icon} />
                      </div>
                    </div>
                    <HomepageImage
                      field={fields.desktop}
                      mobile={fields.mobile}
                    />
                  </HomepageButton>
                </SwiperSlide>
              ))}
              {prev}
              {next}
            </Swiper>
          </>
        )}
      </div>
    </div>
  );
}
