import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Modal, Button } from 'semantic-ui-react';
import { useClassName } from 'common/hooks';

import './multi-value-text.less';

const MultiValueText = ({
  values,
  deleteConfirmationMessage,
  handleAdd,
  handleRemove,
}) => {
  const [textValue, setTextValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(null);

  const className = useClassName('MultiValueText');

  const handleTextKeyDown = (e) => {
    if (
      e.key === 'Enter' &&
      e.target.value.trim() &&
      !values.includes(e.target.value.trim())
    ) {
      handleAdd(e.target.value.trim());
      setTextValue('');
    }
  };

  const handleClose = () => {
    setIndex(null);
    setShowModal(false);
  };

  const handleDelete = (index) => {
    if (!deleteConfirmationMessage) {
      handleRemove(index);
      return;
    }
    setIndex(index);
    setShowModal(true);
  };

  const handleConfirmDeletion = () => {
    handleRemove(index);
    handleClose();
  };

  return (
    <>
      <div className={className('container')}>
        {values.map((value, index) => (
          <div key={`value-${index}`} className={className('value')}>
            {value}
            <Icon
              name="delete"
              size="small"
              onClick={() => handleDelete(index)}
              className={className('delete-icon')}
              color="red"
            />
          </div>
        ))}
        <Input
          type="text"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          onKeyDown={handleTextKeyDown}
          fluid
          className={className('text-input')}
          placeholder="Hit enter to add the value"
        />
      </div>

      <Modal open={showModal} onClose={handleClose} size="fullscreen">
        <Modal.Header>Deleting variant</Modal.Header>
        <Modal.Content>
          <p>{deleteConfirmationMessage}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={handleConfirmDeletion}>
            Delete
          </Button>
          <Button negative onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

MultiValueText.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  deleteConfirmationMessage: PropTypes.string,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

MultiValueText.defaultProps = {
  values: [],
  deleteConfirmationMessage: null,
};

export default MultiValueText;
