import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { useClassName } from 'common/hooks';
import { formatCurrency } from 'utils/l10n';
import ImageGallery from './ImageGallery';
import { SVGIcon as Icon } from 'common/components';

import './modal-gallery.less';

const ModalGallery = ({
  imageGallery,
  overlineText,
  title,
  body,
  price,
  open,
  handleClose,
}) => {
  const className = useClassName('ModularModalGallery');

  return (
    <div className={className('modal-container')}>
      <Modal
        onClose={handleClose}
        open={open}
        size="large"
        className={className('modal')}>
        <div className={className('container')}>
          <div className={className('close-icon')} onClick={handleClose}>
            <Icon name="close" size="mini" />
          </div>
          <div className={className('content')}>
            <div className={className('modal-text')}>
              {overlineText && (
                <p className={className('modal-text-overline')}>
                  {overlineText}
                </p>
              )}
              {title && (
                <p className={className('modal-text-title')}>{title}</p>
              )}
              {price && (
                <p className={className('modal-text-price')}>
                  {formatCurrency(price * 100, true)}
                </p>
              )}
              {body && <p className={className('modal-text-body')}>{body}</p>}
            </div>
            <div className={className('image-gallery-container')}>
              <ImageGallery imageGallery={imageGallery} showMiniatures={true} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ModalGallery.propTypes = {
  imageGallery: PropTypes.object.isRequired,
  overlineText: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  body: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ModalGallery.defaultProps = {
  open: false,
};

export default ModalGallery;
