import React from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import { SVGIcon } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';

import { parseRideMaintenanceSchedule } from '../utils';

import './ride-closures-modal.less';

const RideClosuresModal = ({ ridesCards, isOpen, onClose }) => {
  const { isMobile } = useWindowSize();
  const classNames = useClassName('RideClosuresModal');

  const items = parseRideMaintenanceSchedule(ridesCards);
  const content = (
    <div className={classNames('schedules')}>
      {items.map((item) => (
        <div
          key={item.label}
          {...(item.label === 'Closed now' &&
            items.length > 1 && {
              className: classNames('closed-now'),
            })}>
          <div className={classNames('date')}>{item.label}</div>
          {item.items.map((item) => (
            <div key={item.name} className={classNames('schedule')}>
              <img
                width={74}
                height={62}
                src={item.image.assetUrl}
                className={classNames('image')}
              />
              <div>
                <span>{item.reason}</span>
                <span>{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  if (isMobile) {
    return (
      <BottomSheet
        onClose={onClose}
        isOpen={isOpen}
        title="Ride Maintenance Schedule"
        className={classNames('container')}
        open={open}
        content={<div className={classNames('content')}>{content}</div>}
      />
    );
  }

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      size="small"
      className={classNames('container')}>
      <Modal.Content className={classNames('content')}>
        <div className={classNames('header')}>
          <h1>Ride Maintenance Schedule</h1>
          <div className={classNames('close')} onClick={onClose}>
            <SVGIcon name="close" />
          </div>
        </div>
        {content}
      </Modal.Content>
    </Modal>
  );
};

RideClosuresModal.propTypes = {
  ridesCards: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RideClosuresModal;
