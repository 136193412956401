import React from 'react';
import PropTypes from 'prop-types';

import { useClassName, useResize } from 'common/hooks';
import { DesktopOnly, MobileOnly } from 'common/components';

import { NewCheckoutFooter } from '../Footer';

import './container.less';

const Container = ({ content, summary, attrs, onHelpClick }) => {
  const { height } = useResize(document.querySelector('.mobile-nav'));
  const classNames = useClassName('NewCheckout');

  return (
    <div {...attrs} className={classNames('container')}>
      <div
        className={classNames('content')}
        style={{ minHeight: `calc(100vh - ${height}px)` }}>
        {content}
        <MobileOnly className={classNames('footer')}>
          <NewCheckoutFooter onHelpClick={onHelpClick} />
        </MobileOnly>
      </div>
      {summary}
      <DesktopOnly>
        <NewCheckoutFooter onHelpClick={onHelpClick} />
      </DesktopOnly>
    </div>
  );
};

Container.propTypes = {
  content: PropTypes.element.isRequired,
  summary: PropTypes.element.isRequired,
  attrs: PropTypes.object,
};

export default Container;
