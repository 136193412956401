import React from 'react';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import { SVGIcon } from 'common/components';

import './progress-bar.less';

const ProgressBar = ({ title, current, total, onClick }) => {
  const classNames = useClassName('NewCheckoutProgressBar');
  const { isMobile } = useWindowSize();
  if (!isMobile) return null;

  return (
    <div className={classNames('container')}>
      <div className={classNames('controls')}>
        {current > 1 && (
          <SVGIcon name="arrow-left" size="tiny" onClick={onClick} />
        )}
        <span>{title}</span>
      </div>
      <div className={classNames('bar')}>
        <div
          className={classNames('progress')}
          style={{ width: `${Math.round((current / total) * 100)}%` }}></div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  title: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  onClick: PropTypes.func,
};

export default ProgressBar;
