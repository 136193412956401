import React from 'react';
import { inject } from 'mobx-react';
import { Container, Loader } from 'semantic-ui-react';
import { AutoGrid } from 'common/components';
import { Spacer, Headline, Mast, VerticalCard } from 'public/components';
import { Screen } from 'public/helpers';

@inject('articles')
export default class ArticleList extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      articles: null,
      error: null,
    };
  }

  routeDidUpdate() {
    this.fetch();
  }

  async fetch() {
    this.setState({
      loading: true,
    });
    try {
      const { category } = this.props.match.params;
      this.setState({
        articles: await this.props.articles.fetchItems({
          articleCategory: category,
        }),
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          loading: false,
          error,
        });
      } else {
        throw error;
      }
    }
  }

  renderBody() {
    if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    }
    return (
      <Mast>
        <Mast.Top>
          <Container>
            <Spacer size="l" />
            <Headline title="American Dream" subtitle="Stories" inverted />
            <Spacer size="m" />
          </Container>
        </Mast.Top>
        <Mast.Content>
          <Container>
            <AutoGrid columns={3} padded stackable>
              {this.renderArticles()}
            </AutoGrid>
            <Spacer size="xl" />
          </Container>
        </Mast.Content>
      </Mast>
    );
  }

  renderArticles() {
    return this.state.articles.map((article) => {
      return (
        <VerticalCard
          key={article.id}
          image={article.hero[0]}
          href={`/article/${article.slug}`}
          category={article.articleCategories[0].name}
          date={article.date}
          title={article.name}
        />
      );
    });
  }
}
