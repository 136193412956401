import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { useClassName } from 'common/hooks';
import { ContentfulImage, ContentfulRichText } from 'common/components';

import { NewCheckoutBasketItem } from '../../../Components/BasketItem';

import './admission.less';

const Addon = ({ title, products, onEdit, onChangeQuantity, onRemove }) => {
  const classNames = useClassName('BirthdayReviewAddon');
  if (!products?.length) return null;

  return (
    <div className={classNames('wrapper')}>
      <h2 className={classNames('title')}>{title}</h2>
      {products.map((prod, index) => (
        <div className={classNames('container')} key={index}>
          <div className={classNames('header')}>
            <div className={classNames('header-image')}>
              <ContentfulImage field={prod.images} width={244} />
            </div>
            <div className={classNames('header-info')}>
              <span className={classNames('header-title')}>{prod.title}</span>
              <span className={classNames('header-session')}>
                <ContentfulRichText field={prod.description} />
              </span>
            </div>
            <Button className={classNames('header-edit')} onClick={onEdit}>
              Edit
            </Button>
          </div>
          <div className={classNames('list')}>
            {prod.variants.map((variant, index) => (
              <NewCheckoutBasketItem
                key={variant.name}
                index={index}
                name={variant.name}
                price={variant.price}
                quantity={variant.quantity}
                onRemove={() => onRemove(variant.productId)}
                onChangeQuantity={(quantity) => {
                  if (quantity === 0) {
                    onRemove(variant.productId);
                  } else {
                    onChangeQuantity(variant.productId, quantity);
                  }
                }}
                max={variant.max}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

Addon.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
  onEdit: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onRemove: PropTypes.func,
};

export default Addon;
