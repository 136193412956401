import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { WarningMessage } from 'public/components';
import { useClassName, useWindowSize } from 'common/hooks';
import {
  RideClosuresModal,
  hasRidesUnderMaintenance,
  DEFAULT_MAINTENANCE_REASON,
  TEMPORARILY_CLOSED_REASON,
} from 'public/components/AttractionScreen/RideClosures';
import { formatStartAndEndDate } from 'utils/helpers';
import { parseAttraction } from 'public/helpers/contentful/parser';

import './temporarily-closed.less';

function TemporarilyClosed({ venue, date }) {
  const classNames = useClassName('TemporarilyClosed');
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();

  if (!venue?.content?.attractionScreen) return null;
  if (!date) return null;

  const attractionScreen = parseAttraction(venue.content.attractionScreen);
  const selectedDate = DateTime.fromISO(date, {
    zone: 'America/New_York',
  }).startOf('day');

  const rides = (attractionScreen.ridesCards || []).filter(
    (ride) => ride.schedule
  );
  if (!hasRidesUnderMaintenance(rides)) return null;

  const schedule = rides
    .map(({ name, schedule }) => {
      if (schedule.temporarilyClosed) {
        return {
          name,
          isClosed: true,
          closedRange: '',
          closedReason: TEMPORARILY_CLOSED_REASON,
        };
      }

      const closedRange = schedule.closedDates.find(
        ({ startDate, endDate }) =>
          startDate <= selectedDate && endDate >= selectedDate
      );

      if (!closedRange) {
        return {
          name,
          isClosed: false,
          schedule,
        };
      }

      return {
        name,
        isClosed: true,
        closedRange: formatStartAndEndDate(
          closedRange.startDate,
          closedRange.endDate
        ),
        closedReason: closedRange.reason || DEFAULT_MAINTENANCE_REASON,
      };
    })
    .filter((a) => a.isClosed);

  return (
    <>
      <RideClosuresModal
        ridesCards={rides}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <WarningMessage
        className={classNames('wrapper')}
        content={
          <div className={classNames('container')}>
            <h4>Rides Temporarily Closed</h4>
            <div>
              {schedule.map((item) => (
                <div key={item.name} className={classNames('item')}>
                  {isMobile ? (
                    <div>
                      <span>
                        {item.closedReason}{' '}
                        {item.closedRange && `(${item.closedRange})`}
                      </span>
                      <span>{item.name}</span>
                    </div>
                  ) : (
                    <>
                      <div>
                        <span>{item.closedReason}</span>
                        <span>{item.name}</span>
                      </div>
                      <p>{item.closedRange}</p>
                    </>
                  )}
                </div>
              ))}
            </div>

            <p onClick={() => setIsOpen(true)}>View Ride Closure Schedule</p>
          </div>
        }
      />
    </>
  );
}

export default TemporarilyClosed;
