import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { Layout } from './components';
import { getQuantities, getInventory } from './helpers/orderUpdate';
import { parseApiDate } from 'utils/api';
import { getQuantity } from 'common/helpers';
import { mapQuantitiesToTickets } from './helpers/orderUpdate';
import { NewCheckoutAddonsSelector } from '../NewCheckout/Addons/Selector';
import './order-update-addons.less';

@inject('orderUpdate', 'ticketInventory', 'addons')
@observer
export default class OrderUpdateAddons extends Screen {
  constructor(props) {
    super(props);

    const { newAddons, currentTickets } = this.props.orderUpdate;

    this.state = {
      loading: true,
      availableAddons: [],
      newAddons,
      quantities: getQuantities(newAddons),
      currentQuantities: getQuantities(currentTickets),
      selectedCategory: 'All',
      reservationDate: null,
    };
  }

  async componentDidMount() {
    const { orderUpdate, ticketInventory } = this.props;

    if (!orderUpdate?.order?.id) {
      this.props.history.push('/profile/orders');
    }

    window.scrollTo(0, 0);

    const { currentQuantities } = this.state;
    const { newTickets, venue } = orderUpdate;
    const reservationDate = parseApiDate(newTickets[0].ticketOption.date);

    const availableAddons = getInventory(
      reservationDate,
      ticketInventory,
      venue.id
    )
      .filter((product) => product.addon)
      .map((addon) => ({
        ...addon,
        minimumQuantity: getQuantity(addon, currentQuantities),
      }));

    this.setState({
      availableAddons,
      loading: false,
      reservationDate,
    });
  }

  handleSetQuantity = (product, quantity) => {
    const { ticketInventory, orderUpdate } = this.props;
    const { venue } = orderUpdate;
    const { reservationDate, quantities } = this.state;
    quantities[product.ticketOptionId] = quantity;
    const addonsInventory = getInventory(
      reservationDate,
      ticketInventory,
      venue.id
    ).filter((product) => product.addon);

    quantities[product.ticketOptionId] = quantity;
    const newAddons = mapQuantitiesToTickets(
      addonsInventory,
      reservationDate,
      quantities
    );

    this.setState({
      quantities,
      newAddons,
    });
  };

  handleSave = () => {
    const { newAddons } = this.state;
    const { orderUpdate } = this.props;
    orderUpdate.setNewAddons(newAddons);
    this.props.history.push('/order-update');
  };

  renderBody() {
    const { loading, availableAddons } = this.state;

    const links = [
      { url: '/profile/orders', title: 'My bookings' },
      { url: '/order-update', title: 'Order detail' },
      { url: '/order-update-addons', title: 'Add-ons' },
    ];

    return (
      <Layout links={links}>
        {loading ? (
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        ) : (
          <div className={this.getElementClass('container')}>
            <NewCheckoutAddonsSelector
              setQuantity={this.handleSetQuantity}
              addons={availableAddons}
              addonsStore={this.props.addons}
              quantities={this.state.quantities}
              blockDecrease
            />
            <Button
              className={this.getElementClass('save-button')}
              onClick={this.handleSave}
              inverted>
              Save changes
            </Button>
          </div>
        )}
      </Layout>
    );
  }
}
