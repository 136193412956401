import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { Screen } from 'public/helpers';
import { NotFound, InfoMessage, WarningMessage } from 'public/components';

import { BirthdayCheckoutContainer } from '../BirthdayCheckoutContainer';
import { NewCheckoutSummary } from '../../Components/Summary';
import {
  getAdmissionSubtotal,
  getAdmissionTax,
  getAdmissionTotal,
} from '../Admission/getBirthdayAdmissionTotals';
import {
  getEntertainmentSubtotal,
  getEntertainmentTax,
  getEntertainmentTotal,
} from '../Entertainment/getBirthdayEntertainmentTotals';
import {
  getFoodAndBeverageSubtotal,
  getFoodAndBeverageTax,
  getFoodAndBeverageTotal,
} from '../BirthdayFoodAndBeverage/getBirthdayFoodAndBeverageTotals';
import {
  getMerchandiseSubtotal,
  getMerchandiseTax,
  getMerchandiseTotal,
} from '../Merchandise/getBirthdayMerchandiseTotals';
import { NewCheckoutPaymentContainer } from '../../Payment/StripePaymentContainer';
import { NewCheckoutTicketsHeader } from '../../Tickets/Header';
import { NewCheckoutPaymentForm } from '../../Payment/PaymentForm';
import { BirthdayBackButton } from '../BackButton';
import { trackBirthdayOrderCompleted } from 'common/helpers';
import { NewCheckoutProgressBar } from '../../Components/ProgressBar';
import { STEPS } from '../../const';

import './birthday-payment.less';

@inject('birthdays', 'venues', 'me', 'appSession')
@observer
export default class BirthdayPayment extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: null,
    };
  }

  async routeDidUpdate() {
    if (!this.props.appSession.isLoggedIn()) {
      this.setState({
        redirect: `/signup?return=${location.pathname}`,
      });
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.payment_intent) {
      try {
        const { id, error } = await this.props.birthdays.waitComplete(
          params.payment_intent
        );

        if (error) {
          throw new Error(error);
        }
        await this.props.birthdays.setTransactionDetails(id);
        this.setState({
          redirect: this.props.match.url.replace(
            'payment',
            'payment-confirmed'
          ),
        });
        return;
      } catch (ex) {
        this.setState({
          error: ex.message,
        });
      }
    }

    const { slug } = (this.props.match && this.props.match.params) || {};
    const { isEnabled } = await this.props.birthdays.getSettings(slug);

    if (!isEnabled) {
      this.setState({
        isEnabled: false,
      });
      return;
    }

    try {
      const {
        paymentIntentId,
        clientSecret,
        amountToPay,
        feeAmount,
        birthday,
      } = await this.props.birthdays.createPaymentIntent();

      this.setState({
        isEnabled: true,
        clientSecret,
        paymentIntentId,
        amountToPay,
        feeAmount,
        birthday,
      });
    } catch (e) {
      this.setState({
        error: e.message,
        isEnabled: true,
      });
    }
  }

  onSubmit = async (stripe, elements) => {
    this.setState({ loading: true });

    try {
      const { paymentIntentId, birthday } = this.state;
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: location.href,
        },
        redirect: 'if_required',
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      await this.props.birthdays.setTransactionDetails(result.paymentIntent.id);

      trackBirthdayOrderCompleted({ paymentIntentId, birthday });

      this.setState({
        redirect: this.props.match.url.replace('payment', 'payment-confirmed'),
      });
    } catch (ex) {
      this.setState({ error: ex.message, loading: false });
    }
  };

  getTotals() {
    const { admission, entertainment, foodAndBeverage, merchandise } =
      this.props.birthdays.checkout;
    const { selectedSession, numberOfGuests } = admission;

    const reservationTotal =
      getAdmissionTotal({ selectedSession, numberOfGuests }) +
      getEntertainmentTotal(entertainment) +
      getFoodAndBeverageTotal(foodAndBeverage);

    const merchandiseTotal = Math.round(getMerchandiseTotal(merchandise));

    return {
      subtotal:
        getAdmissionSubtotal({ selectedSession, numberOfGuests }) +
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal(merchandise),
      tax:
        getAdmissionTax({ selectedSession, numberOfGuests }) +
        getEntertainmentTax(entertainment) +
        getFoodAndBeverageTax(foodAndBeverage) +
        getMerchandiseTax(merchandise),
      ticketSubtotal: getAdmissionSubtotal({ selectedSession, numberOfGuests }),
      addonSubtotal:
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal(merchandise),
      merchandiseTotal,
      reservationTotal,
      total: Math.round(reservationTotal + merchandiseTotal),
    };
  }

  backToInfo = () => {
    this.setState({
      redirect: this.props.match.url.replace('payment', 'info'),
    });
  };

  renderSummary(stripe, elements) {
    const error = this.hasBreakingError()
      ? null
      : this.state.error && { errorMessage: this.state.error };
    const { amountToPay, feeAmount } = this.state;

    return (
      <NewCheckoutSummary
        stepNames={{
          tickets: 'Admission',
          addons: 'Add-ons',
          checkout: 'Details & Confirmation',
        }}
        totalName="DUE NOW"
        error={error}
        canSubmit={this.state.formCompleted}
        onSubmit={() => this.onSubmit(stripe, elements)}
        step={STEPS.Checkout}
        loading={this.state.loading}
        totals={{
          ...this.getTotals(),
          ...(amountToPay && { total: amountToPay }),
          ...(feeAmount && { fee: feeAmount }),
        }}
        submitText="Confirm Payment"
      />
    );
  }

  renderContent = () => {
    return (
      <>
        <BirthdayBackButton
          title="Back to Birthday Details"
          onClick={this.backToInfo}
        />
        <NewCheckoutProgressBar
          title="Details & Confirmation"
          total={3}
          current={3}
          onClick={this.backToInfo}
        />
        <NewCheckoutTicketsHeader showLogo={false} title="Payment" />
        {this.hasBreakingError() ? (
          <WarningMessage content={this.state.error} />
        ) : (
          <>
            <InfoMessage
              content="Pay a 50% deposit + Merchandise now to confirm your birthday and complete your payment after checkout when you get contacted."
              className={this.getElementClass('info')}
            />
            <NewCheckoutPaymentForm
              onComplete={(complete) =>
                this.setState({ formCompleted: complete })
              }
              onReady={() => this.setState({ loading: false })}
              hidePayment={false}
            />
          </>
        )}
      </>
    );
  };

  hasBreakingError() {
    const { isEnabled, clientSecret, error } = this.state;
    return isEnabled && !clientSecret && error;
  }

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const { isEnabled } = this.state;
    if (isEnabled === null) return null;
    if (!isEnabled) return <NotFound />;

    if (this.hasBreakingError()) {
      return (
        <BirthdayCheckoutContainer
          attrs={this.getAttrs()}
          content={this.renderContent()}
          summary={this.renderSummary()}
        />
      );
    }

    return (
      <NewCheckoutPaymentContainer clientSecret={this.state.clientSecret}>
        {({ stripe, elements }) => (
          <BirthdayCheckoutContainer
            attrs={this.getAttrs()}
            content={this.renderContent(false)}
            summary={this.renderSummary(stripe, elements)}
          />
        )}
      </NewCheckoutPaymentContainer>
    );
  }
}
