import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import { BirthdayAdmissionCard } from '../AdmissionCard';

import './birthday-admissions.less';

const BirthdayAdmissions = ({
  admissions,
  selectedAdmission,
  selectedSession,
  date,
  setDate,
  sessions,
  setSelectedSession,
  numberOfGuests,
  setNumberOfGuests,
  setSelectedAdmission,
  openForm,
  loadingSessions,
}) => {
  const classNames = useClassName('BirthdayAdmissions');

  return (
    <div className={classNames('container')}>
      {admissions.map((party, index) => {
        const guestsAvailable = selectedSession
          ? Math.min(
              party.maximumNumberOfGuests,
              (selectedSession?.additionalGuests?.capacityRemaining || 0) +
                selectedSession.guestsIncluded
            )
          : party.maximumNumberOfGuests;

        return (
          <BirthdayAdmissionCard
            key={index}
            images={party.images}
            title={party.title}
            description={party.description}
            maximumNumberOfGuests={party.maximumNumberOfGuests}
            guestsAvailable={guestsAvailable}
            date={date}
            setDate={(date) =>
              setDate(
                date,
                party.admissionProductIds,
                party.additionalGuestsProductIds
              )
            }
            sessions={sessions}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            numberOfGuests={numberOfGuests}
            setNumberOfGuests={setNumberOfGuests}
            onSelect={() => setSelectedAdmission(index)}
            selected={selectedAdmission === index}
            openForm={openForm}
            loadingSessions={loadingSessions}
            daysInAdvance={party.daysInAdvance}
          />
        );
      })}
    </div>
  );
};

BirthdayAdmissions.propTypes = {
  admissions: PropTypes.array,
  selectedAdmission: PropTypes.number,
  selectedSession: PropTypes.object,
  date: PropTypes.string,
  setDate: PropTypes.func,
  sessions: PropTypes.array,
  setSelectedSession: PropTypes.func,
  numberOfGuests: PropTypes.number,
  setNumberOfGuests: PropTypes.func,
  setSelectedAdmission: PropTypes.func,
  openForm: PropTypes.func,
  loadingSessions: PropTypes.bool,
};

export default BirthdayAdmissions;
