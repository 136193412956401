import React from 'react';
import { Component } from 'common/helpers';
import { Helmet } from 'react-helmet-async';
import { getCurrentLocaleCode } from 'utils/l10n/client';
import { inject } from 'mobx-react';
import { getContentfulField } from 'common/components';

@inject('pages')
export default class Adhelmet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'American Dream - Fantasy, Fashion, Food, Family, and Fun',
      description:
        'The unrivaled destination for indoor family entertainment - Nickelodeon theme park, DreamWorks Water Park, indoor skiing - located close to New York City',
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.setupRouteEvents();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.venue !== this.props.venue ||
      prevProps.event !== this.props.event ||
      prevProps.customMetadata !== this.props.customMetadata ||
      prevProps.contentfulSlugItem !== this.props.contentfulSlugItem
    ) {
      this.routeDidUpdate();
    }
  }

  async routeDidUpdate() {
    const { loading } = this.props;

    if (this._isMounted && !loading) {
      try {
        const { venue, event, customMetadata, contentfulSlugItem } = this.props;
        const { slug } = this.props.match ? this.props.match.params : {};
        const pageSlug = contentfulSlugItem || slug;
        let metaTitle, metaDescription;

        // if it has custom metadata
        if (customMetadata) {
          metaTitle = customMetadata.metaTitle;
          metaDescription = customMetadata.metaDescription;

          // if it is a venue, will have a venue prop
        } else if (venue) {
          const fields = venue.content;

          metaTitle = getContentfulField(fields.metaTitle);
          metaDescription = getContentfulField(fields.metaDescription);

          // if it is a event, will have a event prop
        } else if (event) {
          const { fields } = event.object;

          metaTitle = getContentfulField(fields.metaTitle);
          metaDescription = getContentfulField(fields.metaDescription);

          // if it is not a venue, can be a page with slug
        } else if (pageSlug) {
          const page = await this.props.pages.fetchItemBySlug(pageSlug);
          const { fields } = page.object;

          metaTitle = getContentfulField(fields.metaTitle);
          metaDescription = getContentfulField(fields.metaDescription);
        }
        if (metaTitle && metaDescription) {
          this.setState({
            title: metaTitle,
            description: metaDescription,
          });
        }
      } catch (error) {
        if (error.message === 'Not Found') {
          this.setState({
            error,
          });
        } else {
          throw error;
        }
      }
    }
  }

  renderCanonical() {
    return <link rel="canonical" href={location.href} />;
  }

  render() {
    return (
      <Helmet>
        <title>{this.state.title}</title>
        <meta name="description" content={this.state.description} />
        <html lang={getCurrentLocaleCode()} />
        {this.renderCanonical()}
      </Helmet>
    );
  }
}
