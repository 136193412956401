import React from 'react';
import { groupBy } from 'lodash';
import { Component } from 'common/helpers';

import {
  ContentfulImage,
  ContentfulRichText,
  ContentfulPlainText,
  getContentfulField,
  SVGIcon as Icon,
} from 'common/components';

import { Spacer } from 'public/components';

import './carousel.less';

export default class DreamWorksCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: null,
      currentIndex: 0,
    };
  }

  getModifiers() {
    return [this.props.offset ? 'offset' : null];
  }

  shiftIndex(groupItems, amt) {
    const { currentIndex } = this.state;
    const nextIndex = currentIndex + amt;
    if (nextIndex >= 0 && nextIndex < groupItems.length) {
      this.setState({
        currentIndex: nextIndex,
      });
    }
  }

  setTab(name) {
    this.setState({
      currentTab: name,
      currentIndex: 0,
    });
  }

  getOffset(groupItems, currentItem) {
    const index = groupItems.indexOf(currentItem);
    const transform = `translateX(-${index * 100 + 50}%)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  getCurrentTab(groups) {
    const { currentTab } = this.state;
    return currentTab || groups[0];
  }

  getGrouped() {
    const { items } = this.props;
    const grouped = groupBy(getContentfulField(items), (item) => {
      return getContentfulField(item.fields.type) || '';
    });
    const groups = Object.keys(grouped);
    const groupItems = grouped[this.getCurrentTab(groups)];
    return {
      groups,
      groupItems,
    };
  }

  render() {
    const { groups, groupItems } = this.getGrouped();
    return (
      <div {...this.getAttrs()}>
        {this.renderTabs(groups)}
        <Spacer size="xs" />
        <div className={this.getElementClass('items-container')}>
          {this.renderImageItems(groupItems)}
          {this.renderButtons(groupItems)}
          {this.renderTextItems(groupItems)}
        </div>
      </div>
    );
  }

  renderTabs(groups) {
    if (groups.length < 2) {
      return;
    }
    const currentTab = this.getCurrentTab(groups);
    return (
      <div className={this.getElementClass('tabs')}>
        {groups.map((name) => {
          const isSelected = name === currentTab;
          return (
            <div
              key={name}
              onClick={() => this.setTab(name)}
              className={this.getElementClass(
                'tab',
                isSelected ? 'selected' : null
              )}>
              {name}
            </div>
          );
        })}
      </div>
    );
  }

  renderButtons(groupItems) {
    if (groupItems.length < 2) {
      return;
    }
    return (
      <div className={this.getElementClass('buttons')}>
        {this.renderButton(groupItems, 'prev')}
        {this.renderButton(groupItems, 'next')}
      </div>
    );
  }

  renderButton(groupItems, type) {
    const { currentIndex } = this.state;
    let shift, icon, disabled;
    if (type === 'prev') {
      shift = -1;
      icon = 'angle-left';
      disabled = currentIndex === 0;
    } else {
      shift = 1;
      icon = 'angle-right';
      disabled = currentIndex === groupItems.length - 1;
    }
    return (
      <div
        onClick={() => this.shiftIndex(groupItems, shift)}
        className={this.getElementClass(
          'button',
          type,
          disabled ? 'disabled' : null
        )}>
        <Icon name={icon} size="tiny" />
      </div>
    );
  }

  renderTextItems(groupItems) {
    const { currentIndex } = this.state;
    const currentItem = groupItems[currentIndex];
    const offset = this.getOffset(groupItems, currentItem);
    return (
      <div className={this.getElementClass('items')}>
        {groupItems.map((item, i) => {
          const { fields } = item;
          const isCurrent = item === currentItem;
          return (
            <div
              key={i}
              className={this.getElementClass('item')}
              style={offset}>
              <Spacer size="s" />
              <div
                className={this.getElementClass(
                  'item-content',
                  isCurrent ? 'visible' : null
                )}>
                <div className={this.getElementClass('item-title')}>
                  <ContentfulPlainText field={fields.title} />
                </div>
                <Spacer size="xs" />
                <ContentfulRichText
                  className={this.getElementClass('item-body')}
                  field={fields.body}
                />
                <Spacer size="s" />
                {this.renderRestrictions(fields.restrictions)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderImageItems(groupItems) {
    const { currentIndex } = this.state;
    const currentItem = groupItems[currentIndex];
    const offset = this.getOffset(groupItems, currentItem);
    return (
      <div className={this.getElementClass('items')}>
        {groupItems.map((item, i) => {
          const { fields } = item;
          return (
            <div
              key={i}
              className={this.getElementClass('item')}
              style={offset}>
              <ContentfulImage
                className={this.getElementClass('item-image')}
                field={fields.image}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderRestrictions(restrictions) {
    restrictions = getContentfulField(restrictions);
    if (!restrictions || !restrictions.length) {
      return;
    }
    return (
      <div className={this.getElementClass('restrictions')}>
        {restrictions.map((restriction, i) => {
          const { fields } = restriction;
          return (
            <div key={i} className={this.getElementClass('restriction')}>
              <Icon name={getContentfulField(fields.icon)} size="tiny" />
              <span className={this.getElementClass('restriction-title')}>
                <ContentfulPlainText field={fields.title} />
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}
