import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

import { Layout, SVGIcon as Icon } from 'common/components';
import { useClassName } from 'common/hooks';
import './search-input.less';

const SearchInput = ({ input, setInput }) => {
  const className = useClassName('SearchInput');

  return (
    <Form className={className('search-form')}>
      <Layout horizontal center spread>
        <Layout.Group grow>
          <Layout horizontal center className={className('input-wrapper')}>
            <Layout.Group grow>
              <Input className={className('search-input')}>
                <input
                  placeholder="Search"
                  onChange={(evt) => setInput(evt.target.value)}
                  value={input}
                />
              </Input>
            </Layout.Group>

            <Layout.Group className={className('search-icon')}>
              <Icon name="search" size="tiny" />
            </Layout.Group>
          </Layout>
        </Layout.Group>
      </Layout>
    </Form>
  );
};

SearchInput.propTypes = {
  input: PropTypes.string.isRequired,
  setInput: PropTypes.func.isRequired,
};

export default SearchInput;
