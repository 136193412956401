import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import { Loader, Container, Button, Form, Divider } from 'semantic-ui-react';
import { sum } from 'lodash';
import {
  Layout,
  ContentfulImage,
  ContentfulRichText,
  DesktopOnly,
  MobileOnly,
  FormActions,
} from 'common/components';
import { Screen } from 'public/helpers';
import { formatApiDate } from 'utils/api';
import {
  AddOnsSelector,
  ErrorMessage,
  Spacer,
  Totals,
} from 'public/components';
import {
  getQuantity,
  getItemPrice,
  getItemTax,
  ticketSalesClosed,
} from 'common/helpers';

import './addons.less';

function getDefaultState(props) {
  const {
    checkout: {
      reservationDate,
      venue: { slug },
    },
  } = props.checkout;

  return {
    reservationDate,
    slug,
    loading: true,
    redirect: null,
    venue: null,
    addons: null,
    quantities: {},
    sessions: null,
    startTime: null,
    cartItems: null,
    isAttraction: false,
  };
}

@inject('checkout', 'venues', 'cart', 'ticketInventory')
@observer
export default class VenueAddOns extends Screen {
  state = getDefaultState(this.props);

  routeDidUpdate() {
    this.fetchTickets();
  }

  async fetchTickets() {
    const {
      checkout: { reservationDate, addons },
    } = this.props.checkout;
    const venue = await this.props.venues.fetchItemBySlug(this.state.slug);

    const cartItems = await this.props.cart.getItems();

    await this.validateTicketsFromCart(venue, reservationDate, cartItems);
    const quantities = await this.getInitialQuantities(cartItems);

    const sessions = this.props.ticketInventory.getSessions(
      venue.id,
      reservationDate
    );

    const isAttraction = venue?.venueType === 'attraction';

    this.setState({
      loading: false,
      reservationDate,
      venue,
      addons,
      quantities,
      sessions,
      cartItems,
      isAttraction,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = getDefaultState(props);
    if (newState.slug !== state.slug) {
      return newState;
    }
    return null;
  }

  onSubmit = (inventory) => {
    const { venue, sessions } = this.state;
    const reservationDate = formatApiDate(this.state.reservationDate);
    const { checkout } = this.props.checkout;

    const session =
      sessions &&
      sessions.find((session) => session.startTime === checkout.startTime);

    let tickets = inventory.map((item) => {
      const { ticketOptionId, bookingItemId, externalId, name, date, price } =
        item;
      return {
        name,
        date,
        price,
        venueId: venue.id,
        venueName: venue.name,
        externalId,
        bookingItemId,
        ticketOptionId,
        reservationDate,
        startTime: session?.startTime,
        startTimeName: session?.name,
        quantity: this.getQuantity(item),
      };
    });
    tickets = tickets.filter((t) => t.quantity > 0);

    tickets.forEach((ticket) => this.props.cart.addTicket(ticket));

    this.setRedirect('/cart');
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
    });
  };

  renderAddOns() {
    const { venue, addons } = this.state;
    const {
      checkout: { startTime },
    } = this.props.checkout;

    if (venue?.openingHours?.temporarilyClosed) {
      return (
        <Layout.Group>
          <div className={this.getElementClass('closed-message')}>
            <ContentfulRichText field={venue.content.closedMessage} />
          </div>
        </Layout.Group>
      );
    }

    return (
      <AddOnsSelector
        addons={addons}
        setQuantity={this.setQuantity}
        startTime={startTime}
        addonTitle={venue?.content?.addonTitle}
      />
    );
  }

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    if (this.state.loading) {
      return (
        <>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </>
      );
    }

    const { venue } = this.state;

    return (
      <div {...this.getAttrs()}>
        <Container>
          <div className={this.getElementClass('selector')}>
            <Layout
              className={this.getElementClass('header')}
              horizontal
              center
              spread
              padded>
              <Layout.Group>
                {venue?.content?.logo && (
                  <ContentfulImage field={venue.content.logo} width={130} />
                )}
              </Layout.Group>
              <Layout.Group>
                {this.state.isAttraction && (
                  <Button
                    as={Link}
                    to={`/venue/${this.state.slug}`}
                    inverted
                    size="small">
                    Attraction Details
                  </Button>
                )}
              </Layout.Group>
            </Layout>
            <Layout horizontal={false} center spread>
              {this.renderSelector()}
            </Layout>
          </div>
        </Container>
        <Spacer size="l" />
      </div>
    );
  }

  renderActions = () => {
    return (
      <Button primary fluid>
        Continue to basket
      </Button>
    );
  };

  setQuantity = (item, quantity) => {
    const { quantities } = this.state;
    quantities[item.ticketOptionId] = quantity;

    const canSubmit = this.validateZeroItems(quantities);

    this.setState({
      quantities,
      canSubmit,
    });
  };

  async validateTicketsFromCart(venue, reservationDate, cartItems) {
    if (!cartItems?.length) {
      this.state.isAttraction
        ? this.setRedirect(`/venue/${venue.slug}/tickets`)
        : this.setRedirect(`/promo/${venue.slug}`);
      return;
    }

    const resDate = formatApiDate(reservationDate);

    const validCartItems = cartItems.filter(
      (cartItem) =>
        cartItem.venueId === venue.id && cartItem.reservationDate === resDate
    );

    if (!validCartItems?.length) {
      this.state.isAttraction
        ? this.setRedirect(`/venue/${venue.slug}/tickets`)
        : this.setRedirect(`/promo/${venue.slug}`);
    }
  }

  async getInitialQuantities(cartItems) {
    let quantities = {};

    if (!cartItems?.length) return quantities;

    cartItems.forEach((cartItem) => {
      const { tickets } = cartItem;

      if (!tickets?.length) return;

      tickets.forEach((ticket) => {
        const { ticketOptionId } = ticket;
        quantities[ticketOptionId] = 0;
      });
    });
    return quantities;
  }

  validateZeroItems(quantities) {
    const { addons } = this.state;
    if (!addons?.length || !quantities) return false;
    return addons.some((addon) => quantities[addon.ticketOptionId] > 0);
  }

  validate() {
    const { venue, reservationDate, quantities } = this.state;
    let canSubmit = false;

    if (ticketSalesClosed(venue, reservationDate)) {
      return {
        canSubmit,
        errorMessage: 'Please select a date in the future',
        errorTitle: 'Same-day ticket sales are now closed.',
      };
    }

    canSubmit = this.validateZeroItems(quantities);

    return { canSubmit };
  }

  getTicketCount(inventory) {
    return sum(inventory.map((item) => this.getQuantity(item)));
  }

  getSubtotal(inventory) {
    const { quantities } = this.state;
    return sum(
      inventory.map((item) => {
        const quantity = this.getQuantity(item);
        return getItemPrice(item, inventory, quantities) * quantity;
      })
    );
  }

  getTax(inventory) {
    const { quantities } = this.state;
    return sum(
      inventory.map((item) => {
        const quantity = this.getQuantity(item);
        return getItemTax(item, inventory, quantities) * quantity;
      })
    );
  }

  getQuantity(item) {
    const quantity = getQuantity(item, this.state.quantities);
    return quantity;
  }

  renderTotals(inventory) {
    return (
      <Totals
        count={this.getTicketCount(inventory)}
        subtotal={this.getSubtotal(inventory)}
        subtotalLabel="Tickets"
        tax={this.getTax(inventory)}
      />
    );
  }

  renderError(errorMessage, errorTitle) {
    if (errorMessage) {
      return (
        <>
          <Spacer size="s" />
          <ErrorMessage header={errorTitle} content={errorMessage} />
        </>
      );
    }
  }

  renderSelector() {
    const { venue, addons } = this.state;
    const inventory = this.props.ticketInventory.get(
      venue.id,
      this.state.reservationDate
    );

    if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="l" />
          <Loader size="large" inline="centered" active />
          <Spacer size="l" />
        </React.Fragment>
      );
    }

    const { errorMessage, errorTitle } = this.validate();

    return (
      <div>
        <Form onSubmit={() => this.onSubmit(addons)}>
          <Layout horizontal stackable>
            <Layout.Group className={this.getElementClass('addons')} grow>
              <Spacer size="s" />
              <div>
                {this.renderAddOns()}
                <MobileOnly>
                  <Spacer size="s" />
                  <Divider className="light" />
                  <h5 htmlFor="cost">Cost</h5>
                  <Spacer size="xs" />
                  {this.renderTotals(inventory)}
                  {this.renderError(errorMessage, errorTitle)}
                  <FormActions>{this.renderActions()}</FormActions>
                  <Spacer size="xs" />
                </MobileOnly>
              </div>
            </Layout.Group>
            <Layout.Group className={this.getElementClass('totals')}>
              <DesktopOnly>
                <Spacer size="m" />
                {this.renderTotals(inventory)}
                {this.renderError(errorMessage, errorTitle)}
                <Spacer size="xs" />
                <FormActions>{this.renderActions()}</FormActions>
                <Spacer size="xs" />
              </DesktopOnly>
            </Layout.Group>
          </Layout>
        </Form>
      </div>
    );
  }
}
