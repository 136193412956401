import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRenderer from 'react-markdown-renderer';

import { Content } from '../Content';
import getContentfulField from './getContentfulField';
import { useClassName } from 'common/hooks';

import './markdown.less';

const ContentfulMarkdown = ({ field, ...rest }) => {
  const className = useClassName('Markdown');

  const content = getContentfulField(field);

  return (
    <Content className={className('container')} {...rest}>
      <MarkdownRenderer
        markdown={content || ''}
        options={{ breaks: true, html: true }}
      />
    </Content>
  );
};

ContentfulMarkdown.PropTypes = {
  field: PropTypes.object.isRequired,
};

export default ContentfulMarkdown;
