export const EVENT_CATEGORIES = [
  {
    name: 'Community',
    slug: 'community',
  },
  {
    name: 'Music & Entertainment',
    slug: 'music-entertainment',
  },
  {
    name: 'Food & Drink',
    slug: 'food-drink',
  },
  {
    name: 'Charity & Causes',
    slug: 'charity-causes',
  },
  {
    name: 'Workshops & DIY',
    slug: 'workshops-diy',
  },
  {
    name: 'Thought Leadership & Networking',
    slug: 'thought-leadership-networking',
  },
  {
    name: 'Family-friendly',
    slug: 'family-friendly',
  },
  {
    name: 'Free',
    slug: 'free',
  },
  {
    name: 'Health & Wellness',
    slug: 'health-wellness',
  },
  {
    name: 'Fashion & Shopping',
    slug: 'fashion-shopping',
  },
];
