import React from 'react';
import { Container, Button } from 'semantic-ui-react';
import {
  Layout,
  ExternalLink,
  ScrollWaypoint,
  ContentfulImage,
  ContentfulPlainText,
  ContentfulRichText,
  getContentfulField,
  Video,
  DesktopOnly,
  MobileOnly,
  MapKitMap as Map,
} from 'common/components';
import { Screen, withVenue } from 'public/helpers';
import {
  Spacer,
  AttractionsNav,
  NearbyVenues,
  AttractionScreen,
} from 'public/components';

import './home.less';

const CIRCLE_ITEMS = [
  {
    videoName: 'Blacklight - Video - Mini Golf',
    imageUrl: 'Blacklight - Image - Mini Golf',
  },
  {
    videoName: 'Blacklight - Video - Mirror Maze',
    imageUrl: 'Blacklight - Image - Mirror Maze',
  },
];

@withVenue('blacklight-mini-golf')
export default class BlacklightHome extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      focusedFloor: props.venue.floorLevel || 1,
    };
  }

  onFloorUpdate = (floor) => {
    this.setState({
      focusedFloor: floor,
    });
  };

  renderMapCallout = () => {
    const { venue } = this.props;
    return (
      <div>
        <p className={this.getElementClass('map-callout-title')}>
          {venue.name}
        </p>
        <p className={this.getElementClass('map-callout-subtitle')}>
          {venue.locationDescription}
        </p>
      </div>
    );
  };

  updateInfoBlock(index, name, value) {
    const key = `infoBlock${index}`;
    const newState = Object.assign({}, this.state[key], {
      [name]: value,
    });
    this.setState({
      [key]: newState,
    });
  }

  getScrollModifier(index) {
    const { entered } = this.getInfoBlockState(index);
    return this.getSlideModifier(entered);
  }

  getSlideModifier(val) {
    if (val === true) {
      return 'in';
    } else if (val === false) {
      return 'out';
    } else {
      return null;
    }
  }

  getInfoBlockState(index) {
    return this.state[`infoBlock${index}`] || {};
  }

  renderBody() {
    const { venue } = this.props;
    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }
    return (
      <div {...this.getAttrs()}>
        <div className={this.getElementClass('hero')}>
          <DesktopOnly>
            <ContentfulImage field={venue.content.hero} fluid />
          </DesktopOnly>
          <MobileOnly>
            <ContentfulImage field={venue.content.heroMobile} fluid />
          </MobileOnly>
        </div>
        <AttractionsNav venue={venue} />
        <div className={this.getElementClass('main-block')}>
          <Spacer size="m" />
          <Container>
            <div className={this.getElementClass('main-title')}>
              BLACKLIGHT <b>MINI GOLF</b>
            </div>
            <Spacer size="xs" />
            <div className={this.getElementClass('main-subtitle')}>
              Get lost in the depths of space in our Blacklight Mini Golf
              experience, fun for the entire family. With 18 holes to challenge
              and delight you, you can take your mini golf game to a whole new
              dimension.
            </div>
            <Spacer size="l" />
          </Container>
        </div>
        <div className={this.getElementClass('info-blocks')}>
          <Container>
            <Spacer size="m" />
            {this.renderInfoBlocks()}
          </Container>
        </div>
        {/* {this.renderMap()} */}
        <Container>
          <Spacer size="m" />
          <NearbyVenues venue={venue} />
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderInfoBlocks() {
    const { venue } = this.props;
    return (
      <div>
        {getContentfulField(venue.content.infoBlocks).map((infoBlock, i) => {
          return this.renderInfoBlock(infoBlock, i);
        })}
      </div>
    );
  }

  renderInfoBlock(infoBlock, index) {
    const { fields } = infoBlock;
    const reverse = index % 2 === 0;
    return (
      <div key={index} className={this.getElementClass('info-block')}>
        <ScrollWaypoint
          onEnter={() => this.updateInfoBlock(index, 'entered', true)}
          onLeave={() => this.updateInfoBlock(index, 'entered', false)}>
          <Layout reversed={reverse} horizontal center stackable extra padded>
            {/* {this.renderInfoBlockImage(index, fields)} */}
            {this.renderInfoBlockVideo(index, fields)}
            {this.renderInfoBlockText(index, fields)}
          </Layout>
        </ScrollWaypoint>
      </div>
    );
  }

  renderInfoBlockText(index, fields) {
    const mod = this.getScrollModifier(index);
    const linkHref = getContentfulField(fields.link);
    return (
      <Layout.Group className={this.getElementClass('slide-text', mod)}>
        <ContentfulRichText
          className={this.getElementClass('info-block-title')}
          field={fields.title}
        />
        <Spacer size="xs" />
        <ContentfulRichText
          className={this.getElementClass('info-block-body')}
          field={fields.body}
        />
        <Spacer size="s" />
        <Button
          as={ExternalLink}
          href={linkHref}
          onMouseEnter={() => this.updateInfoBlock(index, 'hovering', true)}
          onMouseLeave={() => this.updateInfoBlock(index, 'hovering', false)}>
          <ContentfulPlainText field={fields.linkText} />
        </Button>
      </Layout.Group>
    );
  }

  renderInfoBlockVideo(index) {
    const mod = this.getScrollModifier(index);
    const item = CIRCLE_ITEMS[index];
    const { hovering } = this.getInfoBlockState(index);
    return (
      <React.Fragment>
        <DesktopOnly>
          <Layout.Group className={this.getElementClass('slide-image', mod)}>
            <Video
              className={this.getElementClass(
                'info-block-image',
                hovering ? 'hover' : null
              )}
              contentfulName={item.videoName}
              controls={false}
              autoPlay
              loop
              poster={item.imageUrl}
              muted={true}
            />
          </Layout.Group>
        </DesktopOnly>
        <MobileOnly>
          <Layout.Group className={this.getElementClass('slide-image', mod)}>
            <Video
              className={this.getElementClass(
                'info-block-image',
                hovering ? 'hover' : null
              )}
              contentfulName={item.videoName}
              controls={true}
              loop
              muted={true}
              playsInline={true}
              poster={item.imageUrl}
            />
          </Layout.Group>
        </MobileOnly>
      </React.Fragment>
    );
  }

  renderInfoBlockImage(index, fields) {
    const mod = this.getScrollModifier(index);
    const linkHref = getContentfulField(fields.link);
    const { hovering } = this.getInfoBlockState(index);
    return (
      <Layout.Group className={this.getElementClass('slide-image', mod)}>
        <ExternalLink href={linkHref}>
          <ContentfulImage
            className={this.getElementClass(
              'info-block-image',
              hovering ? 'hover' : null
            )}
            field={fields.image}
            ratio={1}
          />
        </ExternalLink>
      </Layout.Group>
    );
  }

  renderMap() {
    const { venue } = this.props;
    if (venue.location) {
      const { lat, lng } = venue.location;
      return (
        <Map
          className={this.getElementClass('map')}
          annotation={{
            lat,
            lng,
            floor: venue.floorLevel,
            renderCallout: this.renderMapCallout,
            selected: true,
          }}
          focusedVenue={venue}
          focusedFloor={this.state.focusedFloor}
          onFloorUpdate={this.onFloorUpdate}
          renderZoomButtons
          removeControls
        />
      );
    }
  }
}
