import React from 'react';
import { Button, Checkbox, Container, Form, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import MarkdownRenderer from 'react-markdown-renderer';
import { Component } from 'common/helpers';
import { isValidEmail, isValidPhone } from 'utils/helpers/validations';
import { captureError } from 'utils/sentry';
import { identify, track } from 'utils/analytics';
import { Spacer } from '../Spacer';
import {
  getContentfulField,
  getContentfulAssetUrlandAlt,
  ContentfulRichText,
  MobileOnly,
  DesktopOnly,
  TelInput,
  SVGIcon as Icon,
} from 'common/components';

import './section.less';

const FORM_STATUS = {
  loading: 'loading',
  pending: 'pending',
  completed: 'completed',
};

@inject('subscriptions')
@observer
export default class SubscriptionSection extends Component {
  formRef = null;

  constructor(props) {
    super(props);

    const subscriptionConfiguration = getContentfulField(
      props.section.fields.subscriptionConfiguration
    );

    const {
      fields,
      groups,
      terms,
      button,
      customAttributes,
      confirmationTitle,
      confirmationMessage,
      additionalMessage,
    } = subscriptionConfiguration;

    const checkedGroups = groups.reduce((reducer, group) => {
      return {
        ...reducer,
        [group.id]: true,
      };
    }, {});

    this.state = {
      page: null,
      error: null,
      touched: false,
      current: FORM_STATUS.pending,
      termsAccepted: false,
      fields,
      groups,
      terms,
      button,
      customAttributes,
      confirmationTitle,
      confirmationMessage,
      additionalMessage,
      ...checkedGroups,
    };
  }

  onTelChange = (phone) => {
    this.setState({
      phone,
    });
  };

  onChange = (evt, data) => {
    const { target } = evt;

    let value;
    let name;

    if (target.type === 'checkbox') {
      value = target.checked;
      name = target.name;
    } else {
      name = data.name;
      value = data.value;
    }

    this.setState({
      [name]: value,
    });
  };

  onFieldChange = (evt) => {
    const { target } = evt;
    let value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else {
      value = target.value;
    }
    this.setState({
      [target.name]: value,
    });
  };

  onSubmit = async () => {
    if (this.validate()) {
      if (this.formRef) {
        window.scrollBy(0, this.formRef.getBoundingClientRect().top);
      }

      this.setState({
        current: FORM_STATUS.loading,
      });

      try {
        await this.send();

        this.setState({
          current: FORM_STATUS.complete,
        });
      } catch (err) {
        this.setState({
          error: err.message
            ? err.message
            : 'Something went wrong. Please try again',
          current: FORM_STATUS.pending,
          touched: true,
        });

        captureError(err, {
          state: this.state,
        });
      }
    } else {
      this.setState({
        touched: true,
      });
    }
  };

  send = async () => {
    const { subscriptions, section } = this.props;
    const name = getContentfulField(section.fields.name);

    const user = this.state.fields.reduce((reducer, item) => {
      return {
        ...reducer,
        [item.name]: this.state[item.name],
      };
    }, {});

    identify({ ...user, ...this.state.customAttributes });
    track(`subscription ${name}`);

    for (const group of this.state.groups) {
      if (this.state[group.id]) {
        await subscriptions.create({
          ...user,
          group: group.id,
        });
      }
    }
  };

  hasError(label, value, required, type) {
    if (
      (required && !value) ||
      (type === 'email' && !isValidEmail(value)) ||
      (type === 'tel' && !isValidPhone(value))
    ) {
      return `Please enter a valid ${label}`;
    }

    return null;
  }

  validate() {
    return this.state.fields.every((field) => {
      const { name, label, required, type } = field;
      const value = this.state[name] || '';
      const error = this.hasError(label, value, required, type);
      this.setState({ error });

      return !error;
    });
  }

  renderFormLoading() {
    return (
      <React.Fragment>
        <Spacer size="l" />
        <Loader size="large" active inline="centered" />
        <Spacer size="l" />
      </React.Fragment>
    );
  }

  renderFormThankYou() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <h4>{this.state.confirmationTitle}</h4>
        <Spacer size="s" />
        <p>{this.state.confirmationMessage}</p>
        <Spacer size="m" />
      </React.Fragment>
    );
  }

  renderAgreements() {
    return (
      <>
        {this.state.terms && (
          <Checkbox
            id="termsAccepted"
            name="termsAccepted"
            checked={this.state.termsAccepted}
            className={this.getElementClass('agreement-check')}
            onChange={this.onChange}
            label={<MarkdownRenderer markdown={this.state.terms} />}
          />
        )}
        {this.state.groups.map((group) => {
          return (
            <Checkbox
              key={group.id}
              id={group.id}
              name={group.id}
              checked={this.state[group.id]}
              className={this.getElementClass('agreement-check')}
              onChange={this.onChange}
              label={<MarkdownRenderer markdown={group.label} />}
            />
          );
        })}
      </>
    );
  }

  renderField(field) {
    const { name, label, type, required, options, multiple } = field;
    const { touched } = this.state;
    const value = this.state[name] || '';
    const validation = touched && this.hasError(label, value, required, type);

    const props = {
      name,
      label,
      value,
      error: !!validation,
      options,
      onChange: this.onChange,
      multiple,
    };

    let input;
    if (type === 'text' || type === 'email') {
      input = <Form.Input {...props} type={type} />;
    } else if (type === 'tel') {
      input = (
        <Form.Field error={props.error}>
          <label htmlFor={props.name}>{props.label}</label>
          <TelInput
            id={props.name}
            name={props.name}
            onChange={this.onTelChange}
          />
        </Form.Field>
      );
    } else if (props.options) {
      input = (
        <Form.Dropdown
          {...props}
          fluid
          icon={<Icon name="angle-down" size="mini" />}
          placeholder={props.label || 'Select'}
          options={props.options}
        />
      );
    }

    return (
      <React.Fragment key={name}>
        {input}
        <Spacer size="xs" />
      </React.Fragment>
    );
  }

  renderFormPending() {
    const { touched, error } = this.state;
    return (
      <Form
        onSubmit={this.onSubmit}
        className={this.getElementClass('subscription-form')}>
        {this.state.fields.map(this.renderField, this)}
        {touched && error && (
          <span className={this.getElementClass('form-error')}>{error}</span>
        )}
        <div>{this.renderAgreements()}</div>
        <div>
          <Spacer size="s" />
          <Button
            primary
            type="submit"
            disabled={this.state.terms && !this.state.termsAccepted}
            className={this.getElementClass('subscription-button')}>
            {this.state.button}
          </Button>
          <Spacer size="m" />
          <MarkdownRenderer
            className={this.getElementClass('additional-message')}
            markdown={this.state.additionalMessage}
            options={{ breaks: true, html: true }}
          />
        </div>
      </Form>
    );
  }

  renderForm() {
    if (this.state.current === FORM_STATUS.pending) {
      return this.renderFormPending();
    } else if (this.state.current === FORM_STATUS.loading) {
      return this.renderFormLoading();
    } else {
      return this.renderFormThankYou();
    }
  }

  renderContent(section) {
    const theme = getContentfulField(section.fields.theme);

    return (
      <div
        ref={(ref) => {
          this.formRef = ref;
        }}>
        {section.fields.title && (
          <ContentfulRichText
            field={section.fields.title}
            className={`${this.getElementClass('title-directory')} ${theme}`}
          />
        )}
        {section.fields.description && (
          <div className={this.getElementClass('top-directory')}>
            <ContentfulRichText
              field={section.fields.description}
              className={`${this.getElementClass(
                'description-directory'
              )} ${theme}`}
            />
          </div>
        )}
        {this.renderForm(section)}
      </div>
    );
  }

  render() {
    const { section } = this.props;
    const name = getContentfulField(section.fields.name);

    let backgroundMobile, backgroundDesktop;

    if (section.fields.backgroundImageMobile) {
      const { assetUrl } = getContentfulAssetUrlandAlt(
        section.fields.backgroundImageMobile
      );
      backgroundMobile = assetUrl;
    }
    if (section.fields.backgroundImageDesktop) {
      const { assetUrl } = getContentfulAssetUrlandAlt(
        section.fields.backgroundImageDesktop
      );
      backgroundDesktop = assetUrl;
    }

    return (
      <div key={name} {...this.getAttrs()}>
        <Container>
          <DesktopOnly
            className={this.getElementClass('subscription-section')}
            style={{ backgroundImage: `url(${backgroundDesktop})` }}>
            <Container>{this.renderContent(section)}</Container>
          </DesktopOnly>
          <MobileOnly
            className={this.getElementClass('subscription-section')}
            style={{ backgroundImage: `url(${backgroundMobile})` }}>
            {this.renderContent(section)}
          </MobileOnly>
        </Container>
      </div>
    );
  }
}
