import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Icon } from 'semantic-ui-react';
import {
  DateRangePicker,
  defaultInputRanges,
  createStaticRanges,
} from 'react-date-range';
import {
  subDays,
  addDays,
  subYears,
  differenceInDays,
  setMonth,
  getMonth,
  setYear,
  getYear,
  endOfMonth,
  format,
} from 'date-fns';
import { useClassName } from 'common/hooks';
import {
  defaultStaticRanges,
  defaultStaticComparisonRanges,
} from './constants';

import './date-range-selector.less';

const SELECTION_KEY = 'selection';

const DateRangeSelector = ({
  initialRange,
  compareToRange,
  staticRanges,
  comparisonRanges,
  showInputRanges,
  maxSelectionDate,
  minSelectionDate,
  handleChange,
}) => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [rangeLabel, setRangeLabel] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [builtStaticRanges, setBuiltStaticRanges] = useState([]);
  const pickerRef = useRef(null);

  const className = useClassName('DateRangeSelector');

  useEffect(() => {
    if (compareToRange) setComparisonValues();
    else {
      setBuiltStaticRanges(
        buildStaticRanges(staticRanges || defaultStaticRanges)
      );

      const selectedRange =
        initialRange ||
        defaultStaticRanges.find((range) => range.label === 'This month');

      setSelectedRange([
        {
          startDate: selectedRange.startDate,
          endDate: selectedRange.endDate,
          key: SELECTION_KEY,
        },
      ]);

      setRangeLabel(selectedRange?.label);
    }
  }, []);

  useEffect(() => {
    if (!showDatePicker && selectedRange)
      handleChange({
        startDate: selectedRange[0].startDate,
        endDate: selectedRange[0].endDate,
      });
  }, [showDatePicker]);

  useEffect(() => {
    if (compareToRange) setComparisonValues();
  }, [compareToRange]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target))
      setShowDatePicker(false);
  };

  const setComparisonValues = () => {
    const comparisonStaticRanges = (
      comparisonRanges || defaultStaticComparisonRanges
    ).map(getComparisonStaticRange);

    setBuiltStaticRanges(buildStaticRanges(comparisonStaticRanges));

    const selectedRange =
      !rangeLabel && initialRange
        ? initialRange
        : getComparisonStaticRange(
            getComparisonSelectedRange(
              comparisonRanges || defaultStaticComparisonRanges
            )
          );

    setSelectedRange([
      {
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate,
        key: SELECTION_KEY,
      },
    ]);

    setRangeLabel(selectedRange?.label);
  };

  const getComparisonSelectedRange = (comparisonStaticRanges) => {
    const defaultRange = comparisonStaticRanges.find(
      (range) => range.label === 'Previous period'
    );
    if (!initialRange && !rangeLabel) return defaultRange;
    return (
      comparisonStaticRanges.find((range) => range.label === rangeLabel) ||
      defaultRange
    );
  };

  const getComparisonStaticRange = (range) => {
    if (range.isNoComparison)
      return {
        startDate: null,
        endDate: null,
        key: SELECTION_KEY,
        label: range.label,
      };

    const totalDays = differenceInDays(
      compareToRange.endDate,
      compareToRange.startDate
    );

    if (range.isPreviosPeriod)
      return {
        startDate: subDays(compareToRange.startDate, totalDays + 1),
        endDate: subDays(compareToRange.endDate, totalDays + 1),
        key: SELECTION_KEY,
        label: range.label,
      };

    if (range.minusType === 'month') {
      const newMonth = getMonth(compareToRange.startDate) - range.minusValue;
      const startDate =
        newMonth >= 0
          ? setMonth(compareToRange.startDate, newMonth)
          : setMonth(
              setYear(
                compareToRange.startDate,
                getYear(compareToRange.startDate) - 1
              ),
              11 + newMonth
            );
      const endDate = addDays(startDate, totalDays);
      return {
        startDate,
        endDate,
        key: SELECTION_KEY,
        label: range.label,
      };
    }

    const minusTypeFunction = range.minusType === 'day' ? subDays : subYears;

    return {
      startDate: subDays(
        minusTypeFunction(compareToRange.startDate, range.minusValue),
        1
      ),
      endDate: subDays(
        minusTypeFunction(compareToRange.endDate, range.minusValue),
        1
      ),
      key: SELECTION_KEY,
      label: range.label,
    };
  };

  const buildStaticRanges = (staticRanges) =>
    createStaticRanges(
      staticRanges.map((range) => ({
        label: range.label,
        range: () => ({
          startDate: range.startDate,
          endDate: range.endDate,
          label: range.label,
          key: SELECTION_KEY,
        }),
      }))
    );

  const handleSelect = (range) => {
    const { selection } = range;
    const { label } = selection;
    delete selection.label;

    setSelectedRange([selection]);

    if (label) {
      setRangeLabel(label);
      return;
    }

    setRangeLabel(
      `${format(range.selection.startDate, 'MM/dd/yyyy')} - ${format(
        range.selection.endDate,
        'MM/dd/yyyy'
      )}`
    );
  };

  return (
    <div className={className('container')}>
      <div
        className={className('range-label')}
        onClick={() => setShowDatePicker(!showDatePicker)}>
        <Icon
          name="calendar"
          size="small"
          className={className('range-icon')}
        />
        {rangeLabel}
      </div>
      {builtStaticRanges?.length && selectedRange && (
        <div
          className={className(
            'date-picker',
            showDatePicker ? 'open' : 'closed'
          )}
          ref={pickerRef}>
          <DateRangePicker
            ref={pickerRef}
            ranges={selectedRange}
            onChange={handleSelect}
            showSelectionPreview={true}
            staticRanges={builtStaticRanges}
            inputRanges={showInputRanges ? defaultInputRanges : []}
            minDate={minSelectionDate}
            maxDate={
              compareToRange ? compareToRange.startDate : maxSelectionDate
            }
          />
        </div>
      )}
    </div>
  );
};

DateRangeSelector.propTypes = {
  initialRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    label: PropTypes.string,
  }),
  compareToRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  staticRanges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    })
  ),
  comparisonRanges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isPreviousPeriod: PropTypes.bool,
      isNoComparison: PropTypes.bool,
      minusValue: PropTypes.number,
      minusType: PropTypes.oneOf(['month', 'year', 'day']),
    })
  ),
  inputRanges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    })
  ),
  maxSelectionDate: PropTypes.instanceOf(Date),
  minSelectionDate: PropTypes.instanceOf(Date),
  handleChange: PropTypes.func.isRequired,
};

DateRangeSelector.defaultProps = {
  showInputRanges: false,
  minSelectionDate: subDays(new Date(), 90),
  maxSelectionDate: endOfMonth(new Date()),
};

export default DateRangeSelector;
