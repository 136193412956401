import moment from 'moment';

const PRICE_TRANSFORM_VALUE = 100;

export const isBlackoutDate = (reservationDate, blackoutDates) => {
  return blackoutDates.includes(reservationDate);
};

export const isDateValid = (dateRange, reservationDate) => {
  const { startDate, endDate: rollerEndDate } = dateRange;

  // // update endDate to fix Roller bad config
  let endDate;

  if (rollerEndDate) {
    endDate = formatRollerDate(rollerEndDate);
  }

  if (
    startDate &&
    endDate &&
    !moment(reservationDate).isBetween(startDate, endDate)
  ) {
    return false;
  }

  if (
    startDate &&
    !endDate &&
    !moment(reservationDate).isSameOrAfter(startDate)
  ) {
    return false;
  }

  if (
    !startDate &&
    endDate &&
    !moment(reservationDate).isSameOrBefore(endDate)
  ) {
    return false;
  }

  return true;
};

export const isDayValid = (dateRange, reservationDate) => {
  const { days } = dateRange;

  //Sunday comes from DB as 7 but moment gets it as 0
  const reservationDay = moment(reservationDate).day() || 7;

  if (days?.length > 0 && !days.some((val) => val === reservationDay)) {
    return false;
  }

  return true;
};

export const isProductIdValid = (productIdList, productId) => {
  // when a discount doesnt have a product id is valid for all
  if (!productIdList?.length) {
    return true;
  }

  return productIdList.some((val) => val === productId.toString());
};

export const formatRollerDate = (rollerDate) => {
  let date = new Date(rollerDate);
  date.setDate(date.getDate() + 1);
  date = date.toISOString();
  return date;
};

export const getDiscount = (price, percentOff, quantity = 1) => {
  const value = price * quantity;
  const discount = (percentOff / 100) * value;

  return discount;
};

export const validateDiscountMax = (discountVal, discountMax) => {
  const discountFormatted = discountVal / PRICE_TRANSFORM_VALUE;

  if (discountFormatted > discountMax) {
    // normalize to roller values
    return discountMax * PRICE_TRANSFORM_VALUE;
  }

  return discountVal;
};

export const getProductsQuantities = (cartItems) => {
  return cartItems
    .map((cartItem) => cartItem.quantities)
    .map((item) =>
      Object.keys(item).map((key) => ({ productId: key, quantity: item[key] }))
    )
    .flat();
};

export const keys = {
  DISCOUNT_CODE_KEY: '_discount_code',
  DISCOUNT_USAGE_KEY: '_discount_usage',
  DISCOUNT_VALUE_KEY: '_discount_value',
};

export const getValidDaysString = (dateRange) => {
  const days = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };

  return dateRange.days.map((d) => days[d]);
};
