import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  getCategoryByCta,
  categories,
} from 'utils/analytics';
import { InfoCard } from '../InfoCard';

import './facts-section.less';

const cardsList = (cards) => {
  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events

  const handleClick = (url, name) => {
    const category = getCategoryByCta(url) || categories.VENUE_INFO;

    standardizedTrack(
      'click',
      'link',
      'facts section',
      name,
      category,
      trackingLabel
    );
  };

  if (!cards?.length) return null;
  return cards.map((card, i) => {
    return (
      <InfoCard
        key={i}
        card={card}
        onClick={() => handleClick(card.cta?.ctaLink, card.name)}
      />
    );
  });
};

const FactsSection = ({ id, cards }) => {
  if (!cards?.length) return null;

  const className = useClassName('FactsSection');

  return (
    <div id={id} className={className('container')}>
      {cardsList(cards)}
    </div>
  );
};

FactsSection.propTypes = {
  id: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.object,
    })
  ).isRequired,
};

export default FactsSection;
