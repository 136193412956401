import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import HoursContainer from './HoursContainer';
import Filters from './Filters';
import './hours.less';
import stores from 'common/assets/stores.svg';
import entertainment from 'common/assets/entertainment.svg';
import restaurantLogo from 'common/assets/restaurant.svg';
import restaurantFullLogo from 'common/assets/restaurant-full-service.svg';
import shoppingLogo from 'common/assets/shopping-bag.svg';

const DINING_LINK = '/directory/dining/all-food-drink';
const FULL_RESTAURANT_LINK = '/directory/dining/full-service';
const ALL_STORES_LINK = '/directory/shopping/all-stores';
const FILTERS = {
  entertainment: {
    name: 'Entertainment',
    image: entertainment,
  },
  stores: {
    name: 'Stores',
    image: stores,
  },
};

const Hours = ({ hoursData, hoursPage, date }) => {
  const className = useClassName('Hours');
  const [filter, setFilter] = useState();

  const hoursGroups = {
    entertainment: {
      ref: useRef(),
      render: () => renderAttractionHours(),
    },
    stores: {
      ref: useRef(),
      render: () => (
        <>
          {renderGeneralHours()}
          {renderShoppingHours()}
          {renderDiningHours()}
        </>
      ),
    },
  };

  useEffect(() => {
    if (hoursGroups.entertainment.ref) setFilter('entertainment');
  }, [hoursGroups.entertainment.ref]);

  const renderGeneralHours = () => (
    <HoursContainer
      image={shoppingLogo}
      name="Retail Stores"
      openingHours={hoursData?.retailHours}
      date={date}
      linkTo={ALL_STORES_LINK}
    />
  );

  const renderDiningHours = () => (
    <>
      <HoursContainer
        image={restaurantLogo}
        name="Coca Cola Eats (Food Court) & Quick-Serve Dining"
        openingHours={hoursData?.diningHours}
        date={date}
        linkTo={DINING_LINK}
      />
      <HoursContainer
        image={restaurantFullLogo}
        name="Full-Service Restaurants"
        date={date}
        linkTo={FULL_RESTAURANT_LINK}
        hasNoHours={true}
      />
    </>
  );

  const renderShoppingHours = () =>
    hoursData?.shoppingVenues?.map((venue) => (
      <HoursContainer
        key={venue.id}
        name={venue.name}
        openingHours={venue.openingHours}
        date={date}
        venue={venue}
        linkTo={`/${venue.slug}`}
      />
    ));

  const renderAttractionHours = () =>
    hoursData?.attractionVenues?.map((venue) => (
      <HoursContainer
        key={venue.id}
        venue={venue}
        name={venue.name}
        openingHours={venue.openingHours}
        date={date}
        linkTo={`/venue/${venue.slug}`}
      />
    ));

  return (
    <div className={className('container')}>
      <div className={className('selected-date')}>
        <p>{DateTime.fromISO(date).toFormat('EEEE, MMMM d')}</p>
      </div>
      <div className={className('filters')}>
        <Filters
          filters={FILTERS}
          filter={filter}
          handleFilterChange={(newFilter) => setFilter(newFilter)}
        />
        <div className={className('open-hours-message')}>
          <ContentfulRichText field={hoursPage.openHoursMessage} />
        </div>
      </div>
      <div
        className={className('hours-groups')}
        style={{
          minHeight: hoursGroups[filter]?.ref?.current?.clientHeight,
        }}>
        {Object.keys(hoursGroups).map((groupKey) => (
          <div
            key={groupKey}
            className={className([
              'hours-group',
              `hours-group-${filter === groupKey ? 'visible' : 'hidden'}`,
            ])}>
            <div className={className('hours')} ref={hoursGroups[groupKey].ref}>
              {hoursGroups[groupKey].render()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Hours.propTypes = {
  hoursData: PropTypes.shape({
    attractionVenues: PropTypes.array.isRequired,
    shoppingVenues: PropTypes.array.isRequired,
    retailHours: PropTypes.object.isRequired,
    diningHours: PropTypes.object.isRequired,
  }).isRequired,
  hoursPage: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
};

export default Hours;
