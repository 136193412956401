import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { parseApiDate } from 'utils/api';
import { formatCurrency } from 'utils/l10n';
import { ContentfulImage, ContentfulRichText } from 'common/components';
import { formatHourAndMinute } from 'utils/helpers';

import { NewCheckoutProductCard } from '../ProductCard';
import { NewCheckoutQuantityControls } from '../QuantityControls';

import './product-option.less';

const MAX_CHARS = 120;

function StringHTML({ className, html }) {
  return (
    <span className={className} dangerouslySetInnerHTML={{ __html: html }} />
  );
}

function Description({ description, contentfulDescription, largeImage }) {
  const classNames = useClassName('ProductOption');
  const [showFullDescription, setShowFullDescription] = useState(
    () => description.length <= MAX_CHARS
  );

  if (!description) return null;
  if (contentfulDescription) {
    return (
      <span className={classNames('description')}>
        <ContentfulRichText field={description} />
      </span>
    );
  }

  if (
    !(typeof description === 'string' || description instanceof String) ||
    largeImage
  ) {
    return (
      <StringHTML html={description} className={classNames('description')} />
    );
  }

  return (
    <div>
      <span className={classNames(['description', 'collapsable'])}>
        <StringHTML
          html={
            showFullDescription
              ? description
              : `${description.substring(0, MAX_CHARS)}...`
          }
        />
        {!showFullDescription && (
          <span
            className={classNames('view-more')}
            onClick={() => setShowFullDescription(true)}>
            View more
          </span>
        )}
      </span>
    </div>
  );
}

const ProductOption = ({
  onChangeQuantity,
  images,
  date,
  name,
  price,
  description,
  isSoldOut,
  quantity,
  onDetail,
  largeImage,
  min,
  max,
  contentfulDescription,
  blockDecrease,
  caption,
  hideDescription,
  hours,
}) => {
  const getSoldOutLabel = () => {
    return parseApiDate(date) < new Date() ? 'No Longer Available' : 'Sold Out';
  };

  const mainImage = images[0];

  return (
    <NewCheckoutProductCard
      isSoldOut={isSoldOut}
      hideDescription={hideDescription}
      caption={caption}
      description={
        <Description
          description={description}
          contentfulDescription={contentfulDescription}
          largeImage={largeImage}
        />
      }
      onDetail={onDetail}
      controls={
        isSoldOut ? (
          getSoldOutLabel()
        ) : (
          <NewCheckoutQuantityControls
            max={max || 999}
            min={min}
            quantity={quantity || 0}
            onChange={onChangeQuantity}
            blockDecrease={blockDecrease}
          />
        )
      }
      image={
        mainImage.contentfulField ? (
          <ContentfulImage field={mainImage.contentfulField} width={244} />
        ) : (
          <img src={mainImage.src} alt={mainImage.alt} />
        )
      }
      info={
        <>
          <h3>{name}</h3>
          <h5>{formatCurrency(price)}</h5>
          {Boolean(hours && !hours.isClosed) && (
            <span>
              {[
                formatHourAndMinute(hours.openHour, hours.openMinute),
                formatHourAndMinute(hours.closeHour, hours.closeMinute),
              ].join(' - ')}
            </span>
          )}
        </>
      }
      largeImage={largeImage}
      selected={quantity > 0}
    />
  );
};

ProductOption.propTypes = {
  onChangeQuantity: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isSoldOut: PropTypes.bool,
  quantity: PropTypes.number,
  onDetail: PropTypes.func,
  largeImage: PropTypes.bool,
  min: PropTypes.number,
  blockDecrease: PropTypes.bool,
  caption: PropTypes.string,
  hideDescription: PropTypes.bool,
  hours: PropTypes.object,
};

export default ProductOption;
