import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import { DateTime } from 'luxon';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import './date-picker.less';

const DatePicker = ({ label, reservationDate, handleDateSelected }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const className = useClassName('DatePicker');
  const today = new Date();
  const isDisabled =
    DateTime.now().startOf('day') >=
    DateTime.fromISO(reservationDate).startOf('day');

  const handleDateClicked = (selectedDate) => {
    handleDateSelected(selectedDate);
    setIsExpanded(false);
  };

  return (
    <div className={className('container')}>
      {label && <div className={className('label')}>{label}</div>}
      <div
        className={className([
          'date',
          `date-${isExpanded ? 'expanded' : 'collapsed'}`,
          `date-${isDisabled ? 'disabled' : 'enabled'}`,
        ])}
        onClick={() => {
          if (isDisabled) return;
          setIsExpanded(!isExpanded);
        }}>
        {DateTime.fromISO(reservationDate).toFormat('ccc, LLL dd')}
        <Icon name="angle-down" width={12} height={12} />
      </div>
      <div
        className={className([
          'calendar',
          `calendar-${isExpanded ? 'expanded' : 'collapsed'}`,
        ])}>
        <DayPicker
          className={className('day-picker')}
          disabledDays={{ before: today }}
          selectedDays={DateTime.fromISO(reservationDate).toJSDate()}
          month={DateTime.fromISO(reservationDate).toJSDate()}
          onDayClick={handleDateClicked}
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  reservationDate: PropTypes.string.isRequired,
  handleDateSelected: PropTypes.func.isRequired,
};

export default DatePicker;
