import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { getContentfulField } from 'common/components';

import './sessions.less';

const Sessions = ({
  venue,
  selectedDate,
  sessions,
  selectedSession,
  onSessionSelected,
  forwardedRef,
}) => {
  const className = useClassName('Sessions');

  const isSoldOut = (session) => !session.capacityRemaining;

  return (
    <div
      ref={forwardedRef}
      className={`${className('container')} ${
        (!selectedDate || !sessions.length) && className('hidden')
      }`}>
      <h2>
        {venue?.content?.sessionSelectorTitle
          ? getContentfulField(venue.content.sessionSelectorTitle)
          : 'SELECT ARRIVAL TIME'}
      </h2>
      <div className={className('times')}>
        {sessions.map((session, index) => {
          const getClassNames = (session) => {
            const cls = [className('time')];
            if (isSoldOut(session)) cls.push(className('unavailable'));
            if (
              selectedSession &&
              selectedSession.startTime === session.startTime
            )
              cls.push(className('selected'));

            return cls.join(' ');
          };

          return (
            <div
              key={index}
              className={getClassNames(session)}
              onClick={() => !isSoldOut(session) && onSessionSelected(session)}>
              <span>{session.name}</span>
              {isSoldOut(session) && <span>Sold out</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Sessions.propTypes = {
  venue: PropTypes.object,
  selectedDate: PropTypes.string,
  sessions: PropTypes.array,
  selectedSession: PropTypes.object,
  onSessionSelected: PropTypes.func.isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default Sessions;
