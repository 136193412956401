import React from 'react';
import { useHistory } from 'react-router';

import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';

export default function BookTicketsAnnouncement({ announcement, setActive }) {
  const className = useClassName('BookTickets');
  const history = useHistory();

  const handleAnnouncementClick = (link) => {
    track('Book Tickets Announcement Pressed', { label: link });
    setActive(false);
    if (link.includes('http')) window.location.href = link;
    else history.push(link);
  };

  return (
    <div
      className={className('book-announcement')}
      key={announcement.name}
      style={{ backgroundColor: announcement.backgroundColor }}
      onClick={() => handleAnnouncementClick(announcement.link)}>
      {announcement.image?.assetUrl && (
        <img
          src={announcement.image.assetUrl}
          alt={announcement.image.assetAlt}
        />
      )}
    </div>
  );
}
