import { observable, action } from 'mobx';
import { request, formatApiDate } from 'utils/api';
import { isValidEmail } from 'utils/helpers';
import BaseStore from 'common/stores/BaseStore';

export default class ExternalBookingsStore extends BaseStore {
  @observable venueAvailabilities = new Map();

  getKey(venue, date) {
    return `${venue}-${formatApiDate(date)}`;
  }

  @action
  save(
    bookingIds,
    venueSlug,
    category,
    expirationDate,
    statusKey = `saveExternalBookings:${venueSlug}`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/externalbookings`,
      body: {
        bookingIds,
        venueSlug,
        category,
        expirationDate,
      },
    })
      .then((data) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchAvailabilityByVenue(
    venueSlug,
    reservationDate,
    statusKey = `fetchExternalBookingsAv:${venueSlug}`
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/externalbookings/${venueSlug}`,
      body: {
        reservationDate,
      },
    })
      .then((data) => {
        status.success();
        this.venueAvailabilities.set(
          this.getKey(venueSlug, reservationDate),
          data.data
        );
        return data.data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  searchByDate(startDate, endDate, statusKey = `searchByDate`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/externalbookings/search/bydate`,
      body: {
        startDate,
        endDate,
      },
    })
      .then((res) => {
        status.success();
        return res?.data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  searchByEmailOrBooking(searchTerm, statusKey = `searchByEmailOrBooking`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/externalbookings/search/byemailorbooking`,
      body: {
        email: isValidEmail(searchTerm) ? searchTerm : '',
        bookingReference: isValidEmail(searchTerm) ? '' : searchTerm,
      },
    })
      .then((res) => {
        status.success();
        return res?.data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  get(venue, date) {
    return this.venueAvailabilities.get(this.getKey(venue, date));
  }
}
