import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Grid, Dropdown, Container, Loader, Button } from 'semantic-ui-react';
import { Headline, Pagination, Spacer, EventCard } from 'public/components';
import { Layout, SVGIcon as Icon } from 'common/components';
import { Screen } from 'public/helpers';

import { EVENT_CATEGORIES } from './const';

const PER_PAGE = 12;

@inject('events')
@observer
export default class EventList extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: true,
      events: null,
      error: null,
    };
  }

  routeDidUpdate() {
    this.fetch();
  }

  componentDidUpdate(lastProps, lastState) {
    const { page } = this.state;
    if (page !== lastState.page) {
      this.fetch();
    }
  }

  getCurrentCategoryName() {
    const slug = this.getCurrentCategorySlug();
    const category = EVENT_CATEGORIES.find((category) => {
      return category.slug === slug;
    });
    return category && category.name;
  }

  getCurrentCategorySlug() {
    return this.props.match.params.category || '';
  }

  onPageChange = async (evt, data) => {
    await this.scrollToTop();
    this.setState({
      page: data.activePage,
    });
  };

  async fetch() {
    this.setState({
      loading: true,
    });
    try {
      const category = this.getCurrentCategoryName();
      const { page } = this.state;
      this.setState({
        events: await this.props.events.fetchItems({
          category,
          skip: (page - 1) * PER_PAGE,
          limit: PER_PAGE,
          sort: {
            order: 'desc',
            field: 'endDate',
          },
        }),
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          loading: false,
          error,
        });
      } else {
        throw error;
      }
    }
  }

  renderBody() {
    if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    }
    return (
      <Container>
        <Spacer size="s" />
        <Spacer size="s" desktop />
        <Headline title="Events At" subtitle="American Dream" />
        <Spacer size="s" />
        {this.renderFilters()}
        <Spacer size="s" />
        <Grid columns={2} stackable>
          {this.renderEvents()}
        </Grid>
        <Spacer size="l" />
        {this.renderPagination()}
        <Spacer size="l" />
      </Container>
    );
  }

  renderEvents() {
    const { events } = this.state;
    if (!events.length) {
      const categoryName = this.getCurrentCategoryName();
      let message;
      if (categoryName) {
        message = (
          <React.Fragment>
            Sorry, no results found for{' '}
            <span className="chunk">“{categoryName}”</span>.
          </React.Fragment>
        );
      } else {
        message = 'Sorry, no results found.';
      }
      return (
        <Container textAlign="center" className="small">
          <Spacer size="s" />
          <h5>{message}</h5>
        </Container>
      );
    }
    return events.map((event) => {
      return (
        <Grid.Column key={event.id}>
          <EventCard event={event} />
        </Grid.Column>
      );
    });
  }

  renderFilters() {
    return (
      <Layout horizontal right>
        <Layout.Group>
          <Dropdown
            direction="right"
            icon={null}
            trigger={
              <Button primary>
                <Icon name="filter" size="mini" />
                Filter
              </Button>
            }>
            <Dropdown.Menu>
              {this.renderFilterLink('', 'All')}
              {EVENT_CATEGORIES.map((category) => {
                const { name, slug } = category;
                return this.renderFilterLink(slug, name);
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Layout.Group>
      </Layout>
    );
  }

  renderFilterLink(slug, name) {
    if (slug === this.getCurrentCategorySlug()) {
      name = <b>{name}</b>;
    }
    return (
      <Dropdown.Item key={slug}>
        <Link to={`/events/${slug}`}>{name}</Link>
      </Dropdown.Item>
    );
  }

  renderPagination() {
    const { page } = this.state;
    const { totalItems } = this.props.events;
    if (totalItems > PER_PAGE) {
      return (
        <Pagination
          totalPages={Math.floor(totalItems / PER_PAGE)}
          activePage={page}
          onPageChange={this.onPageChange}
        />
      );
    }
  }
}
