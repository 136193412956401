import { track } from 'utils/analytics';

export default function trackHomepageSlide(getProps) {
  try {
    const props = getProps();
    track('HP Carousel Slide', props);
  } catch (ex) {
    console.error('Failed to track homepage slide');
  }
}
