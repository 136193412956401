import React from 'react';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Layout, ResponsiveImage, Modal, Content } from 'common/components';
import { Screen, withVenue } from 'public/helpers';
import {
  Headline,
  Hero,
  Spacer,
  AttractionScreen,
  AttractionsNav,
} from 'public/components';

import './home.less';

@withVenue('the-rink')
export default class TheRinkHome extends Screen {
  renderBody() {
    const { venue } = this.props;

    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }
    return (
      <div {...this.getAttrs()}>
        <Hero contentfulImage="Rink - Graphic - Home Hero" />
        <AttractionsNav venue={this.props.venue} alertsText="RINK ALERTS" />
        <Spacer size="s" />
        <Container>
          <Layout
            className={this.getElementClass('bubble')}
            horizontal
            center
            stackable
            padded>
            <Layout.Group className={this.getElementClass('bubble-graphic')}>
              <ResponsiveImage
                contentfulName="Rink - Graphic - Bubble"
                square
                fluid
              />
            </Layout.Group>
            <Layout.Group>
              <Headline title="The" subtitle="Rink" color="#82b5ca" flipped />
              <h4>
                Our NHL regulation size ice rink is available for hockey and
                figure skating rental
              </h4>
            </Layout.Group>
          </Layout>
        </Container>
        <Link
          to="/venue/the-rink/ticket-info"
          className={this.getElementClass('promo')}>
          <ResponsiveImage
            contentfulName="Rink - Graphic - Promo"
            ratio={0.96}
            fluid
          />
        </Link>
        <Spacer size="l" />
        <Layout
          className={this.getElementClass('cards')}
          wrap
          horizontal
          stackable
          spread>
          <Layout.Group>
            <Modal
              size="tiny"
              trigger={
                <div className={this.getElementClass('card')}>
                  <div className={this.getElementClass('card-link')}>
                    <ResponsiveImage
                      className={this.getElementClass('card-image')}
                      contentfulName="Rink - Card - Group Experiences"
                      ratio={0.56}
                      fluid
                    />
                    <div className={this.getElementClass('card-text')}>
                      <div className={this.getElementClass('card-bold')}>
                        Group
                      </div>
                      Experiences
                    </div>
                  </div>
                </div>
              }>
              <div className={this.getElementClass('modalContent')}>
                <Content>
                  <p>
                    Ask us about having The Rink for yourself, for hockey or
                    figure skating, events and other opportunities for groups of
                    40 or more.
                  </p>
                  <p>
                    Please complete <Link to="/group-sales">this form.</Link>
                  </p>
                </Content>
              </div>
            </Modal>
          </Layout.Group>
          <Layout.Group>
            <Link
              to="/venue/the-rink/faq"
              className={this.getElementClass('card')}>
              <div className={this.getElementClass('card-link')}>
                <ResponsiveImage
                  className={this.getElementClass('card-image')}
                  contentfulName="Rink - Card - Guest Services"
                  ratio={0.56}
                  fluid
                />
                <div className={this.getElementClass('card-text')}>
                  <div className={this.getElementClass('card-bold')}>Guest</div>
                  services
                </div>
              </div>
            </Link>
          </Layout.Group>
          <Layout.Group>
            <Link to="/signup" className={this.getElementClass('card')}>
              <div className={this.getElementClass('card-link')}>
                <ResponsiveImage
                  className={this.getElementClass('card-image')}
                  contentfulName="Rink - Card - Stay in the Know"
                  ratio={0.56}
                  fluid
                />
                <div className={this.getElementClass('card-text')}>
                  <div className={this.getElementClass('card-bold')}>
                    Stay In
                  </div>
                  The Know
                </div>
              </div>
            </Link>
          </Layout.Group>
        </Layout>
        <Spacer size="l" />
      </div>
    );
  }
}
