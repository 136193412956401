import React from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';
import { SVGIcon as Icon } from 'common/components';

export default class Pagination extends React.Component {
  render() {
    return (
      <SemanticPagination
        firstItem={null}
        lastItem={null}
        prevItem={
          this.props.activePage === 1
            ? null
            : this.renderArrow('prev', 'Previous Item')
        }
        nextItem={this.renderArrow('next', 'Next Item')}
        siblingRange={1}
        {...this.props}
      />
    );
  }

  renderArrow(type, label) {
    const { activePage, totalPages } = this.props;
    return {
      'aria-label': label,
      disabled: type === 'prev' ? activePage === 1 : activePage === totalPages,
      content: (
        <Icon
          name={`angle-${type === 'prev' ? 'left' : 'right'}`}
          size="tiny"
        />
      ),
    };
  }
}
