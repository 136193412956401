import * as Sentry from '@sentry/browser';
import { SENTRY_DSN } from '../env/client';

export default function captureError(err, extras) {
  if (SENTRY_DSN) {
    if (extras) {
      for (let [name, value] of Object.entries(extras)) {
        Sentry.setExtra(name, value);
      }
    }
    Sentry.captureException(err);
  } else {
    throw err;
  }
}
