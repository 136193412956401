import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { Button, Input } from 'semantic-ui-react';

import './discount-code.less';

const DiscountCode = ({
  discountCode,
  onDiscountCodeChange,
  applyDiscount,
  removeDiscount,
  error,
  applied,
  maximumDiscountReached,
}) => {
  const classNames = useClassName('NewCheckoutBasketDiscountCode');

  return (
    <div className={classNames('container')}>
      <h2>Add a discount code</h2>
      <div>
        <Input
          className={classNames([
            'discounts-input',
            error && 'error',
            applied && 'success',
            discountCode && 'typed',
          ])}
          value={discountCode}
          onChange={(e, { value }) => onDiscountCodeChange(value)}
          name="discount"
          placeholder="Enter code here"
          type="text"
          disabled={applied}
        />
        <Button
          primary
          className={classNames(['discounts-button'])}
          onClick={() => (applied ? removeDiscount() : applyDiscount())}>
          {applied ? 'Remove' : 'Apply'}
        </Button>
      </div>
      {Boolean(error) && (
        <span className={classNames('error-message')}>{error}</span>
      )}
      {Boolean(applied) && (
        <span className={classNames('success-message')}>
          Discount applied
          {maximumDiscountReached ? ' (maximum discount reached)' : ''}.
        </span>
      )}
    </div>
  );
};

DiscountCode.propTypes = {
  discountCode: PropTypes.string,
  onDiscountCodeChange: PropTypes.func.isRequired,
  applyDiscount: PropTypes.func.isRequired,
  removeDiscount: PropTypes.func.isRequired,
  error: PropTypes.string,
  applied: PropTypes.bool,
  maximumDiscountReached: PropTypes.bool,
};

export default DiscountCode;
