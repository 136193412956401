import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import { useClassName } from 'common/hooks';
import { AddOnCardNew } from 'public/components';
import ChangeContainer from './ChangeContainer';
import {
  getQuantities,
  isTicketExpired,
  mapBundleAddon,
} from '../helpers/orderUpdate';
import { SVGIcon as Icon } from 'common/components';

import './add-on-selector.less';

const BundleAddOnSelector = ({
  orderUpdate,
  bundles,
  redirectToNewAddons,
  handleNewAddonsChanged,
}) => {
  const [loading, setLoading] = useState(true);
  const [addons, setAddons] = useState([]);
  const [newAddons, setNewAddons] = useState(orderUpdate.newAddons || []);
  const currentAddonsQuantities = getQuantities(
    orderUpdate.currentTickets.filter((ticket) => ticket.ticketOption.addon)
  );
  const className = useClassName('AddOnSelector');

  useEffect(() => {
    getAddonsAvailability();
  }, []);

  const getAddonsAvailability = async () => {
    const { newTickets } = orderUpdate;
    const addons = [];
    for (const ticket of newTickets) {
      if (!ticket.bundle) continue;

      const reservationDate = ticket.ticketOption.date;

      if (isTicketExpired(reservationDate)) continue;

      const addonsAvailability = await bundles.getAddonsAvailability(
        ticket.bundle.rollerProducts.find(
          (rollerProduct) =>
            rollerProduct.ticketOptionId === ticket.ticketOption.ticketOptionId
        ).addons,
        reservationDate
      );
      addons.push(
        ...addonsAvailability.map((addonAvailability) => ({
          ...addonAvailability,
          venue: ticket.venue,
          reservationDate,
          parentBundleSlug: ticket.bundle.slug,
        }))
      );
    }
    setAddons(addons);
    setLoading(false);
  };

  const renderAddNewAdons = () => {
    return (
      <div className={className('new-addons')}>
        <p onClick={redirectToNewAddons}>Add</p>
        <Icon name="chevron-right" size="small" color="grey" />
      </div>
    );
  };

  const handleAddonUpdate = (item, quantity) => {
    const updatedAddons = mapBundleAddon(item, quantity, newAddons);
    setNewAddons(updatedAddons);
    handleNewAddonsChanged(
      updatedAddons.filter(({ ticketOption }) => ticketOption.quantity > 0)
    );
  };

  const getAddonFromInventory = (newAddon) =>
    addons.find(
      (addon) => addon.ticketOptionId === newAddon.ticketOption.ticketOptionId
    );

  return (
    <ChangeContainer title="Add-ons">
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          {newAddons
            ?.filter(
              (newAddon) =>
                newAddon.ticketOption.quantity > 0 &&
                getAddonFromInventory(newAddon)
            )
            ?.map((newAddon) => (
              <AddOnCardNew
                key={newAddon.ticketOption.ticketOptionId}
                product={getAddonFromInventory(newAddon)}
                currentQuantity={newAddon.ticketOption.quantity}
                minimumQuantity={
                  currentAddonsQuantities[newAddon.ticketOption.ticketOptionId]
                }
                setQuantity={handleAddonUpdate}
              />
            ))}
          {renderAddNewAdons()}
        </>
      )}
    </ChangeContainer>
  );
};

BundleAddOnSelector.propTypes = {
  redirectToNewAddons: PropTypes.func.isRequired,
  handleNewAddonsChanged: PropTypes.func.isRequired,
};

export default inject('orderUpdate', 'bundles')(BundleAddOnSelector);
