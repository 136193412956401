import React from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { Screen, withVenue, getCustomMetadata } from 'public/helpers';
import { ContentfulRichText, Content } from 'common/components';
import { Spacer, AttractionsNav } from 'public/components';

import './ticket-info.less';
import { NotFound } from '../../components/NotFound';

const metaSublinkId = 'faq';

@withVenue
export default class Faq extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      customMetadata: null,
      loading: true,
    };
  }

  async getMetadata() {
    const customMetadata = await getCustomMetadata(
      this.props.venue,
      metaSublinkId
    );
    this.setState({ customMetadata, loading: false });
  }

  componentDidMount() {
    this.setupRouteEvents();
    this.getMetadata();
  }

  renderBody() {
    const { error, venue } = this.props;

    if (error === 'Not Found') {
      return <NotFound />;
    } else if (!venue) {
      return <Loader active />;
    }

    if (!venue.content.FaqUrl) {
      return <NotFound />;
    }

    return (
      <div {...this.getAttrs()}>
        <AttractionsNav venue={this.props.venue} />
        <Container>
          <Spacer size="m" />
          <Content>
            <ContentfulRichText field={venue.content.FaqUrl} />
          </Content>
        </Container>
        <Spacer size="l" />
      </div>
    );
  }
}
