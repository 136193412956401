import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import {
  ContentfulRichText,
  getContentfulField,
  SVGIcon,
} from 'common/components';
import CTA from 'public/components/AttractionScreen/CTA';
import { formatRegularHours } from 'utils/helpers/formatOpeningHours';
import { BottomSheet } from 'public/components/BottomSheet';
import Slider from 'common/components/Slider';
import { useSnackbar } from 'public/components/Snackbar';

import './event-details-modal.less';

const EventDetailsModal = ({ onClose, event }) => {
  const { isMobile } = useWindowSize();
  const classNames = useClassName('EventDetailsModal');
  const [showShareSuccess, shareSnackbar] = useSnackbar();

  const canShare = () => Boolean(navigator?.clipboard?.writeText);

  const share = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    navigator.clipboard
      .writeText(`${location.origin}/events/${event.slug}`)
      .then(function () {
        showShareSuccess(true);
      });
  };

  const getContent = () => {
    if (!event?.title) return null;

    const { schedule } = event;
    const oneDayEvent = schedule.startDate.equals(schedule.endDate);
    const hours = formatRegularHours({ regular: event.weekdays }, true);

    return (
      <>
        {shareSnackbar}
        <Slider
          images={getContentfulField(event.images)}
          contentfulImages
          hideMiniatures
          className={classNames('slider')}
        />
        {!isMobile && <h1>{event.title}</h1>}
        <div className={classNames('actions')}>
          {canShare() && (
            <div onClick={share}>
              <SVGIcon name="share" />
              <span>Share event</span>
            </div>
          )}
        </div>
        <ContentfulRichText
          field={event.details}
          className={classNames('details')}
        />
        <div className={classNames('info')}>
          <span>
            <SVGIcon name="date" />
            <span>
              {[
                event.schedule.startDate.toFormat('LLL dd'),
                !oneDayEvent && event.schedule.endDate.toFormat('LLL dd'),
              ]
                .filter(Boolean)
                .join(' - ')}
            </span>
          </span>
          <span>
            <SVGIcon name="clock" />
            <div>
              {(oneDayEvent && hours.length === 1
                ? [hours[0].split(':').splice(1).join(':').trim()]
                : hours
              ).map((hour) => (
                <span key={hour}>{hour}</span>
              ))}
            </div>
          </span>
          <span>
            {Boolean(event?.venue?.slug || event?.locationDescription) && (
              <>
                <SVGIcon name="location" />
                {event?.venue?.slug ? (
                  <Link
                    to={{ pathname: `/venue/${event?.venue?.slug}` }}
                    smooth="true">
                    {event?.venue?.name}
                  </Link>
                ) : (
                  <span>{event?.locationDescription}</span>
                )}
              </>
            )}
          </span>
        </div>
        {Boolean(event.url) && (
          <CTA
            text={event.type}
            link={event.url}
            className={classNames('cta')}
          />
        )}
      </>
    );
  };

  if (isMobile) {
    return (
      <BottomSheet
        onClose={onClose}
        content={<div className={classNames('content')}>{getContent()}</div>}
        title={event?.title}
        isOpen={event?.title}
      />
    );
  }

  return (
    <Modal
      onClose={onClose}
      className={classNames('container')}
      open={Boolean(event)}>
      <Modal.Content className={classNames('content')}>
        <div className={classNames('close')} onClick={onClose}>
          <SVGIcon name="close" />
        </div>
        {getContent()}
      </Modal.Content>
    </Modal>
  );
};

EventDetailsModal.propTypes = {
  onClose: PropTypes.func,
  event: PropTypes.object,
};

export default EventDetailsModal;
