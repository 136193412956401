import React from 'react';
import PropTypes from 'prop-types';
import { getGroupedTickets } from '../helpers/orderUpdate';
import { formatCurrency } from 'utils/l10n';

const TicketsGroup = ({ tickets, feePaid, showDiscountPrice }) => {
  const groupedTickets = getGroupedTickets(tickets, feePaid);

  const getPrice = (ticketOption) => {
    const price = showDiscountPrice
      ? ticketOption.priceDiscount + ticketOption.taxDiscount
      : ticketOption.price + ticketOption.tax;

    return formatCurrency(
      ticketOption.quantity * price + ticketOption.feeAmount,
      true
    );
  };

  return (
    <div className="tickets">
      {Object.values(groupedTickets)
        .sort(
          (a, b) =>
            Number(Boolean(a.ticketOption.addon)) -
            Number(Boolean(b.ticketOption.addon))
        )
        .map(({ ticketOption }) => (
          <div className="ticket" key={ticketOption.ticketOptionId}>
            <p className="ticket-name">
              (x{ticketOption.quantity}) {ticketOption.ticketOptionName}
            </p>
            <p className="ticket-price">{getPrice(ticketOption)}</p>
          </div>
        ))}
    </div>
  );
};

TicketsGroup.proptypes = {
  tickets: PropTypes.array.isRequired,
  feePaid: PropTypes.object,
  showDiscountPrice: PropTypes.bool,
};

TicketsGroup.defaultProps = {
  showDiscountPrice: false,
};

export default TicketsGroup;
