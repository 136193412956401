import React from 'react';
import { Button } from 'semantic-ui-react';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { ContentfulImage, ContentfulRichText } from 'common/components';

import { NewCheckoutBasketItem } from '../../Components/BasketItem';
import { isSoldOut } from '../utils';
import { NewCheckoutProductCard } from '../../Components/ProductCard';

import './product-with-variants.less';

const ProductWithVariants = ({
  product,
  quantities,
  onClick,
  setQuantities,
  large,
  onDetail,
}) => {
  const classNames = useClassName('ProductWithVariants');
  const selected = product.products.some((prod) => quantities[prod.id]);

  return (
    <NewCheckoutProductCard
      isSoldOut={isSoldOut(product)}
      image={<ContentfulImage field={product.images} width={244} />}
      info={
        <>
          <h3>{product.name}</h3>
          <ContentfulRichText
            className={classNames('description')}
            field={product.description}
          />
        </>
      }
      controls={
        isSoldOut(product) ? (
          <div className={classNames('sold-out-label')}>Sold Out</div>
        ) : (
          <Button
            onClick={() => onClick(product)}
            primary={!selected}
            className={classNames('add')}>
            {selected ? 'Edit' : 'Add'}
          </Button>
        )
      }
      selected={selected}
      largeImage={large}
      onDetail={onDetail && (() => onDetail(product))}>
      {selected && (
        <div className={classNames('selected-products')}>
          {product.products.map((prod, key) => {
            const quantity = quantities[prod.id];
            if (!quantity) return null;

            return (
              <NewCheckoutBasketItem
                name={prod.name}
                key={key}
                index={key}
                price={prod.cost * 100}
                quantity={quantity}
                {...(isNumber(prod.maxQuantity) && { max: prod.maxQuantity })}
                onRemove={() => setQuantities({ ...quantities, [prod.id]: 0 })}
                onChangeQuantity={(quantity) =>
                  setQuantities({ ...quantities, [prod.id]: quantity })
                }
              />
            );
          })}
        </div>
      )}
    </NewCheckoutProductCard>
  );
};

ProductWithVariants.propTypes = {
  product: PropTypes.object,
  quantities: PropTypes.object,
  onClick: PropTypes.func,
  setQuantities: PropTypes.func,
  large: PropTypes.bool,
  onDetail: PropTypes.func,
};

export default ProductWithVariants;
