import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Loader, Container, Form, Button } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';
import { NotFound } from '../NotFound';
import { isValidEmail } from 'utils/helpers/validations';
import { FORMS_COMING_SOON_CONTACT } from 'utils/env/client';
import { Layout, ContentfulRichText, SVGIcon as Icon } from 'common/components';
import { identify, track } from 'utils/analytics';
import { DEFAULT_GROUP_ID, SMS_GROUP_ID } from '../../../common/const';
import { CONTACT_TOPIC_OPTIONS } from 'common/constants/contact';
const FORM_URL = `https://docs.google.com/forms/d/${FORMS_COMING_SOON_CONTACT}/formResponse`;
import { Link } from 'react-router-dom';
import './contact.less';
const contactus = 'yes';
const FIELDS = [
  {
    name: 'entry.1982650626',
    label: 'Name',
    type: 'text',
    required: true,
  },
  {
    name: 'entry.2138577392',
    label: 'Email',
    type: 'email',
    validate: isValidEmail,
    required: true,
  },
  {
    name: 'entry.649365458',
    label: 'Phone',
    type: 'tel',
  },
  {
    name: 'entry.532408974',
    label: 'Type',
    required: true,
    options: CONTACT_TOPIC_OPTIONS,
  },
  {
    name: 'entry.490872861',
    label: 'Message',
    type: 'textarea',
    required: true,
  },
];

@inject('pages', 'subscriptions', 'contact', 'salesforce')
export default class Contact extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      error: null,
      touched: false,
      current: 'pending',
      emailAccepted: true,
      smsAccepted: false,
    };
  }

  async routeDidUpdate() {
    try {
      const slug = 'contact';
      this.setState({
        page: await this.props.pages.fetchItemBySlug(slug),
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
        });
      } else {
        throw error;
      }
    }
  }

  onChange = (evt, data) => {
    const { name, value } = data;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = async () => {
    if (this.validate()) {
      this.setState({
        current: 'loading',
      });
      await this.send();
      this.sendInfoBraze();
      await this.sendSalesforceForm();
      await this.sendContactForm();
      this.setState({
        current: 'complete',
      });
    } else {
      this.setState({
        touched: true,
      });
    }
  };

  getFormFields() {
    const email = this.state['entry.2138577392'];
    const name = this.state['entry.1982650626'];
    const phone = this.state['entry.649365458'];
    const message = this.state['entry.490872861'];
    const type = this.state['entry.532408974'];

    return {
      email,
      name,
      phone,
      message,
      type,
    };
  }

  sendContactForm = async () => {
    const formFields = this.getFormFields();

    const typeObject = CONTACT_TOPIC_OPTIONS.find(
      (option) => option.value === formFields?.type
    );

    if (!typeObject?.emailTo) return;

    const { contact } = this.props;
    const formData = {
      ...formFields,
      type: typeObject.text,
      emailTo: typeObject.emailTo,
    };

    await contact.sendContactForm(formData);
  };

  sendSalesforceForm = async () => {
    const formFields = this.getFormFields();

    const typeObject = CONTACT_TOPIC_OPTIONS.find(
      (option) => option.value === formFields?.type
    );

    if (!typeObject?.sendToSalesForce) return;

    const { salesforce } = this.props;
    const formData = {
      ...formFields,
      firstName: formFields.name.split(' ')[0],
      lastName: formFields.name.split(' ')[1] || '',
      leadType: typeObject?.leadType,
      type: typeObject.text,
    };
    await salesforce.sendFormData(formData);
  };

  sendInfoBraze = async () => {
    const email = this.state['entry.2138577392'];
    const name = this.state['entry.1982650626'];
    const phone = this.state['entry.649365458'];
    const user = { name, email, phone };

    identify({
      ...user,
      contactus,
    });
    track(`subscription contactus form`);

    if (this.state.emailAccepted) {
      await this.props.subscriptions.create({
        ...user,
        group: DEFAULT_GROUP_ID,
      });
    }

    if (this.state.smsAccepted) {
      await this.props.subscriptions.create({
        ...user,
        group: SMS_GROUP_ID,
      });
    }
  };

  validate() {
    return FIELDS.every((field) => {
      const { name, required, validate } = field;
      const value = this.state[name] || '';
      return this.isValid(value, required, validate);
    });
  }

  isValid(value, required, validate) {
    if (required && !value) {
      return false;
    }
    if (validate && !validate(value)) {
      return false;
    }
    return true;
  }

  send() {
    return fetch(FORM_URL, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      mode: 'no-cors',
      method: 'POST',
      body: FIELDS.reduce((params, field) => {
        const { name } = field;
        const value = this.state[name] || '';
        params.append(name, value);
        return params;
      }, new URLSearchParams()).toString(),
    });
  }

  renderBody() {
    const { page, error } = this.state;
    if (error) {
      return <NotFound {...this.props} />;
    } else if (!page) {
      return <Loader inline="centered" active />;
    }
    return (
      <div {...this.getAttrs()}>
        <Spacer size="m" desktop />
        <Container className="white">
          <Layout vertical center>
            <Spacer size="m" />
            <Container className="tight">
              <h1>{page.name}</h1>
              <Spacer size="s" />
              <ContentfulRichText field={page.object.fields.body} />
              <Spacer size="s" />
              {this.renderForm()}
              <Spacer size="m" />
            </Container>
          </Layout>
        </Container>
      </div>
    );
  }

  renderForm() {
    if (this.state.current === 'pending') {
      return this.renderFormPending();
    } else if (this.state.current === 'loading') {
      return this.renderFormLoading();
    } else {
      return this.renderFormThankYou();
    }
  }

  renderFormLoading() {
    return (
      <React.Fragment>
        <Spacer size="l" />
        <Loader size="large" active inline="centered" />
        <Spacer size="l" />
      </React.Fragment>
    );
  }

  renderFormThankYou() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <h1>Thank you!</h1>
        <h3>Someone will be in contact with you shortly about your inquiry.</h3>
        <Spacer size="m" />
      </React.Fragment>
    );
  }

  renderFormPending() {
    const { loading } = this.state;
    return (
      <Form onSubmit={this.onSubmit} loading={loading} noValidate>
        {FIELDS.map(this.renderField, this)}
        <Form.Checkbox
          className="checkbox"
          name="emailAccepted"
          label={
            <>
              {
                'Sign me up for exclusive American Dream sweepstakes, promotions, emails, events, and updates. By checking this option you agree to our '
              }
              <Link className={this.getElementClass('terms-link')} to="/terms">
                Terms and Conditions
              </Link>
              {' and '}
              <Link
                className={this.getElementClass('privacy-link')}
                to="/privacy">
                Privacy Policy
              </Link>
              .
            </>
          }
          checked={this.state.emailAccepted}
          onChange={(e, { checked }) =>
            this.setState({ emailAccepted: checked })
          }
        />
        <Form.Checkbox
          className="checkbox"
          name="smsAccepted"
          label={
            <>
              {
                'Sign me up for SMS messages with news, updates and special promotions at the number provided above. Msg&Data rates may apply. Msg frequency varies. Reply HELP for help, STOP to cancel. By checking this option you agree to our '
              }
              <Link className={this.getElementClass('terms-link')} to="/terms">
                Terms and Conditions
              </Link>
              {' and '}
              <Link
                className={this.getElementClass('privacy-link')}
                to="/privacy">
                Privacy Policy
              </Link>
              .
            </>
          }
          checked={this.state.smsAccepted}
          onChange={(e, { checked }) => this.setState({ smsAccepted: checked })}
        />
        <Spacer size="xs" />
        <Button primary type="submit">
          Submit
        </Button>
      </Form>
    );
  }

  renderField(field) {
    const { name, label, type, required, validate } = field;
    const { touched } = this.state;
    const value = this.state[name] || '';
    const error = touched && !this.isValid(value, required, validate);
    const props = {
      name,
      label,
      value,
      error,
      onChange: this.onChange,
    };

    let input;
    if (type === 'text' || type === 'email' || type === 'tel') {
      input = <Form.Input {...props} type={type} />;
    } else if (type === 'textarea') {
      input = <Form.TextArea {...props} className="field" />;
    } else if (field.options) {
      input = (
        <Form.Dropdown
          {...props}
          fluid
          icon={<Icon name="angle-down" size="mini" />}
          placeholder="Select"
          options={field.options.map(({ value, text }) => ({ value, text }))}
        />
      );
    }
    return (
      <React.Fragment key={name}>
        {input}
        <Spacer size="xs" />
      </React.Fragment>
    );
  }
}

Contact.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

Contact.defaultProps = {
  contentfulSlugItem: 'contact',
};
