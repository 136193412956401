import React, { useEffect, useState } from 'react';

import { request } from 'utils/api';

import Home from '../Home';

export default function Preview({ match }) {
  const [entry, setEntry] = useState(null);
  const params = match.params;

  useEffect(() => {
    if (params?.entryId) {
      request({
        method: 'GET',
        path: `/1/preview/${params.entryId}`,
      }).then(({ entry }) => {
        setEntry(entry);
      });
    }
  }, [params?.entryId]);

  if (!entry) return null;

  return <Home entry={entry} />;
}
