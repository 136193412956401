import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

import {
  LazyVisible,
  useClassName,
  usePreload,
  preloadMedia,
  useWindowSize,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import { usePagination } from '../../Components/usePagination';
import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import './events.less';

export default function EventsSection({ section }) {
  const classNames = useClassName('EventsSection');
  const ref = useRef();
  const { isDesktop } = useWindowSize();
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  usePreload((isDesktop) => {
    preloadMedia(section.fields.events, isDesktop);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('header')}>
        <h3>{section.fields.header}</h3>
        <ContentfulRichText field={{ 'en-US': section.fields.subheader }} />
      </div>
      <Swiper
        modules={[Pagination]}
        height={542}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1128: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        loop={false}
        className={classNames('wrapper')}
        onSlideChange={({ activeIndex }) =>
          trackHomepageSlide(() => ({
            activeIndex,
            name: section.fields.events[activeIndex].fields.name,
            section: section.fields.sectionId,
          }))
        }
        {...pagination}>
        {section.fields.events
          .filter((_, index) => (isDesktop ? index < 3 : true))
          .map(({ fields, sys }, i) => {
            return (
              <SwiperSlide
                key={sys.id}
                className={classNames('slide-container')}>
                <div
                  className={classNames('slide')}
                  style={{ animationDelay: `${i * 150}ms` }}>
                  <HomepageButton
                    cta={{
                      ...fields.callToAction,
                      fields: {
                        ...fields.callToAction.fields,
                        isHidden: true,
                      },
                    }}>
                    <HomepageImage
                      field={fields.desktop}
                      mobile={fields.mobile}
                    />
                  </HomepageButton>
                  <HomepageButton
                    cta={fields.callToAction}
                    className={classNames('cta')}
                  />
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </LazyVisible>
  );
}
