import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Component } from 'common/helpers';
import { getContentfulAssetUrlandAlt } from 'common/components';

import './deal-card.less';
import arrowLinkIcon from 'common/assets/deals-arrow-right.svg';
import adLogo from 'common/assets/logo-black-circle.svg';
import { LoaderImage } from 'public/components/Loader';

const MAX_DSC_LENGTH = 200;

export default class DealCard extends Component {
  render() {
    const { deal } = this.props;
    let { description } = deal;
    if (description.length > MAX_DSC_LENGTH) {
      description = `${description.substr(0, MAX_DSC_LENGTH)}...`;
    }
    return (
      <div {...this.getAttrs(true)}>
        <Link
          to={{ pathname: `/deals/${deal.slug}`, state: null }}
          smooth="true">
          {deal.venueId.content?.logo && (
            <div
              className={this.getElementClass('deals-img')}
              style={{
                backgroundImage: `url("${
                  getContentfulAssetUrlandAlt(deal.venueId.content.logo)
                    .assetUrl
                }")`,
              }}></div>
          )}
          {!deal.venueId.content?.logo && (
            <div className={this.getElementClass('empty-deals-img')}>
              <LoaderImage
                src={adLogo}
                alt={'view'}
                loadingHeight={20}
                hideLoader
                fluid
              />
            </div>
          )}
          <div className={this.getElementClass('content')}>
            <div className={this.getElementClass('title')}>
              {deal.venueId.name}
            </div>
            <div
              className={`${this.getElementClass(
                'title'
              )} ${this.getElementClass('deal-title')}`}>
              {deal.title}
            </div>
            <div
              className={`${this.getElementClass(
                'title'
              )} ${this.getElementClass('deal-description')}`}>
              {description}
            </div>
            {deal?.venueId?.locationDescription && (
              <div
                className={`${this.getElementClass(
                  'title'
                )} ${this.getElementClass('deal-location')}`}>
                {deal.venueId.locationDescription}
              </div>
            )}
            <div
              className={`${this.getElementClass(
                'title'
              )} ${this.getElementClass('deal-dates')}`}>
              <span>
                {new Date(deal.startDate).toLocaleDateString('en', {
                  month: 'short',
                  day: 'numeric',
                })}{' '}
                -{' '}
                {new Date(deal.endDate).toLocaleDateString('en', {
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
              <div className={this.getElementClass('arrow-icon-wrapper')}>
                <LoaderImage
                  src={arrowLinkIcon}
                  alt={'view'}
                  loadingHeight={20}
                  hideLoader
                  fluid
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

DealCard.propTypes = {
  deal: PropTypes.object,
};
