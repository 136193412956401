import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import {
  Modal,
  ContentLink,
  ContentfulRichText,
  ContentfulPlainText,
  getContentfulField,
  ResponsiveImage,
} from 'common/components';
import { track } from 'utils/analytics';
import { Component } from 'common/helpers';

import HomeSpotlight from './HomeSpotlight';

import './announcement.less';

@inject('announcements')
@observer
export default class Announcement extends Component {
  getImage(fields) {
    const image = getContentfulField(fields.image);
    if (!image) {
      return null;
    }
    const title = getContentfulField(fields.title);
    const file = getContentfulField(image.fields.file);
    const url = file.url;
    const dimensions = file.details.image;
    const ratio = dimensions.width / dimensions.height;
    return {
      url,
      title,
      ratio,
    };
  }

  trackPress(type) {
    const announcementType = getContentfulField(type);
    track('Announcement pressed', { label: announcementType });
  }

  render() {
    const { type, as: Component, instance } = this.props;

    let announcement;

    if (!instance) {
      announcement = this.props.announcements.get(type)?.object;
    } else {
      announcement = instance;
    }

    if (!announcement) {
      return null;
    }

    const { fields } = announcement;

    return <Component>{this.renderContent(fields)}</Component>;
  }

  renderContent(fields) {
    const type = getContentfulField(fields.type);
    const linkUrl = getContentfulField(fields.link);
    if (type === 'homepage-spotlight') {
      return this.renderHomeSpotlight(linkUrl, fields);
    } else if (linkUrl) {
      return this.renderLink(linkUrl, fields);
    } else if (fields.modalContent) {
      return this.renderModal(fields);
    }
  }

  renderHomeSpotlight(linkUrl, fields) {
    return <HomeSpotlight linkUrl={linkUrl} fields={fields} />;
  }

  renderLink(linkUrl, fields) {
    return (
      <ContentLink onClick={() => this.trackPress(fields.type)} href={linkUrl}>
        {this.renderLinkContent(fields)}
      </ContentLink>
    );
  }

  renderModal(fields) {
    return (
      <Modal size="tiny" trigger={this.renderLinkContent(fields)}>
        <ContentfulRichText field={fields.modalContent} autoplayVideo />
      </Modal>
    );
  }

  renderLinkContent(fields) {
    const { divided } = this.props;
    const { heading } = fields;
    const image = this.getImage(fields);
    return (
      <div className={this.getElementClass('link-content')}>
        {divided && <Divider />}
        {heading && <ContentfulPlainText field={fields.heading} />}
        {image && (
          <ResponsiveImage
            src={image.url}
            alt={image.title}
            ratio={image.ratio}
            fluid
          />
        )}
      </div>
    );
  }
}

Announcement.propTypes = {
  as: PropTypes.elementType,
  divided: PropTypes.bool,
};

Announcement.defaultProps = {
  as: 'div',
  divided: false,
};
