import React from 'react';
import { inject } from 'mobx-react';
import { Loader, Container } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { Spacer, Hero } from 'public/components';
import { NotFound } from '../NotFound';
import {
  Layout,
  ContentfulRichText,
  getContentfulField,
} from 'common/components';

import './careers.less';

@inject('pages')
export default class Careers extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      error: null,
      touched: false,
      current: 'pending',
    };
  }

  async routeDidUpdate() {
    try {
      const slug = 'careers';
      this.setState({
        page: await this.props.pages.fetchItemBySlug(slug),
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
        });
      } else {
        throw error;
      }
    }
  }

  renderHero() {
    if (this.state.page) {
      const asset = getContentfulField(this.state.page.object.fields.hero);
      if (asset) {
        const assetFile = getContentfulField(asset[0].fields.file);

        if (assetFile && assetFile.url) {
          return <Hero media={{ url: assetFile.url, ratio: 1 }} />;
        }
      }
    }
  }

  renderBody() {
    const { page, error } = this.state;

    if (error) {
      return <NotFound {...this.props} />;
    } else if (!page) {
      return <Loader inline="centered" active />;
    }
    return (
      <div {...this.getAttrs()}>
        {this.renderHero()}
        <Container className="white">
          <Layout vertical center>
            <Spacer size="m" />
            <Container className="tight">
              <h1>{page.name}</h1>
              <Spacer size="s" />
              <ContentfulRichText field={page.object.fields.body} />
              <Spacer size="m" />
              {/*
                  Iframe source is pointing to a public S3 bucket HTML file, that HTML file contains
                  a script that render HTML content from APPLOI, script cannot be loaded directly
                  in this component because it fails while trying to run "document.write".
                  Uploaded HTML file can be found in: services/web/externalcontent/apploi-tenant-careers.html
                */}
              <iframe
                src="https://ameream-external-content.s3.amazonaws.com/apploi-tenant-careers.html"
                className={this.getElementClass('container')}></iframe>
            </Container>
          </Layout>
        </Container>
      </div>
    );
  }
}
