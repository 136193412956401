import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { inject } from 'mobx-react';

import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';
import { track } from 'utils/analytics';
import { TicketOptionsModalControlled } from 'public/components/AttractionScreen/TicketOptionsModal/TicketOptionsModal';
import { parseAttraction } from 'public/helpers/contentful/parser';

import './button.less';

const YOUTUBE_REGEX =
  /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

function DisplayMedia({ url, children }) {
  const [isOpen, setOpen] = useState(false);
  const classNames = useClassName('HomepageButton');

  var [, , videoId] = url.match(YOUTUBE_REGEX);
  if (!videoId) return null;

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={children}
      open={isOpen}
      className={classNames('video-modal')}>
      <div className={classNames('close-modal')} onClick={() => setOpen(false)}>
        <SVGIcon name="close-light" size="mini" />
      </div>

      <iframe
        src={`${url}&autoplay=1&loop=1&playlist=${videoId}`}
        title="Hero video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
        allowfullscreen></iframe>
    </Modal>
  );
}

const WithTicketOptions = inject('venues')(function WithTicketOptions({
  cta,
  venues,
  children,
}) {
  const [state, setState] = useState({
    ticketOptions: [],
    isOpen: false,
    loaded: false,
    redirect: null,
  });
  const history = useHistory();
  const { venueSlug, url } = cta.fields;

  const getTicketOptions = async (e) => {
    if (!venueSlug) return;

    e.preventDefault();
    e.stopPropagation();

    try {
      const venue = await venues.fetchItemBySlug(venueSlug);

      const attractionScreen = parseAttraction(venue.content.attractionScreen);
      if (!attractionScreen.ticketOptions.length) {
        setState((state) => ({
          ...state,
          loaded: true,
          redirect: url,
        }));
        return;
      }

      setState((state) => ({
        ...state,
        ticketOptions: attractionScreen.ticketOptions,
        isOpen: true,
        loaded: true,
      }));
    } catch (e) {
      setState((state) => ({
        ...state,
        loaded: true,
        redirect: url,
      }));
    }
  };

  useEffect(() => {
    if (state.redirect) {
      if (cta.fields.type === 'external-link') {
        window.open(state.redirect, '_blank');
      } else {
        history.push(state.redirect);
      }
    }
  }, [state.redirect]);

  return children(
    getTicketOptions,
    Boolean(venueSlug && state.ticketOptions.length) && (
      <TicketOptionsModalControlled
        onClick={() => {}}
        options={state.ticketOptions}
        isOpen={state.isOpen}
        setOpen={(isOpen) => {
          setState((state) => {
            return {
              ...state,
              isOpen: isOpen,
            };
          });
        }}
      />
    )
  );
});

function Wrapper({ cta, children }) {
  const { venueSlug } = cta.fields;
  if (!venueSlug) {
    return children();
  }

  return <WithTicketOptions cta={cta}>{children}</WithTicketOptions>;
}

function Content({ cta, children, fixed }) {
  const classNames = useClassName('HomepageButton');
  const { icon, label, isHidden, colors } = cta.fields;
  const { fontColor = '#FFFFFF' } = colors?.fields || {};

  return isHidden ? (
    children
  ) : (
    <>
      {icon && (
        <img
          className={classNames('logo')}
          src={icon.fields.file.url}
          alt={icon.fields.title}
        />
      )}
      <span>{label}</span>
      {!fixed && (
        <SVGIcon
          name="arrow-right"
          className={classNames('arrow')}
          style={{ stroke: fontColor }}
        />
      )}
    </>
  );
}

function HomepageButton({ className, style, cta, children, fixed }) {
  const classNames = useClassName('HomepageButton');

  if (!cta) {
    if (children) {
      return (
        <div className={className} style={style}>
          {children}
        </div>
      );
    }

    return null;
  }

  const { label, url, type, isHidden, colors } = cta.fields;
  const {
    backgroundColor = '#000000',
    backgroundHoverColor = '#000000',
    fontColor = '#FFFFFF',
    fontHoverColor = '#FFFFFF',
  } = colors?.fields || {};

  const wrapper = isHidden
    ? {
        className: `${className}`,
        style,
      }
    : {
        className: `${className} ${classNames('container')} ${
          fixed && 'fixed'
        }`,
        style: {
          background: backgroundColor,
          '--background-hover-color': backgroundHoverColor,
          color: fontColor,
          '--font-hover-color': fontHoverColor,
          ...style,
        },
      };

  return (
    <Wrapper cta={cta}>
      {(getTicketOptions, modal) => {
        const onClick = (e) => {
          track('Homepage button pressed', { label, url, type });
          getTicketOptions && getTicketOptions(e);
        };

        return (
          <>
            {{
              'external-link': () => (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...wrapper}
                  onClick={onClick}>
                  <Content cta={cta} fixed={fixed}>
                    {children}
                  </Content>
                </a>
              ),
              'open-media': () => (
                <DisplayMedia url={url}>
                  <a
                    onClick={(e) => {
                      onClick();
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...wrapper}>
                    <Content cta={cta} fixed={fixed}>
                      {children}
                    </Content>
                  </a>
                </DisplayMedia>
              ),
              'internal-link': () => (
                <Link to={url} {...wrapper} onClick={onClick}>
                  <Content cta={cta} fixed={fixed}>
                    {children}
                  </Content>
                </Link>
              ),
            }[type]()}
            {modal}
          </>
        );
      }}
    </Wrapper>
  );
}

export default HomepageButton;
