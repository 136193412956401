import React from 'react';
import { Responsive } from 'semantic-ui-react';

export default class DesktopOnly extends React.Component {
  render() {
    return (
      <Responsive minWidth={Responsive.onlyMobile.maxWidth} {...this.props} />
    );
  }
}
