import React from 'react';
import { PropTypes } from 'prop-types';
import { useClassName } from 'common/hooks';
import './change-container.less';

const ChangeContainer = ({ title, children }) => {
  const className = useClassName('ChangeContainer');

  return (
    <div className={className('main')}>
      <p className={className('title')}>{title}</p>
      {children}
    </div>
  );
};

ChangeContainer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ChangeContainer;
