import React from 'react';
import { inject, observer } from 'mobx-react';

import { Screen } from 'public/helpers';
import { ContentfulImage, SVGIcon } from 'common/components';
import CTA from 'public/components/AttractionScreen/CTA';
import { Resize, WindowSize } from 'common/hooks';

import './birthday-form-submitted.less';

@inject('pages', 'checkout', 'birthdays')
@observer
export default class BirthdayFormSubmitted extends Screen {
  async componentDidMount() {
    super.componentDidMount();
    this.getPage();
  }

  async getPage() {
    const { slug } = (this.props.match && this.props.match.params) || {};
    const parties = await this.props.birthdays.getParties(slug);

    this.setState({ parties: parties });
  }

  renderBody() {
    return (
      <Resize element={document.querySelector('.desktop-header')}>
        {(headerSize) => {
          return (
            <WindowSize>
              {(windowSize) => {
                return (
                  <div className={this.getElementClass('wrapper')}>
                    <div className={this.getElementClass('container')}>
                      <SVGIcon name="success" size="large" />
                      <h1>Thank you!</h1>
                      <h3>
                        You will be soon contacted by a member of American Dream
                        to complete your Birthday Booking.
                      </h3>
                      <CTA
                        text="Continue browsing our venues"
                        link="/directory"
                      />
                    </div>
                    {this.state.parties?.formSubmittedImage && (
                      <div className={this.getElementClass('banner')}>
                        <ContentfulImage
                          field={this.state.parties?.formSubmittedImage}
                          height={windowSize.height - headerSize.height}
                        />
                      </div>
                    )}
                  </div>
                );
              }}
            </WindowSize>
          );
        }}
      </Resize>
    );
  }
}
