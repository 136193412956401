import React from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import { SVGIcon as Icon } from '../../SVGIcon';
import { Component } from '../../../helpers';
import { Layout } from '../../Layout';

import COUNTRIES from './countries.json';

import SPRITE_1x from './sprite-1x.png';
import SPRITE_2x from './sprite-2x.png';

import './tel-input.less';

const SPRITE_WIDTH = 24;
const DEFAULT_CODE = 'us';
const INVALID_CHARS_REG = /[^\d\s()-]/g;
const STRIP_SPECIAL_REG = /[\s()-]/g;

const PRIORITY_COUNTRIES = COUNTRIES.filter((c) => c.priority > 0).sort(
  (a, b) => {
    return b.priority - a.priority;
  }
);

const OTHER_COUNTRIES = COUNTRIES.filter((c) => c.priority === 0);

const COUNTRIES_BY_CODE = COUNTRIES.concat().sort((a, b) => {
  return a.code < b.code ? -1 : 1;
});

export default class TelInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      code: DEFAULT_CODE,
      prefix: this.findPrefix(DEFAULT_CODE),
    };
    this.ref = React.createRef();
  }

  getSpriteImageUrl() {
    return window.devicePixelRatio > 1 ? SPRITE_2x : SPRITE_1x;
  }

  findPrefix(code) {
    return COUNTRIES.find((c) => c.code === code).prefix;
  }

  getSpriteOffset(code) {
    const index = COUNTRIES_BY_CODE.findIndex((c) => c.code === code);
    return index * -SPRITE_WIDTH;
  }

  setCode = (code) => {
    const prefix = this.findPrefix(code);
    this.setState({
      code,
      prefix,
    });
    this.fireChange(prefix, this.state.number);
    this.ref.current.focus();
  };

  onInputChange = (evt) => {
    const { value } = evt.target;
    const number = value.replace(INVALID_CHARS_REG, '').replace(/\s+/g, ' ');
    this.setState({
      number,
    });
    this.fireChange(this.state.prefix, number);
  };

  fireChange(prefix, number) {
    if (number) {
      const value = (prefix + number).replace(STRIP_SPECIAL_REG, '');
      this.props.onChange(value);
    } else {
      this.props.onChange(null);
    }
  }

  render() {
    const { number } = this.state;
    const { onChange, ...rest } = this.props;
    return (
      <div {...this.getAttrs()}>
        <Layout horizontal center padded>
          <Layout.Group>{this.renderDropdown()}</Layout.Group>
          <Layout.Group grow>
            <Input
              type="tel"
              ref={this.ref}
              value={number}
              onChange={this.onInputChange}
              {...rest}
            />
          </Layout.Group>
        </Layout>
      </div>
    );
  }

  renderDropdown() {
    const { code } = this.state;
    return (
      <Dropdown
        trigger={this.renderFlagIcon(code)}
        className={this.getElementClass('dropdown')}
        icon={
          <Icon
            name="angle-down"
            size="mini"
            className={this.getElementClass('dropdown-icon')}
          />
        }
        scrolling>
        <Dropdown.Menu>
          {this.renderDropdownItems(PRIORITY_COUNTRIES)}
          <Dropdown.Divider />
          {this.renderDropdownItems(OTHER_COUNTRIES)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderDropdownItems(items) {
    return items.map((country) => {
      const { name, code, prefix } = country;
      return (
        <Dropdown.Item key={code} onClick={() => this.setCode(code)}>
          {this.renderFlagIcon(code)}
          <span>{name}</span> <span>{prefix}</span>
        </Dropdown.Item>
      );
    });
  }

  renderFlagIcon(code) {
    return (
      <span
        style={{
          backgroundImage: `url(${this.getSpriteImageUrl()})`,
          backgroundPosition: `${this.getSpriteOffset(code)}px 0px`,
        }}
        className={this.getElementClass('flag-icon')}
      />
    );
  }
}
