import React from 'react';

import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';

import './message.less';

const TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

const ICONS = {
  [TYPES.WARNING]: 'warning',
  [TYPES.SUCCESS]: 'success',
  [TYPES.INFO]: 'info',
};

export const Message = ({ content, type, className }) => {
  const classNames = useClassName('Message');

  return (
    <div
      className={`${classNames('container')} ${classNames(type)} ${className}`}>
      <SVGIcon name={ICONS[type]} size="small" />
      <div className={`${classNames('content')}`}>{content}</div>
    </div>
  );
};

export const WarningMessage = ({ content, className }) => (
  <Message content={content} type="warning" className={className} />
);

export const InfoMessage = ({ content, className }) => (
  <Message content={content} type="info" className={className} />
);
