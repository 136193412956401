import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const ErrorMessage = ({ title, errors }) => (
  <Message negative>
    {title && <Message.Header>{title}</Message.Header>}
    <Message.List>
      {errors.map((error, index) => (
        <Message.Item key={`error-${index}`}>{error}</Message.Item>
      ))}
    </Message.List>
  </Message>
);

ErrorMessage.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.array.isRequired,
};

ErrorMessage.defaultProps = {
  title: null,
};

export default ErrorMessage;
