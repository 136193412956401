import React from 'react';
import { Screen } from 'public/helpers';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { TicketCard, Spacer } from 'public/components';
import { Layout } from 'common/components';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import { ProfileGroup } from './components';

export default class ProfileTickets extends Screen {
  state = {
    result: null,
    loadingPage: true,
  };

  componentDidMount() {
    super.componentDidMount();
    request({
      method: 'GET',
      path: '/1/users/me/tickets',
    })
      .then(({ data }) => {
        this.setState({ result: data, loadingPage: false });
      })
      .catch((error) => {
        this.setState({
          error,
          loadingPage: false,
        });
      });
  }

  groupTickets(tickets = []) {
    const venues = {};

    if (!tickets) return [];

    const sortedTickets = tickets.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1
    );

    sortedTickets.forEach((ticket) => {
      const array = venues[ticket.venue.id];
      if (!array) {
        venues[ticket.venue.id] = [ticket];
      } else {
        array.push(ticket);
      }
    });
    return Object.keys(venues).map((key) => {
      const tickets = venues[key];
      return {
        venue: tickets[0].venue,
        tickets,
      };
    });
  }

  renderBody() {
    const { error, loadingPage, result } = this.state;

    const groupedItems = this.groupTickets(result);
    return (
      <ProfileGroup {...this.props}>
        {error && <Message error content={error.message} />}
        {!loadingPage && result && result.length === 0 && (
          <Message content="No tickets purchased yet" />
        )}

        {groupedItems.length > 0 &&
          groupedItems.map(({ venue, tickets }) => {
            const isAttraction = venue?.venueType === 'attraction';
            return (
              <React.Fragment key={venue.id}>
                <Layout horizontal stackable>
                  <Layout.Group grow>
                    <h3>{venue.name}</h3>
                  </Layout.Group>
                  <Layout.Group>
                    {isAttraction && (
                      <Link
                        to={`/venue/${venue.slug}`}
                        className="venue-details-link">
                        Venue Details
                      </Link>
                    )}
                  </Layout.Group>
                </Layout>
                <Spacer size="xs" />
                {tickets.map((ticket) => (
                  <TicketCard key={ticket.id} ticket={ticket} />
                ))}
              </React.Fragment>
            );
          })}
        <Spacer size="l" />
      </ProfileGroup>
    );
  }
}

ProfileTickets.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

ProfileTickets.defaultProps = {
  contentfulSlugItem: 'my-tickets',
};
