import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import { LoaderImage } from 'public/components/Loader';
import FeaturedCard from './FeaturedCard';
import SectionTitle from './SectionTitle';
import { DesktopOnly } from 'common/components';

import '../directory.less';

import featuredSlideLeftAction from 'common/assets/directory-featured-slide-action-left.svg';
import featuredSlideRightAction from 'common/assets/directory-featured-slide-action-right.svg';

const FeaturedSection = (props) => {
  const { featuredItems, slideRef, show = true, title = true } = props;

  const renderFeatured = (venue) => (
    <Grid.Column key={venue._id} width={8}>
      <FeaturedCard venue={venue} />
    </Grid.Column>
  );

  const nextFeatured = (scrollOffset) => {
    slideRef.current.scrollLeft += scrollOffset;
  };

  const shouldRenderFeaturedSection = featuredItems?.length > 0 && show;
  const renderControlActions = featuredItems.length > 1;

  if (!shouldRenderFeaturedSection) return null;

  return (
    <>
      {title === true && <SectionTitle title="Featured" />}
      <Segment className="featured-slide-wrapper">
        {renderControlActions && (
          <DesktopOnly className="control">
            <LoaderImage
              src={featuredSlideLeftAction}
              alt={'next'}
              loadingHeight={20}
              hideLoader
              fluid
              className="left-action"
              onClick={() => nextFeatured(-800)}
            />
          </DesktopOnly>
        )}
        {renderControlActions && (
          <DesktopOnly className="control">
            <LoaderImage
              src={featuredSlideRightAction}
              alt={'previus'}
              loadingHeight={20}
              hideLoader
              fluid
              className="right-action"
              onClick={() => nextFeatured(800)}
            />
          </DesktopOnly>
        )}
        <div className="sub-categories-wrapper" ref={slideRef}>
          {featuredItems.map(renderFeatured)}
        </div>
      </Segment>
    </>
  );
};

FeaturedSection.propTypes = {
  featuredItems: PropTypes.array.isRequired,
  show: PropTypes.bool,
  slideRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default FeaturedSection;
