import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { isNumber } from 'lodash';

import { useClassName, useWindowSize } from 'common/hooks';
import { SVGIcon, ContentfulImage } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';
import { formatCurrency } from 'utils/l10n';

import { NewCheckoutQuantityControls } from '../../../Components/QuantityControls';

import './variants-modal.less';

const VariantsModal = ({
  product,
  isOpen,
  setOpen,
  setQuantities,
  quantities,
  isOptionSoldOut,
}) => {
  const classNames = useClassName('VariantsModal');
  const [selected, setSelected] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (!isOpen) {
      setSelected({});
      setSelectedVariant(null);
    } else {
      setSelected(quantities);
    }
  }, [isOpen]);

  const isVariantSelected = () =>
    Boolean(!product?.variants || selectedVariant);

  const isProductAvailable = (prod) => {
    return prod && (!isNumber(prod.maxQuantity) || prod.maxQuantity > 0);
  };

  const getProducts = () => {
    if (!selectedVariant) return product?.products.filter(isProductAvailable);

    return product.variants.options
      .find((a) => a.name === selectedVariant.name)
      .products.map(({ name, rollerName }) => {
        const prod = product?.products.find((a) => a.name === rollerName);
        if (!prod) return false;

        return {
          ...prod,
          name,
        };
      })
      .filter(isProductAvailable);
  };

  const getContent = () => {
    if (isVariantSelected()) {
      return (
        <div className={classNames('list')}>
          {(getProducts() || []).map((product, index) => (
            <div key={index} className={classNames('item')}>
              <div className={classNames('info')}>
                <span>{product.name}</span>
                <span>{formatCurrency(product.cost * 100)}</span>
              </div>
              <NewCheckoutQuantityControls
                quantity={selected[product.id] || 0}
                onChange={(quantity) =>
                  setSelected({ ...selected, [product.id]: quantity })
                }
                {...(isNumber(product.maxQuantity) && {
                  max: product.maxQuantity,
                })}
              />
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={classNames('list')}>
        <ContentfulImage
          field={product.variants.images}
          width={1000}
          className={classNames('image')}
        />
        <div className={classNames('options')}>
          {product.variants.options.map((option, index) => (
            <div key={index} className={classNames('option')}>
              <span>{option.name}</span>
              {isOptionSoldOut(option) ? (
                <div className={classNames('sold-out-label')}>Sold Out</div>
              ) : (
                <Button onClick={() => setSelectedVariant(option)}>
                  {isMobile ? option.mobileSelectText : option.selectText}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const footer = isVariantSelected() ? (
    <Button
      primary
      fluid={isMobile}
      onClick={() => {
        setQuantities(selected);
        setOpen(false);
      }}>
      Add to Cart
    </Button>
  ) : null;

  if (!product) return null;

  if (isMobile) {
    return (
      <BottomSheet
        onClose={() => setOpen(false)}
        title={selectedVariant?.name || product.name}
        isOpen={isOpen}
        content={getContent()}
        footer={footer}
        onBack={selectedVariant && (() => setSelectedVariant(null))}
      />
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setOpen(false)}
      className={classNames('modal')}
      centered={false}>
      <Modal.Content className={classNames('content')}>
        <div className={classNames('header')}>
          <span>
            {selectedVariant && (
              <div
                className={classNames('back')}
                onClick={() => setSelectedVariant(null)}>
                <SVGIcon name="angle-left" />
              </div>
            )}
            {selectedVariant?.name || product.name}
          </span>
          <div onClick={() => setOpen(false)}>
            <SVGIcon name="close" />
          </div>
        </div>
        {getContent()}
      </Modal.Content>
      {isVariantSelected() && (
        <div className={classNames('footer')}>
          <div className={classNames('actions')}>{footer}</div>
        </div>
      )}
    </Modal>
  );
};

VariantsModal.propTypes = {
  product: PropTypes.object,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  setQuantities: PropTypes.func,
  quantities: PropTypes.object,
  isOptionSoldOut: PropTypes.func,
};

export default VariantsModal;
