import React from 'react';
import { Redirect } from 'react-router-dom';

export default class SuccessRedirect extends React.Component {
  getParamsUrl() {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      return params.get('return');
    }
  }

  getDefaultUrl() {
    return '/profile/orders';
  }

  render() {
    return <Redirect to={this.getParamsUrl() || this.getDefaultUrl()} />;
  }
}
