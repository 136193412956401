import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Component } from 'common/helpers';
import { Layout } from 'common/components';

import { FieldContainer } from '../FieldContainer';
import { WarningMessage } from '../Message';
import PhoneNumberInput from './PhoneNumberInput';

import './signupForm.less';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { fieldErrors, formData, validate, setField, error } = this.props;

    return (
      <Form onSubmit={this.onSubmit} noValidate>
        <div className={this.getElementClass('names')}>
          <FieldContainer error={fieldErrors.firstName}>
            <label htmlFor="firstName">First Name</label>
            <Input
              fluid
              value={formData.firstName}
              onChange={setField}
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="given-name"
              onBlur={() => validate('firstName')}
            />
          </FieldContainer>
          <FieldContainer error={fieldErrors.lastName}>
            <label htmlFor="lastName">Last Name</label>
            <Input
              fluid
              value={formData.lastName}
              onChange={setField}
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="family-name"
              onBlur={() => validate('lastName')}
            />
          </FieldContainer>
        </div>
        <FieldContainer error={fieldErrors.email}>
          <label htmlFor="email">Email Address</label>
          <Input
            fluid
            value={formData.email}
            onChange={setField}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            onBlur={() => validate('email')}
          />
        </FieldContainer>
        <FieldContainer>
          <label htmlFor="phone">Phone Number</label>
          <PhoneNumberInput
            onChange={(value) => setField({ target: { name: 'phone', value } })}
            id="phone"
            name="phone"
            onBlur={() => validate('phone')}
            error={fieldErrors.phone}
          />
        </FieldContainer>
        <FieldContainer error={fieldErrors.zipCode}>
          <label htmlFor="zipCode">Zip / Postal Code</label>
          <Input
            fluid
            value={formData.zipCode}
            onChange={setField}
            id="zipCode"
            name="zipCode"
            type="text"
            onBlur={() => validate('zipCode')}
          />
        </FieldContainer>
        <FieldContainer error={fieldErrors.password}>
          <label htmlFor="password">Password</label>
          <Input
            fluid
            value={formData.password}
            onChange={setField}
            id="password"
            type="password"
            name="password"
            onBlur={() => validate('password')}
          />
        </FieldContainer>

        <Layout horizontal>
          <Layout.Group>
            <Checkbox
              id="termsAccepted"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={setField}
            />
          </Layout.Group>
          <Layout.Group grow className={this.getElementClass('checkbox-label')}>
            <label htmlFor="termsAccepted">
              <p>
                I agree to the{' '}
                <Link
                  className={this.getElementClass('terms-link')}
                  to="/terms">
                  Terms and Conditions
                </Link>
                {` and `}
                <Link
                  className={this.getElementClass('terms-link')}
                  to="/privacy">
                  Privacy Policy
                </Link>
              </p>
            </label>
          </Layout.Group>
        </Layout>

        <Layout horizontal>
          <Layout.Group>
            <Checkbox
              id="emailAccepted"
              name="emailAccepted"
              checked={formData.emailAccepted}
              onChange={setField}
            />
          </Layout.Group>
          <Layout.Group grow className={this.getElementClass('checkbox-label')}>
            <label htmlFor="emailAccepted">
              <p>
                Sign me up for exclusive American Dream sweepstakes, promotions,
                emails, events, and updates.
              </p>
            </label>
          </Layout.Group>
        </Layout>
        {error && <WarningMessage content={error.message} />}
        {fieldErrors.termsAccepted && (
          <WarningMessage content={fieldErrors.termsAccepted} />
        )}
      </Form>
    );
  }
}

SignupForm.propTypes = {
  fieldErrors: PropTypes.object,
  formData: PropTypes.object,
  validate: PropTypes.func,
  setField: PropTypes.func,
  error: PropTypes.instanceOf(Error),
};

export default SignupForm;
