import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { Loader, Dimmer } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { parseHoursPage } from 'public/helpers/contentful/parser';
import { HorizontalDatePicker } from 'public/components';
import { useClassName, useWindowSize } from 'common/hooks';
import { NotFound } from '../NotFound';
import Visit from './components/Visit';
import Hours from './components/Hours';
import CallOuts from './components/CallOuts';
import './hours-and-directions.less';

const HoursAndDirections = ({ property, venues, externalObjects }) => {
  const className = useClassName('HoursAndDirections');
  const startDate = DateTime.local().toFormat('yyyy-MM-dd');
  const { isMobile } = useWindowSize();
  const [hoursPage, setHoursPage] = useState();
  const [date, setDate] = useState(startDate);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [hoursData, setHoursData] = useState({
    attractionVenues: null,
    shoppingVenues: null,
    retailHours: null,
    diningHours: null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContentAndHours();
  }, []);

  const fetchContentAndHours = async () => {
    try {
      await Promise.all([fetchContent(), fetchHours()]);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  };

  const fetchContent = async () => {
    const externalObject = await externalObjects.search({
      source: 'contentful',
      objectType: 'hoursPage',
    });
    setHoursPage(parseHoursPage(externalObject.data[0]));
  };

  const fetchHours = async () => {
    const retailHours = await property.fetchOpeningHours(date, 'retail');
    const diningHours = await property.fetchOpeningHours(date, 'dining');
    const venuesHours = await venues.getAllOpenHours();
    const shoppingVenues = venuesHours.filter(
      (venue) => venue.venueType === 'shopping'
    );
    const attractionVenues = venuesHours.filter(
      (venue) => venue.venueType === 'attraction'
    );
    setHoursData({
      attractionVenues: attractionVenues
        .filter((venue) => venue.hoursDisplayOrder)
        .sort((a, b) => a.hoursDisplayOrder - b.hoursDisplayOrder)
        .concat(attractionVenues.filter((venue) => !venue.hoursDisplayOrder)),
      shoppingVenues,
      retailHours,
      diningHours,
    });
  };

  const onDateSelected = (date) => setDate(date);

  if (error) return <NotFound />;

  if (loading)
    return (
      <Dimmer active inverted>
        <Loader size="medium">Loading</Loader>
      </Dimmer>
    );

  return (
    <div className={className('container')}>
      <div className={className('header')}>
        <div
          className={className('title-container')}
          style={{ backgroundColor: hoursPage?.heroBackground }}>
          <div className={className('title-content')}>
            {hoursPage.overline && (
              <p className={className('overline')}>{hoursPage.overline}</p>
            )}
            <h1 className={className('title')}>{hoursPage.title}</h1>
          </div>
        </div>
        <div className={className('image-container')}>
          <img
            className={className('image')}
            src={
              isMobile
                ? hoursPage.mobileImage.assetUrl
                : hoursPage.desktopImage.assetUrl
            }
            alt={
              isMobile
                ? hoursPage.mobileImage.assetAlt
                : hoursPage.desktopImage.assetAlt
            }
          />
        </div>
      </div>
      <div className={className('content')}>
        <h2 className={className('open-hours-title')}>Open hours</h2>
        <div className={className('date-picker-container')}>
          <HorizontalDatePicker
            startDate={startDate}
            days={7}
            selectedDate={date}
            hiddenControls={false}
            onDateSelected={onDateSelected}
          />
          {hoursPage.callOuts && (
            <CallOuts callOuts={hoursPage.callOuts} selectedDate={date} />
          )}
        </div>
        <Hours hoursData={hoursData} hoursPage={hoursPage} date={date} />
      </div>
      <Visit hoursPage={hoursPage} />
    </div>
  );
};

export default inject(
  'property',
  'venues',
  'externalObjects'
)(HoursAndDirections);
