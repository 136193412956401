import * as Sentry from '@sentry/browser';
import { SENTRY_DSN, ENV } from '../env/client';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
      'TypeError: cancelled',
      'NotFoundError: Not Found',
      'Error: ResizeObserver loop limit exceeded',
      'Error: Extension context invalidated',
    ],
  });
}
