import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import './sub-category-tag.less';

const SubCategoryTag = ({ label, selected = false }) => {
  const className = useClassName('SubCategoryTag');
  const accentStyle = selected ? 'category-selected' : 'category-unselected';
  return (
    <div className={className(`category-button ${accentStyle}`)}>{label}</div>
  );
};

SubCategoryTag.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default SubCategoryTag;
