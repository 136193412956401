import React from 'react';
import { Container } from 'semantic-ui-react';
import {
  Layout,
  ContentfulImage,
  getContentfulField,
  MobileOnly,
  DesktopOnly,
} from 'common/components';
import { Screen } from 'public/helpers';
import { GroupSalesForm, Spacer } from 'public/components';
import { propTypes, defaultProps } from './GroupSalesProps';

import './group-sales.less';

const flatHeroImages = (hero) => {
  if (!hero) return null;

  return Object.fromEntries(
    Object.keys(hero).map((lang) => [lang, getContentfulField(hero, lang)[0]])
  );
};

class GroupSales extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      loading: true,
      hero: null,
      heroMobile: null,
    };
  }

  onFetchPage(page) {
    this.setState({
      hero: flatHeroImages(page.object.fields.hero),
      heroMobile: flatHeroImages(page.object.fields.heroMobile),
    });
  }

  renderHero() {
    return (
      <>
        <DesktopOnly>
          <ContentfulImage
            className={this.getElementClass('image')}
            field={this.state.hero}
            width={1500}
          />
        </DesktopOnly>
        <MobileOnly>
          <ContentfulImage
            className={this.getElementClass('image')}
            field={this.state.heroMobile}
            width={500}
          />
        </MobileOnly>
      </>
    );
  }

  renderBody() {
    return (
      <React.Fragment>
        {this.renderHero()}
        <Spacer size="xs" desktop />
        <Container className="white">
          <Layout vertical center>
            <Container className="tight">
              <Spacer size="m" />
              <GroupSalesForm
                fields={this.props.fields}
                brazeTriggers={this.props.brazeTriggers}
                onFetchPage={this.onFetchPage.bind(this)}
                onCloseClick={() => this.props.history.goBack()}
              />
              <Spacer size="m" />
            </Container>
          </Layout>
        </Container>
      </React.Fragment>
    );
  }
}

GroupSales.propTypes = propTypes;
GroupSales.defaultProps = defaultProps;

export default GroupSales;
