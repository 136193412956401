import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { scrollTo } from 'utils/helpers/scroll';
import { useClassName, useResize } from 'common/hooks';
import { SVGIcon } from 'common/components';

import './tabs.less';

const OFFSET = 12;

const useArrowControls = (containerRef, children) => {
  const [leftHidden, setLeftHidden] = useState(true);
  const [rightHidden, setRightHidden] = useState(true);
  const { width } = useResize(document.body);

  const updateControls = () => {
    if (!containerRef.current) return;

    setLeftHidden(containerRef.current.scrollLeft === 0);
    setRightHidden(
      containerRef.current.scrollLeft + containerRef.current.offsetWidth >
        containerRef.current.scrollWidth - OFFSET
    );
  };

  useEffect(() => {
    const onScroll = () => updateControls();

    containerRef.current.addEventListener('scroll', onScroll);

    return () => containerRef.current.removeEventListener('scroll', onScroll);
  }, []);

  useLayoutEffect(() => {
    updateControls();
  }, [width, children.length]);

  return { leftHidden, rightHidden };
};

const useWaitInitialScrollSize = (containerRef, children) => {
  const [scrollWidth, setScrollWidth] = useState(0);

  useLayoutEffect(() => {
    if (containerRef?.current?.children?.length === children.length) {
      const interval = setInterval(() => {
        setScrollWidth(containerRef.current?.scrollWidth);
      }, 100);

      return () => clearInterval(interval);
    }
  }, [containerRef?.current?.children?.length, children.length]);

  return [scrollWidth];
};

export const BaseTab = ({ fixed, containerRef, children, selectedIndex }) => {
  const classNames = useClassName('Tabs');
  const { leftHidden, rightHidden } = useArrowControls(containerRef, children);
  const [scrollWidth] = useWaitInitialScrollSize(containerRef, children);

  useLayoutEffect(() => {
    if (selectedIndex) {
      const tab = containerRef.current.children[selectedIndex];

      if (tab) {
        containerRef.current.scrollTo({
          left: tab.offsetLeft - OFFSET,
          behavior: 'smooth',
        });
      }
    }
  }, [scrollWidth, selectedIndex]);

  const getChildrenOffsets = () =>
    Array.from(containerRef.current.children).map(
      (tab) => tab.offsetLeft - OFFSET
    );

  const scrollHorizontal = (offsetLeft) =>
    containerRef.current.scrollTo({ left: offsetLeft, behavior: 'smooth' });

  return (
    <div className={`${classNames('wrapper')} ${fixed && classNames('fixed')}`}>
      <SVGIcon
        name="angle-left"
        onClick={() => {
          const offsets = getChildrenOffsets().reverse();
          offsets.shift();

          const offsetLeft = offsets.find(
            (offsetLeft) =>
              offsetLeft < containerRef.current.scrollLeft - OFFSET
          );

          scrollHorizontal(offsetLeft);
        }}
        className={`${classNames('left')} ${
          leftHidden ? classNames('hidden') : ''
        }`}
      />
      <div className={classNames('no-overflow')}>
        <div
          className={`${classNames('container')} ${
            !leftHidden ? classNames('blur-left') : ''
          } ${!rightHidden ? classNames('blur-right') : ''}`}
          ref={containerRef}>
          {children}
        </div>
      </div>
      <SVGIcon
        name="angle-right"
        onClick={() => {
          const offsets = getChildrenOffsets();
          offsets.shift();

          const offsetLeft = offsets.find(
            (offsetLeft) =>
              offsetLeft > containerRef.current.scrollLeft + OFFSET
          );

          scrollHorizontal(offsetLeft);
        }}
        className={`${classNames('right')} ${
          rightHidden ? classNames('hidden') : ''
        }`}
      />
    </div>
  );
};

const RefTabs = ({ names, fixed, refs }) => {
  const classNames = useClassName('Tabs');
  const [selectedTab, setSelectedTab] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.scrollY;
      const selectedTab = refs.current.findIndex((a, index) => {
        if (index === 0 && scrollY < a.offsetTop) {
          return true;
        }

        if (a.offsetTop > scrollY) {
          return false;
        }

        if (refs.current.length === index + 1) return true;

        return scrollY < refs.current[index + 1].offsetTop;
      });

      setSelectedTab(selectedTab);
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, [names]);

  useEffect(() => {
    if (selectedTab === null) return;
    if (!refs.current[selectedTab]) return;

    containerRef.current.scrollTo({
      left:
        selectedTab === 0
          ? 0
          : containerRef.current.children[selectedTab].offsetLeft - OFFSET,
      behavior: 'smooth',
    });
  }, [selectedTab]);

  const onClickTab = (index) => {
    scrollTo(refs.current[index], -48);
  };

  return (
    <BaseTab fixed={fixed} containerRef={containerRef}>
      {names.map((name, index) => (
        <span
          className={`${index === selectedTab ? classNames('selected') : ''}`}
          key={index}
          onClick={() => onClickTab(index)}>
          {name}
        </span>
      ))}
    </BaseTab>
  );
};

const LinkTabs = ({ links, isSelected, fixed }) => {
  const classNames = useClassName('Tabs');
  const containerRef = useRef(null);

  const selectedIndex = links.findIndex((link, index) => isSelected(index));
  return (
    <BaseTab
      fixed={fixed}
      containerRef={containerRef}
      selectedIndex={selectedIndex}>
      {links.map((link, index) => (
        <Link
          to={link.url}
          key={index}
          className={isSelected(index) ? classNames('selected') : ''}>
          {link.title}
        </Link>
      ))}
    </BaseTab>
  );
};

const ControlledTabs = ({ names, selectedIndex, onClick, fixed }) => {
  const classNames = useClassName('Tabs');
  const containerRef = useRef(null);

  return (
    <BaseTab
      fixed={fixed}
      containerRef={containerRef}
      selectedIndex={selectedIndex}>
      {names.map((name, index) => (
        <span
          className={`${index === selectedIndex ? classNames('selected') : ''}`}
          key={index}
          onClick={() => onClick(name)}>
          {name}
        </span>
      ))}
    </BaseTab>
  );
};

LinkTabs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  isSelected: PropTypes.func.isRequired,
  fixed: PropTypes.bool,
};

RefTabs.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  fixed: PropTypes.bool,
  refs: PropTypes.any,
};

export { LinkTabs, RefTabs, ControlledTabs };
