import React from 'react';
import { Screen, withVenue } from 'public/helpers';
import { AttractionScreen } from 'public/components';

@withVenue('sealifeaquarium')
export default class SeaLifeHome extends Screen {
  constructor(props) {
    super(props);
  }

  renderBody() {
    const { venue } = this.props;
    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }
    return null;
  }
}
