import React, { useEffect, useState } from 'react';
import { Button, Form, Loader, Message } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { useClassName } from 'common/hooks';
import { getContentfulField, TelInput, DatePicker } from 'common/components';
import { parseTextBlock } from 'public/helpers/contentful/parser';
import { ContentfulAsset, ContentfulTextBlock } from '../ModularComponents';
import { isValidEmail, isValidPhone } from 'utils/helpers/validations';
import { identify, track } from 'utils/analytics';
import { Spacer } from '../Spacer';
import {
  defaultProps,
  propTypes,
} from '../../screens/PrivateEvents/PrivateEventsProps';
import { DEFAULT_GROUP_ID, SMS_GROUP_ID } from '../../../common/const';

import './private-events-form.less';

const DEFAULT_OPTION = 'Select';

const DEFAULT_GROUP_TYPE_OPTIONS = [
  'Birthday Party',
  'Buyout/Private Rental',
  'Camp',
  'Corporate',
  'Just for Fun',
  'Non-Profit',
  'School Trip',
  'Tour',
  'Reseller',
  'Physics Day',
  'Other',
];

const DEFAULT_VENUE_OPTIONS = [
  'Angry Birds Mini Golf',
  'Big Snow',
  'DreamWorks Water Park',
  'Legoland Discovery Center',
  'New Jersey SEA LIFE Aquarium',
  'Nickelodeon Universe',
  'Blacklight Mini Golf',
  'The Rink',
  'Other',
];

const REQUIRED_FIELDS = ['firstName', 'lastName', 'email', 'phone'];

const DEFAULT_SUCCESS_MESSAGE =
  'Thank you for submitting the group request form! Our dream team will be in touch within 48 hours';

const PrivateEventsForm = (props) => {
  const { form, brazeTriggers, subscriptions, id, background } = props;
  const className = useClassName('PrivateEventsForm');
  const [textBlock, setTextBlock] = useState(null);
  const [textCopies, setTextCopies] = useState([]);
  const [brazeCustomTriggers, setBrazeCustomTriggers] = useState(brazeTriggers);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (form) {
      const textBlock =
        form?.fields?.textBlock &&
        parseTextBlock(getContentfulField(form.fields.textBlock));
      const textCopiesValues =
        form?.fields?.textCopies && getContentfulField(form.fields.textCopies);
      const brazeCustomAttribute =
        form?.fields?.brazeCustomAttribute &&
        getContentfulField(form.fields.brazeCustomAttribute);
      const brazeCustomEvent =
        form?.fields?.brazeCustomEvent &&
        getContentfulField(form.fields.brazeCustomEvent);
      const textCopies = textCopiesValues?.map(({ fields }) => {
        return {
          key: getContentfulField(fields?.key),
          value: getContentfulField(fields?.value),
        };
      });
      setTextBlock(textBlock);
      setTextCopies(textCopies);
      if (brazeCustomAttribute || brazeCustomEvent) {
        setBrazeCustomTriggers({
          ...brazeTriggers,
          ...(brazeCustomAttribute && {
            customAttributes: { [brazeCustomAttribute]: 'yes' },
          }),
          ...(brazeCustomEvent && { customEvent: brazeCustomEvent }),
        });
      }
    }
  }, []);

  const createSubscription = async (user, group) =>
    await subscriptions.create({ ...user, group });

  const isRequired = (field) => REQUIRED_FIELDS.includes(field);

  const getOptions = (key, defaultOptions) => {
    const optionsFromTextCopy = getTextCopy(key, '');
    const options = optionsFromTextCopy
      ? optionsFromTextCopy.split(',')
      : defaultOptions;
    const list = options.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
    list.unshift(
      <option key={DEFAULT_OPTION} value="">
        {DEFAULT_OPTION}
      </option>
    );
    return list;
  };

  const getTextCopy = (key, defaultValue) => {
    const textCopy = textCopies?.find((textCopy) => textCopy.key === key);
    return textCopy?.value || defaultValue;
  };

  const handleChange = ({ id, value }) =>
    setFormValues({ ...formValues, [id]: value });

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors({});
    if (!validate()) return;
    setLoading(true);
    const formData = new FormData(event.target);
    const paramsArray = [];
    formData.forEach((value, key) => {
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });
    fetch(
      'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      {
        method: 'POST',
        mode: 'no-cors',
        body: paramsArray.join('&'),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then(() => {
        sendInfoBraze();
        setMessage({
          type: 'success',
          body: getTextCopy('successMessage', DEFAULT_SUCCESS_MESSAGE),
        });
      })
      .catch((err) => {
        setMessage({
          type: 'error',
          body: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendInfoBraze = async () => {
    const user = {
      firstName: formValues?.firstName || '',
      lastName: formValues?.lastName || '',
      email: formValues?.email || '',
      phone: formValues?.phone || '',
    };
    identify({
      ...user,
      ...brazeCustomTriggers.customAttributes,
    });
    track(brazeCustomTriggers.customEvent);

    if (formValues?.emailAccepted)
      await createSubscription(user, DEFAULT_GROUP_ID);
    if (formValues?.smsAccepted) await createSubscription(user, SMS_GROUP_ID);
  };

  const validate = () => {
    const errors = {};
    REQUIRED_FIELDS.forEach((field) => {
      if (!formValues[field]) {
        const fieldName = getTextCopy(field, startCase(field));
        errors[field] = `${fieldName} is required`;
      } else if (field.includes('email') && !isValidEmail(formValues[field])) {
        errors[field] = 'Please enter a valid email address';
      } else if (field.includes('phone') && !isValidPhone(formValues[field])) {
        errors[field] = 'Please enter a valid phone number';
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderError = (field) => {
    if (!formErrors[field]) return null;
    return <div className={className('error')}>{formErrors[field]}</div>;
  };

  const renderInput = (
    id,
    name,
    type,
    defaultLabel,
    required,
    autoComplete
  ) => {
    return (
      <div className={className('input-wrapper')}>
        <label className={className('input-label')} htmlFor={id}>
          {getTextCopy(id, defaultLabel)}
          {required ? '*' : ''}
        </label>
        {type === 'tel' ? (
          <>
            <input
              type="hidden"
              name={name}
              id={id}
              value={formValues[id] || ''}
            />
            <TelInput
              className={className(['input', formErrors[id] && 'input-error'])}
              onChange={(value) => handleChange({ id, value })}
            />
          </>
        ) : (
          <input
            placeholder={getTextCopy(id, defaultLabel)}
            className={className(['input', formErrors[id] && 'input-error'])}
            id={id}
            name={name}
            type={type}
            required={required}
            autoComplete={autoComplete}
            onChange={(e) => handleChange({ id, value: e.target.value })}
          />
        )}
        {renderError(id)}
      </div>
    );
  };

  const renderSelect = (id, name, options, defaultLabel, required) => {
    return (
      <div className={className('input-wrapper')}>
        <label className={className('input-label')} htmlFor={id}>
          {defaultLabel}
          {required ? '*' : ''}
        </label>
        <select
          className={className(['input', formErrors[id] && 'input-error'])}
          id={id}
          name={name}
          onChange={(e) => handleChange({ id, value: e.target.value })}
          required={required}>
          {options}
        </select>
        {renderError(id)}
      </div>
    );
  };

  const renderDateInput = (id, name, defaultLabel, required) => {
    return (
      <div className={className('input-wrapper')}>
        <label className={className('input-label')} htmlFor={id}>
          {defaultLabel}
          {required ? '*' : ''}
        </label>
        <input type="hidden" name={name} id={id} value={formValues[id] || ''} />
        <DatePicker
          className={className(['input', formErrors[id] && 'input-error'])}
          startDate={new Date()}
          onDayChange={(date) =>
            handleChange({
              id,
              value: DateTime.fromJSDate(date).toFormat('LL/dd/yyyy'),
            })
          }
        />
        {renderError(id)}
      </div>
    );
  };

  const renderCheckbox = (id, name, label) => (
    <div className={className('checkbox-wrapper')}>
      <input
        className={className('checkbox')}
        type="checkbox"
        id={id}
        name={name}
        onChange={(e) => handleChange({ id, value: e.target.checked })}
      />
      <div className={className('checkbox-label')}>{label}</div>
    </div>
  );

  const renderAsset = () => {
    if (!textBlock?.asset) return null;
    return <ContentfulAsset asset={textBlock.asset} />;
  };

  const renderTextBlock = () => {
    if (!textBlock) return null;
    const renderTextBlock = {
      ...textBlock,
      asset: null,
    };
    return <ContentfulTextBlock textBlock={renderTextBlock} />;
  };

  const renderCTA = () => {
    if (loading) return <Loader active inline />;
    else if (message) return renderMessage();
    return (
      <Button type="submit" className={className('submit-button')} inverted>
        Submit Form
      </Button>
    );
  };

  const renderMessage = () => {
    if (!message) return null;
    return (
      <div className={className('message-wrapper')}>
        <Message
          positive={message.type === 'success'}
          negative={message.type === 'error'}>
          {message.body}
        </Message>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Form noValidate onSubmit={handleSubmit} className={className('form')}>
        <input type="hidden" name="oid" value="00D1U000000xmYD" />
        <input type="hidden" name="00N1U00000UsorG" value="Group Sales" />
        <input type="hidden" name="Status" value="Prospecting" />
        <input type="hidden" name="lead_source" value="contact form" />
        <input
          type="hidden"
          name="retURL"
          value="https://www.americandream.com/"
        />
        {renderInput(
          'firstName',
          'first_name',
          'text',
          'First Name',
          isRequired('firstName'),
          'given-name'
        )}
        {renderInput(
          'lastName',
          'last_name',
          'text',
          'Last Name',
          isRequired('lastName'),
          'family-name'
        )}
        {renderInput(
          'email',
          'email',
          'email',
          'Email',
          isRequired('email'),
          'email'
        )}
        {renderInput(
          'phone',
          'phone',
          'tel',
          'Phone Number',
          isRequired('phone'),
          'tel'
        )}
        {renderSelect(
          'groupType',
          '00N1U00000Ufxv3',
          getOptions('groupType', DEFAULT_GROUP_TYPE_OPTIONS),
          'Group Type',
          isRequired('groupType')
        )}
        {renderSelect(
          'venueOfInterest',
          '00N1U00000Ufxv8',
          getOptions('venueOfInterest', DEFAULT_VENUE_OPTIONS),
          'Venue',
          isRequired('venueOfInterest')
        )}
        {renderInput(
          'numberOfPeople',
          '00N1U00000UfxvI',
          'number',
          'Number of People',
          isRequired('numberOfPeople'),
          'number'
        )}
        {renderDateInput(
          'eventDate',
          '00N1U00000UfxvD',
          'Event Date',
          isRequired('eventDate')
        )}
        {renderCheckbox(
          'emailAccepted',
          'emailAccepted',
          <>
            {
              'Sign me up for exclusive American Dream sweepstakes, promotions, emails, events, and updates. By checking this option you agree to our '
            }
            <Link className={className('terms-link')} to="/terms">
              Terms and Conditions
            </Link>
            {' and '}
            <Link className={className('privacy-link')} to="/privacy">
              Privacy Policy
            </Link>
            .
          </>
        )}
        {renderCheckbox(
          'smsAccepted',
          'smsAccepted',
          <>
            {
              'Sign me up for SMS messages with news, updates and special promotions at the number provided above. Msg&Data rates may apply. Msg frequency varies. Reply HELP for help, STOP to cancel. By checking this option you agree to our '
            }
            <Link className={className('terms-link')} to="/terms">
              Terms and Conditions
            </Link>
            {' and '}
            <Link className={className('privacy-link')} to="/privacy">
              Privacy Policy
            </Link>
            .
          </>
        )}
        {renderCTA()}
      </Form>
    );
  };

  return (
    <div
      className={className('container')}
      id={id}
      {...(background && {
        style: { background },
      })}>
      <div className={className('content')}>
        <div className={className('asset')}>{renderAsset()}</div>
        <div className={className('form-container')}>
          {renderTextBlock()}
          <Spacer size="m" />
          {renderForm()}
        </div>
      </div>
    </div>
  );
};

PrivateEventsForm.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  brazeTriggers: propTypes.brazeTriggers,
  background: propTypes.object,
};

PrivateEventsForm.defaultProps = {
  brazeTriggers: defaultProps.brazeTriggers,
  id: 'private-events-form',
};

export default inject('subscriptions')(PrivateEventsForm);
