import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

function useTimeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class OrdersStore extends BaseStore {
  @observable register = new Map();
  @observable items = [];
  @observable breakdown = [];
  @observable stats = new Map();
  @observable totalItems = 0;
  @observable limit = 50;
  @observable page = 1;
  @observable venueId;
  @observable sort = {
    order: 'desc',
    field: 'createdAt',
  };

  parseItem(item) {
    return {
      ...item,
      createdAt: new Date(item.createdAt),
    };
  }

  @action
  setPage(page) {
    this.page = page;
  }

  get(id) {
    return this.register.get(id);
  }

  @action
  fetchItem(id, statusKey = `item:${id}`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/orders/${id}`,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set(item.id, item);
        status.success();
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchStats(statusKey = 'stats') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/orders/stats',
    })
      .then(({ data }) => {
        this.stats.replace(data);
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchItems(
    {
      venueId = this.venueId,
      limit = this.limit,
      searchId,
      skip = (this.page - 1) * this.limit,
    } = {},
    statusKey = 'list'
  ) {
    this.register.clear();
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/orders/search',
      body: {
        limit,
        searchId,
        skip,
        venueId,
        sort: this.sort,
      },
    })
      .then(({ data, meta }) => {
        const items = data.map((item) => this.parseItem(item));
        items.forEach((item) => {
          this.register.set(item.id, item);
        });
        this.totalItems = meta.total;
        this.items.replace(items);
        status.success();
        return items;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  create(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/orders',
      body,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  update(body, orderId, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/orders/${orderId}`,
      body,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  confirm(body, statusKey = 'confirm') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/orders/confirm',
      body,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  async waitComplete(orderId, current = 1, total = 5, delay = 2000) {
    const retry = async (props) => {
      if (current >= total) {
        return props;
      }

      return await this.waitComplete(orderId, current + 1);
    };

    try {
      await useTimeout(delay);

      const { order, intent } = await request({
        method: 'GET',
        path: `/1/orders/${orderId}/completed`,
      });

      // The payment failed, used to get response from external sources (Klarna, Affirm...)
      if (intent?.last_payment_error || order.status === 'failed') {
        return { order, intent };
      }

      // The payment was not yet processed
      if (order.status !== 'complete') {
        return await retry({ order, intent });
      }

      return { order, intent };
    } catch (e) {
      // An internal error happened on our API due to instability
      return await retry({});
    }
  }
}
