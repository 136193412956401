import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Component } from 'common/helpers';
import { FooterList } from 'public/components/FooterList';
import { SocialLinks } from 'public/components/SocialLinks';
import { track } from 'utils/analytics';
import logo from 'common/assets/footer-logo.svg';

import { ABOUT, PARTNER, PLAN_YOUR_TRIP } from './config';
import './footer.less';

export default class Footer extends Component {
  renderLogo = (modifier) => (
    <img
      src={logo}
      alt="AMERICAN DREAM"
      className={this.getElementClass('logo', modifier)}
    />
  );

  handleLinkPress = (name) => {
    return () => {
      track(`Footer - Viewed ${name}`);
    };
  };

  renderTermsLinks = () => (
    <div className={this.getElementClass('terms-links')}>
      <Link to="/terms" onClick={this.handleLinkPress('Terms & Conditions')}>
        Terms & Conditions
      </Link>
      <Link to="/privacy" onClick={this.handleLinkPress('PrivacyPolicy')}>
        Privacy Policy
      </Link>
      <Link to="/waiver" onClick={this.handleLinkPress('Waiver')}>
        Waiver
      </Link>
      <Link to="/cookie-policy" onClick={this.handleLinkPress('CookiePolicy')}>
        Cookie Policy
      </Link>
      <Link to="/sitemap" onClick={this.handleLinkPress('Sitemap')}>
        Sitemap
      </Link>
    </div>
  );

  renderRights = () => (
    <p className={this.getElementClass('rights')}>
      DreamWorks Water Park © 2021 DreamWorks Animation. All Rights Reserved.
    </p>
  );

  render() {
    return (
      <footer {...this.getAttrs()}>
        <Container>
          {this.renderLogo('mobile')}
          <div className={this.getElementClass('main-content')}>
            <div className={this.getElementClass('list')}>
              <FooterList items={PLAN_YOUR_TRIP} title="Plan your trip" />
              <FooterList items={PARTNER} title="Partner with us" />
              <FooterList items={ABOUT} title="About" />
            </div>
            {this.renderLogo('desktop')}
          </div>
          <div className={this.getElementClass('bottom-content')}>
            <div className={this.getElementClass('social-links')}>
              <SocialLinks />
            </div>
            <div>
              {this.renderTermsLinks()}
              {this.renderRights()}
            </div>
          </div>
        </Container>
      </footer>
    );
  }
}
