import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { RefTabs } from 'public/components/Tabs';

import { NewCheckoutVariantsModal } from '../VariantsModal';
import { ProductWithVariants } from '../../ProductWithVariants';
import { isOptionSoldOut } from '../../utils';

import './selector.less';

const Selector = ({ categories, setQuantities, quantities }) => {
  const classNames = useClassName('BirthdayFoodAndBeverageSelector');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const refs = useRef([]);
  const hasCategories = categories?.length > 1;

  return (
    <>
      {hasCategories && (
        <RefTabs fixed names={categories.map((a) => a.name)} refs={refs} />
      )}
      <NewCheckoutVariantsModal
        isOpen={Boolean(selectedProduct)}
        product={selectedProduct}
        setOpen={setSelectedProduct}
        setQuantities={setQuantities}
        quantities={quantities}
        isOptionSoldOut={(option) =>
          isOptionSoldOut(
            option,
            categories
              .map((category) => category.products)
              .flat()
              .map((product) => product.products)
              .flat()
          )
        }
      />
      <div className={classNames('list')}>
        {categories.map((category, i) => (
          <div key={i} ref={(el) => (refs.current[i] = el)}>
            {hasCategories && <h2>{category.name}</h2>}
            {category.products.map((product, key) => {
              return (
                <ProductWithVariants
                  key={key}
                  product={product}
                  onClick={setSelectedProduct}
                  setQuantities={setQuantities}
                  quantities={quantities}
                />
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};

Selector.propTypes = {
  categories: PropTypes.array,
  setQuantities: PropTypes.func,
  quantities: PropTypes.object,
};

export default Selector;
