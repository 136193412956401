import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useClassName } from 'common/hooks';

import './snackbar.less';

const Snackbar = () => {
  const classNames = useClassName('Snackbar');

  return <div className={classNames('container')}>Done! Link copied</div>;
};

export const useSnackbar = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [show]);

  if (!show) return [setShow];

  return [setShow, createPortal(<Snackbar />, document.getElementById('root'))];
};
