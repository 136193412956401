import React from 'react';
import { get } from 'lodash';
import { createClient } from 'contentful';
import { Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import {
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_PREVIEW_TOKEN,
} from 'utils/env/client';

import { mapContentfulFields } from 'common/components';

import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';

import { Article } from '../Articles';
import { EventPage } from '../Events';
import { ContentPage } from '../ContentPage';
import { VenueDetails } from '../Venue';
import { NotFound } from '../NotFound';

import { NickUniverseHome } from '../NickUniverse';
import { DreamWorksHome } from '../DreamWorks';
import { BlacklightHome } from '../Blacklight';
import { BigSnowHome } from '../BigSnow';
import { RinkHome } from '../TheRink';

@inject('venues', 'events', 'pages', 'articles')
@observer
export default class ContentPreview extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      preview: null,
      type: null,
    };
    this.client = createClient({
      space: CONTENTFUL_SPACE_ID,
      environment: CONTENTFUL_ENVIRONMENT,
      accessToken: CONTENTFUL_PREVIEW_TOKEN,
      host: 'preview.contentful.com',
    });
  }

  async routeDidUpdate() {
    try {
      this.setState({
        loading: true,
        preview: null,
      });
      const { type, preview } = await this.fetchPreview();
      this.setState({
        type,
        preview,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  }

  // Get the preview, fetch our object,
  // then graft the fields together.
  async fetchPreview() {
    const { id } = this.props.match.params;
    const contentfulPreview = await this.client.getEntry(id);
    const type = get(contentfulPreview, 'sys.contentType.sys.id');
    const fields = mapContentfulFields(
      contentfulPreview.toPlainObject().fields
    );
    let preview;
    if (type === 'venue') {
      preview = await this.fetchVenue(id);
      preview.content = fields;
    } else {
      preview = await this.fetchByExternalId(type, id);
      preview.object.fields = fields;
    }
    return { type, preview };
  }

  async fetchVenue(externalId) {
    const [venue] = await this.props.venues.fetchItems({
      contentfulVenueId: externalId,
    });
    return venue;
  }

  async fetchByExternalId(type, externalId) {
    const store = this.getStoreForType(type);
    try {
      const [item] = await store.fetchItems({
        externalId,
      });
      return item;
    } catch (err) {
      return null;
    }
  }

  getStoreForType(type) {
    switch (type) {
      case 'article':
        return this.props.articles;
      case 'event':
        return this.props.events;
      case 'page':
        return this.props.pages;
    }
  }

  fetchEvents = async () => {
    const { id } = this.props.match.params;
    const entries = await this.client.getEntries({
      content_type: 'event',
      'fields.venue.sys.id': id,
    });
    let events = await Promise.all(
      entries.items.map(async (preview) => {
        const event = await this.fetchByExternalId('event', preview.sys.id);
        if (event) {
          event.object.fields = mapContentfulFields(preview.fields);
        }
        return event;
      })
    );
    return events.filter((event) => event);
  };

  render() {
    const { loading, preview } = this.state;
    if (loading) {
      return this.renderLoading();
    } else if (preview) {
      return this.renderPreview(preview);
    } else {
      return this.renderNotFound();
    }
  }

  renderPreview(preview) {
    const { type } = this.state;
    if (type === 'venue') {
      if (preview.slug === 'dreamworks-water-park') {
        return <DreamWorksHome preview={preview} {...this.props} />;
      } else if (preview.slug === 'nickelodeon-universe') {
        return <NickUniverseHome preview={preview} {...this.props} />;
      } else if (preview.slug === 'the-rink') {
        return <RinkHome preview={preview} {...this.props} />;
      } else if (preview.slug === 'big-snow') {
        return <BigSnowHome preview={preview} {...this.props} />;
      } else if (preview.slug === 'blacklight-mini-golf') {
        return <BlacklightHome preview={preview} {...this.props} />;
      } else {
        return (
          <VenueDetails
            preview={preview}
            fetchEvents={this.fetchEvents}
            {...this.props}
          />
        );
      }
    } else if (type === 'event') {
      return <EventPage preview={preview} {...this.props} />;
    } else if (type === 'page') {
      return <ContentPage preview={preview} {...this.props} />;
    } else if (type === 'article') {
      return <Article preview={preview} {...this.props} />;
    } else {
      return this.renderNotFound();
    }
  }

  renderNotFound() {
    return <NotFound {...this.props} />;
  }

  renderLoading() {
    return (
      <div>
        <Spacer size="l" />
        <Loader inline="centered" active />
        <Spacer size="l" />
      </div>
    );
  }
}
