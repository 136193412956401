import React from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Layout } from 'common/components';
import { Screen } from 'public/helpers';
import { RequestPasswordForm, Spacer } from 'public/components';

export default class RequestPassword extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  renderBody() {
    return (
      <div {...this.getAttrs()}>
        <Spacer size="m" />
        <Container className="white">
          <Layout vertical center>
            <Spacer size="m" />
            <RequestPasswordForm />
            <Spacer size="m" />
          </Layout>
        </Container>
        <Spacer size="m" />
      </div>
    );
  }
}

RequestPassword.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

RequestPassword.defaultProps = {
  contentfulSlugItem: 'forgot-password',
};
