import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import { ContentfulImage } from 'common/components';

import './featured-card.less';

import LOGO_BLACK_CIRCLE from 'common/assets/logo-black-circle.svg';

export default class FeaturedCard extends Component {
  render() {
    const { venue } = this.props;

    const linkProps = { pathname: `/venue/${venue?.slug}`, state: null };
    if (venue?.ownLink?.path) {
      linkProps.pathname = venue.ownLink.path;
      linkProps.state = venue.ownLink.state;
    }
    return (
      <Link
        onClick={venue.onClick}
        to={linkProps}
        className={this.getElementClass('card-link')}>
        <div {...this.getAttrs(true)}>
          <div className={this.getElementClass('image')}>
            {this.renderImage(venue)}
          </div>
          <div className={this.getElementClass('content')}>
            <div className={this.getElementClass('title')}>
              {Boolean(venue.description) && <p>{venue.description}</p>}
              <span>{venue.name}</span>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  renderImage(venue) {
    if (venue.ownImage?.img) {
      return (
        <div
          className={this.getElementClass('contenful-img')}
          style={{
            backgroundImage: `url("${venue.ownImage.loadFunc(
              venue.ownImage.img
            )}")`,
          }}
        />
      );
    }
    const image = venue.content && venue.content.horizontalImage;
    if (image) {
      return (
        <ContentfulImage
          field={image}
          ratio={16 / 9}
          type="fill"
          fluid
          className={this.getElementClass('contenful-img')}
        />
      );
    } else {
      return (
        <div className={this.getElementClass('image-fallback')}>
          <img src={LOGO_BLACK_CIRCLE} />
        </div>
      );
    }
  }
}

FeaturedCard.propTypes = {
  venue: PropTypes.object,
};
