import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Loader } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Screen } from 'public/helpers';
import { LoginForm } from 'public/components';
import { isValidEmail } from 'utils/helpers';
import { identify, track } from 'utils/analytics';

import SuccessRedirect from './SuccessRedirect';
import { AuthContainer } from './AuthContainer';

const validations = {
  password: [
    { isInvalid: (password) => !password, message: 'Password is required' },
  ],
  email: [
    { isInvalid: (email) => !email, message: 'Email is required' },
    {
      isInvalid: (email) => !isValidEmail(email),
      message: 'Please provide a valid email',
    },
  ],
};

@inject('pages', 'me', 'auth')
@observer
export default class Login extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
      loading: false,
      redirect: null,
      formData: {
        email: '',
        password: '',
      },
      fieldErrors: {},
    };
  }

  componentDidMount() {
    this.props.pages
      .fetchItemBySlug('login')
      .then((page) => this.setState({ page: page }));
  }

  onLoginSuccess = () => {
    this.setState({
      completed: true,
      loading: false,
    });
  };

  onLoginFail = (error) => {
    this.setState({
      error,
      loading: false,
    });
  };

  setField = (evt) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [evt.target.name]: evt.target.value,
      },
    });
  };

  validate = () => {
    const { formData } = this.state;
    const fieldErrors = Object.entries({
      password: formData.password,
      email: formData.email,
    }).reduce((fieldErrors, [key, value]) => {
      const fieldValidations = validations[key];
      if (!fieldValidations?.length) return fieldErrors;

      const error = fieldValidations.find((a) => a.isInvalid(value));
      if (!error) return fieldErrors;

      return {
        ...fieldErrors,
        [key]: error.message,
      };
    }, {});

    this.setState({
      fieldErrors,
    });

    return isEmpty(fieldErrors);
  };

  onSocialAuth = async (handler) => {
    if (handler instanceof Error) {
      this.onLoginFail(handler);
      return;
    }

    this.setState({ loading: false });

    try {
      const user = await handler();
      this.props.me.user = user;
      identify(user);
      track(`Signed In`);
      track(`Signed In - Social`);
      this.onLoginSuccess();
    } catch (err) {
      this.onLoginFail(err);
    }
  };

  onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    this.setState({
      loading: true,
    });
    try {
      const { email, password } = this.state.formData;
      const user = await this.props.auth.login({ email, password }, 'login');
      this.props.me.user = user;
      identify(user);
      this.onLoginSuccess();
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
    }
  };

  renderBody() {
    if (this.state.completed) {
      return <SuccessRedirect />;
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <AuthContainer
        title="Continue with your account"
        page={this.state.page}
        onSuccess={this.onLoginSuccess}
        onSocialAuth={this.onSocialAuth}
        setLoading={(loading) => this.setState({ loading })}
        onFail={(error) => this.setState({ error })}
        error={this.state.error}
        form={
          <LoginForm
            formData={this.state.formData}
            fieldErrors={this.state.fieldErrors}
            onSuccess={this.onLoginSuccess}
            validate={this.validate}
            setField={this.setField}
          />
        }
        setField={this.setField}
        actions={
          <>
            <Button
              type="submit"
              fluid
              primary
              size="large"
              className="static"
              onClick={this.onSubmit}
              disabled={this.state.loading}>
              {this.state.loading ? (
                <Loader active inline size="tiny" />
              ) : (
                'Log in'
              )}
            </Button>
            <Button
              type="button"
              animated={false}
              fluid
              size="large"
              className="static"
              onClick={() =>
                this.setState({ redirect: `/signup${window.location.search}` })
              }>
              I don’t have an account
            </Button>
          </>
        }
      />
    );
  }
}

Login.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

Login.defaultProps = {
  contentfulSlugItem: 'login',
};
