import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import {
  getPriceDifference,
  getTotalChangeDateFee,
} from './helpers/orderUpdate';
import { round } from 'lodash';
import { captureError } from 'utils/sentry';
import { Screen } from 'public/helpers';
import {
  getQuantities,
  validateTickets,
  validateBundleTickets,
} from './helpers/orderUpdate';
import { Layout, PaymentForm } from './components';
import { STRIPE_PUBLISHABLE_KEY } from 'utils/env/client';
import loadStripe from './helpers/loadStripe';

@inject('orderUpdate', 'ticketInventory', 'orders', 'bundles')
@observer
export default class OrderUpdateCheckout extends Screen {
  constructor(props) {
    super(props);

    const { newTickets } = this.props.orderUpdate;

    this.state = {
      loading: false,
      loadingStripe: true,
      newTickets,
      updateAmount: 0,
    };
  }

  async componentDidMount() {
    const { orderUpdate } = this.props;

    if (!orderUpdate?.order?.id) {
      this.props.history.push('/profile/orders');
    }

    window.scrollTo(0, 0);

    const { newAddons, newTickets, currentTickets, changeDateFeeTicket } =
      orderUpdate;
    await loadStripe();
    const ticketsPriceDifference = getPriceDifference(
      currentTickets,
      newTickets
    );
    const addonsPriceDifference = getPriceDifference(
      currentTickets,
      newAddons,
      true
    );
    const totalChangeDateFee = getTotalChangeDateFee(changeDateFeeTicket);
    const totalPriceDifference = round(
      ticketsPriceDifference + addonsPriceDifference + totalChangeDateFee,
      0
    );
    this.setState({
      loadingStripe: false,
      updateAmount: Number(totalPriceDifference),
    });
  }

  getIdsToRemove = () => {
    const { order, currentTickets } = this.props.orderUpdate;
    const idsToRemove = [];

    for (const ticket of currentTickets) {
      const orderItem = order.items.find(
        (item) =>
          item.ticketOptionId === ticket.ticketOption.ticketOptionId &&
          item.quantity === ticket.ticketOption.quantity &&
          item.date === ticket.ticketOption.date
      );
      if (!orderItem) {
        throw new Error('Order item not found');
      }
      idsToRemove.push(orderItem._id);
    }

    return idsToRemove;
  };

  completeCheckout() {
    const { orderUpdate } = this.props;
    const { order, venue } = orderUpdate;
    orderUpdate.setUpdatedOrder({ orderId: order.id, venueId: venue.id });
    this.props.history.push('/order-update-success');
  }

  onPaymentMethodReceived = async (paymentMethodId, saveCard) => {
    this.setState({
      error: null,
      order: null,
      paymentIntent: null,
      loading: true,
    });
    const { updateAmount } = this.state;

    const { orders, ticketInventory, bundles } = this.props;
    const {
      order,
      newTickets,
      venue,
      currentTickets,
      newAddons,
      changeDateFeeTicket,
      missingDateChangeFeeTicket,
      bundle,
    } = this.props.orderUpdate;

    const paymentReferenceHistory = currentTickets[0].paymentReferenceHistory
      ? [
          ...currentTickets[0].paymentReferenceHistory,
          currentTickets[0].paymentReference,
        ]
      : [currentTickets[0].paymentReference];

    const allTickets = [...newTickets, ...newAddons];
    if (changeDateFeeTicket) allTickets.push(changeDateFeeTicket);
    const quantities = getQuantities(allTickets);

    try {
      const { errorMessage } = !bundle
        ? validateTickets({
            reservationDate: newTickets[0].ticketOption.date,
            venue,
            quantities,
            ticketInventory,
            currentTickets,
          })
        : validateBundleTickets({
            newTickets,
            bundles,
          });

      if (errorMessage) {
        throw new Error(errorMessage);
      }

      const idsToRemove = this.getIdsToRemove();

      const newOrderItems = allTickets.map((ticket) => ({
        quantity: ticket.ticketOption.quantity,
        ticketOptionId: ticket.ticketOption.ticketOptionId,
        reservationDate: ticket.ticketOption.date,
        promoCode: '',
        startTime: ticket.ticketOption.startTime,
        bookingItemId: ticket.ticketOption.bookingItemId,
        venueId: ticket?.venue?.id || venue.id,
        bundleSlug: ticket.bundleSlug,
        paymentReferenceHistory: paymentReferenceHistory.filter(Boolean),
        addon: ticket.ticketOption.addon,
        externalTicket: ticket.ticketOption.externalTicket,
        externalVenue: ticket.ticketOption.externalVenue,
      }));

      const response = await orders.update(
        {
          items: newOrderItems,
          paymentToken: paymentMethodId,
          idsToRemove,
          saveCard,
          updateAmount: updateAmount,
          missingDateChangeFeeTicket,
        },
        order.id
      );

      if (response.order.status === 'complete') {
        this.completeCheckout(response.order);
      } else if (response.intent) {
        this.setState({
          order: response.order,
          paymentIntent: response.intent,
        });
      } else {
        const err = new Error('Order could not be completed');
        err.payload = response;
        throw err;
      }
    } catch (err) {
      this.onError(err);
    }
  };

  onPaymentIntentSuccess = async (paymentIntent, saveCard) => {
    try {
      const response = await this.props.orders.confirm({
        orderId: this.state.order.id,
        paymentIntentId: paymentIntent.id,
        saveCard,
      });
      if (response.order.status === 'complete') {
        this.completeCheckout(response.order);
      } else {
        const err = new Error('Order could not be confirmed');
        err.payload = response;
        throw err;
      }
    } catch (err) {
      this.onError(err);
    }
  };

  onError = (error) => {
    this.setState({
      error,
      loading: false,
      paymentIntent: null,
    });
    captureError(error);
  };

  renderBody() {
    const { loading, loadingStripe, paymentIntent, error, updateAmount } =
      this.state;

    if (loadingStripe)
      return (
        <Dimmer>
          <Loader active size="large">
            Loading
          </Loader>
        </Dimmer>
      );

    return (
      <Layout title="Review and confirm">
        <div className={this.getElementClass('main-container')}>
          <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
            <Elements>
              <PaymentForm
                error={error}
                loading={loading}
                showPayment={updateAmount > 0}
                paymentIntent={paymentIntent}
                onPaymentMethodReceived={this.onPaymentMethodReceived}
                onPaymentIntentSuccess={this.onPaymentIntentSuccess}
                onCardActionError={this.onError}
                onPaymentMethodError={this.onError}
                total={Number((updateAmount / 100).toFixed(2))}
              />
            </Elements>
          </StripeProvider>
        </div>
      </Layout>
    );
  }
}
