import React from 'react';
import { Component } from 'common/helpers';
import { ResponsiveImageLight } from 'common/components';
import { Loader } from 'semantic-ui-react';
import './image.less';

export default class LoaderImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  onLoad = () => this.setState({ loaded: true });

  getModifiers() {
    const { loaded } = this.state;
    return [loaded ? 'loaded' : null];
  }

  render() {
    const {
      color,
      background,
      loadingHeight,
      hideLoader,
      loaderSize = 'medium',
      ...rest
    } = this.props;
    const { loaded } = this.state;

    return (
      <div
        className={this.getClasses(this.name, this.getModifiers()).join(' ')}>
        {!loaded && (
          <div
            className={this.getElementClass('container')}
            style={{ height: loadingHeight }}>
            {!hideLoader && (
              <Loader inline="centered" active size={loaderSize} />
            )}
          </div>
        )}
        <ResponsiveImageLight
          {...rest}
          onLoad={this.onLoad}
          style={{ height: `${loaded ? 'auto' : 0}` }}
        />
        {this.props.caption && (
          <div className={this.getElementClass('caption')}>
            {this.props.caption}
          </div>
        )}
      </div>
    );
  }
}
