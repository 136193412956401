import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { sum } from 'lodash';
import { DateTime } from 'luxon';

import { Screen, getCustomMetadata } from 'public/helpers';
import {
  getQuantity,
  ticketSalesClosed,
  validateInventory,
} from 'common/helpers';
import { formatApiDate } from 'utils/api';
import { scrollTo } from 'utils/helpers/scroll';
import { getItemPrice, getItemTax, trackProductViewed } from 'common/helpers';
import { isDateChangeFee } from 'public/helpers/functions';
import { getContentfulField } from 'common/components';

import { STEPS } from '../const';
import { NewCheckoutTicketsHeader } from './Header';
import { NewCheckoutTicketsDates } from './Dates';
import { NewCheckoutTicketsSessions } from './Sessions';
import { NewCheckoutTicketsOptions } from './TicketOptions';
import { NewCheckoutTicketsOtherInformation } from './OtherInformation';
import { NewCheckoutContainer } from '../Components/Container';
import { NewCheckoutSummary } from '../Components/Summary';
import { NewCheckoutTemporarilyClosed } from '../Components/TemporarilyClosed';
import { NewCheckoutPointsNotAvailable } from './Points';

const metaSublinkId = 'tickets';

const INITIAL_STATE = {
  SELECTED_DATE: null,
  SESSIONS: [],
  SELECTED_SESSION: null,
  QUANTITIES: {},
  INVENTORY: [],
  REDIRECT: null,
  CUSTOM_METADATA: null,
  VENUE: null,
};

const getDefaultState = (props) => {
  const { slug } = (props.match && props.match.params) || {};
  const search = new URLSearchParams(props.location.search);
  const points = search.get('points');

  return {
    selectedDate: INITIAL_STATE.SELECTED_DATE,
    sessions: INITIAL_STATE.SESSIONS,
    selectedSession: INITIAL_STATE.SELECTED_SESSION,
    quantities: INITIAL_STATE.QUANTITIES,
    inventory: INITIAL_STATE.INVENTORY,
    slug: slug,
    redirect: INITIAL_STATE.REDIRECT,
    customMetadata: INITIAL_STATE.CUSTOM_METADATA,
    venue: INITIAL_STATE.VENUE,
    points: points
      ? {
          needed: points,
          selected: `${points} points`,
        }
      : {},
  };
};

@inject(
  'checkout',
  'venues',
  'cart',
  'notifications',
  'ticketInventory',
  'externalbookings'
)
@observer
export default class NewCheckoutTickets extends Screen {
  state = getDefaultState(this.props);

  constructor(props) {
    super(props);
    this.sessionsRef = React.createRef();
    this.ticketOptionsRef = React.createRef();
  }

  async getMetadata(venue) {
    return getCustomMetadata(venue, metaSublinkId);
  }

  setSelectedDate(venue) {
    if (
      Boolean(venue.startFromDate) &&
      DateTime.fromISO(venue.startFromDate).startOf('day') >=
        DateTime.now().startOf('day')
    )
      return DateTime.fromISO(venue.startFromDate).toISODate();
    if (!venue.hiddenDatePicker) return null;
    if (venue.hiddenDatePicker) return DateTime.now().toISODate();
  }

  async routeDidUpdate() {
    const venue = await this.props.venues.fetchItemBySlug(this.state.slug);
    const customMetadata = await this.getMetadata(venue);
    const selectedDate = this.setSelectedDate(venue);

    if (venue.externalBookings && selectedDate)
      await this.props.externalbookings.fetchAvailabilityByVenue(
        venue.slug,
        selectedDate
      );

    this.setState({
      customMetadata,
      venue,
      selectedDate: selectedDate,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = getDefaultState(props);
    if (newState.slug !== state.slug) {
      return newState;
    }

    return null;
  }

  getTickets = () => {
    const { selectedDate, selectedSession, venue, quantities, inventory } =
      this.state;

    return inventory.map((item) => {
      const {
        ticketOptionId,
        bookingItemId,
        externalId,
        name,
        date,
        price,
        tax,
      } = item;
      return {
        name,
        date,
        price,
        venueId: venue.id,
        venueName: venue.name,
        externalId,
        bookingItemId,
        ticketOptionId,
        reservationDate: formatApiDate(selectedDate),
        startTime: this.getStartTime(),
        startTimeName: selectedSession?.name,
        quantity: getQuantity(item, quantities),
        tax,
        externalTicket: venue.externalBookings,
      };
    });
  };

  onSubmit = async () => {
    const tickets = this.getTickets().filter((t) => t.quantity > 0);
    tickets.forEach((ticket) => this.props.cart.addTicket(ticket));

    const { venue, selectedDate } = this.state;

    await this.props.ticketInventory.search({
      venueId: venue.id,
      date: selectedDate,
      slug: venue.slug,
    });

    const addons = await this.props.ticketInventory
      .get(venue.id, selectedDate)
      .filter((product) => product.addon);

    if (!addons?.length || this.state.points.needed) {
      this.setRedirect('/cart');
      return;
    }

    this.props.checkout.update({
      venue,
      reservationDate: selectedDate,
      startTime: this.getStartTime(),
      addons,
      tickets,
    });

    const url = this.props.match.url;
    if (url.includes('/promo/')) {
      // promo slug backward compatibility
      this.setRedirect(`/promo/${venue.slug}/addons`);
    } else {
      this.setRedirect(this.props.match.url.replace('tickets', 'addons'));
    }
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
    });
  };

  getStartTime = () =>
    this.state.selectedSession ? this.state.selectedSession.startTime : null;

  scrollIntoRef = (ref) => {
    scrollTo(ref.current, -24);
  };

  getSessions = (date) => {
    const sortSessions = (a, b) => {
      return (
        Number(a.startTime.replace(':', '')) -
        Number(b.startTime.replace(':', ''))
      );
    };

    const sortedSessions = this.props.ticketInventory
      .getSessions(this.state.venue.id, date)
      ?.sort(sortSessions);
    if (!Array.isArray(sortedSessions))
      return {
        sessions: INITIAL_STATE.SESSIONS,
        selectedSession: INITIAL_STATE.SELECTED_SESSION,
      };

    const firstAvailable = sortedSessions.find((session) =>
      Boolean(session.capacityRemaining)
    );
    return { sessions: sortedSessions, selectedSession: firstAvailable };
  };

  onDateSelected = (date, scrollDown = true) => {
    const { sessions, selectedSession } = this.getSessions(date);
    const { venue } = this.state;
    const inventory = this.props.ticketInventory
      .get(this.state.venue.id, date)
      ?.filter((product) => !product.addon)
      .filter((product) => !isDateChangeFee(product.name));

    //TODO: Track product viewed
    inventory.forEach((item) => trackProductViewed(item, venue));

    this.setState({
      quantities: INITIAL_STATE.QUANTITIES,
      selectedSession,
      sessions,
      inventory,
      selectedDate: date,
      fetched: true,
    });
    if (scrollDown && !venue.hiddenDatePicker && !venue.startFromDate) {
      this.scrollIntoRef(this.sessionsRef);
    }
  };

  onSessionSelected = (session, scrollDown = true) => {
    this.setState({
      quantities: INITIAL_STATE.QUANTITIES,
      selectedSession: session,
    });

    if (scrollDown) {
      this.scrollIntoRef(this.ticketOptionsRef);
    }
  };

  onChangeQuantity = (item, quantity) => {
    const { selectedSession } = this.state;

    this.setState({
      quantities: {
        ...this.state.quantities,
        [item.ticketOptionId]: selectedSession
          ? { startTime: selectedSession.startTime, quantity }
          : quantity,
      },
    });
  };

  getSubtotal = () => {
    const { inventory, quantities } = this.state;

    return sum(
      inventory.map((item) => {
        const quantity = getQuantity(item, quantities);
        return getItemPrice(item, inventory, quantities) * quantity;
      })
    );
  };

  getTax = () => {
    const { inventory, quantities } = this.state;

    return sum(
      inventory.map((item) => {
        const quantity = getQuantity(item, quantities);
        return getItemTax(item, inventory, quantities) * quantity;
      })
    );
  };

  getTicketCount = () => {
    const { inventory, quantities } = this.state;

    return sum(inventory.map((item) => getQuantity(item, quantities)));
  };

  getTotal = () => {
    const subtotal = this.getSubtotal();
    const tax = this.getTax();

    return subtotal + tax;
  };

  showPointsNotAvailable = () => {
    const { inventory, points, fetched } = this.state;

    if (!points.needed || !fetched) return false;

    return !inventory?.length || inventory.every(this.isUnavailable);
  };

  isUnavailable = (item) => {
    const { selectedSession } = this.state;
    const startTime = selectedSession ? selectedSession.startTime : null;

    return typeof item.quantity === 'object' && !item.quantity[startTime];
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const validate = (inventory) => {
      if (!this.state.selectedDate) return { canSubmit: false };
      if (!venue) return { canSubmit: false };

      if (
        ticketSalesClosed(
          venue,
          DateTime.fromISO(this.state.selectedDate).toJSDate()
        )
      ) {
        return {
          canSubmit: false,
          errorMessage: 'Please select a date in the future',
          errorTitle: 'Same-day ticket sales are now closed.',
        };
      }

      return validateInventory(
        inventory,
        this.state.quantities,
        venue,
        this.props.externalbookings.get(venue.slug, this.state.selectedDate)
      );
    };

    const { venue } = this.state;
    const { canSubmit, errorMessage, errorTitle } = validate(
      this.state.inventory
    );
    const error = (errorMessage || errorTitle) && { errorMessage, errorTitle };

    const stores = {
      checkout: this.props.checkout,
      venues: this.props.venues,
      cart: this.props.cart,
      notifications: this.props.notifications,
      ticketInventory: this.props.ticketInventory,
      externalbookings: this.props.externalbookings,
    };

    const { date: initialDate } = this.props.match.params;

    return (
      <NewCheckoutContainer
        attrs={this.getAttrs()}
        content={
          this.showPointsNotAvailable() ? (
            <NewCheckoutPointsNotAvailable />
          ) : (
            <>
              <NewCheckoutTicketsHeader
                venue={venue}
                title="Purchase Tickets"
              />
              <NewCheckoutTicketsDates
                venue={venue}
                selectedDate={this.state.selectedDate}
                onDateSelected={this.onDateSelected}
                stores={stores}
                initialDate={initialDate}
                inventory={this.state.inventory}
              />
              <NewCheckoutTicketsSessions
                venue={venue}
                selectedDate={this.state.selectedDate}
                sessions={this.state.sessions}
                selectedSession={this.state.selectedSession}
                onSessionSelected={this.onSessionSelected}
                forwardedRef={this.sessionsRef}
                stores={stores}
              />
              <NewCheckoutTicketsOptions
                venue={venue}
                selectedDate={this.state.selectedDate}
                selectedSession={this.state.selectedSession}
                sessions={this.state.sessions}
                quantities={this.state.quantities}
                onChangeQuantity={this.onChangeQuantity}
                inventory={this.state.inventory}
                forwardedRef={this.ticketOptionsRef}
                isPoints={
                  Boolean(this.state.points.needed) &&
                  Boolean(this.state?.inventory?.length)
                }
                points={this.state.points.selected}
                changePoints={(pointsSelected) =>
                  this.setState((state) => ({
                    points: { ...state.points, selected: pointsSelected },
                  }))
                }
              />
              {!this.state.points.needed && (
                <NewCheckoutTemporarilyClosed
                  venue={venue}
                  date={this.state.selectedDate}
                />
              )}
              <NewCheckoutTicketsOtherInformation
                contentfulInformation={getContentfulField(
                  venue?.content?.otherInformation
                )}
                contentfulMessages={getContentfulField(
                  venue?.content?.messages
                )}
              />
            </>
          )
        }
        summary={
          <NewCheckoutSummary
            error={error}
            canSubmit={canSubmit}
            onSubmit={this.onSubmit}
            step={STEPS.Tickets}
            totals={{
              subtotal: this.getSubtotal(),
              tax: this.getTax(),
              ticketCount: this.getTicketCount(),
              ticketSubtotal: this.getSubtotal(),
              total: this.getTotal(),
            }}
          />
        }></NewCheckoutContainer>
    );
  }
}
