import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';

const getLinks = (type) => {
  const directory = { url: '/directory/shopping', title: 'Directory' };

  return (
    {
      entertainment: [
        directory,
        { url: '/directory/entertainment', title: 'Entertainment' },
      ],
      dining: [directory, { url: '/directory/dining', title: 'Food & Drinks' }],
      shopping: [directory, { url: '/directory/shopping', title: 'Stores' }],
    }[type] || [directory]
  );
};

const DirectoryBreadcrumb = ({ title, type }) => {
  return <Breadcrumb links={[...getLinks(type), { url: '#', title: title }]} />;
};

DirectoryBreadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default DirectoryBreadcrumb;
