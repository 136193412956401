import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Navigation from 'swiper/modules/navigation.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

import { ContentfulRichText } from 'common/components';
import {
  LazyVisible,
  useClassName,
  Only,
  usePreload,
  preloadMedia,
  preloadCTAs,
} from 'common/hooks';

import HomepageButton from '../../Components/Button';
import HomepageAsset from '../../Components/Asset';
import { useNext, usePrev } from '../../Components/useNavigation';
import HomepageImage from '../../Components/Image';
import SmallStoresSection from './Small';
import { usePagination } from '../../Components/usePagination';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import './stores.less';

const Fallback = forwardRef(({ asset }, ref) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      const onScroll = () => {
        const y = ref?.current?.getBoundingClientRect()?.y;

        if (y <= window.innerHeight * 2) {
          setShouldRender(true);
        }
      };

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [ref]);

  if (!shouldRender) return null;

  return <HomepageAsset asset={asset} style={{ display: 'none' }} />;
});

export default function StoresSection({ large, small }) {
  const classNames = useClassName('StoresSection');
  const ref = useRef();
  const [navigationPrevRef, prev] = usePrev({
    className: classNames('navigation'),
  });
  const [navigationNextRef, next] = useNext({
    className: classNames('navigation'),
  });
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  usePreload((isDesktop) => {
    preloadMedia(large.fields.stores, isDesktop);
    preloadCTAs(large.fields.cta);
  }, ref);
  usePreload((isDesktop) => {
    preloadMedia(small.fields.stores, isDesktop);
    preloadCTAs(small.fields.cta);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--background-url': `url(${large.fields.backgroundImage.fields.file.url})`,
      }}
      className={classNames('container')}
      id={small.fields.sectionId}
      ref={ref}
      fallback={<Fallback asset={small.fields.video} ref={ref} />}
      onLoad={() => {
        trackHomepageSection(() => ({
          id: small.fields.sectionId,
        }));
        trackHomepageSection(() => ({
          id: large.fields.sectionId,
        }));
      }}>
      <SmallStoresSection section={small} />
      <div className={classNames('header')}>
        <div>
          <HomepageImage field={large.fields.icon} />
          <ContentfulRichText field={{ 'en-US': large.fields.subheader }} />
        </div>
        <Only desktop>
          <HomepageButton
            cta={large.fields.cta}
            className={classNames('cta')}
          />
        </Only>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        height={500}
        slidesPerView="auto"
        centeredSlides={false}
        loop={true}
        className={classNames('wrapper')}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        onSlideChange={({ activeIndex }) =>
          trackHomepageSlide(() => ({
            activeIndex,
            name: large.fields.stores[activeIndex].fields.name,
            section: large.fields.sectionId,
          }))
        }
        {...pagination}>
        {large.fields.stores.map(({ fields, sys }) => (
          <SwiperSlide key={sys.id} className={classNames('slide-container')}>
            <HomepageButton
              cta={fields.callToAction}
              className={classNames('slide')}>
              <HomepageImage field={fields.desktop} mobile={fields.mobile} />
              <HomepageImage field={fields.icon} />
            </HomepageButton>
          </SwiperSlide>
        ))}
        {prev}
        {next}
      </Swiper>
      <Only mobile tablet>
        <HomepageButton cta={large.fields.cta} className={classNames('cta')} />
      </Only>
    </LazyVisible>
  );
}
