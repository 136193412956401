import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

import Component from 'common/helpers/Component';

import { WarningMessage } from '../Message';
import { FieldContainer } from '../FieldContainer';

import './login-form.less';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { fieldErrors, formData, validate, setField, error } = this.props;

    return (
      <Form noValidate onBlur={validate} id="loginForm">
        <FieldContainer error={fieldErrors.email}>
          <label htmlFor="email">Email</label>
          <Input
            value={formData.email}
            onChange={setField}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
          />
        </FieldContainer>
        <FieldContainer error={fieldErrors.password}>
          <label htmlFor="password">Password</label>
          <Input
            value={formData.password}
            onChange={setField}
            type="password"
            name="password"
            autoComplete="current-password"
          />
        </FieldContainer>
        <Link
          to="/forgot-password"
          className={this.getElementClass('forgot-password')}>
          Forgot Password?
        </Link>
        {error && <WarningMessage content={error.message} />}
      </Form>
    );
  }
}

LoginForm.propTypes = {
  fieldErrors: PropTypes.object,
  formData: PropTypes.object,
  validate: PropTypes.func,
  setField: PropTypes.func,
  error: PropTypes.instanceOf(Error),
};

export default LoginForm;
