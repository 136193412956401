import React from 'react';
import PropTypes from 'prop-types';

import { Spacer, Pagination } from 'public/components';

const DealsPagination = ({
  deals,
  totalItems,
  page,
  itemsPerPage,
  handlePageChange,
}) => {
  if (deals !== null && totalItems > itemsPerPage) {
    return (
      <>
        <Pagination
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          activePage={page}
          onPageChange={(e, data) => handlePageChange(data.activePage)}
        />
        <Spacer size="m" />
      </>
    );
  }
  return <></>;
};

DealsPagination.propTypes = {
  deals: PropTypes.array,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default DealsPagination;
