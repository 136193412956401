import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import { Loader, Container, Button } from 'semantic-ui-react';
import { Layout, ContentfulImage, ContentfulRichText } from 'common/components';
import { Screen, getCustomMetadata } from 'public/helpers';
import { parseApiDate } from 'utils/api';
import { TicketSelector, Modal, Spacer } from 'public/components';
import { OLD_CHECKOUT } from 'public/constants/gtm';

import './tickets.less';

const metaSublinkId = 'tickets';

// TODO: clean this up as ticket selector is already handling this
export const getDay = (props) => {
  const minDate = new Date(2020, 9, 1);
  const today = new Date();
  const defaultDate = minDate > today ? minDate : today;

  const { date } = props.match.params;
  return date ? parseApiDate(date) : defaultDate;
};

function getDefaultState(props) {
  const { slug } = (props.match && props.match.params) || {};
  let reservationDate = getDay(props);

  return {
    reservationDate,
    slug: slug,
    loading: true,
    redirect: null,
    showModal: false,
    addedTickets: null,
    customMetadata: null,
    venue: null,
    isAttraction: false,
  };
}

@inject(
  'checkout',
  'venues',
  'cart',
  'notifications',
  'ticketInventory',
  'externalbookings'
)
@observer
export default class VenueTickets extends Screen {
  state = getDefaultState(this.props);

  async getMetadata(venue) {
    return getCustomMetadata(venue, metaSublinkId);
  }

  async routeDidUpdate() {
    const venue = await this.props.venues.fetchItemBySlug(this.state.slug);
    if (venue.externalBookings) {
      await this.props.externalbookings.fetchAvailabilityByVenue(venue.slug);
    }
    const customMetadata = await this.getMetadata(venue);
    // FFN: Bugfix - state was getting overwritten here.
    const reservationDate = this.state.reservationDate
      ? this.state.reservationDate
      : getDay(this.props);
    const isAttraction = venue?.venueType === 'attraction';

    this.setState({
      loading: false,
      reservationDate,
      customMetadata,
      venue,
      isAttraction,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = getDefaultState(props);
    if (newState.slug !== state.slug) {
      return newState;
    }
    return null;
  }

  onSubmit = async (tickets) => {
    tickets = tickets.filter((t) => t.quantity > 0);
    tickets.forEach((ticket) => this.props.cart.addTicket(ticket));

    const { venue, startTime } = this.state;

    let reservationDate;
    if (tickets?.length) reservationDate = tickets[0].reservationDate;

    if (window[OLD_CHECKOUT]) {
      this.setState({ addedTickets: tickets, showModal: true });
    } else {
      await this.props.ticketInventory.search({
        venueId: venue.id,
        date: reservationDate,
        slug: venue.slug,
      });

      const addons = await this.props.ticketInventory
        .get(venue.id, reservationDate)
        .filter((product) => product.addon);

      if (!addons?.length) {
        this.setRedirect('/cart');
        return;
      }

      this.props.checkout.update({
        venue,
        reservationDate,
        startTime,
        addons,
        tickets,
      });

      this.state.isAttraction
        ? this.setRedirect(`/venue/${venue.slug}/addons`)
        : this.setRedirect(`/promo/${venue.slug}/addons`);
    }
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
      showModal: false,
    });
  };

  handleChangeSession = (session) => {
    this.setState({
      startTime: session.startTime,
    });
  };

  renderSelector(venue) {
    if (venue?.openingHours?.temporarilyClosed) {
      return (
        <Layout.Group>
          <div className={this.getElementClass('closed-message')}>
            <ContentfulRichText field={venue.content.closedMessage} />
          </div>
        </Layout.Group>
      );
    }

    return (
      <Layout.Group className={this.getElementClass('ticket-selector')}>
        <TicketSelector
          reservationDate={this.state.reservationDate}
          venue={venue}
          onSubmit={this.onSubmit}
          renderActions={this.renderActions}
          onChangeSession={this.handleChangeSession}
        />
      </Layout.Group>
    );
  }

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    } else if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    }

    const { venue } = this.state;

    return (
      <div {...this.getAttrs()}>
        <Container>
          <div className={this.getElementClass('selector')}>
            <Layout
              className={this.getElementClass('header')}
              horizontal
              center
              spread
              padded>
              <Layout.Group>
                {venue?.content?.logo && (
                  <ContentfulImage field={venue.content.logo} width={130} />
                )}
              </Layout.Group>
              <Layout.Group>
                {this.state.isAttraction && (
                  <Button
                    as={Link}
                    to={`/venue/${this.state.slug}`}
                    inverted
                    size="small">
                    Attraction Details
                  </Button>
                )}
              </Layout.Group>
            </Layout>
            <Layout horizontal={false} center spread>
              {this.renderSelector(venue)}
            </Layout>
          </div>
        </Container>
        {this.renderModal()}
        <Spacer size="l" />
      </div>
    );
  }

  renderModal() {
    if (!this.state.showModal) {
      return;
    }
    const { slug } = this.props.match.params;
    const { addedTickets } = this.state;
    return (
      <Modal
        open={this.state.showModal}
        onCloseClick={() => this.setRedirect(`/venue/${slug}`)}>
        <Modal.Header>
          <label>Tickets added</label>
        </Modal.Header>
        <Modal.Content>
          <Spacer size="s" />
          {addedTickets.map((ticket) => {
            const { name, quantity } = ticket;
            return (
              <p key={ticket.ticketOptionId}>
                {quantity} x {name}
              </p>
            );
          })}
          <Spacer size="s" />
        </Modal.Content>
        <Modal.Actions>
          <Layout horizontal stackable padded right>
            <Layout.Group>
              <Button onClick={() => this.setRedirect(`/venue/${slug}`)}>
                Continue Browsing
              </Button>
            </Layout.Group>
            <Layout.Group>
              <Button primary onClick={() => this.setRedirect('/cart')}>
                View Basket
              </Button>
            </Layout.Group>
          </Layout>
          <Spacer size="s" />
        </Modal.Actions>
      </Modal>
    );
  }

  renderActions = (canSubmit) => {
    return (
      <Button disabled={!canSubmit} name="/cart" primary fluid>
        Add to basket
      </Button>
    );
  };
}
