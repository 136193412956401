import React from 'react';
import { Container } from 'semantic-ui-react';
import { Screen, withVenue, getCustomMetadata } from 'public/helpers';
import { ContentfulRichText, Content } from 'common/components';
import { Spacer, AttractionsNav } from 'public/components';

import './home.less';

const metaSublinkId = 'faq';

@withVenue('big-snow')
export default class BigSnowFaq extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      customMetadata: null,
      loading: true,
    };
  }

  async getMetadata() {
    const customMetadata = await getCustomMetadata(
      this.props.venue,
      metaSublinkId
    );
    this.setState({ customMetadata, loading: false });
  }

  componentDidMount() {
    this.getMetadata();
  }

  renderBody() {
    const { venue } = this.props;
    return (
      <div {...this.getAttrs()}>
        <AttractionsNav venue={this.props.venue} />
        <Container>
          <Spacer size="m" />
          <Content>
            <ContentfulRichText field={venue.content.FaqUrl} />
          </Content>
        </Container>
        <Spacer size="l" />
      </div>
    );
  }
}
