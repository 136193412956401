import { localStorage } from 'utils/helpers';
import { isSupportedLocale } from 'utils/l10n';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { merge, pick } from 'lodash';

import {
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_DELIVERY_TOKEN,
  CONTENTFUL_ENVIRONMENT,
} from 'utils/env/client';

const LOCAL_STORAGE_KEY = 'contentful';

// 1 hr cache
const MAX_AGE = 60 * 60 * 1000;

// Used for determining if fields are localized or
// not as contentful doesn't expose this as a setting.
const CONTENTFUL_DEFAULT_LANG = 'en-US';

let cache;
let loadPromise;

// Ensure here that bad user data like trailing
// spaces doesn't make entries become unavailable.
function normalizeKey(key) {
  return key.trim();
}

// Mapping remote data to local data
function mapEntries(entries, assets) {
  const data = {};

  // Discard null data from server
  entries = entries.filter((e) => e);

  entries.forEach((entry) => {
    const type = entry.sys.contentType.sys.id;
    const mappedEntry = mapEntry(entry, assets);
    const lookupByKey = 'key' in mappedEntry;
    const set = data[type] || (data[type] = lookupByKey ? {} : []);

    // Going with the convention of always mapping by the key.
    // This means that you can quickly look up data by the keys,
    // ie. /venues/nike -> data.venues['nike']
    if (lookupByKey) {
      set[normalizeKey(mappedEntry.key)] = mappedEntry;
    } else {
      set.push(mappedEntry);
    }
  });

  return data;
}

function mapEntry(entry) {
  const mapped = getNewEntry(entry);
  Object.keys(entry.fields || {}).forEach((key) => {
    setPropIfExists(mapped, key, mapField(entry.fields[key]));
  });
  return mapped;
}

function mapField(field) {
  const keys = Object.keys(field || {});

  // Non-localized field
  if (keys.length === 1 && keys[0] === CONTENTFUL_DEFAULT_LANG) {
    return mapContent(field[CONTENTFUL_DEFAULT_LANG]);
  }

  // Localized field
  return mapLocalizedSet(field);
}

function mapLocalizedSet(obj) {
  Object.keys(obj || {}).forEach((key) => {
    if (isSupportedLocale(key)) {
      setPropIfExists(obj, key, mapContent(obj[key]));
    }
  });
  return obj;
}

function mapContent(val) {
  if (typeof val === 'undefined' || val === null) {
    console.warn('Missing content');
  }
  // Return primitives as is.
  else if (val !== Object(val)) {
    return val;
  }
  // Map html documents to flat html strings.
  else if (val.nodeType === 'document') {
    const isMulti = val.content.length > 1;
    return documentToHtmlString(
      val,
      isMulti
        ? {}
        : {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, next) => {
                return next(node.content);
              },
            },
          }
    );
  }
  // Map file entries to their URL. Can do more later with
  // file-sizes, content types, etc, but this is mostly useless
  // to us as we are using the CDN to control these at any rate.
  else if (val.url) {
    return {
      url: val.url,
    };
  }
  // Assets always need to be linked to asset data and hydrated
  // as the source of truth as they may be removed during a
  // contentful sync.
  else if (isAsset(val)) {
    return getNewEntry(val);
  } else if (Array.isArray(val)) {
    return val.map((v) => mapEntry(v));
  }
  // Map linked entries
  else if (val.sys && val.sys.type === 'Entry') {
    return mapEntry(val);
  }
  // Error on any unknown type.
  else {
    return val;
  }
}

// Asset handling

function hydrate(data) {
  if (data) {
    hydrateAssetUrls(data);
    hydrateEntries(data);
  }
  return data;
}

function hydrateAssetUrls(data) {
  forEachAsset(data, (asset) => {
    if (asset.file) {
      asset.url = asset.file.url;
      delete asset.file;
    }
  });
}

function hydrateEntries(data) {
  removeNullArrayEntries(data);
  forEachEntry(data, (entry) => {
    Object.keys(entry || {}).forEach((key) => {
      const field = entry[key];
      if (isAsset(field)) {
        const asset = data.assets[field.sys.id];
        if (asset) {
          entry[key] = asset;
        } else {
          delete entry[key];
        }
      }
    });
  });
}

function mapAssets(assets) {
  return assets.reduce((map, asset) => {
    map[asset.sys.id] = mapEntry(asset);
    return map;
  }, {});
}

function isAsset(val) {
  const sys = val.sys;
  return (
    sys &&
    (sys.type === 'Asset' || (sys.type === 'Link' && sys.linkType === 'Asset'))
  );
}

// Deletion

function handleDeletedEntries(data, deletedEntries) {
  forEachEntry(data, (entry, entryKey, entrySet) => {
    handleDeleted(entrySet, entryKey, deletedEntries);
  });
}

function handleDeletedAssets(data, deletedAssets) {
  forEachAsset(data, (asset, key, assets) => {
    handleDeleted(assets, key, deletedAssets);
  });
}

function handleDeleted(set, key, deleted) {
  const obj = set[key];
  if (deleted.some((d) => d.sys.id === obj.sys.id)) {
    delete set[key];
  }
}

function removeNullArrayEntries(data) {
  forEachEntry(data, (entry, entryKey, entrySet) => {
    // Remove all null array entries
    if (Array.isArray(entrySet) && entry == null) {
      entrySet.splice(entryKey, 1);
    }
  });
}

// Util

function forEachEntry(data, fn) {
  Object.keys(data.entries || {}).forEach((setKey) => {
    const set = data.entries[setKey];
    Object.keys(set || {}).forEach((entryKey) => {
      fn(set[entryKey], entryKey, set);
    });
  });
}

function forEachAsset(data, fn) {
  Object.keys(data.assets || {}).forEach((key) => {
    fn(data.assets[key], key, data.assets);
  });
}

function getNewEntry(obj) {
  const sys = pick(obj.sys, ['id', 'type', 'linkType']);
  return {
    sys,
  };
}

function setPropIfExists(obj, key, val) {
  if (val != null) {
    obj[key] = val;
  }
}

function isFresh(data) {
  return !!data && !!data.timestamp && Date.now() - data.timestamp < MAX_AGE;
}

function getStored() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
}

async function fetchAndStoreEntries() {
  const client = createClient({
    space: CONTENTFUL_SPACE_ID,
    environment: CONTENTFUL_ENVIRONMENT,
    accessToken: CONTENTFUL_DELIVERY_TOKEN,
  });

  const nextSyncToken = cache && cache.nextSyncToken;

  const params = nextSyncToken ? { nextSyncToken } : { initial: true };

  // toPlainObject prevents circular reference issues;
  let response = (await client.sync(params)).toPlainObject();

  let data;

  // Need to first merge the assets once to
  // pass them into mapEntries below.
  data = merge(getStored() || {}, {
    assets: mapAssets(response.assets),
  });

  // Now merge the rest of the data.
  data = merge(data, {
    timestamp: Date.now(),
    nextSyncToken: response.nextSyncToken,
    entries: mapEntries(response.entries, data.assets),
  });

  handleDeletedEntries(data, response.deletedEntries);
  handleDeletedAssets(data, response.deletedAssets);

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  return data;
}

export default async function fetch() {
  if (loadPromise) {
    return loadPromise;
  }
  cache = cache || hydrate(getStored());
  if (isFresh(cache)) {
    return cache;
  } else {
    loadPromise = fetchAndStoreEntries();
    const data = await loadPromise;
    loadPromise = null;
    cache = hydrate(data);
    return cache;
  }
}

export function clear() {
  cache = null;
}
