import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { ContentfulImage } from 'common/components';
import { useClassName, useWindowSize, useResize } from 'common/hooks';
import GoogleLoginButton from 'public/components/Auth/GoogleLoginButton';
import AppleLoginButton from 'public/components/Auth/AppleLoginButton';
import FacebookLoginButton from 'public/components/Auth/FacebookLoginButton';
import Divider from 'public/components//Divider';
import { WarningMessage } from 'public/components';

import './auth-container.less';

const AuthContainer = ({
  page,
  title,
  form,
  actions,
  onSocialAuth,
  auth,
  betaFeatures,
  error,
}) => {
  const classNames = useClassName('AuthContainer');
  const { height } = useResize(
    document.querySelector('.navigation-header__menu-container')
  );
  const { isMobile } = useWindowSize();
  const [isSocialSignInEnabled, setIsSocialSignInEnabled] = useState(false);

  useEffect(() => {
    betaFeatures
      .fetch('Social SignIn')
      .then((isEnabled) => setIsSocialSignInEnabled(isEnabled));
  }, []);

  function renderActions() {
    const dom = (
      <div className={classNames('actions-container')}>
        {error && (
          <WarningMessage
            content={error.message}
            className={classNames('error')}
          />
        )}
        <div className={classNames('actions')} id="auth-actions">
          {actions}
        </div>
      </div>
    );

    if (isMobile) {
      return ReactDOM.createPortal(dom, document.getElementById('root'));
    }

    return dom;
  }

  return (
    <div className={classNames('wrapper')}>
      <div className={classNames('container')}>
        <h1>{title}</h1>
        {isSocialSignInEnabled && (
          <>
            <div className={classNames('oauth')}>
              <GoogleLoginButton
                onSuccess={(code) =>
                  onSocialAuth(() => auth.google({ code }, 'googleAuth'))
                }
                onError={(err) => onSocialAuth(err)}
              />
              <AppleLoginButton
                onSuccess={(response) =>
                  onSocialAuth(() => auth.apple(response, 'appleAuth'))
                }
                onError={(err) => onSocialAuth(err)}
              />
              <FacebookLoginButton
                onSuccess={({ accessToken }) =>
                  onSocialAuth(() =>
                    auth.facebook({ accessToken }, 'facebookAuth')
                  )
                }
              />
            </div>
            <Divider>OR</Divider>
          </>
        )}
        {form}
      </div>
      {renderActions()}
      {Boolean(page && !isMobile) && (
        <div
          className={classNames('banner')}
          style={{ minHeight: `calc(100vh - ${height}px)` }}>
          <ContentfulImage field={page.object.fields.hero} width={1000} />
        </div>
      )}
    </div>
  );
};

AuthContainer.propTypes = {
  page: PropTypes.object,
  title: PropTypes.string,
  form: PropTypes.any,
  actions: PropTypes.any,
  onSocialAuth: PropTypes.func,
  error: PropTypes.object,
};

export default inject('auth', 'betaFeatures')(AuthContainer);
