import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import { useClassName, useWindowSize } from 'common/hooks';
import { urlForUpload } from 'utils/api';
import { RefTabs } from 'public/components/Tabs';

import { NewCheckoutAddonsOptions } from '../Options';
import { NewCheckoutAddonsDetails } from '../Details';

import './selector.less';

const UNCATEGORIZED = 'Other';

const Selector = ({
  addons,
  setQuantity,
  quantities,
  startTime,
  addonsStore,
  blockDecrease,
}) => {
  const className = useClassName('Selector');
  const [showAddOnDetailModal, setShowAddOnDetailModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [groupedAddons, setGroupedAddons] = useState([]);
  const refs = useRef([]);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    addonsStore.getCategories().then((categories) => {
      const groupUncategorized = (groupedAddons, addon) => {
        const uncategorized = groupedAddons.find(
          (a) => a.categoryName === UNCATEGORIZED
        );

        const addonWithImages = {
          ...addon,
          images: getFallBackImages(addon),
        };
        if (uncategorized) {
          uncategorized.addons = [...uncategorized.addons, addonWithImages];
        } else {
          groupedAddons.push({
            categoryName: UNCATEGORIZED,
            index: null,
            addons: [addonWithImages],
          });
        }

        return groupedAddons;
      };

      const groupCategorized = (addonCategories, groupedAddons, addon) => {
        for (const addonCategory of addonCategories) {
          const categorized = groupedAddons.find(
            (a) => a.categoryName === addonCategory.name
          );

          const addonWithImages = {
            ...addon,
            images: getImages(addonCategory, addon),
            index: addonCategory.addons.find((a) => a.addonName === addon.name)
              .index,
            caption: addonCategory.addons.find(
              (a) => a.addonName === addon.name
            ).caption,
          };

          if (categorized) {
            categorized.addons = orderBy(
              [...categorized.addons, addonWithImages],
              'index'
            );
          } else {
            groupedAddons.push({
              categoryName: addonCategory.name,
              index: addonCategory.index,
              addons: [addonWithImages],
            });
          }
        }

        return groupedAddons;
      };

      const groupedAddons = orderBy(
        addons.reduce((acc, addon) => {
          const addonCategories = categories.filter((category) =>
            category.addons.some(
              (categoryAddon) => addon.name === categoryAddon.addonName
            )
          );

          if (!addonCategories.length) return groupUncategorized(acc, addon);

          return groupCategorized(addonCategories, acc, addon);
        }, []),
        'index'
      );

      setGroupedAddons(groupedAddons);
    });
  }, []);

  const handleAddOnDetail = (product) => {
    setShowAddOnDetailModal(true);
    setSelectedProduct(product);
  };

  const handleCloseAddOnDetail = () => {
    setShowAddOnDetailModal(false);
    setSelectedProduct(null);
  };

  const renderAddOnDetailModal = () => {
    if (!showAddOnDetailModal) {
      return null;
    }

    return (
      <NewCheckoutAddonsDetails
        product={selectedProduct}
        open={showAddOnDetailModal}
        handleClose={handleCloseAddOnDetail}
      />
    );
  };

  const getFallBackImages = (addon) => {
    return addon.image ? [addon.image] : [];
  };

  const getImages = (addonCategory, addon) => {
    const images = addonCategory.addons.find(
      (categoryAddon) => addon.name === categoryAddon.addonName
    )?.images;
    if (!images.length) return getFallBackImages(addon);

    return orderBy(images, ['default', 'index'], ['desc', 'asc']).map((image) =>
      urlForUpload(image, true)
    );
  };

  const hasCategories = groupedAddons.length > 1;
  return (
    <div className={className('container')}>
      {hasCategories && (
        <RefTabs
          fixed
          names={groupedAddons.map((a) => a.categoryName)}
          refs={refs}
        />
      )}
      <div className={className('list')}>
        {groupedAddons.map((category, i) => (
          <div key={i} ref={(el) => (refs.current[i] = el)}>
            {hasCategories && <h2>{category.categoryName}</h2>}
            {category.addons?.map((addon) => (
              <NewCheckoutAddonsOptions
                key={addon.ticketOptionId}
                product={{
                  ...addon,
                  ...(!isMobile && { caption: undefined }),
                }}
                hideDescription={isMobile}
                startTime={startTime}
                onDetail={handleAddOnDetail}
                setQuantity={setQuantity}
                quantities={quantities}
                blockDecrease={blockDecrease}
              />
            ))}
          </div>
        ))}
      </div>
      {renderAddOnDetailModal()}
    </div>
  );
};

Selector.propTypes = {
  addons: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuantity: PropTypes.func.isRequired,
  quantities: PropTypes.object,
  startTime: PropTypes.string,
  addonsStore: PropTypes.object.isRequired,
  blockDecrease: PropTypes.bool,
};

export default Selector;
