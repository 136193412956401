import { observable, action, computed, reaction } from 'mobx';
import { groupBy, isEqual, pick, sumBy } from 'lodash';
import { localStorage } from 'utils/helpers';
import { captureError } from 'utils/sentry';
import { trackProductAdded, trackProductRemoved } from '../helpers/api';

const LOCAL_STORAGE_KEY = 'cart';
const RESERVATION_DATE_REG = /^\d{4}-\d{2}-\d{2}/;

export default class CartStore {
  constructor() {
    reaction(
      () => this.tickets,
      (tickets) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tickets));
      }
    );
  }

  @observable
  tickets = this.hydrateTickets();

  // Public

  @action
  addTicket(props) {
    const ticket = this.pickTicketProps(props);
    if (!this.validateTicket(ticket)) {
      return false;
    }
    const index = this.getExistingIndex(ticket);
    const tickets = this.tickets.concat();
    if (index !== -1) {
      tickets[index] = {
        ...ticket,
        quantity: tickets[index].quantity + ticket.quantity,
      };
    } else {
      tickets.push(ticket);
    }
    this.updateTickets(tickets);
    trackProductAdded(props);
    return true;
  }

  @action
  removeTicket(target, props) {
    const index = this.getExistingIndex(target);
    const tickets = this.tickets.concat();
    if (index === -1) {
      return false;
    }
    const ticket = this.tickets[index];
    tickets.splice(index, 1);
    this.updateTickets(tickets);
    if (props) {
      trackProductRemoved({
        ...props,
        quantity: ticket.quantity,
      });
    }
    return true;
  }

  @action
  updateTicket(target, props) {
    if (!Array.isArray(target)) {
      return this.updateSingleTicket(target, props);
    }
    const processedTargets = target.map((current, index) =>
      this.updateSingleTicket(current, props[index])
    );
    return processedTargets.every((target) => target);
  }

  @action
  clear() {
    this.tickets = [];
  }

  @computed
  get size() {
    return this.getItems().length;
  }

  updateSingleTicket(target, props) {
    const index = this.getExistingIndex(target);
    if (index === -1) {
      this.addTicket(props);
    } else if (props.quantity === 0) {
      this.removeTicket(target);
    } else {
      const existing = this.tickets[index];
      const ticket = this.pickTicketProps(props);
      if (isEqual(existing, ticket)) {
        return false;
      } else {
        const tickets = this.tickets.concat();
        tickets[index] = {
          ...existing,
          ...ticket,
        };
        this.updateTickets(tickets);
      }
    }
    return true;
  }

  getTotalQuantity() {
    return sumBy(
      this.tickets.reduce((reducer, ticket) => {
        if (ticket.bundleSlug) {
          const existing = reducer.find(
            (i) =>
              i.bundleSlug === ticket.bundleSlug &&
              i.reservationDate === ticket.reservationDate
          );

          if (existing) {
            return reducer;
          }
        }
        return reducer.concat(ticket);
      }, []),
      'quantity'
    );
  }

  pickTicketProps(ticket) {
    return pick(ticket, [
      'quantity',
      'venueId',
      'promoCode',
      'price',
      'ticketOptionId',
      'bookingItemId',
      'reservationDate',
      'startTime',
      'startTimeName',
      'bundleSlug',
      'bundleCartId',
      'addons',
      'parentBundleSlug',
      'parentBundleCartId',
      'externalTicket',
      'externalVenue',
    ]);
  }

  // TODO: test!
  findTickets(match) {
    return this.tickets.filter((ticket) => {
      return Object.keys(match).every((key) => ticket[key] === match[key]);
    });
  }

  getTicketOptionIds() {
    return Object.keys(
      groupBy(this.tickets, (ticket) => ticket.ticketOptionId)
    );
  }

  getItems() {
    const obj = groupBy(this.tickets, (ticket) => {
      // Cart items are grouped by
      // their venue and reservation date.
      return [
        ticket.venueId,
        ticket.reservationDate,
        ticket.startTime,
        ticket.startTimeName,
        ticket.promoCode,
        ticket.bundleSlug,
        ticket.bundleCartId,
        ticket.addons,
        ticket.parentBundleSlug,
        ticket.parentBundleCartId,
      ].join('|');
    });
    return Object.entries(obj).map(([groupKey, tickets]) => {
      const [
        venueId,
        reservationDate,
        startTime,
        startTimeName,
        promoCode,
        bundleSlug,
        bundleCartId,
        addons,
        parentBundleSlug,
        parentBundleCartId,
      ] = groupKey.split('|');

      return {
        tickets,
        venueId,
        promoCode,
        reservationDate,
        startTime,
        startTimeName,
        bundleSlug,
        bundleCartId,
        addons,
        parentBundleSlug,
        parentBundleCartId,
      };
    });
  }

  removeItem(ticketOptionId) {
    this.tickets = this.tickets.filter(
      (ticket) => ticket.ticketOptionId !== ticketOptionId
    );
  }

  removeBundle(bundleCartId) {
    let addonsInBundle = [];
    let bundleSlug = '';
    this.tickets.forEach((ticket) => {
      if (ticket.bundleCartId == bundleCartId) {
        bundleSlug = ticket.bundleSlug;
        addonsInBundle.push(ticket.addons);
      }
    });
    addonsInBundle = addonsInBundle.flat().filter((_) => _);
    this.tickets = this.tickets.filter((ticket) => {
      if (
        ticket.bundleCartId === bundleCartId ||
        (ticket.parentBundleSlug === bundleSlug &&
          addonsInBundle.includes(ticket.bookingItemId))
      ) {
        return false;
      }
      return true;
    });
  }

  // Private

  getExistingIndex(target) {
    if (!target.ticketOptionId || !target.reservationDate) {
      throw new Error('No ticketOptionId or reservationDate passed');
    }
    return this.tickets.findIndex((ticket) => this.isEqual(ticket, target));
  }

  validateTicket(ticket, empty = false) {
    return (
      ticket.quantity >= (empty ? 0 : 1) &&
      !!ticket.venueId &&
      !!ticket.ticketOptionId &&
      RESERVATION_DATE_REG.test(ticket.reservationDate)
    );
  }

  isEqual(ticket, target) {
    return (
      target.ticketOptionId === ticket.ticketOptionId &&
      target.reservationDate === ticket.reservationDate &&
      (target.startTime === ticket.startTime ||
        (!target.startTime && !ticket.startTime)) &&
      (target.bundleSlug === ticket.bundleSlug ||
        (!target.bundleSlug && !ticket.bundleSlug)) &&
      (target.bundleCartId === ticket.bundleCartId ||
        (!target.bundleCartId && !ticket.bundleCartId))
    );
  }

  hydrateTickets() {
    const str = localStorage.getItem(LOCAL_STORAGE_KEY);
    let tickets = [];
    if (str) {
      try {
        tickets = JSON.parse(str);
        const valid = tickets.every((ticket) => this.validateTicket(ticket));
        if (!valid) {
          tickets = [];
          throw new Error(`Invalid tickets in cart: ${str}`);
        }
      } catch (err) {
        captureError(err);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }
    return tickets;
  }

  updateTickets(tickets) {
    // Keeping this method here to ensure that the observable array
    // reference is always updated, otherwise the reaction will not work.
    this.tickets = tickets;
  }
}
