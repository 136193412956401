import React from 'react';
import { useHistory } from 'react-router-dom';
import { useClassName } from 'common/hooks';
import Template from './components/Template';
import Button from './components/Button';
import character from './assets/abmg/character.png';
import blmgCharacter from './assets/blmg/character.png';
import './rules.less';

const Rules = (props) => {
  const className = useClassName('Rules');
  const { slug } = props.match.params;
  const history = useHistory();
  const isAngryBirds = slug.includes('angry-birds');

  const handleCtaClick = () => {
    history.push(window.location.pathname.replace('rules', 'board'));
  };

  return (
    <Template venueName={slug}>
      <p className={className('title')}>
        The player with the fewest strokes wins!
      </p>
      <p className={className('description')}>
        You will be able to record the number of strokes made by each player to
        get the hole.
      </p>
      <div className={className('cta-container')}>
        <img
          className={className('character-image')}
          src={isAngryBirds ? character : blmgCharacter}
          alt="character"
        />
        <Button
          className={className('cta')}
          label="Continue"
          handleClick={handleCtaClick}
          venueName={slug}
          primary
        />
      </div>
    </Template>
  );
};

export default Rules;
