import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useClassName } from 'common/hooks';
import Asset from './Asset';
import Text from './Text';
import CTA from './CTA';
import chevronRight from 'common/assets/chevron-right.svg';

import './gallery.less';

const Gallery = ({ images, isCardGallery }) => {
  const history = useHistory();
  const className = useClassName('ModularGallery');
  const [imageExtraClasses, setImageExtraClasses] = useState([]);
  const [contentExtraClasses, setContentExtraClasses] = useState([]);
  const [overlayExtraClasses, setOverlayExtraClasses] = useState([]);
  const [linkCTAExtraClasses, setLinkCTAExtraClasses] = useState([]);

  const defineOverlayExtraClasses = (rounded) => {
    if (rounded) {
      setOverlayExtraClasses(['overlay-big']);
      setLinkCTAExtraClasses(['link-cta-rounded']);
    }
  };

  const defineExtraClasses = () => {
    const contentClasses = [];
    const imageClasses = [];

    if (images.length === 1) {
      imageClasses.push('image-container-large');
      contentClasses.push('content-large');
      defineOverlayExtraClasses(true);
    } else if (images.length === 2) {
      imageClasses.push('image-container-medium');
      contentClasses.push('content-medium');
      defineOverlayExtraClasses(true);
    } else if (
      images.length === 3 ||
      (images.length % 3 === 0 && images.length % 4 !== 0)
    ) {
      imageClasses.push('image-container-small');
      contentClasses.push('content-small');
      defineOverlayExtraClasses(false);
    } else {
      imageClasses.push('image-container-tiny');
      contentClasses.push('content-tiny');
      defineOverlayExtraClasses(false);
    }

    if (isCardGallery) {
      contentClasses.push('content-card');
      imageClasses.push('image-container-card');
    }

    setContentExtraClasses(contentClasses);
    setImageExtraClasses(imageClasses);
  };

  useEffect(() => {
    defineExtraClasses();
  }, []);

  const openURL = (url) => {
    const isExternal = url.startsWith('http');
    if (isExternal) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };

  const renderTextBlock = (textBlock) => {
    return (
      <div
        className={className([
          'text-block',
          `text-block-${textBlock.textAlign}`,
        ])}>
        {textBlock.overline && (
          <Text key="overline" text={textBlock.overline} />
        )}
        {textBlock.title && <Text key="title" text={textBlock.title} />}
        {textBlock.body && <Text key="body" text={textBlock.body} />}
        {textBlock?.cta?.length && (
          <div className={className('text-block-cta-container')}>
            {textBlock.cta.map((cta, index) => (
              <CTA key={`cta-${index}`} cta={cta} />
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderOverlay = (textBlock) => {
    return (
      <div
        className={className(['overlay', ...overlayExtraClasses])}
        onClick={() => textBlock?.cta?.value && openURL(textBlock.cta.value)}>
        <Text key="title" text={textBlock.title} />
        {textBlock?.cta?.length && (
          <div className={className('cta-container')}>
            {textBlock.cta.map((cta, index) => (
              <div
                key={`cta-${index}`}
                className={className(['link-cta', ...linkCTAExtraClasses])}>
                <img
                  src={chevronRight}
                  alt={'chevron-right'}
                  width={24}
                  height={24}
                  onClick={() => openURL(cta.value)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={className('container')}>
      {images.map((image, index) => (
        <div
          className={className(['content', ...contentExtraClasses])}
          key={`content-${index}`}
          style={{
            backgroundColor: image.textBlock?.backgroundColor
              ? image.textBlock.backgroundColor
              : '',
          }}>
          <div className={className(['image-container', ...imageExtraClasses])}>
            <Asset asset={image} />
            {image.textBlock?.title &&
              isCardGallery &&
              renderOverlay(image.textBlock)}
          </div>
          {image.textBlock &&
            !isCardGallery &&
            renderTextBlock(image.textBlock)}
        </div>
      ))}
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCardGallery: PropTypes.bool,
};

Gallery.defaultProps = {
  isCardGallery: false,
};

export default Gallery;
