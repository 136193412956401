import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useClassName, useScrollBlock, useHideHelpscout } from 'common/hooks';
import './template.less';

const Template = ({ venueName, children }) => {
  const className = useClassName('Template');
  const [blockScroll, allowScroll] = useScrollBlock();
  const [hideHelpscout, showHelpscout] = useHideHelpscout();

  useEffect(() => {
    blockScroll();
    hideHelpscout();
    return () => {
      allowScroll();
      showHelpscout();
    };
  }, []);

  return (
    <div className={className(['container', `${venueName}-container`])}>
      {children}
    </div>
  );
};

Template.propTypes = {
  venueName: PropTypes.string.isRequired,
};

export default Template;
