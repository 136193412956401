import { useEffect, useState } from 'react';

const MAX_ATTEMPTS = 10;

const useChat = ({ openOnLoad }) => {
  const [message, setMessage] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [button, setButton] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const beacon = document.querySelector('.BeaconFabButtonFrame');
      if (beacon) {
        const button = beacon
          .querySelector('iframe')
          .contentDocument.querySelector('button');

        if (button) {
          button.addEventListener('click', () => {
            const isOpen =
              document.querySelector('.BeaconContainer-enter-done') != null;
            setIsOpen(!isOpen);
          });

          if (openOnLoad) {
            button.click();
          }

          setButton(button);
          setLoading(false);
          clearTimeout(timer);
        }
      } else if (attempts >= MAX_ATTEMPTS) {
        setButton(null);
        setMessage('Chat could not be loaded');
        setLoading(false);
        clearTimeout(timer);
      } else {
        setAttempts((prevAttempts) => prevAttempts + 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [attempts]);

  return {
    message,
    loading,
    isOpen,
    open: (ev) => {
      ev.preventDefault();
      if (!button) return;

      button.click();
    },
  };
};

export default useChat;
