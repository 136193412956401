import React from 'react';
import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';
import { Container } from 'semantic-ui-react';

export default class Food extends Screen {
  render() {
    return (
      <Container textAlign="center" text>
        <Spacer size="l" />
        <h1>Food</h1>
        <Spacer size="l" />
      </Container>
    );
  }
}
