import React from 'react';
import { Screen } from 'public/helpers';
import { Button, Checkbox } from 'semantic-ui-react';
import { Spacer } from 'public/components';

import './roller-checkout.less';

const COPIES = [
  {
    path: '/redeem',
    title: 'REDEEM YOUR EXPERIENCE',
    description:
      'To begin redeeming your American Dream experience, please read and acknowledge the American Dream Waiver, Release, and Indemnity Agreement and click on the "Redeem" button.',
    button: 'REDEEM',
  },
];

const DEFAULT_COPIES = {
  title: 'WAIVER AGREEMENT',
  description:
    'Please read and acknowledge the American Dream Waiver, Release, and Indemnity Agreement and click on the "Continue" button.',
  button: 'CONTINUE',
};

const WAIVER_PATHS = ['/redeem', '/buy'];

export default class RollerCheckout extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      slug: null,
      showRedemption: false,
      copies: DEFAULT_COPIES,
    };
  }

  onWaiverAcceptedChange = () => {
    this.setState(({ waiverAccepted }) => ({
      waiverAccepted: !waiverAccepted,
    }));
  };

  onHealthAgreementAcceptedChange = () => {
    this.setState(({ healthAgreementAccepted }) => ({
      healthAgreementAccepted: !healthAgreementAccepted,
    }));
  };

  onProceed = () => {
    if (this.state.waiverAccepted && this.state.healthAgreementAccepted) {
      this.setState({
        showRedemption: true,
      });
    }
    this.appendResponsiveScript();
  };

  appendResponsiveScript = () => {
    const script = document.createElement('script');
    script.src = '//cdn.rollerdigital.com/scripts/widget/responsive_iframe.js';
    script.async = true;
    document.body.appendChild(script);
  };

  async routeDidUpdate() {
    const { checkoutslug } = this.props.match.params;
    this.setState({
      checkoutslug,
    });

    const showRedemption = WAIVER_PATHS.every(
      (path) => this.props.match.path.indexOf(path) === -1
    );

    if (showRedemption) {
      this.setState({
        showRedemption,
      });
      this.appendResponsiveScript();
    }

    const pathCopy = COPIES.find(
      (copy) => this.props.match.path.indexOf(copy.path) === 0
    );

    if (pathCopy) {
      this.setState({
        copies: pathCopy,
      });
    }
  }

  renderAgreements() {
    return (
      <div className={this.getElementClass('agreements')}>
        <h1>{this.state.copies.title}</h1>
        <Spacer size="xs" />
        <h6>{this.state.copies.description}</h6>
        <Spacer size="xs" />
        <Checkbox
          id="waiverAccepted"
          name="waiverAccepted"
          checked={this.state.waiverAccepted}
          onChange={this.onWaiverAcceptedChange}
          label={
            <span>
              I have read and agree to be bound by the “American Dream Waiver,
              Release and Indemnity Agreement” found{' '}
              <a href="/waiver" target="_blank">
                here
              </a>
            </span>
          }
        />
        <Spacer size="xs" />
        <div
          className={`${this.getElementClass(
            'health-terms'
          )} scrolling content`}>
          <p>
            <b>Please Agree To The Terms Below</b>
          </p>
          <h1>American Dream Health & Safety Policy</h1>
          <p>
            At American Dream, the health and safety of our guests, tenants,
            brand partners, and community is, and continues to be, our number
            one priority.
          </p>
          <p>
            To further protect the health and safety of our guests and
            community, 
            <b>
              you will not be admitted to the park and/or attraction and you
              should stay home if, within fourteen (14) days prior to the date
              of your arrival, you have
            </b>
            :
          </p>
          <ul>
            <li>
              Tested positive or have been deemed presumptively positive with
              COVID-19;
            </li>
            <li>
              Sought testing to determine whether you might have COVID-19, and
              have not yet received the results;
            </li>
            <li>
              Been informed that you may have been exposed to COVID-19 or
              learned that you have been in close or proximate contact of any
              who has tested positive for COVID-19, is known to have been
              carrying COVID-19, or who has had symptoms of COVID-19;
            </li>
            <li>
              Experienced any of the following symptoms commonly associated with
              COVID-19, including cough, shortness of breath or difficulty
              breathing, OR two or more of any of the following: fever, chills,
              repeated shaking with chills, muscle pain or body aches, headache,
              sore throat, congestion or runny nose, nausea or vomiting,
              diarrhea, new loss of taste or smell;
            </li>
          </ul>
          <p>
            Once you enter any parks and/or attractions at American Dream, 
            <b>you must </b>comply with American Dream protocols while on-site,
            including but not limited to:
          </p>
          <ul>
            <li>
              If you are not vaccinated for COVID-19, wear your protective face
              covering at all times during your visit (other than children two
              and under);
            </li>
            <li>
              If you are vaccinated for COVID-19, we strongly recommend that you
              continue to wear protective face covering at all times during your
              visit;{' '}
            </li>
            <li>
              Follow physical distancing guidelines and maintain at least 6 feet
              between yourself and others;
            </li>
            <li>
              Wash hands and sanitize regularly with soap and water and/or by
              using touchless hand sanitizer stations on-site.
            </li>
          </ul>
          <p>
            American Dream reserves the right to modify these requirements on a
            case-by-case basis to accommodate any circumstance that in American
            Dream’s sole discretion warrants a modification to this policy.
          </p>
          <p>
            We have taken enhanced health and safety measures – for you, our
            other guests, tenants, and brand partners. You must follow all
            posted instructions while visiting American Dream. If at any time
            American Dream determines that a guest does not meet the criteria
            outlined in this policy, American Dream reserves the right to refuse
            entry or (if the guest is already in the park or attraction)
            immediately remove the guest from the park or attraction. These
            actions are at the sole discretion of American Dream.
          </p>
          <p>
            American Dream will continue to update our procedures and protocols
            to remain in compliance with Federal, State and Local orders as well
            as guidelines from the CDC. Such measures, however, do not eliminate
            the inherent risk of potential exposure to COVID-19 in public
            spaces. By visiting American Dream, you acknowledge that COVID-19 is
            an extremely contagious and dangerous disease and you voluntarily
            assume all risks associated with exposure to COVID-19 and release
            American Dream and its owners and affiliates from any and all
            related liability.
          </p>
        </div>
        <Spacer size="xs" />
        <Checkbox
          id="healthAgreementAccepted"
          name="healthAgreementAccepted"
          checked={this.state.healthAgreementAccepted}
          onChange={this.onHealthAgreementAcceptedChange}
          label={
            <span>
              By checking this box you acknowledge that you have read,
              understand and agree{' '}
              <span className="highlight">
                the above American Dream Health & Safety policy
              </span>
              , and that you have shared this policy with all members of your
              party or informed them of its requirements. If you have any
              questions, or if you do not understand the policy, please contact
              guest relations.
            </span>
          }
        />
        <Spacer size="s" />
        <Button
          disabled={
            !this.state.waiverAccepted || !this.state.healthAgreementAccepted
          }
          onClick={this.onProceed}
          fluid
          primary>
          {this.state.copies.button}
        </Button>
      </div>
    );
  }

  renderBody() {
    return (
      <div {...this.getAttrs()}>
        {!this.state.showRedemption && this.renderAgreements()}
        {this.state.showRedemption && (
          <iframe
            id="roller-widget"
            src={`https://roller.app/americandream/products/${this.state.checkoutslug}`}
            frameBorder="0"
            width="100%"></iframe>
        )}
      </div>
    );
  }
}
