import React from 'react';
import { Container } from 'semantic-ui-react';
import { Screen, withVenue } from 'public/helpers';
import { ContentfulRichText, Content } from 'common/components';
import { Spacer, AttractionsNav } from 'public/components';

import './home.less';

@withVenue('big-snow')
export default class BigSnowTicketInfo extends Screen {
  render() {
    const { venue } = this.props;
    return (
      <div {...this.getAttrs()}>
        <AttractionsNav
          ticketsHref="https://www.bigsnowamericandream.com"
          venue={venue}
        />
        <Container>
          <Spacer size="m" />
          <Content>
            <ContentfulRichText field={venue.content.ticketInfo} />
          </Content>
        </Container>
        <Spacer size="l" />
      </div>
    );
  }
}
