import React from 'react';
import { NavLink } from 'react-router-dom';
import { Component } from 'common/helpers';

import './press-nav.less';

export default class PressNav extends Component {
  render() {
    return (
      <nav {...this.getAttrs()}>
        <NavLink
          to="/press"
          className={this.getElementClass('link', 'featured')}
          exact>
          Press
        </NavLink>
        <span className={this.getElementClass('divider')}> | </span>
        <NavLink
          to="/press/releases"
          className={this.getElementClass('link', 'releases')}>
          Press Releases
        </NavLink>
      </nav>
    );
  }
}
