import React from 'react';
import { inject } from 'mobx-react';
import { Loader, Container } from 'semantic-ui-react';

import { ContentfulPlainText, ContentfulRichText } from 'common/components';
import { Screen } from 'public/helpers';
import { Hero, Eyebrow, Spacer } from 'public/components';
import { NotFound } from '../NotFound';

@inject('events')
export default class EventPage extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      event: props.preview,
      error: null,
      loading: true,
    };
  }

  // TODO: make better
  getHeroImages() {
    const { event } = this.state;
    const hero = event.object.fields.hero;
    if (!hero) return;
    return hero['en-US'].map((f) => {
      const { url, details } = f.fields.file['en-US'];
      let ratio;
      if (details.image) {
        ratio = details.image.width / details.image.height;
      } else {
        ratio = 1.3333;
      }
      return {
        url,
        ratio,
      };
    });
  }

  async routeDidUpdate() {
    if (this.props.preview) {
      // Event may be passed in by the preview screen.
      return;
    }
    try {
      const { slug } = this.props.match.params;
      this.setState({
        event: await this.props.events.fetchItemBySlug(slug),
        loading: false,
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
        });
      } else {
        throw error;
      }
    }
  }

  renderBody() {
    const { event, error } = this.state;
    if (error) {
      return <NotFound {...this.props} />;
    } else if (!event) {
      return (
        <React.Fragment>
          <Spacer size="l" />
          <Loader inline="centered" active />
        </React.Fragment>
      );
    }
    return <div {...this.getAttrs()}>{this.renderEvent(event)}</div>;
  }

  renderEvent(event) {
    if (event.source === 'contentful') {
      return this.renderContentful(event);
    }
  }

  renderContentful(event) {
    const { fields } = event.object;
    return (
      <Container>
        <Spacer size="m" />
        <Eyebrow
          title={<ContentfulPlainText field={fields.category} />}
          subtitle={<ContentfulPlainText field={fields.displayDate} />}
        />
        <h1>{event.name}</h1>
        <Spacer size="s" />
        {this.renderHero()}
        <Spacer size="s" />
        <ContentfulRichText field={event.object.fields.body} />
        <Spacer size="l" />
      </Container>
    );
  }

  renderHero() {
    const heroImages = this.getHeroImages();
    if (heroImages && heroImages.length) {
      const [main, fallback] = heroImages;
      return <Hero media={main} fallback={fallback} />;
    }
  }
}
