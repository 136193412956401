import React from 'react';
import { Component } from 'common/helpers';
import { ContentLink, Layout } from 'common/components';
import { Button } from 'semantic-ui-react';

import './horizontalrow.less';

export default class HorizontalRowCard extends Component {
  getModifiers() {
    return [this.props.hover ? 'hover' : null];
  }

  render() {
    const { href, title, date, location } = this.props;
    return (
      <div {...this.getAttrs()}>
        <Layout horizontal stackable className={this.getElementClass('wrap')}>
          <Layout.Group className={this.getElementClass('title')}>
            {title}
          </Layout.Group>
          <Layout.Group className={this.getElementClass('date')}>
            {date}
          </Layout.Group>
          <Layout.Group className={this.getElementClass('location')}>
            {location}
          </Layout.Group>
          <Layout.Group className={this.getElementClass('cta')}>
            <Button as={ContentLink} to={href}>
              More Info
            </Button>
          </Layout.Group>
        </Layout>
      </div>
    );
  }
}
