import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useClassName, useHideHelpscout } from 'common/hooks';
import { getCookie } from 'utils/helpers/cookie';
import Button from './components/Button';
import './winner.less';
import abmgBadge from './assets/abmg/badge.png';
import character from './assets/abmg/character.png';
import abmgLogo from './assets/abmg/logo.png';
import blmgBadge from './assets/blmg/badge.png';
import blmgCharacter from './assets/blmg/character.png';
import blmgLogo from './assets/blmg/logo.png';

const Winner = (props) => {
  const [totalScore, setTotalScore] = useState();
  const [isDraw, setIsDraw] = useState(false);
  const [creatingImage, setCreatingImage] = useState(false);
  const [canvas, setCanvas] = useState(null);
  const [hideHelpscout, showHelpscout] = useHideHelpscout();
  const { slug } = props.match.params;
  const className = useClassName('Winner');
  const history = useHistory();
  const isAngryBirds = slug.includes('angry-birds');
  const containerRef = useRef();

  useEffect(() => {
    hideHelpscout();
    const miniGolfCookie = getCookie('miniGolfScore');
    const miniGolfScore = miniGolfCookie ? JSON.parse(miniGolfCookie) : {};
    if (!miniGolfScore.gameCompleted)
      history.push(window.location.pathname.replace('winner', 'board'));
    const totalScore = miniGolfScore.players
      .map((player) => ({
        name: player.name,
        score: player.score.reduce((acc, cur) => (acc += cur), 0),
      }))
      .sort((a, b) => a.score - b.score)
      .reduce((acc, cur, index) => {
        if (!index) {
          acc.push({
            ...cur,
            position: 1,
          });
          return acc;
        }
        const position =
          cur.score === acc[index - 1].score
            ? acc[index - 1].position
            : acc[index - 1].position + 1;

        acc.push({
          ...cur,
          position,
        });
        return acc;
      }, []);
    setTotalScore(totalScore);
    setIsDraw(totalScore.filter((score) => score.position === 1).length > 1);
    createCanvas();
    return () => showHelpscout();
  }, []);

  const createCanvas = async () => {
    setTimeout(async () => {
      const shareElement = containerRef.current.cloneNode(true);
      shareElement.style.height = 'max-content';
      shareElement.querySelector('#ctas-container').style.display = 'none';
      shareElement.querySelector('#character-container').style.display = 'flex';
      shareElement.querySelectorAll('.animated').forEach((el) => {
        el.style.opacity = 1;
        el.style.animation = 'none';
      });
      containerRef.current.after(shareElement);
      const canvas = await html2canvas(shareElement);
      shareElement.remove();
      setCanvas(canvas);
    }, 500);
  };

  const getOrdinalPosition = (position) => {
    if (position === 1) return `${position}st`;
    if (position === 2) return `${position}nd`;
    return `${position}th`;
  };

  const isWinner = (index) => index === 0 && !isDraw;

  const downloadImage = (canvas) => {
    const blob = canvas.toDataURL('image/png', 1.0);
    const fakeLink = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = 'winner';
    fakeLink.href = blob;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
  };

  const handleShare = async () => {
    try {
      setCreatingImage(true);
      const blob = await new Promise((resolve) => canvas.toBlob(resolve));
      const files = [new File([blob], 'winner.png', { type: blob.type })];
      const shareData = {
        files,
      };
      if (navigator.canShare(shareData))
        return await navigator.share(shareData);
      console.info('Shared method not available');
      downloadImage(canvas);
    } catch (e) {
      console.error('Error creating image', e.message);
    } finally {
      setCreatingImage(false);
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        className={className(['container', `container-${slug}`])}>
        {totalScore && (
          <>
            <img
              className={`animated ${className('badge')}`}
              src={isAngryBirds ? abmgBadge : blmgBadge}
              alt="badge"
            />
            <p id="title" className={`animated ${className('title')}`}>
              {isDraw ? 'We have a draw!' : 'Congrats!'}
            </p>
            <p className={`animated ${className('message')}`}>
              {isAngryBirds ? 'Angry Birds' : 'Black Light'} minigolf completed!
            </p>
            <div className={className('results')}>
              {totalScore.map((player, index) => (
                <div
                  key={`player-${index}`}
                  className={`animated ${className([
                    'result-row',
                    `result-row-${isWinner(index) && 'winner'}`,
                  ])}`}>
                  <div className={className('position-container')}>
                    <span>{getOrdinalPosition(player.position)}</span>
                    <span className={className('dot')}>·</span>
                    <span>{player.score}</span>
                  </div>
                  <p
                    className={className([
                      'player-name',
                      `player-name-${isWinner(index) && 'winner'}`,
                    ])}>
                    {player.name}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
        <div id="ctas-container" className={className('ctas-container')}>
          <Button
            label="Scorecard"
            handleClick={() =>
              history.push(window.location.pathname.replace('winner', 'board'))
            }
            venueName={slug}
            className={className([
              'cta',
              'score-card-cta',
              `score-card-cta-${slug}`,
            ])}
          />
          <Button
            label="Share"
            handleClick={handleShare}
            venueName={slug}
            className={className(['cta', 'share-cta'])}
            primary
            disabled={!canvas}
          />
        </div>
        <div
          id="character-container"
          className={className('character-container')}>
          <img
            className={className('character')}
            src={isAngryBirds ? character : blmgCharacter}
            alt="character"
          />
          <img
            className={className('logo')}
            src={isAngryBirds ? abmgLogo : blmgLogo}
            alt="logo"
          />
        </div>
      </div>
      <Dimmer style={{ height: '100vh' }} active={creatingImage}>
        <Loader />
      </Dimmer>
    </>
  );
};

export default Winner;
