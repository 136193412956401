import React from 'react';
import { Container } from 'semantic-ui-react';
import { Component } from 'common/helpers';
import { Layout } from 'common/components';
import { Spacer } from 'public/components';
import Nav from './Nav';

import './profile-group.less';

export default class ProfileGroup extends Component {
  render() {
    return (
      <Container {...this.getAttrs()}>
        <Spacer size="m" />
        <Layout vertical stackable>
          <Layout.Group className={this.getElementClass('nav')}>
            <Nav {...this.props} />
          </Layout.Group>
          <Layout.Group grow>{this.props.children}</Layout.Group>
        </Layout>
        <Spacer size="l" />
      </Container>
    );
  }
}
