import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Loader } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import { Screen } from 'public/helpers';
import { SignupForm } from 'public/components';
import { identify, track } from 'utils/analytics';
import { isValidEmail, isValidPhone, isValidZipCode } from 'utils/helpers';
import { DEFAULT_GROUP_ID } from 'common/const';

import SuccessRedirect from './SuccessRedirect';
import { AuthContainer } from './AuthContainer';

const validations = {
  firstName: [
    {
      isInvalid: (fields) => !fields.firstName,
      message: 'First Name is required',
    },
    {
      isInvalid: (fields) => fields.firstName.length > 30,
      message: 'First Name should have less than 30 characters',
    },
  ],
  lastName: [
    {
      isInvalid: (fields) => !fields.lastName,
      message: 'Last Name is required',
    },
    {
      isInvalid: (fields) => fields.lastName.length > 30,
      message: 'Last Name should have less than 30 characters',
    },
  ],
  email: [
    { isInvalid: (fields) => !fields.email, message: 'Email is required' },
    {
      isInvalid: (fields) => !isValidEmail(fields.email),
      message: 'Please provide a valid email',
    },
  ],
  password: [
    {
      isInvalid: (fields) => !fields.password,
      message: 'Password is required',
    },
    {
      isInvalid: (fields) => fields.password.length < 6,
      message: 'Password length must be at least 6 characters long',
    },
  ],
  zipCode: [
    {
      isInvalid: (fields) => !fields.zipCode,
      message: 'Zip / Postal Code is required',
    },
    {
      isInvalid: (fields) => !isValidZipCode(fields.zipCode),
      message: 'Please provide a valid Zip / Postal Code',
    },
  ],
  phone: [
    {
      isInvalid: (fields) => !fields.phone,
      message: 'Phone Number is required',
    },
    {
      isInvalid: (fields) => !isValidPhone(fields.phone),
      message: 'Please provide a valid Phone Number',
    },
  ],
  termsAccepted: [
    {
      isInvalid: (fields) => !fields.termsAccepted,
      message: 'Please accept the terms and conditions',
    },
  ],
};

@inject('pages', 'auth', 'me', 'subscriptions')
@observer
export default class Signup extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
      loading: false,
      formData: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        phone: '',
        termsAccepted: false,
        emailAccepted: true,
      },
      fieldErrors: {},
    };
  }

  componentDidMount() {
    this.props.pages
      .fetchItemBySlug('signup')
      .then((page) => this.setState({ page: page }));
  }

  onSignupSuccess = () => {
    this.setState({
      completed: true,
      loading: false,
    });
  };

  onSignupFail = (error) => {
    this.setState({
      error,
      loading: false,
    });
  };

  setField = (evt) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [evt.target.name]:
          evt.target.type === 'checkbox'
            ? evt.target.checked
            : evt.target.value,
      },
    });
  };

  validate = (fieldName) => {
    const { formData } = this.state;
    const fields = {
      email: formData.email,
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
      zipCode: formData.zipCode,
      phone: formData.phone,
      termsAccepted: formData.termsAccepted,
    };

    const fieldErrors = Object.keys(fields)
      .filter((key) => !fieldName || fieldName === key)
      .reduce((fieldErrors, key) => {
        const fieldValidations = validations[key];
        if (!fieldValidations?.length) return fieldErrors;

        const error = fieldValidations.find((a) => a.isInvalid(fields));
        return {
          ...fieldErrors,
          [key]: error ? error.message : null,
        };
      }, {});

    this.setState({
      fieldErrors: {
        ...this.state.fieldErrors,
        ...fieldErrors,
      },
    });

    return !Object.values(fieldErrors).some(Boolean);
  };

  onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });

    try {
      const {
        firstName,
        lastName,
        email,
        password,
        zipCode,
        phone,
        termsAccepted,
        emailAccepted,
      } = this.state.formData;
      const body = {
        firstName,
        lastName,
        email,
        password,
        address: {
          zipCode,
        },
        phone,
        optIns: {
          termsAccepted,
          emailAccepted,
          smsAccepted: false,
        },
      };
      const user = await this.props.auth.register(body, 'register');
      this.props.me.user = user;
      identify({
        ...user,
        zipCode,
        signup: 'yes',
      });
      track(`subscription signup form`);

      await this.props.subscriptions.create({
        ...user,
        group: DEFAULT_GROUP_ID,
      });

      this.onSignupSuccess();
    } catch (err) {
      this.onSignupFail(err);
    }
  };

  onSocialAuth = async (handler) => {
    if (handler instanceof Error) {
      this.onLoginFail(handler);
      return;
    }

    this.setState({ loading: false });

    try {
      const user = await handler();
      this.props.me.user = user;
      identify({
        ...user,
        signup: 'yes',
      });

      await this.props.subscriptions.create({
        ...user,
        group: DEFAULT_GROUP_ID,
      });

      track(`Signed Up`);
      track(`Signed Up - Social`);

      this.onSignupSuccess();
    } catch (err) {
      this.onSignupFail(err);
    }
  };

  renderBody() {
    const { completed } = this.state;
    if (completed) {
      return <SuccessRedirect />;
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <AuthContainer
        title="Create an account"
        page={this.state.page}
        onSocialAuth={this.onSocialAuth}
        error={this.state.error}
        form={
          <SignupForm
            formData={this.state.formData}
            fieldErrors={this.state.fieldErrors}
            onSuccess={this.onSignupSuccess}
            validate={this.validate}
            setField={this.setField}
          />
        }
        actions={
          <>
            <Button
              type="submit"
              fluid
              primary
              size="large"
              className="static"
              onClick={this.onSubmit}
              disabled={this.state.loading}>
              {this.state.loading ? (
                <Loader active inline size="tiny" />
              ) : (
                'Sign Up'
              )}
            </Button>
            <Button
              type="button"
              animated={false}
              fluid
              size="large"
              className="static"
              onClick={() =>
                this.setState({ redirect: `/login${window.location.search}` })
              }>
              I already have an account
            </Button>
          </>
        }
      />
    );
  }
}

Signup.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

Signup.defaultProps = {
  contentfulSlugItem: 'signup',
};
