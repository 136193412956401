import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { formatApiDate } from 'utils/api';
import { useClassName } from 'common/hooks';

const SessionSelector = ({
  rollerProduct,
  date,
  quantities,
  tickets,
  onSessionSelected,
  bundles,
}) => {
  const className = useClassName('SessionSelector');

  const isSessionSelected = (session) =>
    Boolean(
      tickets.find(
        (ticket) =>
          ticket.sessionProductId === session.productId &&
          ticket.startTime === session.startTime
      )
    );

  const hasEnoughAvailability = (session) =>
    session.capacityRemaining >= quantities;

  const renderSessions = () => {
    const formatedDate = formatApiDate(date);
    const { productAvailability } = bundles;
    const productSessions = productAvailability.get(
      `${rollerProduct.productId}-${formatedDate}`
    );

    if (!productSessions || !productSessions?.sessions?.length) return null;

    const { sessions } = productSessions;

    return (
      <div className={className('sessions-container')}>
        {sessions.map((session, index) => (
          <Button
            key={index}
            as="div"
            type="button"
            size="small"
            className="session-slot"
            onClick={() => onSessionSelected(rollerProduct.productId, session)}
            inverted={isSessionSelected(session)}
            disabled={!hasEnoughAvailability(session)}>
            <span>{session.name}</span>
          </Button>
        ))}
      </div>
    );
  };

  return !date ? null : renderSessions();
};

SessionSelector.propTypes = {
  rollerProduct: PropTypes.object.isRequired,
  date: PropTypes.object,
  quantities: PropTypes.number,
  tickets: PropTypes.array,
  onSessionSelected: PropTypes.func.isRequired,
};

SessionSelector.defaultProps = {
  date: null,
  quantities: 0,
  tickets: [],
};

export default inject('bundles')(SessionSelector);
