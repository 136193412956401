import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { inject } from 'mobx-react';
import { useClassName } from 'common/hooks';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { AddOnCardNew } from 'public/components';
import { Layout } from './components';
import {
  getQuantities,
  isTicketExpired,
  mapBundleAddon,
} from './helpers/orderUpdate';
import './order-update-addons.less';

const OrderUpdateBundleAddons = ({ bundles, orderUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [groupedAddons, setGroupedAddons] = useState([]);
  const [newAddons, setNewAddons] = useState(orderUpdate.newAddons || []);
  const currentAddonsQuantities = getQuantities(
    orderUpdate.currentTickets.filter((ticket) => ticket.ticketOption.addon)
  );
  const newAddonsQuantities = getQuantities(newAddons);
  const className = useClassName('OrderUpdateAddons');
  const history = useHistory();

  useEffect(() => {
    if (!orderUpdate?.order?.id) history.push('/profile/orders');
    getAddonsAvailability();
  }, []);

  const getAddonsAvailability = async () => {
    const { newTickets } = orderUpdate;
    const groupedAddons = [];
    for (const ticket of newTickets) {
      if (!ticket.bundle) continue;

      const reservationDate = ticket.ticketOption.date;

      if (isTicketExpired(reservationDate)) continue;

      const addonsGroup = {
        venueName: ticket.venue.name,
      };

      const addonsAvailability = await bundles.getAddonsAvailability(
        ticket.bundle.rollerProducts.find(
          (rollerProduct) =>
            rollerProduct.ticketOptionId === ticket.ticketOption.ticketOptionId
        ).addons,
        reservationDate
      );
      addonsGroup.addons = addonsAvailability.map((addonAvailability) => ({
        ...addonAvailability,
        venue: ticket.venue,
        reservationDate,
        parentBundleSlug: ticket.bundle.slug,
      }));
      groupedAddons.push(addonsGroup);
    }
    setGroupedAddons(groupedAddons);
    setLoading(false);
  };

  const handleAddonUpdate = (item, quantity) =>
    setNewAddons(mapBundleAddon(item, quantity, newAddons));

  const handleSave = () => {
    orderUpdate.setNewAddons(newAddons);
    history.push('/order-update');
  };

  const links = [
    { url: '/profile/orders', title: 'My bookings' },
    { url: '/order-update', title: 'Order detail' },
    { url: '/order-update-addons', title: 'Add-ons' },
  ];

  return (
    <Layout links={links}>
      {loading ? (
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      ) : (
        <div className={className('container')}>
          <div className={className('bundle-addons')}>
            {groupedAddons.map((groupedAddon, index) => (
              <div key={`addongroup-${index}`}>
                <div className={className('bundle-addon-venue')}>
                  For {groupedAddon.venueName}
                </div>
                {groupedAddon?.addons?.map((addon) => (
                  <AddOnCardNew
                    key={addon.ticketOptionId}
                    product={addon}
                    currentQuantity={
                      newAddonsQuantities[addon.ticketOptionId] || 0
                    }
                    minimumQuantity={
                      currentAddonsQuantities[addon.ticketOptionId || 0]
                    }
                    setQuantity={handleAddonUpdate}
                  />
                ))}
              </div>
            ))}
          </div>
          <Button
            className={className('save-button')}
            onClick={handleSave}
            inverted>
            Save changes
          </Button>
        </div>
      )}
    </Layout>
  );
};

export default inject('bundles', 'orderUpdate')(OrderUpdateBundleAddons);
