import React from 'react';
import { inject, observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Modal, Loader, Dimmer } from 'semantic-ui-react';
import { isEqual, sumBy } from 'lodash';
import { Screen } from 'public/helpers';
import { SVGIcon as Icon } from 'common/components';
import {
  getQuantities,
  getPriceDifference,
  getTicketsCount,
  validateTickets,
  validateBundleTickets,
  getInventory,
  getTotalChangeDateFee,
  isEquivalentTicket,
  getFeePaid,
} from './helpers/orderUpdate';
import {
  Layout,
  SaveButton,
  TicketSelector,
  AddOnSelector,
  BundleUpdateSelector,
  BundleAddOnSelector,
  TicketsGroup,
} from './components';
import { parseApiDate } from 'utils/api';
import { formatCurrency } from 'utils/l10n';
import './order-update.less';
const BLACK_FRIDAY_DISCOUNT_PREFIX = 'BFDREAM50';

@inject('orderUpdate', 'ticketInventory', 'bundles')
@observer
export default class OrderUpdate extends Screen {
  constructor(props) {
    super(props);
    const { currentTickets, newTickets, newAddons, order } =
      this.props.orderUpdate;

    this.state = {
      loading: true,
      currentQuantities: getQuantities(currentTickets),
      newTickets,
      newAddons,
      newQuantities: getQuantities(newTickets),
      newAddonsQuantities: getQuantities(newAddons),
      openDetailsModal: false,
      changeDateFeeTicket: null,
      missingDateChangeFeeTicket: false,
      feeAmount: order.feeAmount,
    };
  }

  async componentDidMount() {
    try {
      const { ticketInventory, orderUpdate, bundles } = this.props;
      const { bundle } = orderUpdate;

      if (
        orderUpdate?.order?.discountCode?.includes(BLACK_FRIDAY_DISCOUNT_PREFIX)
      ) {
        throw new Error('Black Friday discount found');
      }

      if (!orderUpdate?.order?.id) {
        this.props.history.push('/profile/orders');
      }

      window.scrollTo(0, 0);

      const { venue } = orderUpdate;
      const { newTickets } = this.state;
      const date = newTickets[0].ticketOption.date;

      if (!bundle) {
        return await ticketInventory.search({
          venueId: venue.id,
          date,
          includeFee: true,
        });
      }

      await Promise.all(
        newTickets.map(
          async (newTicket) =>
            await bundles.getProductAvailability({
              productId: newTicket.ticketOption.bookingItemId,
              date: newTicket.ticketOption.date,
              quantity: newTicket.ticketOption.quantity,
              externalTicket: newTicket.ticketOption.externalTicket,
              venueId: newTicket.venue.id,
            })
        )
      );
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidCatch() {
    this.setState({ error: true });
  }

  canSave = () => {
    const { ticketInventory, orderUpdate, bundles } = this.props;
    const { venue, currentTickets, bundle } = orderUpdate;
    const {
      currentQuantities,
      newQuantities,
      newTickets,
      newAddonsQuantities,
      newAddons,
    } = this.state;
    const allQuantities = { ...newQuantities, ...newAddonsQuantities };
    const totalCurrentTickets = getTicketsCount(currentQuantities);
    const totalTickets = getTicketsCount(allQuantities);

    if (totalTickets < totalCurrentTickets) return false;

    const { canSubmit } = !bundle
      ? validateTickets({
          reservationDate: newTickets[0].ticketOption.date,
          venue,
          quantities: allQuantities,
          ticketInventory,
          currentTickets,
        })
      : validateBundleTickets({
          newTickets,
          bundles,
          newAddons,
        });

    return canSubmit;
  };

  mapNewAddons = (newDate) => {
    const { ticketInventory, orderUpdate } = this.props;
    const { venue } = orderUpdate;
    const { newAddons } = this.state;
    const reservationDate = parseApiDate(newDate);
    const inventory = getInventory(reservationDate, ticketInventory, venue.id);
    const updatedAddons = newAddons.map((addon) => {
      if (
        inventory.find(
          ({ ticketOptionId }) => ticketOptionId === addon.ticketOptionId
        )
      ) {
        return {
          ...addon,
          date: newDate,
        };
      }

      const equivalentTicket = inventory.find((product) =>
        isEquivalentTicket(addon.ticketOption.ticketOptionName, product.name)
      );
      if (equivalentTicket) {
        return {
          ...addon,
          ticketOption: {
            ...addon.ticketOption,
            ticketOptionId: equivalentTicket.ticketOptionId,
            ticketOptionName: equivalentTicket.name,
            price: equivalentTicket.price,
            tax: equivalentTicket.tax,
            description: equivalentTicket.description,
            date: newDate,
            bookingItemId: equivalentTicket.bookingItemId,
          },
        };
      }
    });

    return updatedAddons;
  };

  handleNewTicketsChanged = (
    newTickets,
    changeDateFeeTicket,
    missingDateChangeFeeTicket
  ) => {
    const { orderUpdate } = this.props;

    const updatedAddons = this.mapNewAddons(newTickets[0].ticketOption.date);

    this.setState({
      newTickets,
      newQuantities: getQuantities(newTickets),
      changeDateFeeTicket,
      missingDateChangeFeeTicket,
      newAddons: updatedAddons,
      newAddonsQuantities: getQuantities(updatedAddons),
    });

    orderUpdate.setNewTickets(newTickets);
    orderUpdate.setNewAddons(updatedAddons);
    orderUpdate.setChangeDateFeeTicket(changeDateFeeTicket);
    orderUpdate.setMissingDateChangeFeeTicket(missingDateChangeFeeTicket);
  };

  handleBundleItemsChanged = (
    newTickets,
    changeDateFeeTicket,
    missingDateChangeFeeTicket
  ) => {
    const { orderUpdate } = this.props;

    this.setState({
      newTickets,
      newQuantities: getQuantities(newTickets),
      changeDateFeeTicket,
      missingDateChangeFeeTicket,
    });
    orderUpdate.setNewTickets(newTickets);
  };

  handleNewAddonsChanged = (newAddons) => {
    const { orderUpdate } = this.props;

    this.setState({
      newAddons,
      newAddonsQuantities: getQuantities(newAddons),
    });

    orderUpdate.setNewAddons(newAddons);
  };

  handleSave = () => {
    const { orderUpdate } = this.props;
    const {
      newTickets,
      changeDateFeeTicket,
      missingDateChangeFeeTicket,
      newAddons,
    } = this.state;
    orderUpdate.setNewTickets(newTickets);
    orderUpdate.setNewAddons(newAddons);
    orderUpdate.setChangeDateFeeTicket(changeDateFeeTicket);
    orderUpdate.setMissingDateChangeFeeTicket(missingDateChangeFeeTicket);
    this.props.history.push('/order-update-checkout');
  };

  hasChanged = () => {
    const {
      currentQuantities,
      newQuantities,
      newTickets,
      newAddonsQuantities,
    } = this.state;
    const { currentTickets } = this.props.orderUpdate;
    const allQuantities = { ...newQuantities, ...newAddonsQuantities };

    if (
      Object.keys(allQuantities).some(
        (key) => !isEqual(currentQuantities[key], allQuantities[key])
      )
    ) {
      return true;
    }

    return newTickets.some(
      (newTicket) =>
        !currentTickets.find(
          (currentTicket) =>
            currentTicket.ticketOption.ticketOptionId ===
              newTicket.ticketOption.ticketOptionId &&
            currentTicket.ticketOption.date === newTicket.ticketOption.date &&
            currentTicket.ticketOption.startTime ===
              newTicket.ticketOption.startTime
        )
    );
  };

  redirectToNewAddons = () => {
    const { bundle } = this.props.orderUpdate;
    if (!bundle) return this.props.history.push('order-update-addons');
    return this.props.history.push('order-update-bundle-addons');
  };

  renderChangeTickets() {
    const { venue, bundle } = this.props.orderUpdate;

    if (!bundle)
      return (
        <TicketSelector
          venue={venue}
          handleNewTicketsChanged={this.handleNewTicketsChanged}
        />
      );

    return (
      <BundleUpdateSelector
        handleBundleItemsChanged={this.handleBundleItemsChanged}
      />
    );
  }

  renderChangeAddons() {
    const { bundle } = this.props.orderUpdate;
    const { newAddons, newAddonsQuantities } = this.state;

    if (!bundle) {
      return (
        <AddOnSelector
          handleNewAddonsChanged={this.handleNewAddonsChanged}
          redirectToNewAddons={this.redirectToNewAddons}
          newAddons={newAddons}
          newAddonsQuantities={newAddonsQuantities}
        />
      );
    }

    return (
      <BundleAddOnSelector
        redirectToNewAddons={this.redirectToNewAddons}
        handleNewAddonsChanged={this.handleNewAddonsChanged}
      />
    );
  }

  renderOrderSummary() {
    const { order, currentTickets, venue } = this.props.orderUpdate;

    const orderDate = DateTime.fromISO(order.createdAt).toFormat(
      'LLL, dd yyyy'
    );
    const orderUpdatedAt = DateTime.fromISO(order.updatedAt).toFormat(
      'LLL, dd yyyy'
    );
    const feePaid = getFeePaid(currentTickets);

    return (
      <div className={this.getElementClass('order-summary')}>
        <p className="title">PAID ON {orderUpdatedAt}</p>
        <TicketsGroup
          tickets={currentTickets}
          feePaid={feePaid}
          showDiscountPrice={true}
        />
        <div className="total">
          <div className="amount">
            <p>Total</p>
            <p>
              {formatCurrency(
                sumBy(
                  currentTickets,
                  ({ ticketOption }) =>
                    ticketOption.quantity *
                      (ticketOption.priceDiscount + ticketOption.taxDiscount) +
                    (feePaid[ticketOption.ticketOptionId] || 0)
                ),
                true
              )}
            </p>
          </div>
          <div className="date">
            {!venue.hiddenDatePicker && <p>{orderDate}</p>}
            <p>Booking ID #{order.bookingReference}</p>
          </div>
        </div>
      </div>
    );
  }

  renderDetailsModal() {
    const { currentTickets } = this.props.orderUpdate;
    const { newTickets, newAddons, changeDateFeeTicket } = this.state;
    const { openDetailsModal } = this.state;

    const currentTotal = formatCurrency(
      sumBy(
        currentTickets,
        ({ ticketOption }) =>
          ticketOption.quantity *
          (ticketOption.priceDiscount +
            ticketOption.taxDiscount +
            ticketOption.feeAmount)
      )
    );

    const ticketsPriceDifference = getPriceDifference(
      currentTickets,
      newTickets
    );
    const addonsPriceDifference = getPriceDifference(
      currentTickets,
      newAddons,
      true
    );
    const totalChangeDateFee = getTotalChangeDateFee(changeDateFeeTicket);

    const totalPriceDifference = (
      (ticketsPriceDifference + addonsPriceDifference + totalChangeDateFee) /
      100
    ).toFixed(2);

    const allTickets = [...newTickets, ...newAddons];

    if (changeDateFeeTicket) allTickets.push(changeDateFeeTicket);

    const feePaid = getFeePaid(currentTickets);
    const newTotal = formatCurrency(
      sumBy(
        allTickets,
        ({ ticketOption }) =>
          ticketOption.quantity * (ticketOption.price + ticketOption.tax)
      ) + feePaid.total,
      true
    );

    return (
      <Modal basic open={openDetailsModal} size="small">
        <div className={this.getElementClass('order-details-modal')}>
          <div className="header">
            <p className="title">Order modification changes</p>
            <Icon
              className="modal-close-icon"
              name="close"
              size="mini"
              onClick={() => this.setState({ openDetailsModal: false })}
            />
          </div>
          {<TicketsGroup tickets={allTickets} feePaid={feePaid} />}
          <div className="totals">
            <div>
              <p>Subtotal</p>
              <p>{newTotal}</p>
            </div>
            <div className="previous-paid">
              <p>Previously paid</p>
              <p>{currentTotal}</p>
            </div>
            <div className="total">
              <p>Balance due</p>
              <p>${totalPriceDifference < 0 ? 0 : totalPriceDifference}</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderConfirm() {
    if (!this.hasChanged()) return null;

    const { currentTickets } = this.props.orderUpdate;
    const { newTickets, newAddons, changeDateFeeTicket } = this.state;

    const ticketsPriceDifference = getPriceDifference(
      currentTickets,
      newTickets
    );
    const addonsPriceDifference = getPriceDifference(
      currentTickets,
      newAddons,
      true
    );
    const totalChangeDateFee = getTotalChangeDateFee(changeDateFeeTicket);
    const totalPriceDifference =
      ticketsPriceDifference + addonsPriceDifference + totalChangeDateFee;

    return (
      <div className={this.getElementClass('confirm')}>
        <div className="payment">
          <div className="changes-total">
            <p className="label">Order modification charges</p>
            <p className="amount">{formatCurrency(totalPriceDifference)}</p>
          </div>
          <div className="detail">
            <p onClick={() => this.setState({ openDetailsModal: true })}>
              See detail
            </p>
          </div>
        </div>
        <SaveButton
          label="Pay to modify order"
          handleClick={this.handleSave}
          disabled={!this.canSave()}
        />
        {this.renderDetailsModal()}
      </div>
    );
  }

  renderError() {
    return (
      <div className={this.getElementClass('tickets-error')}>
        <div className={this.getElementClass('tickets-error-content')}>
          <Icon name="warning" color="yellow" transparent />
          <p>
            Please contact Guest Services for assistance with this booking by
            visiting our <Link to="/contact">Contact page</Link>
          </p>
        </div>
      </div>
    );
  }

  renderBody() {
    const links = [
      { url: '/profile/orders', title: 'My bookings' },
      { url: '/order-update', title: 'Order detail' },
    ];
    const { loading, error } = this.state;

    return loading ? (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    ) : (
      <Layout links={links}>
        {error ? (
          this.renderError()
        ) : (
          <div className={this.getElementClass('main-container')}>
            <div className="tickets-container">
              {this.renderChangeTickets()}
              {this.renderChangeAddons()}
            </div>
            <div className="summary">
              {this.renderOrderSummary()}
              {this.renderConfirm()}
            </div>
          </div>
        )}
      </Layout>
    );
  }
}
