import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { parseAttraction } from 'public/helpers/contentful/parser';

import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';

import './rides-points.less';

function parseRides(venue) {
  const attractionScreen = parseAttraction(venue.content.attractionScreen);
  const rides = attractionScreen?.ridesCards || [];

  return groupBy(
    rides
      .map((ride) => {
        return {
          name: ride.name,
          points: ride.points,
          image: ride.image,
          closedMessage: ride?.schedule?.message,
        };
      })
      .filter((a) => a.points),
    'points'
  );
}

function RidesPoints({ venue }) {
  const classNames = useClassName('RidesPoints');
  const [openPoints, setOpenPoints] = useState([]);

  const togglePoints = (points) => {
    setOpenPoints((openPoints) => {
      if (openPoints.includes(points)) {
        return openPoints.filter((a) => a !== points);
      }

      return [...openPoints, points];
    });
  };

  if (!venue) return null;

  const groups = parseRides(venue);
  if (!Object.keys(groups).length) return null;

  return (
    <div>
      {Object.entries(groups).map(([points, rides]) => (
        <div key={points} className={classNames('container')}>
          <div
            className={classNames([
              'header',
              openPoints.includes(points) && 'open',
            ])}
            onClick={() => togglePoints(points)}>
            <h2>{points} Points Rides</h2>
            <div>
              <SVGIcon name="angle-down" size="mini" />
            </div>
          </div>
          {openPoints.includes(points) && (
            <div className={classNames('rides')}>
              {rides.map((ride) => (
                <div key={ride.name} className={classNames('ride')}>
                  {Boolean(ride.image) && (
                    <img src={ride.image.assetUrl} width={80} />
                  )}
                  <div>
                    {Boolean(ride.closedMessage) && (
                      <h5>{ride.closedMessage}</h5>
                    )}
                    <h4>{ride.name}</h4>
                  </div>
                  {ride.isClosed && <SVGIcon name="warning" size="small" />}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

RidesPoints.propTypes = {
  venue: PropTypes.object,
};

export default RidesPoints;
