import React from 'react';
import { inject } from 'mobx-react';
import { Loader } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { Spacer, HeroScreen, Announcement, Section } from 'public/components';
import { trackHotjar, trackEpsilon } from 'utils/analytics';

import {
  getContentfulField,
  unwrapContentfulFields,
  getContentfulAssetUrlandAlt,
  ContentfulImage,
  DesktopOnly,
  MobileOnly,
} from 'common/components';

import HomeBeta from '../Home';

import './home.less';

@inject('pages', 'me', 'betaFeatures', 'externalObjects', 'appSession')
export default class Home extends Screen {
  state = {
    hero: null,
    loading: true,
  };

  async routeDidUpdate() {
    trackHotjar('Home Page Viewed');

    const { me, appSession, betaFeatures } = this.props;
    await trackEpsilon(me, appSession, 1, 101, 'homepage');

    const newHome = await betaFeatures.fetch('New Home');
    if (newHome) {
      this.props.externalObjects
        .search({
          source: 'contentful',
          objectType: 'home',
        })
        .then(({ data }) => {
          this.setState({ newHome: data[0].object });
        });
    } else {
      this.fetchPage();
    }
  }

  async fetchPage() {
    try {
      const {
        object: { fields },
      } = await this.props.pages.fetchItemBySlug('homescreen');
      this.setState({
        hero: getContentfulField(fields.hero),
        sections: getContentfulField(fields.sections),
        backgroundDesktop: fields.backgroundDesktop,
        backgroundMobile: fields.backgroundMobile,
        bottomBackgroundDesktop: fields.bottomBackgroundDesktop,
        bottomBackgroundMobile: fields.bottomBackgroundMobile,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  renderHero() {
    return <HeroScreen hero={this.state.hero} />;
  }

  renderAnnouncements() {
    return <Announcement type="homepage-spotlight" />;
  }

  renderSections() {
    return <Section sections={this.state.sections} />;
  }

  renderMainContainer() {
    const renderContent = (field) => {
      const style = {
        ...(field && {
          backgroundImage: `url(${
            getContentfulAssetUrlandAlt(field).assetUrl
          })`,
        }),
      };
      return (
        <div className={this.getElementClass('main')} style={style}>
          {this.state.hero && this.renderHero()}
          {this.renderAnnouncements()}
          {this.renderSections()}
        </div>
      );
    };

    return (
      <>
        <DesktopOnly>{renderContent(this.state.backgroundDesktop)}</DesktopOnly>
        <MobileOnly>{renderContent(this.state.backgroundMobile)}</MobileOnly>
      </>
    );
  }

  renderBody() {
    if (this.state.newHome) {
      return <HomeBeta entry={unwrapContentfulFields(this.state.newHome)} />;
    }

    if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    }

    return (
      <div {...this.getAttrs()}>
        {this.renderMainContainer()}
        <DesktopOnly>
          <ContentfulImage
            field={this.state.bottomBackgroundDesktop}
            width={1024}
            className={this.getElementClass('bottom-bg')}
          />
        </DesktopOnly>
        <MobileOnly>
          <ContentfulImage
            field={this.state.bottomBackgroundMobile}
            width={375}
            className={this.getElementClass('bottom-bg')}
          />
        </MobileOnly>
      </div>
    );
  }
}
