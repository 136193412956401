import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { useClassName } from 'common/hooks';
import './cta.less';

const HeroCTA = ({
  text,
  link,
  textColor,
  backgroundColor,
  isSecondary,
  onClick,
  ...rest
}) => {
  const className = useClassName('CTA');

  return (
    <div
      className={`${className([
        'container',
        isSecondary ? 'container-secondary' : '',
      ])} ${rest.className}`}>
      <Button
        inverted
        href={link}
        className={className('button')}
        onClick={onClick}
        style={{
          borderColor: backgroundColor,
          backgroundColor: backgroundColor,
        }}>
        <span className={className('button-text')} style={{ color: textColor }}>
          {text}
        </span>
      </Button>
    </div>
  );
};

HeroCTA.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isSecondary: PropTypes.bool,
  onClick: PropTypes.func,
};

HeroCTA.defaultProps = {
  isSecondary: false,
};

export default HeroCTA;
