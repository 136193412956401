import React from 'react';
import { Container } from 'semantic-ui-react';
import {
  Layout,
  ScrollWaypoint,
  ResponsiveImage,
  ContentfulRichText,
  getContentfulField,
  DesktopOnly,
  MobileOnly,
  ContentfulImage,
} from 'common/components';
import { Screen, withVenue } from 'public/helpers';
import {
  Spacer,
  AttractionsAngryBirdsNav,
  AttractionScreen,
} from 'public/components';

import './home.less';

@withVenue('angry-birds-mini-golf')
export default class AngryBirdsHome extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      focusedFloor: props.venue.floorLevel || 1,
    };
  }

  updateInfoBlock(index, name, value) {
    const key = `infoBlock${index}`;
    const newState = Object.assign({}, this.state[key], {
      [name]: value,
    });
    this.setState({
      [key]: newState,
    });
  }

  renderBody() {
    const { venue } = this.props;
    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }
    return (
      <div {...this.getAttrs()}>
        <DesktopOnly>
          <div className={this.getElementClass('hero')}>
            <ContentfulImage field={venue.content.hero} fluid />
          </div>
        </DesktopOnly>
        <MobileOnly>
          <div className={this.getElementClass('hero')}>
            <ContentfulImage field={venue.content.heroMobile} fluid />
          </div>
        </MobileOnly>
        <AttractionsAngryBirdsNav venue={venue} />
        <div className={this.getElementClass('main-block')}>
          <DesktopOnly className={this.getElementClass('bg-image')}>
            <ResponsiveImage
              contentfulName="Angry Birds - BG - Desktop"
              width={1290}
            />
          </DesktopOnly>
          <MobileOnly className={this.getElementClass('bg-image')}>
            <ResponsiveImage
              contentfulName="Angry Birds - BG - Mobile"
              width={300}
            />
          </MobileOnly>
          <Spacer size="m" />
          <div className={this.getElementClass('main-image')}>
            <ResponsiveImage
              contentfulName="Angry Birds - Main Block"
              fluid
              ratio={1}
            />
          </div>
          <div className={this.getElementClass('info-blocks')}>
            {this.renderInfoBlocks()}
          </div>
          <div className={this.getElementClass('main-bottom')}>
            <ResponsiveImage
              contentfulName="Angry Birds - Bottom Block"
              fluid
              ratio={1}
            />
          </div>
        </div>
      </div>
    );
  }

  renderInfoBlocks() {
    const { venue } = this.props;
    return (
      <div>
        {getContentfulField(venue.content.infoBlocks).map((infoBlock, i) => {
          return this.renderInfoBlock(infoBlock, i);
        })}
      </div>
    );
  }

  renderInfoBlock(infoBlock, index) {
    const { fields } = infoBlock;
    const reverse = index % 2 === 1;
    return (
      <div key={index} className={this.getElementClass('info-block')}>
        <Container>
          <ScrollWaypoint
            onEnter={() => this.updateInfoBlock(index, 'entered', true)}
            onLeave={() => this.updateInfoBlock(index, 'entere', false)}>
            <Layout reversed={reverse} horizontal center stackable extra padded>
              {this.renderInfoBlockImage(index, fields)}
              {this.renderInfoBlockText(index, fields)}
            </Layout>
          </ScrollWaypoint>
        </Container>
      </div>
    );
  }

  renderInfoBlockText(index, fields) {
    return (
      <Layout.Group className={this.getElementClass('slide-text')}>
        <ContentfulRichText
          className={this.getElementClass('info-block-title')}
          field={fields.title}
        />
        <ContentfulRichText
          className={this.getElementClass('info-block-body')}
          field={fields.body}
        />
      </Layout.Group>
    );
  }

  renderInfoBlockImage(index, fields) {
    return (
      <Layout.Group className={this.getElementClass('info-block-image')}>
        <ContentfulImage field={fields.image} width={350} />
      </Layout.Group>
    );
  }
}
