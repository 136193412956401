import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { Screen } from 'public/helpers';
import { NotFound } from 'public/components';
import { ContentfulRichText, SVGIcon } from 'common/components';

import { NewCheckoutContainer } from '../../Components/Container';
import { NewCheckoutTicketsHeader } from '../../Tickets/Header';
import { NewCheckoutSummary } from '../../Components/Summary';
import { NewCheckoutAddonsDetails } from '../../Addons/Details';
import { BirthdayMerchandiseSelector } from './BirthdayMerchandiseSelector';
import { BirthdayBackButton } from '../BackButton';
import {
  getAdmissionSubtotal,
  getAdmissionTax,
  getAdmissionTotal,
} from '../Admission/getBirthdayAdmissionTotals';
import {
  getEntertainmentSubtotal,
  getEntertainmentTax,
  getEntertainmentTotal,
} from '../Entertainment/getBirthdayEntertainmentTotals';
import {
  getFoodAndBeverageSubtotal,
  getFoodAndBeverageTax,
  getFoodAndBeverageTotal,
} from '../BirthdayFoodAndBeverage/getBirthdayFoodAndBeverageTotals';
import {
  getMerchandiseSubtotal,
  getMerchandiseTax,
  getMerchandiseTotal,
} from './getBirthdayMerchandiseTotals';
import { trackAddonsAdded } from '../utils';
import { NewCheckoutProgressBar } from '../../Components/ProgressBar';
import { STEPS } from '../../const';

import './birthday-merchandise.less';

const FEATURED_CATEGORY = 'Featured';
const OTHERS_CATEGORY = 'Others';

@inject('birthdays', 'venues', 'me', 'betaFeatures')
@observer
export default class BirthdayMerchandise extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      venue: null,
      isEnabled: null,
    };
  }

  getFeaturedCategory = (products) => {
    const featuredProducts = products.filter((prod) => prod.featured);
    if (!featuredProducts.length) return [];

    return [{ name: FEATURED_CATEGORY, products: featuredProducts }];
  };

  getOthersCategory = (products) => {
    const uncategorizedProducts = products.filter(
      (prod) => !prod?.categories?.length && !prod.featured
    );
    if (!uncategorizedProducts.length) return [];

    return [{ name: OTHERS_CATEGORY, products: uncategorizedProducts }];
  };

  setCategories = (products) => {
    return products
      .reduce((categories, product) => {
        if (!product?.categories?.length) return categories;

        product.categories.forEach((productCategory) => {
          const category = categories.find((a) => a.name === productCategory);
          if (category) {
            category.products.push(product);
          } else {
            categories.push({ name: productCategory, products: [product] });
          }
        });

        return categories;
      }, this.getFeaturedCategory(products))
      .concat(this.getOthersCategory(products));
  };

  async routeDidUpdate() {
    const { slug } = (this.props.match && this.props.match.params) || {};
    const venue = await this.props.venues.fetchItemBySlug(slug);

    try {
      const settings = await this.props.birthdays.getParties(slug);

      if (!settings.merchandise) {
        this.props.birthdays.setMerchandise({ quantities: {}, products: [] });
        this.setState({
          redirect: this.props.match.url.replace('merchandise', 'review'),
        });
      }

      const admission = await this.props.birthdays.validateAdmission(
        slug,
        settings
      );
      const entertainment = this.props.birthdays.getEntertainment();
      const foodAndBeverage = this.props.birthdays.getFoodAndBeverage();

      if (admission.isValid) {
        const products = await this.props.birthdays.getMerchandiseProducts(
          admission,
          settings
        );
        const restored = await this.props.birthdays.validateMerchandise(
          settings,
          products
        );
        this.setState({
          venue,
          entertainment,
          foodAndBeverage,
          merchandise: settings.merchandise,
          isEnabled: true,
          products,
          quantities: {},
          admission,
          categories: this.setCategories(products),
          ...restored,
        });
      } else {
        this.setState({
          redirect: this.props.match.url.replace('merchandise', 'tickets'),
        });
      }
    } catch (e) {
      this.setState({ isEnabled: false });
    }
  }

  onSubmit = () => {
    this.setState({
      loading: true,
    });

    const { quantities, products } = this.state;
    this.props.birthdays.setMerchandise({ quantities, products });

    trackAddonsAdded({
      quantities,
      products,
      venue: this.state.venue,
      date: this.state.admission.date,
    });

    this.setState({
      loading: false,
      redirect: this.props.match.url.replace('merchandise', 'review'),
    });
  };

  handleAddOnDetail = (product) => {
    this.setState({
      showAddOnDetailModal: true,
      selectedProduct: product,
    });
  };

  handleCloseAddOnDetail = () => {
    this.setState({
      showAddOnDetailModal: false,
      selectedProduct: null,
    });
  };

  renderAddOnDetailModal = () => {
    const { showAddOnDetailModal, selectedProduct } = this.state;
    if (!showAddOnDetailModal) {
      return null;
    }

    return (
      <NewCheckoutAddonsDetails
        product={selectedProduct}
        open={showAddOnDetailModal}
        handleClose={this.handleCloseAddOnDetail}
        contentful
      />
    );
  };

  onChangeQuantity = (quantities) => {
    this.setState(() => ({
      quantities: Object.fromEntries(
        Object.entries(quantities).filter(([, value]) => value > 0)
      ),
    }));
  };

  backToFoodAndBeverage = () => {
    this.setState({
      redirect: this.props.match.url.replace(
        'merchandise',
        'food-and-beverage'
      ),
    });
  };

  backToAdmission = () => {
    this.setState({
      redirect: this.props.match.url.replace('merchandise', 'tickets'),
    });
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const {
      isEnabled,
      products,
      admission,
      quantities,
      entertainment,
      foodAndBeverage,
    } = this.state;
    if (isEnabled === null) return null;
    if (!isEnabled) return <NotFound />;

    const error = this.state.error && { errorMessage: this.state.error };

    const { selectedSession, numberOfGuests } = admission;
    const totals = {
      subtotal:
        getAdmissionSubtotal({ selectedSession, numberOfGuests }) +
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal({ products, quantities }),
      tax:
        getAdmissionTax({ selectedSession, numberOfGuests }) +
        getEntertainmentTax(entertainment) +
        getFoodAndBeverageTax(foodAndBeverage) +
        getMerchandiseTax({ products, quantities }),
      ticketSubtotal: getAdmissionSubtotal({ selectedSession, numberOfGuests }),
      addonSubtotal:
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal({ products, quantities }),
      total:
        getAdmissionTotal({ selectedSession, numberOfGuests }) +
        getEntertainmentTotal(entertainment) +
        getFoodAndBeverageTotal(foodAndBeverage) +
        getMerchandiseTotal({ products, quantities }),
    };

    return (
      <NewCheckoutContainer
        attrs={this.getAttrs()}
        content={
          <>
            <BirthdayBackButton
              title="Back to Food & Beverage"
              onClick={this.backToFoodAndBeverage}
            />
            <NewCheckoutProgressBar
              title="Merchandise"
              total={3}
              current={2}
              onClick={this.backToFoodAndBeverage}
            />
            <NewCheckoutTicketsHeader
              showLogo={false}
              title={this.state.merchandise?.title}
            />
            {Boolean(this.state.merchandise?.message) && (
              <div className={this.getElementClass('message')}>
                <SVGIcon name="info" />
                <ContentfulRichText field={this.state.merchandise?.message} />
              </div>
            )}
            <BirthdayMerchandiseSelector
              categories={this.state.categories}
              handleAddOnDetail={this.handleAddOnDetail}
              quantities={this.state.quantities}
              onChangeQuantity={this.onChangeQuantity}
              date={this.state.admission.date}
            />
            {this.renderAddOnDetailModal()}
          </>
        }
        summary={
          <NewCheckoutSummary
            stepNames={{
              tickets: 'Admission',
              addons: 'Add-ons',
              checkout: 'Details & Confirmation',
            }}
            error={error}
            canSubmit
            onSubmit={this.onSubmit}
            modify={{
              [STEPS.Tickets]: {
                enabled: true,
                onClick: this.backToAdmission,
              },
            }}
            step={STEPS.Addons}
            loading={this.state.loading}
            totals={totals}
          />
        }
      />
    );
  }
}
