import React, { useEffect, useState } from 'react';
import { Button, Container, Loader, Grid, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  differenceInDays,
  subDays,
  format,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { inject } from 'mobx-react';
import { useClassName } from 'common/hooks';
import { ErrorMessage } from 'common/components/ErrorMessage';
import { DateRangeSelector } from 'common/components/DateRangeSelector';
import DataCard from './DataCard';
import Orders from './Orders';
import SalesDrillDown from './SalesDrillDown';

import './sales-summary.less';

const SalesSummary = ({ shopify, vendor }) => {
  const [sales, setSales] = useState();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  //Opening date
  const minSelectionDate = new Date('2023-02-13');

  const [datesRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });

  const [comparisonDatesRange, setComparisonDatesRange] = useState({
    startDate: subDays(
      datesRange.startDate,
      differenceInDays(datesRange.endDate, datesRange.startDate)
    ),
    endDate: subDays(
      datesRange.endDate,
      differenceInDays(datesRange.endDate, datesRange.startDate)
    ),
  });

  const amountCards = ['netSales', 'taxes', 'totalSales'];

  const className = useClassName('SalesSummary');

  useEffect(() => {
    fetchSalesSummary({});
  }, []);

  const fetchSalesSummary = async () => {
    setLoading(true);

    const salesResponses = await Promise.all(
      [datesRange, comparisonDatesRange].map(async (range, index) => {
        return {
          type: index === 0 ? 'current' : 'comparison',
          salesByDateResponse:
            range.startDate && range.endDate
              ? await shopify.fetchSales({
                  startDate: format(range.startDate, 'yyyy-MM-dd'),
                  endDate: format(range.endDate, 'yyyy-MM-dd'),
                  vendor,
                })
              : null,
        };
      })
    );

    const errors = salesResponses
      .map((salesResponse) => salesResponse.salesByDateResponse?.errors)
      .flat()
      .filter((error) => error);

    if (errors?.length) {
      setErrors(errors);
      setLoading(false);
      return;
    }

    setSales({
      current: salesResponses.find(
        (salesResponse) => salesResponse.type === 'current'
      ).salesByDateResponse?.data?.sales,
      comparison:
        salesResponses.find(
          (salesResponse) => salesResponse.type === 'comparison'
        ).salesByDateResponse?.data?.sales || null,
    });

    setLoading(false);
  };

  const parseHeader = (value) =>
    value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });

  const getTotalAmount = (sales, field) =>
    sales?.reduce((acc, sale) => (acc += sale[field] || 0), 0);

  const getTotalOrders = (sales) =>
    [...new Set(sales.map((sale) => sale.orderId))].length;

  const getAverageOrderValue = (sales) =>
    getTotalAmount(sales, 'totalSales') / getTotalOrders(sales) || 0;

  const handleRangeChange = (range) => setDateRange(range);

  const handleComparisonRangeChange = (range) => setComparisonDatesRange(range);

  return (
    <Container fluid>
      <Grid columns={3} style={{ marginBottom: '20px' }}>
        <Grid.Row columns={1}>
          <Grid.Column>
            <div className={className('title')}>Sales summary</div>
            <Message warning>
              <Message.Content>
                <strong>Disclaimer:</strong> This data is unaudited and subject
                to final review. Final sales report may differ.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className={className('dates')}>
            <DateRangeSelector
              handleChange={handleRangeChange}
              initialRange={{ ...datesRange, label: 'This month' }}
              minSelectionDate={minSelectionDate}
            />
            <DateRangeSelector
              handleChange={handleComparisonRangeChange}
              compareToRange={datesRange}
              initialRange={{
                ...comparisonDatesRange,
                label: 'Previous period',
              }}
              minSelectionDate={minSelectionDate}
            />
            <Button primary disabled={loading} onClick={fetchSalesSummary}>
              Search
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {loading && <Loader active inline="centered" />}
      {errors?.list && (
        <ErrorMessage title={errors?.title} errors={errors?.list} />
      )}
      {!loading && !errors && (
        <div className={className('data-container')}>
          <div className={className('cards-container')}>
            {amountCards.map((card) => (
              <DataCard
                key={`${card}-card`}
                title={parseHeader(card)}
                value={getTotalAmount(sales.current, card)}
                comparisonValue={
                  sales.comparison === null
                    ? null
                    : getTotalAmount(sales.comparison, card)
                }
                isCurrency={true}
              />
            ))}

            <DataCard
              title="# Orders"
              value={getTotalOrders(sales.current)}
              comparisonValue={
                sales.comparison === null
                  ? null
                  : getTotalOrders(sales.comparison)
              }
            />
            <DataCard
              title="Average order value"
              value={getAverageOrderValue(sales.current) || 0}
              comparisonValue={
                sales.comparison === null
                  ? null
                  : getAverageOrderValue(sales.comparison)
              }
              isCurrency={true}
            />
          </div>
          <Orders sales={sales.current} />
          <SalesDrillDown sales={sales.current} vendor={vendor} />
        </div>
      )}
    </Container>
  );
};

SalesSummary.propTypes = {
  vendor: PropTypes.string,
};

SalesSummary.defaultProps = {
  vendor: '',
};

export default inject('shopify')(SalesSummary);
