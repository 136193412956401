import React from 'react';
import { Component } from 'common/helpers';
import { Video, DesktopOnly, MobileOnly } from 'common/components';
import './hero.less';

export default class DreamSafeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
    };
  }

  handlePlay = () => {
    const video = document.getElementById('dreamsafe-video');
    const newState = !this.state.play;

    newState ? video.play() : video.pause();

    this.setState({ play: newState });
  };

  render() {
    const {
      heroTitle,
      heroDescription,
      heroVideo,
      heroPoster,
      mobileHeroPoster,
      heroLogo,
    } = this.props;
    return (
      <div {...this.getAttrs()}>
        <div className={this.getElementClass('content')}>
          <div className={this.getElementClass('logo')}>
            <div className={this.getElementClass('hashtag')}>#</div>
            <div className={this.getElementClass('dreamsafe-logo')}>
              {heroLogo}
            </div>
          </div>
          <div className={this.getElementClass('text-container')}>
            <div className={this.getElementClass('title')}>{heroTitle}</div>
            <div className={this.getElementClass('description')}>
              {heroDescription}
            </div>
          </div>
        </div>
        {heroPoster && heroVideo.assetUrl && (
          <div
            className={this.getElementClass('video-container')}
            onClick={this.handlePlay}>
            <DesktopOnly>
              <Video
                id="dreamsafe-video"
                className={this.getElementClass('video')}
                src={heroVideo.assetUrl}
                poster={this.state.play ? '' : `${heroPoster}?w=1290&q=70`}
                preload="auto"
                controls={false}
              />
            </DesktopOnly>
            <MobileOnly>
              <Video
                id="dreamsafe-video"
                className={`${this.getElementClass('video')} ${
                  this.state.play && 'play'
                }`}
                src={heroVideo.assetUrl}
                poster={this.state.play ? '' : `${mobileHeroPoster}?q=70`}
                preload="auto"
                controls={false}
              />
            </MobileOnly>
          </div>
        )}
      </div>
    );
  }
}
