import React from 'react';
import { inject } from 'mobx-react';
import { Loader, Container } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import {
  CmsScreen,
  LandingScreen,
  Spacer,
  Hero,
  LandingPage,
} from 'public/components';
import { NotFound } from '../NotFound';
import { ContentfulRichText, getContentfulField } from 'common/components';
import { trackEpsilon } from 'utils/analytics';
import './content-page.less';

@inject('pages', 'me', 'appSession')
export default class ContentPage extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      page: props.preview,
      error: null,
    };
  }

  async routeDidUpdate() {
    if (this.props.preview) {
      // Page may be passed in by the preview screen.
      return;
    }

    window.scrollTo(0, 0);

    try {
      this.setState({
        page: null,
      });
      let { slug } = this.props.match.params;
      slug = slug.toLowerCase();
      this.setState({
        page: await this.props.pages.fetchItemBySlug(slug),
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
        });
      } else {
        throw error;
      }
    }
    const { me, appSession } = this.props;
    await trackEpsilon(
      me,
      appSession,
      2,
      101,
      window.location?.pathname?.replace(/\//g, '')
    );
  }

  // TODO: make better
  getHeroImages() {
    const { page } = this.state;
    const hero = page.object.fields.hero;
    if (!hero) return;
    return hero['en-US']
      .map((f) => {
        const { url, details } = f.fields.file['en-US'];
        let ratio;
        if (details.image) {
          ratio = details.image.width / details.image.height;
        } else {
          ratio = 1.3333;
        }
        return {
          url,
          ratio,
        };
      })
      .filter((url) => url);
  }

  renderBody() {
    const { page, error } = this.state;

    if (error) {
      return <NotFound {...this.props} />;
    } else if (!page) {
      return <Loader inline="centered" active />;
    }

    const type = page.object.sys.contentType.sys.id;

    if (type === 'screen') {
      return <CmsScreen page={page} />;
    }

    if (type === 'landing') {
      return <LandingScreen page={page} />;
    }

    if (type === 'landingPage') {
      return <LandingPage object={page?.object} />;
    }

    return (
      <div {...this.getAttrs()}>
        {this.renderHero()}
        <Container className="tight">
          <Spacer size="s" />
          <h1>{page.name}</h1>
          <div className={this.getElementClass('body')}>
            <ContentfulRichText field={page.object.fields.body} />
            {page.object?.fields?.iframe && (
              <iframe
                className={this.getElementClass('iframe')}
                src={getContentfulField(page.object.fields.iframe)}
                scrolling="no"
                frameBorder="1"
                allowFullScreen></iframe>
            )}
            {page.object?.fields?.footer && (
              <ContentfulRichText
                className={this.getElementClass('body')}
                field={page.object.fields.footer}
              />
            )}
          </div>
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderHero() {
    const heroImages = this.getHeroImages();
    if (heroImages && heroImages.length) {
      const [main, fallback] = heroImages;
      return <Hero media={main} fallback={fallback} />;
    }
  }
}
