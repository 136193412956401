import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';

import { Spacer } from '../../Spacer';

import './qr-code-modal.less';

const QRCodeModal = ({ qrCodes, onClose, isOpen }) => {
  const className = useClassName('QRCodeModal');
  const [qrCounter, setQrCounter] = useState(0);

  const decreaseCounter = () => {
    if (qrCounter > 0) setQrCounter(qrCounter - 1);
  };

  const increaseCounter = () => {
    if (qrCounter < qrCodes.length - 1) setQrCounter(qrCounter + 1);
  };

  if (!isOpen) return null;

  return (
    <Modal basic open size="small">
      <div className={className('container')}>
        <SVGIcon
          className="close-icon"
          name="close"
          size="mini"
          onClick={onClose}
        />
        <p className="booking-id">{`Booking ID: # ${qrCodes[qrCounter]?.id}`}</p>
        <div className="image">
          {qrCodes.length > 1 && (
            <SVGIcon name="angle-left" size="small" onClick={decreaseCounter} />
          )}
          <img src={qrCodes[qrCounter]?.srcImage} alt="qr-code" />
          {qrCodes.length > 1 && (
            <SVGIcon
              name="angle-right"
              size="small"
              onClick={increaseCounter}
            />
          )}
        </div>
        <p className="qr-counter">{`${qrCounter + 1} / ${qrCodes.length}`}</p>
        <Spacer size="s" />
        <p>{qrCodes[qrCounter].venueName}</p>
      </div>
    </Modal>
  );
};

export default QRCodeModal;
