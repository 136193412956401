import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, Dimmer } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import { DateTime } from 'luxon';
import { useClassName } from 'common/hooks';
import { isDateChangeFee } from 'public/helpers';
import {
  purchasedWithin24Hours,
  canUpdateQuantity,
  manageDateChangeTicket,
  validateBundleTickets,
} from '../helpers/orderUpdate';
import { CustomMessage } from 'public/components';
import ChangeContainer from './ChangeContainer';
import DatePicker from './DatePicker';
import ItemContainer from './ItemContainer';
import './bundle-update-selector.less';

const BundleUpdateSelector = ({
  orderUpdate,
  ticketInventory,
  bundles,
  handleBundleItemsChanged,
}) => {
  const [loading, setLoading] = useState(false);
  const [newTickets, setNewTickets] = useState(orderUpdate.newTickets);
  const [error, setError] = useState({});
  const { order } = orderUpdate;
  const bundle = newTickets[0].bundle;
  const initialQuantity = orderUpdate.currentTickets[0].ticketOption.quantity;
  const isRecentPurchase = purchasedWithin24Hours(order);
  const className = useClassName('BundleUpdateSelector');

  useEffect(() => {
    if (newTickets) handleBundleItemsChanged(newTickets, null, null);
  }, [newTickets]);

  const handleDateSelected = async (reservationDate, ticketId) => {
    setLoading(true);
    try {
      const newTicket = newTickets.find((ticket) => ticketId === ticket.id);
      const currentTicket = orderUpdate.currentTickets.find(
        (ticket) => ticketId === ticket.id
      );
      const formatedReservationDate =
        DateTime.fromJSDate(reservationDate).toISODate();

      await bundles.getProductAvailability({
        productId: newTicket.ticketOption.bookingItemId,
        date: formatedReservationDate,
        quantity: newTicket.ticketOption.quantity,
        externalTicket: newTicket.ticketOption.externalTicket,
        venueId: newTicket.venue.id,
      });

      newTicket.ticketOption = {
        ...newTicket.ticketOption,
        date: formatedReservationDate,
      };

      const updatedNewTickets = newTickets.map((ticket) => {
        if (ticketId !== ticket.id) return ticket;
        return newTicket;
      });

      setError(
        validateBundleTickets({ bundles, newTickets: updatedNewTickets })
      );

      if (isRecentPurchase) return setNewTickets(updatedNewTickets);

      return setNewTickets(
        await manageDateChangeTicket(
          newTicket,
          currentTicket,
          updatedNewTickets,
          ticketInventory
        )
      );
    } catch (e) {
      setError({
        errorTitle: 'An unexpected error has occur',
        errorMessage: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChanged = (newQuantity) =>
    setNewTickets(
      newTickets.map((ticket) => ({
        ...ticket,
        ticketOption: {
          ...ticket.ticketOption,
          quantity: newQuantity,
        },
      }))
    );

  const renderDatesSelector = () => (
    <ChangeContainer title="Date of your visit">
      {!isRecentPurchase && (
        <CustomMessage type="warning">
          <span>
            Changing a date has a <strong>$5.00 fee / person</strong>. Ticket
            prices will vary depending on date and session selected.
          </span>
        </CustomMessage>
      )}
      <div className={className('dates-container')}>
        {newTickets
          .filter(
            (ticket) => !isDateChangeFee(ticket.ticketOption.ticketOptionName)
          )
          .map((ticket) => (
            <DatePicker
              key={ticket.venue.name}
              label={`Date for ${ticket.venue.name}`}
              handleDateSelected={(reservationDate) =>
                handleDateSelected(reservationDate, ticket.id)
              }
              reservationDate={ticket.ticketOption.date}
            />
          ))}
      </div>
      {error?.errorMessage && (
        <CustomMessage type="warning" title={error.errorTitle}>
          <p>
            <strong>{error.errorTitle}</strong>
          </p>
          <p>{error.errorMessage}</p>
        </CustomMessage>
      )}
    </ChangeContainer>
  );

  const renderTicketsSelector = () => (
    <ChangeContainer title="Tickets">
      <ItemContainer
        bundle={bundle}
        handleChange={handleQuantityChanged}
        itemDescription={bundle.title}
        itemPrice={bundle.amount.totalAmountWithoutTaxes * 100}
        itemQuantity={newTickets[0].ticketOption.quantity}
        min={initialQuantity}
        max={!canUpdateQuantity(newTickets) ? initialQuantity : 999}
      />
    </ChangeContainer>
  );

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <>
      {renderTicketsSelector()}
      {renderDatesSelector()}
    </>
  );
};

BundleUpdateSelector.propTypes = {
  handleBundleItemsChanged: PropTypes.func.isRequired,
};

export default inject(
  'orderUpdate',
  'bundles',
  'ticketInventory'
)(BundleUpdateSelector);
