import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Component } from 'common/helpers';

import './notifications.less';

@withRouter
@inject('notifications')
@observer
export default class Notifications extends Component {
  render() {
    const unseen = this.props.notifications.getUnseen();
    if (unseen.length === 0) {
      return null;
    }
    return (
      <div {...this.getAttrs()}>
        {unseen.map(this.renderNotification, this)}
      </div>
    );
  }

  componentDidUpdate(newProps) {
    if (this.props.location !== newProps.location) {
      this.props.notifications.incrementSeenCount();
    }
  }

  renderNotification(n, i) {
    const props = {};
    if (n.type === 'success') {
      props.success = true;
    } else if (n.type === 'error') {
      props.error = true;
    } else if (n.type === 'warning') {
      props.warning = true;
    }
    return (
      <div key={i} className={this.getElementClass('item', n.type)}>
        <Container>{n.message}</Container>
      </div>
    );
  }
}
