import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { useClassName } from 'common/hooks';
import { ContentfulRichText, SVGIcon } from 'common/components';

import './ticket-options-modal.less';

function TicketOptionsModalContent({ onClose, options, onClick }) {
  const classNames = useClassName('TicketOptionsModal');

  return (
    <Modal.Content className={classNames('content')}>
      <div className={classNames('header')}>
        <h1>Choose Your Experience</h1>
        <div className={classNames('close')} onClick={() => onClose()}>
          <SVGIcon name="close" />
        </div>
      </div>
      <div className={classNames('options')}>
        {options.map((option) => (
          <a
            onClick={() => onClick(option.link, option.name)}
            href={option.link}
            key={option.name}
            className={classNames('option')}>
            {option.icon ? (
              <img alt={option.icon.assetAlt} src={option.icon.assetUrl} />
            ) : (
              <SVGIcon name="ticket" size="medium" />
            )}
            <div className={classNames('info')}>
              <h2>{option.name}</h2>
              <ContentfulRichText field={option.description} />
            </div>
            {option.type === 'primary' && <span>Popular</span>}
          </a>
        ))}
      </div>
    </Modal.Content>
  );
}

const TicketOptionsModal = ({ trigger, options, onClick }) => {
  const classNames = useClassName('TicketOptionsModal');
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className={classNames('container')}
      open={open}>
      <TicketOptionsModalContent
        options={options}
        onClick={onClick}
        onClose={() => setOpen(false)}
      />
    </Modal>
  );
};

const TicketOptionsModalControlled = ({
  isOpen,
  setOpen,
  options,
  onClick,
}) => {
  const classNames = useClassName('TicketOptionsModal');

  return (
    <Modal
      onClose={() => setOpen(false)}
      className={classNames('container')}
      open={isOpen}>
      <TicketOptionsModalContent
        options={options}
        onClick={onClick}
        onClose={() => setOpen(false)}
      />
    </Modal>
  );
};

TicketOptionsModal.propTypes = {
  trigger: PropTypes.any,
  options: PropTypes.array,
  onClick: PropTypes.func,
};

TicketOptionsModalControlled.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  options: PropTypes.array,
  onClick: PropTypes.func,
};

export { TicketOptionsModalControlled };
export default TicketOptionsModal;
