export const getAdmissionSubtotal = ({ selectedSession, numberOfGuests }) => {
  if (!selectedSession) return 0;

  const additionalGuests = numberOfGuests - selectedSession.guestsIncluded;
  return (
    selectedSession.cost +
    additionalGuests * (selectedSession?.additionalGuests?.cost || 0)
  );
};

export const getAdmissionTax = ({ selectedSession, numberOfGuests }) => {
  if (!selectedSession) return 0;

  return (
    getAdmissionSubtotal({ selectedSession, numberOfGuests }) *
    (selectedSession.tax / 100)
  );
};

export const getAdmissionTotal = ({ selectedSession, numberOfGuests }) => {
  return (
    getAdmissionSubtotal({ selectedSession, numberOfGuests }) +
    getAdmissionTax({ selectedSession, numberOfGuests })
  );
};
