import React from 'react';
import { Screen } from 'public/helpers';
import { NotFound as NotFoundComponent } from 'public/components';

export default class NotFound extends Screen {
  META_TITLE = 'NOT_FOUND_TITLE';

  renderBody() {
    return <NotFoundComponent />;
  }
}
