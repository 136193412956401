import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import './agreements.less';

const Agreements = ({ onChange }) => {
  const [agreements, setAgreements] = useState({
    waiver: false,
  });
  const classNames = useClassName('NewCheckoutPaymentAgreements');

  const isCompleted = (value) => Boolean(value.waiver);

  const changeCheckbox = (newValue) => {
    setAgreements(newValue);
    onChange({ complete: isCompleted(newValue) });
  };

  return (
    <div className={classNames('container')}>
      <Checkbox
        checked={agreements.waiver}
        onChange={() =>
          changeCheckbox({ ...agreements, waiver: !agreements.waiver })
        }
        label={
          <span>
            I have read and agree to be bound by the “
            <a href="/waiver" target="_blank">
              American Dream Waiver, Release and Indemnity Agreement
            </a>
            ”.
          </span>
        }
      />
    </div>
  );
};

Agreements.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Agreements;
