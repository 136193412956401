import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

import {
  LazyVisible,
  useClassName,
  Only,
  preloadMedia,
  usePreload,
  preloadCTAs,
  useWindowSize,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../../Components/Button';
import { usePagination } from '../../../Components/usePagination';
import HomepageImage from '../../../Components/Image';
import trackHomepageSlide from '../../../Components/trackHomepageSlide';
import trackHomepageSection from '../../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import './small.less';

export default function DiningSection({ section }) {
  const classNames = useClassName('DiningSection');
  const ref = useRef();
  const { isDesktop } = useWindowSize();
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  usePreload((isDesktop) => {
    preloadMedia(section.fields.stores, isDesktop);
    preloadCTAs(section.fields.cta);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('header')}>
        <div>
          <h3>{section.fields.header}</h3>
          <ContentfulRichText value={section.fields.subheader} />
        </div>
        <Only desktop>
          <HomepageButton
            cta={section.fields.cta}
            className={classNames('cta')}
          />
        </Only>
      </div>
      <Swiper
        modules={[Pagination]}
        height={542}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },
          1128: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        loop={false}
        className={classNames('wrapper')}
        {...pagination}
        onSlideChange={({ activeIndex }) =>
          trackHomepageSlide(() => ({
            activeIndex,
            name: section.fields.stores[activeIndex].fields.name,
            section: section.fields.sectionId,
          }))
        }>
        {section.fields.stores
          .filter((_, index) => (isDesktop ? index < 4 : true))
          .map(({ fields, sys }, index) => {
            return (
              <SwiperSlide
                key={sys.id}
                className={classNames('slide-container')}>
                <div
                  className={classNames('slide')}
                  style={{
                    borderRadius:
                      index % 2 === 0 ? '200px 200px 40px 40px' : '40px',
                    animationDelay: `${index * 150}ms`,
                  }}>
                  <HomepageButton cta={fields.callToAction}>
                    <HomepageImage
                      field={fields.desktop}
                      mobile={fields.mobile}
                    />
                    <span>{fields.name}</span>
                  </HomepageButton>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <Only mobile tablet>
        <HomepageButton
          cta={section.fields.cta}
          className={classNames('cta')}
        />
      </Only>
    </LazyVisible>
  );
}
