import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { isNumber } from 'lodash';

import { Screen } from 'public/helpers';
import { NotFound } from 'public/components';

import { BirthdayCheckoutContainer } from '../BirthdayCheckoutContainer';
import { NewCheckoutTicketsHeader } from '../../Tickets/Header';
import { NewCheckoutSummary } from '../../Components/Summary';
import { BirthdayReviewAdmission } from './Admission';
import {
  getAdmissionSubtotal,
  getAdmissionTax,
  getAdmissionTotal,
} from '../Admission/getBirthdayAdmissionTotals';
import { BirthdayReviewAddon } from './Addon';
import { STEPS } from '../../const';
import {
  getEntertainmentSubtotal,
  getEntertainmentTax,
  getEntertainmentTotal,
} from '../Entertainment/getBirthdayEntertainmentTotals';
import {
  getFoodAndBeverageSubtotal,
  getFoodAndBeverageTax,
  getFoodAndBeverageTotal,
} from '../BirthdayFoodAndBeverage/getBirthdayFoodAndBeverageTotals';
import {
  getMerchandiseSubtotal,
  getMerchandiseTax,
  getMerchandiseTotal,
} from '../Merchandise/getBirthdayMerchandiseTotals';

import { NewCheckoutProgressBar } from '../../Components/ProgressBar';

@inject('birthdays', 'venues')
@observer
export default class BirthdayReview extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      venue: null,
      isEnabled: null,
    };
  }

  async routeDidUpdate() {
    const { slug } = (this.props.match && this.props.match.params) || {};
    const venue = await this.props.venues.fetchItemBySlug(slug);

    try {
      const settings = await this.props.birthdays.getParties(slug);
      const admission = await this.props.birthdays.validateAdmission(
        slug,
        settings
      );
      this.props.birthdays.validateEntertainment(
        settings,
        await this.props.birthdays.getEntertainmentProducts(admission, settings)
      );
      this.props.birthdays.validateFoodAndBeverage(
        settings,
        await this.props.birthdays.getFoodAndBeverageProducts(
          admission,
          settings
        )
      );
      this.props.birthdays.validateMerchandise(
        settings,
        await this.props.birthdays.getMerchandiseProducts(admission, settings)
      );

      if (admission.isValid) {
        this.setState({
          venue,
          isEnabled: true,
          quantities: {},
          settings,
        });
      } else {
        this.setState({
          redirect: this.props.match.url.replace('review', 'tickets'),
        });
      }
    } catch (e) {
      this.setState({ isEnabled: false });
    }
  }

  onSubmit = () => {
    this.setState({
      redirect: this.props.match.url.replace('review', 'info'),
    });
  };

  getAddons(products, quantities) {
    return products
      .map((product) => {
        const selectedProducts = product.products.filter((prod) =>
          Object.keys(quantities).includes(prod.id)
        );

        if (!selectedProducts.length) return null;

        return {
          title: product.name,
          description: product.description,
          images: product.images,
          variants: selectedProducts.map((prod) => ({
            name: product.products.length > 1 && prod.name,
            price: prod.cost * 100,
            quantity: quantities[prod.id],
            productId: prod.id,
            ...(isNumber(prod.maxQuantity) && { max: prod.maxQuantity }),
          })),
        };
      })
      .filter(Boolean);
  }

  renderEntertainment() {
    const { entertainment } = this.props.birthdays.checkout;
    const { products, quantities } = entertainment;

    const onChangeQuantity = (id, quantity) => {
      this.props.birthdays.update({
        entertainment: {
          ...entertainment,
          quantities: {
            ...quantities,
            [id]: quantity,
          },
        },
      });
    };

    const onRemove = (id) => {
      const { [id]: remove, ...rest } = quantities;
      this.props.birthdays.update({
        entertainment: {
          ...entertainment,
          quantities: rest,
        },
      });
    };

    return (
      <BirthdayReviewAddon
        title="Entertainment"
        products={this.getAddons(products, quantities)}
        onEdit={() => {
          this.setState({
            loading: false,
            redirect: this.props.match.url.replace('review', 'addons'),
          });
        }}
        onChangeQuantity={onChangeQuantity}
        onRemove={onRemove}
      />
    );
  }

  renderFoodAndBeverage() {
    const { foodAndBeverage } = this.props.birthdays.checkout;
    const { products, quantities } = foodAndBeverage;

    const onChangeQuantity = (id, quantity) => {
      this.props.birthdays.update({
        foodAndBeverage: {
          ...foodAndBeverage,
          quantities: {
            ...quantities,
            [id]: quantity,
          },
        },
      });
    };

    const onRemove = (id) => {
      const { [id]: remove, ...rest } = quantities;
      this.props.birthdays.update({
        foodAndBeverage: {
          ...foodAndBeverage,
          quantities: rest,
        },
      });
    };

    return (
      <BirthdayReviewAddon
        title="Food"
        products={this.getAddons(products, quantities)}
        onEdit={() => {
          this.setState({
            loading: false,
            redirect: this.props.match.url.replace(
              'review',
              'food-and-beverage'
            ),
          });
        }}
        onChangeQuantity={onChangeQuantity}
        onRemove={onRemove}
      />
    );
  }

  renderMerchandise() {
    const { merchandise } = this.props.birthdays.checkout;
    const { products, quantities } = merchandise;

    const onChangeQuantity = (id, quantity) => {
      this.props.birthdays.update({
        merchandise: {
          ...merchandise,
          quantities: {
            ...quantities,
            [id]: quantity,
          },
        },
      });
    };

    const onRemove = (id) => {
      const { [id]: remove, ...rest } = quantities;
      this.props.birthdays.update({
        merchandise: {
          ...merchandise,
          quantities: rest,
        },
      });
    };

    return (
      <BirthdayReviewAddon
        title="Merchandise"
        products={this.getAddons(products, quantities)}
        onEdit={() => {
          this.setState({
            loading: false,
            redirect: this.props.match.url.replace('review', 'merchandise'),
          });
        }}
        onChangeQuantity={onChangeQuantity}
        onRemove={onRemove}
      />
    );
  }

  renderAdmission() {
    const { admission } = this.props.birthdays.checkout;
    if (!admission) {
      this.setState({
        redirect: this.props.match.url.replace('review', 'tickets'),
      });
      return;
    }

    const { selectedSession } = admission;
    const settings = this.state.settings.admissions[admission.selected];

    const onChangeQuantity = (quantity) => {
      const { admission } = this.props.birthdays.checkout;

      this.props.birthdays.update({
        admission: {
          ...admission,
          numberOfGuests: quantity,
        },
      });
    };

    const guestsAvailable = this.props.birthdays.getGuestsAvailable(
      selectedSession,
      settings.maximumNumberOfGuests
    );

    return (
      <BirthdayReviewAdmission
        image={settings.images}
        date={admission.date}
        title={settings.title}
        session={selectedSession.name}
        price={selectedSession.cost}
        quantity={admission.numberOfGuests}
        total={getAdmissionSubtotal({
          selectedSession: selectedSession,
          numberOfGuests: admission.numberOfGuests,
        })}
        onChangeQuantity={onChangeQuantity}
        max={guestsAvailable}
        min={selectedSession.guestsIncluded}
        onEdit={() => {
          this.setState({
            loading: false,
            redirect: this.props.match.url.replace('review', 'tickets'),
          });
        }}
      />
    );
  }

  renderSummary() {
    const { admission, entertainment, foodAndBeverage, merchandise } =
      this.props.birthdays.checkout;
    const { selectedSession, numberOfGuests } = admission;

    const totals = {
      subtotal:
        getAdmissionSubtotal({ selectedSession, numberOfGuests }) +
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal(merchandise),
      tax:
        getAdmissionTax({ selectedSession, numberOfGuests }) +
        getEntertainmentTax(entertainment) +
        getFoodAndBeverageTax(foodAndBeverage) +
        getMerchandiseTax(merchandise),
      ticketSubtotal: getAdmissionSubtotal({ selectedSession, numberOfGuests }),
      addonSubtotal:
        getEntertainmentSubtotal(entertainment) +
        getFoodAndBeverageSubtotal(foodAndBeverage) +
        getMerchandiseSubtotal(merchandise),
      total:
        getAdmissionTotal({ selectedSession, numberOfGuests }) +
        getEntertainmentTotal(entertainment) +
        getFoodAndBeverageTotal(foodAndBeverage) +
        getMerchandiseTotal(merchandise),
    };

    const error = this.state.error && { errorMessage: this.state.error };

    return (
      <NewCheckoutSummary
        stepNames={{
          tickets: 'Admission',
          addons: 'Add-ons',
          checkout: 'Details & Confirmation',
        }}
        error={error}
        canSubmit
        onSubmit={this.onSubmit}
        modify={{
          [STEPS.Tickets]: {
            enabled: true,
            onClick: () => {
              this.setState({
                redirect: this.props.match.url.replace('review', 'tickets'),
              });
            },
          },
          [STEPS.Addons]: {
            enabled: true,
            onClick: () => {
              this.setState({
                redirect: this.props.match.url.replace('review', 'addons'),
              });
            },
          },
        }}
        step={STEPS.Checkout}
        loading={this.state.loading}
        totals={totals}
      />
    );
  }

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const { isEnabled } = this.state;
    if (isEnabled === null) return null;
    if (!isEnabled) return <NotFound />;

    return (
      <BirthdayCheckoutContainer
        attrs={this.getAttrs()}
        content={
          <>
            <NewCheckoutProgressBar
              title="Details & Confirmation"
              total={3}
              current={3}
              onClick={() => {
                this.setState({
                  redirect: this.props.match.url.replace(
                    'review',
                    'food-and-beverage'
                  ),
                });
              }}
            />
            <NewCheckoutTicketsHeader showLogo={false} title="Review" />
            {this.renderAdmission()}
            {this.renderEntertainment()}
            {this.renderFoodAndBeverage()}
            {this.renderMerchandise()}
          </>
        }
        summary={this.renderSummary()}
      />
    );
  }
}
