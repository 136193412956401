import React from 'react';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { Screen } from 'public/helpers';
import success from 'common/assets/success.svg';

import WalletButtons from '../NewCheckout/Components/WalletButtons';

import './order-update-success.less';

@inject('orderUpdate')
@observer
export default class OrderUpdateSuccess extends Screen {
  componentDidMount() {
    const { orderUpdate } = this.props;

    if (!orderUpdate?.order?.id) {
      this.props.history.push('/profile/orders');
    }

    window.scrollTo(0, 0);
  }

  handleGoHome = () => {
    const { orderUpdate } = this.props;
    orderUpdate.initialize();
    this.props.history.push('/');
  };

  handleContinueToBookings = () => {
    this.props.history.push('/profile/orders');
  };

  renderBody() {
    const { orderUpdate } = this.props;

    return (
      <div className={this.getElementClass('container')}>
        <div className={this.getElementClass('info')}>
          <img src={success} alt="success" />
          <p className="title">Order modified</p>
          <p>
            You’ll receive an email with your order confirmation and receipt.
          </p>
        </div>
        <WalletButtons orderId={orderUpdate.order.id} />
        <div className={this.getElementClass('buttons-container')}>
          <Button
            size="large"
            fluid
            inverted
            onClick={this.handleContinueToBookings}>
            Continue to bookings
          </Button>
          <Button size="large" fluid onClick={this.handleGoHome}>
            Go home
          </Button>
        </div>
      </div>
    );
  }
}
