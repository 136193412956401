import React from 'react';
import Marquee from 'react-fast-marquee';

import { useClassName, Only } from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import HomepageButton from '../../../Components/Button';
import HomepageImage from '../../../Components/Image';
import HomepageAsset from '../../../Components/Asset';

import './small.less';

export default function SmallStoresSection({ section }) {
  const classNames = useClassName('SmallStoresSection');

  return (
    <>
      <div className={classNames('video-container')}>
        <HomepageAsset asset={section.fields.video} />
        <div className={classNames('shadow')}></div>
        <div className={classNames('video-details')}>
          <h3>{section.fields.header}</h3>
          <ContentfulRichText field={{ 'en-US': section.fields.subheader }} />
          <Only desktop tablet>
            <HomepageButton
              cta={section.fields.cta}
              className={classNames('cta')}
            />
          </Only>
        </div>
      </div>
      <Marquee pauseOnHover>
        {section.fields.stores.map(({ fields, sys }, index) => (
          <HomepageButton cta={fields.callToAction} key={sys.id}>
            <HomepageImage
              field={fields.desktop}
              mobile={fields.mobile}
              className={classNames('slide')}
              style={{
                animationDelay: `${index * 150}ms`,
              }}
            />
          </HomepageButton>
        ))}
      </Marquee>
      <Only mobile>
        <HomepageButton
          cta={section.fields.cta}
          className={classNames('cta')}
        />
      </Only>
    </>
  );
}
