import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Modal from './Modal';
import Button from './Button';
import { getPlayerSelectorWidth } from '../utils';
import './score-modal.less';

const ScoreModal = ({
  players,
  currentHole,
  editingHole,
  isOpen,
  venueName,
  handleClose,
  handleConfirmScore,
}) => {
  const [playersScore, setPlayersScore] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(0);
  const [hole, setHole] = useState(0);
  const className = useClassName('ScoreModal');

  useEffect(() => {
    setSelectedPlayer(0);
    const score = !editingHole
      ? new Array(players.length).fill(0)
      : players.map((player) => player.score[editingHole - 1]);
    setPlayersScore(score);
    setHole(editingHole || currentHole);
  }, [currentHole, editingHole]);

  const handleScoreChange = (action) => {
    if (playersScore[selectedPlayer] === 0 && action === 'reduce') return;

    setPlayersScore(
      playersScore.map((score, index) => {
        if (index !== selectedPlayer) return score;
        return action === 'reduce' ? score - 1 : score + 1;
      })
    );
  };

  const renderControl = (action) => (
    <button
      className={className([
        'control',
        `control-${venueName}`,
        `control-${venueName}-${action}`,
      ])}
      onClick={() => handleScoreChange(action)}>
      {action === 'reduce' ? '-' : '+'}
    </button>
  );

  return (
    <Modal
      isOpen={isOpen}
      title={`Hole ${hole}`}
      venueName={venueName}
      handleClose={handleClose}>
      <div className={className('container')}>
        <div className={className('score')}>{playersScore[selectedPlayer]}</div>
        <p className={className('message')}>
          Select the player who is currently playing and count each stroke with
          +/- buttons.
        </p>
        <div className={className('players')}>
          {players.map((player, index) => (
            <div
              key={`player-${index}`}
              className={className([
                'player',
                `player-${venueName}`,
                `player-${venueName}-${index === selectedPlayer && 'selected'}`,
              ])}
              onClick={() => setSelectedPlayer(index)}
              style={{ width: getPlayerSelectorWidth(players.length) }}>
              {player.name}
            </div>
          ))}
        </div>
        <div className={className('controls')}>
          {renderControl('reduce')}
          {renderControl('increase')}
        </div>
        <Button
          label="Confirm score"
          handleClick={() => handleConfirmScore(playersScore)}
          venueName={venueName}
          disabled={playersScore.some((score) => !score)}
          primary
        />
      </div>
    </Modal>
  );
};

ScoreModal.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object),
  currentHole: PropTypes.number,
  editingHole: PropTypes.number,
  isOpen: PropTypes.bool,
  venueName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmScore: PropTypes.func.isRequired,
};

ScoreModal.defaultProps = {
  players: [],
  currentHole: 1,
  isOpen: false,
};

export default ScoreModal;
