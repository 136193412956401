import React from 'react';
import PropTypes from 'prop-types';

import { LinkTabs } from 'public/components/Tabs';

const Categories = ({ categories, currentCategory }) => {
  const getLinks = () =>
    categories.map((subCategory) => ({
      url: `/events/category/${subCategory.route}`,
      title: subCategory.name,
    }));

  return (
    categories && (
      <LinkTabs
        links={getLinks()}
        isSelected={(index) => currentCategory === categories[index].route}
      />
    )
  );
};

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  currentCategory: PropTypes.string.isRequired,
};

export default Categories;
