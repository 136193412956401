import React from 'react';
import { Container } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { Component } from 'common/helpers';
import { Layout, SVGIcon as Icon } from 'common/components';
import { Spacer } from '../Spacer';

import './carousel.less';

export default class CardCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDisabled: false,
      nextDisabled: false,
      scrollbarWidth: '0%',
      scrollbarOffset: '0%',
      scrollerBottomMargin: '0',
    };
    this.scrollRef = React.createRef();
    this.trackRef = React.createRef();
    this.dragOrigin = null;
  }

  // Lifecycle

  componentDidMount() {
    this.checkScrollBounds();
    this.calculateScrollbarWidth();
    this.removeScrollbar();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  // Updates

  calculateScrollbarWidth() {
    const { clientWidth, scrollWidth } = this.scrollRef.current;
    const scrollbarWidth = `${Math.round((clientWidth / scrollWidth) * 100)}%`;
    this.setState({
      scrollbarWidth,
    });
  }

  calculateScrollbarOffset() {
    const { scrollLeft, scrollWidth } = this.scrollRef.current;
    const scrollbarOffset = `${Math.round((scrollLeft / scrollWidth) * 100)}%`;
    this.setState({
      scrollbarOffset,
    });
  }

  checkScrollBounds(targetLeft) {
    const { scrollLeft, scrollWidth, clientWidth } = this.scrollRef.current;
    const max = scrollWidth - clientWidth;
    targetLeft = targetLeft || scrollLeft;
    this.setState({
      prevDisabled: targetLeft <= 0,
      nextDisabled: targetLeft >= max,
    });
  }

  // Events

  onScroll = () => {
    this.calculateScrollbarOffset();
    this.checkScrollBounds();
  };

  onResize = debounce(() => {
    this.calculateScrollbarWidth();
  }, 200);

  onThumbMouseDown = (evt) => {
    // Prevent text selection while dragging
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOrigin = {
      mouseX: evt.clientX,
      scrollX: this.scrollRef.current.scrollLeft,
    };
    document.documentElement.addEventListener(
      'mousemove',
      this.onThumbDragMove
    );
    document.documentElement.addEventListener('mouseup', this.onThumbDragStop);
    document.body.classList.add('dragging');
  };

  onThumbDragMove = (evt) => {
    const scrollEl = this.scrollRef.current;
    const trackEl = this.trackRef.current;

    const { scrollX, mouseX } = this.dragOrigin;
    const { scrollWidth } = scrollEl;
    const { clientWidth: trackWidth } = trackEl;

    const dx = evt.clientX - mouseX;
    scrollEl.scrollLeft = scrollX + dx * (scrollWidth / trackWidth);
  };

  onThumbDragStop = () => {
    this.dragOrigin = null;
    document.documentElement.removeEventListener(
      'mousemove',
      this.onThumbDragMove
    );
    document.documentElement.removeEventListener(
      'mouseup',
      this.onThumbDragStop
    );
    document.body.classList.remove('dragging');
  };

  // Actions

  prev = () => {
    this.scrollDir(-1);
  };

  next = () => {
    this.scrollDir(1);
  };

  scrollDir(dir) {
    const el = this.scrollRef.current;
    const left = el.clientWidth * dir;
    el.scrollBy({
      left,
      behavior: 'smooth',
    });
    this.checkScrollBounds(el.scrollLeft + left);
  }

  // Util

  removeScrollbar() {
    const { clientHeight, offsetHeight } = this.scrollRef.current;
    const scrollerBottomMargin = `${clientHeight - offsetHeight}px`;
    this.setState({
      scrollerBottomMargin,
    });
  }

  getArrowClass(type) {
    const disabled = this.state[`${type}Disabled`];
    return this.getElementClass('arrow', type, disabled ? 'disabled' : null);
  }

  render() {
    return (
      <div {...this.getAttrs()}>
        <div className={this.getElementClass('scroll-container')}>
          <div
            ref={this.scrollRef}
            style={{
              marginBottom: this.state.scrollerBottomMargin,
            }}
            onScroll={this.onScroll}
            className={this.getElementClass('scroll')}>
            {this.props.children}
          </div>
        </div>
        <Spacer size="s" />
        <Container>{this.renderControls()}</Container>
      </div>
    );
  }

  renderControls() {
    const { scrollbarWidth } = this.state;
    if (scrollbarWidth === '100%') {
      return;
    }
    return (
      <Layout padded horizontal stretch>
        <Layout.Group fixed>{this.renderArrows()}</Layout.Group>
        <Layout.Group grow>{this.renderScrollbar()}</Layout.Group>
      </Layout>
    );
  }

  renderArrows() {
    return (
      <Layout horizontal center>
        <div onClick={this.prev} className={this.getArrowClass('prev')}>
          <Icon name="angle-left" width={7} height={12} />
        </div>
        <div onClick={this.next} className={this.getArrowClass('next')}>
          <Icon name="angle-right" width={7} height={12} />
        </div>
      </Layout>
    );
  }

  renderScrollbar() {
    const { scrollbarWidth, scrollbarOffset } = this.state;
    return (
      <Layout
        onMouseDown={this.onThumbMouseDown}
        className={this.getElementClass('scrollbar-hit-area')}
        center>
        <div
          ref={this.trackRef}
          className={this.getElementClass('scrollbar-track')}>
          <div
            style={{
              width: scrollbarWidth,
              left: scrollbarOffset,
            }}
            className={this.getElementClass('scrollbar-thumb')}
          />
        </div>
      </Layout>
    );
  }
}
