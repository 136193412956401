import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PaymentElement } from '@stripe/react-stripe-js';

import { useClassName } from 'common/hooks';

import { NewCheckoutPaymentAgreements } from '../Agreements';

import './payment-form.less';

const options = {
  bancontact: 'never',
  card: 'never',
  ideal: 'never',
  sepaDebit: 'never',
  sofort: 'never',
  auBecsDebit: 'never',
  usBankAccount: 'never',
};

const PaymentForm = ({ onComplete, onReady, hidePayment }) => {
  const [completed, setCompleted] = useState({
    form: false,
    agreements: false,
  });
  const classNames = useClassName('NewCheckoutPaymentForm');

  useEffect(() => {
    if (hidePayment) {
      onReady();
    }
  }, [hidePayment]);

  const onChangeCompleted = (newValue) => {
    setCompleted(newValue);
    onComplete((hidePayment || newValue.form) && newValue.agreements);
  };

  return (
    <div className={classNames('container')}>
      {!hidePayment && (
        <PaymentElement
          options={{
            terms: options,
          }}
          onChange={({ complete }) => {
            onChangeCompleted({ ...completed, form: complete });
          }}
          onReady={onReady}
        />
      )}
      <NewCheckoutPaymentAgreements
        onChange={({ complete }) =>
          onChangeCompleted({ ...completed, agreements: complete })
        }
      />
    </div>
  );
};

PaymentForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onReady: PropTypes.func.isRequired,
  hidePayment: PropTypes.bool,
};

export default PaymentForm;
