import { observable, action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class DealsStore extends BaseStore {
  @observable redirect = false;

  @action
  setRedirect() {
    this.redirect = true;
  }

  @action
  async getDealsSubCategories(query = '', statusKey = 'dealsSubCategories') {
    const status = this.createStatus(statusKey);
    const [deals] = await this.getAllDeals({
      subCategory: 'all-deals',
      page: -1,
      skip: -1,
      query,
    });
    const createRoute = (str) =>
      str
        .replaceAll(/[^0-9a-z\s+]/gi, '')
        .replace(/ +/g, '-')
        .trim()
        .toLowerCase();
    let subCategories = deals
      .flatMap((deal) => deal.venueId.categories)
      .filter((category) => category);
    subCategories = [...new Set(subCategories)].map((category) => {
      return {
        name: category,
        route: createRoute(category),
      };
    });
    status.success();
    return [
      { name: 'All Deals', route: createRoute('All Deals') },
      ...subCategories,
    ];
  }

  @action
  getDealsByCategory(category, statusKey = 'listDealsByCategory') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/deals/search/${category}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  getFeaturedDeals(statusKey = 'featuredDeals') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/deals/featured`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  getAllDeals(params, statusKey = 'listAllDeals') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/deals/filter`,
      body: {
        subCategory: params.subCategory,
        page: params.page,
        skip: params.skip,
        query: params?.query || '',
      },
    })
      .then(({ data, totalItems }) => {
        status.success();
        return [data, totalItems];
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  getVenueDeals(venueId, statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/deals/${venueId}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  deleteDeal(dealId, venueId, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/deals/${dealId}/${venueId}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  storeDeal(body, statusKey = 'store') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/deals`,
      body,
    })
      .then(() => {
        status.success();
        this.setRedirect();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateDealStatus(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/deals/status/${body.dealId}`,
      body: { published: body.published },
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateDeal({ dealId, body }, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/deals/${dealId}`,
      body: { ...body },
    })
      .then(({ data }) => {
        status.success();
        this.setRedirect();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  getDealById(dealId, statusKey = 'getDealById') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/deals/find/${dealId}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
  @action
  getDealBySlug(slug, statusKey = 'getDealBySlug') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/deals/find-by-slug/${slug}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
