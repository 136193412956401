import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { Component } from 'common/helpers';
import { Layout } from '../Layout';
import { SVGIcon as Icon } from '../SVGIcon';

import './integer-input.less';

export default class IntegerInput extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      focused: false,
    };
  }

  componentDidUpdate() {
    const el = this.ref.current;
    if (el && el.value.length !== String(this.props.value).length) {
      // Fix issue with React allowing "0" prefix to values.
      el.value = this.props.value;
    }
  }

  validate(n) {
    return Math.max(Math.min(n, this.props.max), this.props.min);
  }

  toggleFocus(focused) {
    this.setState({
      focused,
    });
  }

  increment = (evt) => {
    if (!this.props.disabled) {
      this.props.onChange(
        Math.min(this.props.max, (this.props.value || 0) + 1),
        evt
      );
    }
  };

  decrement = (evt) => {
    if (!this.props.disabled) {
      this.props.onChange(
        Math.max(this.props.min, (this.props.value || 0) - 1),
        evt
      );
    }
  };

  onKeyDown = (evt) => {
    if (evt.key === 'ArrowUp') {
      evt.preventDefault();
      this.increment(evt);
    } else if (evt.key === 'ArrowDown') {
      evt.preventDefault();
      this.decrement(evt);
    }
  };

  onChange = (evt) => {
    let val = evt.target.value;
    if (val) {
      val = Number(evt.target.value);
      if (val > this.props.max || val < this.props.min) {
        return;
      }
    }
    this.props.onChange(val, evt);
  };

  getModifiers() {
    return [
      this.isInitial() ? 'initial' : null,
      this.props.disabled ? 'disabled' : null,
      this.props.error ? 'error' : null,
    ];
  }

  getInputStyles() {
    const { max } = this.props;
    const digits = Math.ceil(Math.log10(max));
    return {
      width: `${digits}em`,
    };
  }

  isInitial() {
    if (this.state.focused) {
      return false;
    }
    const { value } = this.props;
    return !value && value !== 0;
  }

  render() {
    const { min, max } = this.props;
    return (
      <Layout {...this.getAttrs()} horizontal>
        <Layout.Group
          onClick={this.decrement}
          className={this.getElementClass('button', 'dec')}>
          <Icon name="minus" size="tiny" />
        </Layout.Group>
        <Layout.Group className={this.getElementClass('input')}>
          <Input {...this.passProps(true)}>
            <input
              disabled={this.props.disabled}
              ref={this.ref}
              min={min}
              max={max}
              type="number"
              onKeyDown={this.onKeyDown}
              onChange={this.onChange}
              onBlur={() => this.toggleFocus(false)}
              onFocus={() => this.toggleFocus(true)}
              style={this.getInputStyles()}
            />
          </Input>
        </Layout.Group>
        <Layout.Group
          onClick={this.increment}
          className={this.getElementClass('button', 'inc')}>
          <Icon name="plus" size="tiny" />
        </Layout.Group>
        <div
          onClick={this.increment}
          className={this.getElementClass('button', 'inc-large')}>
          <Icon name="plus" size="tiny" />
        </div>
      </Layout>
    );
  }
}

IntegerInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
};

IntegerInput.defaultProps = {
  min: 0,
  max: 99,
  disabled: false,
};
