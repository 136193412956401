import React from 'react';
import { Step } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { SVGIcon as Icon } from 'common/components';

export default class Steps extends React.Component {
  render() {
    return (
      <Step.Group fluid unstackable>
        <Step to="/cart" as={NavLink} exact>
          <Icon name="cart" size="big" />
          <Step.Content>
            <Step.Title>Cart</Step.Title>
            <Step.Description>Confirm your tickets</Step.Description>
          </Step.Content>
        </Step>
        <Step to="/cart/checkout" as={NavLink} active={this.props.step === 2}>
          <Icon name="payment" size="big" />
          <Step.Content>
            <Step.Title>Checkout</Step.Title>
            <Step.Description>Enter billing information</Step.Description>
          </Step.Content>
        </Step>
        <Step active={this.props.step === 3}>
          <Icon name="ticket" size="big" />
          <Step.Content>
            <Step.Title>Get your tickets!</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}
