import React from 'react';
import AppleSignin from 'react-apple-signin-auth';

import { APPLE_CLIENT_ID } from 'utils/env/client';
import { useClassName } from 'common/hooks';
import appleLogo from 'common/assets/apple.svg';

import './apple-login-button.less';

const authOptions = {
  clientId: APPLE_CLIENT_ID,
  scope: 'email name',
  redirectURI: location.origin,
  state: 'state',
  nonce: 'nonce',
  usePopup: true,
};

export default function AppleLoginButton({ onError, onSuccess }) {
  const classNames = useClassName('AppleLoginButton');

  return (
    <AppleSignin
      authOptions={authOptions}
      onSuccess={({ authorization, user }) => {
        console.log(JSON.stringify({ authorization, user }));
        onSuccess({
          idToken: authorization.id_token,
          email: user?.email,
          firstName: user?.name?.firstName,
          lastName: user?.name?.lastName,
        });
      }}
      onError={(ex) => {
        console.error(ex);
        if (ex.error === 'popup_closed_by_user') return;

        onError(new Error(ex.error));
      }}
      render={(props) => (
        <div className={classNames('container')} {...props}>
          <img src={appleLogo} width="24" height="24" alt="Google logo" />
        </div>
      )}
    />
  );
}
