import React from 'react';
import PropTypes from 'prop-types';

import { SVGIcon } from 'common/components';
import { useClassName, useWindowSize } from 'common/hooks';

import './back-button.less';

const BackButton = ({ onClick, title }) => {
  const classNames = useClassName('BirthdayBackButton');
  const { isMobile } = useWindowSize();

  if (isMobile) return null;

  return (
    <div className={classNames('container')} onClick={onClick}>
      <SVGIcon name="arrow-left" size="mini" />
      {title}
    </div>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default BackButton;
