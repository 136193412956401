import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';

@inject('appSession')
export default class Logout extends React.Component {
  componentDidMount() {
    this.props.appSession.reset();
  }

  render() {
    return <Redirect to="/" />;
  }
}
