import React, { useRef } from 'react';
import { chunk } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Navigation from 'swiper/modules/navigation.mjs';
import Pagination from 'swiper/modules/pagination.mjs';
import EffectCreative from 'swiper/modules/effect-creative.mjs';

import {
  LazyVisible,
  useClassName,
  useResize,
  useWindowSize,
  usePreload,
  preloadAssets,
} from 'common/hooks';

import HomepageButton from '../../Components/Button';
import { usePagination } from '../../Components/usePagination';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';

import 'swiper/swiper.less';
import 'swiper/modules/effect-creative.less';
import 'swiper/modules/autoplay.less';
import './parks-gallery.less';

function Content({ fields, cta }) {
  const classNames = useClassName('ParksGallery');

  return (
    <>
      <HomepageButton
        cta={{
          ...cta,
          fields: {
            ...cta.fields,
            isHidden: true,
          },
        }}
        className={classNames(['park-card', fields.animation])}
        style={{
          background: `linear-gradient(180deg, ${fields.primaryColor} 0%, ${fields.secondaryColor}  100%)`,
        }}>
        <HomepageImage field={fields.icon} />
        {(fields?.figures || []).map((figure) => (
          <HomepageImage field={figure} key={figure.sys.id} />
        ))}
      </HomepageButton>
      <HomepageButton cta={cta} className={classNames('cta')} />
    </>
  );
}

function Mobile({ section }) {
  const classNames = useClassName('ParksGallery');
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  const ref = useRef();

  const parks = section.fields.parks.filter(({ fields }) => !fields.isLarge);

  const updateBulletColors = (swiper) => {
    const bullets = document.querySelectorAll(
      `.${classNames('bullets-container')} > *`
    );
    const park = parks[swiper.activeIndex];

    for (const bullet of bullets) {
      bullet.style.backgroundColor = park.fields.primaryColor;
    }
  };

  return (
    <LazyVisible ref={ref}>
      <Swiper
        modules={[Navigation, Pagination, EffectCreative]}
        height={542}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 7000 }}
        creativeEffect={{
          prev: {
            translate: ['-100%', 0, 0],
          },
          next: {
            translate: ['100%', 0, 0],
          },
          scale: 0.3,
        }}
        effect="creative"
        onSlideChange={(swiper) => {
          updateBulletColors(swiper);
          trackHomepageSlide(() => ({
            activeIndex: swiper.activeIndex,
            name: parks[swiper.activeIndex].fields.name,
            section: section.fields.sectionId,
          }));
        }}
        onSwiper={updateBulletColors}
        className={classNames('wrapper')}
        {...pagination}>
        {parks.map(({ fields, sys }) => (
          <SwiperSlide key={sys.id} className={classNames('slide-container')}>
            <div className={classNames('park-container')}>
              {fields.ctas.map((cta) => {
                return <Content key={cta.sys.id} cta={cta} fields={fields} />;
              })}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </LazyVisible>
  );
}

function DesktopRow({ items, index }) {
  const classNames = useClassName('ParksGallery');
  const ref = useRef(null);

  return (
    <LazyVisible key={index} ref={ref}>
      {items.map(({ fields, sys }) => (
        <div key={sys.id} className={classNames('park-container')}>
          {fields.ctas.map((cta) => {
            return <Content key={cta.sys.id} cta={cta} fields={fields} />;
          })}
        </div>
      ))}
    </LazyVisible>
  );
}

function Desktop({ section }) {
  const classNames = useClassName('ParksGallery');
  const { width } = useResize(
    document.querySelector(`.${classNames('small-parks')}`)
  );

  return chunk(
    section.fields.parks.filter(({ fields }) => !fields.isLarge),
    width > 600 ? 3 : 2
  ).map((items, index) => (
    <DesktopRow items={items} index={index} key={index} />
  ));
}

export default function ParksGallery({ section }) {
  const classNames = useClassName('ParksGallery');
  const { isMobile } = useWindowSize();
  const ref = useRef();

  usePreload(() => {
    preloadAssets([
      ...section.fields.parks.map(({ fields }) => fields.icon),
      ...section.fields.parks.map(({ fields }) => fields.figures).flat(),
    ]);
  }, ref);

  return (
    <>
      <div className={classNames('small-parks')}>
        {isMobile ? (
          <Mobile section={section} />
        ) : (
          <Desktop section={section} />
        )}
      </div>
      <LazyVisible ref={ref}>
        <HomepageButton
          cta={section.fields.exploreCta}
          className={classNames('explore')}
        />
      </LazyVisible>
    </>
  );
}
