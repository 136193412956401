import React from 'react';
import { Responsive } from 'semantic-ui-react';

const TABLET_MAX_WIDTH = 1128;

export default class TabletOnly extends React.Component {
  render() {
    return (
      <Responsive
        minWidth={Responsive.onlyMobile.maxWidth}
        maxWidth={TABLET_MAX_WIDTH - 1}
        {...this.props}
      />
    );
  }
}
