import React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Screen, isDateChangeFee } from 'public/helpers';
import { OrderGroup } from 'public/components';
import { request } from 'utils/api';
import { ProfileGroup } from './components';
const MAX_RETRIEVED_ORDERS = 10;

@inject('orderUpdate')
@observer
export default class ProfileOrders extends Screen {
  state = {
    result: null,
    loadingPage: true,
  };

  componentDidMount() {
    super.componentDidMount();
    const { orderUpdate } = this.props;
    const { updatedOrder } = orderUpdate;

    orderUpdate.initialize();

    request({
      method: 'GET',
      path: `/1/users/me/orders?last=${MAX_RETRIEVED_ORDERS}`,
    })
      .then(({ data }) => {
        this.setState({ result: data, loadingPage: false, updatedOrder });
      })
      .catch((error) => {
        this.setState({
          error,
          loadingPage: false,
        });
      });
  }

  getBundleAddon(ticketOption, orderTickets, venue) {
    if (!ticketOption?.addon) return null;
    const bundleSlug = orderTickets
      .filter(
        (ticket) =>
          ticket.bundle &&
          ticket.ticketOption.date === ticketOption.date &&
          ticket.venue.slug === venue.slug &&
          ticket.bundle?.rollerProducts
            ?.map((product) => product.addons)
            .flat()
            .includes(ticketOption.bookingItemId)
      )
      .map((filteredTicket) => filteredTicket.bundle.slug)
      .flat();
    return bundleSlug.length ? bundleSlug[0] : null;
  }

  defineTicketGroup(ticketOption, orderTickets, venue, bundleSlug) {
    if (bundleSlug) return bundleSlug;

    const bundleAddon = this.getBundleAddon(ticketOption, orderTickets, venue);
    if (bundleAddon) return bundleAddon;

    return `${venue.slug}-${ticketOption.date}`;
  }

  groupOrders(orders) {
    if (!orders) return [];

    const sortedOrders = orders.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1
    );

    const groupedOrders = sortedOrders.map((order) => {
      const groupedTickets = {};

      for (const ticket of order.tickets) {
        //Getting bundle and bundle slug for preventing app to crash if bundle is not found
        const { ticketOption, venue, bundle, bundleSlug } = ticket;

        if (isDateChangeFee(ticketOption.ticketOptionName)) continue;

        const ticketGroup = this.defineTicketGroup(
          ticketOption,
          order.tickets,
          venue,
          bundleSlug
        );
        if (!groupedTickets[ticketGroup]) {
          groupedTickets[ticketGroup] = {
            tickets: [],
            venue,
            bundle,
          };
          if (bundleSlug && !bundle) groupedTickets[ticketGroup].bundle = {};
          if (groupedTickets[ticketGroup].bundle)
            groupedTickets[ticketGroup].bundle.totalAmount = 0;
        }
        if (bundleSlug) {
          groupedTickets[ticketGroup].bundle.totalAmount +=
            ticketOption.totalAmountDiscount + ticketOption.taxDiscount;
        }

        if (!ticket.ticketOption?.externalTicket) {
          groupedTickets[ticketGroup].tickets.push(ticket);
        } else {
          const { ticketOptionId, date } = ticketOption;
          const existingTicket = groupedTickets[ticketGroup].tickets.findIndex(
            ({ ticketOption }) =>
              ticketOption.ticketOptionId === ticketOptionId &&
              ticketOption.date === date
          );
          if (existingTicket !== -1) {
            groupedTickets[ticketGroup].tickets[
              existingTicket
            ].ticketOption.quantity += ticket.ticketOption.quantity;
            groupedTickets[ticketGroup].tickets[
              existingTicket
            ].externalIds.push(ticket.externalBookingId);
          } else {
            ticket.externalIds = [ticket.externalBookingId];
            groupedTickets[ticketGroup].tickets.push(ticket);
          }
        }
      }
      order.ticketGroups = groupedTickets;
      return order;
    });

    return groupedOrders;
  }

  handleModifyOrder = (order, tickets, venue, bundle = null) => {
    const { orderUpdate } = this.props;

    orderUpdate.setOrder(order);
    orderUpdate.setCurrentTickets(tickets);
    orderUpdate.setNewTickets(
      tickets.filter(
        (ticket) =>
          !ticket.ticketOption.addon &&
          !isDateChangeFee(ticket.ticketOption.ticketOptionName)
      )
    );
    orderUpdate.setNewAddons(
      tickets.filter((ticket) => ticket.ticketOption.addon)
    );
    orderUpdate.setVenue(venue);
    orderUpdate.setBundle(bundle);

    this.props.history.push('/order-update');
  };

  isOrderEmpty(order) {
    return !Object.entries(order.ticketGroups).length;
  }

  renderBody() {
    const { error, loadingPage, result, updatedOrder } = this.state;

    const groupedOrders = this.groupOrders(result);
    return (
      <ProfileGroup {...this.props}>
        {error && <Message error content={error.message} />}
        {!loadingPage &&
          (!result?.length ||
            groupedOrders.every((order) => this.isOrderEmpty(order))) && (
            <Message content="No tickets purchased yet" />
          )}

        <div className={this.getElementClass('orders-container')}>
          {loadingPage ? (
            <Loader
              active
              inline="centered"
              size="big"
              style={{ marginTop: '50px' }}>
              Loading
            </Loader>
          ) : (
            !!groupedOrders.length &&
            groupedOrders.map((order) => {
              if (this.isOrderEmpty(order)) return null;
              return (
                <OrderGroup
                  key={order.id}
                  order={order}
                  updatedOrder={updatedOrder}
                  handleModifyOrder={this.handleModifyOrder}
                />
              );
            })
          )}
        </div>
      </ProfileGroup>
    );
  }
}

ProfileOrders.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

ProfileOrders.defaultProps = {
  contentfulSlugItem: 'my-orders',
};
