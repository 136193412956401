import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import './save-button.less';

const SaveButton = ({ label, disabled, handleClick }) => {
  const className = useClassName('SaveButton');
  return (
    <Button
      onClick={handleClick}
      className={className('main')}
      disabled={disabled}
      inverted>
      {label}
    </Button>
  );
};

PropTypes.SaveButton = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

PropTypes.defaultProps = {
  disabled: false,
};

export default SaveButton;
