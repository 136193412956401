import React from 'react';
import { inject } from 'mobx-react';
import { Loader, Container } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { ContentfulRichText, Content } from 'common/components';
import { Spacer, Eyebrow } from 'public/components';
import { NotFound } from '../NotFound';

import './article.less';

@inject('articles')
export default class Article extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      article: props.preview,
      error: null,
    };
  }

  async routeDidUpdate() {
    if (this.props.preview) {
      // Article may be passed in by the preview screen.
      return;
    }
    try {
      const { slug } = this.props.match.params;
      this.setState({
        article: await this.props.articles.fetchItemBySlug(slug),
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
        });
      } else {
        throw error;
      }
    }
  }

  renderBody() {
    const { article, error } = this.state;
    if (error) {
      return <NotFound {...this.props} />;
    } else if (!article) {
      return <Loader inline="centered" active />;
    }

    return (
      <div {...this.getAttrs()}>
        <Spacer size="m" />
        <Container>
          {this.renderCategory(article)}
          <Spacer size="xs" />
          <h1 className={this.getElementClass('title')}>{article.name}</h1>
        </Container>
        <Container text>
          <Content>
            <ContentfulRichText field={article.object.fields.body} />
          </Content>
          <Spacer size="xl" />
        </Container>
      </div>
    );
  }

  renderCategory(article) {
    const categories = article.articleCategories;
    if (categories && categories.length) {
      return <Eyebrow category={categories[0].name} date={article.date} link />;
    }
  }
}
