import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Component } from 'common/helpers';
import {
  Modal,
  Layout,
  ContentfulRichText,
  ContentfulImage,
  ContentLink,
  SVGIcon as Icon,
} from 'common/components';
import { Spacer } from '../Spacer';
import { OpenStatus } from '../Hours';
import { ViewHours } from 'public/components/ViewHours';
import { track } from 'utils/analytics';
import './nav.less';

export default class AttractionsDreamworksNav extends Component {
  getContainerProps(element) {
    const { color, background: backgroundColor } = this.props;
    const className = this.getElementClass(element);
    return {
      className,
      style: {
        color,
        backgroundColor,
      },
    };
  }

  getTicketsHref() {
    const { ticketsHref, venue } = this.props;
    // Preserve any query parameters
    return ticketsHref || `/venue/${venue.slug}/tickets${location.search}`;
  }

  handleLinkClick = (name) => {
    return () => {
      const { venue } = this.props;
      track(`${venue.slug} - Viewed ${name}`);
    };
  };

  render() {
    const { venue } = this.props;
    let { ticketInfoUrl, FaqUrl, alertsText } = this.props;
    ticketInfoUrl =
      ticketInfoUrl || `/venue/${venue.slug}/ticket-info${location.search}`;
    FaqUrl = FaqUrl || `/venue/${venue.slug}/faq`;
    // Note that position: sticky gets weird inside nested elements,
    // so need to use a fragment here.
    return (
      <React.Fragment>
        <div {...this.getContainerProps('desktop')}>
          <Container>
            <Layout
              className={this.getElementClass('sticky')}
              horizontal
              center
              spread>
              <Layout.Group>
                <Layout horizontal center extra padded>
                  <Layout.Group>
                    <Link to={`/venue/${venue.slug}`}>
                      <ContentfulImage field={venue.content.logo} height={52} />
                    </Link>
                  </Layout.Group>
                  <Layout.Group>
                    <OpenStatus openingHours={venue.openingHours} />
                    <ViewHours
                      openingHours={venue.openingHours}
                      closedMessage={venue.content.closedMessage}
                    />
                  </Layout.Group>
                </Layout>
              </Layout.Group>
              <Layout horizontal right center padded>
                <Layout.Group>
                  <ContentLink
                    href="/dreamsafe"
                    className={this.getElementClass('link')}
                    onClick={this.handleLinkClick('DreamSafe')}>
                    #DREAMSAFE
                  </ContentLink>
                </Layout.Group>
                <Layout.Group>
                  <ContentLink
                    href={FaqUrl}
                    className={this.getElementClass('link')}
                    onClick={this.handleLinkClick('FAQ')}>
                    <Icon name="faq" size="small" />
                    FAQ
                  </ContentLink>
                </Layout.Group>
                {venue.content.alerts && (
                  <Layout.Group>
                    <Modal
                      size="tiny"
                      trigger={
                        <div
                          className={this.getElementClass('link')}
                          onClick={this.handleLinkClick('ParkAlerts')}>
                          <Icon name="bell" size="small" /> {alertsText}
                        </div>
                      }>
                      <div className={this.getElementClass('modalContent')}>
                        <ContentfulRichText field={venue.content.alerts} />
                      </div>
                    </Modal>
                  </Layout.Group>
                )}
                <Layout.Group>
                  <ContentLink
                    href={ticketInfoUrl}
                    className={this.getElementClass('link')}
                    onClick={this.handleLinkClick('ViewInfo')}>
                    <Icon name="info" size="small" />
                    VIEW INFO
                  </ContentLink>
                </Layout.Group>
                {venue.hasTickets && (
                  <Layout.Group>
                    <Button
                      inverted
                      as={ContentLink}
                      href={this.getTicketsHref()}
                      onClick={this.handleLinkClick('BuyTickets')}>
                      <Icon name="ticketing" size="small" />
                      <span>Buy Tickets</span>
                    </Button>
                  </Layout.Group>
                )}
              </Layout>
            </Layout>
          </Container>
        </div>
        <div className={this.getElementClass('mobile')}>
          <Container>
            <Layout vertical center padded>
              <Layout.Group>
                <Link to={`/venue/${venue.slug}`}>
                  <ContentfulImage field={venue.content.logo} width={184} />
                </Link>
              </Layout.Group>
              <Layout.Group className={this.getElementClass('open-hours')}>
                <OpenStatus openingHours={venue.openingHours} />
                <ViewHours
                  openingHours={venue.openingHours}
                  closedMessage={venue.content.closedMessage}
                  venueSlug={venue.slug}
                />
              </Layout.Group>
            </Layout>
          </Container>
        </div>
        <div className={this.getElementClass('mobile-sticky')}>
          <Container>
            <Layout center>
              <Layout center padded>
                <Layout.Group>
                  <Layout.Group>
                    <ContentLink
                      href="/dreamsafe"
                      className={this.getElementClass('link')}
                      onClick={this.handleLinkClick('DreamSafe')}>
                      #DREAMSAFE
                    </ContentLink>
                  </Layout.Group>
                </Layout.Group>
                <Layout.Group>
                  <ContentLink
                    href={FaqUrl}
                    className={this.getElementClass('link')}
                    onClick={this.handleLinkClick('FAQ')}>
                    <Icon name="faq" size="small" />
                    FAQ
                  </ContentLink>
                </Layout.Group>
                {venue.content.alerts && (
                  <Layout.Group>
                    <Modal
                      size="tiny"
                      trigger={
                        <div
                          className={this.getElementClass('link')}
                          onClick={this.handleLinkClick('ParkAlerts')}>
                          <Icon name="bell" size="small" /> {alertsText}
                        </div>
                      }>
                      <div className={this.getElementClass('modalContent')}>
                        <ContentfulRichText field={venue.content.alerts} />
                      </div>
                    </Modal>
                  </Layout.Group>
                )}
                <Layout.Group>
                  <Link
                    to={`/venue/${venue.slug}/ticket-info`}
                    className={this.getElementClass('link')}
                    onClick={this.handleLinkClick('ViewInfo')}>
                    <Icon name="info" size="small" />
                    INFO
                  </Link>
                </Layout.Group>
              </Layout>
            </Layout>
            {venue.hasTickets && (
              <React.Fragment>
                <Spacer size="xs" />
                <Button
                  inverted
                  as={ContentLink}
                  href={this.getTicketsHref()}
                  size="small"
                  fluid
                  onClick={this.handleLinkClick('BuyTickets')}>
                  <Icon name="ticketing" size="small" />
                  <span>Buy Tickets</span>
                </Button>
              </React.Fragment>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AttractionsDreamworksNav.propTypes = {
  venue: PropTypes.object.isRequired,
  ticketInfoUrl: PropTypes.string,
  FaQUrl: PropTypes.string,
  ticketsHref: PropTypes.string,
  alertsText: PropTypes.string,
};

AttractionsDreamworksNav.defaultProps = {
  alertsText: 'PARK ALERTS',
};
