import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';

import './product-card.less';

const ProductCard = ({
  isSoldOut,
  largeImage,
  selected,
  image,
  info,
  description,
  onDetail,
  controls,
  children,
  caption,
  hideDescription,
}) => {
  const classNames = useClassName('ProductCard');

  const getItemClassNames = () => {
    const cls = [classNames('container')];
    if (isSoldOut) cls.push(classNames('sold-out'));
    if (selected) cls.push(classNames('selected'));

    return cls.join(' ');
  };

  return (
    <div className={getItemClassNames()}>
      <div
        className={classNames([
          'header',
          caption ? 'with-caption' : largeImage && 'large-image',
        ])}>
        <div className={classNames('image')}>{image}</div>
        {Boolean(caption) && (
          <div className={classNames('caption')}>{caption}</div>
        )}
        <div className={classNames('info')}>{info}</div>
        {Boolean(description && !hideDescription) && (
          <div className={classNames('description')}>{description}</div>
        )}
        {Boolean(onDetail) && (
          <p className={classNames('view-detail')} onClick={onDetail}>
            View Detail
          </p>
        )}
        <div className={classNames('controls')}>{controls}</div>
      </div>
      {children}
    </div>
  );
};

ProductCard.propTypes = {
  isSoldOut: PropTypes.bool,
  largeImage: PropTypes.bool,
  selected: PropTypes.bool,
  image: PropTypes.any,
  info: PropTypes.any,
  description: PropTypes.any,
  onDetail: PropTypes.func,
  controls: PropTypes.any,
  children: PropTypes.any,
  caption: PropTypes.string,
  hideDescription: PropTypes.bool,
};

export default ProductCard;
