import React from 'react';

import GroupSales from './GroupSales';
import { propTypes, defaultProps } from './GroupSalesProps';

const DreamGiftsGroupSales = ({ fields, brazeTriggers, ...props }) => {
  return (
    <GroupSales
      fields={{
        ...fields,
        venueOfInterest: { ignored: true },
        companyType: { ignored: true },
        clientType: { ignored: true },
        company: { optional: true },
        groupType: { optional: true },
        taxExempt: { optional: true },
        comments: { optional: true },
      }}
      brazeTriggers={brazeTriggers}
      {...props}
    />
  );
};

DreamGiftsGroupSales.propTypes = propTypes;
DreamGiftsGroupSales.defaultProps = defaultProps;

export default DreamGiftsGroupSales;
