import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useClassName } from 'common/hooks';
import logo from 'common/assets/footer-logo.svg';

import useChat from '../../../Chat/useChat';

import './footer.less';

const Footer = ({ classes, onHelpClick }) => {
  const classNames = useClassName('Footer');

  const chat = useChat({
    openOnLoad: false,
  });

  useEffect(() => {
    document
      .getElementsByClassName('footer')?.[0]
      ?.style?.setProperty('display', 'none');
  }, []);

  return (
    <footer className={`${classNames('container')} ${classes}`}>
      <style>
        {`
          .BeaconFabButtonFrame {
            display: ${chat.isOpen ? 'block' : 'none'} !important;
          }
        `}
      </style>
      <p
        className={
          !onHelpClick && (chat.loading || chat.message) && classNames('hidden')
        }>
        <span>Need assistance?</span>
        <Link
          onClick={(ev) => {
            if (!onHelpClick) {
              chat.open(ev);
            } else {
              ev.preventDefault();
              onHelpClick(ev);
            }
          }}
          to="#">
          Get help with your purchase
        </Link>
      </p>
      <img src={logo} alt="AMERICAN DREAM" />
    </footer>
  );
};

export default Footer;
