import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, Table, Checkbox, Button, Message } from 'semantic-ui-react';
import { SVGIcon as Icon } from 'common/components';
import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';
import { ProfileGroup } from '../components';
import { DEFAULT_GROUP_ID } from '../../../../common/const';

import './preferences.less';

@inject('subscriptions', 'me')
@observer
export default class EmailPreferences extends Screen {
  state = {
    loading: true,
    message: null,
    groups: null,
  };

  routeDidUpdate() {
    this.fetchGroups();
  }

  async fetchGroups() {
    this.setState({
      loading: true,
      message: null,
    });
    const groups = await this.props.subscriptions.fetch();
    this.setState({
      loading: false,
      groups,
    });
  }

  onGroupChange = (evt, data) => {
    let { groups } = this.state;
    const isAll = data.value === DEFAULT_GROUP_ID;
    groups = groups.map((group) => {
      let active = group.active;
      if (group.id === DEFAULT_GROUP_ID) {
        if (data.value === DEFAULT_GROUP_ID) {
          active = data.checked;
        } else if (data.value !== DEFAULT_GROUP_ID && data.checked) {
          active = false;
        }
      } else if (isAll) {
        active = false;
      } else if (group.id === data.value) {
        active = data.checked;
      }
      return {
        ...group,
        active,
        changed: group.changed || active !== group.active,
      };
    });
    this.setState({
      groups,
    });
  };

  onSave = async () => {
    const { subscriptions, me } = this.props;
    const { groups } = this.state;
    try {
      this.setState({
        loading: true,
        message: null,
      });
      await Promise.all(
        groups
          .filter((group) => group.changed)
          .map((group) => {
            if (group.subscription_id) {
              return subscriptions.update(group.subscription_id, !group.active);
            } else if (!group.subscription_id && group.active) {
              const { email, firstName, lastName } = me.user;
              return subscriptions.create({
                email,
                firstName,
                lastName,
                group: group.id,
              });
            } else {
              return Promise.resolve();
            }
          })
      );
      this.setState({
        loading: false,
        message: {
          type: 'success',
          text: 'Preferences Saved',
        },
      });
    } catch (err) {
      this.setState({
        loading: false,
        message: {
          type: 'error',
          text: err.message,
        },
      });
    }
  };

  renderBody() {
    return (
      <ProfileGroup {...this.props}>
        <h3>Email Preferences</h3>
        <Spacer size="s" />
        <section>
          {this.renderMessage()}
          <div className={this.getElementClass('title')}>
            <Icon
              name="email"
              size="tiny"
              className={this.getElementClass('title-icon')}
            />
            Manage email preferences
          </div>
          <Spacer size="s" />
          <p>Select the emails you want to receive.</p>
          <Spacer size="s" />
          {this.renderGroups()}
          <Spacer size="m" />
        </section>
      </ProfileGroup>
    );
  }

  renderMessage() {
    const { message } = this.state;
    if (message) {
      const { type, text } = message;
      return (
        <React.Fragment>
          <Message
            content={text}
            positive={type === 'success'}
            negative={type === 'error'}
          />
          <Spacer size="s" />
        </React.Fragment>
      );
    }
  }

  renderGroups() {
    const { loading, groups } = this.state;
    if (loading) {
      return (
        <React.Fragment>
          <Spacer size="m" />
          <Loader inline="centered" active />
          <Spacer size="m" />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Allow</Table.HeaderCell>
              <Table.HeaderCell>Topic</Table.HeaderCell>
              <Table.HeaderCell>What to Expect</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {groups.map((group) => {
              const { id, name, description, active } = group;
              return (
                <Table.Row key={id}>
                  <Table.Cell>
                    <Checkbox
                      checked={active}
                      value={id}
                      onChange={this.onGroupChange}
                      toggle
                    />
                  </Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{description}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Spacer size="s" />
        {this.renderActions()}
      </React.Fragment>
    );
  }

  renderActions() {
    const { groups } = this.state;
    const touched = groups && groups.some((g) => g.changed);
    return (
      <div className={this.getElementClass('actions')}>
        <Button onClick={this.onSave} disabled={!touched} primary>
          Save
        </Button>
      </div>
    );
  }
}
