import React from 'react';
import { Button } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { useClassName } from 'common/hooks';
import { ContentfulImage, SVGIcon } from 'common/components';

import { NewCheckoutBasketItem } from '../../../Components/BasketItem';

import './admission.less';
import PropTypes from 'prop-types';

const Admission = ({
  image,
  date,
  title,
  session,
  price,
  quantity,
  total,
  onChangeQuantity,
  max,
  min,
  onEdit,
}) => {
  const classNames = useClassName('BirthdayReviewAdmission');

  return (
    <>
      <h2 className={classNames('title')}>Package</h2>
      <div className={classNames('container')}>
        <div className={classNames('header')}>
          <div className={classNames('header-image')}>
            <ContentfulImage field={image} width={244} />
          </div>
          <div className={classNames('header-info')}>
            <div className={classNames('header-date')}>
              <SVGIcon name="date" size="tiny" />{' '}
              {DateTime.fromISO(date).toFormat('LLL dd, yyyy')}
            </div>
            <span className={classNames('header-title')}>{title}</span>
            <span className={classNames('header-session')}>{session}</span>
          </div>
          <Button className={classNames('header-edit')} onClick={onEdit}>
            Edit
          </Button>
        </div>
        <div className={classNames('list')}>
          <NewCheckoutBasketItem
            name="Guests"
            index={0}
            price={price}
            quantity={quantity}
            total={total}
            onChangeQuantity={(quantity) => onChangeQuantity(quantity)}
            max={max}
            min={min}
            blockRemove
          />
        </div>
      </div>
    </>
  );
};

Admission.propTypes = {
  image: PropTypes.object,
  date: PropTypes.string,
  title: PropTypes.string,
  session: PropTypes.string,
  price: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  onEdit: PropTypes.func,
};

export default Admission;
