import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClassName } from 'common/hooks';
import Template from './components/Template';
import Modal from './components/Modal';
import Button from './components/Button';
import abmgLogo from './assets/abmg/logo.png';
import blmgLogo from './assets/blmg/logo.png';
import { setCookie } from 'utils/helpers/cookie';
import './home.less';

const COOKIE_NAME = 'miniGolfScore';

const Home = (props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const { slug } = props.match.params;
  const className = useClassName('Home');
  const history = useHistory();
  const isAngryBirds = slug.includes('angry-birds');

  const handleCtaClick = () => {
    if (!termsAccepted) return setShowTermsConditions(true);
    setCookie(
      COOKIE_NAME,
      JSON.stringify(
        {
          termsAccepted: true,
        },
        300
      )
    );
    history.push(`/venue/${slug}/score-card/players`);
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setShowTermsConditions(false);
  };

  return (
    <Template venueName={slug}>
      <div className={className('container')}>
        <div className={className('logo-container')}>
          <img
            className={className('logo')}
            alt={slug}
            src={isAngryBirds ? abmgLogo : blmgLogo}
          />
        </div>
        <p className={className('title')}>
          18 holes full of twists and challenges.
        </p>
        <p className={className('description')}>
          You and up to 5 other players will take your mini-golf game to
          one-of-a-kind experience.
        </p>
      </div>
      <Button
        label="Select players"
        handleClick={handleCtaClick}
        primary
        className={className([
          'cta',
          `cta-${slug}`,
          `cta-${termsAccepted ? 'enabled' : 'disabled'}`,
        ])}
      />
      <Modal
        isOpen={showTermsConditions}
        title={'Terms and conditions'}
        description={
          'Please read and accept terms and conditions in order to use this application.'
        }
        venueName={slug}
        handleClose={() => setShowTermsConditions(false)}>
        <div className={className('ctas-container')}>
          <Button
            label="Read"
            handleClick={() =>
              window.open(`${window.location.origin}/terms`, '_blank')
            }
            venueName={slug}
          />
          <Button
            label="Accept and continue"
            handleClick={handleAcceptTerms}
            primary
            venueName={slug}
          />
        </div>
      </Modal>
    </Template>
  );
};

export default Home;
