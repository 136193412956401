import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

import {
  useClassName,
  LazyVisible,
  Only,
  preloadMedia,
  usePreload,
  preloadAssets,
  preloadCTAs,
  useWindowSize,
} from 'common/hooks';

import HomepageButton from '../../Components/Button';
import { usePagination } from '../../Components/usePagination';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import './passes.less';

export default function PassesSection({ section }) {
  const classNames = useClassName('PassesSection');
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  const ref = useRef();
  const { isDesktop } = useWindowSize();
  usePreload((isDesktop) => {
    preloadMedia(section.fields.media, isDesktop);
    preloadCTAs(section.fields.cta);
    preloadAssets(section.fields.icon);
  }, ref);

  return (
    <LazyVisible
      className={classNames('container')}
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <HomepageImage field={section.fields.icon} />
      <div className={classNames('header')}>
        <h1>{section.fields.title}</h1>

        <Only desktop>
          <HomepageButton
            cta={section.fields.cta}
            className={classNames('cta')}
          />
        </Only>
      </div>
      <div className={classNames('content')}>
        <Swiper
          modules={[Pagination]}
          height={542}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1128: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
          }}
          className={classNames('wrapper')}
          {...pagination}
          onSlideChange={({ activeIndex }) =>
            trackHomepageSlide(() => ({
              activeIndex,
              name: section.fields.media[activeIndex].fields.name,
              section: section.fields.sectionId,
            }))
          }>
          {section.fields.media
            .filter((_, index) => (isDesktop ? index < 4 : true))
            .map(({ fields, sys }) => (
              <SwiperSlide
                key={sys.id}
                className={classNames('slide-container')}>
                <div key={sys.id} className={classNames('slide')}>
                  <HomepageImage
                    field={fields.desktop}
                    mobile={fields.mobile}
                  />
                  <div>
                    <h4>{fields.title}</h4>
                    <p>{fields.description}</p>
                    <HomepageButton
                      cta={fields.callToAction}
                      className={classNames('cta')}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <Only mobile tablet>
        <HomepageButton
          cta={section.fields.cta}
          className={classNames('cta')}
        />
      </Only>
    </LazyVisible>
  );
}
