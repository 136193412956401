import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';
import { FieldContainer } from 'public/components/FieldContainer';

import './quantity-controls.less';

const QuantityControls = ({
  quantity,
  onChange,
  max,
  min,
  className,
  editable,
  exceptions,
  blockDecrease,
}) => {
  const [value, setValue] = useState(quantity);
  const classNames = useClassName('QuantityControls');

  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const canIncrease = value < max;

  const canDecrease = blockDecrease ? value > min : value > 0;

  const setQuantity = (newQuantity) => {
    const exception = exceptions?.[newQuantity];

    if (exception) {
      exception();
      return;
    }

    setValue(newQuantity);
    onChange(newQuantity);
  };

  const onClickIncrease = () => {
    if (!canIncrease) return;

    const newQuantity = Math.max(value + 1, min);

    setQuantity(newQuantity);
  };

  const onClickDecrease = () => {
    if (!canDecrease) return;

    const newQuantity = value - 1 < min ? 0 : value - 1;

    setQuantity(newQuantity);
  };

  const onBlur = () => {
    if (isNaN(value)) {
      onChange(min);
      setValue(min);
    } else if (value < min) {
      onChange(min);
      setValue(min);
    } else if (value > max) {
      onChange(max);
      setValue(max);
    }
  };

  const onChangeInput = (ev) => {
    const parsed = parseInt(ev.target.value);

    if (isNaN(parsed)) {
      setValue(null);
    } else {
      setValue(parsed);
    }
  };

  return (
    <div className={`${classNames('container')} ${className}`}>
      <div
        className={`${classNames('decrease')} ${
          !canDecrease && classNames('disabled')
        }`}
        onClick={onClickDecrease}>
        <SVGIcon name="minus-light" size="mini" />
      </div>
      {editable ? (
        <FieldContainer>
          <Input
            value={value}
            onChange={onChangeInput}
            type="text"
            pattern="[0-9]"
            onBlur={onBlur}
          />
        </FieldContainer>
      ) : (
        <span className={classNames('quantity')}>{value}</span>
      )}
      <div
        className={`${classNames('increase')} ${
          !canIncrease && classNames('disabled')
        }`}
        onClick={onClickIncrease}>
        <SVGIcon name="plus-light" size="mini" />
      </div>
    </div>
  );
};

QuantityControls.propTypes = {
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  className: PropTypes.string,
  editable: PropTypes.bool,
  exceptions: PropTypes.object,
  blockDecrease: PropTypes.bool,
};

QuantityControls.defaultProps = {
  min: 0,
  max: 999,
};

export default QuantityControls;
