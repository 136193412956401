import React from 'react';
import PropTypes from 'prop-types';

import { ContentfulImage } from 'common/components';
import { useClassName } from 'common/hooks';

import './header.less';

const Header = ({ title, venue, showLogo, className }) => {
  const classNames = useClassName('Header');

  return (
    <div className={`${classNames('container')} ${className}`}>
      <h1>{title}</h1>
      {showLogo && venue?.content?.horizontalImage && (
        <ContentfulImage field={venue.content.horizontalImage} width={144} />
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  venue: PropTypes.object,
  showLogo: PropTypes.bool,
  className: PropTypes.string,
};

Header.defaultProps = {
  showLogo: true,
};

export default Header;
