export const CONTACT_TOPIC_OPTIONS = [
  {
    text: 'Advertising With Us',
    value: 'advertising',
    emailTo: 'advertising@americandream.com',
  },
  {
    text: 'Careers',
    value: 'careers',
    emailTo: 'careers@americandream.com',
  },
  {
    text: 'Community',
    value: 'community',
    emailTo: 'community@americandream.com',
  },
  {
    text: 'Corporate Partners',
    value: 'sponsorship',
    emailTo: 'sponsorship@americandream.com',
  },
  {
    text: 'Groups & Charter Buses',
    value: 'groups',
    emailTo: 'groups@americandream.com',
    sendToSalesForce: true,
    leadType: 'Group Sales',
  },
  {
    text: 'Guest Services',
    value: 'customer',
    emailTo: 'guestservices@americandream.com',
  },
  {
    text: 'Leasing Opportunities',
    value: 'leasing',
    emailTo: 'lease.inquiries@americandream.com',
    sendToSalesForce: true,
    leadType: 'Leasing',
  },
  {
    text: 'Others',
    value: 'other',
    emailTo: 'guestservices@americandream.com',
  },
  {
    text: 'Press & Media Inquiries',
    value: 'pr',
    emailTo: 'pr@americandream.com',
  },
];
