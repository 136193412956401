import React, { useRef, useState } from 'react';
import { useClassName } from 'common/hooks';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

import { RefTabs } from 'public/components/Tabs';
import { getContentfulField } from 'common/components';
import { getCurrentLocaleCode } from 'utils/l10n';

import { NewCheckoutProductOption } from '../../../Components/ProductOption';
import { ProductWithVariants } from '../../ProductWithVariants';
import { isOptionSoldOut } from '../../utils';
import { NewCheckoutVariantsModal } from '../../BirthdayFoodAndBeverage/VariantsModal';

import './birthday-entertainment-selector.less';

const BirthdayEntertainmentSelector = ({
  categories,
  quantities,
  onChangeQuantity,
  handleAddOnDetail,
  date,
}) => {
  const classNames = useClassName('BirthdayEntertainmentSelector');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const refs = useRef([]);
  const hasCategories = categories?.length > 1;

  return (
    <>
      {hasCategories && (
        <RefTabs fixed names={categories.map((a) => a.name)} refs={refs} />
      )}
      <NewCheckoutVariantsModal
        isOpen={Boolean(selectedProduct)}
        product={selectedProduct}
        setOpen={setSelectedProduct}
        setQuantities={onChangeQuantity}
        quantities={quantities}
        isOptionSoldOut={(option) =>
          isOptionSoldOut(
            option,
            categories
              .map((category) => category.products)
              .flat()
              .map((product) => product.products)
              .flat()
          )
        }
      />
      <div className={classNames('list')}>
        {categories.map((category, i) => (
          <div key={i} ref={(el) => (refs.current[i] = el)}>
            {hasCategories && <h2>{category.name}</h2>}
            {category.products.map((product, key) => {
              if (product.products.length > 1) {
                return (
                  <ProductWithVariants
                    key={key}
                    product={product}
                    onClick={setSelectedProduct}
                    setQuantities={onChangeQuantity}
                    quantities={quantities}
                    large
                    onDetail={handleAddOnDetail}
                  />
                );
              }

              const prod = product.products[0];
              return (
                <NewCheckoutProductOption
                  key={key}
                  images={getContentfulField(product.images).map((a) => ({
                    contentfulField: { [getCurrentLocaleCode(false)]: a },
                  }))}
                  name={product.name}
                  price={prod.cost * 100}
                  description={product.description}
                  contentfulDescription
                  isSoldOut={prod.maxQuantity === 0}
                  date={date}
                  quantity={quantities[prod.id] || 0}
                  onChangeQuantity={(quantity) =>
                    onChangeQuantity({ ...quantities, [prod.id]: quantity })
                  }
                  onDetail={() => handleAddOnDetail(product)}
                  largeImage
                  {...(isNumber(prod.maxQuantity) && { max: prod.maxQuantity })}
                />
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};

BirthdayEntertainmentSelector.propTypes = {
  categories: PropTypes.array,
  quantities: PropTypes.object,
  onChangeQuantity: PropTypes.func,
  handleAddOnDetail: PropTypes.func,
  date: PropTypes.string,
};

export default BirthdayEntertainmentSelector;
