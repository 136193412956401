import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Filters from './Filters';
import Instructions from './Instructions';
import './directions.less';
import redCar from 'common/assets/red-car.svg';
import taxi from 'common/assets/taxi.svg';
import bus from 'common/assets/bus.svg';

const FILTERS = {
  driving: {
    name: 'Driving',
    image: redCar,
  },
  rideshare: {
    name: 'Rideshare',
    image: taxi,
  },
  publicTransportation: {
    name: 'Public Transportation',
    image: bus,
  },
};

const Directions = ({ hoursPage }) => {
  const className = useClassName('Directions');
  const [filter, setFilter] = useState('driving');
  const [contentHeight, setContentHeight] = useState(0);

  const instructions = {
    driving: hoursPage.drivingInstructions,
    rideshare: hoursPage.rideshareInstructions,
    publicTransportation: hoursPage.publicTransportationInstructions,
  };

  const instructionsRefs = Object.keys(instructions).reduce((acc, cur) => {
    acc[cur] = useRef();
    return acc;
  }, {});

  const handleContentResize = () => {
    setContentHeight(instructionsRefs[filter]?.current?.clientHeight);
  };

  useEffect(() => {
    handleContentResize();
  }, [filter]);

  return (
    <div className={className('container')}>
      <h3 className={className('title')}>{hoursPage.directionsTitle}</h3>
      <Filters
        filters={FILTERS}
        filter={filter}
        centered={true}
        handleFilterChange={(newFilter) => setFilter(newFilter)}
      />
      <div
        className={className('content')}
        style={{ minHeight: contentHeight }}>
        {Object.keys(instructions).map((instructionKey) => (
          <div
            key={instructionKey}
            className={className([
              'instructions',
              `instructions-${
                filter === instructionKey ? 'visible' : 'hidden'
              }`,
            ])}>
            <Instructions
              instructionKey={instructionKey}
              instructionRef={instructionsRefs[instructionKey]}
              instructions={instructions[instructionKey]}
              isVisible={filter === instructionKey}
              handleResize={handleContentResize}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

Directions.propTypes = {
  hoursPage: PropTypes.object.isRequired,
};

export default Directions;
