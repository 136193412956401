import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Container, Button, Loader } from 'semantic-ui-react';
import { Screen } from 'public/helpers';
import { TicketSelector, Spacer } from 'public/components';

@inject('venues', 'cart', 'notifications')
@observer
export default class CartUpdate extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    const { slug } = this.props.match.params;
    await this.props.venues.fetchItemBySlug(slug);
    this.setState({
      loading: false,
    });
  }

  onSubmit = (tickets, action) => {
    if (action !== 'cancel') {
      const reservationDate = this.props.match.params.date;
      const results = tickets.map((ticket) => {
        const { ticketOptionId } = ticket;
        return this.props.cart.updateTicket(
          {
            ticketOptionId,
            reservationDate,
          },
          ticket
        );
      });
      if (results.some((r) => r)) {
        // this.props.notifications.addSuccess('Updated your order.');
      }
    }
    this.setState({
      redirect: '/cart',
    });
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    } else if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    }
    const { slug, date } = this.props.match.params;
    const venue = this.props.venues.get(slug);
    return (
      <Container>
        <Spacer size="s" />
        <TicketSelector
          venue={venue}
          onSubmit={this.onSubmit}
          renderActions={this.renderActions}
          reservationDate={date}
          loadCartQuantities
          allowZero
        />
        <Spacer size="m" />
      </Container>
    );
  }

  renderActions = (canSubmit) => {
    return (
      <React.Fragment>
        <Button disabled={!canSubmit} name="cancel">
          Cancel
        </Button>
        <Button disabled={!canSubmit} name="update" primary>
          Update
        </Button>
      </React.Fragment>
    );
  };
}
