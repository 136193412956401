import { DateTime } from 'luxon';

const normalize = (text) => {
  return text
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
};

const createRoute = (str) => {
  return str
    .replaceAll(/[^0-9a-z\s+]/gi, '')
    .replace(/ +/g, '-')
    .trim()
    .toLowerCase();
};

const isPast = (schedule) => {
  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  return today > schedule.endDate;
};

const getSchedule = (event) => {
  const schedules = event.schedules.map((a) => ({
    ...a,
    startDate: DateTime.fromFormat(a.startDate, 'yyyy-MM-dd', {
      zone: 'America/New_York',
    }),
    endDate: DateTime.fromFormat(a.endDate, 'yyyy-MM-dd', {
      zone: 'America/New_York',
    }),
  }));

  return (
    schedules.find(
      (schedule, index) => !isPast(schedule) || index === schedule.length - 1
    ) || schedules[schedules.length - 1]
  );
};

export { getSchedule, isPast, createRoute, normalize };
