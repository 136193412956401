import React from 'react';
import { inject } from 'mobx-react';

import Basket from './Basket';
import CartView from '../../Cart/View';

@inject('betaFeatures')
export default class BasketScreenContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCheckoutAvailable: null,
    };
  }

  componentDidMount() {
    this.props.betaFeatures
      .fetch('Checkout Redesign')
      .then((isEnabled) => this.setState({ newCheckoutAvailable: isEnabled }));
  }

  render() {
    const { newCheckoutAvailable } = this.state;

    if (newCheckoutAvailable === null) return null;
    if (newCheckoutAvailable) return <Basket {...this.props} />;
    if (!newCheckoutAvailable) return <CartView {...this.props} />;
  }
}
