import React, { useEffect, useState } from 'react';
import { Button, Form, Loader, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { DateTime } from 'luxon';

import { useClassName, useWindowSize } from 'common/hooks';
import { getContentfulField, ContentfulRichText } from 'common/components';
import { parseTextBlock } from 'public/helpers/contentful/parser';
import Asset from './Asset';
import TextBlock from './TextBlock';
import { isValidEmail, isValidPhone } from 'utils/helpers/validations';
import { identify, track } from 'utils/analytics';
import {
  defaultProps,
  propTypes,
} from 'public/screens/PrivateEvents/PrivateEventsProps';
import { DEFAULT_GROUP_ID, SMS_GROUP_ID } from 'common/const';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { FieldContainer } from 'public/components/FieldContainer';
import PhoneNumberInput from 'public/components/Auth/PhoneNumberInput';
import { request } from 'utils/api';

import { Spacer } from '../Spacer';
import Select from './Select';
import ModularDatePicker from './DatePicker';
import MultiSelect from './MultiSelect';
import Checkbox from './Checkbox';

import './modular-form.less';

const DEFAULT_SUCCESS_MESSAGE =
  'Thank you for submitting the group request form! Our dream team will be in touch within 48 hours';

const getConditions = (form) => {
  const conditions =
    form.fields.conditions && getContentfulField(form.fields.conditions);

  return (conditions || []).map(({ fields }) => {
    return {
      fieldName: getContentfulField(
        getContentfulField(fields.field).fields.name
      ),
      values: getContentfulField(fields.values),
      sendToEmails: getContentfulField(fields.sendToEmails),
      sendToSalesforce: getContentfulField(fields.sendToSalesforce),
      formData: getContentfulField(fields.formData),
    };
  });
};

const getMatchingCondition = (form, data) => {
  const conditions = getConditions(form);
  if (!conditions?.length) return null;

  const formFields = getFormFields(form);
  const fields = Object.fromEntries(
    formFields
      .map(({ name, value }) => [name, value || data[name]])
      .filter(([key, value]) => key && value)
  );

  return conditions.find((condition) => {
    const value = fields[condition.fieldName];
    if (!value) return false;

    const formField = formFields.find((a) => a.name === condition.fieldName);
    if (!formField) return false;

    const index = formField.options.indexOf(value);
    return condition.values.includes(
      index === -1 ? value : formField.optionValues[index]
    );
  });
};

const getFormFields = (form) => {
  const fields = getContentfulField(form.fields.fields);

  return fields.map(({ fields }) => {
    return {
      label: getContentfulField(fields.label),
      placeholder: getContentfulField(fields.placeholder),
      type: getContentfulField(fields.type),
      name: getContentfulField(fields.name),
      salesforceName: getContentfulField(fields.salesforceName),
      brazeName: getContentfulField(fields.brazeName),
      isBrazeSubscription: getContentfulField(fields.isBrazeSubscription),
      value: getContentfulField(fields.value),
      required: getContentfulField(fields.required),
      options: getContentfulField(fields.options),
      optionValues: getContentfulField(fields.optionValues),
      checkboxContent: fields.checkboxContent,
      checkedByDefault: getContentfulField(fields.checkedByDefault),
    };
  });
};

const getFieldValidations = (form) => {
  return getFormFields(form).reduce((validations, field) => {
    return {
      ...validations,
      [field.name]: [
        ...(field.required
          ? [
              {
                isInvalid: (fields) =>
                  field.type === 'multiselect'
                    ? !fields?.[field.name]?.length
                    : !fields[field.name],
                message: `${field.label} is required`,
              },
            ]
          : []),
        ...(field.type === 'email'
          ? [
              {
                isInvalid: (fields) => !isValidEmail(fields[field.name]),
                message: `Please provide a valid ${field.label}`,
              },
            ]
          : []),
        ...(field.type === 'phone'
          ? [
              {
                isInvalid: (fields) => !isValidPhone(fields[field.name]),
                message: `Please provide a valid ${[field.label]}`,
              },
            ]
          : []),
      ],
    };
  }, {});
};

const getInitialFormData = (form) => {
  const fields = getFormFields(form);

  return Object.fromEntries(
    fields
      .filter((field) => field.type === 'checkbox' && field.checkedByDefault)
      .map((field) => [field.name, true])
  );
};

const ModularForm = (props) => {
  const { form, brazeTriggers, subscriptions, id, background } = props;
  const [currentFocus, setCurrentFocus] = useState(null);
  const [formData, setFormData] = useState(() => getInitialFormData(form));
  const [fieldErrors, setFieldErrors] = useState({});
  const { isMobile } = useWindowSize();
  const className = useClassName('ModularForm');
  const [textBlock, setTextBlock] = useState(null);
  const [textCopies, setTextCopies] = useState([]);
  const [brazeCustomTriggers, setBrazeCustomTriggers] = useState(brazeTriggers);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (form) {
      const textBlock =
        form?.fields?.textBlock &&
        parseTextBlock(getContentfulField(form.fields.textBlock));
      const textCopiesValues =
        form?.fields?.textCopies && getContentfulField(form.fields.textCopies);
      const brazeCustomAttribute =
        form?.fields?.brazeCustomAttribute &&
        getContentfulField(form.fields.brazeCustomAttribute);
      const brazeCustomEvent =
        form?.fields?.brazeCustomEvent &&
        getContentfulField(form.fields.brazeCustomEvent);
      const textCopies = textCopiesValues?.map(({ fields }) => {
        return {
          key: getContentfulField(fields?.key),
          value: getContentfulField(fields?.value),
        };
      });
      setTextBlock(textBlock);
      setTextCopies(textCopies);
      if (brazeCustomAttribute || brazeCustomEvent) {
        setBrazeCustomTriggers({
          ...brazeTriggers,
          ...(brazeCustomAttribute && {
            customAttributes: { [brazeCustomAttribute]: 'yes' },
          }),
          ...(brazeCustomEvent && { customEvent: brazeCustomEvent }),
        });
      }
    }
  }, []);

  const createSubscription = async (user, group) =>
    await subscriptions.create({ ...user, group });

  const getTextCopy = (key, defaultValue) => {
    const textCopy = textCopies?.find((textCopy) => textCopy.key === key);
    return textCopy?.value || defaultValue;
  };

  const sendToSalesforce = async () => {
    const condition = getMatchingCondition(form, formData);
    const shouldSend = condition
      ? condition.sendToSalesforce
      : getContentfulField(form.fields.sendToSalesforce);
    if (!shouldSend) return;

    const fields = Object.fromEntries([
      ...getFormFields(form)
        .map(({ salesforceName, name, value, type }) => {
          if (value) {
            return [salesforceName, value];
          }

          if (type === 'date' && formData[name]) {
            return [
              salesforceName,
              DateTime.fromISO(formData[name]).toFormat('MM/dd/yyyy'),
            ];
          }

          return [salesforceName, formData[name]];
        })
        .filter(([key, value]) => key && value),
      ...(condition?.formData ? Object.entries(condition.formData) : []),
    ]);

    if (!Object.keys(fields).length) return;

    const body = Object.keys(fields)
      .map((key) => `${key}=${fields[key]}`)
      .join('&');

    return fetch(
      'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      {
        method: 'POST',
        mode: 'no-cors',
        body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
  };

  const sendToEmails = async () => {
    const condition = getMatchingCondition(form, formData);
    const sendEmailTo = condition
      ? condition.sendToEmails
      : getContentfulField(form.fields.sendEmailTo);

    if (!sendEmailTo?.length) return;

    const fields = Object.fromEntries(
      getFormFields(form)
        .filter(({ type }) => type !== 'hidden')
        .map(({ label, name, value }) => [label, value || formData[name]])
        .filter(([key, value]) => key && value)
    );

    if (!Object.keys(fields).length) return;

    for (const to of sendEmailTo) {
      await request({
        method: 'POST',
        path: '/1/forms/notify/',
        body: {
          fields: { ...fields, ...(condition?.formData || {}) },
          name: getContentfulField(form.fields.name),
          to,
        },
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate(null, {})) return;
    setLoading(true);

    Promise.all([
      sendToSalesforce(),
      sendToBraze(),
      storeSubmission(),
      sendToEmails(),
    ])
      .then(() => {
        setMessage({
          type: 'success',
          body: getTextCopy('successMessage', DEFAULT_SUCCESS_MESSAGE),
        });
      })
      .catch((err) => {
        console.error(err);
        setMessage({
          type: 'error',
          body: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendToBraze = async () => {
    const fields = Object.fromEntries(
      getFormFields(form)
        .map(({ brazeName, name, value }) => [
          brazeName,
          value || formData[name],
        ])
        .filter(([key, value]) => key && value)
    );

    if (!Object.keys(fields).length) return;

    identify({
      ...fields,
      ...brazeCustomTriggers.customAttributes,
    });
    track(brazeCustomTriggers.customEvent);

    if (fields.emailAccepted) {
      createSubscription(fields, DEFAULT_GROUP_ID);
    }

    if (fields.smsAccepted) {
      createSubscription(fields, SMS_GROUP_ID);
    }

    const subscriptions = getFormFields(form)
      .filter((field) => field.isBrazeSubscription)
      .map(({ name, options, optionValues }) => {
        return options
          .map((option, index) => {
            if (!formData[name]?.includes(option)) return null;

            return optionValues[index];
          })
          .filter(Boolean);
      })
      .flat();

    if (subscriptions?.length) {
      await Promise.all(
        subscriptions.map((group) => createSubscription(fields, group))
      );
    }
  };

  const storeSubmission = async () => {
    try {
      await request({
        method: 'POST',
        path: '/1/forms/submission/',
        body: {
          fields: formData,
          pathname: location.pathname,
        },
      });
    } catch {
      // DO NOTHING
    }
  };

  const validate = (fieldName, { value }) => {
    const fields = Object.fromEntries(
      getFormFields(form).map(({ name }) => {
        return [name, value || formData[name]];
      })
    );

    const validations = getFieldValidations(form);

    const newFieldErrors = Object.keys(fields)
      .filter((key) => !fieldName || fieldName === key)
      .reduce((fieldErrors, key) => {
        const fieldValidations = validations[key];
        if (!fieldValidations?.length) return fieldErrors;

        const error = fieldValidations.find((a) => a.isInvalid(fields));
        return {
          ...fieldErrors,
          [key]: error ? error.message : null,
        };
      }, {});

    const errors = {
      ...fieldErrors,
      ...newFieldErrors,
    };

    setFieldErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const renderAsset = () => {
    if (!textBlock?.asset) return null;
    return <Asset asset={textBlock.asset} />;
  };

  const renderTextBlock = () => {
    if (!textBlock) return null;
    const renderTextBlock = {
      ...textBlock,
      asset: null,
    };
    return <TextBlock textBlock={renderTextBlock} />;
  };

  const renderCTA = () => {
    if (loading) return <Loader active inline />;
    else if (message) return renderMessage();
    return (
      <Button type="submit" className={className('submit-button')} inverted>
        Submit Form
      </Button>
    );
  };

  const renderMessage = () => {
    if (!message) return null;
    return (
      <div className={className('message-wrapper')}>
        <Message
          positive={message.type === 'success'}
          negative={message.type === 'error'}>
          {message.body}
        </Message>
      </div>
    );
  };

  const getFocusListeners = (fieldName) => {
    return {
      onBlur: (value) => {
        setCurrentFocus(null);
        validate(fieldName, value);
      },
      onFocus: () => setCurrentFocus(fieldName),
    };
  };

  const hasFocus = (fieldName) => currentFocus === fieldName;

  const renderField = ({
    label,
    type,
    name,
    value,
    required,
    options,
    placeholder,
    checkboxContent,
  }) => {
    const fieldStyle = {
      width: isMobile ? '100%' : '48%',
      marginBottom: '32px',
    };

    if (type === 'hidden') {
      return <input key={name} type={type} name={name} value={value} />;
    }

    if (['text', 'number', 'email'].includes(type)) {
      return (
        <ControlledInputContainer
          key={name}
          required={required}
          label={
            <label htmlFor={name}>{`${label}${required ? ' *' : ''}`}</label>
          }
          input={
            <input
              value={formData[name]}
              id={name}
              name={name}
              type={type}
              placeholder={placeholder}
              onChange={(evt) =>
                setFormData({ ...formData, [name]: evt.target.value })
              }
              {...getFocusListeners(name)}
            />
          }
          hasFocus={hasFocus(name)}
          error={fieldErrors[name]}
          style={fieldStyle}
        />
      );
    }

    if (type === 'phone') {
      return (
        <FieldContainer style={fieldStyle} key={name}>
          <label htmlFor={name}>{`${label}${required ? ' *' : ''}`}</label>
          <PhoneNumberInput
            onChange={(value) => setFormData({ ...formData, [name]: value })}
            id={name}
            name={name}
            {...getFocusListeners(name)}
            error={fieldErrors[name]}
            placeholder={placeholder}
          />
        </FieldContainer>
      );
    }

    if (type === 'select') {
      return (
        <FieldContainer style={fieldStyle} key={name}>
          <label htmlFor={name}>{`${label}${required ? ' *' : ''}`}</label>
          <Select
            items={options}
            error={fieldErrors[name]}
            setSelectedItem={(item) =>
              setFormData({ ...formData, [name]: item })
            }
            selectedItem={formData[name]}
            {...getFocusListeners(name)}
            placeholder={placeholder}
          />
        </FieldContainer>
      );
    }

    if (type === 'multiselect') {
      return (
        <FieldContainer style={fieldStyle}>
          <label htmlFor={name}>{`${label}${required ? ' *' : ''}`}</label>
          <MultiSelect
            title={`${label}${required ? ' *' : ''}`}
            items={options}
            error={fieldErrors[name]}
            setSelectedItems={(items) => {
              setFormData({ ...formData, [name]: [...items] });
            }}
            selectedItems={[...(formData[name] || [])]}
            {...getFocusListeners(name)}
            placeholder={placeholder}
          />
        </FieldContainer>
      );
    }

    if (type === 'date') {
      return (
        <FieldContainer
          style={fieldStyle}
          {...getFocusListeners(name)}
          key={name}>
          <label htmlFor={name}>{`${label}${required ? ' *' : ''}`}</label>
          <ModularDatePicker
            date={formData[name]}
            setDate={(date) => setFormData({ ...formData, [name]: date })}
            error={fieldErrors[name]}
            {...getFocusListeners(name)}
            placeholder={placeholder}
          />
        </FieldContainer>
      );
    }

    if (type === 'checkbox') {
      return (
        <Checkbox
          name={name}
          error={fieldErrors[name]}
          checked={formData[name] || false}
          label={<ContentfulRichText field={checkboxContent} />}
          onChange={(e) =>
            setFormData({ ...formData, [name]: e.target.checked })
          }
        />
      );
    }
  };

  const renderForm = () => {
    const fields = getFormFields(form);

    return (
      <Form noValidate onSubmit={handleSubmit} className={className('form')}>
        {fields.map((field) => renderField(field))}
        {renderCTA()}
      </Form>
    );
  };

  return (
    <div
      className={className('container')}
      id={id}
      {...(background && {
        style: { background },
      })}>
      <div className={className('content')}>
        <div className={className('asset')}>{renderAsset()}</div>
        <div className={className('form-container')}>
          {renderTextBlock()}
          <Spacer size="m" />
          {renderForm()}
        </div>
      </div>
    </div>
  );
};

ModularForm.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  brazeTriggers: propTypes.brazeTriggers,
  background: propTypes.object,
};

ModularForm.defaultProps = {
  brazeTriggers: defaultProps.brazeTriggers,
  id: 'private-events-form',
};

export default inject('subscriptions')(ModularForm);
