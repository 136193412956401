import React from 'react';
import { inject } from 'mobx-react';
import { Grid, Container, Loader } from 'semantic-ui-react';
import {
  ContentfulPlainText,
  ContentfulRichText,
  getContentfulField,
} from 'common/components';
import { Spacer, HorizontalRowCard, Hero } from 'public/components';
import { NotFound } from '../NotFound';
import { Screen } from 'public/helpers';

import './community.less';

@inject('pages', 'events')
export default class Community extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      events: null,
      error: null,
      loading: true,
    };
  }

  routeDidUpdate() {
    this.fetch();
  }

  async fetch() {
    this.setState({
      loading: true,
    });
    try {
      const page = await this.props.pages.fetchItemBySlug('community');
      /*
       * Disabling events for now
      const [page, events] = await Promise.all([
        this.props.pages.fetchItemBySlug('community'),
        this.props.events.fetchItems({
          category: 'Community',
          sort: {
            order: 'desc',
            field: 'endDate',
          },
        }),
      ]);
      */
      this.setState({
        page,
        loading: false,
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          loading: false,
          error,
        });
      } else {
        throw error;
      }
    }
  }

  getHeroImages() {
    const { page } = this.state;
    const hero = page.object.fields.hero;
    if (!hero) return;
    return hero['en-US']
      .map((f) => {
        const { url, details } = f.fields.file['en-US'];
        let ratio;
        if (details.image) {
          ratio = details.image.width / details.image.height;
        } else {
          ratio = 1.3333;
        }
        return {
          url,
          ratio,
        };
      })
      .filter((url) => url);
  }

  renderBody() {
    const { page, error } = this.state;
    if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    } else if (error) {
      return <NotFound {...this.props} />;
    }
    return (
      <div {...this.getAttrs()}>
        {this.renderHero()}
        <Container className="small">
          <Spacer size="s" />
          <div className={this.getElementClass('body')}>
            <ContentfulRichText field={page.object.fields.body} />
          </div>
        </Container>
        <Container>
          {/*
          <Spacer size="m" className={this.getElementClass('divider')} />
          <Spacer size="m" />
          <Headline title="Events" subtitle="" />
          <Spacer size="s" />
          <Grid columns={1} stackable>
            {this.renderEvents()}
          </Grid>
          */}
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderEvents() {
    const { events } = this.state;
    return events.map((event) => {
      const { fields } = event.object;
      if (fields.venue) {
        const venue = getContentfulField(fields.venue);
        location = <ContentfulPlainText field={venue.fields.name} />;
      }
      return (
        <Grid.Column key={event.id} className={this.getElementClass('item')}>
          <HorizontalRowCard
            href={`/event/${event.slug}`}
            title={<ContentfulPlainText field={fields.title} />}
            date={<ContentfulPlainText field={fields.displayDate} />}
            location={<ContentfulPlainText field={fields.displayLocation} />}
          />
        </Grid.Column>
      );
    });
  }

  renderHero() {
    const heroImages = this.getHeroImages();
    if (heroImages && heroImages.length) {
      const [main, fallback] = heroImages;
      return <Hero media={main} fallback={fallback} />;
    }
  }
}
