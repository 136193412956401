import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import {
  trackProductViewed,
  validateInventory,
  ticketSalesClosed,
  getQuantity,
} from 'common/helpers';
import { isDateChangeFee } from 'public/helpers';
import { formatApiDate, parseApiDate } from 'utils/api';

const getSessions = (venue, reservationDate, ticketInventory) => {
  const sessions = ticketInventory.getSessions(venue.id, reservationDate);
  const sortedSessions = sessions?.sort(
    (a, b) =>
      Number(a.startTime.replace(':', '')) -
      Number(b.startTime.replace(':', ''))
  );
  return sortedSessions;
};

const addCurrentTicketsToInventory = (
  inventory,
  sessions,
  currentTickets,
  reservationDate
) => {
  const currentTicketsDate = currentTickets[0]?.ticketOption?.date;

  if (currentTicketsDate !== formatApiDate(reservationDate)) return inventory;

  const currentTicketsStartTime = currentTickets[0]?.ticketOption?.startTime;
  const totalSessionTickets =
    currentTicketsStartTime &&
    currentTickets
      .filter((ticket) => !ticket.ticketOption.addon)
      .reduce((acc, ticket) => (acc += ticket.ticketOption.quantity), 0);
  const currentSessionTotal =
    (currentTicketsStartTime &&
      sessions.find((session) => session.startTime === currentTicketsStartTime)
        ?.capacityRemaining) ||
    0;
  const newSessionTotal =
    currentTicketsStartTime && currentSessionTotal + totalSessionTickets;

  return inventory.map((inventoryItem) => {
    const currentTicket = currentTickets.find(
      (ticket) =>
        ticket.ticketOption.bookingItemInventoryId ===
        inventoryItem.ticketOptionId
    );

    if (!currentTicket) return inventoryItem;

    const inventoryItemQuantity = inventoryItem.quantity;

    const newQuantity =
      typeof inventoryItemQuantity === 'number'
        ? inventoryItemQuantity + currentTicket.ticketOption.quantity
        : {
            ...inventoryItemQuantity,
            [currentTicketsStartTime]: !inventoryItemQuantity[
              currentTicketsStartTime
            ]
              ? null
              : newSessionTotal,
          };

    return {
      ...inventoryItem,
      quantity: newQuantity,
    };
  });
};

export const fetchInventory = async (
  venue,
  reservationDate,
  ticketInventory,
  forceRefresh = false
) => {
  try {
    if (ticketInventory.get(venue.id, reservationDate) && !forceRefresh) {
      return {
        sessions: getSessions(venue, reservationDate, ticketInventory),
        error: null,
        loading: false,
      };
    }

    await ticketInventory.search({
      venueId: venue.id,
      date: reservationDate,
      slug: venue.slug,
      forceRefresh: true,
    });

    ticketInventory
      .get(venue.id, reservationDate)
      .filter((product) => !product.addon)
      .forEach((inventoryItem) => {
        trackProductViewed(inventoryItem, venue);
      });

    return {
      sessions: getSessions(venue, reservationDate, ticketInventory),
      error: null,
    };
  } catch (err) {
    return { sessions: null, error: err };
  }
};

export const getQuantities = (tickets) => {
  const quantities = {};
  tickets.forEach((ticket) => {
    const { ticketOptionId, quantity, startTime, addon } = ticket.ticketOption;
    quantities[ticketOptionId] =
      startTime && !addon ? { startTime, quantity } : quantity;
  });
  return quantities;
};

export const validateTickets = ({
  reservationDate,
  venue,
  quantities,
  ticketInventory,
  currentTickets,
}) => {
  const inventory = getInventory(
    reservationDate,
    ticketInventory,
    venue.id,
    currentTickets
  );
  if (ticketSalesClosed(venue, reservationDate)) {
    return {
      canSubmit: false,
      errorMessage: 'Please select a date in the future',
      errorTitle: 'Same-day ticket sales are now closed.',
    };
  }
  return validateInventory(inventory, quantities, venue);
};

export const validateBundleTickets = ({ newTickets, bundles }) => {
  const productsAvailability = bundles.productAvailability;
  for (const newTicket of newTickets) {
    const { bundle, venue } = newTicket;

    if (!bundle) continue;

    const { bookingItemId, date, quantity, name } = newTicket.ticketOption;

    if (isTicketExpired(date)) continue;

    if (ticketSalesClosed(venue, parseApiDate(date))) {
      return {
        canSubmit: false,
        errorMessage: 'Please select a date in the future',
        errorTitle: 'Same-day ticket sales are now closed.',
      };
    }
    const productAvailability = productsAvailability.get(
      `${bookingItemId}-${date}`
    );

    if (
      !productAvailability ||
      !productAvailability.available ||
      productAvailability.capacityRemaining < quantity
    ) {
      return {
        canSubmit: false,
        errorTitle: 'Not available',
        errorMessage: `${name} is not available`,
      };
    }
  }
  return {
    canSubmit: true,
  };
};

export const getInventory = (
  reservationDate,
  ticketInventory,
  venueId,
  currentTickets = null
) => {
  const inventory = ticketInventory.get(venueId, reservationDate);

  if (!currentTickets) return inventory;

  const sessions = ticketInventory.getSessions(venueId, reservationDate);

  return addCurrentTicketsToInventory(
    inventory,
    sessions,
    currentTickets,
    reservationDate
  );
};

export const getPriceDifference = (
  currentTickets,
  newTickets,
  addons = false
) => {
  const currentTotal = sumBy(
    currentTickets.filter((ticket) => {
      if (addons) return ticket.ticketOption.addon;
      return !ticket.ticketOption.addon;
    }),
    ({ ticketOption }) =>
      (ticketOption.priceDiscount + ticketOption.taxDiscount) *
      ticketOption.quantity
  );

  const newTotal = sumBy(
    newTickets,
    (ticket) =>
      (ticket.ticketOption.price + ticket.ticketOption.tax) *
      ticket.ticketOption.quantity
  );

  const difference = newTotal - currentTotal;

  if (difference <= 0) return 0;

  return difference;
};

export const getFeePaid = (currentTickets) => {
  return currentTickets.reduce(
    (fees, { ticketOption }) => {
      const itemFee = ticketOption.feeAmount * ticketOption.quantity || 0;

      return {
        ...fees,
        total: fees.total + itemFee,
        [ticketOption.ticketOptionId]:
          (fees[ticketOption.ticketOptionId] || 0) + itemFee,
      };
    },
    { total: 0 }
  );
};

export const getTotalChangeDateFee = (changeDateFeeTicket) => {
  if (!changeDateFeeTicket) return 0;

  return (
    (changeDateFeeTicket.ticketOption.price +
      changeDateFeeTicket.ticketOption.tax) *
    changeDateFeeTicket.ticketOption.quantity
  );
};

export const getTicketsCount = (quantities) => {
  return Object.values(quantities).reduce((acc, cur) => {
    if (typeof cur === 'object') {
      return acc + cur.quantity;
    }
    return acc + cur;
  }, 0);
};

export const getDateChangeFeeTicket = (
  reservationDate,
  ticketInventory,
  venueId
) => {
  const inventory = getInventory(reservationDate, ticketInventory, venueId);
  return inventory.find((item) => isDateChangeFee(item.name));
};

export const mapQuantitiesToTickets = (
  inventory,
  reservationDate,
  quantities,
  startTime = ''
) => {
  const newTickets = [];
  Object.keys(quantities).forEach((key) => {
    const ticket = inventory.find((product) => product.ticketOptionId === key);
    const quantity = getQuantity(ticket, quantities);
    newTickets.push({
      ticketOption: {
        ticketOptionId: ticket.ticketOptionId,
        ticketOptionName: ticket.name,
        startTime,
        description: ticket.description,
        bookingItemId: ticket.bookingItemId,
        price: ticket.price,
        quantity: quantity,
        tax: ticket.tax,
        date: formatApiDate(reservationDate),
        addon: ticket.addon,
        externalTicket: ticket.externalAddon,
        externalVenue: ticket.externalVenue,
        feeAmount: ticket.feeAmount,
      },
    });
  });
  return newTickets;
};

const cleanTicketName = (name) => {
  return name
    .toLowerCase()
    .replace(/ages/gi, 'age')
    .replace(/seniors/gi, 'senior')
    .replace(/tickets|ticket/gi, '')
    .replace(/admissions|admission/gi, '')
    .replace(/citizens|citizen/gi, '')
    .replace(/non-peak|peak/gi, '')
    .replace(/[^\w!?]/g, '');
};

export const isEquivalentTicket = (prevName, newName) => {
  const compPrevName = cleanTicketName(prevName);
  const compNewName = cleanTicketName(newName);
  return compPrevName === compNewName;
};

export const purchasedWithin24Hours = (order) => {
  const now = new Date();
  const purchaseDate = parseApiDate(order.createdAt);
  return (now - purchaseDate) / (1000 * 60 * 60) < 24;
};

export const getGroupedTickets = (currentTickets, feePaid) => {
  return currentTickets.reduce((acc, ticket) => {
    if (!ticket.bundleSlug) {
      acc[ticket.ticketOption.ticketOptionId] = {
        ...ticket,
        ticketOption: {
          ...ticket.ticketOption,
          feeAmount: feePaid[ticket.ticketOption.ticketOptionId] || 0,
          priceDiscount:
            typeof ticket.ticketOption.priceDiscount !== 'undefined'
              ? ticket.ticketOption.priceDiscount
              : ticket.ticketOption.price,
          taxDiscount:
            typeof ticket.ticketOption.taxDiscount !== 'undefined'
              ? ticket.ticketOption.taxDiscount
              : ticket.ticketOption.tax,
        },
      };
      return acc;
    }

    if (!acc[ticket.bundleSlug]) {
      acc[ticket.bundleSlug] = {
        ...ticket,
        ticketOption: {
          ...ticket.ticketOption,
          ticketOptionId: ticket.bundleSlug,
          ticketOptionName: ticket.bundle.title,
          price: 0,
          tax: 0,
          priceDiscount: 0,
          taxDiscount: 0,
          feeAmount: 0,
        },
      };
    }

    acc[ticket.bundleSlug].ticketOption.price += ticket.ticketOption.price;
    acc[ticket.bundleSlug].ticketOption.tax += ticket.ticketOption.tax;
    acc[ticket.bundleSlug].ticketOption.priceDiscount +=
      ticket.ticketOption.priceDiscount;
    acc[ticket.bundleSlug].ticketOption.taxDiscount +=
      ticket.ticketOption.taxDiscount;
    acc[ticket.bundleSlug].ticketOption.taxDiscount +=
      feePaid[ticket.ticketOption.ticketOptionId] || 0;

    return acc;
  }, {});
};

export const canUpdateQuantity = (tickets) => {
  const today = DateTime.now().startOf('day');
  return !tickets.some(
    (ticket) =>
      DateTime.fromISO(ticket?.ticketOption?.date).startOf('day') <= today
  );
};

export const manageDateChangeTicket = async (
  updatedTicket,
  currentTicket,
  tickets,
  ticketInventory
) => {
  const { venue, ticketOption } = updatedTicket;

  await fetchInventory(venue, ticketOption.date, ticketInventory);

  const changeDateFeeTicket = getDateChangeFeeTicket(
    ticketOption.date,
    ticketInventory,
    venue.id
  );

  if (!changeDateFeeTicket) return tickets;

  if (currentTicket.ticketOption.date === updatedTicket.ticketOption.date) {
    return tickets.filter(
      ({ ticketOption }) =>
        ticketOption.ticketOptionId !== changeDateFeeTicket.ticketOptionId
    );
  }

  const existingFee = tickets.find(
    ({ ticketOption }) =>
      ticketOption.ticketOptionId === changeDateFeeTicket.ticketOptionId
  );

  if (existingFee) return tickets;

  return tickets.concat({
    venue,
    ticketOption: {
      ticketOptionId: changeDateFeeTicket.ticketOptionId,
      ticketOptionName: changeDateFeeTicket.name,
      startTime: '',
      bookingItemId: changeDateFeeTicket.bookingItemId,
      price: changeDateFeeTicket.price,
      tax: changeDateFeeTicket.tax,
      description: changeDateFeeTicket.description,
      date: ticketOption.date,
      quantity: updatedTicket.ticketOption.quantity,
      feeAmount: 0,
    },
  });
};

export const mapBundleAddon = (addon, newQuantity, existingAddons) => {
  const existingAddonIndex = existingAddons.findIndex(
    (existingAddon) =>
      existingAddon?.ticketOptionId === addon.ticketOptionId ||
      existingAddon?.ticketOption?.ticketOptionId === addon.ticketOptionId
  );

  if (existingAddonIndex >= 0) {
    existingAddons[existingAddonIndex].ticketOption.quantity = newQuantity;
    return existingAddons;
  }

  existingAddons.push({
    venue: addon.venue,
    ticketOption: {
      ticketOptionId: addon.ticketOptionId,
      ticketOptionName: addon.name,
      description: addon.description,
      bookingItemId: addon.bookingItemId,
      price: addon.price,
      quantity: newQuantity,
      tax: addon.tax,
      date: formatApiDate(addon.reservationDate),
      addon: true,
      externalTicket: addon.externalTicket,
      externalVenue: addon.externalVenue,
    },
  });

  return existingAddons;
};

export const isTicketExpired = (reservationDate) => {
  const today = DateTime.now().startOf('day');
  const ticketDate = DateTime.fromISO(reservationDate).startOf('day');
  return today > ticketDate;
};
