import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useClassName, useHideHelpscout } from 'common/hooks';
import Template from './components/Template';
import Modal from './components/Modal';
import Button from './components/Button';
import PlayersSelector from './components/PlayersSelector';
import { getCookie, setCookie } from 'utils/helpers/cookie';
import { getPlayerSelectorWidth } from './utils';
import './players.less';

const COLORS = [
  '#42A133',
  '#356FC0',
  '#CC3E5F',
  '#DCAD2E',
  '#AD25B0',
  '#D01013',
  '#FFEB35',
  '#3C70AA',
];

const BLMG_COLORS = [
  '#6C39E7',
  '#CD00B8',
  '#A10033',
  '#3C70AA',
  '#FF6700',
  '#42A133',
  '#E9AC00',
  '#D01012',
];

const DEFAULT_COLOR = '#000000';

const DEFAULT_SCORE = new Array(18).fill(0);

const COOKIE_NAME = 'miniGolfScore';

const Players = (props) => {
  const className = useClassName('Players');
  const [totalSelected, setTotalSelected] = useState();
  const [showPlayersModal, setShowPlayersModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(0);
  const [players, setPlayers] = useState([]);
  const [confirmedPlayers, setConfirmedPlayers] = useState([]);
  const [hideHelpscout, showHelpscout] = useHideHelpscout();
  const inputRef = useRef();
  const history = useHistory();
  const { slug } = props.match.params;
  const isAngryBirds = slug.includes('angry-birds');
  const colors = isAngryBirds ? COLORS : BLMG_COLORS;

  useEffect(() => {
    hideHelpscout();
    const miniGolfCookie = getCookie(COOKIE_NAME);
    const miniGolfScore = miniGolfCookie ? JSON.parse(miniGolfCookie) : {};
    if (!miniGolfScore.termsAccepted) {
      history.push(window.location.pathname.replace('players', ''));
    }
    return () => showHelpscout();
  }, []);

  const handleColorClicked = (color) => {
    setPlayers(
      players.map((player, index) => {
        if (index !== selectedPlayer) return player;
        return {
          ...player,
          color,
        };
      })
    );
  };

  const handleModalClose = () => {
    setShowPlayersModal(false);
  };

  const handleNumberSelected = (number) => {
    const total =
      number > confirmedPlayers.length ? number : confirmedPlayers.length;

    setTotalSelected(total);
    setPlayers(
      new Array(total).fill({}).map((player, index) => {
        if (confirmedPlayers[index]) return confirmedPlayers[index];
        return player;
      })
    );
    setShowPlayersModal(true);
    setSelectedPlayer(confirmedPlayers.length ? number - 1 : 0);
  };

  const handleRemovePlayer = (index) => {
    const newConfirmedPlayers = confirmedPlayers.filter(
      (player, playerIndex) => playerIndex !== index
    );
    setConfirmedPlayers(newConfirmedPlayers);
    setPlayers(newConfirmedPlayers);
  };

  const handleConfirmPlayers = () => {
    setConfirmedPlayers(
      players.map((player) => {
        if (player.color) return player;
        return {
          ...player,
          color: DEFAULT_COLOR,
        };
      })
    );
    setShowPlayersModal(false);
  };

  const handleInputChange = (e) => {
    setPlayers(
      players.map((player, index) => {
        if (index !== selectedPlayer) return player;
        return {
          ...player,
          name: e.target.value,
        };
      })
    );
  };

  const handleSavePlayers = () => {
    setCookie(
      COOKIE_NAME,
      JSON.stringify({
        players: confirmedPlayers.map((player) => ({
          ...player,
          score: DEFAULT_SCORE,
        })),
      }),
      300
    );
    history.push(window.location.pathname.replace('players', 'rules'));
  };

  return (
    <Template venueName={slug}>
      <div className={className(['container', `container-${slug}`])}>
        <p className={className('title')}>Choose game players</p>
        <p className={className('description')}>
          Players who are not selected will not be considered for the game.
        </p>
        <PlayersSelector
          confirmedPlayers={confirmedPlayers}
          handleNumberSelected={handleNumberSelected}
          handleRemovePlayer={handleRemovePlayer}
        />
        <div className={className('cta-container')}>
          <Button
            label={
              confirmedPlayers.length ? 'Confirm players' : 'Select players'
            }
            disabled={!confirmedPlayers.length}
            handleClick={handleSavePlayers}
            venueName={slug}
            primary
          />
        </div>
      </div>
      <Modal
        title="Edit player profiles"
        description="Complete each player's name/nickname"
        venueName={slug}
        isOpen={showPlayersModal}
        handleClose={handleModalClose}>
        <input
          ref={inputRef}
          className={className('player-name')}
          placeholder="Name/Nickname"
          value={players[selectedPlayer]?.name || ''}
          onChange={handleInputChange}
        />

        <div className={className('players-container')}>
          {players.slice(0, totalSelected).map((player, index) => (
            <div
              key={`player-${index}`}
              className={className([
                'player',
                `player-${slug}`,
                `player-${slug}-${index === selectedPlayer && 'selected'}`,
                `player-${player.color && 'colored'}`,
              ])}
              style={{
                backgroundColor: `${player.color}`,
                width: getPlayerSelectorWidth(totalSelected),
              }}
              onClick={() => setSelectedPlayer(index)}>
              {player.name || `Player ${index + 1}`}
            </div>
          ))}
        </div>
        <div className={className('colors-selectors')}>
          <p className={className('message')}>
            If you wish, choose your favorite color
          </p>
          <div className={className('colors')}>
            {colors.slice(0, totalSelected < 5 ? 5 : 8).map((color) => {
              const selectedColor = players.some(
                (player) => player.color === color
              );
              return (
                <div
                  key={color}
                  className={className([
                    'color',
                    `color-${totalSelected < 5 ? 'small' : 'big'}`,
                  ])}
                  style={{
                    backgroundColor: `${color}`,
                  }}
                  onClick={() => {
                    if (selectedColor) return;
                    handleColorClicked(color);
                  }}>
                  {selectedColor ? 'X' : ''}
                </div>
              );
            })}
          </div>
        </div>
        <Button
          label="Comfirm and Start"
          handleClick={handleConfirmPlayers}
          venueName={slug}
          primary
          disabled={players.some((player) => !player?.name?.trim())}
        />
      </Modal>
    </Template>
  );
};

export default Players;
