import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Loader, Container } from 'semantic-ui-react';
import { Spacer } from 'public/components';
import { getContentfulField } from 'common/components';
import { useClassName } from 'common/hooks';
import { ABOUT, PARTNER } from 'public/components/Footer/config';
import { track } from 'utils/analytics';
import './sitemap.less';

const SiteMap = (props) => {
  const className = useClassName('Sitemap');
  const [linkGroups, setLinkGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLinkGroups = async () => {
      const menuLinkGroups = await getMenuLinks();
      const footerLinkGroups = getFooterLinks();
      setLinkGroups([...menuLinkGroups, ...footerLinkGroups]);
      setLoading(false);
    };
    window.scrollTo(0, 0);
    fetchLinkGroups();
  }, []);

  const trackLinkClicked = (link) => {
    track('Link clicked from sitemamp', { label: link.text, url: link.link });
  };

  const getLinksFromGroup = (linksGroup) => {
    return linksGroup.map((link) => ({
      text: link.title,
      link: link.value,
    }));
  };

  const getItemProps = (item) => ({
    children: getContentfulField(item.fields.children),
    text: getContentfulField(item.fields.text),
    link: getContentfulField(item.fields.link),
  });

  const getItemLinks = (item) => {
    if (!item?.fields) return null;
    const { children, text, link } = getItemProps(item);
    const group = { title: text };
    if (link && !children)
      return {
        ...group,
        items: [{ text, link }],
      };

    group.items = children
      .map((child) => {
        if (!child?.fields) return null;
        const { children, text, link } = getItemProps(child);
        if (children) return getItemLinks(child);
        return { text, link };
      })
      .filter((child) => child);

    return group;
  };

  const getMenuLinks = async () => {
    const { menu } = props;
    await menu.fetch();
    const menuItems = getContentfulField(menu.menu.fields.items);

    return menuItems.map(getItemLinks).filter((group) => group);
  };

  const getFooterLinks = () => {
    const footerLinks = [];

    footerLinks.push({
      title: 'About',
      items: getLinksFromGroup(ABOUT),
    });

    footerLinks.push({
      title: 'Partner with us',
      items: getLinksFromGroup(PARTNER),
    });

    return footerLinks;
  };

  const renderGroupLinks = (group, isPrimary = false) => (
    <div
      className={className('link-group', !isPrimary ? 'subgroup' : '')}
      key={group.title}>
      <p className={className(isPrimary ? 'group-title' : 'group-subtitle')}>
        {group.title}
      </p>
      <ul>
        {group.items.map((item) =>
          item.items ? (
            renderGroupLinks(item, false)
          ) : (
            <li key={item.link} className={className('link')}>
              <Link
                to={{ pathname: item.link }}
                target="_blank"
                onClick={(e) => {
                  trackLinkClicked(item);
                  if (item.link.includes('@')) {
                    e.preventDefault();
                    window.location.href = `mailto:${item.link}`;
                  }
                }}>
                {item.text?.toLowerCase()}
              </Link>
            </li>
          )
        )}
      </ul>
    </div>
  );

  const renderLinks = () => {
    if (!linkGroups) return null;
    return linkGroups.map((group) => renderGroupLinks(group, true));
  };

  return (
    <Container className="small">
      <Spacer size="m" />
      <h1 className={className('title')}>Sitemap</h1>
      <Spacer size="m" />
      {loading ? <Loader active inline="centered" /> : renderLinks()}
    </Container>
  );
};

export default inject('menu')(SiteMap);
