import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { inject } from 'mobx-react';
import { Container, Loader } from 'semantic-ui-react';

import { Spacer } from 'public/components';
import { useClassName } from 'common/hooks';
import { urlForUpload } from 'utils/api';
import { SectionTitle } from '../Directory/components';

import DealList from './components/DealList';
import SearchInput from './components/SearchInput';
import Categories from './components/Categories';
import DealsPagination from './components/DealsPagination';
import { FeaturedSection } from '../Directory/components';
import { DesktopOnly } from 'common/components';

import './deals.less';

const ITEMS_PER_PAGE = 20;

const Deals = (props) => {
  let { category = 'all-deals' } = useParams();
  const slideRef = useRef();
  const [deals, setDeals] = useState(null);
  const [featured, setFeatured] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const className = useClassName('DealsPage');

  useEffect(() => {
    getFeatured();
  }, []);

  useEffect(() => {
    fetchData();
  }, [category, page, searchInput]);

  useEffect(() => {
    setPage(1);
  }, [category, searchInput]);

  const getCategoryNameByRoute = async () => {
    let subCategoryName = category;
    const categoryMap =
      subCategories || (await props.deals.getDealsSubCategories(searchInput));
    const currentCategory = categoryMap.find(
      (subCategory) => subCategory.route === category
    );
    subCategoryName = currentCategory?.name || subCategoryName;
    return subCategoryName;
  };

  const getFeatured = async () => {
    const data = (await props.deals.getFeaturedDeals()) || [];
    const normalizedDeal = data.map((deal) => {
      const { _id, venueId, title: name, dealImage, logo } = deal;
      let { content = { horizontalImage: null }, slug } = venueId;
      content = content?.logo ? { ...content, ownLogo: content.logo } : content;
      return {
        _id,
        content,
        name,
        slug,
        ownImage: { img: dealImage, loadFunc: urlForUpload },
        ownLogo: { img: logo, loadFunc: urlForUpload },
        ownLink: { path: `/deals/${deal.slug}`, state: null },
      };
    });
    setFeatured([...normalizedDeal]);
  };

  const fetchData = async () => {
    const [data, totalItems] = await props.deals.getAllDeals({
      subCategory: await getCategoryNameByRoute(),
      page,
      skip: ITEMS_PER_PAGE,
      query: searchInput,
    });
    const subCategories = await props.deals.getDealsSubCategories(searchInput);
    setDeals([...data]);
    setTotalItems(totalItems);
    setSubCategories([...subCategories]);
  };

  return (
    <Container className={className('deals-page-wrapper')}>
      <div className={className('header-wrapper')}>
        <SectionTitle title="Deals" />
        <SearchInput input={searchInput} setInput={setSearchInput} />
      </div>
      {featured?.length > 0 && (
        <>
          {searchInput === '' && (
            <FeaturedSection
              featuredItems={featured}
              slideRef={slideRef}
              show={true}
              title={false}
            />
          )}
          <DesktopOnly>
            <Spacer size="m" />
          </DesktopOnly>
        </>
      )}
      {deals === null && <Loader inline="centered" active />}
      {deals?.length === 0 && <span>There's no deals or promo.</span>}
      {deals?.length > 0 && (
        <>
          <Categories
            categories={subCategories || []}
            currentCategory={category}
          />
          <Spacer size="m" />
          <DealList dealItems={deals} />
          <Spacer size="m" />
          <DealsPagination
            deals={deals}
            page={page}
            totalItems={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            handlePageChange={setPage}
          />
        </>
      )}
    </Container>
  );
};

Deals.propTypes = {
  props: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default inject('deals')(Deals);
