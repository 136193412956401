import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import './players-selector.less';
const MAX_PLAYERS = 6;

const PlayersSelector = ({
  confirmedPlayers,
  handleNumberSelected,
  handleRemovePlayer,
}) => {
  const className = useClassName('PlayersSelector');

  const getStyle = (index) => {
    if (!confirmedPlayers[index]) return {};
    return {
      backgroundColor: confirmedPlayers[index].color,
      border: 'none',
    };
  };

  return (
    <div className={className('container')}>
      {[...Array(MAX_PLAYERS)].map((item, index) => (
        <div
          key={`slot-${index}`}
          className={className('selector')}
          style={getStyle(index)}>
          <div
            className={className(
              `selector-${confirmedPlayers[index] ? 'name' : 'number'}`
            )}
            onClick={() => handleNumberSelected(index + 1)}>
            {confirmedPlayers[index]
              ? confirmedPlayers[index].name
              : `${index + 1} player${index > 0 ? 's' : ''}`}
          </div>
          {confirmedPlayers[index] && (
            <Icon
              name="close"
              size="mini"
              className={className('delete')}
              onClick={() => handleRemovePlayer(index)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

PlayersSelector.propTypes = {
  confirmedPlayers: PropTypes.arrayOf(PropTypes.object),
  handleNumberSelected: PropTypes.func.isRequired,
  handleRemovePlayer: PropTypes.func.isRequired,
};

PlayersSelector.defaultProps = {
  confirmedPlayers: [],
};

export default PlayersSelector;
