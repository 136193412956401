import React from 'react';
import PropTypes from 'prop-types';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { STRIPE_PUBLISHABLE_KEY } from 'utils/env/client';

const appearance = {
  theme: 'stripe',
  variables: {
    borderRadius: '6px',
    colorPrimary: '#000000',
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
      padding: '12px',
    },
    '.Label': {
      fontWeight: '500',
      fontSize: '13px',
    },
    '.Input': {
      lineHeight: '30px',
    },
  },
};

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripePaymentContainer = ({ clientSecret, children }) => {
  if (!clientSecret) return null;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance,
        clientSecret,
        locale: 'en',
      }}>
      <ElementsConsumer>{children}</ElementsConsumer>
    </Elements>
  );
};

StripePaymentContainer.propTypes = {
  clientSecret: PropTypes.string,
  children: PropTypes.any,
};

export default StripePaymentContainer;
