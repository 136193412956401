import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Loader,
  Form,
  Message,
  Button,
  Input,
  Checkbox,
  TextArea,
} from 'semantic-ui-react';
import {
  Layout,
  TelInput,
  Select,
  DatePicker,
  SVGIcon as Icon,
} from 'common/components';
import { Component } from 'common/helpers';
import { DATE_MMDDYYYY } from 'common/constants/dates';
import { Spacer } from '../Spacer';
import { isValidEmail, isValidPhone } from 'utils/helpers/validations';
import { inject } from 'mobx-react';
import { ContentfulRichText } from 'common/components';
import { identify, track } from 'utils/analytics';
import { DEFAULT_GROUP_ID, SMS_GROUP_ID } from '../../../common/const';
import { Link } from 'react-router-dom';
import './group-sales-form.less';
import {
  defaultProps,
  propTypes,
} from '../../screens/GroupSales/GroupSalesProps';

const SELECT_DEFAULT_KEY = 'select';

@inject('pages', 'subscriptions')
export default class GroupSalesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      state: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      city: '',
      province: '',
      company: '',
      groupType: '',
      venueOfInterest: '',
      companyType: '',
      clientType: '',
      eventDate: undefined,
      taxExempt: false,
      contactFormComments: '',
      emailAccepted: true,
      smsAccepted: false,
    };
  }

  async componentDidMount() {
    try {
      const page = await this.props.pages.fetchItemBySlug(
        window.location.pathname.replace('/', '')
      );

      this.props.onFetchPage(page);
      this.setState({
        page: page,
        venueOfInterest: this.fieldIsIgnored('venueOfInterest')
          ? page.name
          : '',
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          errors: [error.message],
        });
      } else {
        throw error;
      }
    }
  }

  fieldIsIgnored = (fieldName) => this.props.fields[fieldName]?.ignored;

  fieldIsOptional = (fieldName) => this.props.fields[fieldName]?.optional;

  validateFields = () => {
    const validations = {
      firstName: {
        errorMessage: 'Please provide a First Name.',
        func: () => this.state.firstName,
      },
      lastName: {
        errorMessage: 'Please provide a Last Name.',
        func: () => this.state.lastName,
      },
      email: {
        errorMessage: 'Please provide a valid Email Address.',
        func: () => isValidEmail(this.state.email),
      },
      phone: {
        errorMessage: 'Please provide a valid Phone Number.',
        func: () => isValidPhone(this.state.phone),
      },
      city: {
        errorMessage: 'Please provide a City.',
        func: () => this.state.city,
      },
      province: {
        errorMessage: 'Please provide a State/Province.',
        func: () => this.state.province,
      },
      company: {
        errorMessage: 'Please select a Company Name.',
        func: () => this.state.company,
      },
      companyType: {
        errorMessage: 'Please select a Company Type.',
        func: () => this.state.companyType,
      },
      clientType: {
        errorMessage: 'Please select a Client Type.',
        func: () => this.state.clientType,
      },
      groupType: {
        errorMessage: 'Please select Group Type.',
        func: () => this.state.groupType.length,
      },
      venueOfInterest: {
        errorMessage: 'Please select an Attraction.',
        func: () => this.state.venueOfInterest,
      },
      ticketQuantity: {
        errorMessage: 'Please indicate the number of people in your group.',
        func: () => this.state.ticketQuantity,
      },
      eventDate: {
        errorMessage: 'Please select an Event Date.',
        func: () => this.state.eventDate,
      },
    };

    return Object.keys(validations)
      .map((field) => {
        if (this.fieldIsOptional(field) || this.fieldIsIgnored(field))
          return false;

        const validation = validations[field];
        if (validation.func()) return false;

        return validation.errorMessage;
      })
      .filter(Boolean);
  };

  onSubmit = async (evt) => {
    evt.preventDefault();

    const errors = this.validateFields();

    if (errors.length) {
      this.setState({
        errors,
        status: 'error',
      });
      window.scrollTo(0, 0);
      return;
    }
    try {
      this.setState({
        errors: [],
        state: 'loading',
      });

      const formData = new FormData(evt.target);

      const paramsArray = [];

      formData.forEach((value, key) => {
        paramsArray.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      });

      const body = paramsArray.join('&');

      fetch(
        'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
        {
          method: 'POST',
          mode: 'no-cors',
          body,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
        .then(() => {
          this.setState({
            state: 'success',
          });
          this.sendInfoBraze();
        })
        .catch((err) => {
          this.setState({
            state: 'error',
            errors: [err.message],
          });
        });
    } catch (err) {
      this.setState({
        state: 'error',
        errors: [err.message],
      });
    }
  };

  onTelChange = (phone) => {
    this.setState({
      phone,
    });
  };

  onDayChange = (eventDate) => {
    this.setState({
      eventDate,
    });
  };

  onChange = (evt) => {
    const { target } = evt;
    let value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (target.type === 'select-multiple') {
      value = Array.apply(null, target.options)
        .filter((i) => i.selected)
        .map((i) => i.value)
        .join(',');
    } else {
      value = target.value;
    }
    this.setState({
      [target.id]: value,
    });
  };

  getPageCopy = (page, key, defaultValue) => {
    let result = defaultValue;
    const copies = page.object.fields.textCopies['en-US'];
    if (copies.length) {
      const entry = copies.filter(
        (item) => item.fields.key['en-US'] === key
      )[0];
      if (entry) {
        result = entry.fields.value['en-US'];
      }
    }

    return result;
  };

  getOptions = (page, key, defaultValues) => {
    let list = defaultValues;
    const copy = this.getPageCopy(page, key);
    const defaultOption = (
      <option key={SELECT_DEFAULT_KEY} value={SELECT_DEFAULT_KEY} disabled>
        Select
      </option>
    );

    if (copy) {
      list = copy.split(',').map((i) => i.trim());
    }

    const options = list.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));

    // add default value.
    options.unshift(defaultOption);

    return options;
  };

  sendInfoBraze = async () => {
    const { firstName, lastName, name, email, phone } = this.state;
    const user = { firstName, lastName, name, email, phone };

    identify({
      ...user,
      ...this.props.brazeTriggers.customAttributes,
    });
    track(this.props.brazeTriggers.customEvent);
    if (this.state.emailAccepted) {
      await this.props.subscriptions.create({
        ...user,
        group: DEFAULT_GROUP_ID,
      });
    }

    if (this.state.smsAccepted) {
      await this.props.subscriptions.create({
        ...user,
        group: SMS_GROUP_ID,
      });
    }
  };

  getFormattedDate = (date) => moment(date).format(DATE_MMDDYYYY);

  render() {
    const { state, page, errors, eventDate } = this.state;

    if (state === 'loading' || !page) {
      return (
        <React.Fragment>
          <Spacer size="m" />
          <Loader inline="centered" active />
          <Spacer size="m" />
        </React.Fragment>
      );
    } else if (state === 'success') {
      return (
        <div className={this.getElementClass('thank-you')}>
          <Layout horizontal center padded stackable>
            <Layout.Group>
              <Icon name="check-circle" size="large" />
            </Layout.Group>
            <Layout.Group>
              <h4>
                {this.getPageCopy(
                  page,
                  'successMessageTitle',
                  'Thank you for submitting the Group Request Form!'
                )}
              </h4>
            </Layout.Group>
          </Layout>
          <Spacer size="xs" />
          <p>
            {this.getPageCopy(
              page,
              'successMessage',
              'Our Dream Team will be in touch within 48 hours'
            )}
          </p>
          <Spacer size="s" />
          <Button onClick={this.props.onCloseClick}>Close</Button>
        </div>
      );
    }
    return (
      <div className={this.getComponentClass()}>
        {Boolean(errors?.length) && (
          <React.Fragment>
            <Message error>
              {errors.map((error, i) => (
                <Message.Item key={i}>{error}</Message.Item>
              ))}
            </Message>
            <Spacer size="xs" />
          </React.Fragment>
        )}
        <React.Fragment>
          <h1>{page.name}</h1>
          <Spacer size="s" />
          <ContentfulRichText field={page.object.fields.body} />
          <Spacer size="s" />
        </React.Fragment>
        <Form noValidate onSubmit={this.onSubmit}>
          <Input type="hidden" name="oid" value="00D1U000000xmYD" />
          <Input
            type="hidden"
            name="00N1U00000UsorG"
            value={this.props.fields.leadType?.defaultValue || 'Group Sales'}
          />
          <Input type="hidden" name="Status" value="Prospecting" />
          <Input type="hidden" name="lead_source" value="contact form" />
          <Input
            type="hidden"
            name="retURL"
            value="https://www.americandream.com/"
          />
          {!this.fieldIsIgnored('firstName') && (
            <>
              <Form.Field required={!this.fieldIsOptional('firstName')}>
                <label htmlFor="firstName">
                  {this.getPageCopy(page, 'firstName', 'First Name')}
                </label>
                <Input
                  value={this.state.firstName}
                  onChange={this.onChange}
                  id="firstName"
                  name="first_name"
                  type="text"
                  autoComplete="given-name"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('lastName') && (
            <>
              <Form.Field required={!this.fieldIsOptional('lastName')}>
                <label htmlFor="lastName">
                  {this.getPageCopy(page, 'lastName', 'Last Name')}
                </label>
                <Input
                  value={this.state.lastName}
                  onChange={this.onChange}
                  id="lastName"
                  name="last_name"
                  type="text"
                  autoComplete="family-name"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('email') && (
            <>
              <Form.Field required={!this.fieldIsOptional('email')}>
                <label htmlFor="email">
                  {this.getPageCopy(page, 'emailAddress', 'Email Address')}
                </label>
                <Input
                  value={this.state.email}
                  onChange={this.onChange}
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('phone') && (
            <>
              <Form.Field
                required={!this.fieldIsOptional('phone')}
                error={this.state.phoneValid === false}>
                <Input type="hidden" name="phone" value={this.state.phone} />
                <label htmlFor="phone">
                  {this.getPageCopy(page, 'phoneNumber', 'Phone Number')}
                </label>
                <TelInput id="phone" onChange={this.onTelChange} />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('city') && (
            <>
              <Form.Field required={!this.fieldIsOptional('city')}>
                <label htmlFor="city">
                  {this.getPageCopy(page, 'city', 'City')}
                </label>
                <Input
                  value={this.state.city}
                  onChange={this.onChange}
                  id="city"
                  name="city"
                  type="text"
                  autoComplete="address-level2"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('province') && (
            <>
              <Form.Field required={!this.fieldIsOptional('province')}>
                <label htmlFor="province">
                  {this.getPageCopy(page, 'state', 'State/Province')}
                </label>
                <Input
                  value={this.state.province}
                  onChange={this.onChange}
                  id="province"
                  name="state"
                  type="text"
                  autoComplete="address-level1"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('company') && (
            <>
              <Form.Field required={!this.fieldIsOptional('company')}>
                <label htmlFor="company">
                  {this.getPageCopy(
                    page,
                    'company',
                    'Company Name (if you are a corporate group)'
                  )}
                </label>
                <Input
                  value={this.state.company}
                  onChange={this.onChange}
                  id="company"
                  name="company"
                  type="text"
                  autoComplete="organization"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('companyType') && (
            <>
              <Form.Field required={!this.fieldIsOptional('companyType')}>
                <label htmlFor="companyType">
                  {this.getPageCopy(page, 'companyType', 'Company Type')}
                </label>
                <Select
                  defaultValue={SELECT_DEFAULT_KEY}
                  id="companyType"
                  name="00N4y000003b28D"
                  onChange={this.onChange}>
                  {this.getOptions(page, 'companyType', [
                    'Tour Operator',
                    'RTO',
                    'Travel Agent',
                    'Destination Management Company (DMC)',
                    'Destination Marketing Organization (DMO)',
                    'Convention & Visitors Bureau (CVB)',
                    'Online Travel Agent (OTA)',
                  ])}
                </Select>
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('groupType') && (
            <>
              <Form.Field required={!this.fieldIsOptional('groupType')}>
                <label htmlFor="groupType">
                  {this.getPageCopy(page, 'groupType', 'Group Type')}
                </label>
                <Select
                  defaultValue={SELECT_DEFAULT_KEY}
                  id="groupType"
                  name="00N1U00000Ufxv3"
                  onChange={this.onChange}>
                  {this.getOptions(page, 'groupTypes', [
                    'Birthday Party',
                    'Buyout/Private Rental',
                    'Camp',
                    'Corporate',
                    'Just for Fun',
                    'School Trip',
                    'Tour',
                    'Reseller',
                    'Other',
                  ])}
                </Select>
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('clientType') && (
            <>
              <Form.Field required={!this.fieldIsOptional('clientType')}>
                <label htmlFor="clientType">
                  {this.getPageCopy(page, 'clientType', 'Client Type')}
                </label>
                <Select
                  defaultValue={SELECT_DEFAULT_KEY}
                  id="clientType"
                  name="00N4y000003b28I"
                  onChange={this.onChange}>
                  {this.getOptions(page, 'clientType', [
                    'FIT',
                    'Group',
                    'M.I.C.E.',
                    'Other',
                  ])}
                </Select>
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {this.fieldIsIgnored('venueOfInterest') ? (
            <Input
              type="hidden"
              name="00N1U00000Ufxv8"
              value={this.state.venueOfInterest}
            />
          ) : (
            <>
              <Form.Field required={!this.fieldIsOptional('venueOfInterest')}>
                <label htmlFor="venueOfInterest">
                  {this.getPageCopy(page, 'venueOfInterest', 'Attraction')}
                </label>
                <Select
                  defaultValue={SELECT_DEFAULT_KEY}
                  id="venueOfInterest"
                  name="00N1U00000Ufxv8"
                  onChange={this.onChange}>
                  {this.getOptions(page, 'venuesOfInterest', [
                    'Angry Birds Mini Golf',
                    'Big Snow',
                    'DreamWorks Water Park',
                    'Legoland Discovery Center',
                    'New Jersey SEA LIFE Aquarium',
                    'Nickelodeon Universe',
                    'Blacklight Mini Golf',
                    'The Rink',
                    'Other',
                  ])}
                </Select>
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('taxExempt') && (
            <>
              <Input
                type="hidden"
                name="00N1U00000UfxvS"
                value={this.state.taxExempt}
              />
              <Checkbox
                id="taxExempt"
                label={this.getPageCopy(page, 'taxExempt', 'Tax Exempt')}
                onChange={this.onChange}
              />
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('ticketQuantity') && (
            <>
              <Form.Field required={!this.fieldIsOptional('ticketQuantity')}>
                <label htmlFor="ticketQuantity">
                  {this.getPageCopy(
                    page,
                    'ticketQuantity',
                    'Number of people in your group'
                  )}
                </label>
                <Input
                  value={this.state.ticketQuantity}
                  onChange={this.onChange}
                  id="ticketQuantity"
                  name="00N1U00000UfxvI"
                  type="number"
                  min="0"
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}
          {!this.fieldIsIgnored('comments') && (
            <>
              <Form.Field required={!this.fieldIsOptional('comments')}>
                <label htmlFor="contactFormComments">
                  {this.getPageCopy(
                    page,
                    'comments',
                    'Please tell us more about your group'
                  )}
                </label>
                <TextArea
                  value={this.state.comments}
                  onChange={this.onChange}
                  id="contactFormComments"
                  name="00N1U00000UfxvX"
                  type="text"
                  rows="3"
                  wrap="soft"
                  style={{ resize: 'vertical' }}
                />
              </Form.Field>
              <Spacer size="s" />
            </>
          )}
          {!this.fieldIsIgnored('eventDate') && (
            <>
              <Form.Field required={!this.fieldIsOptional('eventDate')}>
                <label>Choose an event date</label>
                <Input
                  type="hidden"
                  name="00N1U00000UfxvD"
                  value={eventDate ? this.getFormattedDate(eventDate) : ''}
                />
                <DatePicker
                  icon={<Icon size="tiny" name="calendar" />}
                  date={this.state.eventDate}
                  onDayChange={this.onDayChange}
                  startDate={new Date()}
                />
              </Form.Field>
              <Spacer size="xs" />
            </>
          )}

          <Form.Checkbox
            className="checkbox"
            name="emailAccepted"
            label={
              <>
                {
                  'Sign me up for exclusive American Dream sweepstakes, promotions, emails, events, and updates. By checking this option you agree to our '
                }
                <Link
                  className={this.getElementClass('terms-link')}
                  to="/terms">
                  Terms and Conditions
                </Link>
                {' and '}
                <Link
                  className={this.getElementClass('privacy-link')}
                  to="/privacy">
                  Privacy Policy
                </Link>
                .
              </>
            }
            checked={this.state.emailAccepted}
            onChange={(e, { checked }) =>
              this.setState({ emailAccepted: checked })
            }
          />
          <Form.Checkbox
            className="checkbox"
            name="smsAccepted"
            label={
              <>
                {
                  'Sign me up for SMS messages with news, updates and special promotions at the number provided above. Msg&Data rates may apply. Msg frequency varies. Reply HELP for help, STOP to cancel. By checking this option you agree to our '
                }
                <Link
                  className={this.getElementClass('terms-link')}
                  to="/terms">
                  Terms and Conditions
                </Link>
                {' and '}
                <Link
                  className={this.getElementClass('privacy-link')}
                  to="/privacy">
                  Privacy Policy
                </Link>
                .
              </>
            }
            checked={this.state.smsAccepted}
            onChange={(e, { checked }) =>
              this.setState({ smsAccepted: checked })
            }
          />
          <Spacer size="m" />
          <Button type="submit" fluid primary>
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

GroupSalesForm.propTypes = {
  onCloseClick: PropTypes.func,
  onFetchPage: PropTypes.func,
  fields: propTypes.fields,
  brazeTriggers: propTypes.brazeTriggers,
};

GroupSalesForm.defaultProps = {
  fields: defaultProps.fields,
  brazeTriggers: defaultProps.brazeTriggers,
};

GroupSalesForm.defaultProps = {};
