import React from 'react';
import PropTypes from 'prop-types';

import { AssetPropType } from 'public/schema';
import {
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
} from 'common/components/Responsive';
import { useClassName } from 'common/hooks';
import {
  standardizedTrack,
  getCategoryByCta,
  categories,
} from 'utils/analytics';

import HeroLogo from './HeroLogo';
import HeroTitle from './HeroTitle';
import HeroAsset from './HeroAsset';
import HeroSubtitle from './HeroSubtitle';
import { OperationHours } from '../OperationHours';
import { Spacer } from '../Spacer';
import CTA from './CTA';
import { TicketOptionsModal } from './TicketOptionsModal';
import { RideClosures } from './RideClosures';

import './hero.less';

const Hero = ({
  id,
  venue,
  heroLogo,
  heroTitle,
  heroSubtitle,
  heroVideo,
  heroPoster,
  heroCtaText,
  heroCtaLink,
  theme,
  ticketOptions,
  ridesCards,
}) => {
  const className = useClassName('Hero');
  const heroPrimaryColor = theme.customHeroPrimary || theme.primaryColor;
  const heroSecondaryColor = theme.customHeroSecondary || theme.secondaryColor;
  const heroTextColor = theme.customHeroTextColor || theme.textColor;

  const gradientColor = `linear-gradient(180deg, ${heroSecondaryColor} 29.13%, ${heroPrimaryColor} 90.94%)`;

  const trackingLabel = window.location.pathname; //We save this here to make sure the window location is not overwritten before sending the track events

  const handleClick = (heroCtaLink, heroCtaText) => {
    const category = getCategoryByCta(heroCtaLink) || categories.ERC_INFO;

    standardizedTrack(
      'click',
      'button',
      'hero',
      heroCtaText,
      category,
      trackingLabel
    );
  };

  const renderOperationHours = () => {
    return (
      <>
        <Spacer size="xs" />
        <OperationHours
          openingHours={venue.openingHours}
          featuredFlags={venue?.featuredFlags}
        />
      </>
    );
  };

  const TicketOptions = () => {
    if (!heroCtaText) return null;

    if (ticketOptions.length) {
      return (
        <TicketOptionsModal
          trigger={
            <CTA
              text={heroCtaText}
              textColor={theme.buttonTextColor}
              backgroundColor={theme.buttonColor}
            />
          }
          options={ticketOptions}
          onClick={handleClick}
        />
      );
    }

    if (!heroCtaLink) return null;
    return (
      <CTA
        text={heroCtaText}
        link={heroCtaLink}
        textColor={theme.buttonTextColor}
        backgroundColor={theme.buttonColor}
        onClick={() => handleClick(heroCtaLink, heroCtaText)}
      />
    );
  };

  const ContentMobile = () => (
    <>
      {heroLogo?.assetUrl && <HeroLogo asset={heroLogo} />}
      {heroTitle && <HeroTitle text={heroTitle} color={heroTextColor} />}
      {(heroVideo?.assetUrl || heroPoster?.assetUrl) && (
        <HeroAsset
          venue={venue}
          videoUrl={heroVideo?.assetUrl}
          posterUrl={heroPoster?.assetUrl}
        />
      )}
      {heroSubtitle && (
        <HeroSubtitle text={heroSubtitle} color={heroTextColor} />
      )}
      <TicketOptions />

      {!venue.hiddenOperationHours && renderOperationHours()}
      <RideClosures rides={ridesCards} />
    </>
  );

  const ContentDesktop = () => (
    <>
      <div className={className('left-container')}>
        {heroLogo?.assetUrl && <HeroLogo asset={heroLogo} />}
        {heroTitle && <HeroTitle text={heroTitle} color={heroTextColor} />}
        {heroSubtitle && (
          <HeroSubtitle text={heroSubtitle} color={heroTextColor} />
        )}
        <TicketOptions />
        {!venue.hiddenOperationHours && renderOperationHours()}
        <RideClosures rides={ridesCards} />
      </div>
      <div className={className('right-container')}>
        {(heroVideo?.assetUrl || heroPoster?.assetUrl) && (
          <HeroAsset
            venue={venue}
            videoUrl={heroVideo?.assetUrl}
            posterUrl={heroPoster?.assetUrl}
          />
        )}
      </div>
    </>
  );

  return (
    <div
      id={id}
      className={className('container')}
      style={{ background: gradientColor }}>
      <MobileOnly className={className('wrapper')}>
        <ContentMobile />
      </MobileOnly>
      <TabletOnly className={className('wrapper')}>
        <ContentMobile />
      </TabletOnly>
      <DesktopOnly className={className('wrapper')}>
        <ContentDesktop />
      </DesktopOnly>
    </div>
  );
};

Hero.propTypes = {
  id: PropTypes.string.isRequired,
  venue: PropTypes.object,
  heroTitle: PropTypes.object,
  heroSubtitle: PropTypes.object.isRequired,
  heroLogo: PropTypes.shape(AssetPropType),
  heroVideo: PropTypes.shape(AssetPropType),
  heroPoster: PropTypes.shape(AssetPropType),
  heroCtaText: PropTypes.string.isRequired,
  heroCtaLink: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  ticketOptions: PropTypes.array,
};

Hero.defaultProps = {
  venue: null,
  heroTitle: null,
  heroVideo: null,
  heroPoster: null,
  heroLogo: null,
};

export default Hero;
