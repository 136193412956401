import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { AttractionLogo } from 'public/components';
import { NumericUpDown } from 'common/components';
import { formatCurrency } from 'utils/l10n';
import './item-container.less';

const ItemContainer = ({
  itemDescription,
  itemPrice,
  itemQuantity,
  min,
  max,
  handleChange,
  bundle,
  venue,
}) => {
  const className = useClassName('ItemContainer');

  return (
    <div className={className('container')}>
      <div className={className('row')}>
        <AttractionLogo bundle={bundle} venue={venue} />
        <div className={className('quantity')}>
          <NumericUpDown
            value={itemQuantity}
            min={min}
            max={max}
            onChange={(quantity) => handleChange(quantity)}
          />
        </div>
      </div>
      <div className={className('row')}>
        <div className={className('name-price')}>
          <p className={className('name')}>{itemDescription}</p>
          <p className={className('price')}>{formatCurrency(itemPrice)}</p>
        </div>
      </div>
    </div>
  );
};

ItemContainer.propTypes = {
  itemPrice: PropTypes.number.isRequired,
  itemQuantity: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ItemContainer;
