import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { isValidEmail } from 'utils/helpers';
import { Component } from '../../helpers';

export default class ValidatedForm extends Component {
  onSubmit = (evt) => {
    const elements = Array.from(evt.target.elements);
    if (this.validateFields(elements)) {
      this.props.onSubmit(
        this.collect(elements, (el) => el.value),
        evt
      );
    }
  };

  validateFields(elements) {
    const errors = this.collect(elements, (el) => {
      // Semantic UI elements may put data attributes on input parents
      const required = el.dataset.required || el.parentNode.dataset.required;
      if (!this.validateField(el.type, el.value, required)) {
        return true;
      }
    });
    const pass = Object.keys(errors).length === 0;
    this.props.onValidated(errors, pass);
    return pass;
  }

  validateField(type, value, required) {
    if (type === 'email') {
      return isValidEmail(value);
    } else if (required) {
      return !!value;
    } else {
      return true;
    }
  }

  collect(elements, fn) {
    return elements.reduce((data, el) => {
      if (this.isInput(el)) {
        const val = fn(el);
        if (val != null) {
          data[el.name] = val;
        }
      }
      return data;
    }, {});
  }

  isInput(el) {
    return el.type !== 'button' && el.type !== 'submit';
  }

  render() {
    return (
      <Form {...this.passProps()} onSubmit={this.onSubmit}>
        {this.props.children}
      </Form>
    );
  }
}

ValidatedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onValidated: PropTypes.func.isRequired,
};
