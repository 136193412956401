import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { sum } from 'lodash';
import { Loader } from 'semantic-ui-react';

import { ContentfulRichText } from 'common/components';
import { Screen } from 'public/helpers';
import { formatApiDate } from 'utils/api';
import { Spacer } from 'public/components';
import {
  getQuantity,
  getItemPrice,
  getItemTax,
  ticketSalesClosed,
  validateInventory,
  trackProductViewed,
} from 'common/helpers';
import { WarningMessage } from 'public/components';

import { STEPS } from '../const';
import { NewCheckoutAddonsSelector } from './Selector';
import { NewCheckoutContainer } from '../Components/Container';
import { NewCheckoutTicketsHeader } from '../Tickets/Header';
import { NewCheckoutSummary } from '../Components/Summary';

import './addons.less';

function getDefaultState(props) {
  const {
    checkout: {
      reservationDate,
      venue: { slug },
    },
  } = props.checkout;

  return {
    reservationDate,
    slug,
    loading: true,
    redirect: null,
    venue: null,
    addons: null,
    quantities: {},
    sessions: null,
    startTime: null,
    cartItems: null,
    isAttraction: false,
  };
}

@inject('checkout', 'venues', 'cart', 'ticketInventory', 'addons')
@observer
export default class NewCheckoutAddons extends Screen {
  state = getDefaultState(this.props);

  routeDidUpdate() {
    this.fetchTickets();
    window.scrollTo(0, 0);
  }

  async fetchTickets() {
    const {
      checkout: { reservationDate, addons },
    } = this.props.checkout;
    const venue = await this.props.venues.fetchItemBySlug(this.state.slug);

    const cartItems = await this.props.cart.getItems();
    const isAttraction = venue?.venueType === 'attraction';

    await this.validateTicketsFromCart(
      venue,
      reservationDate,
      cartItems,
      isAttraction
    );
    const quantities = await this.getInitialQuantities();

    const sessions = this.props.ticketInventory.getSessions(
      venue.id,
      reservationDate
    );

    addons.forEach((item) => trackProductViewed(item, venue));

    this.setState({
      loading: false,
      reservationDate,
      venue,
      addons,
      quantities,
      sessions,
      cartItems,
      isAttraction,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = getDefaultState(props);
    if (newState.slug !== state.slug) {
      return newState;
    }
    return null;
  }

  onSubmit = (inventory) => {
    const { venue, sessions } = this.state;
    const reservationDate = formatApiDate(this.state.reservationDate);
    const { checkout } = this.props.checkout;

    const session = sessions?.length
      ? sessions.find((session) => session.startTime === checkout.startTime)
      : {};

    inventory
      .map((item) => {
        const {
          ticketOptionId,
          bookingItemId,
          externalId,
          name,
          date,
          price,
          externalAddon,
          externalVenue,
        } = item;

        return {
          name,
          date,
          price,
          venueId: venue.id,
          venueName: venue.name,
          externalId,
          bookingItemId,
          ticketOptionId,
          reservationDate,
          startTime: session.startTime,
          startTimeName: session.name,
          quantity: this.getQuantity(item),
          externalTicket: externalAddon,
          externalVenue,
        };
      })
      .filter((t) => t.quantity > 0)
      .forEach((ticket) => this.props.cart.addTicket(ticket));

    this.setRedirect('/cart');
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
    });
  };

  renderAddOns() {
    const { venue, addons } = this.state;
    const {
      checkout: { startTime },
    } = this.props.checkout;

    if (venue?.openingHours?.temporarilyClosed) {
      return (
        <WarningMessage
          content={<ContentfulRichText field={venue?.content?.closedMessage} />}
        />
      );
    }

    return (
      <div className={this.getElementClass('selector')}>
        <NewCheckoutAddonsSelector
          addons={addons}
          setQuantity={this.setQuantity}
          quantities={this.state.quantities}
          startTime={startTime}
          addonTitle={venue?.content?.addonTitle}
          addonsStore={this.props.addons}
        />
      </div>
    );
  }

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    if (this.state.loading) {
      return (
        <>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </>
      );
    }

    const { venue, addons } = this.state;
    const inventory = this.props.ticketInventory.get(
      venue.id,
      this.state.reservationDate
    );
    const { errorMessage, errorTitle, canSubmit } = this.validate();
    const error = (errorMessage || errorTitle) && { errorMessage, errorTitle };

    return (
      <NewCheckoutContainer
        attrs={this.getAttrs()}
        content={
          <>
            <NewCheckoutTicketsHeader
              showLogo={false}
              title="Enhance your experience"
            />
            {this.renderAddOns()}
          </>
        }
        summary={
          <NewCheckoutSummary
            step={STEPS.Addons}
            onSubmit={() => this.onSubmit(addons)}
            canSubmit={canSubmit}
            error={error}
            totals={{
              subtotal: this.getSubtotal(inventory),
              tax: this.getTax(inventory),
              total: this.getTotal(inventory),
              ticketCount: this.getTicketsCount(),
              ticketSubtotal: this.getTicketsSubtotal(),
              addonCount: this.getAddonsCount(inventory),
              addonSubtotal: this.getAddonsSubtotal(inventory),
            }}
          />
        }
      />
    );
  }

  setQuantity = (item, quantity) => {
    const { quantities } = this.state;
    quantities[item.ticketOptionId] = quantity;

    this.setState({
      quantities,
    });
  };

  async validateTicketsFromCart(
    venue,
    reservationDate,
    cartItems,
    isAttraction
  ) {
    if (!cartItems?.length) {
      isAttraction
        ? this.setRedirect(`/venue/${venue.slug}/tickets`)
        : this.setRedirect(`/promo/${venue.slug}`);
      return;
    }

    const resDate = formatApiDate(reservationDate);

    const validCartItems = cartItems.filter(
      (cartItem) =>
        cartItem.venueId === venue.id && cartItem.reservationDate === resDate
    );

    if (!validCartItems?.length) {
      isAttraction
        ? this.setRedirect(`/venue/${venue.slug}/tickets`)
        : this.setRedirect(`/promo/${venue.slug}`);
    }
  }

  async getInitialQuantities() {
    let quantities = {};
    const { checkout } = this.props.checkout;
    const { tickets } = checkout;

    if (!tickets?.length) return quantities;

    tickets.forEach((ticket) => {
      const { ticketOptionId, quantity, startTime } = ticket;
      quantities[ticketOptionId] = startTime
        ? { startTime, quantity: quantity || 0 }
        : quantity || 0;
    });

    return quantities;
  }

  validate() {
    const { venue, reservationDate, addons, quantities } = this.state;
    const inventory = this.props.ticketInventory.get(
      venue.id,
      this.state.reservationDate
    );
    if (ticketSalesClosed(venue, reservationDate)) {
      return {
        canSubmit: false,
        errorMessage: 'Please select a date in the future',
        errorTitle: 'Same-day ticket sales are now closed.',
      };
    }

    if (addons) return validateInventory(inventory, quantities, venue, null);

    return { canSubmit: true };
  }

  getAddonsCount(inventory) {
    return sum(
      inventory
        .filter((item) => item.addon)
        .map((item) => this.getQuantity(item))
    );
  }

  getTicketsCount = () => {
    const {
      checkout: { tickets },
    } = this.props.checkout;

    return sum(tickets.map((ticket) => ticket.quantity));
  };

  getTicketsSubtotal() {
    const {
      checkout: { tickets },
    } = this.props.checkout;

    return sum(tickets.map((ticket) => ticket.quantity * ticket.price));
  }

  getAddonsSubtotal(inventory) {
    const { quantities } = this.state;

    return sum(
      inventory
        .filter((item) => item.addon)
        .map((item) => {
          const quantity = this.getQuantity(item);
          return getItemPrice(item, inventory, quantities) * quantity;
        })
    );
  }

  getSubtotal(inventory) {
    return this.getTicketsSubtotal() + this.getAddonsSubtotal(inventory);
  }

  getAddonsTax = (inventory) => {
    const { quantities } = this.state;

    return sum(
      inventory
        .filter((item) => item.addon)
        .map((item) => {
          const quantity = this.getQuantity(item);
          return getItemTax(item, inventory, quantities) * quantity;
        })
    );
  };

  getTicketsTax = () => {
    const {
      checkout: { tickets },
    } = this.props.checkout;

    return sum(tickets.map((ticket) => ticket.quantity * ticket.tax));
  };

  getTax(inventory) {
    return this.getTicketsTax() + this.getAddonsTax(inventory);
  }

  getTotal = (inventory) => {
    const subtotal = this.getSubtotal(inventory);
    const tax = this.getTax(inventory);

    return subtotal + tax;
  };

  getQuantity(item) {
    return getQuantity(item, this.state.quantities);
  }
}
