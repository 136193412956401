import React, { useState } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { formatCurrency } from 'utils/l10n';
import { WarningMessage } from 'public/components';
import { SVGIcon } from 'common/components';

import { STEPS } from '../../const';

import './summary.less';

const SummaryStep = ({
  step,
  currentStep,
  name,
  count,
  total,
  error,
  modify,
}) => {
  const className = useClassName('Summary');

  const isCompleted = () => currentStep > step;

  const isSelected = () => currentStep === step;

  const isPending = () => currentStep < step;

  return (
    Boolean(!error || (error && isSelected(step))) && (
      <div
        className={`${className('step')} ${
          isSelected(step) && className('selected')
        } ${isCompleted(step) && className('completed')} ${
          isPending(step) && className('pending')
        }`}>
        <div>
          {isCompleted(step) ? <SVGIcon name="completed" size="tiny" /> : step}
        </div>
        <span>
          <span>
            {name}
            {Boolean(count) && ` (${count})`}
            {modify?.enabled && (
              <span onClick={modify.onClick} className={className('modify')}>
                Modify
              </span>
            )}
          </span>
          {Boolean(total) && <span>{formatCurrency(total, true)}</span>}
        </span>
      </div>
    )
  );
};

const Summary = ({
  step,
  totals,
  error,
  canSubmit,
  onSubmit,
  submitText,
  modify,
  stepNames = {},
  loading = false,
  totalName = 'TOTAL',
}) => {
  const className = useClassName('Summary');
  const [showOnlyTotal, setShowOnlyTotals] = useState(true);

  const onClickTotal = () => setShowOnlyTotals(!showOnlyTotal);

  return (
    <div
      className={`${className('container')} ${
        totals.subtotal <= 0 && className('hidden')
      }`}>
      <div className={className('steps')}>
        <h1>Summary</h1>
        <SummaryStep
          step={STEPS.Tickets}
          currentStep={step}
          name={stepNames.tickets || 'Tickets'}
          count={totals.ticketCount}
          total={totals.ticketSubtotal}
          error={error}
          modify={modify[STEPS.Tickets]}
        />
        <SummaryStep
          step={STEPS.Addons}
          currentStep={step}
          name={stepNames.addons || 'Add-ons'}
          count={totals.addonCount}
          total={totals.addonSubtotal}
          error={error}
          modify={modify[STEPS.Addons]}
        />
        <SummaryStep
          step={STEPS.Checkout}
          currentStep={step}
          name={stepNames.checkout || 'Checkout'}
          error={error}
        />
      </div>
      <div className={className('totals')}>
        {error && (
          <WarningMessage
            content={
              <div>
                {error.errorTitle && <p>{error.errorTitle}</p>}
                {error.errorMessage && (
                  <p>{error.errorData || error.errorMessage}</p>
                )}
              </div>
            }
          />
        )}
        <div className={`${showOnlyTotal && className('hidden')}`}>
          <span>SUBTOTAL</span>
          <span>{formatCurrency(totals.subtotal, true)}</span>
        </div>
        {!totals.fee && (
          <div className={`${showOnlyTotal && className('hidden')}`}>
            <span>TAXES</span>
            <span>+ {formatCurrency(totals.tax, true)}</span>
          </div>
        )}
        {Boolean(totals.fee) && (
          <div className={`${showOnlyTotal && className('hidden')}`}>
            <span>TAXES AND FEES</span>
            <span>+ {formatCurrency(totals.fee + totals.tax, true)}</span>
          </div>
        )}
        {Boolean(totals.discount) && (
          <div className={className(['promo-code', showOnlyTotal && 'hidden'])}>
            <span>PROMO CODE</span>
            <span>- {formatCurrency(totals.discount, true)}</span>
          </div>
        )}
        <div
          className={className('total', showOnlyTotal && 'reduced')}
          onClick={onClickTotal}>
          <span>
            {totalName} <SVGIcon name="angle-up" size="mini" />
          </span>
          <span>{formatCurrency(totals.total, true)}</span>
        </div>
        <Button
          onClick={onSubmit}
          disabled={!canSubmit || loading}
          name="/cart"
          primary
          fluid>
          {loading ? (
            <>
              <Loader
                className={className('loading')}
                size="mini"
                active
                inline
              />
              <span>Loading</span>
            </>
          ) : (
            <span>{submitText || 'Continue'}</span>
          )}
        </Button>
      </div>
    </div>
  );
};

Summary.propTypes = {
  step: PropTypes.number,
  totals: PropTypes.object,
  error: PropTypes.object,
  canSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
  stepNames: PropTypes.object,
  loading: PropTypes.bool,
  modify: PropTypes.object,
  totalName: PropTypes.string,
};

Summary.defaultProps = {
  modify: {},
};

export default Summary;
