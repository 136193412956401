import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClassName, useWindowSize } from 'common/hooks';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { SVGIcon } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';
import { Dropdown } from 'public/components/Dropdown';

import './birthday-session-picker.less';

const BirthdaySessionPicker = ({
  sessions,
  selectedSession,
  setSelectedSession,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const classNames = useClassName('BirthdaySessionPicker');
  const { isMobile } = useWindowSize();

  const getPlaceholder = () => {
    if (loading) return 'Loading sessions';
    if (sessions && !sessions.length) return 'No sessions available';

    return 'Select Session Time';
  };

  const content = (
    <div className={classNames('list')}>
      {(sessions || []).map((session, index) => (
        <div
          key={index}
          className={classNames('item')}
          onClick={() => {
            setSelectedSession(session);
            setOpen(false);
          }}>
          <span>{session.name}</span>
        </div>
      ))}
    </div>
  );

  return (
    <ControlledInputContainer
      input={
        <input
          value={selectedSession?.name || ''}
          placeholder={getPlaceholder()}
          disabled={!sessions?.length}
          readOnly
        />
      }
      addonRight={
        <SVGIcon name="angle-down" className={classNames('addon-right')} />
      }
      loading={loading}
      onClick={() => sessions?.length && setOpen(true)}
      hasFocus={open}
      trigger={
        isMobile ? (
          <BottomSheet
            onClose={() => setOpen(false)}
            title="Select Session Time"
            isOpen={open}
            content={content}
          />
        ) : (
          <Dropdown
            isOpen={open}
            onClose={() => setOpen(false)}
            content={content}
            useTriggerWidth
          />
        )
      }
    />
  );
};

BirthdaySessionPicker.propTypes = {
  sessions: PropTypes.array,
  selectedSession: PropTypes.object,
  setSelectedSession: PropTypes.func,
  loading: PropTypes.bool,
};

export default BirthdaySessionPicker;
