import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { NotFound } from 'public/components';
import { Screen } from 'public/helpers';

import { NewCheckoutTicketsHeader } from '../Tickets/Header';
import { NewCheckoutSummary } from '../Components/Summary';
import { BirthdayCheckoutContainer } from './BirthdayCheckoutContainer';
import OtherInformation from '../Tickets/OtherInformation/OtherInformation';
import { BirthdayContactForm } from './BirthdayContactForm';
import { BirthdayAdmissions } from './BirthdayAdmissions';
import {
  getAdmissionSubtotal,
  getAdmissionTax,
  getAdmissionTotal,
} from './Admission/getBirthdayAdmissionTotals';
import { trackAdmissionAdded } from './utils';
import { NewCheckoutProgressBar } from '../Components/ProgressBar';
import { STEPS } from '../const';

@inject('birthdays', 'venues', 'me', 'betaFeatures', 'salesforce')
@observer
export default class BirthdayTickets extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      venue: null,
      admissions: [],
      sessions: null,
      selectedSession: null,
      numberOfGuests: 0,
      loadingSessions: false,
      selected: null,
      form: { opened: false },
      isEnabled: null,
    };
  }

  async routeDidUpdate() {
    const { slug } = (this.props.match && this.props.match.params) || {};
    const venue = await this.props.venues.fetchItemBySlug(slug);

    try {
      const settings = await this.props.birthdays.getParties(slug);
      const admissions = settings?.admissions || [];

      const restored = await this.props.birthdays.validateAdmission(
        slug,
        settings
      );
      this.setState({
        venue,
        settings,
        admissions: admissions,
        information: settings?.information || [],
        selected: admissions.length === 1 ? 0 : null,
        isEnabled: true,
        ...restored,
      });
    } catch (e) {
      this.setState({ isEnabled: false });
    }
  }

  setDate = async (date, admissionProductIds, additionalGuestsProductIds) => {
    this.setState({
      date,
      selectedSession: null,
      loadingSessions: true,
    });

    const sessions = await this.props.birthdays.getSessions({
      date,
      admissionProductIds,
      additionalGuestsProductIds,
    });

    this.setState({ sessions: sessions, loadingSessions: false });
    this.setSelectedSession(sessions[0]);
  };

  setSelectedSession = (session) => {
    if (!session) {
      this.setState({
        selectedSession: null,
        numberOfGuests: 0,
      });

      return;
    }

    this.setState({
      selectedSession: session,
      numberOfGuests: session.guestsIncluded,
    });
  };

  openForm = (isoDate) => {
    const { user } = this.props.me;

    this.setState({
      form: {
        opened: true,
        options: {
          date: isoDate,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
        },
      },
    });
  };

  onSubmit = () => {
    this.setState({ loading: true, error: null });

    const selectedAdmission = this.state.admissions[this.state.selected];
    const admissionProductIds = selectedAdmission.admissionProductIds;
    const additionalGuestsProductIds =
      selectedAdmission.additionalGuestsProductIds;
    const date = this.state.date;
    const selectedSession = this.state.selectedSession;
    const numberOfGuests = this.state.numberOfGuests;
    const settings = this.state.settings;
    const selected = this.state.selected;

    this.props.birthdays.setAdmission(
      {
        admissionProductIds,
        additionalGuestsProductIds,
        date,
        selectedSession,
        numberOfGuests,
        selected,
      },
      settings
    );

    trackAdmissionAdded({
      selectedAdmission,
      selectedSession,
      date,
      numberOfGuests,
      venue: this.state.venue,
    });

    this.setState({
      loading: false,
      redirect: this.props.match.url.replace('tickets', 'addons'),
    });
  };

  onSelectAdmission = (index) => {
    this.setState({
      sessions: null,
      selectedSession: null,
      numberOfGuests: 0,
      selected: index,
      date: null,
    });
  };

  onFormSubmitted = () => {
    this.setState({
      redirect: this.props.match.url.replace('tickets', 'form-submitted'),
    });
  };

  sendFormData = (formData) => {
    return this.props.salesforce.sendFormData(formData);
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const { isEnabled, selectedSession, numberOfGuests } = this.state;
    if (isEnabled === null) return null;
    if (!isEnabled) return <NotFound />;

    const error = this.state.error && { errorMessage: this.state.error };
    const totals = {
      subtotal: getAdmissionSubtotal({ selectedSession, numberOfGuests }),
      tax: getAdmissionTax({ selectedSession, numberOfGuests }),
      ticketSubtotal: getAdmissionSubtotal({ selectedSession, numberOfGuests }),
      total: getAdmissionTotal({ selectedSession, numberOfGuests }),
    };
    return (
      <BirthdayCheckoutContainer
        attrs={this.getAttrs()}
        content={
          <>
            <NewCheckoutProgressBar title="Admission" total={3} current={1} />
            <NewCheckoutTicketsHeader
              venue={this.state.venue}
              title="Book a Birthday"
            />
            <BirthdayAdmissions
              admissions={this.state.admissions}
              date={this.state.date}
              setDate={this.setDate}
              loadingSessions={this.state.loadingSessions}
              numberOfGuests={this.state.numberOfGuests}
              setNumberOfGuests={(number) =>
                this.setState({ numberOfGuests: number })
              }
              sessions={this.state.sessions}
              selectedSession={this.state.selectedSession}
              setSelectedSession={this.setSelectedSession}
              selectedAdmission={this.state.selected}
              setSelectedAdmission={this.onSelectAdmission}
              openForm={this.openForm}
            />
            <OtherInformation contentfulInformation={this.state.information} />
            <BirthdayContactForm
              open={this.state.form.opened}
              options={this.state.form.options}
              setOpen={(open) => {
                this.setState({
                  form: {
                    opened: open,
                  },
                });
              }}
              venueName={this.state.venue?.name}
              onFormSubmitted={this.onFormSubmitted}
              sendFormData={this.sendFormData}
            />
          </>
        }
        summary={
          <NewCheckoutSummary
            stepNames={{
              tickets: 'Admission',
              addons: 'Add-ons',
              merchandise: 'Merchandise',
              checkout: 'Details & Confirmation',
            }}
            error={error}
            canSubmit={Boolean(this.state.numberOfGuests)}
            onSubmit={this.onSubmit}
            step={STEPS.Tickets}
            loading={this.state.loading}
            totals={totals}
          />
        }
      />
    );
  }
}
