import React from 'react';
import fetch from './fetch';

export default function awaitEntries(key) {
  return function (Component, methodName, descriptor) {
    if (methodName && descriptor) {
      return decorateMethod(key, descriptor);
    } else {
      return decorateComponent(key, Component);
    }
  };
}

function getEntries(data, key) {
  let entries = data.entries[key];
  entries = mapDates(entries);
  return entries;
}

function decorateMethod(key, descriptor) {
  const rawMethod = descriptor.value;
  let injected;
  descriptor.value = function (...args) {
    if (injected) {
      return rawMethod.apply(this, [...args, injected]);
    } else {
      fetch().then((data) => {
        injected = getEntries(data, key);
        if (injected) {
          this.forceUpdate();
        }
      });
    }
  };
  return descriptor;
}

function decorateComponent(key, Component) {
  return class Decorated extends React.Component {
    async componentDidMount() {
      const data = await fetch();
      const injected = getEntries(data, key);
      if (injected) {
        this.setState({
          [key]: injected,
        });
      }
    }

    render() {
      if (this.state) {
        return <Component {...this.state} {...this.props} />;
      } else if (Component.renderLoading) {
        return Component.renderLoading();
      } else {
        return null;
      }
    }
  };
}

function mapDates(entries) {
  return entries.map((entry) => {
    if (entry.date) {
      // Add time to format yyyy-mm-dd to parse as local time.
      const date = new Date(`${entry.date}T00:00`);
      date.setMinutes(date.getMinutes() + 4 * 60);
      entry = {
        ...entry,
        date: date,
      };
    }
    return entry;
  });
}
