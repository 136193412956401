import React from 'react';
import { inject } from 'mobx-react';

import NewCheckoutAddons from './Addons';
import { VenueAddons } from '../../Venue';

@inject('betaFeatures')
export default class AddonsScreenContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCheckoutAvailable: null,
    };
  }

  componentDidMount() {
    this.props.betaFeatures
      .fetch('Checkout Redesign')
      .then((isEnabled) => this.setState({ newCheckoutAvailable: isEnabled }));
  }

  render() {
    const { newCheckoutAvailable } = this.state;

    if (newCheckoutAvailable === null) return null;
    if (newCheckoutAvailable) return <NewCheckoutAddons {...this.props} />;
    if (!newCheckoutAvailable) return <VenueAddons {...this.props} />;
  }
}
