import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Screen } from 'public/helpers';
import { FullHeight } from 'common/components';
import { SignupForm, Spacer } from 'public/components';
import { Header } from 'semantic-ui-react';

export default class SignupReferrer extends Screen {
  constructor(props) {
    super(props);
    localStorage.referrer = props.match.params.code;
    this.state = {
      redirect: null,
    };
  }

  onSignupSuccess = () => {
    this.setState({
      redirect: '/',
    });
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const code = this.props.match.params.code;
    const attrs = this.getAttrs();
    attrs.className += ` ${code}`;
    return (
      <div {...attrs}>
        <FullHeight center>
          <div className={this.getElementClass('form')}>
            <Header>Create an Account</Header>
            {code === 'bergen' && <p>Bergen welcomes you to American Dream!</p>}
            <SignupForm onSuccess={this.onSignupSuccess} />
            <Spacer size="s" />
            <p className="ui centered content">
              Already have an account? <NavLink to="/login">Sign in</NavLink>
            </p>
          </div>
        </FullHeight>
      </div>
    );
  }
}
