import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Navigation from 'swiper/modules/navigation.mjs';
import Pagination from 'swiper/modules/pagination.mjs';
import EffectCards from 'swiper/modules/effect-creative.mjs';

import {
  LazyVisible,
  useClassName,
  Only,
  usePreload,
  preloadMedia,
  preloadAssets,
} from 'common/hooks';
import { ContentfulRichText } from 'common/components';

import { usePagination } from '../../Components/usePagination';
import HomepageButton from '../../Components/Button';
import { useNext, usePrev } from '../../Components/useNavigation';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import 'swiper/modules/effect-creative.less';
import './news.less';

export default function NewsSection({ section }) {
  const classNames = useClassName('NewsSection');
  const ref = useRef();
  const [navigationPrevRef, prev] = usePrev({
    className: classNames('navigation'),
  });
  const [navigationNextRef, next] = useNext({
    className: classNames('navigation'),
  });
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  usePreload((isDesktop) => {
    preloadMedia(section.fields.media, isDesktop);
    preloadAssets(section.fields.icon);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <Only desktop>
        <HomepageImage field={section.fields.icon} />
      </Only>
      <div className={classNames('header')}>
        <h3>{section.fields.title}</h3>
        <span>
          <ContentfulRichText value={section.fields.subtitle} />
        </span>
      </div>

      <Swiper
        modules={[Navigation, Pagination, EffectCards]}
        height={500}
        slidesPerView={1}
        breakpoints={{
          1128: {
            slidesPerView: 2,
            centeredSlides: true,
            loop: true,
          },
        }}
        centeredSlides={false}
        loop={false}
        className={classNames('wrapper')}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        onSlideChange={({ activeIndex }) =>
          trackHomepageSlide(() => ({
            activeIndex,
            name: section.fields.media[activeIndex].fields.name,
            section: section.fields.sectionId,
          }))
        }
        {...pagination}>
        {section.fields.media.map(({ fields, sys }) => (
          <SwiperSlide key={sys.id} className={classNames('slide-container')}>
            {({ isActive }) => (
              <HomepageButton
                cta={fields.callToAction}
                className={classNames('slide')}>
                <HomepageImage
                  field={fields.desktop}
                  mobile={fields.mobile}
                  style={{
                    opacity: isActive ? '1' : '.4',
                  }}
                />
              </HomepageButton>
            )}
          </SwiperSlide>
        ))}
        {prev}
        {next}
      </Swiper>
    </LazyVisible>
  );
}
