import React from 'react';
import { Responsive } from 'semantic-ui-react';

export default class MobileOnly extends React.Component {
  render() {
    return (
      <Responsive
        maxWidth={Responsive.onlyMobile.maxWidth - 1}
        {...this.props}
      />
    );
  }
}
