import { sumBy } from 'lodash';

const getQuantityTotals = (cartItems, category) => {
  return cartItems.reduce((totals, cartItem) => {
    for (const inventoryItem of cartItem.tickets) {
      const { ticketOptionId, reportingCategoryName, price } = inventoryItem;
      const { quantities } = cartItem;
      const quantity = quantities[ticketOptionId];
      if (cartItem.bundleSlug) continue;
      if (reportingCategoryName !== category) continue;

      const product = totals.find((a) => a.ticketOptionId === ticketOptionId);
      if (product) {
        product.quantity = product.quantity + quantity;
      } else {
        totals.push({ ticketOptionId, quantity, price });
      }
    }

    return totals;
  }, []);
};

export const getDiscountValues = (
  cartItems,
  discountUsage,
  reportingCategoryName
) => {
  const quantityTotals = getQuantityTotals(cartItems, reportingCategoryName);

  const { usageLimits } = discountUsage;
  const productQuantity = sumBy(quantityTotals, (a) => a.quantity);

  const availables = usageLimits.limit - usageLimits.numberOfUses;

  if (productQuantity <= 1)
    return {
      quantity: productQuantity,
      discount: 0,
      maximumDiscountReached: false,
    };

  const needed = Math.floor(productQuantity / 2);
  const applied = Math.min(needed, availables);

  const { discount } = quantityTotals
    .sort((a, b) => (a.price > b.price ? 1 : -1))
    .reduce(
      ({ toApply, discount }, item) => {
        if (toApply <= 0) return { toApply, discount };

        const itemQuantityApplied = Math.min(toApply, item.quantity);
        return {
          toApply: toApply - itemQuantityApplied,
          discount: discount + itemQuantityApplied * item.price,
        };
      },
      { toApply: applied, discount: 0 }
    );

  return {
    quantity: productQuantity,
    discount: discount,
    maximumDiscountReached: needed > availables,
  };
};

export const isTwoForOneDiscount = ({ percentOff, amountOff, productIds }) =>
  !amountOff && !percentOff && productIds.length === 1;
