import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import './button.less';

const Button = (props) => {
  const className = useClassName('Button');
  const { label, primary, disabled, venueName, handleClick } = props;
  return (
    <button
      onClick={handleClick}
      className={`${className([
        'button',
        `button-${primary ? `primary` : 'secondary'}${
          venueName ? `-${venueName}` : ''
        }`,
        `button-${disabled ? 'disabled' : 'enabled'}`,
      ])} ${props.className}`}
      disabled={disabled}>
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  primary: PropTypes.any,
  disabled: PropTypes.any,
  venueName: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  primary: false,
  disabled: false,
  venueName: '',
};

export default Button;
