import React from 'react';
import { Screen, withVenue, getCustomMetadata } from 'public/helpers';
import { ResponsiveImage } from 'common/components';
import {
  getContentfulField,
  getContentfulAssetUrlandAlt,
} from 'common/components';

import './home.less';
import './map.less';

const metaSublinkId = 'map';

const MAP_OUTER_SPACE = 24;

@withVenue('dreamworks-water-park')
export default class DreamWorksMap extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      customMetadata: null,
      loading: true,
      extraImage: null,
    };
  }

  async getMetadata() {
    const customMetadata = await getCustomMetadata(
      this.props.venue,
      metaSublinkId
    );
    this.setState({ customMetadata, loading: false });
  }

  componentDidMount() {
    this.getMetadata();
    const { venue } = this.props;
    const infoBlocks = getContentfulField(venue.content.infoBlocks).filter(
      (ib) => !!ib.fields.extraImage
    );
    const infoBlock = infoBlocks.find(
      (infoBlock) =>
        getContentfulAssetUrlandAlt(infoBlock.fields.extraImage)?.assetAlt ===
        'map'
    );
    if (infoBlock) {
      const extraImage = getContentfulAssetUrlandAlt(
        infoBlock.fields.extraImage
      );
      this.setState({ extraImage });
    }
  }

  renderBody() {
    const { extraImage } = this.state;

    return (
      <div {...this.getAttrs()}>
        <div className={this.getElementClass('map-container')}>
          <ResponsiveImage
            src={extraImage && extraImage.assetUrl}
            width={window.innerWidth - MAP_OUTER_SPACE}
          />
        </div>
      </div>
    );
  }
}
