import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb';
import { Component } from 'common/helpers';

import './nav.less';

const ROUTES = [
  {
    text: 'My Bookings',
    value: '/profile/orders',
  },
  {
    text: 'Email Prefs',
    value: '/profile/preferences/email',
  },
  {
    text: 'Privacy Prefs',
    value: '/profile/preferences/privacy',
  },
];

export default class ProfileNav extends Component {
  onDropdownChange = (evt, data) => {
    this.props.history.push(data.value);
  };

  getCurrentRoute() {
    const { pathname } = this.props.location;
    const route = ROUTES.find((r) => r.value === pathname);
    return { title: route.text, url: pathname };
  }

  render() {
    return (
      <React.Fragment>
        <div className={this.getElementClass('container')}>
          <Breadcrumb links={[{ ...this.getCurrentRoute() }]} />
          <div className={this.getElementClass('menu')}>
            <ul {...this.getAttrs()}>
              {ROUTES.map((route) => {
                const { text, value } = route;
                return (
                  <li key={value}>
                    <NavLink
                      to={value}
                      className={this.getElementClass('link')}
                      activeClassName={this.getElementClass('link', 'active')}>
                      {text}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <Link to="/logout" className={this.getElementClass('sign-out')}>
              Sign Out
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
