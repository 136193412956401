import React from 'react';
import { inject } from 'mobx-react';

import { Screen } from 'public/helpers';
import { Spacer, CardContainer } from 'public/components';
import {
  getContentfulField,
  ContentfulImage,
  DesktopOnly,
  MobileOnly,
} from 'common/components';
import DreamsafeHero from './Hero';

import { Loader } from 'semantic-ui-react';

import './home.less';

@inject('pages')
export default class DreamSafeHome extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      screen: null,
      loading: true,
    };
  }

  getScreenContent = (screen, key, defaultValue) => {
    let result = defaultValue;
    const item = screen[key];
    if (item) result = item['en-US'];

    return result;
  };

  getContentfulText = (item) => {
    let result = item;
    let newItem;
    if (item) newItem = item['en-US'];
    if (newItem) result = newItem;
    return result;
  };

  getContentfulAssetUrlandAlt = (item) => {
    let assetUrl;
    let assetAlt;

    if (item) {
      const {
        fields: { file, title },
      } = item['en-US'];
      if (file) assetUrl = file['en-US'].url;
      if (title) assetAlt = title['en-US'];
    }

    return { assetUrl, assetAlt };
  };

  async componentDidMount() {
    try {
      const {
        object: { fields: screen },
      } = await this.props.pages.fetchItemBySlug('dreamsafe');
      this.setState({
        screen,
        loading: false,
      });
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
          loading: false,
        });
      } else {
        throw error;
      }
    }
  }

  render() {
    if (this.state.loading)
      return (
        <>
          <Spacer size="m" />
          <Loader inline="centered" active />
          <Spacer size="m" />
        </>
      );

    const poster = getContentfulField(this.state.screen.heroPoster);
    const file = poster ? getContentfulField(poster.fields.file) : null;
    const posterUrl = file ? file.url : null;

    const mobilePoster = getContentfulField(this.state.screen.mobileHeroPoster);
    const mobileFile = mobilePoster
      ? getContentfulField(mobilePoster.fields.file)
      : null;
    const mobilePosterUrl = mobileFile ? mobileFile.url : null;

    return (
      <div {...this.getAttrs()}>
        <DesktopOnly>
          <ContentfulImage
            field={this.state.screen.heroBackground}
            width={1024}
            className={this.getElementClass('hero-bg')}
          />
        </DesktopOnly>
        <MobileOnly>
          <ContentfulImage
            field={this.state.screen.mobileHeroBackground}
            width={375}
            className={this.getElementClass('hero-bg')}
          />
        </MobileOnly>
        <div className={this.getElementClass('main')}>
          <DreamsafeHero
            heroTitle={getContentfulField(this.state.screen.heroTitle)}
            heroDescription={getContentfulField(
              this.state.screen.heroDescription
            )}
            heroLogo={'dreamsafe'}
            heroPoster={posterUrl}
            mobileHeroPoster={mobilePosterUrl}
            heroVideo={this.getContentfulAssetUrlandAlt(
              this.state.screen.heroVideo
            )}
          />
          <div className={this.getElementClass('content')}>
            {this.renderSections(
              getContentfulField(this.state.screen.sections)
            )}
          </div>
        </div>
        <DesktopOnly>
          <ContentfulImage
            field={this.state.screen.bottomBackground}
            width={1024}
            className={this.getElementClass('bottom-bg')}
          />
        </DesktopOnly>
        <MobileOnly>
          <ContentfulImage
            field={this.state.screen.mobileBottomBackground}
            width={375}
            className={this.getElementClass('bottom-bg')}
          />
        </MobileOnly>
      </div>
    );
  }

  renderSections(sections) {
    return sections.map((section, index) => {
      const cards = getContentfulField(section.fields.cards);
      const id = getContentfulField(section.fields.name)
        .toLowerCase()
        .replace(/\s/g, '-');

      return (
        <CardContainer
          id={id}
          key={index}
          title={section.fields.title}
          description={section.fields.description}
          cards={cards}
        />
      );
    });
  }
}
