import React from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveImage } from 'common/components';
import { Screen } from 'public/helpers';
import { Headline, Spacer } from 'public/components';
import { FullHeight } from 'common/components';
import { Header, Grid, Button, Container } from 'semantic-ui-react';

import './home.less';

export default class WifiHome extends Screen {
  render() {
    return (
      <div {...this.getAttrs()}>
        <FullHeight center>
          <Spacer size="m" />
          <Container>
            <Spacer size="s" />
            <Headline
              title="Success, you're on"
              subtitle="American Dream Ultra Fast WiFi"
            />
            <Spacer size="m" />
            <Grid className="root" stackable>
              <Grid.Row divided>
                <Grid.Column width={8}>
                  Why not sign up for an account now for the best web
                  experience.
                  <Spacer size="s" />
                  <Button
                    as={Link}
                    to="/signup/referrer/wifi"
                    content="Create an Account"
                  />
                  <Spacer size="s" />
                  <Link to="/">Skip</Link>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h3">DOWNLOAD OUR APP</Header>
                  <Spacer size="s" />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Link to="/newsletter/subscribe/ios">
                          <ResponsiveImage
                            className="iphone-x"
                            contentfulName="iPhone X"
                            alt="iPhone X"
                            fluid
                          />
                        </Link>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        Get even more from your American Dream experience by
                        downloading our app.
                        <Spacer size="s" />
                        <Link to="/newsletter/subscribe/ios">
                          <ResponsiveImage
                            className="ios-app-store-badge"
                            contentfulName="iOS App Store Badge"
                            alt="iOS App Store Badge"
                            fluid
                          />
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Spacer size="l" />
        </FullHeight>
      </div>
    );
  }
}
