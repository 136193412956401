import React from 'react';
import qrcode from 'qrcode';
import { Button } from 'semantic-ui-react';
import { Component } from 'common/helpers';
import { Spacer } from '../Spacer';
import {
  Layout,
  Content,
  ResponsiveImage,
  SVGIcon as Icon,
} from 'common/components';
import { formatCurrency, formatDate, addDays } from 'utils/l10n';

import './ticket-card.less';

export default class TicketCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
    };
  }

  async componentDidMount() {
    this.setState({
      src: await qrcode.toDataURL(
        this.props.ticket.externalBookingId || this.props.ticket.issuedCode,
        {
          width: 240,
          margin: 0,
        }
      ),
    });
  }

  getTicketImage(venue) {
    return venue.ticketImage || venue.images[0];
  }

  render() {
    const { ticket } = this.props;
    const { ticketOption, venue } = ticket;
    const { src } = this.state;

    return (
      <Layout className={this.getComponentClass()} horizontal center stackable>
        <Layout.Group className={this.getElementClass('image')} fixed>
          <ResponsiveImage
            className="ticket-square-image"
            src={this.getTicketImage(venue)}
            fluid
            square
          />
          <Layout className={this.getElementClass('scan-container')} center>
            <div className={this.getElementClass('scan')}>
              {src && <img src={src} className={this.getElementClass('qr')} />}
              <p className={this.getElementClass('code')}>
                {this.props.ticket.externalBookingId ||
                  this.props.ticket.issuedCode}
              </p>
            </div>
          </Layout>
        </Layout.Group>
        <Layout.Group className={this.getElementClass('content')} grow>
          <Content tight>
            <dl>
              <dt size="small">Ticket:</dt>
              <dd>
                {ticket.ticketOption.quantity > 1
                  ? `${ticket.ticketOption.quantity} x`
                  : ''}{' '}
                {ticketOption.name}
              </dd>
              <dt size="small">Date:</dt>
              <dd>
                {ticket.passportType && ticket.passportValidDays > 1
                  ? `Valid from ${formatDate(
                      ticketOption.date
                    )} to ${formatDate(
                      addDays(ticketOption.date, ticket.passportValidDays)
                    )}`
                  : formatDate(ticketOption.date)}
              </dd>
              <dt size="small">Price:</dt>
              <dd>{formatCurrency(ticket.paidAmountUsd, true)}</dd>
              <dt size="small">Location:</dt>
              <dd>{ticket.venue.locationDescription}</dd>
            </dl>
          </Content>
          <Spacer size="xs" />
          <Button onClick={() => window.print()}>
            <Icon name="print" size="small" /> Print
          </Button>
          {/*
            <Button
              secondary
              fluid
              target="_blank"
              href={`${API_URL}/1/passkit/v1/passes/pass.com.americandream.ios.pem/${
                ticket.issuedCode
              }/download?authorizationToken=${
                ticket.passkitAuthorizationToken
              }`}>
              <Icon name="plus" /> Apple Wallet
            </Button>
        */}
        </Layout.Group>
      </Layout>
    );
  }
}
