import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ContentfulImage,
  ContentfulRichText,
  DesktopOnly,
  MobileOnly,
  SVGIcon,
} from 'common/components';
import { useClassName } from 'common/hooks';
import { useSnackbar } from 'public/components/Snackbar';

import './new-event-card.less';

const NewEventCard = ({ event, setSelected }) => {
  const [showShareSuccess, shareSnackbar] = useSnackbar();
  const classNames = useClassName('NewEventCard');

  const canShare = () => Boolean(navigator?.clipboard?.writeText);

  const share = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    navigator.clipboard
      .writeText(`${location.origin}/events/${event.slug}`)
      .then(function () {
        showShareSuccess(true);
      });
  };

  const renderActions = () => {
    return (
      <div className={classNames('actions')}>
        {canShare() && (
          <div onClick={share}>
            <SVGIcon name="share" />
          </div>
        )}
        {/*<div><SVGIcon name="calendar-add" /></div>*/}
      </div>
    );
  };

  return (
    <div onClick={setSelected} className={classNames('container')}>
      {shareSnackbar}
      <ContentfulImage
        field={event.images}
        width={382}
        className={classNames('image')}
      />
      <div className={classNames('content')}>
        <div className={classNames('title')}>
          <div className={classNames('info')}>
            <h4>{event.title}</h4>
            {event?.venue?.slug ? (
              <Link
                to={{ pathname: `/venue/${event?.venue?.slug}` }}
                smooth="true">
                {event?.venue?.name}
              </Link>
            ) : (
              <h6>{event?.locationDescription}</h6>
            )}
          </div>
          <DesktopOnly>{renderActions()}</DesktopOnly>
        </div>
        <ContentfulRichText
          field={event.shortDescription}
          className={classNames('details')}
        />
        <a
          href={event.url}
          className={classNames('link')}
          onClick={(ev) => ev.stopPropagation()}>
          {event.type}
        </a>
        <MobileOnly>{renderActions()}</MobileOnly>
      </div>
    </div>
  );
};

NewEventCard.propTypes = {
  event: PropTypes.object,
  setSelected: PropTypes.func,
};

export default NewEventCard;
