import { sum } from 'lodash';
import { DateTime } from 'luxon';

const getCartTotals = ({ cartItems, discountValue, fee }) => {
  const sumItems = (func, addons) => {
    return sum(
      cartItems
        .map((cartItem) => {
          const { tickets, quantities } = cartItem;

          return tickets
            .filter((ticket) => Boolean(ticket.addon) === addons)
            .map((ticket) => {
              return func(ticket, quantities);
            });
        })
        .flat()
    );
  };

  const getTicketsCount = () =>
    sumItems((ticket, quantities) => quantities[ticket.ticketOptionId], false);

  const getTicketsSubtotal = () =>
    sumItems(
      (ticket, quantities) => quantities[ticket.ticketOptionId] * ticket.price,
      false
    );

  const getTicketsTax = () =>
    sumItems(
      (ticket, quantities) => quantities[ticket.ticketOptionId] * ticket.tax,
      false
    );

  const getAddonsCount = () =>
    sumItems((ticket, quantities) => quantities[ticket.ticketOptionId], true);

  const getAddonsSubtotal = () =>
    sumItems(
      (ticket, quantities) => quantities[ticket.ticketOptionId] * ticket.price,
      true
    );

  const getAddonsTax = () =>
    sumItems(
      (ticket, quantities) => quantities[ticket.ticketOptionId] * ticket.tax,
      true
    );

  const getTax = () => {
    const tax = getTicketsTax() + getAddonsTax();
    if (!discountValue) return tax;

    const subtotal = getSubtotal();
    const taxRatio = tax / subtotal;
    return (subtotal - discountValue) * taxRatio;
  };

  const getSubtotal = () => getTicketsSubtotal() + getAddonsSubtotal();

  const getTotal = () => {
    return getSubtotal() + getTax() + (fee || 0) - (discountValue || 0);
  };

  return {
    subtotal: getSubtotal(),
    tax: getTax(),
    ticketCount: getTicketsCount(),
    ticketSubtotal: getTicketsSubtotal(),
    addonCount: getAddonsCount(),
    addonSubtotal: getAddonsSubtotal(),
    total: getTotal(),
    discount: discountValue,
    fee,
  };
};

const getBundleDates = ({
  passportType,
  reservationDate,
  passportValidDays,
}) => {
  if (!passportType) return null;

  const startDate = DateTime.fromISO(reservationDate);
  const endDate = startDate.plus({ days: passportValidDays - 1 });

  return [
    startDate.toLocaleString({ month: 'long', day: 'numeric' }),
    startDate.month === endDate.month
      ? endDate.toLocaleString({ day: 'numeric' })
      : endDate.toLocaleString({ month: 'long', day: 'numeric' }),
  ].join(' - ');
};

export { getCartTotals, getBundleDates };
