import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { BottomSheet } from 'public/components/BottomSheet';
import { useClassName } from 'common/hooks';
import image from 'common/assets/spongebob.png';
import pointsIcon from 'common/assets/points-ticket.svg';

import './points-details.less';

export default function PointsDetails() {
  const classNames = useClassName('PointsDetails');
  const [isOpen, setIsOpen] = useState(true);

  const title = <h3>How it works?</h3>;
  const subheader = (
    <h4>
      Purchase points on your mobile device now and go directly onto your
      ride(s) of choice! <b>No Wristbands Required!</b>
    </h4>
  );
  const description = (
    <>
      <p>
        <b>Step 1:</b> Select quantity of points passes. Each ride is either 3
        or 6 points.
      </p>
      <p>
        <b>Step 2:</b> Complete purchase to receive your mobile points pass via
        email. You can also add your points pass to your Apple or Google Wallet
        for easier accessibility.
      </p>
      <p>
        <b>Step 3:</b> Scan Points Pass by ride of your choice.
      </p>
    </>
  );
  return (
    <>
      <BottomSheet
        onClose={() => setIsOpen(false)}
        addon={
          <img className={classNames('addon')} src={image} alt="spongebob" />
        }
        content={
          <div className={classNames('container')}>
            {title}
            {subheader}
            {description}
            <Button
              fluid
              primary
              className="static"
              onClick={() => setIsOpen(false)}>
              Got it!
            </Button>
          </div>
        }
        isOpen={isOpen}
      />
      <div className={classNames('wrapper')}>
        <img src={pointsIcon} alt="points icon" />
        <div className={classNames('info')}>
          {title}
          {subheader}
        </div>
        <div className={classNames('description')}>{description}</div>
      </div>
    </>
  );
}
