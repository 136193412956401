import React from 'react';
import PropTypes from 'prop-types';
import { BottomSheet as SpringBottomSheet } from 'react-spring-bottom-sheet';

import { SVGIcon } from 'common/components';
import { useClassName } from 'common/hooks';

import 'react-spring-bottom-sheet/dist/style.css';
import './bottom-sheet.less';

const BottomSheet = ({
  isOpen,
  onClose,
  title,
  content,
  footer,
  onBack,
  addon,
}) => {
  const className = useClassName('BottomSheet');

  return (
    <SpringBottomSheet
      open={isOpen}
      onDismiss={onClose}
      initialFocusRef={false}
      className={className('container')}
      footer={footer}
      header={
        <div className={className('header')}>
          {addon}
          {Boolean(onBack) && (
            <div className={className('back')} onClick={onBack}>
              <SVGIcon name="angle-left" />
            </div>
          )}
          <h4>{title}</h4>
          <div className={className('close')} onClick={onClose}>
            <SVGIcon name="close" />
          </div>
        </div>
      }>
      {content}
    </SpringBottomSheet>
  );
};

BottomSheet.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  title: PropTypes.any,
  content: PropTypes.any,
  footer: PropTypes.any,
};

export default BottomSheet;
