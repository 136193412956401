import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';
import { ContentfulRichText, SVGIcon as Icon } from 'common/components';
import './instructions.less';

const Instructions = ({
  instructionKey,
  instructionRef,
  instructions,
  isVisible,
  handleResize,
}) => {
  const className = useClassName('Instructions');
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (!instructionRef.current || !isVisible) return;
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(instructionRef.current);
    return () => resizeObserver.disconnect();
  }, [isVisible]);

  const handleInstructionClicked = (key) => {
    const newExpanded = [...expanded];
    if (newExpanded.includes(key))
      return setExpanded(newExpanded.filter((element) => element !== key));
    newExpanded.push(key);
    setExpanded(newExpanded);
  };

  const renderInstructions = () => {
    const columns = [
      instructions.slice(0, Math.ceil(instructions.length / 2)),
      instructions.slice(
        Math.floor(instructions.length / 2),
        instructions.length
      ),
    ];

    return (
      <div className={className('instructions')}>
        {columns.map((column, index) => (
          <div
            key={`column-${index}`}
            className={className('instructions-column')}>
            {column.map((instruction, instructionIndex) => (
              <div
                key={`instruction-${instructionIndex}`}
                className={className('instruction')}>
                <div
                  className={className('instruction-title')}
                  onClick={() => handleInstructionClicked(instruction.key)}>
                  <span>{instruction.key}</span>
                  <div
                    className={className([
                      'chevron-container',
                      `chevron-container-${
                        expanded.includes(instruction.key) ? 'open' : 'closed'
                      }`,
                    ])}>
                    <Icon name="angle-down" size="mini" />
                  </div>
                </div>
                <div
                  className={className([
                    'instruction-description',
                    `instruction-description-${
                      expanded.includes(instruction.key) ? 'visible' : 'hidden'
                    }`,
                  ])}>
                  {instruction.value}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div ref={instructionRef} className={className('container')}>
      {!Array.isArray(instructions) ? (
        <ContentfulRichText field={instructions} />
      ) : (
        renderInstructions()
      )}
      {instructionKey === 'driving' && (
        <Link
          to="/parking"
          onClick={() => track('View parking information clicked')}
          className={className('parking-cta')}>
          View Parking Information
        </Link>
      )}
    </div>
  );
};

Instructions.propTypes = {
  instructionKey: PropTypes.string.isRequired,
  instructionRef: PropTypes.object.isRequired,
  instructions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isVisible: PropTypes.bool,
  handleResize: PropTypes.func.isRequired,
};

Instructions.defaultProps = {
  isVisible: false,
};

export default Instructions;
