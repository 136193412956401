import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'utils/l10n';

const GuestsInfo = ({
  selectedSession,
  maximumNumberOfGuests,
  openForm,
  date,
  className,
}) => {
  return (
    <div className={className}>
      {selectedSession.additionalGuests &&
      selectedSession.additionalGuests.capacityRemaining > 0 ? (
        <>
          <p>
            The first {selectedSession.guestsIncluded} guests are included.{' '}
            {selectedSession.additionalGuests
              ? `Additional guests, ${formatCurrency(
                  selectedSession.additionalGuests.cost
                )} per person.`
              : ''}
          </p>
          <p>
            The maximum number of guests for online booking is{' '}
            {maximumNumberOfGuests}.{' '}
            <span onClick={() => openForm(date)}>
              Contact our events team for larger parties.
            </span>
          </p>
        </>
      ) : (
        <>
          <p>
            The first {selectedSession.guestsIncluded} guests are included. No
            additional guests available.
          </p>
          <p>
            <span onClick={() => openForm(date)}>
              Do you wish to have a larger party?
            </span>
          </p>
        </>
      )}
    </div>
  );
};

GuestsInfo.propTypes = {
  selectedSession: PropTypes.object,
  maximumNumberOfGuests: PropTypes.number,
  openForm: PropTypes.func,
  date: PropTypes.string,
  className: PropTypes.string,
};

export default GuestsInfo;
