import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Modal from './Modal';
import Button from './Button';
import './end-game.less';

const EndGame = ({ venueName, handleEndGame }) => {
  const [showModal, setShowModal] = useState(false);
  const className = useClassName('EndGame');

  return (
    <div className={className('container')}>
      <button
        className={className(['end-button', `end-button-${venueName}`])}
        onClick={() => setShowModal(true)}>
        End Game
      </button>
      <Modal
        title="End Game"
        description="Are you sure you want to end the game?"
        isOpen={showModal}
        venueName={venueName}
        handleClose={() => setShowModal(false)}>
        <div className={className('ctas-container')}>
          <Button
            venueName={venueName}
            label="Yes"
            handleClick={handleEndGame}
            className={className('cta')}
          />

          <Button
            venueName={venueName}
            label="No"
            handleClick={() => setShowModal(false)}
            primary
            className={className(['cta', `cta-${venueName}`])}
          />
        </div>
      </Modal>
    </div>
  );
};

EndGame.propTypes = {
  venueName: PropTypes.string.isRequired,
  handleEndGame: PropTypes.func.isRequired,
};

export default EndGame;
