import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Location from './Location';
import Directions from './Directions';
import './visit.less';

const Visit = ({ hoursPage }) => {
  const className = useClassName('Visit');

  return (
    <div className={className('container')}>
      <div className={className('content')}>
        <Location hoursPage={hoursPage} />
        <Directions hoursPage={hoursPage} />
      </div>
    </div>
  );
};

Visit.propTypes = {
  hoursPage: PropTypes.object.isRequired,
};

export default Visit;
