import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Component } from 'common/helpers';

import './subnav-panel.less';

export default class SubNavPanel extends Component {
  render() {
    const className = this.getComponentClass();
    return (
      <CSSTransition in={true} timeout={1000} classNames={className} appear>
        <div className={className}>{this.props.children}</div>
      </CSSTransition>
    );
  }
}
