import React from 'react';
import PropTypes from 'prop-types';

import { isSoldOut, getQuantity } from 'common/helpers';
import { ProductPropType } from 'public/schema';

import { NewCheckoutProductOption } from '../../Components/ProductOption';

const Options = ({
  product,
  onDetail,
  setQuantity,
  quantities,
  startTime,
  blockDecrease,
  hideDescription,
}) => {
  const handleChange = (quantity) => setQuantity(product, quantity);

  return (
    <NewCheckoutProductOption
      images={[{ src: product.images, alt: product.name }]}
      name={product.name}
      price={product.price}
      description={product.description}
      isSoldOut={isSoldOut(product, startTime)}
      date={product.date}
      quantity={getQuantity(product, quantities)}
      min={product.minimumQuantity}
      onChangeQuantity={handleChange}
      onDetail={() => onDetail(product)}
      largeImage
      blockDecrease={blockDecrease}
      caption={product.caption}
      hideDescription={hideDescription}
      hours={product.hours}
    />
  );
};

Options.propTypes = {
  product: PropTypes.shape(ProductPropType).isRequired,
  onDetail: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  quantities: PropTypes.object,
  startTime: PropTypes.string,
  blockDecrease: PropTypes.bool,
  hideDescription: PropTypes.bool,
};

export default Options;
