// TODO: merge with public/components/Modal or override
import React from 'react';
import { Modal } from 'semantic-ui-react';
import './modal.less';
import { Component } from 'common/helpers';

export default class ModalWrapper extends Component {
  render() {
    const { className, black, children, ...rest } = this.props;
    return React.Children.only(
      <Modal
        closeOnEscape
        className={['common', className, black ? 'black' : false]
          .filter(Boolean)
          .join(' ')}
        closeIcon={<span className="close">&times;</span>}
        {...rest}>
        <Modal.Content>{children}</Modal.Content>
      </Modal>
    );
  }
}
