import { DateTime } from 'luxon';

import {
  getContentfulField as getField,
  getContentfulAssetUrlandAlt as getAsset,
} from 'common/components';
import { formatStartAndEndDate } from 'utils/helpers';
import {
  DEFAULT_MAINTENANCE_REASON,
  TEMPORARILY_CLOSED_REASON,
} from 'public/components/AttractionScreen/RideClosures';

const getClosedMessage = (temporarilyClosed, closedDates) => {
  if (temporarilyClosed && getField(temporarilyClosed)) {
    return TEMPORARILY_CLOSED_REASON;
  }

  if (!closedDates?.length) return null;

  const now = DateTime.local();
  const closed = closedDates.find(
    ({ startDate, endDate }) => now >= startDate && now <= endDate
  );

  if (!closed) return null;

  return `${
    closed.reason || DEFAULT_MAINTENANCE_REASON
  } (${formatStartAndEndDate(closed.startDate, closed.endDate)})`;
};

const getSchedule = (schedule) => {
  if (!schedule?.fields) return undefined;

  const { temporarilyClosed, closedDates } = schedule.fields;

  const dates = closedDates
    ? getField(closedDates).map(({ fields }) => {
        return {
          reason: fields.reason
            ? getField(getField(fields.reason).fields.value)
            : DEFAULT_MAINTENANCE_REASON,
          startDate: DateTime.fromISO(getField(fields.startDate), {
            zone: 'America/New_York',
          }).startOf('day'),
          endDate: DateTime.fromISO(getField(fields.endDate), {
            zone: 'America/New_York',
          }).endOf('day'),
        };
      })
    : [];
  return {
    temporarilyClosed: temporarilyClosed && getField(temporarilyClosed),
    closedDates: dates,
    message: getClosedMessage(temporarilyClosed, dates),
  };
};

const getCards = (cards) => {
  let cardsList = getField(cards);

  if (!cardsList || !cardsList.length) return null;

  cardsList = cardsList.map((c) => {
    const card = c.fields;

    const title = card.title;
    const cardTitle = card.cardTitle && getField(card.cardTitle);
    const description = card.description;
    const theme = card.theme && getField(card.theme);
    const category = card.category && getField(card.category);
    const categories = card.categories && getField(card.categories);
    const author = card.author && getField(card.author);
    const ctaLink = card.ctaLink && getField(card.ctaLink);
    const ctaExternal = card.ctaExternal && getField(card.ctaExternal);
    const ctaText = card.ctaText && getField(card.ctaText);
    const ctaColor = card.ctaColor && getField(card.ctaColor);
    const image = card.image && getAsset(card.image);
    const name = card.name && getField(card.name);
    const slug = card.slug && getField(card.slug);
    const schedule = card.schedule && getSchedule(getField(card.schedule));
    const points = card.points && getField(card.points);

    const infoCard = {
      title,
      cardTitle,
      description,
      theme,
      category,
      categories,
      author,
      image,
      name,
      slug,
      schedule,
      points,
      ...(ctaLink && { cta: { ctaLink, ctaText, ctaColor, ctaExternal } }),
    };

    return infoCard;
  });

  return cardsList;
};

const getImages = (images) => {
  let imagesList = getField(images);

  if (!imagesList || !imagesList.length) return null;
  imagesList = imagesList.map((i) => {
    const image = i.fields;
    const title = image.title && getField(image.title);
    const asset = image.file && getField(image.file);
    const file = asset?.url;

    return {
      file,
      title,
    };
  });

  return imagesList;
};

const getDetailedRides = (rides) => {
  let ridesList = getField(rides);

  if (!ridesList || !ridesList.length) return null;

  ridesList = ridesList.map((r) => {
    const item = r.fields;

    const title = item.title;
    const image = item.image && getAsset(item.image);
    const fullDescription = item.fullDescription;
    const heroPoster = item.heroPoster && getAsset(item.heroPoster);
    const heroVideo = item.heroVideo && getAsset(item.heroVideo);
    const slug = item.slug && getField(item.slug);
    const height = item.height && getField(item.height);
    const tags = item.tags && getField(item.tags);
    const galleryTitle = item.galleryTitle;
    const gallery = item.gallery && getImages(item.gallery);
    const rideInfoTitle = item.rideInfoTitle;
    const rideInfoDescription = item.rideInfoDescription;

    const ride = {
      title,
      image,
      fullDescription,
      slug,
      heroVideo,
      heroPoster,
      tags,
      height,
      galleryTitle,
      gallery,
      rideInfoTitle,
      rideInfoDescription,
    };

    return ride;
  });

  return ridesList;
};

const getLinks = (links) => {
  let linksList = getField(links);

  if (!linksList || !linksList.length) return null;
  linksList = linksList.map((l) => {
    const link = l.fields;
    const name = link.name && getField(link.name);
    const url = link.link && getField(link.link);
    const logo = link.logo && getAsset(link.logo);

    return {
      name,
      url,
      logo,
    };
  });

  return linksList;
};

const getTheme = (fields) => {
  const contentfulTheme = getField(fields?.theme);
  const theme = contentfulTheme.fields;

  return {
    primaryColor: theme.primaryColor && getField(theme.primaryColor),
    secondaryColor: theme.secondaryColor && getField(theme.secondaryColor),
    tertiaryColor: theme.tertiaryColor && getField(theme.tertiaryColor),
    buttonColor: theme.buttonColor && getField(theme.buttonColor),
    buttonSecondaryColor:
      theme.buttonSecondaryColor && getField(theme.buttonSecondaryColor),
    textColor: theme.textColor && getField(theme.textColor),
    buttonTextColor: theme.buttonTextColor && getField(theme.buttonTextColor),
    buttonSecondaryTextColor:
      theme.buttonSecondaryTextColor &&
      getField(theme.buttonSecondaryTextColor),
    customHeroPrimary:
      theme.customHeroPrimary && getField(theme.customHeroPrimary),
    customHeroSecondary:
      theme.customHeroSecondary && getField(theme.customHeroSecondary),
    customHeroTextColor:
      theme.customHeroTextColor && getField(theme.customHeroTextColor),
    customDarkTitleColor:
      theme.customDarkTitleColor && getField(theme.customDarkTitleColor),
  };
};

const parseTicketOptions = (ticketOptions) => {
  if (!ticketOptions) return [];

  return getField(ticketOptions).map(({ fields }) => {
    return {
      link: getField(fields.link),
      name: getField(fields.name),
      type: getField(fields.type),
      description: fields.description,
      ...(fields.logo && { icon: getAsset(fields.logo) }),
    };
  });
};

const parseAttraction = (contentModel) => {
  const fields = getField(contentModel)?.fields;
  if (!fields) {
    return null;
  }

  return {
    heroTitle: fields.heroTitle,
    heroSubtitle: fields.heroSubtitle,
    heroLogo: fields.heroLogo && getAsset(fields.heroLogo),
    heroVideo: fields.heroVideo && getAsset(fields.heroVideo),
    heroPoster: fields.heroVideoPoster && getAsset(fields.heroVideoPoster),
    heroCtaText: fields.heroCtaText && getField(fields.heroCtaText),
    heroCtaLink: fields.heroCtaLink && getField(fields.heroCtaLink),
    ticketOptions: parseTicketOptions(fields.ticketOptions),
    welcomeSectionPrimaryTitle: fields.welcomeSectionPrimaryTitle,
    welcomeSectionSecondaryTitle: fields.welcomeSectionSecondaryTitle,
    welcomeSectionImageMobile:
      fields.welcomeSectionImageMobile &&
      getAsset(fields.welcomeSectionImageMobile),
    welcomeSectionImageDesktop:
      fields.welcomeSectionImageDesktop &&
      getAsset(fields.welcomeSectionImageDesktop),
    exploreSectionTitle:
      fields.exploreSectionTitle && getField(fields.exploreSectionTitle),
    exploreSectionImageMobile:
      fields.exploreSectionImageMobile &&
      getAsset(fields.exploreSectionImageMobile),
    exploreSectionImageTablet:
      fields.exploreSectionImageTablet &&
      getAsset(fields.exploreSectionImageTablet),
    exploreSectionImageDesktop:
      fields.exploreSectionImageDesktop &&
      getAsset(fields.exploreSectionImageDesktop),
    factsSectionTitleFirstPart: fields.factsSectionTitleFirstPart,
    factsSectionTitleSecondPart: fields.factsSectionTitleSecondPart,
    factsSectionImage:
      fields.factsSectionImage && getAsset(fields.factsSectionImage),
    factsCards: fields.factsCards && getCards(fields.factsCards),
    ridesSectionTitleFirstPart: fields.ridesSectionTitleFirstPart,
    ridesSectionTitleSecondPart: fields.ridesSectionTitleSecondPart,
    ridesSectionImage:
      fields.ridesSectionImage && getAsset(fields.ridesSectionImage),
    ridesCategories: fields.ridesCategories && getField(fields.ridesCategories),
    ridesCards: fields.ridesCards && getCards(fields.ridesCards),
    ticketsSectionTitle: fields.ticketsSectionTitle,
    ticketsSectionSubtitle: fields.ticketsSectionSubtitle,
    ticketsSectionImage:
      fields.ticketsSectionImage && getAsset(fields.ticketsSectionImage),
    ticketsSectionCtaText:
      fields.ticketsSectionCtaText && getField(fields.ticketsSectionCtaText),
    ticketsSectionCtaLink:
      fields.ticketsSectionCtaLink && getField(fields.ticketsSectionCtaLink),
    infoSectionTitle: fields.infoSectionTitle,
    openHoursTitle: fields.openHoursTitle,
    openHoursIcon: fields.openHoursIcon && getAsset(fields.openHoursIcon),
    alertsTitle: fields.alertsTitle,
    alertsIcon: fields.alertsIcon && getAsset(fields.alertsIcon),
    infoLinks: fields.infoSectionLinks && getLinks(fields.infoSectionLinks),
    showCustomerReviews:
      fields.showCustomerReviews && getField(fields.showCustomerReviews),
    reviewsSectionTitle: fields.reviewsSectionTitle,
    reviewsSectionImage:
      fields.reviewsSectionImage && getAsset(fields.reviewsSectionImage),
    reviewsSectionCards: fields.reviewCards && getCards(fields.reviewCards),
    showInfoSection: fields.showInfoSection && getField(fields.showInfoSection),
    theme: getTheme(fields),
  };
};

const parseRides = (contentModel) => {
  const fields = getField(contentModel)?.fields;
  if (!fields) {
    return null;
  }

  return {
    ridesCards: fields.ridesCards && getDetailedRides(fields.ridesCards),
    theme: getTheme(fields),
  };
};

const parseLanding = (contentModel) => {
  const fields = contentModel?.object?.fields;
  if (!fields) {
    return null;
  }

  return {
    title: fields.title && getField(fields.title),

    heroTitle: fields.heroTitle,
    heroSubtitle: fields.heroSubtitle,
    heroLogo: fields.heroLogo && getAsset(fields.heroLogo),
    heroVideo: fields.heroVideo && getAsset(fields.heroVideo),
    heroPoster: fields.heroVideoPoster && getAsset(fields.heroVideoPoster),
    heroCtaText: fields.heroCtaText && getField(fields.heroCtaText),
    heroCtaLink: fields.heroCtaLink && getField(fields.heroCtaLink),

    welcomeSectionPrimaryTitle: fields.welcomeSectionPrimaryTitle,
    welcomeSectionSecondaryTitle: fields.welcomeSectionSecondaryTitle,
    welcomeSectionImageMobile:
      fields.welcomeSectionImageMobile &&
      getAsset(fields.welcomeSectionImageMobile),
    welcomeSectionImageDesktop:
      fields.welcomeSectionImageDesktop &&
      getAsset(fields.welcomeSectionImageDesktop),

    exploreSectionImageMobile:
      fields.exploreSectionImageMobile &&
      getAsset(fields.exploreSectionImageMobile),
    exploreSectionImageTablet:
      fields.exploreSectionImageTablet &&
      getAsset(fields.exploreSectionImageTablet),
    exploreSectionImageDesktop:
      fields.exploreSectionImageDesktop &&
      getAsset(fields.exploreSectionImageDesktop),

    factsSectionTitle: fields.factsSectionTitle,
    factsSectionImage:
      fields.factsSectionImage && getAsset(fields.factsSectionImage),
    factsCards: fields.factsCards && getCards(fields.factsCards),

    venueDetailCategories:
      fields.venueDetailCategories && getField(fields.venueDetailCategories),
    venueDetailCards:
      fields.venueDetailCards && getCards(fields.venueDetailCards),

    backgroundColor: fields.backgroundColor && getField(fields.backgroundColor),
    fontColor: fields.fontColor && getField(fields.fontColor),
    buttonColor: fields.buttonColor && getField(fields.buttonColor),
    buttonSecondaryColor:
      fields.buttonSecondaryColor && getField(fields.buttonSecondaryColor),
    buttonTextColor: fields.buttonTextColor && getField(fields.buttonTextColor),
    buttonSecondaryTextColor:
      fields.buttonSecondaryTextColor &&
      getField(fields.buttonSecondaryTextColor),
    backgroundImage: fields.backgroundImage && getAsset(fields.backgroundImage),
    venueDetailDefaultCategory:
      fields.venueDetailDefaultCategory &&
      getField(fields.venueDetailDefaultCategory),
  };
};

const parseVenueDetails = (contentModel) => {
  const fields = getField(contentModel)?.fields;
  if (!fields) {
    return null;
  }

  const slug = fields.slug && getField(fields.slug);
  const cardTitle = fields.cardTitle && getField(fields.cardTitle);
  const title = fields.title;
  const heroLogo = fields.heroLogo && getAsset(fields.heroLogo);
  const heroPoster = fields.heroPoster && getAsset(fields.heroPoster);
  const heroVideo = fields.heroVideo && getAsset(fields.heroVideo);
  const fullDescription = fields.fullDescription;
  const detailsLinks = fields.detailsLinks && getLinks(fields.detailsLinks);
  const type = fields.type && getField(fields.type);
  const image = fields.image && getAsset(fields.image);
  const galleryTitle = fields.galleryTitle;
  const gallery = fields.gallery && getImages(fields.gallery);

  return {
    slug,
    title,
    cardTitle,
    heroLogo,
    heroVideo,
    heroPoster,
    fullDescription,
    detailsLinks,
    type,
    image,
    galleryTitle,
    gallery,
  };
};

const getVenueDetailType = (venueDetail) => {
  const fields = getField(venueDetail)?.fields;
  if (!fields) {
    return null;
  }

  const type = fields.type && getField(fields.type);

  return type;
};

const parseTextProperties = (contentModel) => {
  const text = getField(contentModel);
  const body = text?.fields?.body && getField(text.fields.body);
  const richTextBody = text?.fields?.richTextBody;
  const fontSize = text?.fields?.fontSize && getField(text.fields.fontSize);
  const mobileFontSize =
    text?.fields?.mobileFontSize && getField(text.fields.mobileFontSize);
  const color = text?.fields?.color && getField(text.fields.color);
  const underline = text?.fields?.underline && getField(text.fields.underline);
  const bold = text?.fields?.bold && getField(text.fields.bold);
  const italic = text?.fields?.italic && getField(text.fields.italic);
  const titleType = text?.fields?.titleType && getField(text.fields.titleType);
  const whiteLink = text?.fields?.whiteLink && getField(text.fields.whiteLink);

  return {
    body,
    richTextBody,
    fontSize,
    mobileFontSize,
    color,
    underline,
    bold,
    italic,
    titleType,
    whiteLink,
  };
};

const parseInputThemeProperties = (contentModel) => {
  const inputTheme = getField(contentModel);
  const background =
    inputTheme?.fields?.background && getField(inputTheme.fields.background);
  const hoverBackground =
    inputTheme?.fields?.hoverBackground &&
    getField(inputTheme.fields.hoverBackground);
  const disabledBackground =
    inputTheme?.fields?.disabledBackground &&
    getField(inputTheme.fields.disabledBackground);
  const textColor =
    inputTheme?.fields?.textColor && getField(inputTheme.fields.textColor);
  const hoverTextColor =
    inputTheme?.fields?.hoverTextColor &&
    getField(inputTheme.fields.hoverTextColor);
  const disabledTextColor =
    inputTheme?.fields?.disabledTextColor &&
    getField(inputTheme.fields.disabledTextColor);
  const placeHoldeColor =
    inputTheme?.fields?.placeHoldeColor &&
    getField(inputTheme.fields.placeHoldeColor);
  const borderColor =
    inputTheme?.fields?.borderColor && getField(inputTheme.fields.borderColor);
  return {
    background,
    hoverBackground,
    disabledBackground,
    textColor,
    hoverTextColor,
    disabledTextColor,
    placeHoldeColor,
    borderColor,
  };
};

const parseCTAProperties = (contentModel) => {
  const cta = !contentModel.fields ? getField(contentModel) : contentModel;
  const label = cta?.fields?.label && getField(cta.fields.label);
  const size = cta?.fields?.size && getField(cta.fields.size);
  const customWidth =
    cta?.fields?.customWidth && getField(cta.fields.customWidth);
  const customFontSize =
    cta?.fields?.customFontSize && getField(cta.fields.customFontSize);
  const value = cta?.fields?.value && getField(cta.fields.value);
  const isLink = cta?.fields?.isLink && getField(cta.fields.isLink);
  const ctaType = cta?.fields?.ctaType && getField(cta.fields.ctaType);
  const rounded = cta?.fields?.rounded && getField(cta.fields.rounded);
  const theme =
    cta?.fields?.theme && parseInputThemeProperties(cta.fields.theme);
  const assetObject = cta?.fields?.icon && getField(cta.fields.icon);
  const icon = assetObject && getAsset(assetObject.fields.desktopMedia);
  const scrollLink = cta?.fields?.scrollLink && getField(cta.fields.scrollLink);
  const spacerSize = cta?.fields?.spacerSize && getField(cta.fields.spacerSize);

  return {
    label,
    size,
    customWidth,
    customFontSize,
    value,
    isLink,
    ctaType,
    rounded,
    theme,
    icon,
    scrollLink,
    spacerSize,
  };
};

const getAssetMedia = (assetObject) => {
  if (!assetObject) return null;
  return {
    link: assetObject?.fields?.link && getField(assetObject.fields.link),
    desktopMedia:
      assetObject?.fields?.desktopMedia &&
      getAsset(assetObject.fields.desktopMedia),
    mobileMedia:
      assetObject?.fields?.mobileMedia &&
      getAsset(assetObject.fields.mobileMedia),
    label: assetObject?.fields?.label && getField(assetObject.fields.label),
    iframeUrl:
      assetObject?.fields?.iframeUrl && getField(assetObject.fields.iframeUrl),
    color: assetObject?.fields?.color && getField(assetObject.fields.color),
    textBlock:
      assetObject?.fields?.textBlock &&
      parseTextBlock(getField(assetObject.fields.textBlock)),
    desktopHeight:
      assetObject?.fields?.desktopHeight &&
      getField(assetObject.fields.desktopHeight),
    mobileHeight:
      assetObject?.fields?.mobileHeight &&
      getField(assetObject.fields.mobileHeight),
  };
};

const parseImageGallery = (imageGallery) => {
  const images = getField(imageGallery.fields.images);
  return {
    name: imageGallery.fields?.name && getField(imageGallery.fields.name),
    isGallery:
      imageGallery?.fields?.isGallery &&
      getField(imageGallery?.fields?.isGallery),
    isSlider:
      imageGallery?.fields?.isSlider &&
      getField(imageGallery?.fields?.isSlider),
    isCarousel:
      imageGallery?.fields?.isCarousel &&
      getField(imageGallery?.fields?.isCarousel),
    isImageCardGallery:
      imageGallery?.fields?.isImageCardGallery &&
      getField(imageGallery?.fields?.isImageCardGallery),
    isFeaturesList:
      imageGallery?.fields?.isFeaturesList &&
      getField(imageGallery?.fields?.isFeaturesList),
    images: images && images.map((image) => getAssetMedia(image)),
    category:
      imageGallery?.fields?.category &&
      getField(imageGallery?.fields?.category),
    spacerSize:
      imageGallery?.fields?.spacerSize &&
      getField(imageGallery?.fields?.spacerSize),
    backgroundColor:
      imageGallery?.fields?.backgroundColor &&
      getField(imageGallery?.fields?.backgroundColor),
    backgroundSecondaryColor:
      imageGallery?.fields?.backgroundColor &&
      getField(imageGallery?.fields?.backgroundSecondaryColor),
    backgroundImage:
      imageGallery?.fields?.backgroundImage &&
      getAsset(imageGallery?.fields?.backgroundImage),
  };
};

const parseMenuBar = (menuBar) => {
  return {
    name: menuBar?.fields?.name && getField(menuBar?.fields?.name),
    categories:
      menuBar?.fields?.categories && getField(menuBar?.fields?.categories),
    position: menuBar?.fields?.position && getField(menuBar?.fields?.position),
    spacerSize:
      menuBar?.fields?.spacerSize && getField(menuBar?.fields?.spacerSize),
  };
};

const parsePricingCards = (pricingCards) => {
  const cards = getField(pricingCards.fields.cards);
  return {
    name: pricingCards?.fields?.name && getField(pricingCards?.fields?.name),
    category:
      pricingCards?.fields?.category &&
      getField(pricingCards?.fields?.category),
    cards:
      cards &&
      cards.map((card) => {
        if (!card.fields) return null;

        const title = card.fields.title && getField(card.fields.title);
        const description =
          card.fields.description && getField(card.fields.description);
        const body = card.fields.body && getField(card.fields.body);
        const regularPrice =
          card.fields.regularPrice && getField(card.fields.regularPrice);
        const peakPrice =
          card.fields.peakPrice && getField(card.fields.peakPrice);
        const tooltipPeak =
          card.fields.tooltipPeak && getField(card.fields.tooltipPeak);
        const asset =
          card.fields.asset && getAssetMedia(getField(card.fields.asset));
        const imageGallery =
          card.fields.imageGallery &&
          parseImageGallery(getField(card.fields.imageGallery));
        const spacerSize =
          card?.fields?.spacerSize && getField(card.fields.spacerSize);

        return {
          title,
          description,
          body,
          regularPrice,
          peakPrice,
          tooltipPeak,
          asset,
          imageGallery,
          spacerSize,
        };
      }),
  };
};

const parseCollapsibleList = (collapsibleList) => {
  const list = collapsibleList?.fields?.list
    ? getField(collapsibleList.fields.list)
    : [];
  const name =
    collapsibleList?.fields?.name && getField(collapsibleList.fields.name);
  const category =
    collapsibleList?.fields?.category &&
    getField(collapsibleList.fields.category);
  const items =
    list &&
    list.map((item) => {
      const key = item?.fields?.key && getField(item.fields.key);
      const richTextValue = item?.fields?.richTextValue;
      const value = item?.fields?.value && getField(item.fields.value);
      return { key, richTextValue, value };
    });
  const initiallyCollapsed =
    collapsibleList?.fields?.initiallyCollapsed &&
    getField(collapsibleList.fields.initiallyCollapsed);
  const cta =
    collapsibleList?.fields?.cta &&
    parseCTAProperties(getField(collapsibleList.fields.cta));
  const spacerSize =
    collapsibleList?.fields?.spacerSize &&
    getField(collapsibleList.fields.spacerSize);
  return {
    name,
    category,
    items,
    initiallyCollapsed,
    cta,
    spacerSize,
  };
};

const parseTextBlock = (textBlock) => {
  const name = textBlock?.fields?.name && getField(textBlock?.fields?.name);
  const title =
    textBlock?.fields?.title && parseTextProperties(textBlock?.fields?.title);
  const body =
    textBlock?.fields?.body && parseTextProperties(textBlock?.fields?.body);
  const overline =
    textBlock?.fields?.overline &&
    parseTextProperties(textBlock?.fields?.overline);
  const textAlign =
    textBlock?.fields?.textAlign && getField(textBlock?.fields?.textAlign);
  const backgroundColor =
    textBlock?.fields?.backgroundColor &&
    getField(textBlock?.fields?.backgroundColor);
  const backgroundSecondaryColor =
    textBlock?.fields?.backgroundColor &&
    getField(textBlock?.fields?.backgroundSecondaryColor);
  const asset =
    textBlock?.fields?.asset &&
    getAssetMedia(getField(textBlock?.fields?.asset));
  const assetPosition =
    textBlock?.fields?.assetPosition &&
    getField(textBlock?.fields?.assetPosition);
  const ctaArray = textBlock?.fields?.cta && getField(textBlock?.fields?.cta);
  const cta = ctaArray && ctaArray.map((cta) => parseCTAProperties(cta));
  const sliderObject =
    textBlock?.fields?.slider && getField(textBlock?.fields?.slider);
  const slider = sliderObject && parseImageGallery(sliderObject);
  const isCtaBlock =
    textBlock?.fields?.isCtaBlock && getField(textBlock?.fields?.isCtaBlock);
  const isHero =
    textBlock?.fields?.isHero && getField(textBlock?.fields?.isHero);
  const category =
    textBlock?.fields?.category && getField(textBlock?.fields?.category);
  const spacerSize =
    textBlock?.fields?.spacerSize && getField(textBlock?.fields?.spacerSize);
  const backgroundImage =
    textBlock?.fields?.backgroundImage &&
    getAsset(textBlock?.fields?.backgroundImage);

  return {
    name,
    title,
    body,
    overline,
    textAlign,
    backgroundColor,
    backgroundSecondaryColor,
    asset,
    assetPosition,
    cta,
    slider,
    isCtaBlock,
    isHero,
    category,
    spacerSize,
    backgroundImage,
  };
};

const parseKeyValueArray = (keyValues) =>
  keyValues.map((keyValue) => ({
    key: getField(keyValue.fields.key),
    value: getField(keyValue.fields.value),
  }));

const parseAnnouncement = (announcement) => {
  const { fields } = announcement?.object;

  if (!fields) return null;

  const name = fields.link && getField(fields.name);
  const link = fields.link && getField(fields.link);
  const linkText = fields.linkText && getField(fields.linkText);
  const image = fields.image && getAsset(fields.image);
  const mobileImage = fields.image && getAsset(fields.image);
  const logo = fields.logo && getAsset(fields.logo);
  const order = fields.order && getField(fields.order);
  const backgroundColor =
    fields.backgroundColor && getField(fields.backgroundColor);
  const type = fields.type && getField(fields.type);
  const ctaType = fields.ctaType && getField(fields.ctaType);
  const heading = fields.heading && getField(fields.heading);
  const body = fields.body;
  const withGradient = fields.withGradient && getField(fields.withGradient);

  return {
    name,
    link,
    linkText,
    image,
    mobileImage,
    logo,
    order,
    backgroundColor,
    type,
    ctaType,
    heading,
    body,
    withGradient,
  };
};

const parseCallOuts = (callOuts) =>
  callOuts.map((callOut) => {
    const { fields } = callOut;
    const body = fields.body;
    const backgroundColor =
      fields.backgroundColor && getField(fields.backgroundColor);
    const textColor = fields.textColor && getField(fields.textColor);
    const ctaText = fields.ctaText && getField(fields.ctaText);
    const ctaAction = fields.ctaAction && getField(fields.ctaAction);
    const displayOn = fields.displayOn && getField(fields.displayOn);
    const displayOnDates =
      fields.displayOnDates && getField(fields.displayOnDates);
    const iconType = fields.iconType && getField(fields.iconType);
    return {
      body,
      backgroundColor,
      textColor,
      ctaText,
      ctaAction,
      displayOn,
      displayOnDates,
      iconType,
    };
  });

const parseHoursPage = (hoursPage) => {
  const { fields } = hoursPage?.object;

  if (!fields) return null;

  const title = fields.title && getField(fields.title);
  const overline = fields.overline && getField(fields.overline);
  const desktopImage = fields.desktopImage && getAsset(fields.desktopImage);
  const mobileImage = fields.mobileImage && getAsset(fields.mobileImage);
  const heroBackground =
    fields.heroBackground && getField(fields.heroBackground);
  const openHoursMessage = fields.openHoursMessage;
  const callOut = fields.callOut;
  const callOuts = fields.callOuts && parseCallOuts(getField(fields.callOuts));
  const locationTitle = fields.locationTitle && getField(fields.locationTitle);
  const locationDescription = fields.locationDescription;
  const address = fields.address && getField(fields.address);
  const googleMapsLink =
    fields.googleMapsLink && getField(fields.googleMapsLink);
  const appleMapsLink = fields.appleMapsLink && getField(fields.appleMapsLink);
  const mapDesktopImage =
    fields.mapDesktopImage && getAsset(fields.mapDesktopImage);
  const mapMobileImage =
    fields.mapMobileImage && getAsset(fields.mapMobileImage);
  const directionsTitle =
    fields.directionsTitle && getField(fields.directionsTitle);
  const drivingInstructions =
    fields.drivingInstructions &&
    parseKeyValueArray(getField(fields.drivingInstructions));
  const rideshareInstructions = fields.rideshareInstructions;
  const publicTransportationInstructions =
    fields.publicTransportationInstructions &&
    parseKeyValueArray(getField(fields.publicTransportationInstructions));

  return {
    title,
    overline,
    desktopImage,
    mobileImage,
    heroBackground,
    openHoursMessage,
    callOut,
    callOuts,
    locationTitle,
    locationDescription,
    address,
    googleMapsLink,
    appleMapsLink,
    mapDesktopImage,
    mapMobileImage,
    directionsTitle,
    drivingInstructions,
    rideshareInstructions,
    publicTransportationInstructions,
  };
};

export {
  parseAttraction,
  parseRides,
  parseLanding,
  parseVenueDetails,
  getVenueDetailType,
  parseTextBlock,
  parseImageGallery,
  parseMenuBar,
  parsePricingCards,
  parseCollapsibleList,
  parseAnnouncement,
  getSchedule,
  parseHoursPage,
};
