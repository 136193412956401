import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import { getContentfulAssetUrlandAlt } from 'common/components';

import './card.less';

import LOGO_BLACK_CIRCLE from 'common/assets/logo-black-circle.svg';

export default class DirectoryCard extends Component {
  render() {
    const { venue } = this.props;
    return (
      <div {...this.getAttrs(true)}>
        {venue.content && (
          <div className={this.getElementClass('image')}>
            <div
              className={this.getElementClass('explore-venue-img')}
              style={{
                backgroundImage: `url("${
                  getContentfulAssetUrlandAlt(venue.content.horizontalImage)
                    .assetUrl
                }")`,
              }}
            />
          </div>
        )}
        {!venue.content && (
          <div className={this.getElementClass('image')}>
            <div className={this.getElementClass('image-fallback')}>
              <img src={LOGO_BLACK_CIRCLE} />
            </div>
          </div>
        )}
        {venue.featuredFlags.includes('Coming Soon') && (
          <div className={this.getElementClass('content')}>
            <div
              className={`${this.getElementClass('tag')} ${this.getElementClass(
                'btag'
              )}`}>
              Coming Soon
            </div>
          </div>
        )}
        {venue.featuredFlags.includes('Now Open') && (
          <div className={this.getElementClass('content')}>
            <div
              className={`${this.getElementClass('tag')} ${this.getElementClass(
                'gtag'
              )}`}>
              Now Open
            </div>
          </div>
        )}
      </div>
    );
  }
}

DirectoryCard.propTypes = {
  venue: PropTypes.object,
};
