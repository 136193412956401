import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { Spacer } from 'public/components';
import { ResponsiveImage as Image } from 'common/components';
import { formatCurrency, formatDate } from 'utils/l10n';

export default class CartItem extends React.Component {
  render() {
    const { venue, tickets, subtotal, tax, reservationDate } =
      this.props.cartItem;
    const image = venue.images[0];
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            {image && <Image src={image} size="small" />}
          </Grid.Column>
          <Grid.Column width={12}>
            <Header>{venue.name}</Header>
            <p>
              <em>Date:</em> {formatDate(reservationDate)}
            </p>
            <Spacer size="xs" />
            <ul>
              {tickets.map((t) => (
                <li key={t.ticketOptionId}>
                  {t.name} x {t.quantity}
                </li>
              ))}
            </ul>
            <Spacer size="xs" />
            <p>
              <em>{formatCurrency(subtotal + tax)}</em>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
