import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { min } from 'lodash';
import { useClassName, useScrollBlock, useHideHelpscout } from 'common/hooks';
import { getCookie, setCookie } from 'utils/helpers/cookie';
import Button from './components/Button';
import ScoreModal from './components/ScoreModal';
import './board.less';
import EndGame from './components/EndGame';

const COOKIE_NAME = 'miniGolfScore';

const Board = (props) => {
  const [players, setPlayers] = useState();
  const [currentHole, setCurrentHole] = useState(0);
  const [editingHole, setEditingHole] = useState();
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [totals, setTotals] = useState();
  const [minTotal, setMinTotal] = useState(0);
  const { slug } = props.match.params;
  const className = useClassName('Board');
  const [blockScroll, allowScroll] = useScrollBlock();
  const [hideHelpscout, showHelpscout] = useHideHelpscout();
  const history = useHistory();

  useEffect(() => {
    blockScroll();
    hideHelpscout();
    const miniGolfCookie = getCookie(COOKIE_NAME);
    const miniGolfScore = miniGolfCookie ? JSON.parse(miniGolfCookie) : {};
    if (!miniGolfScore?.players) {
      history.push(window.location.pathname.replace('board', ''));
    }
    setPlayers(miniGolfScore.players);
    setCurrentHole(miniGolfScore.gameCompleted ? 19 : miniGolfScore.hole || 1);
    return () => {
      allowScroll();
      showHelpscout();
    };
  }, []);

  useEffect(() => {
    const totals = players?.map((player) =>
      player.score.reduce((acc, cur) => (acc += cur), 0)
    );
    setTotals(totals);
    setMinTotal(min(totals));
  }, [players]);

  const getCellStyle = (index, player, minValue) => {
    if (index > currentHole || player.score[index - 1] !== minValue) return {};

    if (index < currentHole) {
      return {
        background: `${player.color}30`,
      };
    }

    return {
      border: `2px solid ${player.color}`,
      opacity: 1,
      background: 'auto',
    };
  };

  const handleEditHoleScore = (hole) => {
    if (hole >= currentHole) return;
    setEditingHole(hole);
    setShowScoreModal(true);
  };

  const handleConfirmScore = (score) => {
    const scoreHole = editingHole || currentHole;
    const updatedPlayers = players.map((player, index) => {
      player.score[scoreHole - 1] = score[index];
      return player;
    });
    const hole = editingHole ? currentHole : currentHole + 1;

    setPlayers(updatedPlayers);
    setShowScoreModal(false);
    setCookie(
      COOKIE_NAME,
      JSON.stringify(
        {
          players: updatedPlayers,
          hole,
        },
        300
      )
    );
    setCurrentHole(hole);
    setEditingHole(null);
  };

  const handleCloseScoreModal = () => {
    setShowScoreModal(false);
    setEditingHole(null);
  };

  const getMinValue = (index, players) => {
    const holeScore = players.map((player) => player.score[index]);
    return min(holeScore);
  };

  const handleEndGame = () => {
    setCookie(
      COOKIE_NAME,
      JSON.stringify({
        gameCompleted: true,
        hole: currentHole,
        players,
      }),
      300
    );
    history.push(window.location.pathname.replace('board', 'winner'));
  };

  const getWidthStyle = () => {
    if (players.length == 1) return `90%`;
    if (players.length == 2) return `45%`;
    if (players.length == 3) return `30%`;
    return '75px';
  };

  return (
    <div className={className('container')}>
      <div className={className('title-container')}>
        <p className={className(['title', `title-${slug}`])}>Scorecard</p>
        <EndGame venueName={slug} handleEndGame={handleEndGame} />
      </div>
      <div className={className('content')}>
        {players && (
          <table className={className('score-table')}>
            <thead className={className('score-header')}>
              <tr>
                <th>
                  <div className={className('small')}>Hole</div>
                </th>
                {players.map((player, index) => (
                  <th
                    key={`player-${index}`}
                    style={{ width: getWidthStyle() }}>
                    <div
                      style={{
                        color: player.color,
                      }}>
                      {player.name}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={className('score-table-body')}>
              {[...Array(18)].map((item, index) => {
                const minValue = getMinValue(index, players);
                return (
                  <tr
                    key={`hole-${index}`}
                    className={className([
                      'score-row',
                      `score-row-${index + 1 < currentHole && 'completed'}`,
                    ])}>
                    <td>
                      <div
                        className={className([
                          'small',
                          `small-${
                            index + 1 === currentHole ? 'active' : 'inactive'
                          }`,
                        ])}
                        onClick={() => handleEditHoleScore(index + 1)}>
                        {index + 1}
                      </div>
                    </td>
                    {players.map((player, playerIndex) => (
                      <td
                        key={`hole-score-${playerIndex}`}
                        style={{ width: getWidthStyle() }}>
                        <div
                          className={className('hole-score')}
                          style={getCellStyle(index + 1, player, minValue)}>
                          {player.score[index]}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
            <tfoot className={className('score-footer')}>
              <tr className={className('total')}>
                <td>
                  <div className={className(['small', 'small-inactive'])}>
                    Total
                  </div>
                </td>
                {totals?.map((total, index) => {
                  return (
                    <td
                      key={`hole-score-${index}`}
                      style={{ width: getWidthStyle() }}>
                      <div
                        className={className([
                          'total',
                          `total-${
                            currentHole > 1 && total === minTotal
                              ? 'min'
                              : 'regular'
                          }`,
                        ])}>
                        {total}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tfoot>
          </table>
        )}
      </div>
      {currentHole < 19 ? (
        <Button
          label={`Score round ${currentHole}`}
          handleClick={() => setShowScoreModal(true)}
          className={className(['score-button', `score-button-${slug}`])}
          primary
        />
      ) : (
        <Button
          label={`End Game`}
          handleClick={handleEndGame}
          className={className(['end-button', `end-button-${slug}`])}
          primary
        />
      )}

      <ScoreModal
        isOpen={showScoreModal}
        currentHole={currentHole}
        editingHole={editingHole}
        handleClose={handleCloseScoreModal}
        players={players || []}
        venueName={slug}
        handleConfirmScore={handleConfirmScore}
      />
    </div>
  );
};

export default Board;
