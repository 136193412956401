import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { track } from 'utils/analytics';
import { useClassName } from 'common/hooks';
import { ContentfulRichText, SVGIcon as Icon } from 'common/components';
import alertIcon from 'common/assets/alert-icon.png';
import './call-outs.less';

const COLORS = {
  info: 'grey',
  warning: 'yellow',
  error: 'red',
};

const ICONS = {
  info: 'info',
  warning: 'exclamation-triangle',
  error: 'exclamation-triangle',
};

const CallOuts = ({ callOuts, selectedDate }) => {
  const className = useClassName('callOuts');
  const weekday = DateTime.fromISO(selectedDate).toFormat('EEEE');

  const shouldRender = ({ displayOnDates, displayOn }) => {
    if (displayOnDates) return displayOnDates.includes(selectedDate);
    return displayOn?.includes(weekday);
  };

  const handleCTAClick = (ctaText) => track(`CTA ${ctaText} clicked`);

  return (
    <div className={className('container')}>
      {callOuts
        .filter((callOut) => shouldRender(callOut))
        .map((callOut, index) => (
          <div
            key={`call-out-${index}`}
            className={className('call-out')}
            style={{
              backgroundColor: callOut?.backgroundColor,
              color: callOut?.textColor,
            }}>
            <div className={className('body')}>
              {Boolean(callOut.iconType) &&
                (callOut.iconType === 'alert' ? (
                  <img
                    src={alertIcon}
                    alt="alert"
                    className={className('icon-image')}
                  />
                ) : (
                  <Icon
                    name={ICONS[callOut.iconType]}
                    color={COLORS[callOut.iconType]}
                    size="small"
                    transparent
                  />
                ))}
              <ContentfulRichText className field={callOut.body} />
            </div>
            {callOut?.ctaText && callOut?.ctaAction && (
              <Link
                onClick={() => handleCTAClick(callOut.ctaText)}
                to={callOut.ctaAction}
                className={className('cta')}>
                {callOut.ctaText}
              </Link>
            )}
          </div>
        ))}
    </div>
  );
};

CallOuts.propTypes = {
  callOuts: PropTypes.array.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default CallOuts;
