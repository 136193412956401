import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Pagination from 'swiper/modules/pagination.mjs';
import EffectCreative from 'swiper/modules/effect-creative.mjs';

import {
  useClassName,
  LazyVisible,
  usePreload,
  preloadAssets,
} from 'common/hooks';

import ParksGallery from '../ParksGallery';
import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import 'swiper/modules/effect-creative.less';
import './parks.less';

function Figure({ figure, position }) {
  const classNames = useClassName('ParksSection');

  if (!figure?.fields) return null;

  return (
    <HomepageImage
      field={figure}
      className={`${classNames('figure')} ${classNames(
        `figure-${position === 'left' ? 1 : 2}`
      )}`}
    />
  );
}

export default function ParksSection({ section }) {
  const classNames = useClassName('ParksSection');
  const ref = useRef();
  usePreload(() => {
    preloadAssets([
      ...section.fields.parks.map(({ fields }) => fields.icon),
      ...section.fields.parks.map(({ fields }) => fields.figures).flat(),
    ]);
  }, ref);

  return (
    <div id={section.fields.sectionId}>
      <div className={classNames('header')}>
        <h1>{section.fields.title}</h1>
        <h2>{section.fields.subtitle}</h2>
      </div>
      <LazyVisible
        ref={ref}
        onLoad={() =>
          trackHomepageSection(() => ({
            id: section.fields.sectionId,
          }))
        }>
        <Swiper
          modules={[Pagination, EffectCreative]}
          height={542}
          spaceBetween={50}
          slidesPerView={1}
          speed={800}
          creativeEffect={{
            prev: {
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          effect="creative"
          initialSlide={1}
          loop={true}
          className={classNames('wrapper')}
          maxBackfaceHiddenSlides={0}
          pagination={{
            clickable: true,
            horizontalClass: classNames('bullets-container'),
            bulletClass: classNames('bullet'),
            bulletActiveClass: classNames('bullet-active'),
            renderBullet: function (index, className) {
              const { fields } = section.fields.parks[index];

              const border = `
                background: linear-gradient(white, white) padding-box, 
                  linear-gradient(to bottom, ${fields.primaryColor}, ${fields.secondaryColor}) border-box;
                border: 4px solid transparent;`;

              return `
                <div class="${className}" style="${border}">
                  <img src="${fields.icon.fields.file.url}" alt="${fields.icon.fields.title}" />
                </div>`;
            },
          }}
          onSlideChange={({ activeIndex }) =>
            trackHomepageSlide(() => ({
              activeIndex,
              name: section.fields.parks[activeIndex].fields.name,
              section: section.fields.sectionId,
            }))
          }>
          {section.fields.parks
            .filter(({ fields }) => fields.isLarge)
            .map(({ fields }, index) => (
              <SwiperSlide key={index}>
                {() => {
                  return (
                    <div
                      className={classNames('slide')}
                      style={{
                        background: `linear-gradient(180deg, ${fields.primaryColor} 0%, ${fields.secondaryColor}  100%)`,
                      }}>
                      <Figure figure={fields?.figures?.[0]} position="left" />
                      <div className={classNames('content')}>
                        <span>{fields.description}</span>
                        <div>
                          {fields.ctas.map((cta) => {
                            return (
                              <HomepageButton
                                key={cta.sys.id}
                                cta={cta}
                                className={classNames('cta')}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <Figure figure={fields?.figures?.[1]} position="right" />
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
        </Swiper>
      </LazyVisible>
      <ParksGallery section={section} />
    </div>
  );
}
