import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Component } from 'common/helpers';
import { urlForUpload } from 'utils/api';

import './event-card.less';
import arrowLinkIcon from 'common/assets/deals-arrow-right.svg';
import adLogo from 'common/assets/logo-black-circle.svg';
import { LoaderImage } from 'public/components/Loader';

export default class EventCard extends Component {
  renderDates() {
    const { startDate, endDate } = this.props.event;
    const tempStartDate = startDate.split('T')[0];
    const tempEndDate = endDate.split('T')[0];
    if (tempStartDate === tempEndDate) {
      return new Date(startDate).toLocaleDateString('en', {
        month: 'short',
        day: 'numeric',
      });
    }
    return `${new Date(startDate).toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    })} - ${new Date(endDate).toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    })}`;
  }

  renderLogo() {
    const { logo } = this.props.event;
    if (logo) {
      return (
        <div
          className={this.getElementClass('event-img')}
          style={{ backgroundImage: `url("${urlForUpload(logo)}")` }}
        />
      );
    }

    return (
      <div className={this.getElementClass('empty-event-img')}>
        <LoaderImage
          src={adLogo}
          alt={'view'}
          loadingHeight={20}
          hideLoader
          fluid
        />
      </div>
    );
  }

  render() {
    const { event } = this.props;

    return (
      <div {...this.getAttrs(true)}>
        <Link to={{ pathname: `/events/${event.slug}` }} smooth="true">
          {this.renderLogo()}
          <div className={this.getElementClass('content')}>
            <div className={this.getElementClass('title')}>
              {event.venue?.name}
            </div>
            <div
              className={`${this.getElementClass(
                'title'
              )} ${this.getElementClass('event-title')}`}>
              {event.title}
            </div>
            {event?.venueId?.locationDescription && (
              <div
                className={`${this.getElementClass(
                  'title'
                )} ${this.getElementClass('event-location')}`}>
                {event.venue?.locationDescription}
              </div>
            )}
            <div
              className={`${this.getElementClass(
                'title'
              )} ${this.getElementClass('event-dates')}`}>
              <span>{this.renderDates()}</span>
              <div className={this.getElementClass('arrow-icon-wrapper')}>
                <LoaderImage
                  src={arrowLinkIcon}
                  alt={'view'}
                  loadingHeight={20}
                  hideLoader
                  fluid
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

EventCard.propTypes = {
  event: PropTypes.object,
};
