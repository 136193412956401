import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { Icon } from 'semantic-ui-react';
import { formatCurrency } from 'utils/l10n';

import './data-card.less';

const DataCard = ({ title, value, comparisonValue, isCurrency }) => {
  const className = useClassName('DataCard');

  const percentageDifference =
    comparisonValue === null
      ? comparisonValue
      : value === 0
      ? (comparisonValue * 100).toFixed(2)
      : (100 - (comparisonValue * 100) / value).toFixed(2);

  return (
    <div className={className('container')}>
      <div className={className('title-container')}>
        <div className={className('title')}>{title}</div>
        {comparisonValue !== null && (
          <div className={className('difference')}>
            {percentageDifference !== 0 && (
              <Icon
                name="arrow up"
                size="small"
                className={className(
                  'icon-difference',
                  percentageDifference < 0 ? 'negative' : 'positive'
                )}
              />
            )}
            {Math.abs(percentageDifference)}%
          </div>
        )}
      </div>
      <div className={className('total')}>
        {isCurrency ? formatCurrency(Number(value * 100)) : value}
      </div>
      {comparisonValue !== null && (
        <div className={className('compared-to')}>
          Compared to:{' '}
          <strong>
            {isCurrency
              ? formatCurrency(Number(comparisonValue * 100))
              : comparisonValue}
          </strong>
        </div>
      )}
    </div>
  );
};

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  comparisonValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isCurrency: PropTypes.bool,
};

DataCard.defaultProps = {
  isCurrency: false,
};

export default DataCard;
