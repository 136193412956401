import React from 'react';
import PropTypes from 'prop-types';

import { FieldContainer } from 'public/components/FieldContainer';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { useClassName, useWindowSize } from 'common/hooks';
import { SVGIcon } from 'common/components';
import { Dropdown } from 'public/components/Dropdown';
import { BottomSheet } from 'public/components/BottomSheet';
import PhoneNumberInput from 'public/components/Auth/PhoneNumberInput';
import { InfoMessage } from 'public/components';

import { NewCheckoutTicketsHeader } from '../../../Tickets/Header';

import './birthday-info-form.less';

const BirthdayInfoForm = ({
  infoFields,
  formData,
  setFormData,
  fieldErrors,
  validate,
  selectedField,
  setSelectedField,
  defaultFields,
  productForms,
}) => {
  const classNames = useClassName('BirthdayInfoForm');
  const { isMobile } = useWindowSize();

  const getListeners = (fieldName) => {
    return {
      onChange: ({ target }) =>
        setFormData({ ...formData, [fieldName]: target.value }),
      onBlur: ({ target }) => {
        validate(fieldName, target);
        setSelectedField(null);
      },
      onFocus: () => {
        setSelectedField(fieldName);
      },
    };
  };

  const renderSelect = (infoField) => {
    const id = infoField.name || infoField.title;
    const listeners = getListeners(id);
    const content = (
      <div className={classNames('list')}>
        {infoField.options.map((option, index) => (
          <div
            key={index}
            className={classNames('item')}
            onClick={() => {
              listeners.onChange({ target: { value: option } });
              listeners.onBlur({ target: { value: option } });
            }}>
            <span>{option}</span>
          </div>
        ))}
      </div>
    );

    return (
      <ControlledInputContainer
        label={<label htmlFor={id}>{infoField.title}</label>}
        input={
          <input
            value={formData[id] || ''}
            placeholder={infoField.placeholder}
          />
        }
        addonRight={
          <SVGIcon name="angle-down" className={classNames('addon-right')} />
        }
        onClick={listeners.onFocus}
        hasFocus={selectedField === id}
        error={fieldErrors[id]}
        trigger={
          isMobile ? (
            <BottomSheet
              onClose={() =>
                listeners.onBlur({ target: { value: formData[id] } })
              }
              title={infoField.title}
              isOpen={selectedField === id}
              content={content}
            />
          ) : (
            <Dropdown
              isOpen={selectedField === id}
              onClose={() =>
                listeners.onBlur({ target: { value: formData[id] } })
              }
              content={content}
              useTriggerWidth
            />
          )
        }
      />
    );
  };

  const renderInput = ({ name, title, placeholder = '', type = 'text' }) => {
    const id = name || title;

    return (
      <ControlledInputContainer
        label={<label htmlFor={id}>{title}</label>}
        input={
          <input
            value={formData[id] || ''}
            placeholder={placeholder}
            id={id}
            name={id}
            type={type}
            {...getListeners(id)}
          />
        }
        hasFocus={selectedField === id}
        error={fieldErrors[id]}
      />
    );
  };

  const renderField = (field, index) => {
    return (
      <div
        key={field.title}
        className={classNames(index === 2 ? 'full' : 'half')}>
        {field?.options?.length ? renderSelect(field) : renderInput(field)}
      </div>
    );
  };

  return (
    <div className={classNames('wrapper')}>
      <div>
        <NewCheckoutTicketsHeader
          showLogo={false}
          title="Enter Birthday Info"
          className={classNames('header')}
        />
        <div className={classNames('container')}>
          {infoFields.map(renderField)}
        </div>
        {Boolean(productForms.length) && (
          <div className={classNames('product-forms')}>
            {productForms.map((form) => (
              <div key={form.productId}>
                <h2>{form.name} options</h2>
                <div className={classNames('container')}>
                  {form.fields.map(renderField)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <NewCheckoutTicketsHeader
          showLogo={false}
          title="Enter your contact details"
          className={classNames('header')}
        />
        <InfoMessage
          content="Please complete the form below to confirm your booking. You’ll pay 50% of the event fee now, and 50% once your event details are final."
          className={classNames('info')}
        />
        <div className={classNames('container')}>
          <div className={classNames('half')}>
            {renderInput({ title: defaultFields.firstName })}
          </div>
          <div className={classNames('half')}>
            {renderInput({ title: defaultFields.lastName })}
          </div>
          <div className={classNames('half')}>
            <FieldContainer>
              <label htmlFor={defaultFields.phone}>{defaultFields.phone}</label>
              <PhoneNumberInput
                {...getListeners(defaultFields.phone)}
                onChange={(value) =>
                  getListeners(defaultFields.phone).onChange({
                    target: { value },
                  })
                }
                id={defaultFields.phone}
                name={defaultFields.phone}
                error={fieldErrors[defaultFields.phone]}
                initialValue={formData[defaultFields.phone]}
              />
            </FieldContainer>
          </div>
          <div className={classNames('half')}>
            {renderInput({ title: defaultFields.zipCode })}
          </div>
          <div className={classNames('full')}>
            {renderInput({ title: defaultFields.email })}
          </div>
        </div>
      </div>
    </div>
  );
};

BirthdayInfoForm.propTypes = {
  infoFields: PropTypes.array,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  fieldErrors: PropTypes.object,
  validate: PropTypes.func,
  selectedField: PropTypes.string,
  setSelectedField: PropTypes.func,
  defaultFields: PropTypes.object,
  productForms: PropTypes.array,
};

export default BirthdayInfoForm;
