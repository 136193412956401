import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import {
  Form,
  Input,
  Button,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Spacer } from '../Spacer';

import { getToken } from 'admin/utils/token';

@inject('auth')
@withRouter
@observer
export default class SetPasswordForm extends React.Component {
  state = {
    token: getToken(this.props),
    touched: false,
    repeat: '',
    password: '',
    passwordValid: true,
    loading: false,
    error: null,
  };

  static propTypes = {
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
  };

  static defaultProps = {
    onSuccess: () => {},
    onFail: () => {},
  };

  onSubmit = async () => {
    this.setState({
      touched: true,
    });

    if (!this.validate()) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      const { password, token } = this.state;
      await this.props.auth.setPassword({ password, token });
      this.setState({
        loading: false,
        success: true,
      });

      setTimeout(() => {
        this.props.onSuccess();
      }, 1000);
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
      this.props.onFail();
    }
  };

  setField = (evt) => {
    this.setState({
      touched: false,
      [evt.target.name]: evt.target.value,
    });
  };

  validate = () => {
    const passwordValid = this.state.password === this.state.repeat;
    this.setState({
      passwordValid,
    });
    return passwordValid;
  };

  render() {
    if (this.state.loading) {
      return (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      );
    }
    const { passwordValid, touched, token } = this.state;

    if (this.state.success) {
      return (
        <Message>
          <Message.Header>Your password has been changed!</Message.Header>
          <p>... Redirecting to your profile.</p>
        </Message>
      );
    }

    return (
      <>
        {!token && (
          <>
            <Message error>
              <Message.Header>No valid token found</Message.Header>
              <Message.Content>
                Please ensure you either click the email link in the email or
                copy paste the link in full.
                <br />
                <br />
                Try Again ? <br />
                <Link to="/forgot-password">Reset Password</Link>
              </Message.Content>
            </Message>
          </>
        )}

        {token && (
          <Form
            error={touched && !passwordValid}
            onSubmit={this.onSubmit}
            onBlur={this.validate}>
            <p>Reset Password</p>
            <Spacer size="xs" />
            {this.renderError()}
            <Form.Field>
              <label htmlFor="password">New Password</label>
              <Input
                name="password"
                autoComplete="new-password"
                type="password"
                value={this.state.password}
                onChange={this.setField}
              />
            </Form.Field>
            <Spacer size="xs" />
            <Form.Field>
              <label htmlFor="repeat">Repeat Password</label>
              <Input
                id="repeat"
                name="repeat"
                autoComplete="new-password"
                type="password"
                value={this.state.repeat}
                onChange={this.setField}
              />
            </Form.Field>
            <Spacer size="s" />
            <Button fluid primary size="large">
              Set New Password
            </Button>
          </Form>
        )}
      </>
    );
  }

  renderError() {
    const { error, passwordValid, touched } = this.state;
    if (!touched) return;
    if (error) {
      return <Message negative>{error.message}</Message>;
    } else if (!passwordValid) {
      return <Message negative>Password not the same</Message>;
    }
  }
}
