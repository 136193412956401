import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import { SVGIcon as Icon } from '../SVGIcon';
import { Component } from 'common/helpers';

// Semantic Dropdowns have a number of issues
// when using "multiple" so ironing them out here.

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  getPlaceholder() {
    const { selected } = this.props;
    if (selected.length > 0) {
      return 'Items Selected';
    } else {
      return 'Please Select';
    }
  }

  onOpen = () => {
    this.setState({
      open: true,
    });
  };

  onClose = () => {
    this.setState({
      open: false,
    });
  };

  setValue = (value, checked) => {
    const { selected } = this.props;
    if (checked) {
      this.props.onAdd(selected.concat(value), value);
    } else {
      this.props.onRemove(
        selected.filter((s) => s !== value),
        value
      );
    }
  };

  render() {
    const { items, selected, additionalLabel, icon, onAdd, onRemove, ...rest } =
      this.props;
    const { open } = this.state;
    return (
      <Dropdown
        multiple
        open={open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        placeholder={this.getPlaceholder()}
        icon={icon || <Icon name="angle-down" size="mini" />}
        {...rest}>
        <Dropdown.Menu onClick={(evt) => evt.stopPropagation()}>
          {this.renderItems(items, selected, additionalLabel)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderItems(items, selected, additionalLabel) {
    return items.map((item) => {
      const { text, value } = item;
      const isSelected = selected.includes(value);
      return (
        <Dropdown.Item
          key={value}
          onClick={() => this.setValue(value, !isSelected)}>
          <Checkbox checked={isSelected} /> {additionalLabel}
          {text}
        </Dropdown.Item>
      );
    });
  }
}

MultiSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.string),
  additionalLabel: PropTypes.string,
  icon: PropTypes.node,
};

MultiSelect.defaultProps = {
  items: [],
  selected: [],
  additionalLabel: '',
};
