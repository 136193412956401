import React, { useEffect, useRef, useState } from 'react';
import ProptTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import './filters.less';
const DEFAULT_SCROLL_MOVE = 250;

const Filters = ({ filters, filter, handleFilterChange, centered }) => {
  const className = useClassName('Filters');
  const contentRef = useRef();
  const [currentScroll, setCurrentScroll] = useState(0);

  const handleScroll = (direction) =>
    (contentRef.current.scrollLeft += direction * DEFAULT_SCROLL_MOVE);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', () =>
        setCurrentScroll(contentRef.current.scrollLeft)
      );
      return () =>
        contentRef.current.removeEventListener('scroll', () =>
          setCurrentScroll(contentRef.current.scrollLeft)
        );
    }
  }, [contentRef]);

  return (
    <div className={className('container')}>
      <div
        onClick={() => handleScroll(-1)}
        className={className([
          'control',
          'control-left',
          `control-${currentScroll === 0 ? 'hidden' : 'visible'}`,
        ])}>
        <Icon name="angle-right" size="mini" />
      </div>
      <div
        onClick={() => handleScroll(1)}
        className={className([
          'control',
          'control-right',
          `control-${
            currentScroll == 0 ||
            contentRef?.current?.clientWidth <
              contentRef?.current?.scrollWidth - currentScroll
              ? 'visible'
              : 'hidden'
          }`,
        ])}>
        <Icon name="angle-right" size="mini" />
      </div>
      <div
        ref={contentRef}
        className={className([
          'content',
          `content-${centered ? 'centered' : 'default'}`,
        ])}>
        {Object.keys(filters).map((filterKey) => (
          <div
            key={filterKey}
            className={className([
              'filter',
              `filter-${filter === filterKey ? 'selected' : 'default'}`,
            ])}
            onClick={() => handleFilterChange(filterKey)}>
            <img src={filters[filterKey].image} alt={filterKey} />
            <span>{filters[filterKey].name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

Filters.propTypes = {
  filter: ProptTypes.string,
  filters: ProptTypes.object.isRequired,
  handleFilterChange: ProptTypes.func.isRequired,
  centered: ProptTypes.bool,
};

Filters.defaultProps = {
  centered: false,
};

export default Filters;
