import React from 'react';
import PropTypes from 'prop-types';

import './section-title.less';

const typeMap = {
  shopping: 'Stores',
  dining: 'Restaurants, Bars, Coffee and more',
  entertainment: 'Parks and Attractions',
};

const SectionTitle = ({ title, type, className }) => {
  return (
    <div className={`section-title ${className}`}>
      {title} {type && typeMap[type]}
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default SectionTitle;
