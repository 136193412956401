export function setCookie(key, value, minutes = 10) {
  var date = new Date();

  // Get unix milliseconds at current time plus number of minutes
  date.setTime(+date + minutes * 60000); // 60 * 1000

  // window.document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";
  window.document.cookie = `${key}=${value}; expires=${date.toGMTString()}; path=/`;
}

export function getCookie(key) {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(key))
    ?.split('=')[1];
}

export function deleteCookie(key) {
  window.document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
}
