import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { Link } from 'react-router-dom';
import { SVGIcon as Icon, DesktopOnly, MobileOnly } from 'common/components';

import './breadcrumb.less';

const Breadcrumb = ({ links }) => {
  const className = useClassName('Breadcrumb');

  const previousLink = links[links.length - 2];
  return (
    <>
      <DesktopOnly className={className('container')}>
        <Link to="/">
          <Icon name="home" size="tiny" className="home" />
        </Link>
        {links.map((link, index) => (
          <Fragment key={index}>
            <Icon
              name="chevron-right"
              size="tiny"
              className={className('chevron')}
            />
            <Link to={link.url}>{link.title}</Link>
          </Fragment>
        ))}
      </DesktopOnly>
      {previousLink && (
        <MobileOnly className={className('container')}>
          <Link to={previousLink.url}>
            <Icon name="arrow-left" size="mini" />
            {previousLink.title}
          </Link>
        </MobileOnly>
      )}
    </>
  );
};

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Breadcrumb;
