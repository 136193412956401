import React from 'react';
import { Screen, withVenue } from 'public/helpers';
import { Loader } from 'semantic-ui-react';
import { RideScreen } from 'public/components';
import { NotFound } from '../../components/NotFound';

@withVenue
export default class Ride extends Screen {
  constructor(props) {
    super(props);
  }

  renderBody() {
    const { error, venue, match } = this.props;
    const {
      params: { rideSlug },
    } = match;

    if (error === 'Not Found') {
      return <NotFound />;
    } else if (!venue) {
      return <Loader active />;
    }

    if (venue?.content?.attractionScreen) {
      return <RideScreen venue={venue} rideSlug={rideSlug} />;
    }

    return null;
  }
}
