import { isNumber } from 'lodash';
import { trackProductAdded } from 'common/helpers/api';

const trackTickets = (tickets) =>
  tickets.map((ticket) => trackProductAdded(ticket));

export const isAvailable = (product) => {
  if (!isNumber(product.maxQuantity)) return true;

  return product.maxQuantity > 0;
};

export const isOptionSoldOut = (option, products) => {
  return !option.products.some(({ rollerName }) => {
    const product = products.find(({ name }) => name === rollerName);
    if (!product) return false;

    return isAvailable(product);
  });
};

export const isSoldOut = (product) => {
  if (product.variants) {
    return product.variants.options.every((option) =>
      isOptionSoldOut(option, product.products)
    );
  }

  return !product.products.some(isAvailable);
};

export const trackAdmissionAdded = ({
  selectedAdmission,
  selectedSession,
  date,
  numberOfGuests,
  venue,
}) => {
  try {
    const tickets = [
      {
        name: selectedAdmission.title,
        price: selectedSession.cost,
        venueId: venue.id,
        venueName: venue.name,
        bookingItemId: selectedSession.productId,
        ticketOptionId: selectedSession.productId,
        reservationDate: date,
        startTime: selectedSession.startTime,
        startTimeName: selectedSession.name,
        quantity: 1,
        tax: selectedSession.cost * (selectedSession.tax / 100),
      },
    ];

    const extraGuests = numberOfGuests - selectedSession.guestsIncluded;

    if (extraGuests < 0) return trackTickets(tickets);

    tickets.push({
      name: `${selectedAdmission.title} additional guest`,
      price: selectedSession.additionalGuests.cost,
      venueId: venue.id,
      venueName: venue.name,
      bookingItemId: selectedSession.additionalGuests.productId,
      ticketOptionId: selectedSession.additionalGuests.productId,
      reservationDate: date,
      startTime: selectedSession.startTime,
      startTimeName: selectedSession.name,
      quantity: extraGuests,
      tax: selectedSession.additionalGuests.cost * (selectedSession.tax / 100),
    });

    trackTickets(tickets);
  } catch (e) {
    console.error('Error while tracking birthday admissions added', e.message);
  }
};

export const trackAddonsAdded = ({ quantities, products, date, venue }) => {
  try {
    const tickets = Object.keys(quantities).map((productId) => {
      const product = products.find((product) =>
        product.products.find((subProduct) => subProduct.id === productId)
      );
      const subProduct = product.products.find(
        (subProduct) => subProduct.id === productId
      );
      return {
        name: subProduct.name,
        price: subProduct.cost * 100,
        venueId: venue.id,
        venueName: venue.name,
        bookingItemId: product.id,
        ticketOptionId: subProduct.id,
        reservationDate: date,
        quantity: quantities[productId],
        tax: subProduct.cost * subProduct.tax,
      };
    });
    trackTickets(tickets);
  } catch (e) {
    console.error('Error while tracking birthday addons added', e.message);
  }
};
