import React from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Layout } from 'common/components';
import { Screen } from 'public/helpers';
import { DEFAULT_GROUP_ID } from '../../../common/const';
import { NewsletterForm, Spacer } from 'public/components';
import { NotFound } from '../NotFound';

export default class NewsletterSubscribe extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      loadingPage: true,
    };
  }

  onGroupsReceived = (groups) => {
    const { params } = this.props.match;
    const slug = params.group || DEFAULT_GROUP_ID;
    const group = groups.find((g) => g.id === slug);
    this.setState({
      group,
      loadingPage: false,
    });
  };

  renderBody() {
    const { group, loadingPage } = this.state;
    if (!loadingPage && !group) {
      return <NotFound {...this.props} />;
    }
    return (
      <React.Fragment>
        <Spacer size="m" desktop />
        <Container className="white">
          <Layout vertical center>
            <Container className="tight">
              <Spacer size="m" />
              <NewsletterForm
                group={group}
                onGroupsReceived={this.onGroupsReceived}
                onBackClick={() => this.props.history.goBack()}
              />
              <Spacer size="m" />
            </Container>
          </Layout>
        </Container>
      </React.Fragment>
    );
  }
}

NewsletterSubscribe.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

NewsletterSubscribe.defaultProps = {
  contentfulSlugItem: 'newsletter',
};
