import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Loader, Container, Button } from 'semantic-ui-react';
import {
  Layout,
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
} from 'common/components';
import { Screen } from 'public/helpers';
import { BundleSelector, Spacer } from 'public/components';
import { trackProductViewed } from 'common/helpers';
import { NotFound } from '../NotFound';

import './tickets.less';

function getDefaultState(props) {
  const { slug } = (props.match && props.match.params) || {};

  return {
    slug: slug,
    loading: true,
    redirect: null,
    addedTickets: null,
    bundle: null,
    isAttraction: false,
    error: null,
  };
}

@inject('checkout', 'bundles', 'cart')
@observer
export default class BundleTickets extends Screen {
  state = getDefaultState(this.props);

  async routeDidUpdate() {
    try {
      const { slug } = this.state;
      const bundle = await this.props.bundles.fetchItemBySlug(slug);

      bundle?.rollerProducts?.forEach((item) =>
        trackProductViewed(
          {
            price: item?.cost ? item.cost * 100 : 0,
            ticketOptionId: item?.ticketOptionId,
            venueId: item?.venueId,
            name: item?.name,
          },
          {
            name: bundle?.name,
          }
        )
      );

      this.setState({
        loading: false,
        bundle,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      if (error.message === 'Not Found') {
        this.setState({
          error,
          loading: false,
        });
      } else {
        throw error;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newState = getDefaultState(props);
    if (newState.slug !== state.slug) {
      return newState;
    }
    return null;
  }

  getAddonsAvailability = async (tickets) => {
    const addons = [];
    for (const ticket of tickets) {
      if (ticket.addons?.length) {
        const addonsArr = await this.props.bundles.getAddonsAvailability(
          ticket.addons,
          ticket.reservationDate
        );
        addons.push(
          ...addonsArr.map((addonAv) => ({
            ...addonAv,
            venueId: ticket.venueId,
            reservationDate: ticket.reservationDate,
            parentBundleSlug: ticket.bundleSlug,
            parentBundleCartId: ticket.bundleCartId,
          }))
        );
      }
    }
    return addons;
  };

  setCheckoutAddons = async (tickets) => {
    const addons = await this.getAddonsAvailability(tickets);

    if (!addons?.length) return false;

    this.props.checkout.update({
      addons,
    });
    return true;
  };

  onSubmit = async (tickets) => {
    const { bundle } = this.state;
    this.setState({
      loading: true,
    });
    const hasAddons = await this.setCheckoutAddons(tickets);
    tickets.forEach((ticket) => this.props.cart.addTicket(ticket));
    if (hasAddons) {
      this.setRedirect(`/bundle/${bundle.slug}/addons`);
    } else {
      this.setRedirect('/cart');
    }
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
    });
  };

  renderSelector(bundle) {
    return (
      <Layout.Group className={this.getElementClass('bundles-selector')}>
        <BundleSelector
          bundle={bundle}
          onSubmit={this.onSubmit}
          renderActions={this.renderActions}
        />
      </Layout.Group>
    );
  }

  renderActions = (canSubmit, capacity = true) => {
    return (
      <Button disabled={!canSubmit || !capacity} name="/cart" primary fluid>
        Add to basket
      </Button>
    );
  };

  renderBody() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    } else if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </React.Fragment>
      );
    } else if (this.state.error) {
      return <NotFound {...this.props} />;
    }

    const { bundle } = this.state;

    return (
      <div {...this.getAttrs()}>
        <MobileOnly>{this.renderSelector(bundle)}</MobileOnly>

        <TabletOnly>
          <Container>
            <div className={this.getElementClass('selector')}>
              {this.renderSelector(bundle)}
            </div>
          </Container>
          <Spacer size="l" />
        </TabletOnly>

        <DesktopOnly>
          <Container>
            <div className={this.getElementClass('selector')}>
              {this.renderSelector(bundle)}
            </div>
          </Container>
          <Spacer size="l" />
        </DesktopOnly>
      </div>
    );
  }
}
