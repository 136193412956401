import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';

import stores from 'common/stores';

Element.prototype._addEventListener = Element.prototype.addEventListener;
Element.prototype.addEventListener = function (a, b, c) {
  this._addEventListener(a, b, c);
  if (!this.eventListenerList) this.eventListenerList = {};
  if (!this.eventListenerList[a]) this.eventListenerList[a] = [];
  this.eventListenerList[a].push(b);
};

class AppWrapper extends React.Component {
  componentDidMount() {
    try {
      const isProd = window.__ENV__.API_URL.includes('americandream.com');
      if (!isProd) return;
      datadogRum.init({
        applicationId: '9d5d1a24-c8f7-419d-83c2-d3234d5822a0',
        clientToken: 'pub4ee5b4a353f6de1ef9eb36cd147062e7',
        site: 'datadoghq.com',
        service: 'american-dream-web',
        env: 'Production',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    } catch (e) {
      console.error(`Could not init DataDog, Error: ${e.message}`);
    }
  }

  render() {
    return (
      <Provider {...stores}>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

render(<AppWrapper />, document.getElementById('root'));
