import React from 'react';
import { Component } from 'common/helpers';

import {
  Video,
  AnimatedSprite,
  ContentfulImage,
  DesktopOnly,
  MobileOnly,
} from 'common/components';

import { LoaderImage } from 'public/components';

import './motion-hero.less';

export default class DreamWorksMotionHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  onLoad = () => {
    this.setState({
      loaded: true,
    });
  };

  getGraphic(type) {
    const val = this.props[`${type}Graphic`];
    let graphic;
    if (typeof val === 'string') {
      const ratio = this.props[`${type}Ratio`];
      graphic = (
        <LoaderImage
          color="#fff"
          background="#4084e9"
          onLoad={this.onLoad}
          className={this.getElementClass('overlay-graphic')}
          contentfulName={val}
          ratio={ratio}
          fluid
        />
      );
    } else {
      graphic = (
        <ContentfulImage
          onLoad={this.onLoad}
          className={this.getElementClass('overlay-graphic')}
          field={val}
          fluid
        />
      );
    }
    return graphic;
  }

  render() {
    const { loaded } = this.state;
    return (
      <div {...this.getAttrs()}>
        <DesktopOnly>
          <div className={this.getElementClass('overlay')}>
            {this.getGraphic('desktop')}
            <Video
              className={this.getElementClass(
                'overlay-motion',
                loaded ? 'loaded' : null
              )}
              contentfulName="DreamWorks Animation - Motion - Video"
              controls={false}
              playsInline
              autoPlay
              loop
            />
          </div>
        </DesktopOnly>
        <MobileOnly>
          {this.getGraphic('mobile')}
          <AnimatedSprite
            fps={12}
            frames={46}
            className={this.getElementClass(
              'overlay-motion',
              loaded ? 'loaded' : null
            )}
            contentfulName="DreamWorks Animation - Motion - Sprite"
          />
        </MobileOnly>
      </div>
    );
  }
}
