import React from 'react';
import { inject } from 'mobx-react';

import { NewCheckoutContainer } from '../../Components/Container';

const BirthdayCheckoutContainer = ({ attrs, content, summary, birthdays }) => {
  const email = birthdays.checkout.settings.submitInfoTo;

  return (
    <NewCheckoutContainer
      attrs={attrs}
      content={content}
      summary={summary}
      onHelpClick={() => window.open(`mailto:${email}`)}
    />
  );
};

export default inject('birthdays')(BirthdayCheckoutContainer);
