import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import './modal.less';

const Modal = ({
  isOpen,
  title,
  description,
  handleClose,
  venueName,
  children,
}) => {
  const className = useClassName('Modal');

  return (
    <div
      className={className([
        'container',
        `container-${isOpen ? 'open' : 'closed'}`,
      ])}>
      <div
        className={className([
          'content',
          `content-${venueName}`,
          `content-${isOpen ? 'open' : 'closed'}`,
        ])}>
        <div className={className('title-container')}>
          <p className={className('title')}>{title}</p>
          <Icon name="close" size="mini" onClick={handleClose} />
        </div>
        {description && (
          <p className={className('description')}>{description}</p>
        )}
        <div className={className('children-container')}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  venueName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  isOpen: false,
};

export default Modal;
