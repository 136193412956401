import React from 'react';
import { sum, sumBy, uniq } from 'lodash';
import { Container, Loader, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { captureError } from 'utils/sentry';
import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';
import { DEV } from 'utils/env/client';
import loadStripe from './loadStripe';
import { getItemPrice, getItemTax } from 'common/helpers';

export default function CartStep(Component) {
  @inject(
    'cart',
    'orders',
    'venues',
    'bundles',
    'ticketInventory',
    'notifications',
    'discounts',
    'externalbookings'
  )
  @observer
  class CartBase extends Screen {
    constructor(props) {
      super(props);
      this.state = {
        loadingCart: true,
        error: null,
      };
    }

    componentDidUpdate() {
      //Realoading inventory only if any venue is not in the current store
      const venues = this.props.cart
        .getItems()
        .map((cartItem) => cartItem.venueId);
      for (let i = 0; i < venues.length; i += 1) {
        if (!this.props.venues.get(venues[i])) {
          this.fetchTicketInventory();
          break;
        }
      }
    }

    routeDidUpdate() {
      this.fetchTicketInventory();
    }

    async fetchExternalBookings(venueId, reservationDate) {
      const { venues, externalbookings } = this.props;
      const venue = venues.get(venueId);
      if (
        venue?.externalBookings &&
        !externalbookings.get(venue.slug, reservationDate)
      ) {
        await externalbookings.fetchAvailabilityByVenue(
          venue.slug,
          reservationDate
        );
      }
    }

    async fetchTicketInventory() {
      try {
        for (const item of this.props.cart.getItems()) {
          const { venueId, reservationDate, promoCode, tickets, bundleSlug } =
            item;
          if (!this.props.venues.get(venueId)) {
            await this.props.venues.fetchItem(venueId, reservationDate);
          }

          await this.fetchExternalBookings(venueId, reservationDate);

          if (bundleSlug) {
            if (!this.props.bundles.get(bundleSlug)) {
              await this.props.bundles.fetchItemBySlug(bundleSlug);
            }
            if (item.addons?.length && !this.existingItemAddons(item)) {
              await this.getBundleAddons(item);
            }
          }
          if (
            !bundleSlug &&
            !this.props.ticketInventory.get(venueId, reservationDate)
          ) {
            await this.props.ticketInventory.search({
              venueId,
              promoCode,
              date: reservationDate,
            });
          }

          for (const ticket of tickets) {
            const {
              ticketOptionId,
              bookingItemId,
              quantity,
              startTime,
              externalTicket,
            } = ticket;
            let inventoryItem;

            if (bundleSlug) {
              const bundleAvailability =
                await this.props.bundles.getProductAvailability({
                  productId: bookingItemId,
                  venueId,
                  externalTicket,
                  date: reservationDate,
                  quantity,
                });
              inventoryItem =
                bundleAvailability && bundleAvailability.available;
              if (!inventoryItem) {
                const { bundleCartId } = item;
                this.props.cart.removeBundle(bundleCartId);
                inventoryItem = true;
              } else {
                let bundle = this.props.bundles.get(bundleSlug);
                bundle.rollerProducts = bundle.rollerProducts.map((product) => {
                  if (
                    product.productId === bookingItemId &&
                    !product.hasSessions
                  ) {
                    product.capacityRemaining =
                      bundleAvailability.capacityRemaining;
                  } else if (
                    product.productId === bookingItemId &&
                    product.hasSessions
                  ) {
                    const session = bundleAvailability.sessions.find(
                      (session) => session.startTime === startTime
                    );
                    if (session) {
                      product.capacityRemaining = session.capacityRemaining;
                    }
                  }
                  return product;
                });
                this.props.bundles.setBundle(bundle, bundleSlug);
              }
            } else if (this.ticketIsAddon(this.props.cart.getItems(), ticket)) {
              inventoryItem = true;
            } else {
              inventoryItem = this.props.ticketInventory.getInventoryItem({
                venueId,
                reservationDate,
                ticketOptionId,
              });
            }

            if (!inventoryItem) {
              this.props.cart.removeItem(ticketOptionId);
            }
          }
        }
        await loadStripe();
        this.setState({
          loadingCart: false,
        });
      } catch (err) {
        this.setState({
          loadingCart: false,
          error: 'Something went wrong',
        });
        if (!DEV) {
          this.props.cart.clear();
        }
        captureError(err);
      }
    }

    getAddonInventory(cartItem, parentBundleSlug) {
      const bundlesAddons = this.props.bundles.addons;
      const ticketBookingIds = cartItem.tickets.map(
        (ticket) => ticket.bookingItemId
      );
      const parentBundle =
        parentBundleSlug && this.props.bundles.get(parentBundleSlug);

      const inventory = parentBundle.rollerProducts.map((product) => ({
        bundled: true,
        ...product,
        price: product.cost * 100,
        tax: product.tax ? product.cost * product.tax : 0,
        bookingItemId: product.productId,
        capacityRemaining: product.capacityRemaining,
      }));
      inventory.push(
        ...bundlesAddons.filter((addon) =>
          ticketBookingIds.includes(addon.bookingItemId)
        )
      );

      return inventory;
    }

    getCartItems() {
      return this.props.cart.getItems().map((cartItem) => {
        const {
          venueId,
          reservationDate,
          startTime,
          startTimeName,
          promoCode,
          bundleSlug,
          bundleCartId,
          parentBundleSlug,
          parentBundleCartId,
        } = cartItem;
        const venue = this.props.venues.get(venueId);
        const bundle = bundleSlug && this.props.bundles.get(bundleSlug);

        let inventory;

        if (bundleSlug) {
          inventory = bundle.rollerProducts.map((product) => ({
            bundled: true,
            ...product,
            cost: product.cost,
            price: product.cost * 100,
            tax: product.tax ? product.cost * product.tax : 0,
            bookingItemId: product.productId,
            passportType: bundle.passportType,
            passportValidDays: bundle.passportValidDays,
          }));
        } else if (this.itemIsAddon(cartItem) && parentBundleSlug) {
          inventory = this.getAddonInventory(cartItem, parentBundleSlug);
        } else {
          inventory = this.props.ticketInventory.get(venueId, reservationDate);
        }

        const sessions = this.props.ticketInventory.getSessions(
          venueId,
          reservationDate
        );
        const quantities = cartItem.tickets.reduce((quantities, ticket) => {
          const { ticketOptionId, quantity } = ticket;
          quantities[ticketOptionId] = quantity;
          return quantities;
        }, {});

        const tickets = cartItem.tickets.map((ticket) => {
          const inventoryTicket = inventory.find(
            (item) => item.ticketOptionId === ticket.ticketOptionId
          );
          return {
            ...inventoryTicket,
            externalTicket: ticket.externalTicket,
          };
        });

        return {
          venue,
          venueId,
          tickets,
          quantities,
          inventory,
          sessions,
          promoCode,
          reservationDate,
          startTime,
          startTimeName,
          bundleSlug,
          bundle,
          bundleCartId,
          parentBundleSlug,
          parentBundleCartId,
        };
      });
    }

    getCount(cartItems) {
      return sumBy(cartItems, (cartItem) => {
        return sum(Object.values(cartItem.quantities));
      });
    }

    getSubtotal(cartItems) {
      return sumBy(cartItems, (cartItem) => {
        const { inventory, quantities } = cartItem;
        return sumBy(cartItem.tickets, (inventoryItem) => {
          const { ticketOptionId } = inventoryItem;
          const quantity = quantities[ticketOptionId];
          const price = getItemPrice(inventoryItem, inventory, quantities);
          return price * quantity;
        });
      });
    }

    getTax(cartItems) {
      return sumBy(cartItems, (cartItem) => {
        const { inventory, quantities } = cartItem;
        return sumBy(cartItem.tickets, (inventoryItem) => {
          const { ticketOptionId } = inventoryItem;
          const quantity = quantities[ticketOptionId];
          const tax = getItemTax(inventoryItem, inventory, quantities);
          return tax * quantity;
        });
      });
    }

    async getBundleAddons({ addons, reservationDate }) {
      const addonsArr = addons.split(',');
      if (addonsArr.length) {
        await this.props.bundles.getAddonsAvailability(
          addonsArr,
          reservationDate
        );
      }
    }

    existingItemAddons(item) {
      const bundlesAddons = this.props.bundles.addons;
      const itemAddons = item?.addons?.split(',') || [];
      for (const addon of itemAddons) {
        if (!bundlesAddons.find((bad) => bad.bookingItemId === addon))
          return false;
      }
      return false;
    }

    ticketIsAddon(cartItems, { venueId, bookingItemId }) {
      return cartItems.some(
        (c) =>
          c.venueId === venueId && c.addons.split(',').includes(bookingItemId)
      );
    }

    itemIsAddon(cartItem) {
      const bundlesAddons = this.props.bundles.addons;
      return cartItem.tickets.every((ticket) =>
        bundlesAddons
          .map((addon) => addon.bookingItemId)
          .includes(ticket.bookingItemId)
      );
    }

    renderBody() {
      if (this.state.loadingCart) {
        return (
          <React.Fragment>
            <Spacer size="l" />
            <Loader inline="centered" active />
            <Spacer size="l" />
          </React.Fragment>
        );
      } else if (this.state.error) {
        return (
          <React.Fragment>
            <Spacer size="l" />
            <Container>
              <Message negative content={this.state.error.message} />
            </Container>
            <Spacer size="l" />
          </React.Fragment>
        );
      } else if (this.props.cart.size === 0) {
        return (
          <React.Fragment>
            <Spacer size="l" />
            <Container>
              <Message warning content="There are no items in your basket." />
            </Container>
            <Spacer size="l" />
          </React.Fragment>
        );
      } else {
        const cartItems = this.getCartItems();

        return (
          <Component
            cart={this.props.cart}
            orders={this.props.orders}
            discounts={this.props.discounts}
            notifications={this.props.notifications}
            cartItems={cartItems}
            promoMessages={this.renderPromoMessages(cartItems)}
            tax={this.getTax(cartItems)}
            count={this.getCount(cartItems)}
            subtotal={this.getSubtotal(cartItems)}
          />
        );
      }
    }

    renderPromoMessages(cartItems) {
      const messages = uniq(
        cartItems
          .map((cartItem) => cartItem.promoCode)
          .filter((promoCode) => promoCode)
      );
      return messages.map((promoCode) => {
        return (
          <React.Fragment key={promoCode}>
            <Spacer size="xs" desktop />
            <Message size="small" positive>
              Applying promo code <code>{promoCode}</code>
            </Message>
          </React.Fragment>
        );
      });
    }
  }

  CartBase.propTypes = {
    contentfulSlugItem: PropTypes.string,
  };

  CartBase.defaultProps = {
    contentfulSlugItem: 'shopping-cart',
  };

  return CartBase;
}
