import React from 'react';
import { Container } from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';
import { Component } from 'common/helpers';
import { Layout, ResponsiveImage } from 'common/components';

import './hero.less';

const SLIDE_TIMEOUT = 4000;
const SLIDE_DURATION = 500;

const SLIDES = [
  {
    modifier: 'bright',
    image: 'Big Snow - Hero - 1',
    ratio: 1.26,
    top: 'The most fun you can have indoors.',
    bottom:
      "North America's first and only indoor, real-snow, year-round ski and snow resort.",
  },
  //{
  //modifier: 'dark',
  //image: 'Big Snow - Hero - 2',
  //ratio: 1.28,
  //top: 'NOW OPEN AT NIGHT.',
  //bottom: "North America's first and only indoor, real-snow, year-round ski and snow park.",

  //},
];

export default class BigSnowHero extends Component {
  state = {
    activeIndex: 0,
  };

  startTimer() {
    this.waitToSlide();
  }

  stopTimer() {
    clearTimeout(this.timer);
  }

  waitToSlide() {
    if (SLIDES.length > 1) {
      this.timer = setTimeout(this.onSlideChange, SLIDE_TIMEOUT);
    }
  }

  onSlideChange = () => {
    const { activeIndex } = this.state;
    const nextSlide = (activeIndex + 1) % SLIDES.length;
    this.setState({
      activeIndex: nextSlide,
    });
    this.waitToSlide();
  };

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  getModifiers() {
    const { activeIndex } = this.state;
    return [SLIDES[activeIndex].modifier];
  }

  render() {
    const { activeIndex } = this.state;
    return (
      <div {...this.getAttrs()}>
        <ResponsiveImage
          className={this.getElementClass('background')}
          contentfulName="Big Snow - Hero - Background"
          ratio={3.16}
          cover
        />
        {this.renderCounter()}
        <div className={this.getElementClass('shade')} />
        {SLIDES.map((slide, i) => {
          const active = i === activeIndex;
          return (
            <CSSTransition key={i} in={active} timeout={SLIDE_DURATION} appear>
              <div
                className={this.getElementClass(
                  'item',
                  active ? 'active' : null
                )}>
                <Container>
                  <Layout horizontal spread stackable>
                    <Layout.Group>
                      <Layout vertical>
                        <Layout.Group
                          className={this.getElementClass('item-top')}>
                          {slide.top}
                        </Layout.Group>
                        <Layout.Group
                          className={this.getElementClass('item-bottom')}>
                          {slide.bottom}
                          <p
                            style={{
                              marginTop: '24px',
                            }}>
                            Now Open!
                          </p>
                        </Layout.Group>
                      </Layout>
                    </Layout.Group>
                    <Layout.Group>
                      <Layout horizontal bottom>
                        <ResponsiveImage
                          className={this.getElementClass('item-image')}
                          contentfulName={slide.image}
                          ratio={slide.ratio}
                        />
                      </Layout>
                    </Layout.Group>
                  </Layout>
                </Container>
              </div>
            </CSSTransition>
          );
        })}
      </div>
    );
  }

  renderCounter() {
    if (SLIDES.length <= 1) {
      return;
    }
    const { activeIndex } = this.state;
    return (
      <div className={this.getElementClass('counter')}>
        {SLIDES.map((slide, i) => {
          return (
            <div key={i} className={this.getElementClass('counter-item')}>
              <CSSTransition
                in={activeIndex === i}
                timeout={SLIDE_TIMEOUT}
                appear>
                <div className={this.getElementClass('counter-bar')} />
              </CSSTransition>
            </div>
          );
        })}
      </div>
    );
  }
}
