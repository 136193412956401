import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { ProductPropType } from 'public/schema';
import { useClassName } from 'common/hooks';
import CloseIcon from 'common/assets/ic-close.svg';
import { formatCurrency } from 'utils/l10n';
import Slider from 'common/components/Slider';
import { ContentfulRichText, getContentfulField } from 'common/components';

import './details.less';

const getPrice = (product) => {
  if (!product?.products?.length) return product.price;
  if (product.products.length !== 1) return null;

  return product.products[0].cost * 100;
};

const Details = ({ product, open, handleClose, contentful }) => {
  const className = useClassName('Details');

  useEffect(() => {
    if (open) {
      const dimmer = document.querySelector('.dimmer');
      if (dimmer) {
        dimmer.style.background = 'black';
        dimmer.style.zIndex = '9999';
      }
    }
  }, [open]);

  const price = getPrice(product);
  return (
    <Modal open={open} size="fullscreen" basic onClose={handleClose}>
      <div
        className={className('close-button-container')}
        onClick={handleClose}>
        <img className={className('close-button')} src={CloseIcon} alt="logo" />
      </div>
      <div className={className('container')}>
        <div className={className('info')}>
          <div className={className('header')}>
            <h3>{product.name}</h3>
            {Boolean(price) && <h5>{formatCurrency(price)}</h5>}
          </div>
          <div className={className('content')}>
            {contentful ? (
              <div className={className('product-description')}>
                <ContentfulRichText field={product.description} />
              </div>
            ) : (
              <div
                className={className('product-description')}
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            )}
          </div>
        </div>
        <div className={className('images')}>
          <Slider
            images={
              contentful ? getContentfulField(product.images) : product.images
            }
            contentfulImages={contentful}
          />
        </div>
      </div>
    </Modal>
  );
};

Details.propTypes = {
  product: PropTypes.shape(ProductPropType).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Details;
