import React from 'react';
import { Button } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import {
  ContentfulImage,
  ContentfulRichText,
  SVGIcon,
} from 'common/components';

import { BirthdayDatePicker } from '../BirthdayDatePicker';
import { BirthdaySessionPicker } from '../BirthdaySessionPicker';
import { NewCheckoutQuantityControls } from '../../Components/QuantityControls';
import { BirthdayGuestsInfo } from '../GuestsInfo';

import './admission-card.less';

const AdmissionCard = ({
  images,
  title,
  description,
  date,
  setDate,
  sessions,
  selectedSession,
  setSelectedSession,
  numberOfGuests,
  setNumberOfGuests,
  maximumNumberOfGuests,
  selected,
  onSelect,
  openForm,
  loadingSessions,
  guestsAvailable,
  daysInAdvance,
}) => {
  const classNames = useClassName('BirthdayAdmissionCard');

  return (
    <div className={classNames('container')}>
      <div className={classNames('image')}>
        <ContentfulImage field={images} width={2000} />
      </div>
      <div className={classNames('header')}>
        <div>
          <h4>{title}</h4>
          <ContentfulRichText field={description} />
        </div>
        {!selected && (
          <div>
            <Button primary onClick={onSelect}>
              Select
            </Button>
          </div>
        )}
      </div>
      {selected && (
        <div className={classNames('session-options')}>
          <BirthdayDatePicker
            showLabel={false}
            date={date}
            daysInAdvance={daysInAdvance}
            setDate={(isoDate) => {
              const today = DateTime.fromObject({
                zone: 'America/New_York',
              }).startOf('day');
              const date = DateTime.fromISO(isoDate, {
                zone: 'America/New_York',
              });
              const nearDate =
                date.diff(today, 'days').toObject().days < daysInAdvance;

              if (nearDate) {
                openForm(isoDate);
              } else {
                setDate(isoDate);
              }
            }}
            openForm={openForm}
          />
          <BirthdaySessionPicker
            sessions={sessions}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            loading={loadingSessions}
          />
        </div>
      )}
      {Boolean(selected && date && selectedSession) && (
        <div className={classNames('guests')}>
          <h4 className={classNames('guests-title')}>Number of guests:</h4>
          <BirthdayGuestsInfo
            selectedSession={selectedSession}
            maximumNumberOfGuests={maximumNumberOfGuests}
            openForm={openForm}
            date={date}
            className={classNames('guests-info')}
          />
          <NewCheckoutQuantityControls
            onChange={setNumberOfGuests}
            quantity={numberOfGuests}
            min={selectedSession.guestsIncluded}
            max={guestsAvailable}
            editable
            className={classNames('quantity-controls')}
          />
        </div>
      )}
      {selected && sessions && sessions.length === 0 && (
        <div className={classNames('footer')}>
          <SVGIcon name="info" size="small" />
          <div>
            <p>
              It seems there is no availability for the date you chose. Please
              pick another date or{' '}
              <span
                onClick={() => openForm(date)}
                className={classNames('link')}>
                fill the form and we’ll get back to you.
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

AdmissionCard.propTypes = {
  images: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.object,
  date: PropTypes.string,
  setDate: PropTypes.func,
  sessions: PropTypes.array,
  selectedSession: PropTypes.object,
  setSelectedSession: PropTypes.func,
  numberOfGuests: PropTypes.number,
  setNumberOfGuests: PropTypes.func,
  maximumNumberOfGuests: PropTypes.number,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  openForm: PropTypes.func,
  loadingSessions: PropTypes.bool,
  guestsAvailable: PropTypes.number,
};

export default AdmissionCard;
