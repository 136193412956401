import React from 'react';
import { inject, observer } from 'mobx-react';
import qrcode from 'qrcode';
import { capitalize, get } from 'lodash';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { Screen } from 'public/helpers';
import { ContentfulImage, SVGIcon } from 'common/components';
import { formatCurrency } from 'utils/l10n';
import { trackEpsilon } from 'utils/analytics';

import { NewCheckoutFooter } from '../Components/Footer';
import { getBundleDates } from '../helpers';
import QRCodeModal from '../../../components/OrderGroup/components/QRCodeModal';
import { CartCompleted } from '../../Cart';
import WalletButtons from '../Components/WalletButtons';

import './congratulations.less';

const PAGE_SLUG = 'checkout-completed';

@inject('pages', 'checkout', 'me', 'appSession')
@observer
export default class Congratulations extends Screen {
  state = {
    parkingLinks: null,
  };

  async componentDidMount() {
    super.componentDidMount();
    const { order } = this.props.checkout.checkout;
    const { me, appSession } = this.props;
    await trackEpsilon(me, appSession, 100, 102, 'congratulations', order);
    this.getPage();
  }

  async getPage() {
    const page = await this.props.pages.fetchItemBySlug(PAGE_SLUG);
    this.setState({ page: page.object });
  }

  setQrCodes = async (ticket) => {
    const { ticketOption } = ticket;
    const name = ticketOption.addon
      ? ticketOption.name
      : ticketOption.venueName;

    const code = ticket.externalBookingId || ticket.issuedCode;
    const qrCodes = await qrcode
      .toDataURL(code, { width: 240, margin: 0 })
      .then((srcImage) => [
        {
          srcImage,
          id: code,
          venueName: ticketOption.venueName,
        },
      ]);

    this.setState({
      qrModal: {
        name,
        qrCodes,
      },
    });
  };

  renderQrCode = () => {
    if (!this.state.qrModal) return null;

    return (
      <QRCodeModal
        name={this.state.qrModal.name}
        qrCodes={this.state.qrModal.qrCodes}
        isOpen
        onClose={() => this.setState({ qrModal: null })}
      />
    );
  };

  renderDateRow = (ticketOption) => {
    const getSession = () => {
      if (ticketOption.bundleSlug) return null;
      if (ticketOption.addon) return 'Valid only with Ticket';

      return ticketOption.sessionName || ticketOption.startTime;
    };

    const getDate = () => {
      if (ticketOption.hiddenDate) return null;

      if (ticketOption.bundleSlug && ticketOption.passportType) {
        return getBundleDates({
          passportType: ticketOption.passportType,
          passportValidDays: ticketOption.passportValidDays,
          reservationDate: ticketOption.date,
        });
      }

      return DateTime.fromISO(ticketOption.date).toFormat('LLL dd');
    };

    const session = getSession();
    const date = getDate();

    return <span>{[session, date].filter(Boolean).join(' | ')}</span>;
  };

  renderImage = (ticketOption) => {
    const getImage = () => {
      if (!ticketOption.image) {
        const cartImages = this.props.checkout.checkout.images;
        if (!cartImages) return null;

        const cartImage = cartImages[ticketOption.bookingItemId];
        if (!cartImage) return null;

        return { assetUrl: cartImage, assetAlt: ticketOption.name };
      }

      return ticketOption.image;
    };

    const image = getImage();
    if (!image) return null;

    return (
      <img
        className={this.getElementClass('ticket-image')}
        src={image.assetUrl}
        alt={image.assetAlt}
      />
    );
  };

  renderBody() {
    const { order, intent } = this.props.checkout.checkout;
    if (!order) return <CartCompleted {...this.props} />;

    const { card, type } = get(
      intent,
      'charges.data[0].payment_method_details',
      { card: {}, type: null }
    );

    return (
      <>
        <div className={this.getElementClass('wrapper')}>
          <div className={this.getElementClass('container')}>
            <div>
              <SVGIcon name="success" size="large" />
              <h1>The adventure awaits you</h1>
              <h3>
                Your transaction has been confirmed. Your tickets have been sent
                to <strong>{order.user.email}</strong>
              </h3>
              <div style={{ display: 'flex', gap: '8px' }}>
                <WalletButtons orderId={order.id} />
              </div>
            </div>
            <div className={this.getElementClass('tickets')}>
              <div className={this.getElementClass('tickets-header')}>
                <h5>Your purchase</h5>
                <Link to="/profile/orders">Modify order</Link>
              </div>
              {order.tickets.map((ticket, index) => {
                const ticketOption = ticket.ticketOption;

                return (
                  <div key={index} className={this.getElementClass('ticket')}>
                    {this.renderImage(ticketOption)}
                    <div className={this.getElementClass('ticket-info')}>
                      <span>
                        {ticketOption.addon
                          ? ticketOption.name
                          : ticketOption.venueName}
                      </span>
                      <span>{this.renderDateRow(ticketOption)}</span>
                      <span>
                        {ticketOption.addon
                          ? 'Ticket'
                          : ticketOption.ticketOptionName}{' '}
                        x{ticketOption.quantity}
                      </span>
                    </div>
                    <div
                      className={this.getElementClass('ticket-code')}
                      onClick={() => this.setQrCodes(ticket)}>
                      <SVGIcon name="qr-code" size="medium" />
                    </div>
                  </div>
                );
              })}
            </div>
            {intent && (
              <div className={this.getElementClass('payment')}>
                <h5>YOUR payment METHOD</h5>
                <div>
                  <span>
                    <SVGIcon name="credit-card" size="small" />
                    {card?.brand
                      ? `${capitalize(card.brand)} ending in ${card.last4}`
                      : capitalize(type)}
                  </span>
                  <span>{formatCurrency(intent.charges.data[0].amount)}</span>
                </div>
              </div>
            )}
          </div>
          {this.state.page?.fields?.backgroundDesktop && (
            <div className={this.getElementClass('banner')}>
              <ContentfulImage
                field={this.state.page.fields.backgroundDesktop}
                width={1000}
              />
            </div>
          )}
        </div>
        <NewCheckoutFooter classes={this.getElementClass('footer')} />
        {this.renderQrCode()}
      </>
    );
  }
}
