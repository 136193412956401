import React from 'react';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { useClassName } from 'common/hooks';

import useChat from './useChat';

import './chat.less';

const Chat = () => {
  const { message, loading } = useChat({ openOnLoad: true });
  const className = useClassName('Chat');

  return (
    <div className={className('container')}>
      <h1 className={className('title')}>American Dream Chat</h1>
      <p className={className('content')}>
        Chat with our American Dream team!!
      </p>
      {loading && (
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      )}
      {!loading && message && <Message error>{message}</Message>}
    </div>
  );
};

export default Chat;
