import React from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { useClassName } from 'common/hooks';
import facebookLogo from 'common/assets/facebook.svg';
import { FACEBOOK_APP_ID } from 'utils/env/client';

import './facebook-login-button.less';

export default function FacebookLoginButton({ onSuccess }) {
  const classNames = useClassName('FacebookLoginButton');

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      onSuccess={onSuccess}
      render={({ onClick }) => (
        <div className={classNames('container')} onClick={onClick}>
          <img src={facebookLogo} width="24" height="24" alt="Facebook logo" />
        </div>
      )}
    />
  );
}
