import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Container, Loader } from 'semantic-ui-react';

import { Spacer } from 'public/components';
import { useClassName } from 'common/hooks';
import { urlForUpload } from 'utils/api';
import {
  DesktopOnly,
  getContentfulAssetUrlandAlt,
  getContentfulField,
} from 'common/components';

import { SectionTitle } from '../Directory/components';
import EventList from './components/EventList';
import SearchInput from './components/SearchInput';
import Categories from './components/Categories';
import { FeaturedSection } from '../Directory/components';
import { createRoute, getSchedule, normalize } from './utils';
import EventDetailsModal from './components/EventDetailsModal';

import './adevents.less';

const AdEvents = (props) => {
  let { category = 'all-happenings' } = useParams();
  const slideRef = useRef();
  const [events, setEvents] = useState(null);
  const [featured, setFeatured] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [selected, setSelected] = useState(null);
  const className = useClassName('AdEventPage');

  useEffect(() => {
    getFeatured();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const getCategoryNameByRoute = async () => {
    let subCategoryName = category;
    const categoryMap =
      subCategories ||
      (await props.adEvents.getEventsSubCategories(searchInput));
    const currentCategory = categoryMap.find(
      (subCategory) => subCategory.route === category
    );
    subCategoryName = currentCategory?.name || subCategoryName;
    return subCategoryName;
  };

  const getExternalOptions = (event) => {
    if (!event.externalId) return {};

    const schedule = getSchedule(event);
    const description = [
      schedule.startDate,
      ...(schedule.startDate.equals(schedule.endDate)
        ? []
        : [schedule.endDate]),
    ]
      .map((date) => date.toFormat('LLLL dd'))
      .join(' - ');

    return {
      description,
      onClick: (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        setSelected({ ...event, schedule, weekdays: schedule.weekdays });
      },
    };
  };

  const getFeatured = async () => {
    const data = (await props.adEvents.getFeaturedEvents()) || [];
    const normalizedEvent = data.map((event) => {
      const { _id, venue, title: name, image, logo, images } = event;
      let { content = { horizontalImage: null }, slug } = venue || {};
      content = content?.logo ? { ...content, ownLogo: content.logo } : content;

      const getUrl = (contentfulImage) => {
        return getContentfulAssetUrlandAlt(contentfulImage).assetUrl;
      };

      return {
        _id,
        content,
        name,
        slug,
        ...getExternalOptions(event),
        ownImage: { img: image, loadFunc: urlForUpload },
        ownLogo: { img: logo, loadFunc: urlForUpload },
        ownLink: { path: `/events/${event.slug}`, state: null },
        ...(images && {
          ownImage: {
            img: { 'en-US': getContentfulField(images)[0] },
            loadFunc: getUrl,
          },
        }),
      };
    });
    setFeatured([...normalizedEvent]);
  };

  const fetchData = async () => {
    const [data] = await props.adEvents.getAllEvents({
      subCategory: await getCategoryNameByRoute(),
      page: -1,
      skip: -1,
      published: true,
    });

    const subCategories = await props.adEvents.getEventsSubCategories();
    setEvents([...data]);
    setSubCategories([...subCategories]);
  };

  const inCategory = (event) => {
    if (category === 'all-happenings') return true;

    return event.categories.findIndex((a) => a.slug === category) >= 0;
  };

  const byName = (a, b) => (a.name > b.name ? 1 : -1);

  const getFilteredEvents = () => {
    if (!searchInput) {
      return events.filter(inCategory);
    }

    const search = normalize(searchInput);
    return events
      .filter(inCategory)
      .filter((event) => normalize(event.title).includes(search));
  };

  const getFilteredCategories = () => {
    const categories = subCategories || [];
    if (!searchInput) {
      return categories.sort(byName);
    }

    return getFilteredEvents()
      .map((event) => event.categories)
      .flat()
      .reduce(
        (acc, cur) => {
          if (acc.some((a) => a.name === cur.name)) return acc;

          return [...acc, { name: cur.name, route: createRoute(cur.name) }];
        },
        [{ name: 'All Happenings', route: createRoute('All Happenings') }]
      )
      .sort(byName);
  };

  const setInput = (value) => {
    setSearchInput(value);
  };

  return (
    <Container className={className('events-page-wrapper')}>
      <EventDetailsModal event={selected} onClose={() => setSelected(null)} />
      <div className={className('header-wrapper')}>
        <SectionTitle title="Happenings" />
      </div>
      {featured?.length > 0 && (
        <>
          <FeaturedSection
            featuredItems={featured}
            slideRef={slideRef}
            show={true}
            title={false}
          />
        </>
      )}
      {Boolean(events === null) && <Loader inline="centered" active />}
      {Boolean(events?.length === 0) && <span>There's no events.</span>}
      {events?.length > 0 && (
        <>
          <SearchInput
            input={searchInput}
            setInput={setInput}
            placeholder="Search Happenings"
          />
          <DesktopOnly>
            <Spacer size="xs" />
          </DesktopOnly>
          <Categories
            categories={getFilteredCategories()}
            currentCategory={category}
          />
          <Spacer size="m" />
          <EventList
            eventItems={getFilteredEvents()}
            setSelected={setSelected}
          />
        </>
      )}
    </Container>
  );
};

AdEvents.propTypes = {
  props: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default inject('adEvents')(AdEvents);
