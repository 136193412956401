import React from 'react';
import { defer } from 'lodash';
import { Container, Message } from 'semantic-ui-react';
import { trackPage } from 'utils/analytics';
import { Component } from 'common/helpers';
import { Spacer } from 'public/components';
import { captureError } from 'utils/sentry';
import Adhelmet from './Adhelmet';
// TODO: how do we deal with metadata/contentful etc?
// TODO: this is starting to get brittle with having to
// call super for all lifecycle methods...see if we can
// refactor
export default class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = this.state || {};
  }

  componentDidMount() {
    this.setupRouteEvents();
  }

  componentWillUnmount() {
    this.destroyRouteEvents();
  }

  // TODO: make this better
  _routeDidUpdate() {
    // Need to defer here as react-helmet is async and
    // segment.io checks for a canonical link first.
    defer(trackPage, this.constructor.name);
  }

  scrollToTop() {
    if (window.scrollY === 0) {
      return Promise.resolve();
    } else {
      return new Promise(function (resolve) {
        function onScroll() {
          if (window.scrollY === 0) {
            window.removeEventListener('scroll', onScroll);
            resolve();
          }
        }

        // Some browsers will throw an error on scrollTo
        // when an options object is passed.
        try {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          window.addEventListener('scroll', onScroll);
        } catch (err) {
          window.scrollTo(0, 0);
          resolve();
        }
      });
    }
  }

  render() {
    try {
      return (
        <React.Fragment>
          <Adhelmet
            {...this.props}
            event={this.state.event}
            loading={this.state.loading}
            customMetadata={this.state.customMetadata}
          />
          {this.renderBody()}
        </React.Fragment>
      );
    } catch (err) {
      // Note: Intentionally not using componentDidCatch as it
      // swallows errors for code with async initiators.
      captureError(err, {
        state: this.state,
      });
      return this.renderScreenError();
    }
  }

  renderScreenError() {
    return (
      <Container>
        <Spacer size="s" />
        <Message negative>Something went wrong</Message>
      </Container>
    );
  }
}
