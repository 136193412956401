import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

import {
  LazyVisible,
  useClassName,
  Only,
  preloadCTAs,
  preloadMedia,
  usePreload,
  useWindowSize,
} from 'common/hooks';

import { usePagination } from '../../Components/usePagination';
import HomepageButton from '../../Components/Button';
import HomepageImage from '../../Components/Image';
import HomepageAsset from '../../Components/Asset';
import trackHomepageSlide from '../../Components/trackHomepageSlide';
import trackHomepageSection from '../../Components/trackHomepageSection';

import 'swiper/swiper.less';
import './community.less';

const PREFIX = 'CommunitySection';

function CTAs({ section }) {
  const classNames = useClassName(PREFIX);

  return (
    <div className={classNames('ctas')}>
      {section.fields.ctas.map((cta) => (
        <HomepageButton
          key={cta.sys.id}
          cta={cta}
          className={classNames('cta')}
          fixed
        />
      ))}
    </div>
  );
}

export default function CommunitySection({ section }) {
  const classNames = useClassName(PREFIX);
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  const ref = useRef();
  const { isDesktop } = useWindowSize();
  usePreload((isDesktop) => {
    preloadMedia(section.fields.media, isDesktop);
    preloadCTAs(section.fields.ctas);
  }, ref);

  return (
    <LazyVisible
      style={{
        '--primary-color': section.fields.primaryColor,
        '--secondary-color': section.fields.secondaryColor,
      }}
      className={classNames('container')}
      id={section.fields.sectionId}
      ref={ref}
      onLoad={() =>
        trackHomepageSection(() => ({
          id: section.fields.sectionId,
        }))
      }>
      <div className={classNames('header')}>
        <div>
          <h3>{section.fields.header}</h3>
        </div>
        <Only desktop>
          <CTAs section={section} />
        </Only>
      </div>
      <Swiper
        modules={[Pagination]}
        height={542}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },
          1128: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        loop={false}
        onSlideChange={({ activeIndex }) =>
          trackHomepageSlide(() => ({
            activeIndex,
            name: section.fields.media[activeIndex].fields.name,
            section: section.fields.sectionId,
          }))
        }
        className={classNames('wrapper')}
        {...pagination}>
        {section.fields.media
          .filter((_, index) => (isDesktop ? index < 4 : true))
          .map((media, index) => {
            return (
              <SwiperSlide
                key={media.sys.id}
                className={classNames('slide-container')}>
                <HomepageButton
                  cta={media.fields.callToAction}
                  className={classNames('slide')}
                  style={{ animationDelay: `${index * 150}ms` }}>
                  <HomepageAsset asset={media.fields.asset} />
                  <div>
                    <HomepageImage field={media.fields.icon} />
                  </div>
                </HomepageButton>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <Only mobile tablet>
        <CTAs section={section} />
      </Only>
    </LazyVisible>
  );
}
