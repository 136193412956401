const getProductById = (products, productId) =>
  products
    .map((prod) => prod.products)
    .flat()
    .find((prod) => prod.id === productId);

const getPrice = (product) => product.cost * 100;

const getTax = (product) => product.tax / 100;

export const getMerchandiseSubtotal = ({ products, quantities }) => {
  return Object.keys(quantities)
    .map((productId) => {
      const product = getProductById(products, productId);
      return quantities[productId] * getPrice(product);
    })
    .reduce((a, b) => a + b, 0);
};

export const getMerchandiseTax = ({ products, quantities }) => {
  return Object.keys(quantities)
    .map((productId) => {
      const product = getProductById(products, productId);
      return quantities[productId] * (getPrice(product) * getTax(product));
    })
    .reduce((a, b) => a + b, 0);
};

export const getMerchandiseTotal = ({ products, quantities }) => {
  return (
    getMerchandiseSubtotal({ products, quantities }) +
    getMerchandiseTax({ products, quantities })
  );
};
