import React from 'react';
import { Component } from 'common/helpers';
import {
  ContentfulRichText,
  Layout,
  getContentfulField,
  DesktopOnly,
  MobileOnly,
} from 'common/components';
import ListedCards from './ListedCards';
import ResponsiveCard from './ResponsiveCard';

import './cardContainer.less';
import { Accordion } from 'semantic-ui-react';

export default class CardContainer extends Component {
  state = { activeIndex: [] };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    let { activeIndex } = this.state;

    if (activeIndex.includes(index)) {
      const itemIndex = activeIndex.indexOf(index);
      activeIndex.splice(itemIndex, 1);
    } else {
      activeIndex.push(index);
    }

    this.setState({ activeIndex: activeIndex });
  };

  render() {
    const { id, cards, title, description } = this.props;
    return (
      <div {...this.getAttrs()} id={id}>
        {title && (
          <h2>
            <ContentfulRichText field={title} />
          </h2>
        )}
        {description && <ContentfulRichText field={description} />}
        <DesktopOnly>
          {cards &&
            (id === 'general-health-and-safety-principles' ? (
              <Layout
                vertical
                stretch
                wrap
                className={this.getElementClass('cards-wrapper-stretch-wrap')}>
                {this.renderCards(cards)}
              </Layout>
            ) : (
              <Layout
                horizontal
                stackable
                wrap
                className={this.getElementClass('cards-wrapper')}>
                {this.renderCards(cards)}
              </Layout>
            ))}
        </DesktopOnly>
        <MobileOnly>
          {cards && (
            <Layout
              horizontal
              stackable
              wrap
              className={this.getElementClass('cards-wrapper')}>
              {this.renderCards(cards)}
            </Layout>
          )}
        </MobileOnly>
      </div>
    );
  }

  renderCards(cards) {
    return (
      cards &&
      cards.length &&
      cards.map((card, index) => {
        const type = card.fields.type
          ? getContentfulField(card.fields.type)
          : 'small';
        const id = getContentfulField(card.fields.name)
          .toLowerCase()
          .replace(/\s/g, '-');
        const description = card.fields.subcards
          ? null
          : card.fields.description;
        const { activeIndex } = this.state;

        return (
          <Layout.Group
            key={index}
            className={this.getElementClass(`list-item-${type}`)}>
            <Accordion id={id}>
              <Accordion.Title
                active={activeIndex.includes(index)}
                index={index}
                onClick={this.handleClick}>
                <ResponsiveCard
                  open={activeIndex.includes(index)}
                  title={card.fields.title}
                  description={description}
                  image={card.fields.image}
                  type={type}
                  backgroundImage={
                    card.fields.backgroundImage
                  }></ResponsiveCard>
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(index)}>
                {activeIndex.includes(index) && this.renderSubcards(card)}
              </Accordion.Content>
            </Accordion>
          </Layout.Group>
        );
      })
    );
  }

  renderSubcards(card) {
    if (card && card.fields && card.fields.subcards) {
      return (
        <ListedCards
          cards={getContentfulField(card.fields.subcards)}
          description={card.fields.description}
        />
      );
    }
  }
}
