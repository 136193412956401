import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

import { useClassName } from 'common/hooks';
import googleLogo from 'common/assets/google.svg';

import './google-login-button.less';

export default function GoogleLoginButton({ onSuccess, onError }) {
  const classNames = useClassName('GoogleLoginButton');
  const login = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    onSuccess: (tokenResponse) => {
      onSuccess(tokenResponse.code);
    },
    onError: () => {
      onError(new Error('Failed to authenticate.'));
    },
  });

  return (
    <div className={classNames('container')} onClick={() => login()}>
      <img src={googleLogo} width="24" height="24" alt="Google logo" />
    </div>
  );
}
