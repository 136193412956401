import React from 'react';
import { inject } from 'mobx-react';
import { getContentfulField } from 'common/components';
import { Screen } from 'public/helpers';
import { LandingPage, PrivateEventsForm } from 'public/components';
import { propTypes, defaultProps } from './PrivateEventsProps';

import './private-events.less';

@inject('pages')
class PrivateEvents extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: null,
      form: null,
    };
  }

  async componentDidMount() {
    const { pages } = this.props;
    const page = await pages.fetchItemBySlug(
      window.location.pathname.replace('/', '')
    );
    const pageComponents = getContentfulField(page.object.fields.components);
    const form = pageComponents.find(
      (component) => component.sys.contentType.sys.id === 'form'
    );
    this.setState({
      page,
      loading: false,
      form,
    });
  }

  renderBody() {
    const { loading, page, form } = this.state;

    if (loading) return null;

    return (
      <div className={this.getElementClass('container')}>
        <LandingPage object={page?.object} />
        {form && (
          <PrivateEventsForm
            id="private-events-form"
            form={form}
            brazeTriggers={this.props.brazeTriggers}
          />
        )}
      </div>
    );
  }
}

PrivateEvents.propTypes = propTypes;
PrivateEvents.defaultProps = defaultProps;

export default PrivateEvents;
