import React from 'react';
import { observer, inject } from 'mobx-react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import { Spacer } from '../Spacer';
import { Header, Button } from 'semantic-ui-react';

@inject('appSession')
@observer
export default class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'login',
    };
  }

  onLoginClick = () => {
    this.setState({
      type: 'login',
    });
  };

  onSignupClick = () => {
    this.setState({
      type: 'signup',
    });
  };

  render() {
    if (this.state.type === 'login') {
      return this.renderLogin();
    } else {
      return this.renderSignup();
    }
  }

  renderLogin() {
    return (
      <React.Fragment>
        <Header>Returning customer</Header>
        <LoginForm />
        <Spacer size="s" />

        <Header>First Time</Header>
        <Button fluid onClick={this.onSignupClick}>
          Create an account to buy tickets
        </Button>
      </React.Fragment>
    );
  }

  renderSignup() {
    return (
      <React.Fragment>
        <Header>Create an Account</Header>
        <SignupForm />
        <Spacer size="s" />

        <Header>Returning Customer</Header>
        <Button fluid onClick={this.onLoginClick}>
          Sign In
        </Button>
      </React.Fragment>
    );
  }
}
