import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'semantic-ui-react';

import { DealCard } from '../../Directory/components';
import { DesktopOnly, MobileOnly } from 'common/components';

const DealList = ({ dealItems }) => {
  const renderDeals = (columns) => (
    <Grid columns={columns}>
      {dealItems.map((deal) => (
        <DealCard deal={deal} key={deal._id} />
      ))}
    </Grid>
  );

  return (
    <>
      <DesktopOnly>{renderDeals(4)}</DesktopOnly>
      <MobileOnly>{renderDeals(1)}</MobileOnly>
    </>
  );
};

DealList.propTypes = {
  dealItems: PropTypes.array.isRequired,
};

export default DealList;
