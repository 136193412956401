import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { isSoldOut } from 'common/helpers';
import { getItemPrice, getQuantity } from 'common/helpers';
import { ControlledTabs } from 'public/components/Tabs';

import { NewCheckoutProductOption } from '../../Components/ProductOption';
import { NewCheckoutPointsDetails, NewCheckoutRidesPoints } from '../Points';

import './options.less';

const Options = ({
  venue,
  selectedDate,
  selectedSession,
  sessions,
  quantities,
  onChangeQuantity,
  inventory,
  forwardedRef,
  points,
  changePoints,
  isPoints,
}) => {
  const classNames = useClassName('Options');
  const startTime = selectedSession ? selectedSession.startTime : null;

  const getClassNames = () => {
    const cls = [classNames('container')];
    if (!showOptions()) cls.push(classNames('hidden'));

    return cls.join(' ');
  };

  const showOptions = () => {
    if (!selectedDate) return false;
    if (sessions.length && !selectedSession) return false;
    if (inventory.every(isUnavailable)) return false;

    return Boolean(inventory.length);
  };

  const isUnavailable = (item) => {
    return typeof item.quantity === 'object' && !item.quantity[startTime];
  };

  const selectedOption = inventory.findIndex(
    (item) => item.name.toLowerCase() === points
  );
  return (
    <div ref={forwardedRef} className={getClassNames()}>
      {isPoints ? <h2>SELECT AMOUNT OF POINTS</h2> : <h2>SELECT TICKET</h2>}
      {isPoints && (
        <ControlledTabs
          fixed
          names={inventory.map((item) => item.name)}
          selectedIndex={selectedOption}
          onClick={(name) => changePoints(name.toLowerCase())}
        />
      )}
      {showOptions() &&
        inventory.map((item, index) => {
          if (isUnavailable(item)) return null;
          if (isPoints && points !== item.name.toLowerCase()) return null;

          return (
            <NewCheckoutProductOption
              key={item.ticketOptionId || index}
              name={item.name}
              date={item.date}
              quantity={getQuantity(item, quantities)}
              isSoldOut={isSoldOut(item, startTime)}
              description={item.note}
              price={getItemPrice(item, inventory, quantities)}
              images={[
                isPoints
                  ? { src: item.image }
                  : { contentfulField: venue.content.horizontalImage },
              ]}
              onChangeQuantity={(value) => onChangeQuantity(item, value)}
              largeImage={isPoints}
              min={item.minPurchase}
              max={item.maxPurchase}
            />
          );
        })}
      {isPoints && (
        <>
          <NewCheckoutRidesPoints venue={venue} />
          <NewCheckoutPointsDetails />
        </>
      )}
    </div>
  );
};

Options.propTypes = {
  venue: PropTypes.object,
  selectedDate: PropTypes.string,
  selectedSession: PropTypes.object,
  sessions: PropTypes.array,
  quantities: PropTypes.object,
  onChangeQuantity: PropTypes.func,
  inventory: PropTypes.array,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  points: PropTypes.string,
  changePoints: PropTypes.func,
  isPoints: PropTypes.bool,
};

export default Options;
