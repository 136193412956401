import React from 'react';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Container,
  Grid,
  Button,
  Loader,
  Segment,
  Dropdown,
} from 'semantic-ui-react';
import { track, trackEpsilon } from 'utils/analytics';
import { Screen } from 'public/helpers';
import { Spacer, Pagination } from 'public/components';
import DirectoryBreadcrumb from 'public/components/DirectoryBreadcrumb';
import { Card, FeaturedSection, EntertainmentCard } from './components';
import {
  Layout,
  SVGIcon as Icon,
  DesktopOnly,
  MobileOnly,
} from 'common/components';

import './directory.less';
import { LinkTabs } from 'public/components/Tabs';

const TRANSITION_DURATION = 600;
const PER_PAGE = 21;

@inject('venues', 'me', 'appSession')
@observer
export default class Directory extends Screen {
  state = {
    display: 'categories',
    items: null,
    type: 'shopping',
    subCategory: 'all-stores',
    flag: null,
    query: null,
    letter: null,
    searchInput: '',
    searchActive: false,
    page: null,
    subCategories: [],
    itemsPerCategories: {
      shopping: [],
      attraction: [],
      restaurant: [],
    },
    subCategoryName: '',
    totalItems: 0,
  };

  constructor(props) {
    super(props);
    this.noScroll = true;
    this.searchInputRef = React.createRef();
    this.slideRef = React.createRef();
    this.fetchCategories();
    this.debouncedFetch = debounce(this.fetch, 300);
  }

  async routeDidUpdate() {
    const { match, location } = this.props;
    const mapFlagsDefault = {
      shopping: 'all-stores',
      dining: 'all-food-drink',
      entertainment: 'all-entertainments',
    };
    let { type = 'shopping', flag } = match.params;
    flag = (!flag && mapFlagsDefault[type]) || flag;
    const params = new URLSearchParams(location.search);
    const query = params.get('q');
    const letter = params.get('l');
    const sort = params.get('sort');
    const page = Number(params.get('page')) || 1;

    this.setState({
      ...this.state,
      type,
      flag,
      query,
      letter,
      searchInput: letter || query,
      sort,
      page,
      searchActive: Boolean(letter || query),
    });

    track('Directory visted', { label: type });
    const { me, appSession } = this.props;
    await trackEpsilon(me, appSession, 2, 101, type);
  }

  componentDidUpdate(lastProps, lastState) {
    const { type, flag, query, letter, page, searchInput } = this.state;
    if (type !== lastState.type) {
      this.fetchCategories();
    }
    if (
      type !== lastState.type ||
      flag !== lastState.flag ||
      page !== lastState.page ||
      query !== lastState.query ||
      letter !== lastState.letter ||
      searchInput !== lastState.searchInput
    ) {
      this.debouncedFetch();
    }
  }

  async fetchCategories() {
    try {
      const subCategories = await this.props.venues.fetchCategories();
      this.setState({
        ...this.state,
        subCategories,
      });
    } catch (error) {
      this.setState({
        error: error,
      });
    }
  }

  async fetch() {
    const { page, searchInput } = this.state;
    this.setState({
      ...this.state,
      items: null,
    });
    try {
      let { type = 'shopping', flag, subCategoryName } = this.state;
      const mapFlagsDefault = {
        shopping: 'all-stores',
        dining: 'all-food-drink',
        entertainment: 'all-entertainments',
      };
      flag = (!flag && mapFlagsDefault[type]) || flag;
      const subCategories = await this.props.venues.fetchCategories();
      subCategoryName =
        subCategoryName ||
        (subCategories?.length > 0 &&
          subCategories.find((subCategory) => subCategory.route === flag).name);
      const mappedVenueType = this.getVenueType();
      let [items, totalItems] = await this.props.venues.fetchItems({
        name: searchInput || null,
        skip: (page - 1) * PER_PAGE,
        limit: PER_PAGE,
        isListed: true,
        ...(!searchInput && {
          venueType: mappedVenueType,
          categorySelected: flag,
          subCategoryName: subCategoryName || `${subCategoryName}`,
        }),
      });

      const itemsInCurrentCategory =
        await this.props.venues.fetchVenuesByCategory(mappedVenueType);
      const itemsPerCategories = {
        ...this.state.itemsPerCategories,
        [mappedVenueType]: itemsInCurrentCategory,
      };
      items = items.filter(
        (item) => !(item.displayHours && !item.contentfulVenueId)
      );
      this.setState({
        ...this.state,
        items,
        itemsPerCategories,
        totalItems,
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  }

  getVenueType() {
    const { type = 'shopping' } = this.state;
    if (type === 'all') {
      return null;
    } else if (type === 'entertainment') {
      return 'attraction';
    } else if (type === 'shopping') {
      return 'shopping';
    } else if (type === 'dining') {
      return 'restaurant';
    } else {
      return type;
    }
  }

  getFeaturedFlags(flag) {
    switch (flag) {
      case 'featured':
        return ['Featured'];
      case 'coming-soon':
        return ['Coming Soon'];
    }
  }

  onSearchButtonClick = () => {
    this.setState({
      searchActive: true,
      letter: null,
    });
    // Focusing will force element scroll, so
    // wait to focus here.
    setTimeout(() => {
      this.searchInputRef.current.focus();
    }, TRANSITION_DURATION);
  };

  onSearchChange = (evt) => {
    this.setState({
      searchInput: evt.target.value,
    });
  };

  onSearchKeyDown = (evt) => {
    if (evt.keyCode === 27) {
      this.onSearchCloseClick();
    }
  };

  onSearchCommit = () => {
    const { searchInput, type, flag } = this.state;
    this.props.history.push(
      this.getSearchUrl({
        type,
        subCategory: flag,
        query: searchInput,
        letter: null,
        page: 1,
      })
    );
  };

  onSearchCloseClick = () => {
    this.setState({
      searchActive: false,
    });
    this.searchInputRef.current.blur();
    setTimeout(() => {
      this.setState({
        searchInput: '',
      });
      this.props.history.push(this.getSearchUrl({ query: null, page: 1 }));
    }, TRANSITION_DURATION);
  };

  onPageChange = async (evt, data) => {
    const { type, flag } = this.state;
    const url = this.getSearchUrl({
      page: data.activePage,
      type,
      subCategory: flag,
    });
    this.props.history.push(url);
  };

  // TODO: this will be tricky when we get
  // a lot of venues as we can't know in advance
  // so turning off for now
  getDeactivatedLetters = () => {
    let disableLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.toLowerCase();

    this.state.items.map((c) => {
      disableLetters = disableLetters.replace(c.name[0].toLowerCase(), '');
    });
    return disableLetters;
  };

  renderBody() {
    const {
      type = 'shopping',
      flag,
      searchInput,
      subCategories,
      items,
    } = this.state;
    const { categories } = this.props.venues;
    const title =
      flag && categories.length
        ? categories.find((category) => category.route === flag).name
        : '';
    return (
      <React.Fragment>
        <Container className="directory-page-wrapper">
          <DirectoryBreadcrumb type={type} title={title} />
          <h1>Discover all what we have to offer</h1>
          {this.renderStaticBar()}
          <FeaturedSection
            featuredItems={this.getFeaturedVenues()}
            slideRef={this.slideRef}
            show={!searchInput}
            title={false}
          />
          {!searchInput && subCategories?.length > 0 && (
            <Layout horizontal className="sub-categories-wrapper">
              {this.renderSubCategories()}
            </Layout>
          )}
          {Boolean(searchInput && items?.length) && (
            <h3>Results for “{searchInput}”</h3>
          )}
          <Spacer size="m" />
          {this.renderVenues()}
          <Spacer size="m" />
          {this.renderPagination()}
        </Container>
      </React.Fragment>
    );
  }

  getFeaturedVenues() {
    const { type = 'shopping' } = this.state;
    const mapTypes = {
      shopping: 'shopping',
      dining: 'restaurant',
      entertainment: 'attraction',
    };
    const { itemsPerCategories } = this.state;
    return itemsPerCategories[mapTypes[type]].filter((venue) =>
      venue.featuredFlags.includes('Featured')
    );
  }

  getSearchUrl(update) {
    const state = Object.assign({}, this.state, update);
    const params = new URLSearchParams();
    let url = '/directory';
    if (state.type) {
      url += `/${state.type}`;
    } else if (state.flag) {
      url += `/all-stores`;
    }
    if (state.subCategory && state.subCategory !== state.flag) {
      url += `/${state.subCategory}`;
    }
    if (state.flag && state.subCategory === state.flag) {
      url += `/${state.flag}`;
    }
    if (state.query) {
      params.append('q', state.query);
    }
    if (state.letter) {
      params.append('l', state.letter);
    }
    if (state.sort) {
      params.append('sort', state.sort);
    }
    if (state.page && state.page > 1) {
      params.append('page', state.page);
    }
    const qs = params.toString();
    if (qs) {
      url += `?${qs}`;
    }
    return url;
  }

  getUrlForLetter = (letter) => {
    if (letter === this.state.letter) {
      letter = null;
    }
    return this.getSearchUrl({
      letter,
      query: null,
      page: 1,
    });
  };

  getCategoryClass(active) {
    return this.getElementClass('category', active ? 'active' : null, null);
  }

  renderIconOpt(active, iconName) {
    return (
      <div
        className={this.getElementClass(
          `icon-container`,
          active ? 'active' : null,
          null
        )}>
        <Icon size="small" name={iconName} />
      </div>
    );
  }

  renderCategoryLegend(legend) {
    return (
      <span className="category_legend">
        <span>{legend}</span>
      </span>
    );
  }

  renderSubCategories() {
    const {
      type: currentType = 'shopping',
      subCategories,
      itemsPerCategories: items,
    } = this.state;
    const mapTypes = {
      shopping: 'shopping',
      dining: 'restaurant',
      entertainment: 'attraction',
    };
    const defaultCategories = [
      'All Stores',
      'All Food & Drink',
      'All Entertainment',
    ];
    const settedFeaturedSubCategories = ['Now Open', 'Coming Soon'];
    const featuredCategories = items[mapTypes[currentType]]
      .map((item) => item.featuredFlags)
      .flat()
      .filter((featuredFlag) =>
        settedFeaturedSubCategories.includes(featuredFlag)
      );
    let categoriesOnItems = items[mapTypes[currentType]]
      .map((item) => item.categories)
      .flat();
    categoriesOnItems = [...categoriesOnItems, ...featuredCategories];
    let categories = subCategories.filter(
      (category) =>
        (category.categoryType === mapTypes[currentType] &&
          categoriesOnItems.includes(category.name)) ||
        (category.categoryType === mapTypes[currentType] &&
          defaultCategories.includes(category.name))
    );

    const links = categories.map((category) => {
      const url = this.getSearchUrl({
        type: `${currentType}`,
        subCategory: `${category.route}`,
        query: null,
        page: 1,
      });

      return {
        url,
        title: category.name,
      };
    });

    return (
      <LinkTabs
        links={links}
        isSelected={(index) => this.state.flag === categories[index].route}
      />
    );
  }

  renderStaticBar() {
    const { type: currentType, searchActive, searchInput } = this.state;
    return (
      <div className={this.getElementClass('bar')}>
        <div
          className={[
            this.getElementClass('categories'),
            searchActive && this.getElementClass('search-active'),
            searchInput && this.getElementClass('typing'),
          ]
            .filter(Boolean)
            .join(' ')}>
          <Link
            to={this.getSearchUrl({
              type: 'shopping',
              subCategory: 'all-stores',
              query: null,
              page: 1,
            })}
            className={this.getCategoryClass(
              !currentType || currentType === 'shopping'
            )}>
            {this.renderIconOpt(
              !currentType || currentType === 'shopping',
              'store'
            )}
            {this.renderCategoryLegend('Stores')}
          </Link>
          <Link
            to={this.getSearchUrl({
              type: 'dining',
              subCategory: 'all-food-drink',
              query: null,
              page: 1,
            })}
            className={this.getCategoryClass(currentType === 'dining')}>
            {this.renderIconOpt(currentType === 'dining', 'food')}
            {this.renderCategoryLegend('Food & Drinks')}
          </Link>
          <Link
            to={this.getSearchUrl({
              type: 'entertainment',
              subCategory: 'all-entertainments',
              query: null,
              page: 1,
            })}
            className={this.getCategoryClass(currentType === 'entertainment')}>
            {this.renderIconOpt(currentType === 'entertainment', 'attraction')}
            {this.renderCategoryLegend('Entertainment')}
          </Link>
          <Link to={'/deals/category'} className={this.getCategoryClass(false)}>
            {this.renderIconOpt(false, 'deal')}
            {this.renderCategoryLegend('Deals')}
          </Link>
        </div>

        {this.renderSearch()}
      </div>
    );
  }

  renderSearch() {
    const { searchActive } = this.state;

    return (
      <>
        <div
          className={this.getElementClass('new-search')}
          {...(searchActive && {
            style: { width: '100%', borderRadius: '12px' },
          })}
          {...(!searchActive && {
            onClick: () => {
              this.setState({ searchActive: !searchActive }, () => {
                this.searchInputRef.current.focus();
              });
            },
            style: { cursor: 'pointer' },
          })}>
          <Icon name="search" size="tiny" />
          <input
            type="text"
            placeholder="Search Stores, Dinning, Entertainment or Parks & Attractions"
            ref={this.searchInputRef}
            onChange={this.onSearchChange}
            onKeyDown={this.onSearchKeyDown}
            value={this.state.searchInput}
            {...(!searchActive && { style: { display: 'none' } })}
          />
          {Boolean(searchActive && this.state.searchInput) && (
            <div
              onClick={() => {
                this.setState({ searchActive: false, searchInput: '' });
              }}>
              <Icon name="close-light" size="tiny" />
            </div>
          )}
        </div>
        {searchActive && (
          <button
            className={this.getElementClass('close-search')}
            onClick={() => {
              this.setState({ searchActive: false, searchInput: '' });
            }}>
            Close
          </button>
        )}
      </>
    );
  }

  renderFilters() {
    return (
      <Layout horizontal right padded>
        <Layout.Group>
          <Dropdown icon={null} trigger={this.renderFilterLabel()}>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to={this.getSearchUrl({ flag: null, page: 1 })}>Off</Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={this.getSearchUrl({ flag: 'featured', page: 1 })}>
                  Featured
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={this.getSearchUrl({ flag: 'coming-soon', page: 1 })}>
                  Coming Soon
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Layout.Group>
        <Layout.Group>
          <Dropdown icon={null} trigger={this.renderSortLabel()}>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to={this.getSearchUrl({ sort: null, page: 1 })}>Off</Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to={this.getSearchUrl({ sort: 'on', page: 1 })}>On</Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Layout.Group>
      </Layout>
    );
  }

  renderVenues() {
    let { items, searchInput } = this.state;
    if (items == null) {
      return (
        <React.Fragment>
          <Loader inline="centered" active />
        </React.Fragment>
      );
    } else if (!items.length) {
      let title = "Sorry, we couldn't find any results";
      if (searchInput) {
        title += ` for ‘${searchInput}’`;
      }
      return (
        <div className={this.getElementClass('no-results')}>
          <Spacer size="s" />
          <p className={this.getElementClass('no-results-title')}>{title}</p>
          {searchInput && (
            <React.Fragment>
              <Spacer size="xs" />
              <p className={this.getElementClass('no-results-message')}>
                Please try entering a different search term
              </p>
            </React.Fragment>
          )}
        </div>
      );
    }
    return (
      <>
        <DesktopOnly>
          <Segment>
            <Grid columns={6}>{items.map(this.renderVenue, this)}</Grid>
          </Segment>
        </DesktopOnly>
        <MobileOnly>
          <Segment>
            <Grid columns={3} className="venue-card-grid">
              {items.map(this.renderVenue, this)}
            </Grid>
          </Segment>
        </MobileOnly>
      </>
    );
  }

  renderVenue(venue) {
    const { venueType } = venue;
    const { searchInput } = this.state;
    if (venueType === 'attraction' && !searchInput) {
      return <EntertainmentCard venue={venue} key={venue.id} />;
    }
    return (
      <Link
        to={`/venue/${venue.slug}`}
        key={venue.id}
        className="venue-card-link">
        <Card venue={venue} />
      </Link>
    );
  }

  renderFilterLabel() {
    return (
      <Button className={this.getElementClass('filter')}>
        <Icon name="filter" size="mini" />
        Filter
      </Button>
    );
  }

  renderSortLabel() {
    return (
      <Button className={this.getElementClass('filter')}>
        <Icon name="sort" size="mini" />
        Sort
      </Button>
    );
  }

  renderPagination() {
    const { page, items, totalItems } = this.state;
    if (items != null && totalItems > PER_PAGE) {
      return (
        <React.Fragment>
          <Pagination
            totalPages={Math.ceil(totalItems / PER_PAGE)}
            activePage={page}
            onPageChange={this.onPageChange}
          />
          <Spacer size="m" />
        </React.Fragment>
      );
    }
  }
}
