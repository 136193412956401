import React from 'react';
import { Container, Grid, Button } from 'semantic-ui-react';
import {
  Modal,
  Layout,
  Content,
  ExternalLink,
  ScrollWaypoint,
  ResponsiveImage,
  ContentfulPlainText,
  ContentfulRichText,
  getContentfulField,
  getContentfulAssetUrlandAlt,
  MapKitMap as Map,
  Video,
} from 'common/components';

import { Screen, withVenue } from 'public/helpers';

import {
  Spacer,
  AttractionsDreamworksNav,
  NearbyVenues,
  VerticalCard,
  AttractionScreen,
} from 'public/components';

import { ContentTypeSlugs } from 'public/constants/contentful';

import { track } from 'utils/analytics';

import Carousel from './Carousel';
import MotionHero from './MotionHero';
import MotionGraphic from './MotionGraphic';

import './home.less';

@withVenue('dreamworks-water-park')
export default class DreamWorksHome extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      focusedFloor: props.venue.floorLevel || 1,
    };
  }

  onFloorUpdate = (floor) => {
    this.setState({
      focusedFloor: floor,
    });
  };

  renderMapCallout = () => {
    const { venue } = this.props;
    return (
      <div>
        <p className={this.getElementClass('map-callout-title')}>
          {venue.name}
        </p>
        <p className={this.getElementClass('map-callout-subtitle')}>
          {venue.locationDescription}
        </p>
      </div>
    );
  };

  updateInfoBlock(index, name, value) {
    const key = `infoBlock${index}`;
    const newState = Object.assign({}, this.state[key], {
      [name]: value,
    });
    this.setState({
      [key]: newState,
    });
  }

  getScrollModifier(index) {
    const { entered } = this.getInfoBlockState(index);
    return this.getSlideModifier(entered);
  }

  getSlideModifier(val) {
    if (val === true) {
      return 'in';
    } else if (val === false) {
      return 'out';
    } else {
      return null;
    }
  }

  getInfoBlockState(index) {
    return this.state[`infoBlock${index}`] || {};
  }

  handleVideoPlay = (id, name, evt) => {
    evt.preventDefault();
    const video = document.getElementById(id);
    if (!video) return null;

    const { isPlayingVideo } = this.state;

    if (isPlayingVideo) {
      this.setState({
        isPlayingVideo: false,
      });
      video.pause();
    } else {
      this.setState({
        isPlayingVideo: true,
      });
      video.controls = true;
      video.play();

      track('Video Play', { label: name });

      video.onended = () => {
        track('Video Ended', { label: name });
      };
    }
  };

  handleLinkClick = (name) => {
    return () => {
      const { venue } = this.props;
      track(`${venue.slug} - Viewed ${name}`);
    };
  };

  renderBody() {
    const { venue } = this.props;
    const hero = getContentfulField(venue.content.hero);
    const file = getContentfulField(hero[0].fields.file);
    const videoPreview = getContentfulAssetUrlandAlt(
      venue.content.heroVideoPreview
    );

    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }

    return (
      <div {...this.getAttrs()}>
        {file.contentType === ContentTypeSlugs.Video ? (
          <div
            className={this.getElementClass('main-video-container')}
            onClick={(evt) =>
              this.handleVideoPlay('video-dwwp-hero', 'DWWP Hero Video', evt)
            }>
            <Video
              src={file.url}
              id={`video-dwwp-hero`}
              className={this.getElementClass('main-video')}
              poster={videoPreview.assetUrl}
              preload="metadata"
              controls={false}
              controlsList="nodownload"
            />
          </div>
        ) : (
          <MotionHero
            desktopGraphic={venue.content.hero}
            mobileGraphic={venue.content.heroMobile}
          />
        )}

        <AttractionsDreamworksNav venue={venue} />
        <div className={this.getElementClass('main-block')}>
          <Spacer size="m" />
          <Container>
            <div className={this.getElementClass('main-title')}>
              DreamWorks Water Park
            </div>
            <Spacer size="xs" />
            <div className={this.getElementClass('main-subtitle')}>
              Experience year-round fun and sun with some of DreamWorks
              Animation’s memorable characters in the largest indoor water park
              in North America.
            </div>
            <Spacer size="xs" />
            <Button
              inverted
              as={ExternalLink}
              href={`/venue/${venue.slug}/tickets`}
              className={this.getElementClass('main-cta')}
              onClick={this.handleLinkClick('Section1 - BuyTickets')}>
              <span>Buy Tickets</span>
            </Button>
            <Spacer size="l" />
          </Container>
        </div>
        <div className={this.getElementClass('info-blocks')}>
          <Container>
            <Spacer size="m" />
            {this.renderInfoBlocks()}
            <Spacer size="xs" />
            <div className={this.getElementClass('info-block-title')}>
              Things To Do
              <Spacer size="s" />
            </div>
          </Container>
        </div>
        {this.renderCards()}
        <Spacer size="m" />
        {/* {this.renderMap()} */}
        <Container>
          <Spacer size="m" />
          <NearbyVenues venue={venue} />
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderInfoBlocks() {
    const { venue } = this.props;
    return (
      <div>
        {getContentfulField(venue.content.infoBlocks).map((infoBlock, i) => {
          return this.renderInfoBlock(infoBlock, i);
        })}
      </div>
    );
  }

  renderInfoBlock(infoBlock, index) {
    const { fields } = infoBlock;
    const reverse = index % 2 === 0;
    return (
      <div key={index} className={this.getElementClass('info-block')}>
        <ScrollWaypoint
          onEnter={() => this.updateInfoBlock(index, 'entered', true)}
          onLeave={() => this.updateInfoBlock(index, 'entere', false)}>
          <Layout reversed={reverse} horizontal center stackable extra padded>
            {this.renderInfoBlockImage(index, fields)}
            {this.renderInfoBlockText(index, fields)}
          </Layout>
        </ScrollWaypoint>
      </div>
    );
  }

  renderInfoBlockText(index, fields) {
    const mod = this.getScrollModifier(index);
    const linkHref = getContentfulField(fields.link);

    return (
      <Layout.Group className={this.getElementClass('slide-text', mod)}>
        <ContentfulRichText
          className={this.getElementClass('info-block-title')}
          field={fields.title}
        />
        <Spacer size="xs" />
        <ContentfulRichText
          className={this.getElementClass('info-block-body')}
          field={fields.body}
        />
        <Spacer size="s" />
        <Button
          as={ExternalLink}
          href={linkHref}
          onClick={this.handleLinkClick(linkHref)}
          onMouseEnter={() => this.updateInfoBlock(index, 'hovering', true)}
          onMouseLeave={() => this.updateInfoBlock(index, 'hovering', false)}>
          <ContentfulPlainText field={fields.linkText} />
        </Button>
      </Layout.Group>
    );
  }

  renderInfoBlockImage(index, fields) {
    const mod = this.getScrollModifier(index);
    const linkHref = getContentfulField(fields.link);
    const { hovering } = this.getInfoBlockState(index);
    return (
      <Layout.Group className={this.getElementClass('slide-image', mod)}>
        <ExternalLink href={linkHref}>
          <MotionGraphic
            className={this.getElementClass(
              'info-block-image',
              hovering ? 'hover' : null
            )}
            field={fields.image}
            circle
          />
        </ExternalLink>
      </Layout.Group>
    );
  }

  onCardsEnter = () => {
    this.setState({
      cardsEntered: true,
    });
  };

  onCardsLeave = () => {
    this.setState({
      cardsEntered: false,
    });
  };

  renderCards() {
    const { cardsEntered } = this.state;
    const mod = this.getSlideModifier(cardsEntered);
    return (
      <Container className={this.getElementClass('cards')}>
        <ScrollWaypoint onEnter={this.onCardsEnter} onLeave={this.onCardsLeave}>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column className={this.getElementClass('card', mod)}>
                <Modal
                  size="small"
                  className={this.getElementClass('modal-wrap')}
                  trigger={
                    <div>
                      <VerticalCard
                        body="RIDES, SLIDES + ATTRACTIONS"
                        image={
                          <MotionGraphic
                            ratio={0.63}
                            contentfulName="DreamWorks Animation - Card 1"
                          />
                        }
                        centered
                        hover
                      />
                    </div>
                  }>
                  {this.renderRidesModal()}
                </Modal>
              </Grid.Column>
              <Grid.Column className={this.getElementClass('card', mod)}>
                <Modal
                  size="small"
                  className={this.getElementClass('modal-wrap')}
                  trigger={
                    <div>
                      <VerticalCard
                        body="MEET AND GREETS"
                        image={
                          <MotionGraphic
                            ratio={0.63}
                            contentfulName="DreamWorks Animation - Card 2"
                          />
                        }
                        centered
                        hover
                      />
                    </div>
                  }>
                  {this.renderMeetAndGreetModal()}
                </Modal>
              </Grid.Column>
              <Grid.Column className={this.getElementClass('card', mod)}>
                <Modal
                  size="small"
                  className={this.getElementClass('modal-wrap')}
                  trigger={
                    <div>
                      <VerticalCard
                        body="GET STOKED AT SOAKIN SURFARI"
                        image={
                          <MotionGraphic
                            ratio={0.63}
                            contentfulName="DreamWorks Animation - Card 3"
                          />
                        }
                        centered
                        hover
                      />
                    </div>
                  }>
                  {this.renderSurfsUpModal()}
                </Modal>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ScrollWaypoint>
      </Container>
    );
  }

  renderRidesModal() {
    const { venue } = this.props;
    return (
      <div>
        <div className={this.getElementClass('modal-title', 'centered')}>
          Ogre-whelming Fun!
        </div>
        <Spacer size="xs" />
        <Carousel items={venue.content.rides} />
      </div>
    );
  }

  renderMeetAndGreetModal() {
    const { venue } = this.props;
    return (
      <div>
        <div className={this.getElementClass('modal-title', 'centered')}>
          Meet And Greets
        </div>
        <Carousel items={venue.content.characters} offset />
        <Spacer size="s" />
      </div>
    );
  }

  renderSurfsUpModal() {
    return (
      <div className={this.getElementClass('modal-padded-left')}>
        <div className={this.getElementClass('modal-title')}>
          Get Stoked At Soakin Surfari
        </div>
        <Spacer size="xs" />
        <Layout horizontal center reverse padded stackable>
          <Layout.Group grow>
            <Content>
              <p>
                Grab a board, catch a swell, and see how long you can ride the
                wave.
              </p>
              <p>
                Whether you’re a pro at riding swells or just a master at the
                boogie board, Soakin Surfari is the ultimate simulated
                wave-shredding experience.
              </p>
              <p>
                Soakin Surfari is a body boarding and stand-up surf experience
                which uses jet-propelled water to simulate waves.
              </p>
            </Content>
          </Layout.Group>
          <Layout.Group fixed>
            <ResponsiveImage
              className={this.getElementClass('modal-image')}
              contentfulName="DreamWorks - Surf's Up"
            />
          </Layout.Group>
        </Layout>
      </div>
    );
  }

  renderMap() {
    const { venue } = this.props;
    if (venue.location) {
      const { lat, lng } = venue.location;
      return (
        <Map
          className={this.getElementClass('map')}
          annotation={{
            lat,
            lng,
            floor: venue.floorLevel,
            renderCallout: this.renderMapCallout,
            selected: true,
          }}
          focusedVenue={venue}
          focusedFloor={this.state.focusedFloor}
          onFloorUpdate={this.onFloorUpdate}
          renderZoomButtons
          removeControls
        />
      );
    }
  }
}
