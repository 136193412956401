import React from 'react';
import { Container, Grid, Button } from 'semantic-ui-react';
import {
  Layout,
  ResponsiveImage,
  ExternalLink,
  ScrollWaypoint,
} from 'common/components';
import { Screen, withVenue } from 'public/helpers';
import {
  Headline,
  Spacer,
  AttractionsNav,
  VerticalCard,
  NearbyVenues,
  AttractionScreen,
} from 'public/components';
//import { EventsCarousel } from 'public/loaders';
import Hero from './Hero';

import './home.less';

@withVenue('big-snow')
export default class BigSnowHome extends Screen {
  state = {
    programsVisible: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onYetiClick = () => {
    this.scrollToTop();
  };

  getInfoBlockState(index) {
    return this.state[`infoBlock${index}`] || {};
  }

  updateInfoBlock(index, name, value) {
    const key = `infoBlock${index}`;
    const newState = Object.assign({}, this.state[key], {
      [name]: value,
    });
    this.setState({
      [key]: newState,
    });
  }

  getScrollModifier(index) {
    const { entered } = this.getInfoBlockState(index);
    return this.getSlideModifier(entered);
  }

  getSlideModifier(val) {
    if (val === true) {
      return 'in';
    } else if (val === false) {
      return 'out';
    } else {
      return null;
    }
  }

  getHoverProps(index) {
    return {
      onMouseEnter: () => {
        this.updateInfoBlock(index, 'hovering', true);
      },
      onMouseLeave: () => {
        this.updateInfoBlock(index, 'hovering', false);
      },
    };
  }

  renderBody() {
    const { venue } = this.props;

    if (venue.content.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }

    return (
      <div {...this.getAttrs()}>
        <Hero />
        <AttractionsNav
          venue={this.props.venue}
          ticketsHref="https://www.bigsnowamericandream.com"
        />
        <Spacer size="m" />
        {/*disable events carousel for now
        <Container>
          <Headline title="The Most Fun" subtitle="You Can Have Indoors" />
        </Container>
        <Spacer size="l" />
        <EventsCarousel venueId={venue.id} />
        <Spacer size="l" />
        */}
        <div className={this.getElementClass('dark')}>
          <Container>
            <Spacer size="m" />
            <div>
              {this.renderPackagesBlock()}
              {this.renderTicketsBlock()}
            </div>
            <Spacer size="l" />
          </Container>
        </div>
        <Container>
          {this.renderPrograms()}
          <Spacer size="l" />
          <NearbyVenues venue={venue} />
          <Spacer size="l" />
          <Layout vertical center>
            <Layout
              className={this.getElementClass('bottom')}
              onClick={this.onYetiClick}
              horizontal
              center>
              <ResponsiveImage
                contentfulName="Big Snow - Bottom"
                width={76}
                height={64}
              />
              <div className={this.getElementClass('bottom-text')}>
                Back To Top
              </div>
            </Layout>
          </Layout>
          <Spacer size="m" />
        </Container>
      </div>
    );
  }

  renderPackagesBlock() {
    const mod = this.getScrollModifier(1);
    const { hovering } = this.getInfoBlockState(1);
    return (
      <div className={this.getElementClass('info-block')}>
        <ScrollWaypoint
          onEnter={() => this.updateInfoBlock(1, 'entered', true)}
          onLeave={() => this.updateInfoBlock(1, 'entered', false)}>
          <Layout horizontal center stackable reversed extra padded>
            <Layout.Group className={this.getElementClass('slide-image', mod)}>
              <ExternalLink
                href="https://www.bigsnowamericandream.com/snow-day-packages/"
                className={this.getElementClass('bubble')}>
                <ResponsiveImage
                  className={this.getElementClass(
                    'bubble-image',
                    'top',
                    hovering ? 'hover' : null
                  )}
                  contentfulName="Big Snow - Bubble - 1"
                  square
                />
              </ExternalLink>
            </Layout.Group>
            <Layout.Group className={this.getElementClass('slide-text', mod)}>
              <Headline title="Snow Day" subtitle="Packages" />
              <Spacer size="s" />
              <p className={this.getElementClass('dark-text')}>
                Whether it's your first day sliding on snow or you're just
                looking to get out and enjoy our slopes, our SNOW Day package
                gives you everything you need to enjoy a day on the snow or
                begin your lifetime of skiing or snowboarding.
              </p>
              <Spacer size="s" />
              <Button
                as={ExternalLink}
                href="https://www.bigsnowamericandream.com/snow-day-packages/"
                {...this.getHoverProps(1)}>
                Find Out More
              </Button>
            </Layout.Group>
          </Layout>
        </ScrollWaypoint>
      </div>
    );
  }

  renderTicketsBlock() {
    const mod = this.getScrollModifier(2);
    const { hovering } = this.getInfoBlockState(2);
    return (
      <div className={this.getElementClass('info-block')}>
        <ScrollWaypoint
          onEnter={() => this.updateInfoBlock(2, 'entered', true)}
          onLeave={() => this.updateInfoBlock(2, 'entered', false)}>
          <Layout horizontal center stackable extra padded>
            <Layout.Group className={this.getElementClass('slide-image', mod)}>
              <ExternalLink
                href="https://www.bigsnowamericandream.com/slope-access/"
                className={this.getElementClass('bubble')}>
                <ResponsiveImage
                  className={this.getElementClass(
                    'bubble-image',
                    hovering ? 'hover' : null
                  )}
                  contentfulName="Big Snow - Bubble - 2"
                  square
                />
              </ExternalLink>
            </Layout.Group>
            <Layout.Group className={this.getElementClass('slide-text', mod)}>
              <Headline title="Slope Access" subtitle="Tickets" />
              <Spacer size="s" />
              <p className={this.getElementClass('dark-text')}>
                If you don't need rentals and already have the skills and
                confidence to take on our slopes without assistance, then a
                Slope Access Ticket is all you'll need.
              </p>
              <Spacer size="s" />
              <Button
                as={ExternalLink}
                href="https://www.bigsnowamericandream.com/slope-access/"
                {...this.getHoverProps(2)}>
                Find Out More
              </Button>
            </Layout.Group>
          </Layout>
        </ScrollWaypoint>
      </div>
    );
  }

  renderPrograms() {
    const { programsVisible } = this.state;
    const mod = this.getSlideModifier(programsVisible);
    return (
      <ScrollWaypoint
        onEnter={() => this.setState({ programsVisible: true })}
        onLeave={() => this.setState({ programsVisible: false })}>
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column className={this.getElementClass('program-column')}>
              <VerticalCard
                href="https://www.bigsnowamericandream.com/childrens-programs/"
                title="Kid's Programs"
                className={this.getElementClass('program', mod)}
                body="Camp BIG children's skiing and snowboarding programs for ages 5 - 12"
                image={ResponsiveImage.getContentfulBaseUrl(
                  'Big Snow - Program - 1'
                )}
                hover
              />
            </Grid.Column>
            <Grid.Column className={this.getElementClass('program-column')}>
              <VerticalCard
                href="https://www.bigsnowamericandream.com/beginners-guide/"
                title="Beginner Programs"
                className={this.getElementClass('program', mod)}
                body="New to skiing and snowboarding? Here's the place to start"
                image={ResponsiveImage.getContentfulBaseUrl(
                  'Big Snow - Program - 2'
                )}
                hover
              />
            </Grid.Column>
            <Grid.Column className={this.getElementClass('program-column')}>
              <VerticalCard
                href="https://www.bigsnowamericandream.com/private-lessons/"
                title="Lesson Programs"
                className={this.getElementClass('program', mod)}
                body="Get the most out of your time on snow with lesson programs for all ages and abilities"
                image={ResponsiveImage.getContentfulBaseUrl(
                  'Big Snow - Program - 3'
                )}
                hover
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ScrollWaypoint>
    );
  }
}
