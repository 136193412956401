import React from 'react';
import { Container, Grid, Card, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { ResponsiveImage, ExternalLink } from 'common/components';
import { formatDate } from 'utils/l10n';
import { Screen, awaitEntries } from 'public/helpers';
import { Spacer } from 'public/components';
import PressNav from './PressNav';

import './press.less';

@awaitEntries('press')
export default class Press extends Screen {
  META_TITLE = 'META_PRESS_TITLE';

  static renderLoading() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <Loader inline="centered" active />
        <Spacer size="m" />
      </React.Fragment>
    );
  }

  constructor(props) {
    super(props);
    this.press = props.press.sort((a, b) => {
      if (a.weight != b.weight) {
        return b.weight - a.weight;
      } else {
        return b.date - a.date;
      }
    });
  }

  renderBody() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <div {...this.getAttrs()}>
          <Container>
            <Spacer size="s" />
            <PressNav />
            <Spacer size="s" />
            <Grid columns={3} stackable>
              {this.renderItems()}
            </Grid>
          </Container>
        </div>
        <Spacer size="l" />
      </React.Fragment>
    );
  }

  renderItems() {
    return this.press.map((item, i) => {
      return (
        <Grid.Column key={i}>
          <Card as={ExternalLink} href={item.link} fluid>
            <ResponsiveImage
              src={item.image.url}
              ratio={1.32}
              type="fill"
              fluid
            />
            <Card.Content>
              <Card.Meta className={this.getElementClass('source')}>
                {item.source}
              </Card.Meta>
              <Card.Meta className={this.getElementClass('date')}>
                {formatDate(item.date)}
              </Card.Meta>
              <Card.Description>{item.title}</Card.Description>
            </Card.Content>
            <Card.Content extra>Read More</Card.Content>
          </Card>
        </Grid.Column>
      );
    });
  }
}

Press.propTypes = {
  contentfulSlugItem: PropTypes.string,
};

Press.defaultProps = {
  contentfulSlugItem: 'press',
};
