import { useCallback, useEffect } from 'react';
import {
  useClassName,
  useDOMElement,
  useScrollDirection,
  useScrollY,
} from 'common/hooks';

import './chat-icon.less';

function getNavbarHeight() {
  return [
    '.navigation-header__menu-container',
    '.navigation-header__banner-container',
  ].reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: document?.querySelector(cur)?.getBoundingClientRect()?.height || 0,
    }),
    {}
  );
}

export default function ChatIcon() {
  const classNames = useClassName('ChatIcon');
  const [elem] = useDOMElement('.BeaconFabButtonFrame', true);
  const scrollDirection = useScrollDirection();
  const { scrollY } = useScrollY();

  const getButtonPosition = useCallback(() => {
    const offset = 12;
    if (scrollDirection === 'down') {
      return {
        opacity: 0,
        '--icon-offset': '0px',
      };
    }

    const {
      ['.navigation-header__menu-container']: menuHeight,
      ['.navigation-header__banner-container']: bannerHeight,
    } = getNavbarHeight();

    if (!bannerHeight) {
      return {
        opacity: 1,
        '--icon-offset': `${menuHeight + offset}px`,
      };
    }

    if (scrollY < bannerHeight) {
      return {
        opacity: 1,
        '--icon-offset': `${menuHeight + bannerHeight + offset}px`,
      };
    }

    return {
      opacity: 1,
      '--icon-offset': `${menuHeight + offset}px`,
    };
  }, [scrollDirection, scrollY]);

  useEffect(() => {
    if (elem) {
      elem.classList.add(classNames('container'));
    }
  }, [elem]);

  useEffect(() => {
    if (!elem) return;

    const position = getButtonPosition();
    Object.entries(position).forEach(([key, value]) => {
      elem.style.setProperty(key, value, 'important');
    });
  }, [elem, getButtonPosition]);

  return null;
}
