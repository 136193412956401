import React from 'react';
import { Screen } from 'public/helpers';
import { Container } from 'semantic-ui-react';
import { Layout } from 'common/components';
import { SetPasswordForm, Spacer } from 'public/components';
import { withRouter } from 'react-router-dom';

@withRouter
export default class SetPassword extends Screen {
  constructor(props) {
    super(props);
  }

  onLoginSuccess = () => {
    this.props.history.push('/profile');
  };

  renderBody() {
    return (
      <div {...this.getAttrs()}>
        <Spacer size="m" />
        <Container className="white">
          <Layout vertical center>
            <Spacer size="m" />
            <SetPasswordForm onSuccess={this.onLoginSuccess} />
            <Spacer size="m" />
          </Layout>
        </Container>
        <Spacer size="m" />
      </div>
    );
  }
}
