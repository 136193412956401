import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { useClassName } from 'common/hooks';
import { ContentfulRichText, ContentLink } from 'common/components';
import { AssetPropType } from 'public/schema';
import { SVGIcon } from 'common/components';

import { LoaderImage } from '../../Loader';

import './info-card.less';

const InfoCard = ({ card, style, onClick }) => {
  const className = useClassName('InfoCard');
  const { title, description, theme, image, cta, slug } = card;

  const themeFontColor = theme === 'light' ? '#FFFFFF' : '#000000';
  const defaultLinkColor = cta?.ctaColor || themeFontColor;

  const [linkColor, setLinkColor] = useState(defaultLinkColor);
  const [redirect, setRedirect] = useState(null);

  const onLoadMoreMouseEnter = () => {
    setLinkColor(themeFontColor);
  };

  const onLoadMoreMouseLeave = () => {
    setLinkColor(defaultLinkColor);
  };

  const content = (
    <>
      {image && (
        <LoaderImage
          className={className('image')}
          src={image.assetUrl}
          alt={image.assetAlt}
          loadingHeight={260}
          fluid
          caption={
            Boolean(card?.schedule?.message) && (
              <>
                <SVGIcon name="warning" size="tiny" />
                <span>{card.schedule.message}</span>
              </>
            )
          }
        />
      )}
      {title && (
        <ContentfulRichText
          className={className(`title ${theme}`)}
          field={title}
        />
      )}
      {description && (
        <ContentfulRichText
          className={className(`description ${theme}`)}
          field={description}
        />
      )}
      {cta?.ctaLink && (
        <div className={className('cta')}>
          <ContentLink
            href={cta.ctaLink}
            className={className('cta-text')}
            style={{ color: linkColor }}
            onClick={onClick}
            onMouseEnter={() => onLoadMoreMouseEnter()}
            onMouseLeave={() => onLoadMoreMouseLeave()}>
            {cta.ctaText}
          </ContentLink>
        </div>
      )}
    </>
  );

  if (redirect) {
    const pathname = redirect.startsWith('/')
      ? `${window.location.pathname.replace(/\/$/, '')}`
      : window.location.pathname;

    return <Redirect push to={pathname + redirect} />;
  }

  if (slug) {
    return (
      <div
        className={className('container link')}
        style={style}
        onClick={() => setRedirect(`/attraction/${slug}`)}>
        {content}
      </div>
    );
  } else {
    return (
      <div className={className('container')} style={style}>
        {content}
      </div>
    );
  }
};

InfoCard.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.object,
    description: PropTypes.object,
    theme: PropTypes.string,
    category: PropTypes.string,
    image: PropTypes.shape(AssetPropType),
    cta: PropTypes.shape({
      ctaLink: PropTypes.string,
      ctaText: PropTypes.string,
      ctaColor: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func,
};

export default InfoCard;
