import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import { Loader, Container, Button, Form } from 'semantic-ui-react';
import { sum } from 'lodash';
import {
  Layout,
  ContentfulRichText,
  DesktopOnly,
  MobileOnly,
  FormActions,
} from 'common/components';
import { Screen } from 'public/helpers';
import { parseApiDate } from 'utils/api';
import {
  AddOnsSelector,
  ErrorMessage,
  Spacer,
  Totals,
} from 'public/components';
import {
  getQuantity,
  getItemPrice,
  getItemTax,
  ticketSalesClosed,
  trackProductViewed,
} from 'common/helpers';

import './addons.less';

const DEFAULT_TITLE =
  'Make your stay even better by adding on one of these experiences';
const DEFAULT_TOP_BUTTON_CONTENT = 'Continue Browsing';

function getDefaultState(props) {
  const { slug } = (props.match && props.match.params) || {};
  return {
    slug,
    loading: true,
    redirect: null,
    bundle: null,
    addons: [],
    sessions: null,
    cartItems: null,
    quantities: {},
  };
}

@inject('checkout', 'venues', 'cart', 'ticketInventory', 'bundles')
@observer
export default class BundleAddons extends Screen {
  state = getDefaultState(this.props);

  routeDidUpdate() {
    this.fetchTickets();
  }

  async fetchTickets() {
    const cartItems = await this.props.cart.getItems();
    const addons = this.props.checkout.checkout.addons;
    const { slug } = this.state;
    const bundle = await this.props.bundles.fetchItemBySlug(slug);
    const quantities = await this.getInitialQuantities(cartItems);
    const sessions = [];

    await this.validateTicketsFromCart(slug, cartItems, addons);

    for (const addon of addons) {
      if (!this.props.venues.get(addon.venueId)) {
        await this.props.venues.fetchItem(
          addon.venueId,
          parseApiDate(addon.reservationDate)
        );
      }
      if (
        !this.props.ticketInventory.get(addon.venueId, addon.reservationDate)
      ) {
        await this.props.ticketInventory.search({
          venueId: addon.venueId,
          date: addon.reservationDate,
          slug: this.props.venues.get(addon.venueId).slug,
        });
      }

      trackProductViewed(addon, this.props.venues.get(addon.venueId));

      sessions.push(
        ...this.props.ticketInventory.getSessions(
          addon.venueId,
          addon.reservationDate
        )
      );
    }

    this.setState({
      loading: false,
      bundle,
      addons,
      quantities,
      sessions,
      cartItems,
    });
  }

  onSubmit = (addons) => {
    let tickets = addons.map((item) => {
      const {
        ticketOptionId,
        bookingItemId,
        externalId,
        name,
        date,
        price,
        reservationDate,
        venueId,
        parentBundleSlug,
        parentBundleCartId,
        cost,
        externalTicket,
        externalVenue,
      } = item;
      return {
        name,
        date,
        price,
        venueId: venueId,
        venueName: this.props.venues.get(venueId).name,
        externalId,
        bookingItemId,
        ticketOptionId,
        reservationDate,
        parentBundleSlug,
        parentBundleCartId,
        cost,
        quantity: this.getQuantity(item),
        externalTicket,
        externalVenue,
      };
    });
    tickets = tickets.filter((t) => t.quantity > 0);
    tickets.forEach((ticket) => this.props.cart.addTicket(ticket));
    this.setRedirect('/cart');
  };

  setRedirect = (path) => {
    this.setState({
      redirect: path,
    });
  };

  renderAddOns(venue, addons) {
    if (venue?.openingHours?.temporarilyClosed) {
      return (
        <Layout.Group key={`${venue.name}-closed`}>
          <div className={this.getElementClass('closed-message')}>
            <ContentfulRichText field={venue.content.closedMessage} />
          </div>
        </Layout.Group>
      );
    }

    return (
      <div key={`${venue.name}-addons`}>
        <div className={this.getElementClass('venue-title')}>
          For {venue.name}
        </div>
        <AddOnsSelector addons={addons} setQuantity={this.setQuantity} />
        <Spacer size="s" />
      </div>
    );
  }

  renderBody() {
    const { bundle } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    if (this.state.loading) {
      return (
        <>
          <Spacer size="xl" />
          <Loader inline="centered" active />
          <Spacer size="xl" />
        </>
      );
    }

    return (
      <div {...this.getAttrs()}>
        <Container>
          <div className={this.getElementClass('selector')}>
            <Layout
              className={this.getElementClass('header')}
              horizontal
              center
              spread
              padded>
              <DesktopOnly>
                <Layout.Group>
                  <div className={this.getElementClass('addons-title')}>
                    {bundle.addonsTitle || DEFAULT_TITLE}
                  </div>
                </Layout.Group>
              </DesktopOnly>
              <DesktopOnly>
                <Layout.Group>
                  <Button as={Link} to={'/'} size="small">
                    {DEFAULT_TOP_BUTTON_CONTENT}
                  </Button>
                </Layout.Group>
              </DesktopOnly>

              <MobileOnly>
                <Layout.Group>
                  <div className={this.getElementClass('addons-title')}>
                    {bundle.addonsTitle || DEFAULT_TITLE}
                  </div>
                </Layout.Group>
              </MobileOnly>
            </Layout>

            <Layout horizontal={false} center spread>
              {this.renderSelector()}
            </Layout>
          </div>
        </Container>
        <Spacer size="l" />
      </div>
    );
  }

  renderActions = () => {
    return (
      <Button primary fluid>
        Continue to basket
      </Button>
    );
  };

  setQuantity = (item, quantity) => {
    const { quantities } = this.state;
    quantities[item.ticketOptionId] = quantity;
    const canSubmit = this.validateZeroItems(quantities);

    this.setState({
      quantities,
      canSubmit,
    });
  };

  async validateTicketsFromCart(bundleSlug, cartItems, addons) {
    if (!cartItems?.length) {
      this.setRedirect(`/bundle/${bundleSlug}`);
      return;
    }

    addons.forEach((addon) => {
      const validCartItems = cartItems.filter(
        (cartItem) =>
          cartItem.bundleSlug === bundleSlug &&
          cartItem.reservationDate === addon.reservationDate
      );
      if (!validCartItems?.length) {
        this.setRedirect(`/bundle/${bundleSlug}`);
        return;
      }
    });
  }

  async getInitialQuantities(cartItems) {
    let quantities = {};

    if (!cartItems?.length) return quantities;

    cartItems.forEach((cartItem) => {
      const { tickets } = cartItem;

      if (!tickets?.length) return;

      tickets.forEach((ticket) => {
        const { ticketOptionId } = ticket;
        quantities[ticketOptionId] = 0;
      });
    });
    return quantities;
  }

  validateZeroItems(quantities) {
    const { addons } = this.state;
    if (!addons?.length || !quantities) return false;
    return addons.some((addon) => quantities[addon.ticketOptionId] > 0);
  }

  validate() {
    const { addons, quantities } = this.state;
    let canSubmit = false;

    for (const addon of addons) {
      if (
        ticketSalesClosed(
          this.props.venues.get(addon.venueId),
          parseApiDate(addon.reservationDate)
        )
      ) {
        return {
          canSubmit,
          errorMessage: 'Please select a date in the future',
          errorTitle: 'Same-day ticket sales are now closed.',
        };
      }
    }
    canSubmit = this.validateZeroItems(quantities);
    return { canSubmit };
  }

  getTicketCount(addons) {
    return sum(addons.map((item) => this.getQuantity(item)));
  }

  getSubtotal(addons) {
    const { quantities } = this.state;
    return sum(
      addons.map((item) => {
        const quantity = this.getQuantity(item);
        return getItemPrice(item, addons, quantities) * quantity;
      })
    );
  }

  getTax(addons) {
    const { quantities } = this.state;
    return sum(
      addons.map((item) => {
        const quantity = this.getQuantity(item);
        return getItemTax(item, addons, quantities) * quantity;
      })
    );
  }

  getQuantity(item) {
    const quantity = getQuantity(item, this.state.quantities);
    return quantity;
  }

  renderTotals(addons) {
    return (
      <div className={this.getElementClass('totals-container')}>
        <div className={this.getElementClass('totals-title')}>Summary</div>
        <Totals
          count={this.getTicketCount(addons)}
          subtotal={this.getSubtotal(addons)}
          subtotalLabel="Items subtotal"
          tax={this.getTax(addons)}
        />
      </div>
    );
  }

  renderError(errorMessage, errorTitle) {
    if (errorMessage) {
      return (
        <>
          <Spacer size="s" />
          <ErrorMessage header={errorTitle} content={errorMessage} />
        </>
      );
    }
  }

  renderSelector() {
    const { bundle, addons } = this.state;
    const { errorMessage, errorTitle } = this.validate();

    if (this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="l" />
          <Loader size="large" inline="centered" active />
          <Spacer size="l" />
        </React.Fragment>
      );
    }

    return (
      <div>
        <Form onSubmit={() => this.onSubmit(addons)}>
          <Layout horizontal stackable>
            <Layout.Group className={this.getElementClass('addons')} grow>
              <Spacer size="s" />
              <div>
                {bundle.rollerProducts.map((product) => {
                  const venue = this.props.venues.get(product.venueId);
                  const addons = this.props.checkout.checkout.addons.filter(
                    (addon) => product.addons.includes(addon.bookingItemId)
                  );
                  if (addons?.length) return this.renderAddOns(venue, addons);
                })}
                <MobileOnly>
                  {this.renderTotals(addons)}
                  {this.renderError(errorMessage, errorTitle)}
                  <FormActions>{this.renderActions()}</FormActions>
                  <Spacer size="xs" />
                </MobileOnly>
              </div>
            </Layout.Group>
            <Layout.Group className={this.getElementClass('totals')}>
              <DesktopOnly>
                {this.renderTotals(addons)}
                {this.renderError(errorMessage, errorTitle)}
                <FormActions>{this.renderActions()}</FormActions>
                <Spacer size="xs" />
              </DesktopOnly>
            </Layout.Group>
          </Layout>
        </Form>
      </div>
    );
  }
}
