import React from 'react';
import { Component } from 'common/helpers';
import { Layout, Select, SVGIcon as Icon } from 'common/components';

import './card-on-file.less';

export default class CardOnFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  onEditClick = () => {
    this.setState({
      edit: true,
    });
  };

  onChange = (evt) => {
    const index = evt.target.selectedIndex;
    const card = this.props.paymentMethods[index];
    this.props.onCardSelected(card);
    this.setState({
      edit: false,
    });
  };

  render() {
    if (this.state.edit) {
      return this.renderEdit();
    } else {
      return this.renderConfirm();
    }
  }

  renderEdit() {
    const options = this.props.paymentMethods.map(({ card }, i) => {
      const { last4, brand } = card;
      const exp = this.getExpiration(card);
      return (
        <option key={i}>
          {brand} ending in {last4} (Expires: {exp})
        </option>
      );
    });
    return <Select onChange={this.onChange}>{options}</Select>;
  }

  renderConfirm() {
    // Assuming all payment methods are cards here.
    const { card } = this.props.currentMethod;
    return (
      <Layout {...this.getAttrs()} horizontal spread>
        <Layout.Group>
          <Icon size="large" name={`card-${card.brand}`} />
          <span className={this.getElementClass('hidden')}>****</span>
          <span className={this.getElementClass('details')}>{card.last4}</span>
        </Layout.Group>
        <Layout.Group>
          <a onClick={this.onEditClick}>Edit</a>
        </Layout.Group>
      </Layout>
    );
  }

  getExpiration(card) {
    return `${card.exp_month}/${card.exp_year.toString().slice(2)}`;
  }
}
