import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Container, Button, Input } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import {
  Badge,
  Layout,
  IntegerInput,
  SVGIcon as Icon,
} from 'common/components';
import { Component } from 'common/helpers';
import { ErrorMessage, Modal, Totals, Spacer, Alert } from 'public/components';
import { formatDate, formatCurrency } from 'utils/l10n';
import { parseApiDate } from 'utils/api';
import { setCookie, getCookie, deleteCookie } from 'utils/helpers/cookie';
import {
  hasValidQuantity,
  validateInventory,
  getItemPrice,
  getItemName,
  trackCartViewed,
  trackCheckoutStepViewed,
  trackCheckoutStepCompleted,
} from 'common/helpers';

import CartStep from './helpers/CartStep';
import {
  isDateValid,
  isDayValid,
  isProductIdValid,
  validateDiscountMax,
  formatRollerDate,
  getDiscount,
  getValidDaysString,
  keys,
  getProductsQuantities,
} from './helpers/discounts';

import './view.less';
import {
  getDiscountValues,
  isTwoForOneDiscount,
} from './helpers/twoForOneCodes';

const errors = {
  INVALID_DATE: 'This discount code is valid only for bookings between',
  INVALID_DAY: 'This discount code is valid only for bookings on',
  INVALID_PRODUCTS:
    "This discount code isn't valid for any of the tickets in your cart.",
};

const bookingRulesTypes = {
  ACROSS_PRODUCTS: 1,
};

const limitTypes = {
  PER_CODE: 0,
  ACROSS_PRODUCTS: 3,
};

@CartStep
@inject('appSession', 'externalbookings')
export default class CartView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeIntent: null,
      discountCode: '',
      discountValue: null,
      isDiscountApplied: false,
      invalidDiscountMessage: '',
      unavailableQuantityMessage: '',
      alertMessage: null,
    };
  }

  componentDidMount() {
    this.applyDiscount();
    //Track cart only when cart items are fully loaded
    const curStateEmptyVenue = !this.props.cartItems.every((cartItem) =>
      Boolean(cartItem.venue)
    );
    if (!curStateEmptyVenue) {
      trackCartViewed(this.props.cartItems);
      trackCheckoutStepViewed(1);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartItems !== this.props.cartItems) {
      this.applyDiscount();
      //Track cart only when cart items are fully loaded, only if it was not tracked while component did mount
      const prevStateEmptyVenue = !prevProps.cartItems.every((cartItem) =>
        Boolean(cartItem.venue)
      );
      const curStateEmptyVenue = !this.props.cartItems.every((cartItem) =>
        Boolean(cartItem.venue)
      );
      if (prevStateEmptyVenue && !curStateEmptyVenue) {
        trackCartViewed(this.props.cartItems);
        trackCheckoutStepViewed(1);
      }
    }
  }

  onCheckoutClick = () => {
    trackCheckoutStepCompleted(1);
  };

  hasValidQuantity(inventoryItem, inventory, quantities) {
    return hasValidQuantity(inventoryItem, inventory, quantities);
  }

  bulkHasValidQuantity(bundle) {
    const validation = [];
    bundle.items.forEach((item) => {
      item.tickets.forEach((ticket) => {
        validation.push(
          this.hasValidQuantity(
            ticket,
            item.inventory,
            this.getValidationQuantity(item)
          )
        );
      });
    });
    return !validation.every((result) => result);
  }

  isSoldOut(inventoryItem) {
    return inventoryItem.quantity === 0;
  }

  bulkIsSoldOut(bundle) {
    const soldOut = {};
    bundle.items.forEach((item) => {
      item.tickets.forEach((ticket) => {
        if (this.isSoldOut(ticket)) {
          soldOut.soldOut = true;
          soldOut.ticket = ticket;
        }
      });
    });
    return soldOut;
  }

  getSoldOutLabel(inventoryItem) {
    return parseApiDate(inventoryItem.date) < new Date()
      ? 'No Longer Available'
      : 'Sold Out';
  }

  setQuantity(cartItem, inventoryItem, quantity) {
    const { reservationDate, startTime } = cartItem;
    const { ticketOptionId } = inventoryItem;

    this.props.cart.updateTicket(
      {
        ticketOptionId,
        reservationDate,
        startTime,
      },
      {
        ...cartItem,
        quantity,
      }
    );
  }

  setBundleQuantity(bundleItems, quantity) {
    const props = [];
    const tickets = bundleItems.items.map((item) => {
      const { reservationDate, inventoryItem, bundleCartId, bundleSlug } = item;
      const { ticketOptionId } = inventoryItem;
      props.push({ ...item, quantity });
      return {
        ticketOptionId,
        reservationDate,
        bundleCartId,
        bundleSlug,
      };
    });
    this.props.cart.updateTicket(tickets, props);
  }

  getValidationQuantity(item) {
    const { startTime, quantities } = item;
    return Object.keys(item.quantities).reduce((reducer, productId) => {
      const quantity = quantities[productId];
      return {
        ...reducer,
        [productId]: startTime ? { startTime, quantity } : quantity,
      };
    }, {});
  }

  validate() {
    const { externalbookings } = this.props;
    for (const ci of this.props.cartItems) {
      const validation = validateInventory(
        ci.inventory,
        this.getValidationQuantity(ci),
        ci.venue,
        externalbookings.get(ci.venue.slug, ci.reservationDate)
      );
      if (!validation.canSubmit) {
        return validation;
      }
    }

    return {
      canSubmit: true,
    };
  }

  onDiscountCodeChange(value) {
    if (!value) {
      this.removeDiscount();
    } else {
      this.setState({ discountCode: value.trim().toUpperCase() });
    }
  }

  async deleteDiscount() {
    // check usage to delete discount if still exists
    const discountUsageFromCookie = getCookie(keys.DISCOUNT_USAGE_KEY);
    let discountUsage;
    if (discountUsageFromCookie)
      discountUsage = JSON.parse(discountUsageFromCookie);

    // delete all discount related cookies
    deleteCookie(keys.DISCOUNT_CODE_KEY);
    deleteCookie(keys.DISCOUNT_USAGE_KEY);
    deleteCookie(keys.DISCOUNT_VALUE_KEY);
    localStorage.removeItem(keys.DISCOUNT_USAGE_KEY);

    if (discountUsage?.id) {
      try {
        await this.props.discounts.delete(discountUsage.id);
      } catch (error) {
        console.error(error);
      }
    }
  }

  async removeDiscount() {
    await this.deleteDiscount();

    this.setState({
      isDiscountApplied: false,
      discountCode: '',
      discountValue: null,
      invalidDiscountMessage: '',
    });
  }

  getMaxDiscountProducts = (discountUsage) => {
    const { usageLimits, bookingRule } = discountUsage;

    if (
      usageLimits.type !== limitTypes.PER_CODE &&
      usageLimits.type !== limitTypes.ACROSS_PRODUCTS
    )
      return { checkProductQty: false };

    if (
      usageLimits.type === limitTypes.PER_CODE &&
      bookingRule?.type !== bookingRulesTypes.ACROSS_PRODUCTS
    )
      return { checkProductQty: false };

    const checkProductQty = true;
    const maxDiscountProducts =
      usageLimits.type === limitTypes.PER_CODE
        ? bookingRule.numberOfUses
        : usageLimits.limit - usageLimits.numberOfUses;

    return { checkProductQty, maxDiscountProducts };
  };

  getDiscount = (discountUsage, productIds) => {
    const { cartItems } = this.props;

    const {
      maxApplicableAmount,
      bookingDateRestrictions: { dateRange },
      percentOff,
      amountOff,
      reportingCategoryName,
    } = discountUsage;

    const { checkProductQty, maxDiscountProducts } =
      this.getMaxDiscountProducts(discountUsage);

    let discountValue = 0;
    let totalPrice = 0;
    let isValidDate = true;
    let isValidDay = true;
    let needsMoreItems = false;
    let invalidDaysCounter = 0;
    let invalidDateCounter = 0;
    let invalidProductsCounter = 0;
    let ticketCounter = 0;
    let discountProductCounter = 0;

    // mapper
    for (const cartItem of cartItems) {
      if (checkProductQty && discountProductCounter >= maxDiscountProducts)
        break;

      for (const inventoryItem of cartItem.tickets) {
        if (checkProductQty && discountProductCounter >= maxDiscountProducts)
          break;

        ticketCounter += 1;
        const { reservationDate, inventory, quantities } = cartItem;
        const { ticketOptionId } = inventoryItem;
        const itemReportingCategory = inventoryItem.reportingCategoryName;
        const quantity = quantities[ticketOptionId];
        const price = getItemPrice(inventoryItem, inventory, quantities);

        totalPrice = totalPrice + price * quantity;

        if (!isDateValid(dateRange, reservationDate)) {
          invalidDateCounter += 1;
          continue;
        }

        if (!isDayValid(dateRange, reservationDate)) {
          invalidDaysCounter += 1;
          continue;
        }

        if (isTwoForOneDiscount({ percentOff, amountOff, productIds })) {
          if (itemReportingCategory !== reportingCategoryName) {
            invalidProductsCounter += 1;
            continue;
          }
        } else if (!isProductIdValid(productIds, ticketOptionId)) {
          invalidProductsCounter += 1;
          continue;
        }

        if (percentOff) {
          const discountQuantity = !checkProductQty
            ? quantity
            : discountProductCounter + quantity <= maxDiscountProducts
            ? quantity
            : maxDiscountProducts - discountProductCounter;

          discountProductCounter += discountQuantity;

          const ticketDiscount = getDiscount(
            price,
            percentOff,
            discountQuantity
          );
          discountValue = discountValue + ticketDiscount;
        } else if (amountOff) {
          discountValue = 100 * amountOff;
        } else if (productIds.length === 1) {
          const { quantity, discount, maximumDiscountReached } =
            getDiscountValues(cartItems, discountUsage, reportingCategoryName);

          if (quantity <= 1) needsMoreItems = true;
          this.setState({ maximumDiscountReached });

          discountValue = discount;
        }
      }
    }

    if (checkProductQty && discountProductCounter >= maxDiscountProducts) {
      this.setState({ maximumDiscountReached: true });
    } else if (
      checkProductQty &&
      discountProductCounter < maxDiscountProducts
    ) {
      this.setState({ maximumDiscountReached: false });
    }

    discountValue = validateDiscountMax(discountValue, maxApplicableAmount);

    if (discountValue >= totalPrice) discountValue = totalPrice;

    isValidDate = invalidDateCounter !== ticketCounter;
    isValidDay = invalidDaysCounter !== ticketCounter;
    const hasValidProducts = invalidProductsCounter !== ticketCounter;

    return {
      discountValue,
      dateRange,
      isValidDate,
      isValidDay,
      hasValidProducts,
      needsMoreItems,
    };
  };

  updateDiscountUsage = async (discountCode) => {
    const { cartItems } = this.props;
    const productQuantities = getProductsQuantities(cartItems);

    await this.deleteDiscount();
    this.setState({ maximumDiscountReached: false });
    const discountUsage = await this.props.discounts.redeem(
      discountCode,
      productQuantities
    );
    discountUsage.userLoggedIn = this.props.appSession.isLoggedIn();
    // Remove productIds from discountUsage and store them in localStorage
    const { productIds = [], ...rest } = discountUsage;
    const discountUsageProductIdsString = JSON.stringify(productIds);
    const discountUsageString = JSON.stringify(rest);

    // Store discount product ids in localStorage
    localStorage.setItem(
      keys.DISCOUNT_USAGE_KEY,
      discountUsageProductIdsString
    );
    // Store discount usage without productIds in cookie
    setCookie(keys.DISCOUNT_USAGE_KEY, discountUsageString, 15);
    setCookie(keys.DISCOUNT_CODE_KEY, discountCode, 15);

    return {
      discountCode,
      discountUsage,
    };
  };

  applyDiscount = async () => {
    try {
      let discountUsage;
      let discountCode;

      const { discountCode: discountCodeFromState } = this.state;
      const discountCodeFromCookie = getCookie(keys.DISCOUNT_CODE_KEY);
      const discountUsageFromCookie = getCookie(keys.DISCOUNT_USAGE_KEY);

      // new typed discount
      if (
        discountCodeFromState &&
        discountCodeFromState !== discountCodeFromCookie
      ) {
        // is a new discount code typed
        const data = await this.updateDiscountUsage(discountCodeFromState);
        discountCode = data.discountCode;
        discountUsage = data.discountUsage;
      } else if (discountCodeFromCookie && discountUsageFromCookie) {
        // discount coming from cookie
        discountCode = discountCodeFromCookie;
        discountUsage = JSON.parse(discountUsageFromCookie);
      }
      // Refresh discount usage
      if (
        discountUsage &&
        discountUsage.token !== localStorage.getItem('jwt')
      ) {
        const data = await this.updateDiscountUsage(discountCodeFromCookie);
        discountCode = data.discountCode;
        discountUsage = data.discountUsage;
      }

      // get discount usage product ids from local storage
      const discountUsageProductsIdsLocalStorage = localStorage.getItem(
        keys.DISCOUNT_USAGE_KEY
      );
      const discountUsageProductIds =
        discountUsageProductsIdsLocalStorage &&
        JSON.parse(discountUsageProductsIdsLocalStorage);

      if (discountUsage) {
        // Check if discount is valid before checking if applies to items
        if (!discountUsage.valid) {
          this.setState({
            invalidDiscountMessage: discountUsage.errorMessage,
            discountCode,
          });
        } else {
          setCookie(keys.DISCOUNT_CODE_KEY, discountCode, 15);

          const {
            discountValue,
            dateRange,
            isValidDate,
            isValidDay,
            hasValidProducts,
            needsMoreItems,
          } = this.getDiscount(discountUsage, discountUsageProductIds);

          let discountToApply = 0;
          let invalidDiscountMessage = '';
          let isDiscountApplied;

          if (!isValidDate) {
            const { startDate, endDate } = dateRange;

            let start = formatRollerDate(startDate);
            let end = formatRollerDate(endDate);

            start = new Date(start).toLocaleDateString();
            end = new Date(end).toLocaleDateString();

            invalidDiscountMessage = `${errors.INVALID_DATE} ${start} and ${end}.`;
            isDiscountApplied = false;
          } else if (!isValidDay) {
            const daysString = getValidDaysString(dateRange);
            invalidDiscountMessage = `${errors.INVALID_DAY} ${daysString.join(
              ', '
            )}.`;
            isDiscountApplied = false;
          } else if (!hasValidProducts) {
            invalidDiscountMessage = errors.INVALID_PRODUCTS;
            isDiscountApplied = false;
          } else if (needsMoreItems) {
            invalidDiscountMessage = this.getTwoForOneInvalidMessage(
              discountUsage.reportingCategoryName
            );
            isDiscountApplied = false;
          } else {
            if (discountValue > 0) {
              isDiscountApplied = true;
              discountToApply = discountValue;
              setCookie(keys.DISCOUNT_VALUE_KEY, discountValue, 15);
            } else {
              invalidDiscountMessage = errors.INVALID_PRODUCTS;
              isDiscountApplied = false;
              deleteCookie(keys.DISCOUNT_VALUE_KEY);
            }
          }

          this.setState({
            isDiscountApplied,
            discountCode,
            discountValue: discountToApply,
            invalidDiscountMessage,
          });
        }
      }
    } catch (error) {
      const invalidDiscountMessage = error.message;
      this.setState({ isDiscountApplied: false, invalidDiscountMessage });
    }
  };

  getTwoForOneInvalidMessage = (reportingCategoryName) => {
    const { cartItems } = this.props;
    const invalidItem = cartItems.find(
      (a) =>
        !a.bundleSlug &&
        a.tickets.some((b) => b.reportingCategoryName === reportingCategoryName)
    );
    const venueName = invalidItem.venue.name;

    return `Add more ${venueName} items to the card to apply this code.`;
  };

  validateCartSelection = (cartItem, inventoryItem) => {
    const { cartItems } = this.props;
    const venueCartItems = cartItems.find(
      (ci) =>
        ci.venueId === cartItem.venueId &&
        ci.reservationDate === cartItem.reservationDate &&
        ci.startTime === cartItem.startTime
    );
    const tickets = venueCartItems.tickets;
    const ticketsCount = tickets.filter(
      (ticket) =>
        !ticket.addon && ticket.ticketOptionId !== inventoryItem.ticketOptionId
    ).length;
    const addonsCount = tickets.filter((ticket) => ticket.addon).length;
    if (ticketsCount === 0 && addonsCount > 0) {
      throw "Add-ons can't be purchased without tickets. Please add at least one ticket to your basket.";
    }
  };

  validateBundleCartSelection = (bundleItem) => {
    const { cartItems } = this.props;
    const bundleItems = cartItems.filter(
      (item) => item.bundleCartId === bundleItem.bundleCartId
    );

    for (const bundleItem of bundleItems) {
      const sameDayItems = cartItems.filter(
        (cartItem) =>
          cartItem.reservationDate === bundleItem.reservationDate &&
          cartItem.bundleCartId !== bundleItem.bundleCartId
      );
      if (!sameDayItems?.length) continue;

      const addons = bundleItem.tickets.map((ticket) => ticket.addons).flat();
      if (
        sameDayItems
          .map((item) => item.tickets)
          .flat()
          .some((ticket) => addons.includes(ticket.bookingItemId))
      ) {
        throw "Add-ons can't be purchased without tickets. Please add at least one ticket to your basket.";
      }
    }
  };

  setBundleRemoveIntent = (bundleItem) => {
    try {
      this.validateBundleCartSelection(bundleItem);
      this.setState({
        removeIntent: {
          bundleItem,
        },
      });
    } catch (error) {
      this.setState({
        alertMessage: error,
      });
    }
  };

  setRemoveIntent = (cartItem, inventoryItem) => {
    try {
      this.validateCartSelection(cartItem, inventoryItem);
      this.setState({
        removeIntent: {
          cartItem,
          inventoryItem,
        },
      });
    } catch (error) {
      this.setState({
        alertMessage: error,
      });
    }
  };

  clearRemoveIntent = () => {
    this.setState({
      removeIntent: null,
    });
  };

  removeItem = (
    ticketOptionId,
    reservationDate,
    startTime,
    bundleSlug,
    bundleCartId,
    trackingProps
  ) => {
    this.props.cart.removeTicket(
      {
        ticketOptionId,
        reservationDate,
        startTime,
        bundleSlug,
        bundleCartId,
      },
      trackingProps
    );
  };

  confirmRemoveIntent = () => {
    const { cartItem, inventoryItem, bundleItem } = this.state.removeIntent;

    if (bundleItem) {
      bundleItem.items.forEach((item) => {
        const {
          reservationDate,
          startTime,
          inventoryItem,
          bundleSlug,
          bundleCartId,
        } = item;
        const { ticketOptionId } = inventoryItem;
        const trackingProps = {
          ...inventoryItem,
          venueId: item.venue.id,
        };

        this.removeItem(
          ticketOptionId,
          reservationDate,
          startTime,
          bundleSlug,
          bundleCartId,
          trackingProps
        );
      });
    } else {
      const { reservationDate, startTime } = cartItem;
      const { ticketOptionId } = inventoryItem;
      const trackingProps = {
        ...inventoryItem,
        venueId: cartItem.venue.id,
      };

      this.removeItem(
        ticketOptionId,
        reservationDate,
        startTime,
        null,
        null,
        trackingProps
      );
    }

    this.setState({
      removeIntent: null,
    });
  };

  confirmRemove = (cartItem) => {
    const name = cartItem.venue.name;
    const date = formatDate(cartItem.reservationDate);
    const message = `Remove all tickets for ${name} on ${date}?`;
    this.setState({
      removeIntent: {
        message,
        cartItem,
      },
    });
  };

  renderError(errorMessage, errorTitle) {
    if (errorMessage) {
      return (
        <React.Fragment>
          <Spacer size="s" />
          <ErrorMessage header={errorTitle} content={errorMessage} />
        </React.Fragment>
      );
    }
  }

  renderHeader() {
    return (
      <Table.Row>
        <Table.HeaderCell className={this.getElementClass('header-cell')}>
          Item Type
        </Table.HeaderCell>
        <Table.HeaderCell className={this.getElementClass('header-cell')}>
          Item Price
        </Table.HeaderCell>
        <Table.HeaderCell className={this.getElementClass('header-cell')}>
          Qty
        </Table.HeaderCell>
        <Table.HeaderCell
          colSpan={2}
          className={this.getElementClass('header-cell')}>
          Item Total
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  renderItems() {
    const { cartItems } = this.props;
    return (
      <Layout.Group className={this.getElementClass('tickets')} grow>
        <Table>
          <Table.Header>{this.renderHeader()}</Table.Header>
          <Table.Body className={this.getElementClass('table-body')}>
            {this.renderCartItems(cartItems)}
          </Table.Body>
        </Table>
        {this.renderDiscounts()}
      </Layout.Group>
    );
  }

  renderDiscounts() {
    const { isDiscountApplied, maximumDiscountReached } = this.state;

    if (isDiscountApplied) {
      return (
        <>
          <div className={this.getElementClass('discounts', 'applied')}>
            <div className={this.getElementClass('discounts-title')}>
              <Icon
                name="check"
                size="small"
                className={this.getElementClass('discounts-ok')}
              />
              <div>
                <p>Discount Code Applied</p>
                {maximumDiscountReached && (
                  <caption>Maximum discount reached</caption>
                )}
              </div>
            </div>
            <span
              className={this.getElementClass('discounts-remove')}
              onClick={() => this.removeDiscount()}>
              Remove
            </span>
          </div>
        </>
      );
    } else {
      return (
        <Layout.Group className={this.getElementClass('discounts')}>
          <div className={this.getElementClass('discounts-title')}>
            Add a discount code
          </div>
          <div className={this.getElementClass('discounts-wrapper')}>
            <Input
              className={this.getElementClass(
                'discounts-input',
                this.state.discountCode && 'typed'
              )}
              value={this.state.discountCode}
              onChange={(e, { value }) => this.onDiscountCodeChange(value)}
              name="discount"
              placeholder="Enter code here"
              type="text"
            />
            <Button
              className={this.getElementClass('discounts-button')}
              onClick={() => this.applyDiscount()}>
              Apply
            </Button>
          </div>
          {this.state.invalidDiscountMessage && (
            <div className={this.getElementClass('discounts-error')}>
              {this.state.invalidDiscountMessage}
            </div>
          )}
        </Layout.Group>
      );
    }
  }

  renderTotals() {
    const { canSubmit, errorMessage, errorTitle } = this.validate();
    const disabledBtn =
      !canSubmit || this.state.unavailableQuantityMessage !== '';
    return (
      <Layout.Group className={this.getElementClass('totals')}>
        <p className="large">Summary</p>
        <Spacer size="s" />
        <Totals
          count={this.props.count}
          subtotal={this.props.subtotal}
          tax={this.props.tax}
          discount={this.state.discountValue}
        />
        {this.props.promoMessages}
        {this.renderError(errorMessage, errorTitle)}
        {this.state.unavailableQuantityMessage &&
          this.renderError(this.state.unavailableQuantityMessage)}
        <Spacer size="s" />
        <Button
          disabled={disabledBtn}
          primary
          fluid
          as={Link}
          onClick={this.onCheckoutClick}
          to="/cart/checkout">
          {this.props.subtotal - this.state.discountValue > 0
            ? 'Continue to payment'
            : 'Continue'}
        </Button>
      </Layout.Group>
    );
  }

  groupBundles(cartItems) {
    return cartItems.reduce((reducer, item) => {
      if (item.bundleSlug) {
        const existing = reducer.find(
          (i) =>
            i.slug === item.bundleSlug &&
            (!i.items || i.items.length < i.rollerProducts?.length)
        );

        if (existing) {
          const newItems = item.tickets.map((inventoryItem) => {
            return { ...item, inventoryItem };
          });

          existing.items.push(...newItems);

          return reducer;
        } else {
          return reducer.concat({
            bundle: true,
            bundleCartId: item.bundleCartId,
            ...item.bundle,
            items: item.tickets.map((inventoryItem) => {
              return { ...item, inventoryItem };
            }),
          });
        }
      } else {
        return reducer.concat(item);
      }
    }, []);
  }

  renderCartItems(cartItems) {
    const bundleGroupedCartItems = this.groupBundles(cartItems);
    return bundleGroupedCartItems.map((cartItem) => {
      if (cartItem.bundle) {
        return this.renderBundleRow(cartItem);
      } else {
        return cartItem.tickets.map((inventoryItem) => {
          return this.renderRow(cartItem, inventoryItem);
        });
      }
    });
  }

  handleQuantityChange = (cartItem, inventoryItem, quantity) => {
    try {
      if (quantity === 0) {
        this.validateCartSelection(cartItem, inventoryItem);
      }
      this.setQuantity(cartItem, inventoryItem, quantity);
    } catch (errorMessage) {
      this.setState({
        alertMessage: errorMessage,
      });
    }
  };

  areThereCapacity = (bundle, quantity) => {
    const { bundleCartId } = bundle;
    const { inventory } = this.props.cartItems.find(
      (item) => item.bundleCartId === bundleCartId
    );
    const errors = inventory
      .map((product) => {
        if (product.capacityRemaining < quantity) {
          return product.name;
        }
      })
      .filter((_) => _);
    return errors;
  };

  validateAvailableQuatity = (bundleItem, quantity) =>
    this.areThereCapacity(bundleItem, quantity).length > 0;

  handleBundleQuantityChange = (cartItem, quantity) => {
    try {
      if (quantity === 0) {
        this.validateBundleCartSelection(cartItem);
      }
      const availabilityErrors = this.areThereCapacity(cartItem, quantity);
      this.setState({
        unavailableQuantityMessage: '',
      });
      if (availabilityErrors.length > 0) {
        const errorMessage = `There isn't availability for ${availabilityErrors.join(
          ' and '
        )} for this date and time`;
        this.setState({
          unavailableQuantityMessage: errorMessage,
        });
      }
      this.setBundleQuantity(cartItem, quantity);
    } catch (errorMessage) {
      this.setState({
        alertMessage: errorMessage,
      });
    }
  };

  renderRow(cartItem, inventoryItem) {
    const {
      venue,
      reservationDate,
      startTime,
      startTimeName,
      inventory,
      quantities,
    } = cartItem;
    if (!venue) {
      return null;
    }
    const { ticketOptionId } = inventoryItem;
    const quantity = quantities[ticketOptionId];
    const price = getItemPrice(inventoryItem, inventory, quantities);
    const name = getItemName(inventoryItem, inventory, quantities);
    const key = [venue.id, reservationDate, ticketOptionId].join('-');
    return (
      <Table.Row key={key} className={this.getElementClass('table-row')}>
        <Table.Cell className={this.getElementClass('text-cell')}>
          <p>{venue.name}</p>
          <p className={this.getElementClass('ticket-name')}>
            {name}
            {inventoryItem.addon && <Badge text="ADD-ON" />}
          </p>
          <p className="small caps">{formatDate(reservationDate)}</p>
          {startTime && (
            <p className="small caps">{startTimeName || startTime}</p>
          )}
        </Table.Cell>
        <Table.Cell
          className={`${this.getElementClass('text-cell')} desktop-only`}>
          {formatCurrency(price)}
        </Table.Cell>
        <Table.Cell className={`${this.getElementClass('text-cell')} quantity`}>
          {this.isSoldOut(inventoryItem) ? (
            <div className={this.getElementClass('sold-out')}>
              {this.getSoldOutLabel(inventoryItem)}
            </div>
          ) : (
            <IntegerInput
              error={
                !this.hasValidQuantity(
                  inventoryItem,
                  inventory,
                  this.getValidationQuantity(cartItem),
                  venue
                )
              }
              value={quantity}
              onChange={(quantity) =>
                this.handleQuantityChange(cartItem, inventoryItem, quantity)
              }
            />
          )}
        </Table.Cell>
        <Table.Cell className={`${this.getElementClass('text-cell')} price`}>
          {formatCurrency(price * quantity)}
        </Table.Cell>
        <Table.Cell className={`${this.getElementClass('text-cell')} remove`}>
          <span onClick={() => this.setRemoveIntent(cartItem, inventoryItem)}>
            Remove
            <Icon name="close" size="mini" />
          </span>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderBundleRow(bundleItem) {
    if (!bundleItem) {
      return null;
    }

    let quantity = 0;
    let price = 0;
    const soldOutItem = this.bulkIsSoldOut(bundleItem);
    return (
      <Table.Row
        key={bundleItem.bundleCartId}
        className={this.getElementClass('table-row')}>
        <Table.Cell className={this.getElementClass('text-cell')}>
          <p>{bundleItem.title}</p>
          {bundleItem.items.map((item) => {
            const {
              venue,
              reservationDate,
              quantities,
              inventoryItem,
              inventory,
            } = item;

            const { ticketOptionId } = inventoryItem;
            quantity = quantities[ticketOptionId];
            const itemPrice = getItemPrice(
              inventoryItem,
              inventory,
              quantities
            );
            price += itemPrice;

            return (
              <p key={`${venue.name}-${reservationDate}`} className="small">
                {venue.name}: {formatDate(reservationDate)}
              </p>
            );
          })}
        </Table.Cell>
        <Table.Cell
          className={`${this.getElementClass('text-cell')} desktop-only`}>
          {formatCurrency(price)}
        </Table.Cell>
        <Table.Cell className={`${this.getElementClass('text-cell')} quantity`}>
          {'soldOut' in soldOutItem ? (
            <div className={this.getElementClass('sold-out')}>
              {this.getSoldOutLabel(soldOutItem.ticket)}
            </div>
          ) : (
            <IntegerInput
              error={this.validateAvailableQuatity(bundleItem, quantity)}
              value={quantity}
              onChange={(quantity) =>
                this.handleBundleQuantityChange(bundleItem, quantity)
              }
            />
          )}
        </Table.Cell>
        <Table.Cell className={`${this.getElementClass('text-cell')} price`}>
          {formatCurrency(price * quantity)}
        </Table.Cell>
        <Table.Cell className={`${this.getElementClass('text-cell')} remove`}>
          <span onClick={() => this.setBundleRemoveIntent(bundleItem)}>
            Remove
            <Icon name="close" size="mini" />
          </span>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderModal() {
    const { removeIntent } = this.state;
    if (removeIntent) {
      return (
        <Modal open={true} onCloseClick={this.clearRemoveIntent}>
          <Modal.Content>
            <Spacer size="s" />
            <p>
              Remove{' '}
              {removeIntent.bundleItem
                ? removeIntent.bundleItem.title
                : removeIntent.inventoryItem.name}{' '}
              from your basket?
            </p>
            <Spacer size="s" />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.clearRemoveIntent}>
              Cancel
            </Button>
            <Button onClick={this.confirmRemoveIntent}>Remove</Button>
          </Modal.Actions>
        </Modal>
      );
    }
  }

  clearAlertMessage = () => {
    this.setState({
      alertMessage: null,
    });
  };

  render() {
    const { alertMessage } = this.state;
    return (
      <div {...this.getAttrs()}>
        <Container>
          <Spacer size="s" />
          <Layout horizontal center spread>
            <Layout.Group>
              <h5>Basket</h5>
            </Layout.Group>
            <Layout.Group>
              <Button as={Link} to="/" size="small">
                Continue Browsing
              </Button>
            </Layout.Group>
          </Layout>
          <Spacer size="s" />
          <Layout horizontal stackable>
            {this.renderItems()}
            {this.renderTotals()}
          </Layout>
          <Spacer size="l" />
        </Container>
        {this.renderModal()}
        {alertMessage && (
          <Alert text={alertMessage} onClose={this.clearAlertMessage} />
        )}
      </div>
    );
  }
}
