import React from 'react';
import { Responsive } from 'semantic-ui-react';

const TABLET_MAX_WIDTH = 1128;

export default class DesktopOnly extends React.Component {
  render() {
    return <Responsive minWidth={TABLET_MAX_WIDTH} {...this.props} />;
  }
}
