import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import PhoneNumberInput from 'public/components/Auth/PhoneNumberInput';
import { FieldContainer } from 'public/components/FieldContainer';
import { ControlledInputContainer } from 'public/components/ControlledInputContainer';
import { useClassName, useWindowSize } from 'common/hooks';
import { isValidEmail, isValidPhone } from 'utils/helpers';
import { identify, track } from 'utils/analytics';
import { WarningMessage } from 'public/components';
import { SVGIcon } from 'common/components';
import { BottomSheet } from 'public/components/BottomSheet';

import { BirthdayDatePicker } from '../BirthdayDatePicker';

import './birthday-contact-form.less';

const validations = {
  date: [{ isInvalid: (fields) => !fields.date, message: 'Date is required' }],
  firstName: [
    {
      isInvalid: (fields) => !fields.firstName,
      message: 'First Name is required',
    },
  ],
  lastName: [
    {
      isInvalid: (fields) => !fields.lastName,
      message: 'Last Name is required',
    },
  ],
  numberOfGuests: [
    {
      isInvalid: (fields) => !fields.numberOfGuests,
      message: 'Number of guests is required',
    },
  ],
  email: [
    { isInvalid: (fields) => !fields.email, message: 'Email is required' },
    {
      isInvalid: (fields) => !isValidEmail(fields.email),
      message: 'Please provide a valid email',
    },
  ],
  phone: [
    {
      isInvalid: (fields) => !fields.phone,
      message: 'Phone Number is required',
    },
    {
      isInvalid: (fields) => !isValidPhone(fields.phone),
      message: 'Please provide a valid Phone Number',
    },
  ],
};

const BirthdayContactForm = ({
  open,
  setOpen,
  venueName,
  onFormSubmitted,
  options,
  sendFormData,
}) => {
  const classNames = useClassName('BirthdayContactForm');
  const [currentFocus, setCurrentFocus] = useState(null);
  const [formData, setFormData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    setFormData({
      date: options?.date,
      lastName: options?.lastName,
      firstName: options?.firstName,
      email: options?.email,
    });
  }, [options]);

  const validate = (fieldName, { value }) => {
    const fields = Object.fromEntries(
      ['date', 'firstName', 'lastName', 'numberOfGuests', 'email', 'phone'].map(
        (name) => [name, value || formData[name]]
      )
    );

    const newFieldErrors = Object.keys(fields)
      .filter((key) => !fieldName || fieldName === key)
      .reduce((fieldErrors, key) => {
        const fieldValidations = validations[key];
        if (!fieldValidations?.length) return fieldErrors;

        const error = fieldValidations.find((a) => a.isInvalid(fields));
        return {
          ...fieldErrors,
          [key]: error ? error.message : null,
        };
      }, {});

    const errors = {
      ...fieldErrors,
      ...newFieldErrors,
    };

    setFieldErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const getFocusListeners = (fieldName) => {
    return {
      onBlur: (value) => {
        setCurrentFocus(null);
        validate(fieldName, value);
      },
      onFocus: () => setCurrentFocus(fieldName),
    };
  };

  const hasFocus = (fieldName) => currentFocus === fieldName;

  const onSubmit = async (evt) => {
    evt.preventDefault();

    const isValid = validate(null, { value: null });
    if (!isValid) return;

    try {
      setLoading(true);
      setError(null);

      sendFormData({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        venue: venueName,
        numberOfPeople: formData.numberOfGuests,
        date: DateTime.fromISO(formData.date).toFormat('MM/dd/yyyy'),
        groupType: 'Birthday Party',
      })
        .then(() => {
          sendInfoBraze();
          setLoading(false);
          onFormSubmitted();
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const sendInfoBraze = async () => {
    const user = {
      firstName: formData?.firstName || '',
      lastName: formData?.lastName || '',
      email: formData?.email || '',
      phone: formData?.phone || '',
    };
    const brazeTriggers = {
      customAttributes: {
        birthday: 'yes',
      },
      customEvent: 'subscription birthday form',
    };
    identify({
      ...user,
      ...brazeTriggers.customAttributes,
    });
    track(brazeTriggers.customEvent);
  };

  const title =
    'Please complete the form below, and we’ll contact you about your event.';
  const footer = (
    <>
      {error && <WarningMessage content={error} />}
      <div className={classNames('actions')}>
        <Button primary onClick={onSubmit} disabled={loading} fluid={isMobile}>
          {loading && <Loader size="mini" active inline="centered" />}
          <span>Send form</span>
        </Button>
      </div>
    </>
  );
  const content = (
    <div className={classNames('fields')}>
      <BirthdayDatePicker
        date={formData.date}
        showLabel={true}
        setDate={(date) => setFormData({ ...formData, date })}
        allowNearDates
        error={fieldErrors.date}
        onClose={(date) => getFocusListeners('date').onBlur({ value: date })}
        onOpen={getFocusListeners('date').onFocus}
      />
      <ControlledInputContainer
        label={<label htmlFor="numberOfGuests">Number of guests</label>}
        input={
          <input
            value={formData.numberOfGuests}
            id="numberOfGuests"
            name="numberOfGuests"
            type="number"
            min="1"
            step="1"
            onChange={(evt) =>
              setFormData({ ...formData, numberOfGuests: evt.target.value })
            }
            {...getFocusListeners('numberOfGuests')}
          />
        }
        hasFocus={hasFocus('numberOfGuests')}
        error={fieldErrors.numberOfGuests}
      />
      <ControlledInputContainer
        label={<label htmlFor="firstName">First Name</label>}
        input={
          <input
            value={formData.firstName}
            id="firstName"
            name="firstName"
            type="text"
            onChange={(evt) =>
              setFormData({ ...formData, firstName: evt.target.value })
            }
            {...getFocusListeners('firstName')}
          />
        }
        hasFocus={hasFocus('firstName')}
        error={fieldErrors.firstName}
      />
      <ControlledInputContainer
        label={<label htmlFor="lastName">Last Name</label>}
        input={
          <input
            value={formData.lastName}
            id="lastName"
            name="lastName"
            type="text"
            onChange={(evt) =>
              setFormData({ ...formData, lastName: evt.target.value })
            }
            {...getFocusListeners('lastName')}
          />
        }
        hasFocus={hasFocus('lastName')}
        error={fieldErrors.lastName}
      />
      <ControlledInputContainer
        label={<label htmlFor="email">Email</label>}
        input={
          <input
            value={formData.email}
            id="email"
            name="email"
            type="email"
            onChange={(evt) =>
              setFormData({ ...formData, email: evt.target.value })
            }
            {...getFocusListeners('email')}
          />
        }
        hasFocus={hasFocus('email')}
        error={fieldErrors.email}
      />
      <FieldContainer>
        <label htmlFor="phone">Phone Number</label>
        <PhoneNumberInput
          onChange={(value) => setFormData({ ...formData, phone: value })}
          id="phone"
          name="phone"
          {...getFocusListeners('phone')}
          error={fieldErrors.phone}
        />
      </FieldContainer>
    </div>
  );

  if (isMobile) {
    return (
      <BottomSheet
        onClose={() => setOpen(false)}
        title={title}
        isOpen={open}
        footer={<div className={classNames('footer')}>{footer}</div>}
        content={content}
      />
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className={classNames('container')}>
      <div className={classNames('header')}>
        {title}
        <div onClick={() => setOpen(false)}>
          <SVGIcon name="close" />
        </div>
      </div>
      {content}
      <div className={classNames('footer')}>{footer}</div>
    </Modal>
  );
};

BirthdayContactForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  venueName: PropTypes.string,
  onFormSubmitted: PropTypes.func,
  options: PropTypes.object,
  sendFormData: PropTypes.func,
};

export default BirthdayContactForm;
