import React from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { ExternalLink } from 'common/components';
import { formatDate } from 'utils/l10n';
import { Screen, awaitEntries } from 'public/helpers';
import { Spacer } from 'public/components';
import PressNav from './PressNav';

import './press-releases.less';

@awaitEntries('press-other')
export default class PressReleases extends Screen {
  META_TITLE = 'META_PRESS_RELEASES_TITLE';

  static renderLoading() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <Loader inline="centered" active />
        <Spacer size="m" />
      </React.Fragment>
    );
  }

  constructor(props) {
    super(props);
    this.releases = props['press-other'].sort((a, b) => b.date - a.date);
  }

  renderBody() {
    return (
      <React.Fragment>
        <Spacer size="m" />
        <div {...this.getAttrs()}>
          <Container>
            <Spacer size="s" />
            <PressNav />
            <Spacer size="s" />
            <ul>{this.renderItems()}</ul>
          </Container>
        </div>
        <Spacer size="l" />
      </React.Fragment>
    );
  }

  renderItems() {
    return this.releases.map((item, i) => {
      return (
        <li key={i} className={this.getElementClass('item')}>
          <ExternalLink
            href={item.link}
            className={this.getElementClass('link')}>
            {formatDate(item.date)} - {item.title}
          </ExternalLink>
        </li>
      );
    });
  }
}
