import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';
import {
  Layout,
  SVGIcon as Icon,
  getContentfulField,
  ContentfulRichText,
} from 'common/components';
import {
  trackCheckoutStepCompleted,
  trackOrderCompleted,
} from 'common/helpers';
import { deleteCookie } from 'utils/helpers/cookie';

import { keys } from './helpers/discounts';

import './completed.less';

const PAGE_SLUG = 'checkout-completed';

@inject('cart', 'orders', 'me', 'pages', 'checkout')
@observer
export default class CartCompleted extends Screen {
  state = {
    parkingLinks: null,
  };

  componentDidMount() {
    super.componentDidMount();
    this.props.me.fetch();
    this.getParkingLinks();
    this.completeCheckout();
  }

  completeCheckout() {
    const { tracking } = this.props.checkout.checkout;
    if (!tracking) return;

    trackOrderCompleted(tracking.order, tracking.cartItems);
    trackCheckoutStepCompleted(3);

    deleteCookie(keys.DISCOUNT_VALUE_KEY);
    deleteCookie(keys.DISCOUNT_CODE_KEY);
    deleteCookie(keys.DISCOUNT_USAGE_KEY);
    localStorage.removeItem(keys.DISCOUNT_USAGE_KEY);

    this.props.cart.clear();
    this.props.checkout.finish({
      ...this.props.checkout.checkout,
      tracking: undefined,
    });
  }

  async getParkingLinks() {
    const page = await this.props.pages.fetchItemBySlug(PAGE_SLUG);

    if (page) {
      const {
        object: { fields },
      } = page;
      const sections = fields?.sections && getContentfulField(fields.sections);

      if (sections?.length && sections[0]) {
        const { description } = sections[0]?.fields;
        if (description) this.setState({ parkingLinks: description });
      }
    }
  }

  renderBody() {
    if (!this.props.me.user) {
      return null;
    }

    return (
      <>
        <Spacer size="xs" />
        <Layout className={this.getElementClass('card')}>
          <div>
            <Icon name="check-circle" size="large" />
          </div>
          <Spacer size="xs" />
          <h4>You're all set!</h4>
          <Spacer size="xs" />
          <p>We’ll send a confirmation to {this.props.me.user.email}</p>
          <Spacer size="xs" />
          <p>
            You can view and manage your bookings on{' '}
            <Link to="/profile/orders">profile page</Link>
          </p>
          <Spacer size="s" />
          <Button as={Link} to="/" fluid>
            Continue browsing
          </Button>
          {this.state.parkingLinks && (
            <>
              <Spacer size="m" />
              <ContentfulRichText
                className={this.getElementClass('links')}
                field={this.state.parkingLinks}
              />
            </>
          )}
        </Layout>
        <Spacer size="xs" />
      </>
    );
  }
}
