import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ContentfulRichText } from 'common/components';
import { useClassName, useWindowSize } from 'common/hooks';
import './location.less';
import googleMaps from 'common/assets/google-maps.svg';
import appleMaps from 'common/assets/apple-maps.svg';
import mapPin from 'common/assets/map-pin.svg';

const Location = ({ hoursPage }) => {
  const className = useClassName('Location');
  const { isMobile } = useWindowSize();

  return (
    <div className={className('container')}>
      <div className={className('body')}>
        <h3 className={className('title')}>{hoursPage.locationTitle}</h3>
        <ContentfulRichText field={hoursPage.locationDescription} />
        <div className={className('address')}>
          <div className={className('map-pin')}>
            <img src={mapPin} alt="map-pin" />
          </div>
          <span>{hoursPage.address}</span>
        </div>
      </div>
      <div className={className('map')}>
        <img
          className={className('map-image')}
          src={
            isMobile
              ? hoursPage.mapMobileImage.assetUrl
              : hoursPage.mapDesktopImage.assetUrl
          }
          alt={
            isMobile
              ? hoursPage.mapMobileImage.assetAlt
              : hoursPage.mapDesktopImage.assetAlt
          }
        />
        <div className={className('map-link')}>
          <Link className={className('link')} to={hoursPage.googleMapsLink}>
            <img src={googleMaps} alt="google-maps" />
            View on Google Maps
          </Link>
        </div>
        <div className={className(['map-link', 'map-bottom-link'])}>
          <Link className={className('link')} to={hoursPage.appleMapsLink}>
            <img src={appleMaps} alt="apple-maps" />
            View on Apple Maps
          </Link>
        </div>
      </div>
    </div>
  );
};

Location.propTypes = {
  hoursPage: PropTypes.object.isRequired,
};

export default Location;
