import React from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { observer, inject } from 'mobx-react';
import {
  Form,
  Button,
  Checkbox,
  Message,
  Dimmer,
  Loader,
  Input,
} from 'semantic-ui-react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
} from 'react-stripe-elements';
import { Component } from 'common/helpers';
import { FormActions, MobileOnly } from 'common/components';
import { Spacer } from 'public/components';
import { formatCurrency } from 'utils/l10n';
import masterCard from 'common/assets/master-card.svg';
import { getFeePaid } from '../helpers/orderUpdate';

import './payment-form.less';
import TicketsGroup from './TicketsGroup';

@injectStripe
@inject('payment', 'orderUpdate')
@observer
export default class PaymentForm extends Component {
  state = {
    currentPaymentMethod: null,
    paymentMethodsLoading: true,
    useExistingMethod: false,
    saveCard: false,
  };

  onCardElementChange = ({ elementType, error }) => {
    this.setState({
      [`${elementType}Error`]: error,
    });
  };

  onZipCodeChange = (evt) => {
    this.setState({
      zipCode: evt.target.value,
    });
  };

  onWaiverAcceptedChange = () => {
    this.setState(({ waiverAccepted }) => ({
      waiverAccepted: !waiverAccepted,
    }));
  };

  onSubmit = async () => {
    if (!this.props.showPayment) {
      this.props.onPaymentMethodReceived(null, false);
    } else if (this.useCardOnFile()) {
      this.props.onPaymentMethodReceived(
        this.state.currentPaymentMethod.id,
        this.state.saveCard
      );
    } else {
      const {
        zipCode,
        waiverAccepted,
        cardNumberError,
        cardExpiryError,
        cardCvcError,
      } = this.state;

      if (cardNumberError) {
        this.props.onPaymentMethodError(cardNumberError);
      } else if (cardExpiryError) {
        this.props.onPaymentMethodError(cardExpiryError);
      } else if (cardCvcError) {
        this.props.onPaymentMethodError(cardCvcError);
      } else if (!zipCode) {
        this.props.onPaymentMethodError(new Error('Zip Code Required'));
      } else if (!waiverAccepted) {
        this.props.onPaymentMethodError(
          new Error('Please review and accept the agreement')
        );
      } else {
        try {
          this.setState({
            paymentMethodsLoading: true,
          });
          const response = await this.props.stripe.createPaymentMethod('card', {
            billing_details: {
              address: {
                postal_code: zipCode,
              },
            },
          });
          if (response.error) {
            throw response.error;
          }
          this.setState({
            paymentMethodsLoading: false,
          });

          const existingCreditCard = this.props.payment.items.filter(
            (item) =>
              item.card.brand === response.paymentMethod.card.brand &&
              item.card.last4 === response.paymentMethod.card.last4 &&
              item.card.exp_month === response.paymentMethod.card.exp_month &&
              item.card.exp_year === response.paymentMethod.card.exp_year &&
              item.zipCode === zipCode
          );
          const paymentMethodId = existingCreditCard.length
            ? existingCreditCard[0].id
            : response.paymentMethod.id;

          this.props.onPaymentMethodReceived(
            paymentMethodId,
            this.state.saveCard
          );
        } catch (err) {
          this.setState({
            paymentMethodsLoading: false,
          });
          this.props.onPaymentMethodError(err);
        }
      }
    }
  };

  updatePaymentMethod = (paymentMethod) => {
    this.setState({
      currentPaymentMethod: paymentMethod,
    });
  };

  toggleSaveCard = () => {
    this.setState({
      saveCard: !this.state.saveCard,
    });
  };

  async componentDidMount() {
    await this.props.payment.fetchPaymentMethods();
    const { items } = this.props.payment;
    const currentPaymentMethod = items.length && items[0];
    this.setState({
      currentPaymentMethod,
      paymentMethodsLoading: false,
    });
  }

  async componentDidUpdate(prevProps) {
    const paymentIntent = this.getPendingPaymentIntent(prevProps);
    if (paymentIntent) {
      try {
        const response = await this.props.stripe.handleCardAction(
          paymentIntent.client_secret
        );
        if (response.error) {
          throw response.error;
        } else if (response.paymentIntent.status !== 'requires_confirmation') {
          const err = new Error('Card action could not be handled.');
          err.payload = response;
          throw err;
        }
        this.props.onPaymentIntentSuccess(
          response.paymentIntent,
          this.state.saveCard
        );
      } catch (err) {
        this.props.onCardActionError(err);
      }
    }
  }

  getPendingPaymentIntent(prevProps) {
    const { paymentIntent } = this.props;
    if (paymentIntent && paymentIntent !== prevProps.paymentIntent) {
      return ['requires_source_action', 'requires_action'].includes(
        paymentIntent.status
      )
        ? paymentIntent
        : null;
    }
  }

  useCardOnFile() {
    return this.state.currentPaymentMethod && this.state.useExistingMethod;
  }

  hasStoredPaymentMethods() {
    return this.props.payment.items.length > 0;
  }

  isLoading() {
    return this.props.loading || this.state.paymentMethodsLoading;
  }

  renderPaymentDetails() {
    return (
      <>
        <label>Card Number</label>
        <Form.Field>
          <CardNumberElement
            onChange={this.onCardElementChange}
            placeholder="1234 1234 1234 1234"
          />
          <img src={masterCard} alt="MasterCard" />
        </Form.Field>
        <Spacer size="xs" />
        <label>Expiration Date</label>
        <Form.Field>
          <CardExpiryElement
            onChange={this.onCardElementChange}
            placeholder="MM / YY"
          />
        </Form.Field>
        <Spacer size="xs" />
        <label>Security Code</label>
        <Form.Field>
          <CardCvcElement
            onChange={this.onCardElementChange}
            placeholder="CCV"
          />
        </Form.Field>
        <Spacer size="xs" />
        <label>Zip / Postal Code</label>
        <Form.Field>
          <Input
            value={this.state.zipCode}
            onChange={this.onZipCodeChange}
            id="zipCode"
            name="zipCode"
            type="text"
            autoComplete="postal-code"
            placeholder="ZIP"
          />
        </Form.Field>
      </>
    );
  }

  renderAgreements() {
    return (
      <div className={this.getElementClass('agreements')}>
        <Spacer size="xs" />
        <Checkbox
          id="waiverAccepted"
          name="waiverAccepted"
          checked={this.state.waiverAccepted}
          onChange={this.onWaiverAcceptedChange}
          label={
            <span>
              I have read and agree to the{' '}
              <a target="_blank" href="/waiver">
                American Dream Waiver, Release and Indemnity Agreement
              </a>
              .
            </span>
          }
        />
      </div>
    );
  }

  renderSummary() {
    const { currentTickets, newTickets, newAddons, changeDateFeeTicket } =
      this.props.orderUpdate;
    const { total } = this.props;
    const feePaid = getFeePaid(currentTickets);

    const currentTotal = formatCurrency(
      sumBy(
        currentTickets,
        ({ ticketOption }) =>
          ticketOption.quantity *
          (ticketOption.priceDiscount +
            ticketOption.taxDiscount +
            ticketOption.feeAmount)
      ),
      true
    );

    const allTickets = [...newTickets, ...newAddons];

    if (changeDateFeeTicket) allTickets.push(changeDateFeeTicket);

    const newTotal = formatCurrency(
      sumBy(
        allTickets,
        ({ ticketOption }) =>
          ticketOption.quantity * (ticketOption.price + ticketOption.tax)
      ) + feePaid.total,
      true
    );

    return (
      <>
        <p className="title">Summary</p>
        <TicketsGroup tickets={allTickets} feePaid={feePaid} />
        <div className="totals">
          <div>
            <p>Subtotal</p>
            <p>{newTotal}</p>
          </div>
          <div>
            <p className="previous-paid">Previously paid</p>
            <p>{currentTotal}</p>
          </div>
          <div className="total">
            <p>Balance due</p>
            <p>${total < 0 ? 0 : total}</p>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { error, total } = this.props;

    return (
      <Form className={this.getElementClass('form')} onSubmit={this.onSubmit}>
        <div className="form-details">
          {this.isLoading() && (
            <Dimmer active inverted>
              <Loader inverted />
            </Dimmer>
          )}
          {error && (
            <React.Fragment>
              <Message negative content={error.message || error.errorMessage} />
            </React.Fragment>
          )}
          {this.props.showPayment && this.renderPaymentDetails()}
          {this.renderAgreements()}
          <MobileOnly>
            <FormActions>
              <Button
                disabled={this.isLoading() || !this.state.waiverAccepted}
                fluid
                primary>
                {this.props.showPayment ? `Pay $${total}` : 'Confirm'}
              </Button>
            </FormActions>
          </MobileOnly>
        </div>
        <div className={this.getElementClass('summary')}>
          {this.renderSummary()}
          <FormActions>
            <Button
              disabled={this.isLoading() || !this.state.waiverAccepted}
              fluid
              primary>
              {this.props.showPayment ? 'Confirm and pay' : 'Confirm'}
            </Button>
          </FormActions>
        </div>
      </Form>
    );
  }
}

PaymentForm.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  showPayment: PropTypes.bool.isRequired,
  paymentIntent: PropTypes.object,
  onPaymentMethodReceived: PropTypes.func.isRequired,
  onPaymentIntentSuccess: PropTypes.func.isRequired,
  onCardActionError: PropTypes.func.isRequired,
  onPaymentMethodError: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};
