import React from 'react';
import { sum } from 'lodash';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import { ContentfulImage, SVGIcon } from 'common/components';
import { formatCurrency, formatDate } from 'utils/l10n';
import { getItemPrice } from 'common/helpers';
import { parseApiDate } from 'utils/api';

import QuantityControls from '../../Components/QuantityControls/QuantityControls';

import './tickets.less';

const CartTicket = ({
  logo,
  type,
  name,
  description,
  date,
  tickets,
  getQuantity,
  onChangeQuantity,
  onRemove,
  getSoldOutLabel,
}) => {
  const classNames = useClassName('NewCheckoutBasketTicket');

  return (
    <div className={classNames('container')}>
      <div className={classNames('header')}>
        <div className={classNames('header-image')}>
          {logo.contentfulField ? (
            <ContentfulImage field={logo.contentfulField} width={244} />
          ) : (
            <img src={logo.src} alt={logo.alt} />
          )}
        </div>
        <div className={classNames('header-info')}>
          <span>{type}</span>
          <span>{name}</span>
          <span>{description}</span>
        </div>
        {date && (
          <div className={classNames('header-date')}>
            <SVGIcon name="date" size="tiny" /> {date}
          </div>
        )}
      </div>
      <div className={classNames('tickets')}>
        {tickets.map((ticket, index) => {
          const soldOutLabel = getSoldOutLabel(ticket);

          return (
            <div
              key={index}
              className={classNames([
                'ticket',
                Boolean(soldOutLabel) && 'sold-out',
              ])}>
              <div className={classNames('ticket-info')}>
                <span>{ticket.name}</span>
                {ticket.addon && (
                  <span className={classNames('addon')}>ADD-ON</span>
                )}
              </div>
              <div className={classNames('ticket-controls')}>
                <span>{formatCurrency(ticket.price)}</span>
                {soldOutLabel ? (
                  <div>{soldOutLabel}</div>
                ) : (
                  <QuantityControls
                    onChange={(quantity) => onChangeQuantity(ticket, quantity)}
                    quantity={getQuantity(ticket)}
                    className={classNames('small-controls')}
                    min={ticket.minPurchase}
                    max={ticket.maxPurchase}
                    exceptions={{ 0: () => onRemove(ticket) }}
                  />
                )}
              </div>
              <div className={classNames('ticket-totals')}>
                <span>
                  {formatCurrency(ticket.price * getQuantity(ticket))}
                </span>
              </div>
              <div
                className={classNames('ticket-remove')}
                onClick={() => onRemove(ticket)}>
                <SVGIcon name="trash" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const BundleTicket = ({
  isSoldOut,
  getSoldOutLabel,
  cartItem,
  onChangeBundleQuantity,
  onRemoveBundle,
  onChangeAddonsQuantity,
  onRemoveAddons,
  addonsCartItems,
}) => {
  const getBundleSoldOutLabel = (bundle) => {
    const soldOutTicket = bundle.items
      .map((item) => item.tickets)
      .flat()
      .find(isSoldOut);

    if (!soldOutTicket) return null;

    return getSoldOutLabel(soldOutTicket);
  };

  const getBundleDescription = () =>
    cartItem.items
      .map((item) => {
        if (cartItem.passportType) {
          return item.venue.name;
        }

        if (item.venue.hiddenDatePicker) {
          return item.venue.name;
        }

        return `${item.venue.name} (${formatDate(item.reservationDate, {
          month: 'long',
          day: 'numeric',
        })})`;
      })
      .join(' + ');

  const getBundleQuantity = () =>
    cartItem.items.map((item) => {
      const { quantities, inventoryItem } = item;
      const { ticketOptionId } = inventoryItem;

      return quantities[ticketOptionId];
    })[0];

  const getBundlePrice = () =>
    sum(
      cartItem.items.map((item) => {
        const { quantities, inventoryItem, inventory } = item;

        return getItemPrice(inventoryItem, inventory, quantities);
      })
    );

  const getBundleDates = () => {
    if (!cartItem.passportType) return null;

    const startDate = DateTime.fromISO(cartItem.items[0].reservationDate);
    const endDate = startDate.plus({ days: cartItem.passportValidDays - 1 });

    return [
      startDate.toLocaleString({ month: 'long', day: 'numeric' }),
      startDate.month === endDate.month
        ? endDate.toLocaleString({ day: 'numeric' })
        : endDate.toLocaleString({ month: 'long', day: 'numeric' }),
    ].join(' - ');
  };

  const isAddon = (ticket) => Boolean(ticket.ticketOptionId);

  const getBundleAddonsTickets = (addonsCartItems) =>
    addonsCartItems.map(({ tickets }) => tickets).flat() || [];

  const getBundleAddonCartItem = (addonsCartItems, ticket) =>
    addonsCartItems.find(({ tickets }) =>
      tickets.find(
        ({ ticketOptionId }) => ticketOptionId === ticket.ticketOptionId
      )
    );

  return (
    <CartTicket
      logo={{ src: cartItem.image.desktopSrc, alt: cartItem.image.imageAlt }}
      type="Bundle"
      name={cartItem.title}
      description={getBundleDescription()}
      date={getBundleDates(cartItem)}
      tickets={[
        {
          name: 'General Pass',
          addon: false,
          price: getBundlePrice(),
        },
        ...getBundleAddonsTickets(addonsCartItems),
      ]}
      getQuantity={(ticket) => {
        if (isAddon(ticket)) {
          const addonsCartItem = getBundleAddonCartItem(
            addonsCartItems,
            ticket
          );
          return addonsCartItem.quantities[ticket.ticketOptionId];
        }

        return getBundleQuantity();
      }}
      onChangeQuantity={(ticket, quantity) => {
        if (isAddon(ticket)) {
          const addonsCartItem = getBundleAddonCartItem(
            addonsCartItems,
            ticket
          );
          return onChangeAddonsQuantity(addonsCartItem, ticket, quantity);
        }

        onChangeBundleQuantity(cartItem, quantity);
      }}
      onRemove={(ticket) => {
        if (isAddon(ticket)) {
          const addonsCartItem = getBundleAddonCartItem(
            addonsCartItems,
            ticket
          );
          return onRemoveAddons(addonsCartItem, ticket);
        }

        onRemoveBundle(cartItem);
      }}
      getSoldOutLabel={(ticket) => {
        if (isAddon(ticket)) {
          return isSoldOut(ticket) && getSoldOutLabel(ticket);
        }

        getBundleSoldOutLabel(cartItem);
      }}
    />
  );
};

const CartTickets = ({
  cartItems,
  onChangeQuantity,
  onRemove,
  onChangeBundleQuantity,
  onRemoveBundle,
}) => {
  const getSoldOutLabel = (inventoryItem) => {
    return parseApiDate(inventoryItem.date) < new Date()
      ? 'No Longer Available'
      : 'Sold Out';
  };

  const isSoldOut = (inventoryItem) => {
    return inventoryItem.quantity === 0;
  };

  return (
    <>
      {cartItems.map((cartItem, index) => {
        if (cartItem.bundle) {
          const addonsCartItems = cartItems.filter(
            (ci) => ci.parentBundleCartId === cartItem.bundleCartId
          );

          return (
            <BundleTicket
              key={index}
              isSoldOut={isSoldOut}
              getSoldOutLabel={getSoldOutLabel}
              cartItem={cartItem}
              onChangeBundleQuantity={onChangeBundleQuantity}
              onRemoveBundle={onRemoveBundle}
              onChangeAddonsQuantity={onChangeQuantity}
              onRemoveAddons={onRemove}
              addonsCartItems={addonsCartItems}
            />
          );
        }

        const tickets = cartItem.tickets;
        if (!tickets.length) return null;
        if (cartItem.parentBundleSlug) return null;

        const {
          venue,
          reservationDate,
          startTime,
          startTimeName,
          quantities,
          sessions,
        } = cartItem;

        return (
          <CartTicket
            key={index}
            logo={{ contentfulField: venue.content.horizontalImage }}
            type={sessions ? 'Admission' : 'Package'}
            name={venue.name}
            description={startTimeName || startTime}
            date={
              !venue.hiddenDatePicker
                ? formatDate(reservationDate, { month: 'long', day: 'numeric' })
                : null
            }
            tickets={tickets}
            getQuantity={(ticket) => quantities[ticket.ticketOptionId]}
            onChangeQuantity={(ticket, quantity) =>
              onChangeQuantity(cartItem, ticket, quantity)
            }
            onRemove={(ticket) => onRemove(cartItem, ticket)}
            getSoldOutLabel={(ticket) =>
              isSoldOut(ticket) && getSoldOutLabel(ticket)
            }
          />
        );
      })}
    </>
  );
};

CartTickets.propTypes = {
  cartItems: PropTypes.array.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeBundleQuantity: PropTypes.func.isRequired,
  onRemoveBundle: PropTypes.func.isRequired,
};

export default CartTickets;
