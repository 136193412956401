import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { useClassName } from 'common/hooks';
import { SVGIcon } from 'common/components';

import './not-available.less';

export default function NotAvailable() {
  const classNames = useClassName('NotAvailable');

  return (
    <div className={classNames('container')}>
      <div>
        <SVGIcon name="warning" size="small" />
      </div>
      <h1>Tickets not available</h1>
      <p>
        Points passes are not available on select Saturdays, holidays and other
        blackout days.
      </p>
      <div>
        <Button
          as={Link}
          to="/venue/nickelodeon-universe/tickets"
          fluid
          primary
          className="static">
          Buy All Access Pass
        </Button>
        <Button as={Link} to="/" fluid className="static">
          Go to Home
        </Button>
      </div>
    </div>
  );
}
