import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import { useClassName } from 'common/hooks';
import { SVGIcon as Icon } from 'common/components';
import { SocialLinks } from 'public/components/SocialLinks';
import { FooterList } from 'public/components/FooterList';
import { identify, track } from 'utils/analytics';
import { isValidEmail } from 'utils/helpers';
import logo from 'common/assets/ad-logo-linear.png';
import logoCircle from 'common/assets/logo-black-circle.svg';

import { ABOUT, PARTNER, PLAN_YOUR_TRIP } from './config';
import './navigation-footer.less';

const INFO_LINKS = [
  { name: 'Terms & Conditions', link: '/terms', label: 'Terms & Conditions' },
  { name: 'PrivacyPolicy', link: '/privacy', label: 'Privacy Policy' },
  { name: 'Waiver', link: '/waiver', label: 'Waiver' },
  { name: 'CookiePolicy', link: '/cookie-policy', label: 'Cookie Policy' },
  { name: 'Sitemap', link: '/sitemap', label: 'Sitemap' },
];
const AD_EMAIL = 'guestservices@americandream.com';
const AD_PHONE = '1-833-263-7326';
const AD_LOCATION = 'http://bitly.ws/uhh6';

const excludedRoutes = [
  /^\/login/i,
  /\/signup/i,
  /\/tickets/i,
  /\/*-golf\/score-card*/,
];

const NavigationFooter = ({ subscriptions, me }) => {
  const className = useClassName('NavigationFooter');

  const [singnedUp, setSignedUp] = useState(false);
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleLinkPress = (name) => track(`Footer - Viewed ${name}`);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setInvalidEmail(true);
      return;
    }
    try {
      identify(me?.user || { email });

      await subscriptions.create({
        group: 'notify',
        email,
      });
      track('Footer newsletter subscription');
      setSignedUp(true);
    } catch (e) {
      console.error(`Error subscribing customer, error: ${e.message}`);
    } finally {
      setInvalidEmail(false);
    }
  };

  const shouldDisplay = () => {
    return excludedRoutes.every((regexp) => {
      return !window.location.pathname.match(regexp);
    });
  };

  if (!shouldDisplay()) return null;

  return (
    <div className={`${className('container')} footer`}>
      <div className={className('logo-container')}>
        <img src={logo} alt="AMERICAN DREAM" />
      </div>
      <div className={className('content')}>
        <div className={className('left-content')}>
          {singnedUp ? (
            <>
              <p className={className('left-title')}>
                Thank you for Signing up!
              </p>
              <p className={className('left-body')}>
                Check your email for the latest news.
              </p>
            </>
          ) : (
            <>
              <p className={className('left-title')}>
                Sign up for the latest news
              </p>
              <p className={className('left-body')}>
                American Dream openings, events, and promotions straight to your
                inbox!
              </p>
              <form onSubmit={handleSubscribe}>
                <div className={className('email-input')}>
                  <Input
                    error={invalidEmail}
                    key="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit">
                    <Icon
                      name="white-rigth-arrow"
                      className={className('go-arrow')}
                    />
                  </button>
                </div>
              </form>
            </>
          )}
          <div className={className('social-media')}>
            <p className={className('social-media-title')}>
              Follow us on social
            </p>
            <SocialLinks />
          </div>
        </div>
        <div className={className('right-content')}>
          <FooterList
            items={PLAN_YOUR_TRIP}
            title="Plan your trip"
            isNew={true}
          />
          <FooterList items={PARTNER} title="Partner with us" isNew={true} />
          <FooterList items={ABOUT} title="About" isNew={true} />
        </div>
      </div>
      <div className={className('info-container')}>
        <div
          className={className('address')}
          onClick={() => window.open(AD_LOCATION, '_blank')}>
          <img src={logoCircle} alt="AMERICAN DREAM" />1 American Dream Way,
          East Rutherford, NJ 07073
        </div>
        <div className={className('contact')}>
          <div
            className={className('contact-method')}
            onClick={() => window.open(`tel:${AD_PHONE}`)}>
            <Icon className={className(['icon', 'phone-icon'])} name="phone" />
            {AD_PHONE}
          </div>
          <div
            className={className('contact-method')}
            onClick={() => window.open(`mailto:${AD_EMAIL}`)}>
            <Icon
              className={className(['icon', 'message-icon'])}
              name="message"
            />
            {AD_EMAIL}
          </div>
        </div>
      </div>
      <div className={className('info-links-container')}>
        {INFO_LINKS.map((infoLink) => (
          <Link
            key={`link-${infoLink.name}`}
            to={infoLink.link}
            onClick={() => handleLinkPress(infoLink.name)}
            className={className('info-link')}>
            {infoLink.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default inject('subscriptions', 'me')(NavigationFooter);
