import React from 'react';
import { inject, observer } from 'mobx-react';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';

import { Screen } from 'public/helpers';
import {
  ContentfulImage,
  SVGIcon,
  DesktopOnly,
  MobileOnly,
} from 'common/components';
import { formatCurrency } from 'utils/l10n';

import { NewCheckoutFooter } from '../../../Components/Footer';

import './confirmation.less';

@inject('birthdays')
@observer
export default class BirthdayConfirmation extends Screen {
  renderBody() {
    const { transactionDetails, settings } = this.props.birthdays.checkout;

    return (
      <>
        <div className={this.getElementClass('wrapper')}>
          <div className={this.getElementClass('container')}>
            <div>
              <SVGIcon name="success" size="large" />
              <h1>The adventure awaits you</h1>
              <h3>
                Your transaction has been confirmed. Your confirmation has been
                sent to <strong>{transactionDetails.email}</strong> and an
                American Dream member will contact you to confirm.
              </h3>
            </div>
            <div className={this.getElementClass('purchase')}>
              <h5>your purchase</h5>
              <div className={this.getElementClass('items')}>
                <div className={this.getElementClass('item')}>
                  <div className={this.getElementClass('item-image')}>
                    <SVGIcon name="gift" size="small" />
                  </div>
                  <div className={this.getElementClass('item-info')}>
                    <span>Birthday Party Pack</span>
                    <span>
                      {DateTime.fromISO(
                        transactionDetails.admission.date
                      ).toFormat('LLLL dd, yyyy')}{' '}
                      | {transactionDetails.admission.session}
                    </span>
                    <span>
                      {transactionDetails.admission.numberOfGuests} guest pack
                    </span>
                  </div>
                </div>
                {Boolean(transactionDetails.entertainment.length) && (
                  <div className={this.getElementClass('item')}>
                    <div className={this.getElementClass('item-image')}>
                      <SVGIcon name="ferris-wheel" size="small" />
                    </div>
                    <div className={this.getElementClass('item-info')}>
                      <span>entertainment</span>
                      {transactionDetails.entertainment.map(
                        ({ productName, quantity }, index) => (
                          <span key={index}>
                            {productName} x{quantity}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                )}
                {Boolean(transactionDetails.foodAndBeverage.length) && (
                  <div className={this.getElementClass('item')}>
                    <div className={this.getElementClass('item-image')}>
                      <SVGIcon name="restaurant" size="small" />
                    </div>
                    <div className={this.getElementClass('item-info')}>
                      <span>Food & Beverage</span>
                      {transactionDetails.foodAndBeverage.map(
                        ({ productName, quantity }, index) => (
                          <span key={index}>
                            {productName} x{quantity}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                )}
                {Boolean(transactionDetails.merchandise.length) && (
                  <div className={this.getElementClass('item')}>
                    <div className={this.getElementClass('item-image')}>
                      <SVGIcon name="restaurant" size="small" />
                    </div>
                    <div className={this.getElementClass('item-info')}>
                      <span>Merchandise</span>
                      {transactionDetails.merchandise.map(
                        ({ productName, quantity }, index) => (
                          <span key={index}>
                            {productName} x{quantity}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={this.getElementClass('payment')}>
              <h5>YOUR payment METHOD</h5>
              <div>
                <span>
                  <SVGIcon name="credit-card" size="small" />
                  <DesktopOnly>
                    {transactionDetails.payment.cardBrand
                      ? `${capitalize(
                          transactionDetails.payment.cardBrand
                        )} ending in ${transactionDetails.payment.last4}`
                      : capitalize(transactionDetails.payment.type)}
                  </DesktopOnly>
                  <MobileOnly>
                    **** {transactionDetails.payment.last4}
                    {transactionDetails.payment.cardBrand
                      ? `**** ${transactionDetails.payment.last4}`
                      : capitalize(transactionDetails.payment.type)}
                  </MobileOnly>
                </span>
                <span>{formatCurrency(transactionDetails.payment.amount)}</span>
              </div>
            </div>
          </div>
          {transactionDetails?.images && (
            <div className={this.getElementClass('banner')}>
              <ContentfulImage field={transactionDetails.images} width={2000} />
            </div>
          )}
        </div>
        <NewCheckoutFooter
          classes={this.getElementClass('footer')}
          onHelpClick={() => window.open(`mailto:${settings.submitInfoTo}`)}
        />
      </>
    );
  }
}
