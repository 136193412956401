import React from 'react';
import { Container, Button } from 'semantic-ui-react';
import {
  Layout,
  ExternalLink,
  ScrollWaypoint,
  ContentfulImage,
  ContentfulPlainText,
  ContentfulRichText,
  getContentfulField,
  DesktopOnly,
  MobileOnly,
} from 'common/components';
import { Screen, withVenue } from 'public/helpers';
import {
  Spacer,
  AttractionsNav,
  NearbyVenues,
  AttractionScreen,
} from 'public/components';

import './home.less';

@withVenue('mirrormaze')
export default class MirrorMazeHome extends Screen {
  constructor(props) {
    super(props);
    this.state = {
      focusedFloor: props.venue.floorLevel || 1,
    };
  }

  updateInfoBlock(index, name, value) {
    const key = `infoBlock${index}`;
    const newState = Object.assign({}, this.state[key], {
      [name]: value,
    });
    this.setState({
      [key]: newState,
    });
  }

  getScrollModifier(index) {
    const { entered } = this.getInfoBlockState(index);
    return this.getSlideModifier(entered);
  }

  getSlideModifier(val) {
    if (val === true) {
      return 'in';
    } else if (val === false) {
      return 'out';
    } else {
      return null;
    }
  }

  getInfoBlockState(index) {
    return this.state[`infoBlock${index}`] || {};
  }

  renderBody() {
    const { venue } = this.props;
    if (venue?.content?.attractionScreen) {
      return <AttractionScreen venue={venue} />;
    }
    return (
      <div {...this.getAttrs()}>
        <div className={this.getElementClass('hero')}>
          <DesktopOnly>
            <ContentfulImage field={venue.content.hero} fluid />
          </DesktopOnly>
          <MobileOnly>
            <ContentfulImage field={venue.content.heroMobile} fluid />
          </MobileOnly>
        </div>
        <AttractionsNav venue={venue} />
        <div className={this.getElementClass('main-block')}>
          <Spacer size="m" />
          <Container>
            <div className={this.getElementClass('main-title')}>
              MIRROR <b>MAZE</b>
            </div>
            <Spacer size="xs" />
            <div className={this.getElementClass('main-subtitle')}>
              Experience amazement as you navigate your way through a galaxy of
              corridors filled with infinite reflections. Mind-bending light
              effects create twists and turns around every corner. An
              interstellar adventure for the entire family!
            </div>
            <Spacer size="l" />
          </Container>
        </div>
        <div className={this.getElementClass('info-blocks')}>
          <Container>
            <Spacer size="m" />
            {this.renderInfoBlocks()}
          </Container>
        </div>
        <Container>
          <Spacer size="m" />
          <NearbyVenues venue={venue} />
          <Spacer size="l" />
        </Container>
      </div>
    );
  }

  renderInfoBlocks() {
    const { venue } = this.props;

    return (
      <div>
        {getContentfulField(venue.content.infoBlocks).map((infoBlock, i) => {
          return this.renderInfoBlock(infoBlock, i);
        })}
      </div>
    );
  }

  renderInfoBlock(infoBlock, index) {
    const { fields } = infoBlock;
    const reverse = index % 2 === 0;
    return (
      <div key={index} className={this.getElementClass('info-block')}>
        <ScrollWaypoint
          onEnter={() => this.updateInfoBlock(index, 'entered', true)}
          onLeave={() => this.updateInfoBlock(index, 'entered', false)}>
          <Layout reversed={reverse} horizontal center stackable extra padded>
            {this.renderInfoBlockImage(index, fields)}
            {this.renderInfoBlockText(index, fields)}
          </Layout>
        </ScrollWaypoint>
      </div>
    );
  }

  renderInfoBlockText(index, fields) {
    const mod = this.getScrollModifier(index);
    const linkHref = getContentfulField(fields.link);
    return (
      <Layout.Group className={this.getElementClass('slide-text', mod)}>
        <ContentfulRichText
          className={this.getElementClass('info-block-title')}
          field={fields.title}
        />
        <Spacer size="xs" />
        <ContentfulRichText
          className={this.getElementClass('info-block-body')}
          field={fields.body}
        />
        <Spacer size="s" />
        <Button
          as={ExternalLink}
          href={linkHref}
          onMouseEnter={() => this.updateInfoBlock(index, 'hovering', true)}
          onMouseLeave={() => this.updateInfoBlock(index, 'hovering', false)}>
          <ContentfulPlainText field={fields.linkText} />
        </Button>
      </Layout.Group>
    );
  }

  renderInfoBlockImage(index, fields) {
    const mod = this.getScrollModifier(index);
    const linkHref = getContentfulField(fields.link);
    const { hovering } = this.getInfoBlockState(index);
    return (
      <Layout.Group className={this.getElementClass('slide-image', mod)}>
        <ExternalLink href={linkHref}>
          <ContentfulImage
            className={this.getElementClass(
              'info-block-image',
              hovering ? 'hover' : null
            )}
            field={fields.image}
            ratio={1}
          />
        </ExternalLink>
      </Layout.Group>
    );
  }
}
